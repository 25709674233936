import type { GridLayout, Column, Columns } from '../../gridLayoutModels';
import { colWidth } from 'styles/constants';
import { getPositionById } from './gridLayoutPosition';
import { getSizeById } from './gridLayoutSize';

export function getOccupiedColumnsIndicesByCoordinates(
  left: number,
  width: number,
): readonly number[] {
  const start = Math.floor(left / colWidth);
  const length = Math.ceil(width / colWidth);
  return Array.from(Array(length), (_item, index) => index + start);
}

export function getOccupiedColumnsById(
  gridLayout: Pick<GridLayout, 'gridItemSizes' | 'gridItemPositions'>,
  gridItemId: string,
): readonly number[] {
  const { left } = getPositionById(gridLayout, gridItemId);
  const { width } = getSizeById(gridLayout, gridItemId);
  return getOccupiedColumnsIndicesByCoordinates(left, width);
}

export const findLocusInsertionIndexInColumn =
  (top: number, bottom: number) =>
  (column: Column): number => {
    let index = 0;
    for (const locus of column) {
      if (top < locus.top) {
        // top is above locus.top
        // if bottom is below it, it collides -> -1
        // if bottom is above top, it fits -> index
        return bottom > locus.top ? -1 : index;
      } else if (top < locus.bottom) {
        // top is between locus.top and locus.bottom
        return -1;
      }
      index++;
      // else try below
    }
    // there is always infinite room at the bottom of the collumn
    return index;
  };

export const findForceInsertionIndexInColumn =
  (top: number) =>
  (column: Column): number => {
    let index = 0;
    for (const { bottom } of column) {
      if (top < bottom) {
        return index;
      }
      index++;
    }
    // there is always infinite room at the bottom of the collumn
    return index;
  };

export function getOccupiedColumnsByCoordinates(
  left: number,
  width: number,
  columns: Columns,
): Columns {
  const startColumn = Math.floor(left / colWidth);
  const length = Math.ceil(width / colWidth);
  return columns.slice(startColumn, startColumn + length);
}
