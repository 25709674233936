import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { ICashQuoteRequestApi } from 'epics/cash';
import { ignoreElements } from 'rxjs/operators';

export function cashRfsQuoteRequestApi(http: ISgmeHttp): ICashQuoteRequestApi {
  return {
    msToFirstHearbeat: 120_000,
    createQuoteRequest(request) {
      return http
        .post<void>('api/fxSpotFwd/rfs/request', request, { 'X-Correlation-ID': request.rfsId })
        .pipe(ignoreElements());
    },
  };
}
