import type { AppState } from 'state/model';
import { isDefined, isNotDefined } from '@sgme/fp';

import type { FxOptionHedgeState } from '../model/optionHedges';
import type { Collection } from 'typings/utils';

// ██╗  ██╗███████╗██████╗  ██████╗ ███████╗
// ██║  ██║██╔════╝██╔══██╗██╔════╝ ██╔════╝
// ███████║█████╗  ██║  ██║██║  ███╗█████╗
// ██╔══██║██╔══╝  ██║  ██║██║   ██║██╔══╝
// ██║  ██║███████╗██████╔╝╚██████╔╝███████╗
// ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚══════╝
//

export function getOptionHedge(state: AppState, hedgeId: string): FxOptionHedgeState | undefined {
  const hedge = state.fxOptions.hedges[hedgeId];
  // assertIsDefined(hedge, `hedge ${hedgeId} does not exist in state`);
  return hedge;
}

export function isHedgeAmountValidated(state: AppState, hedgeId: string) {
  const hedge = getOptionHedge(state, hedgeId);

  return (
    isDefined(hedge) && isNotDefined(hedge.errors.amount) && isNotDefined(hedge.warnings.amount)
  );
}

//  ██████╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ██╔═══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██║   ██║██████╔╝   ██║   ██║██║   ██║██╔██╗ ██║
// ██║   ██║██╔═══╝    ██║   ██║██║   ██║██║╚██╗██║
// ╚██████╔╝██║        ██║   ██║╚██████╔╝██║ ╚████║
//  ╚═════╝ ╚═╝        ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
//

// TODO: useful ?? better use = getFxOptionMultilegOfOption ?
export function getAllHedgeIdsOfOption(state: AppState, optionId: string) {
  const optionPrefixLegId = `${optionId}/`;

  return Object.keys(state.fxOptions.hedges).filter(hedgeId =>
    hedgeId.startsWith(optionPrefixLegId),
  );
}

export function getHedgeStateOfOption(
  state: AppState,
  optionId: string,
): Collection<FxOptionHedgeState> {
  return getAllHedgesOfOptionWithId(state, optionId).reduce((hedgeState, [hedgeId, hedge]) => {
    hedgeState[hedgeId] = hedge;
    return hedgeState;
  }, {} as Collection<FxOptionHedgeState>);
}

export function getAllHedgesOfOption(
  state: AppState,
  optionId: string,
): Array<Readonly<FxOptionHedgeState>> {
  return getAllHedgeIdsOfOption(state, optionId).map(hedgeId => getOptionHedge(state, hedgeId)!);
}

export function getAllHedgesOfOptionWithId(
  state: AppState,
  optionId: string,
): Array<readonly [string, FxOptionHedgeState]> {
  return getAllHedgeIdsOfOption(state, optionId).map(hedgeId => [
    hedgeId,
    getOptionHedge(state, hedgeId)!,
  ]);
}
