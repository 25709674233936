import type { Thunk } from 'state';

export function bulkRfsCancelThunk(bulkId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const currentStreamId = sl.getBulkCurrentStreamId(getState(), bulkId);
    if (currentStreamId !== null) {
      dispatch(ac.bulkRfsCancel(bulkId, currentStreamId));
      dispatch(ac.bulkRfsCancelEpic(bulkId, currentStreamId));
    }
    dispatch(ac.bulkRfsClearError(bulkId));
  };
}

export function bulkClientChangedThunk(bulkId: string, clientId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const companyId = sl.getCompanyIdFromClientId(getState(), clientId);
    dispatch(ac.espStreamRestartThunk(bulkId, undefined, undefined, companyId));
    dispatch(ac.bulkRfsCancelThunk(bulkId));
  };
}
