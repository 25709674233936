import type { MapStateToPropsFactoryHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { Client } from 'state/referenceData';
import { strictValues } from 'utils/object';
import { createSelector } from 'reselect';

export interface TileClientPickerConnectOwnProps {
  quoteId: string;
}

export interface TileClientPickerConnectStateProps {
  clients: readonly Client[];
  pinned: readonly number[];
  isClientOverridden: boolean;
}

export interface TileClientPickerConnectDispatchProps {
  onClientChange(clientId: string): void;
  onReset(): void;
}

export type TileClientPickerConnectSelectorsKeys = 'getAllUserClients' | 'isTileClientIdOverridden';
export type TileClientPickerConnectSelectors = Pick<
  Selectors,
  TileClientPickerConnectSelectorsKeys
>;

export const mapStateToPropsTileClientPicker: MapStateToPropsFactoryHOF<
  TileClientPickerConnectStateProps,
  TileClientPickerConnectOwnProps,
  AppState,
  TileClientPickerConnectSelectors
> = sl => () => {
  const getAllClients = createSelector([sl.getAllUserClients], strictValues);
  return (state, { quoteId }) => ({
    clients: getAllClients(state),
    pinned: state.referenceData.pinnedCompanies,
    isClientOverridden: sl.isTileClientIdOverridden(state, quoteId),
  });
};

export type TileClientPickerConnectActionCreatorsKeys =
  | 'tileClientOverridenThunk'
  | 'tileClientResetThunk'
  | 'orderRequestEmailsEpic';
export type TileClientPickerConnectActionCreators = Pick<
  ActionCreators,
  TileClientPickerConnectActionCreatorsKeys
>;

export const mapDispatchToPropsTileClientPicker: MapDispatchToPropsHOF<
  TileClientPickerConnectDispatchProps,
  TileClientPickerConnectOwnProps,
  TileClientPickerConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    onClientChange: clientId => dispatch(ac.tileClientOverridenThunk(quoteId, clientId)),
    onReset: () => dispatch(ac.tileClientResetThunk(quoteId)),
  });
