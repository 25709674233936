import { throwIfUndefined } from 'utils/maps';
import type { AppState } from 'state/model';

export function getBulkStreamState(state: AppState, streamId: string) {
  return throwIfUndefined(
    state.fxBulks.streams[streamId],
    `Bulk stream state for ${streamId} does not exist`,
  );
}

export function getBulkStreamStatus(state: AppState, streamId: string) {
  return getBulkStreamState(state, streamId).status;
}

export function getBulkRfsStreamQuote(state: AppState, streamId: string) {
  const stream = getBulkStreamState(state, streamId);
  const quote = stream.status === 'PRICING' ? stream.quote : undefined;
  return throwIfUndefined(quote, `Bulk stream state ${streamId} not in PRICING state`);
}

export function getBulkRfsStreamQuoteType(state: AppState, streamId: string) {
  return getBulkRfsStreamQuote(state, streamId).quoteType;
}
