import type { Thunk } from 'state';

export function optionStreamCancelThunk(optionId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { currentStreamId } = sl.getOptionState(state, optionId);
    const isPricing = sl.getOptionStreamStatus(state, currentStreamId) === 'PRICING';

    if (currentStreamId !== null) {
      dispatch(ac.optionStreamCanceled(optionId, currentStreamId, isPricing));
    }
  };
}
