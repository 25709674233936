import type { AppState } from 'state/model';
import { getSharedProperty, getValueProperty } from 'state/share/form';
import type {
  BlotterTradeValues,
  BlotterTradeInputs,
  BlotterTradeValuePropertyKey,
  BlotterTradeSharedPropertyKey,
} from '../blotterEntryModel';
import { getBlotterTradeById } from './blotterSelectors';

export function getBlotterTradeInput<T extends BlotterTradeSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<BlotterTradeValues, BlotterTradeInputs, T>(prop);
  return (state: AppState, tradeId: string) => get(getBlotterTradeById(state, tradeId));
}

export function getBlotterTradeValue<T extends BlotterTradeValuePropertyKey>(prop: T) {
  const get = getValueProperty<BlotterTradeValues, BlotterTradeInputs, T>(prop);
  return (state: AppState, tradeId: string) => get(getBlotterTradeById(state, tradeId));
}

export const getBlotterTradeProduct = getBlotterTradeValue('product');
export const getBlotterTradeStatus = getBlotterTradeValue('status');
export const getBlotterTradeCurrencyPair = getBlotterTradeValue('currencyPair');
export const getBlotterTradeDealtCurrency = getBlotterTradeValue('dealtCurrency');
export const getBlotterTradeContraCurrency = getBlotterTradeValue('contraCurrency');
export const getBlotterTradeContraAmount = getBlotterTradeValue('contraAmount');
export const getBlotterTradeFarContraAmount = getBlotterTradeValue('farContraAmount');
export const getBlotterTradeWay = getBlotterTradeValue('way');
export const getBlotterTradeAccount = getBlotterTradeValue('account');
export const getBlotterTradeSpotRate = getBlotterTradeValue('spot');
export const getBlotterTradeFarRate = getBlotterTradeValue('farRate');
export const getBlotterTradeNearRate = getBlotterTradeValue('nearRate');
export const getBlotterTradeSwapPoints = getBlotterTradeValue('points');
export const getBlotterTradeSpotDate = getBlotterTradeValue('spotDate');
export const getBlotterTradeTradeDate = getBlotterTradeValue('date');
export const getBlotterTradeFarDate = getBlotterTradeValue('farDate');
export const getBlotterTradeNearDate = getBlotterTradeValue('nearDate');
export const getBlotterTradeFarDealtAmount = getBlotterTradeValue('farDealtAmount');
export const getBlotterTradeDealtAmount = getBlotterTradeValue('dealtAmount');
export const getBlotterTradeStrike = getBlotterTradeValue('strike');
export const getBlotterTradeExpiry = getBlotterTradeValue('expiry');
export const getBlotterTradePremium = getBlotterTradeValue('premium');
export const getBlotterTradePremiumSide = getBlotterTradeValue('premiumSide');
export const getBlotterTradeOptionType = getBlotterTradeValue('optionType');
