import { createContext, Component, type Ref } from 'react';
import type { RenderPropChildren } from 'typings/utils';

type RefContextChildrenProps<E> = Ref<E>;
interface RefContextState<E> {
  element: E | null;
}

export function createRefContext<E>(contextDisplayName?: string) {
  const RefContext = createContext<E | null>(null);
  RefContext.displayName = contextDisplayName;

  class RefProvider extends Component<
    RenderPropChildren<RefContextChildrenProps<E>>,
    RefContextState<E>
  > {
    public state = { element: null };
    private ref: Ref<E> = element => this.setState(() => ({ element }));
    public render() {
      return (
        <RefContext.Provider value={this.state.element}>
          {this.props.children(this.ref)}
        </RefContext.Provider>
      );
    }
  }
  return { RefContext, RefProvider };
}
