import { PureComponent } from 'react';
import type { RenderPropChildren } from 'typings/utils';

export type ListFilter<T> = (
  query: string,
) => (item: T, index: number, array: readonly T[]) => boolean;

interface FilterListState<T> {
  list: readonly T[];
  query: string;
  filteredList: readonly T[];
  selectionIndex: number;
}
interface FilterListProps<T> {
  list: readonly T[];
  query: string;
}
export interface FilterListChildrenProps<T> {
  query: string;
  filteredList: readonly T[];
  selectionIndex: number;
  goto(index: number): void;
  next(): void;
  previous(): void;
  onQueryChange(query: string): void;
}

export function createFilterList<T>(filter: ListFilter<T>) {
  class FilterList extends PureComponent<
    RenderPropChildren<FilterListChildrenProps<T>> & FilterListProps<T>,
    FilterListState<T>
  > {
    // create memoizing filter
    private filter = filterByQuery(filter);
    // initial state
    public state: FilterListState<T> = (() => {
      const { query, list } = this.props;
      const filteredList = this.filter(list, query);
      const selectionIndex = Math.min(0, filteredList.length - 1);
      return { query, list, filteredList, selectionIndex };
    })();
    private previous = () =>
      this.setState(({ selectionIndex }) => ({
        selectionIndex: Math.max(0, selectionIndex - 1),
      }));

    private next = () =>
      this.setState(({ filteredList, selectionIndex }) => ({
        selectionIndex: Math.min(filteredList.length - 1, selectionIndex + 1),
      }));

    private onQueryChange = (query: string) =>
      this.setState(previousState => {
        if (previousState.query === query) {
          return null;
        }
        const filteredList = this.filter(this.props.list, query);
        const selectionIndex =
          filteredList === previousState.filteredList
            ? previousState.selectionIndex
            : Math.min(0, filteredList.length - 1);
        return { query, filteredList, selectionIndex };
      });

    private goto = (i: number) =>
      this.setState(({ filteredList: { length: l } }) => ({
        selectionIndex: l === 0 ? -1 : i >= l ? l - 1 : i < 0 ? 0 : i,
      }));

    private get childrenProps(): FilterListChildrenProps<T> {
      const { query, filteredList, selectionIndex } = this.state;
      const { next, previous, onQueryChange, goto } = this;
      return {
        query,
        filteredList,
        selectionIndex,
        next,
        previous,
        onQueryChange,
        goto,
      };
    }
    public render() {
      return this.props.children(this.childrenProps);
    }
  }
  return FilterList;
}

function filterByQuery<T>(filter: ListFilter<T>) {
  let memList: readonly T[] | null = null;
  let memQuery: string | null = null;
  let memFilteredList: readonly T[] = [];
  return (list: readonly T[], query: string) => {
    if (list !== memList || memQuery !== query) {
      memList = list;
      memQuery = query;
      memFilteredList = list.filter(filter(query));
    }
    return memFilteredList;
  };
}
