export const normalize = (value: string) => trimAll(cleanDiacritics(value.toLowerCase()));

export const trimAll = (value: string) => value.replace(/\s+/g, ' ').trim();

/**
 * Cleans diacritics from given value (e.g. "Crème brulée" => "Creme brulee")
 * @param value
 */
export const cleanDiacritics = (value: string) =>
  value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
