import type {
  UpstreamPostMessageStreaming,
  DownstreamPostMessageStreaming,
} from 'Workers/messages';
import type { ISignalRConfiguration } from '../../../configuration.signalr';
import { loggerBuilder } from 'utils/logger';
import { fromMessagePort } from 'utils/RX';
import { tap } from 'rxjs/operators';
import { noop } from 'rxjs';
// @ts-ignore
import MyWorker from '../../Workers/index.js?worker&inline';

const params = new URLSearchParams(self?.location.search);
const logger = loggerBuilder(params)('[MAIN THREAD MESSAGE RECEIVED]')('verbose');

const worker = new MyWorker();

export const [fromWebworker$, toWebworker$] = fromMessagePort<
  UpstreamPostMessageStreaming,
  DownstreamPostMessageStreaming
>(worker);

fromWebworker$.pipe(tap(logger.log)).subscribe(noop);

export function startSignalR(config: ISignalRConfiguration) {
  logger.log('START SIGNALR');
  toWebworker$.next({ type: 'START_SIGNALR', config });
}

export function startRemoteLogging(windowMs: number) {
  if (!Number.isNaN(windowMs) && windowMs >= 1000) {
    // eslint-disable-next-line no-console
    console.info('STARTING WEBWORKER LOGGING', windowMs);
    toWebworker$.next({ type: 'START_REMOTE_LOGGING', windowMs });
  } else {
    console.warn('WEBWORKER LOGGING IS DISABLED', windowMs);
  }
}

export function startStreamingPing(intervalMs: number, maxEspStreamingMs: number) {
  if (!Number.isNaN(intervalMs) && intervalMs >= 1000) {
    // eslint-disable-next-line no-console
    console.info('STARTING WEBWORKER STREAMING PING', intervalMs);
    toWebworker$.next({
      type: 'START_STREAMING_PING',
      intervalMs,
      maxEspStreamingMs,
    });
  } else {
    console.warn('WEBWORKER STREAMING PING IS DISABLED', intervalMs);
  }
}

export function setUserInfo(userInfo: { login: string; authorizationHeader: string }) {
  toWebworker$.next({ type: 'USER_INFO', userInfo });
}

export function setConfig(baseUrl: string, loggingSessionId: string) {
  toWebworker$.next({ type: 'CONFIG', baseUrl, loggingSessionId });
}

export function terminate(e: any) {
  // eslint-disable-next-line no-console
  console.info('TERMINATING WEB WORKER', e);
  worker.terminate();
}
