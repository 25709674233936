import { isBefore, addYears, addMonths, isValid, parse, format, startOfDay } from 'date-fns';
import { enUS as en, fr } from 'date-fns/locale';
import type { DateInputCultureInfo } from 'state/userPreferences';

const locales: Record<DateInputCultureInfo, Locale> = { en, fr };

const acceptedUsFormats = [
  'dd MMM yyyy',
  'MMM dd',
  'MMdd',
  'MM/dd',
  'MM/dd/yy',
  'MM/dd/yyyy',
  'MM-dd',
  'MM-dd-yy',
  'MM-dd-yyyy',
  'yyyy-MM-dd',
];
const acceptedFrFormats = [
  'dd MMM yyyy',
  'dd MMM yy',
  'd MMM yyyy',
  'd MMM yy',
  'ddMM',
  'dd MMM',
  'd MMM',
  'ddMMyy',
  'ddMMyyyy',
  'dd/MM',
  'dd-MM',
  'dd/MM/yy',
  'dd-MM-yy',
  'dd/MM/yyyy',
  'dd-MM-yyyy',
  'yyyy-MM-dd',
];
const standardFormat = 'yyyy-MM-dd';

function addOneYearIfPastDate(date: Date, now: Date): Date {
  if (isBefore(date, now)) {
    return addYears(date, 1);
  }
  return date;
}

function addOneMonthIfPastDate(date: Date, now: Date): Date {
  if (isBefore(date, now)) {
    return addMonths(date, 1);
  }
  return date;
}

export function tryParseDate(
  cultureInfo: DateInputCultureInfo,
  selectedDate: string,
  now: Date = new Date(),
): Date {
  const dateStringTrimmed = selectedDate.trim();

  const acceptedFormats = cultureInfo === 'fr' ? acceptedFrFormats : acceptedUsFormats;

  for (const formatString of acceptedFormats) {
    const parsedDate = parse(dateStringTrimmed, formatString, now, {
      locale: locales[cultureInfo],
    });
    if (isValid(parsedDate)) {
      return addOneYearIfPastDate(parsedDate, now);
    }
  }

  const parsedDayDate = parse(dateStringTrimmed, 'dd', now, {
    locale: locales[cultureInfo],
  });
  if (isValid(parsedDayDate)) {
    return addOneMonthIfPastDate(parsedDayDate, now);
  }

  return parsedDayDate;
}

const tenorRegex = /^([0-9]+[dwmy]{1}|[a-z]{1,3}[0-9]*)$/gi;
const isTenor = (dateOrTenor: string) => tenorRegex.test(dateOrTenor.trim());

const isValidStandardDate = (date: string) => isValid(parse(date, standardFormat, new Date()));

export const parseWithCultureInfoWith =
  (now: Date) =>
  (cultureInfo: DateInputCultureInfo, selectedDateOrTenor: string): string => {
    if (isTenor(selectedDateOrTenor) || isValidStandardDate(selectedDateOrTenor)) {
      return selectedDateOrTenor;
    }

    const maybeParsedDate = tryParseDate(cultureInfo, selectedDateOrTenor, now);

    if (isValid(maybeParsedDate)) {
      return format(maybeParsedDate, standardFormat);
    }

    return selectedDateOrTenor;
  };

export const parseWithCultureInfo = parseWithCultureInfoWith(startOfDay(new Date()));
