import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { pick } from 'utils/object';
import { isSwitchableInstrument } from 'state/referenceData/referenceDataModel';
import { defaultOrderHeight, orderTileWidth } from 'styles/constants';

const getSize = pick('width', 'height');

export interface TileSizeConnectOwnProps {
  workspaceId: string;
  quoteId: string;
}

export interface TileSizeConnectStateProps {
  height: number;
  width: number;
}

export interface TileSizeConnectDispatchProps {}

// you can whitelist selectors after implementation
export type TileSizeConnectSelectors = Selectors;

/*
export type TileSizeConnectSelectorsKeys = 'aSelector'; // example
export type TileSizeConnectSelectors = Pick<Selectors, TileSizeConnectSelectorsKeys>;
*/

export const mapStateToPropsTileSize: MapStateToPropsHOF<
  TileSizeConnectStateProps,
  TileSizeConnectOwnProps,
  AppState,
  TileSizeConnectSelectors
> =
  sl =>
  (state, { workspaceId, quoteId }) => {
    const instrument = sl.getQuoteInstrument(state, quoteId);
    return isSwitchableInstrument(instrument)
      ? getSize(sl.getGridItemSize(state, workspaceId, quoteId))
      : { height: defaultOrderHeight, width: orderTileWidth };
  };
