import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';

export interface QuoteHasErrorConnectOwnProps {
  quoteId: string;
}

export interface QuoteHasErrorConnectStateProps {
  hasError: boolean;
}

// you can whitelist selectors after implementation
export type QuoteHasErrorConnectSelectors = Selectors;
/*
export type QuoteHasErrorConnectSelectorsKeys = 'aSelector'; // example
export type QuoteHasErrorConnectSelectors = Pick<Selectors, QuoteHasErrorConnectSelectorsKeys>;
*/

export const mapStateToPropsQuoteHasError: MapStateToPropsHOF<
  QuoteHasErrorConnectStateProps,
  QuoteHasErrorConnectOwnProps,
  AppState,
  QuoteHasErrorConnectSelectors
> =
  sl =>
  (state, { quoteId }) => ({
    hasError:
      sl.isTilePresent(state, quoteId) &&
      (sl.getTilePropertiesRequestError(state, quoteId) !== null ||
        (sl.getTileState(state, quoteId).clientError ?? false)),
  });
