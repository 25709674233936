import type { AppState } from 'state/model';
import { getOptionVanillaLegInput } from './factories';
import { getOptionLeg } from './fxOptionsLegsSelectors';

export type { OptionLegSharedPropertyStatus } from './factories';

export function getOptionLegProductName(state: AppState, legId: string) {
  const leg = getOptionLeg(state, legId);

  return leg.productName;
}

export const getOptionVanillaLegOptionType = getOptionVanillaLegInput('optionType');
export const getOptionVanillaLegSettlementType = getOptionVanillaLegInput('settlementType');
export const getOptionVanillaLegCashSettlementCurrency =
  getOptionVanillaLegInput('cashSettlementCurrency');
export const getOptionVanillaLegFixingReference1 = getOptionVanillaLegInput('fixingReference1');
export const getOptionVanillaLegExpiry = getOptionVanillaLegInput('expiryDate');
export const getOptionVanillaLegExpiryTenor = getOptionVanillaLegInput('expiryDateTenor');
export const getOptionVanillaLegDelivery = getOptionVanillaLegInput('deliveryDate');
export const getOptionVanillaLegMarketPlace = getOptionVanillaLegInput('marketPlace');
export const getOptionVanillaLegStrike = getOptionVanillaLegInput('strike');
export const getOptionVanillaLegNotional = getOptionVanillaLegInput('notionalAmount');
export const getOptionVanillaLegNotionalCurrency = getOptionVanillaLegInput('notionalCurrency');
export const getOptionVanillaLegMarkup = getOptionVanillaLegInput('markup');
export const getOptionVanillaLegVolatilityAsk = getOptionVanillaLegInput('volatilityAsk');
export const getOptionVanillaLegVolatilityBid = getOptionVanillaLegInput('volatilityBid');
export const getOptionVanillaLegSide = getOptionVanillaLegInput('side');
export const getOptionVanillaLegPremiumDate = getOptionVanillaLegInput('premiumDate');
export const getOptionVanillaLegPremiumDateTenor = getOptionVanillaLegInput('premiumDateTenor');
export const getOptionVanillaLegPremiumBid = getOptionVanillaLegInput('premiumBid');
export const getOptionVanillaLegPremiumAsk = getOptionVanillaLegInput('premiumAsk');
export const getOptionVanillaLegIsInFine = getOptionVanillaLegInput('isInFine');
export const getOptionVanillaLegPremiumTypeString = getOptionVanillaLegInput('premiumTypeString');
export const getOptionVanillaLegPremiumCurrency = getOptionVanillaLegInput('premiumCurrency');
