import type { Reducer } from 'redux';
import { addKey, updateKey } from 'utils/stateMap';
import type { Collection } from 'typings/utils';
import type { Mifid2Quote } from '../mifidPanelModel';
import type { Mifid2PanelActions } from '../mifidPanelActions';
import { getMifidQuoteId } from '../utils';

export const mifid2QuotesReducer: Reducer<Collection<Mifid2Quote>, Mifid2PanelActions> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case 'MIFID2_RECORD_TIMESTAMP_SENT':
      return addKey(state, getMifidQuoteId(action.negotiationId, action.quoteId), {
        timestamp: action.timestamp,
        status: 'Sent',
      });
    case 'MIFID2_RECORD_TIMESTAMP_RECEIVED':
      return updateKey(state, getMifidQuoteId(action.negotiationId, action.quoteId), () => ({
        status: action.success ? 'Given' : 'Failed',
      }));
    default:
      return state;
  }
};
