import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { Mifid2NegotiationStatus } from 'state/mifidPanel';

export interface NegotiationConnectOwnProps {
  negotiationId: string;
}

export interface NegotiationConnectStateProps {
  startTime: string;
  quotes: readonly string[];
  status: Mifid2NegotiationStatus;
}

export interface NegotiationConnectDispatchProps {
  onRecordTimestamp(): void;
  onDealDone(): void;
  onDealMissed(): void;
  onRemoveNegotiation(): void;
}

export type NegotiationConnectSelectorsKeys = 'getMifid2PanelNegotiation';
export type NegotiationConnectSelectors = Pick<Selectors, NegotiationConnectSelectorsKeys>;

export const mapStateToPropsNegotiation: MapStateToPropsHOF<
  NegotiationConnectStateProps,
  NegotiationConnectOwnProps,
  AppState,
  NegotiationConnectSelectors
> =
  (sl) =>
    (state, { negotiationId }) =>
      sl.getMifid2PanelNegotiation(state, negotiationId) ?? {
        startTime: '',
        quotes: [],
        status: 'Open',
      };

export type NegotiationConnectActionCreatorsKeys =
  | 'mifid2RecordTimestampEpic'
  | 'mifid2FinishNegotiation'
  | 'mifid2RemoveNegotiation';
export type NegotiationConnectActionCreators = Pick<
  ActionCreators,
  NegotiationConnectActionCreatorsKeys
>;

export const mapDispatchToPropsNegotiation: MapDispatchToPropsHOF<
  NegotiationConnectDispatchProps,
  NegotiationConnectOwnProps,
  NegotiationConnectActionCreators
> =
  (ac) =>
    (dispatch, { negotiationId }) => ({
      onRecordTimestamp() {
        dispatch(ac.mifid2RecordTimestampEpic(negotiationId));
      },
      onDealDone() {
        dispatch(ac.mifid2FinishNegotiation(negotiationId, true));
      },
      onDealMissed() {
        dispatch(ac.mifid2FinishNegotiation(negotiationId, false));
      },
      onRemoveNegotiation() {
        dispatch(ac.mifid2RemoveNegotiation(negotiationId));
      },
    });
