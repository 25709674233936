import type { Selector } from './typings';
import { fieldData } from 'utils/fieldSelectors';

export const liftToString =
  <T extends readonly any[]>(selector: Selector<T>): Selector<T> =>
  (...args) => {
    const value = fieldData(selector(...args)).data;
    return {
      value: null,
      input: value ? value.toString() : value,
    };
  };

export const bindSelector =
  <T, R, A extends readonly any[]>(transform: (arg: T) => R) =>
  (selector: (...args: A) => T) =>
  (...args: A) =>
    transform(selector(...args));

export const boxInValue = <T>(value: T): { value: T } => ({
  value,
});

export const boxResult = bindSelector(boxInValue);
