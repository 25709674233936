import type { Thunk } from 'state';
import type { Way } from 'state/share/productModel/litterals';
import { fieldData } from 'utils/fieldSelectors';

export function swapExecutionRequestedThunk(swapId: string, way: Way): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const canTrade = sl.userCanTrade(state) && sl.isStreamingConnected(state);
    if (canTrade !== true) {
      return;
    }

    const currencyPair = sl.getSwapCurrencyPair(state, swapId).value;
    if (currencyPair === null) {
      return;
    }
    const { currentStreamId } = sl.getSwapState(state, swapId);
    if (currentStreamId !== null) {
      dispatch(ac.swapRfsExecutionRequestedEpic(swapId, currentStreamId, way));
      if (fieldData(sl.getSwapIsOffMarket(state, swapId)).data === false) {
        dispatch(ac.swapPropertyChanged(swapId, { nearPriceReference: null }));
      }
    }
  };
}
