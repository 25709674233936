import {
  CashLegId,
  type ICashPropertiesChangedPatch,
} from 'api/tradeCapture/cash/tradeCaptureModel';
import type { TileInstrumentChanged } from 'state/clientWorkspace/clientWorkspaceActions';
import type {
  CashPropertiesChanged,
  CashTileOpenFromBlotterEpic,
  CashTileRestoreEpic,
} from 'state/fxCashs/fxCashsActions';

export function cashLegPropertiesChangedToTCPatch({
  cashId,
  patch,
}: CashPropertiesChanged): ICashPropertiesChangedPatch {
  const { currencyPair, ...legPatch } = patch;

  return {
    cashId,
    patch: {
      currencyPair,
      legs: {
        [CashLegId]: legPatch,
      },
    },
  };
}

export function cashTileRestoreToTCPatch(
  action: CashTileRestoreEpic,
): ICashPropertiesChangedPatch[] {
  return Object.entries(action.tiles).map(([cashId, tile]) => ({
    cashId,
    patch: {
      currencyPair: tile.currencyPair,
      legs: {
        [CashLegId]: {
          maturityDate: tile.maturityDateTenor,
          amountCurrency: tile.amountCurrency,
          amount: tile.amount?.toString(),
          isNonDeliverable: tile.isNonDeliverable,
        },
      },
    },
  }));
}

export function cashTileOpenFromBlotter({
  tileId,
  tile,
}: CashTileOpenFromBlotterEpic): ICashPropertiesChangedPatch {
  return {
    cashId: tileId,
    patch: {
      currencyPair: tile.currencyPair,
      legs: {
        [CashLegId]: {
          maturityDate: tile.maturityDateTenor,
          amountCurrency: tile.amountCurrency,
          amount: tile.amount?.toString(),
          isNonDeliverable: tile.isNonDeliverable,
        },
      },
    },
  };
}

export function cashTileIntrumentChangedToPatch({
  tileId,
  currencyPair,
}: TileInstrumentChanged): ICashPropertiesChangedPatch {
  return {
    cashId: tileId,
    patch: {
      currencyPair,
      legs: {
        [CashLegId]: {},
      },
    },
  };
}
