import { connect } from 'react-redux';
import { mapStateToPropsFieldTooltip, mapDispatchToPropsFieldTooltip } from './connectFieldTooltip';
import {
  mapStateToPropsLegFieldTooltip,
  mapDispatchToPropsLegFieldTooltip,
} from './connectLegFieldTooltip';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import { withQuoteId, withQuoteAndLegIds } from 'components/contexts';
import { DisplayTooltip } from './DisplayTooltip';
import {
  mapDispatchToPropsHedgeFieldTooltip,
  mapStateToPropsHedgeFieldTooltip,
} from './connectHedgeFieldTooltip';
import { withQuoteAndHedgeIds } from '../contexts/QuoteAndLegIdsContext';

export * from './DisplayTooltip';

export const connectFieldTooltip = connect(
  mapStateToPropsFieldTooltip(selectors),
  mapDispatchToPropsFieldTooltip(actionCreators),
);

export const connectLegFieldTooltip = connect(
  mapStateToPropsLegFieldTooltip(selectors),
  mapDispatchToPropsLegFieldTooltip(actionCreators),
);

export const connectHedgeFieldTooltip = connect(
  mapStateToPropsHedgeFieldTooltip(selectors),
  mapDispatchToPropsHedgeFieldTooltip(actionCreators),
);

/**
 * Automagically display warning/error tooltip for a field on the product
 * @see TooltipContainerContext.Provider should be present in the tree for proper z-index positioning
 */
export const FieldTooltip = withQuoteId(connectFieldTooltip(DisplayTooltip));

/**
 * Automagically display warning/error tooltip for a field on a leg of the product
 * @see TooltipContainerContext.Provider should be present in the tree for proper z-index positioning
 */
export const LegFieldTooltip = withQuoteAndLegIds(connectLegFieldTooltip(DisplayTooltip));

/**
 * Automagically display warning/error tooltip for a field on a hedge of the product
 * @see TooltipContainerContext.Provider should be present in the tree for proper z-index positioning
 */
export const HedgeFieldTooltip = withQuoteAndHedgeIds(connectHedgeFieldTooltip(DisplayTooltip));
