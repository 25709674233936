import { Component, Suspense } from 'react';
import { connectFreeLayoutContainer } from './connect';
import type { ItemPosition } from 'state/freeLayout/freeLayoutModel';
import { QuoteIdProvider, WorkspaceIdProvider } from '../contexts';
import type { Collection } from 'typings/utils';
import { GridItem } from './GridItem';
import { CurrencyPickerProvider } from '../CurrencyPicker';
import { colWidth, defaultOrderHeight, orderTileWidth } from 'styles/constants';
import { DraggingProvider } from '../contexts/Dragging';
import styled from 'styled-components';
import { OrderResultOverlay } from 'components/share/OrderResultOverlay';
import { TileLayout } from 'components/FxTileLayout';
import { TileCatchError } from "../GenericTile/TileCatchError";
import { TileStatus } from "../GenericTile/TileStatus";
import { TileInstrument } from "../GenericTile/TileInstrument";

export interface FreeLayoutContainerProps {
  tabId: string;
  itemPositions: Collection<ItemPosition>;
  onPositionChanged(itemId: string, position: ItemPosition): void;
}

export interface FreeLayoutContainerState {
  dragState: Dragging | Idle;
}

interface Dragging {
  isDragging: true;
  draggingItemId: string;
  positionDraggingItem: ItemPosition;
}
interface Idle {
  isDragging: false;
}

const defaultSize = { width: 0, height: 0 };

const GridItemsContainer = styled.div.attrs({ className: 'position-relative' })`
  z-index: 10;
`;

export class FreeLayoutContainerRaw extends Component<FreeLayoutContainerProps, FreeLayoutContainerState> {
  public state: FreeLayoutContainerState = {
    dragState: { isDragging: false },
  };

  public onDrag = (itemId: string, position: ItemPosition) => {
    this.setState({
      dragState: {
        isDragging: true,
        draggingItemId: itemId,
        positionDraggingItem: {
          top: position.top,
          left: position.left - Math.floor(colWidth / 2),
        },
      },
    });
  };

  public onDragEnd = () => {
    if (this.state.dragState.isDragging) {
      this.props.onPositionChanged(this.state.dragState.draggingItemId, this.state.dragState.positionDraggingItem);

      this.setState({ dragState: { isDragging: false } });
    }
  };

  public render() {
    const { itemPositions, tabId } = this.props;
    return (
      <DraggingProvider value>
        <WorkspaceIdProvider value={tabId}>
          <GridItemsContainer>
            {Object.entries(itemPositions).map(([orderId, position], index) => {
              if (position === undefined) {
                throw new Error(`Position for item ${orderId} should exist at this stage`);
              }
              return (
                <GridItem
                  key={orderId}
                  gridItemId={orderId}
                  position={position}
                  size={defaultSize}
                  onDrag={this.onDrag}
                  onDragEnd={this.onDragEnd}
                  noDragSelector="input, label, select, button, .no-drag, [data-nodrag]"
                >
                  <QuoteIdProvider value={orderId}>
                    <TileCatchError>
                      <div className="position-relative" style={{ zIndex: 10 + 2 * index }}>
                        <OrderResultOverlay />
                        <TileStatus error close>
                          <CurrencyPickerProvider>
                            <Suspense
                              fallback={
                                <TileLayout
                                  layout="free-width"
                                  width={orderTileWidth}
                                  height={defaultOrderHeight}
                                  currencyList={null}
                                  header={null}
                                >
                                  <div className="h-100 w-100 d-flex justify-content-around align-items-center">
                                    <div className="spinner spinner-xl"></div>
                                  </div>
                                </TileLayout>
                              }
                            >
                              <TileInstrument />
                            </Suspense>
                          </CurrencyPickerProvider>
                        </TileStatus>
                      </div>
                    </TileCatchError>
                  </QuoteIdProvider>
                </GridItem>
              );
            })}
          </GridItemsContainer>
        </WorkspaceIdProvider>
      </DraggingProvider>
    );
  }
}

export const FreeLayoutContainer = connectFreeLayoutContainer(FreeLayoutContainerRaw);
