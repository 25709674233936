import type { HttpCall, ISgmeHttp } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';

export function recordPriceRequestApi(http: ISgmeHttp): IRecordPriceRequestApi {
  return {
    createRecordPriceRequest(request) {
      return http.post<void>('api/mifid/recordPrice', request);
    },
  };
}

export interface IRecordPriceRequestApi {
  createRecordPriceRequest(request: IRecordPriceRequestParams): Observable<HttpCall<void>>;
}

export interface IRecordPriceRequestParams {
  prices: readonly ILegRecordPrice[];
  rfsId: string;
  quoteId: string;
}

export interface ILegRecordPrice {
  bid: number;
  ask: number;
}
