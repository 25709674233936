import type { Thunk } from 'state';

export function americanForwardStreamCancelThunk(americanForwardId: string): Thunk<void> {
    return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
        const state = getState();
        const { currentStreamId } = sl.getAmericanForwardState(state, americanForwardId);
        const isPricing = sl.getAmericanForwardStreamStatus(state, currentStreamId) === 'PRICING';

        if (currentStreamId !== null) {
            dispatch(ac.americanForwardStreamCanceled(americanForwardId, currentStreamId, isPricing));
        }
    };
}
