import type { Thunk } from 'state';
import type { IESPTraderPriceUnderThreshold } from 'state/esp/espStreamsModel';
import type { IFxCashMetadata } from '../fxCashsModel';
import { updateMargin } from 'utils/margin';
import { getEspPriceByNotional } from 'state/esp/utils';

type AskMarginName = 'askMargin' | 'askForwardMargin';
type BidMarginName = 'bidMargin' | 'bidForwardMargin';

function getCashMarginPlus(askMarginName: AskMarginName, bidMarginName: BidMarginName) {
  const getMargin = getCashMarginFromState(askMarginName, bidMarginName);
  return (cashId: string): Thunk<void> =>
    (dispatch, getState, { selectors: { getCashState }, actionCreators: ac }) => {
      const cashState = getCashState(getState(), cashId);
      const { askMargin, bidMargin } = getMargin(cashState);

      const data: Partial<IFxCashMetadata> = {};
      data[askMarginName] = updateMargin(askMargin, 0.1);
      data[bidMarginName] = updateMargin(bidMargin, 0.1);

      dispatch(ac.cashLocalPropertyChanged(cashId, data));
    };
}

export const cashMarginPlus = getCashMarginPlus('askMargin', 'bidMargin');
export const cashSwapMarginPlus = getCashMarginPlus('askForwardMargin', 'bidForwardMargin');

function getCashMarginMinus(askMarginName: AskMarginName, bidMarginName: BidMarginName) {
  const getMargin = getCashMarginFromState(askMarginName, bidMarginName);
  return (cashId: string): Thunk<void> =>
    (dispatch, getState, { selectors: { getCashState }, actionCreators: ac }) => {
      const cashState = getCashState(getState(), cashId);
      const { askMargin, bidMargin } = getMargin(cashState);

      const data: Partial<IFxCashMetadata> = {};
      data[askMarginName] = updateMargin(askMargin, -0.1);
      data[bidMarginName] = updateMargin(bidMargin, -0.1);

      dispatch(ac.cashLocalPropertyChanged(cashId, data));
    };
}

export const cashMarginMinus = getCashMarginMinus('askMargin', 'bidMargin');
export const cashSwapMarginMinus = getCashMarginMinus('askForwardMargin', 'bidForwardMargin');

function getCashMarginLeftSkew(askMarginName: AskMarginName, bidMarginName: BidMarginName) {
  const getMargin = getCashMarginFromState(askMarginName, bidMarginName);
  return (cashId: string): Thunk<void> =>
    (dispatch, getState, { selectors: { getCashState }, actionCreators: ac }) => {
      const cashState = getCashState(getState(), cashId);
      const { askMargin, bidMargin } = getMargin(cashState);

      const data: Partial<IFxCashMetadata> = {};
      data[askMarginName] = updateMargin(askMargin, -0.1);
      data[bidMarginName] = updateMargin(bidMargin, 0.1);

      dispatch(ac.cashLocalPropertyChanged(cashId, data));
    };
}
export const cashMarginLeftSkew = getCashMarginLeftSkew('askMargin', 'bidMargin');
export const cashSwapMarginLeftSkew = getCashMarginLeftSkew('askForwardMargin', 'bidForwardMargin');

function getCashMarginRightSkew(askMarginName: AskMarginName, bidMarginName: BidMarginName) {
  const getMargin = getCashMarginFromState(askMarginName, bidMarginName);
  return (cashId: string): Thunk<void> =>
    (dispatch, getState, { selectors: { getCashState }, actionCreators: ac }) => {
      const cashState = getCashState(getState(), cashId);
      const { askMargin, bidMargin } = getMargin(cashState);

      const data: Partial<IFxCashMetadata> = {};
      data[askMarginName] = updateMargin(askMargin, 0.1);
      data[bidMarginName] = updateMargin(bidMargin, -0.1);

      dispatch(ac.cashLocalPropertyChanged(cashId, data));
    };
}
export const cashMarginRightSkew = getCashMarginRightSkew('askMargin', 'bidMargin');
export const cashSwapMarginRightSkew = getCashMarginRightSkew(
  'askForwardMargin',
  'bidForwardMargin',
);

export function cashSpotMarginReset(cashId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    let bidMargin = null;
    let askMargin = null;

    const state = getState();
    const { currentStreamId, currentEspStreamId } = sl.getCashState(state, cashId);
    const amount = sl.getCashAmount(state, cashId).value;
    const amountCurrency = sl.getCashAmountCurrency(state, cashId).value;

    if (currentStreamId !== null) {
      const currentStream = sl.getCashRfsStreamState(state, currentStreamId);
      if (currentStream.status === 'PRICING') {
        const { bid, ask } = currentStream.quote.defaultSpotMargin;
        bidMargin = bid;
        askMargin = ask;
      }
    } else if (currentEspStreamId !== null) {
      const currentStream = sl.getEspStreamState(state, currentEspStreamId)!;
      if (currentStream.status === 'PRICING' && currentStream.priceType === 'ESP.TRADER.PRICE') {
        const price = getEspPriceByNotional(currentStream, amount, amountCurrency)
          .price as IESPTraderPriceUnderThreshold;
        if (price?.valid) {
          bidMargin = price.marginBid;
          askMargin = price.marginAsk;
        }
      }
    }

    dispatch(
      ac.cashLocalPropertyChanged(cashId, {
        bidMargin,
        askMargin,
      }),
    );
  };
}

export function cashForwardMarginReset(cashId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    const { bid: bidForwardMargin, ask: askForwardMargin } = sl.getRfsDefaultForwardMarginPoints(
      state,
      cashId,
    );

    dispatch(
      ac.cashLocalPropertyChanged(cashId, {
        bidForwardMargin,
        askForwardMargin,
      }),
    );
  };
}

export function cashSwitchMarkupCurrency(cashId: string): Thunk<void> {
  return (dispatch, getState, { selectors: { getCashState }, actionCreators: ac }) => {
    const { markupCurrency } = getCashState(getState(), cashId);

    dispatch(
      ac.cashLocalPropertyChanged(cashId, {
        markupCurrency: markupCurrency === 1 ? 2 : 1,
      }),
    );
  };
}

function getCashMarginFromState(
  askMarginName: keyof IFxCashMetadata,
  bidMarginName: keyof IFxCashMetadata,
) {
  return (cashState: IFxCashMetadata) => ({
    askMargin: cashState[askMarginName]! as number,
    bidMargin: cashState[bidMarginName]! as number,
  });
}
