import { sgwtConnect } from './api/connect';
import { bootstrap } from './bootstrap';
import { logger } from 'logging/logger';
import { getMeasureTextWidthContext } from 'utils/canvas';
import { redirectToRequestAccessPage } from 'utils/url';
import { forceLoadFonts } from 'utils/font';

// force the browser to load the font
// required to avoid bug on first price render resize
getMeasureTextWidthContext("30px 'Neue Haas Grotesk Text'");
forceLoadFonts();

declare global {
  interface Window {
    sgwtConnect: typeof sgwtConnect;
    sgbsVersion: string;
  }
}

window.sgwtConnect = sgwtConnect;

if (sgwtConnect.isAuthorized()) {
  const claims = sgwtConnect.getIdTokenClaims();
  if (claims && claims.sub) {
    logger.logInformation('User: {claims_s} successfully signed in on the FX website.', claims.sub);
  } else {
    logger.logWarning(
      'A user has successfully signed in on the FX website, but we failed to obtain their username (probably due to SGConnect configuration).',
    );
  }
  bootstrap();
} else {
  const authorizationError = sgwtConnect.getAuthorizationError();
  if (authorizationError) {
    logger.logError(
      'source : authorization. Authorization failed with error {message_s}',
      authorizationError,
    );

    redirectToRequestAccessPage();
  } else {
    logger.logInformation(`Someone has entered the FX Website and requested authorization.`);
    sgwtConnect.requestAuthorization();
  }
}
