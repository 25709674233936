import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { Instrument } from 'state/referenceData/referenceDataModel';

export interface InstrumentConnectOwnProps {
  quoteId: string;
}

export interface InstrumentConnectStateProps {
  instrument: Instrument;
}

export type InstrumentConnectSelectorKeys = 'getTileState';
export type InstrumentConnectSelectors = Pick<Selectors, InstrumentConnectSelectorKeys>;

export const mapStateToPropsInstrument: MapStateToPropsHOF<
  InstrumentConnectStateProps,
  InstrumentConnectOwnProps,
  AppState,
  InstrumentConnectSelectors
> =
  sl =>
  (state, { quoteId }) => ({
    instrument: sl.getTileState(state, quoteId).instrument,
  });
