export interface ISeparators {
  localeThousandSeparator: string;
  localeDecimalSeparator: string;
}

const localeSeparators: { [key: string]: ISeparators } = {
  en: {
    localeThousandSeparator: ',',
    localeDecimalSeparator: '.',
  },
  fr: {
    localeThousandSeparator: ' ',
    localeDecimalSeparator: ',',
  },
};

export function getSeparatorByLocale(locale?: string): ISeparators {
  return locale === undefined
    ? localeSeparators.en
    : localeSeparators[locale.toLowerCase()] || localeSeparators.en;
}

export function isAccelerator(keyReceived: string) {
  const key = keyReceived.toLowerCase();
  return key === 'k' || key === 'm' || key === 'g' || key === 'b';
}

export function acceleratorOffset(keyReceived: string) {
  const key = keyReceived.toLowerCase();
  switch (key) {
    case 'k':
      return 3;
    case 'm':
      return 6;
    case 'g':
    case 'b':
      return 9;
  }
  return 0;
}
