import type { Side } from '../share/productModel/litterals';
import { makeValuesPredicate } from '@sgme/fp';
import type { BlotterOrderInputs } from '../blotter/blotterEntryModel';
import type { BlotterOrderLimitInputs } from '../blotter/blotterEntryModel';
import type { BlotterEntry, OrderBlotterLimitEntry } from '../blotter/blotterEntryModel';
import { limitOrderTypes } from './fxOrdersModel';

const possiblePriceFields = ['margin', 'limitPrice', 'customerPrice'] as const;
const isPriceField = makeValuesPredicate(possiblePriceFields);

export const isOrderBlotterLimitEntry = (order: BlotterEntry): order is OrderBlotterLimitEntry =>
  // @ts-ignore
  limitOrderTypes.includes(order.values.product);

export const isBlotterOrderLimitInputs = (
  patch: Partial<BlotterOrderInputs>,
): patch is BlotterOrderLimitInputs => Object.keys(patch).some(isPriceField);

export function getCurrentPriceField<T extends Record<string, unknown>>(patch: Partial<T>) {
  return Object.keys(patch).find(isPriceField) ?? null;
}

export function getPriceWith(way: Side | null, priceType: string | number) {
  return (price: number, margin: string | number) => {
    const calculatePrice = calculatePriceWith(price, Number(margin));
    const subtractMarginFromPrice = calculatePrice('subtract');
    const addMarginFromPrice = calculatePrice('add');

    if (
      (priceType === 'limitPrice' && way === 'Sell') ||
      (priceType !== 'limitPrice' && way === 'Buy')
    ) {
      return subtractMarginFromPrice;
    } else {
      return addMarginFromPrice;
    }
  };
}

function calculatePriceWith(price: number, margin: number) {
  return (operation: string) => {
    const marginValue = operation === 'add' ? margin : margin * -1;

    return parseFloat((price + marginValue / 10000).toFixed(5)).toString();
  };
}
