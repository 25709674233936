import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { ISwapQuoteRequestApi } from 'epics/swap';
import { ignoreElements } from 'rxjs/operators';

export function swapRfsQuoteRequestApi(http: ISgmeHttp): ISwapQuoteRequestApi {
  return {
    msToFirstHearbeat: 120_000,
    createQuoteRequest(request) {
      return http
        .post<void>('api/fxSwap/rfs/request', request, { 'X-Correlation-ID': request.rfsId })
        .pipe(ignoreElements());
    },
  };
}
