import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { AppState } from 'state/model';
import type { OmsOrderModifyRequest } from 'api/order/oms';
import { fieldData } from 'utils/fieldSelectors';
import {
  getEditedAmountsHOF,
  getEditedClipDataHOF,
  getEditedDatesDataHOF,
  getEditedLimitPriceHOF,
} from './blotterOrderGetters';
import * as orderMetaSelectors from 'state/blotter/selectors';

type OrderMetaSelectors = typeof orderMetaSelectors;

export const metaSelectorBlotterOrderModifyData: MapStateToMetadataHOF<
  OmsOrderModifyRequest,
  { orderId: string },
  AppState,
  OrderMetaSelectors
> = sl => {
  const getAmounts = getEditedAmountsHOF(sl);
  const getClipData = getEditedClipDataHOF(sl);
  const getLimitPrice = getEditedLimitPriceHOF(sl);
  const getDatesData = getEditedDatesDataHOF(sl);

  return (state, { orderId }) => {
    const orderType = fieldData(sl.getBlotterOrderProduct(state, orderId)).data;
    const fixingMarginType = fieldData(sl.getBlotterEditedOrderFixingMarginTypeInput(state, orderId)).data ?? undefined;

    const isInternalSales = state.referenceData.userInfo.userType === 'Internal Sales';
    const isTakeProfit = orderType === 'TakeProfit';
    const margin =
      isInternalSales && isTakeProfit
        ? fieldData(sl.getBlotterEditedOrderTakeProfitMarginInput(state, orderId)).data
        : undefined;
    const way = sl.getBlotterOrderWay(state, orderId);

    return orderType === 'Fixing'
      ? {
          orderType,
          payload: {
            orderRef: orderId,
            ...getAmounts(state, orderId),
            ccyPair: sl.getBlotterOrderCurrencyPair(state, orderId),
            fixingPriceType: fieldData(sl.getBlotterEditedOrderFixingPriceTypeInput(state, orderId)).data ?? undefined,
            fixingTime: fieldData(sl.getBlotterOrderFixingTime(state, orderId)).data ?? undefined,
            fixingPlace: fieldData(sl.getBlotterOrderFixingPlace(state, orderId)).data ?? undefined,
            fixingMarginType,
            marginInBps:
              fixingMarginType === 'MarginInBps'
                ? Number(fieldData(sl.getBlotterEditedOrderMarginInBpsInput(state, orderId)).data) ?? undefined
                : undefined,
            fixingDateUtc: sl.getBlotterOrderFixingDateUtc(state, orderId) ?? undefined,
            fixingBenchmark: fieldData(sl.getBlotterOrderFixingBenchmark(state, orderId)).data ?? undefined,
          },
        }
      : {
          orderType: fieldData(sl.getBlotterEditedOrderProduct(state, orderId)).data ?? undefined,
          payload: {
            orderRef: orderId,
            way: way === null ? undefined : way.toLocaleLowerCase(),
            ccyPair: sl.getBlotterOrderCurrencyPair(state, orderId),
            limitPrice: getLimitPrice(state, orderId),
            customerPrice:
              fieldData(sl.getBlotterEditedOrderCustomerPriceInput(state, orderId)).data?.toString() ?? undefined,
            margin,
            ...(orderType !== 'Call' ? getAmounts(state, orderId) : undefined),
            ...getDatesData(state, orderId),
            liquidityPool: fieldData(sl.getBlotterOrderLiquidityPool(state, orderId)).data?.join(',') ?? undefined,
            ...getClipData(state, orderId),
            dodging: fieldData(sl.getBlotterEditedOrderRandomize(state, orderId)).data ?? undefined,
            spreadCapture: fieldData(sl.getBlotterEditedOrderSpreadCapture(state, orderId)).data ?? undefined,
            speed: fieldData(sl.getBlotterEditedOrderSpeed(state, orderId)).data ?? undefined,
            alphaSeeker: fieldData(sl.getBlotterEditedOrderAlphaSeeker(state, orderId)).data ?? undefined,
          },
        };
  };
};

export const getBlotterOrderModify = metaSelectorBlotterOrderModifyData(orderMetaSelectors);
