import type { AppState } from 'state/model';

export const isUserPreferenceOpened = (state: AppState): boolean =>
  getUserPreference(state).showUserPreferences;

export const isResetTileOnSuccessfulExecution = (state: AppState): boolean =>
  getUserPreferenceData(state).resetTileOnSuccessfulExecution;

export const getSplitNotifications = (state: AppState): boolean =>
  getUserPreferenceData(state).splitNotifications;

export const getUserDateInputCultureInfo = (state: AppState) =>
  getUserPreferenceData(state).dateInputCultureInfo;

export const getUserSwapPointsVisibility = (state: AppState) =>
  getUserPreferenceData(state).showSwapPoints;

export const getUserDeltaStrikeVisibility = (state: AppState) =>
  getUserPreferenceData(state).showDeltaStrike;

export const getUserDefaultNotional = (state: AppState) =>
  getUserPreferenceData(state).defaultNotional;

export const getAccountNameLength = (state: AppState) =>
  getUserPreferenceData(state).accountNameLength;

export const getDefaultHedgeType = (state: AppState) =>
  getUserPreferenceData(state).defaultHedgeType;

export const getDefaultPriceType = (state: AppState) =>
  getUserPreferenceData(state).defaultPriceType;

export const getUserOptionAlignCurrencies = (state: AppState) =>
  getUserPreferenceData(state).optionAlignCurrencies;

export const getUserPreferenceData = (state: AppState) => getUserPreference(state).data;

const getUserPreference = (state: AppState) => state.userPreferences;
