import type { Thunk } from 'state';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import { fieldData } from 'utils/fieldSelectors';

export function accumulatorTileClosedThunk(quoteId: string, automatically = false): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    if (!sl.isTilePresent(state, quoteId)) {
      return;
    }
    const tabId = sl.getClientWorkspaceIdByQuoteId(state, quoteId);
    dispatch(ac.accumulatorRfsCancelThunk(quoteId));
    const lastStreamId = sl.getAccumulatorLastStreamId(state, quoteId);
    if (lastStreamId !== null) {
      dispatch(ac.accumulatorRfsLastRemoved(quoteId, lastStreamId));
    }
    dispatch(ac.espTileStreamUnsubscribeThunk(quoteId));
    dispatch(ac.clientWorkspaceTileDeleted(quoteId, tabId, automatically));
  };
}

export function accumulatorAmountCurrencyMaskChangedThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    const oldAmountCurrency = fieldData(sl.getAccumulatorAmountCurrency(state, quoteId)).data;
    const amountCurrency = ((oldAmountCurrency % 2) + 1) as CurrencyChoice;
    dispatch(ac.accumulatorPropertiesChanged(quoteId, { amountCurrency }));
  };
}

export function accumulatorClientChangedThunk(quoteId: string, clientId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const companyId = sl.getCompanyIdFromClientId(getState(), clientId);
    dispatch(ac.accumulatorRfsCancelThunk(quoteId));
    dispatch(ac.espStreamRestartThunk(quoteId, undefined, undefined, companyId));
  };
}

export function accumulatorTileStateCleaned(tileId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    dispatch(ac.accumulatorRfsCancelThunk(tileId));

    const lastStreamId = sl.getAccumulatorLastStreamId(getState(), tileId);
    if (lastStreamId !== null) {
      dispatch(ac.accumulatorRfsLastRemoved(tileId, lastStreamId));
    }

    dispatch(ac.espTileStreamUnsubscribeThunk(tileId));
  };
}
