import { tileWrapper } from 'components/Form/Inputs/e2e';
import { TestUtilSpan } from 'components/share/TestUtilSpan';
import { changeFocusOnKeyDown } from 'components/share/changeFocusOnKeyDown';

function onSubmitPreventDefault(e: React.FormEvent<HTMLFormElement>) {
  e.preventDefault();
}

interface TileWrapperProps {
  children: React.ReactNode;
}

export function TileWrapper({ children }: TileWrapperProps): JSX.Element {
  return (
    <article data-e2e={tileWrapper()}>
      <TestUtilSpan />
      <form onSubmit={onSubmitPreventDefault} onKeyDown={changeFocusOnKeyDown}>
        {children}
      </form>
    </article>
  );
}
