import type { Thunk } from 'state';
import type { UserPreferencesData } from '../userPreferencesModel';

export function userPreferencesDataReceivedThunk(data: Partial<UserPreferencesData>): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const optionOneWayQuotationDefault = !sl.isUserInternalSales(getState());
    dispatch(
      ac.userPreferenceDataReceived({
        optionOneWayQuotation: optionOneWayQuotationDefault,
        ...data,
      }),
    );
  };
}
