import { connect } from 'react-redux';
import type { AppState } from 'state/model';
import type { PredealCheck, PredealCheckStatusHealth } from 'state/share/predealChecksModel';
import { isUserInternalSales } from 'state/referenceData/referenceDataSelectors';
import { getTilePredealChecks } from 'state/sharedSelectors';

export interface IPredealChecksOwnProps {
  quoteId: string;
}

export interface IPredealChecksStateProps {
  predealChecks: readonly PredealCheck[];
  globalStatus: PredealCheckStatusHealth;
  isVisible: boolean;
}

function mapStateToProps(
  state: AppState,
  { quoteId }: IPredealChecksOwnProps,
): IPredealChecksStateProps {
  const isInternalSales = isUserInternalSales(state);
  const { predealChecks, globalStatus } = getTilePredealChecks(state, quoteId);

  return {
    predealChecks,
    globalStatus,
    isVisible: isInternalSales && !!predealChecks.length,
  };
}

export const connectPredealChecks = connect(mapStateToProps);
