import type { Thunk } from 'state';
import { distinct } from 'utils/array';
import { isDefined } from '@sgme/fp';

export function optionExpandAllThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    // const expiries = sl
    //   .getLegIdsOfMultilegOfOption(state, quoteId)
    //   .map(legId => {
    //     /** @todo expand all needs to be fixed for typed strategies in table view */
    //     return sl.getOptionVanillaLegExpiry(state, legId).value;
    //   })
    //   .filter(isDefined)
    //   .filter(distinct);

    // TODO: validate if it is OK for Vanilla of typed strategy
    const expiries = sl
      .getAllVanillaLegsOfOption(state, quoteId)
      .map(leg => leg.values.expiryDate)
      .filter(isDefined)
      .filter(distinct);

    dispatch(ac.optionMassExpand(quoteId, expiries));
  };
}
