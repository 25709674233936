import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { fieldData } from 'utils/fieldSelectors';

export interface CurrencyPairConnectOwnProps {
  quoteId: string;
}

export interface CurrencyPairConnectStateProps {
  ccy1: string;
  ccy2: string;
}

// you can whitelist selectors after implementation
export type CurrencyPairConnectSelectors = Selectors;
/*
export type CurrencyPairConnectSelectorsKeys = 'aSelector'; // example
export type CurrencyPairConnectSelectors = Pick<Selectors, CurrencyPairConnectSelectorsKeys>;
*/

export const mapStateToPropsCurrencyPair: MapStateToPropsHOF<
  CurrencyPairConnectStateProps,
  CurrencyPairConnectOwnProps,
  AppState,
  CurrencyPairConnectSelectors
> =
  sl =>
  (state, { quoteId }) => {
    const currencyPair = fieldData(sl.getTileCurrencyPair(state, quoteId)).data;
    if (currencyPair === null) {
      throw new Error(`Missing currency pair in tile ${quoteId}`);
    }
    const [ccy1, ccy2] = currencyPair.split('/');
    return { ccy1, ccy2 };
  };
