import { getBulkLegValue } from './factories';
export {
  getBulkLegState,
  getBulkSplitLegs,
  getBulkNettedLegs,
  getBulkNettedLegMargin,
  getBulkNettedLegWay,
} from './bulkLeg';

export const getBulkLegCurrency1 = getBulkLegValue('currency1');
export const getBulkLegCurrency2 = getBulkLegValue('currency2');
