import type { Reducer } from 'redux';
import type { Collection } from 'typings/utils';
import type { Action } from 'state/actions';

export const orderEmailsReducer: Reducer<Collection<readonly string[]>> = (
  state: Collection<readonly string[]> = {},
  action: Action,
): Collection<readonly string[]> => {
  if (action.type === 'ORDER_DEFAULT_EMAILS') {
    return { ...state, [action.bdrId]: action.emails };
  }
  return state;
};
