const defaultContext = (_font: string) => (text: string) => text.length * 10;

export const getMeasureTextWidthContext = (font: string) => {
  if (document === undefined || document.createElement === undefined) {
    return defaultContext(font);
  }

  const ctx = document.createElement('canvas').getContext('2d');
  if (ctx === null) {
    return defaultContext(font);
  }
  ctx.font = font;
  const ret = (text: string) => Math.ceil(ctx.measureText(text).width);
  /**
   * Because of a data race of font caracteristics and load delays
   * Browser needs to load the font once at import time
   * before first call at render time
   */
  ret('');
  return ret;
};
