import type { MapStateToPropsFactoryHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import { createSelector } from 'reselect';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';

export interface MifidPanelConnectOwnProps {}

export interface MifidPanelConnectStateProps {
  isVisible: boolean;
  negociations: readonly string[];
  isCreatingNewNegotiation: boolean;
}

export interface MifidPanelConnectDispatchProps {
  onClose(): void;
  onNewNegotiation(): void;
}

export type MifidPanelConnectSelectorsKeys =
  | 'isMifid2PanelVisible'
  | 'getMifid2PanelNegotiations'
  | 'isStartingMifid2Negotiation';
export type MifidPanelConnectSelectors = Pick<Selectors, MifidPanelConnectSelectorsKeys>;

export const mapStateToPropsMifidPanel: MapStateToPropsFactoryHOF<
  MifidPanelConnectStateProps,
  MifidPanelConnectOwnProps,
  AppState,
  MifidPanelConnectSelectors
> = sl => () => {
  const getNegotiationsIds = createSelector(sl.getMifid2PanelNegotiations, Object.keys);
  return state => ({
    isVisible: sl.isMifid2PanelVisible(state),
    negociations: getNegotiationsIds(state),
    isCreatingNewNegotiation: sl.isStartingMifid2Negotiation(state),
  });
};

export type MifidPanelConnectActionCreatorsKeys =
  | 'toggleMifid2Panel'
  | 'mifid2StartNewNegotiationEpic';
export type MifidPanelConnectActionCreators = Pick<
  ActionCreators,
  MifidPanelConnectActionCreatorsKeys
>;

export const mapDispatchToPropsMifidPanel: MapDispatchToPropsHOF<
  MifidPanelConnectDispatchProps,
  MifidPanelConnectOwnProps,
  MifidPanelConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  onClose() {
    dispatch(ac.toggleMifid2Panel(false));
  },
  onNewNegotiation() {
    dispatch(ac.mifid2StartNewNegotiationEpic());
  },
});
