import type { IFxOptionInputs } from '../model/optionProduct';
import type {
  FxOptionLegInputs,
  IFxOptionTypedStrategyLegInputs,
  IFxOptionTypedStrategyLegValues,
  IFxVanillaLegValues,
} from '../model/optionsLegs';
import { isEmpty } from '@sgme/fp';

export interface OptionLegAdded {
  type: 'OPTION_LEG_ADDED';
  optionId: string;
}

export function optionLegAdded(optionId: string): OptionLegAdded {
  return {
    type: 'OPTION_LEG_ADDED',
    optionId,
  };
}

export interface OptionLegDuplicated {
  type: 'OPTION_LEG_DUPLICATED';
  optionId: string;
  legId: string;
}

export function optionLegDuplicated(optionId: string, legId: string): OptionLegDuplicated {
  return {
    type: 'OPTION_LEG_DUPLICATED',
    optionId,
    legId,
  };
}

export interface OptionLegsImported {
  type: 'OPTION_LEGS_IMPORTED';
  optionId: string;
  patch: Partial<IFxOptionInputs> & {
    legs?: Record<string, Partial<FxOptionLegInputs>>;
  };
}

export function optionLegsImported(
  optionId: string,
  patch: Partial<IFxOptionInputs> & {
    legs?: Record<string, Partial<FxOptionLegInputs>>;
  },
): OptionLegsImported {
  return {
    type: 'OPTION_LEGS_IMPORTED',
    optionId,
    patch,
  };
}

export interface OptionLegRemoved {
  type: 'OPTION_LEG_REMOVED';
  optionId: string;
  legId: string;
}

export function optionLegRemoved(optionId: string, legId: string): OptionLegRemoved {
  return {
    type: 'OPTION_LEG_REMOVED',
    optionId,
    legId,
  };
}

export interface OptionLegPropertyChanged {
  type: 'OPTION_LEG_PROPERTY_CHANGED';
  optionId: string;
  legId: string;
  patch: Partial<FxOptionLegInputs>;
}

export function optionLegPropertyChanged(
  optionId: string,
  legId: string,
  patch: Partial<FxOptionLegInputs>,
): OptionLegPropertyChanged {
  const patchKeys = Object.keys(patch);
  if (isEmpty(patchKeys) || (patch as any)[patchKeys[0]] === undefined) {
    throw 'you must change at least one property';
  }

  return {
    type: 'OPTION_LEG_PROPERTY_CHANGED',
    optionId,
    legId,
    patch,
  };
}

export interface OptionLegPropertyRemoveError {
  type: 'OPTION_LEG_PROPERTY_REMOVE_ERROR';
  optionId: string;
  legId: string;
  keys: ReadonlyArray<keyof IFxVanillaLegValues | keyof IFxOptionTypedStrategyLegValues>;
}

export function optionLegPropertyRemoveError(
  optionId: string,
  legId: string,
  keys: ReadonlyArray<keyof IFxVanillaLegValues | keyof IFxOptionTypedStrategyLegValues>,
): OptionLegPropertyRemoveError {
  return {
    type: 'OPTION_LEG_PROPERTY_REMOVE_ERROR',
    optionId,
    legId,
    keys,
  };
}

export interface OptionLegsPropertyChanged {
  type: 'OPTION_LEGS_PROPERTY_CHANGED';
  optionId: string;
  patch: Record<string, Partial<FxOptionLegInputs> | IFxOptionTypedStrategyLegInputs>;
}

export function optionLegsPropertyChanged(
  optionId: string,
  patch: Record<string, Partial<FxOptionLegInputs> | IFxOptionTypedStrategyLegInputs>,
): OptionLegsPropertyChanged {
  const patchKeys = Object.keys(patch);
  if (isEmpty(patchKeys) || (patch as any)[patchKeys[0]] === undefined) {
    throw 'you must change at least one leg';
  }

  return {
    type: 'OPTION_LEGS_PROPERTY_CHANGED',
    optionId,
    patch,
  };
}

export interface OptionLegsCurrencyLinkedToggled {
  type: 'OPTION_LEG_CURRENCY_LINKED_TOGGLED';
  optionId: string;
}

export function optionLegsCurrencyLinkedToggled(optionId: string): OptionLegsCurrencyLinkedToggled {
  return {
    type: 'OPTION_LEG_CURRENCY_LINKED_TOGGLED',
    optionId,
  };
}

export interface OptionLegsPremiumPinToggled {
  type: 'OPTION_LEGS_PREMIUM_PIN_TOGGLED';
  quoteId: string;
}

export function optionLegsPremiumPinToggled(quoteId: string): OptionLegsPremiumPinToggled {
  return {
    type: 'OPTION_LEGS_PREMIUM_PIN_TOGGLED',
    quoteId,
  };
}
