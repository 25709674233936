import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { type AppState } from 'state/model';
import { QuoteIdContext } from '../../../contexts';
import { getQuoteInstrument } from 'state/clientWorkspace/selectors';
import {
  getAllVanillaLegIdsOfOption,
  getAllVanillaLegsOfOption,
  getFirstLegOfMultilegOfOption,
  getGreeksFromStream,
  getOptionActiveRfsId,
  getOptionDisplayPriceType,
  getOptionQuote,
} from 'state/fxOptions/selectors';
import { getSwapState } from 'state/fxSwaps/selectors';
import type { IGreeks } from 'state/fxOptions/fxOptionsModel';
import { DropdownItem } from 'reactstrap';
import { menuItem } from '../../../Form/Inputs/e2e';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuoteType } from '../../../share/hooks/useQuoteType';
import type { Instrument } from 'state/referenceData';
import type { CopyToClipBoardRawData } from './types';
import { formatAccumulatorRawData, formatOptionRawData, formatSwapRawData } from './formatRawData';
import { getSwapQuoteData, getSwapStateData } from './swapData';
import { getAccumulatorRfsQuoteByQuoteId, getAccumulatorState } from 'state/fxAccumulators/selectors';

const selectCopyToClipBoardRawData = (state: AppState, quoteId: string): CopyToClipBoardRawData => {
  const instrument = getQuoteInstrument(state, quoteId);

  if (instrument === 'Option') {
    let greeks: Readonly<IGreeks> | null = null;
    const productName = getFirstLegOfMultilegOfOption(state, quoteId).productName;
    const vanillaLegs = getAllVanillaLegsOfOption(state, quoteId);
    const legValues = vanillaLegs.map(vanillaLeg => vanillaLeg.values);
    const vanillaLegsId = getAllVanillaLegIdsOfOption(state, quoteId);

    const quote = getOptionQuote(state, quoteId);
    const [displayPriceCurrency] = getOptionDisplayPriceType(state, quoteId);

    if (quote) {
      const optionId = vanillaLegsId[0].split('/')[0];
      const currentStreamId = getOptionActiveRfsId(state, optionId);

      if (currentStreamId) {
        greeks = getGreeksFromStream(state, currentStreamId, legValues[0]?.notionalCurrency);
      }
    }

    return {
      vanillaLegsId,
      greeks,
      quote,
      legValues,
      productName,
      displayPriceCurrency,
      instrument,
    };
  }

  if (instrument === 'Swap') {
    const swapState = getSwapState(state, quoteId);
    const swapStateData = getSwapStateData(swapState);
    const swapQuoteData = getSwapQuoteData(state, quoteId);

    return {
      instrument,
      swapStateData,
      swapQuoteData,
    };
  }

  if (instrument === 'Accumulator') {
    const accumulatorQuoteData = getAccumulatorRfsQuoteByQuoteId(state, quoteId);
    const accumulatorStateData = getAccumulatorState(state, quoteId);

    return { accumulatorQuoteData, accumulatorStateData, instrument };
  }

  throw Error(`instrument ${instrument} is not supported`);
};

const useGetFormattedDataToCopy = (rawData: CopyToClipBoardRawData) => {
  const { formatNumber } = useIntl();

  switch (rawData.instrument) {
    case 'Option':
      return () => navigator.clipboard.writeText(formatOptionRawData(rawData, formatNumber));
    case 'Swap':
      return () => navigator.clipboard.writeText(formatSwapRawData(rawData));
    case 'Accumulator':
      return () => navigator.clipboard.writeText(formatAccumulatorRawData(rawData, formatNumber));
  }
};

const CopyToClipBoardDropDown = () => {
  const quoteId = useContext(QuoteIdContext);
  const rawData = useSelector((state: AppState) => selectCopyToClipBoardRawData(state, quoteId));

  let hideClipBoard = false;

  if (rawData?.instrument === 'Option') {
    hideClipBoard = rawData.vanillaLegsId.length > 1 && rawData.productName === 'Vanilla';
  }

  const onCopyDataTileToClipboard = useGetFormattedDataToCopy(rawData);

  return hideClipBoard ? null : (
    <DropdownItem onClick={onCopyDataTileToClipboard} data-e2e={menuItem('CopyToClipBoard')}>
      <FormattedMessage id="tile.copyToClipboard" />
    </DropdownItem>
  );
};

const CopyToClipBoard = () => {
  const quoteId = useContext(QuoteIdContext);
  const instrument = useQuoteType(quoteId);
  const supportedInstruments: Instrument[] = ['Option', 'Swap', 'Accumulator'];

  return supportedInstruments.includes(instrument) ? <CopyToClipBoardDropDown /> : null;
};

export default CopyToClipBoard;
