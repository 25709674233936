import type { Thunk } from 'state';
import type { Way } from 'state/share/productModel/litterals';

export function optionExecutionRequestedThunk(optionId: string, way: Way): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const canTrade = sl.userCanTrade(state) && sl.isStreamingConnected(state);

    if (canTrade !== true) {
      return;
    }

    const currentStreamId = sl.getOptionRfsStreamId(state, optionId);

    if (currentStreamId === null) {
      return;
    }

    const streamState = sl.getOptionStreamState(state, currentStreamId);

    if (streamState.status !== 'PRICING' || streamState.quote.quoteType !== 'Tradeable') {
      return;
    }

    dispatch(ac.optionExecutionRequested(optionId, currentStreamId, way));
  };
}
