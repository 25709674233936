import { connectNDCheckbox } from './connect';
import { withQuoteId } from 'components/contexts';
import { Checkbox } from "@sgme/ui";

interface NDCheckboxProps {
  quoteId: string;
  value: boolean;
  show: boolean;
  disabled: boolean;
  label: string;
  className?: string;
  onChange: (value: boolean) => void;
}

const NDCheckboxRaw: React.FunctionComponent<NDCheckboxProps> = ({
  show,
  disabled,
  onChange,
  value,
  label,
  className,
}) =>
  show ? (
    <Checkbox
      data-e2e="nd-checkbox"
      checked={value}
      onCheckBoxChange={onChange}
      className={className}
      disabled={disabled}
      label={label}
    />
  ) : (
    <div></div>
  );
export const NDCheckbox = withQuoteId(connectNDCheckbox(NDCheckboxRaw));
