import type { FixingMarginType, IFxOrderInputs, OrderTemplate } from './fxOrdersModel';
import type { OrderValidationDetail } from 'api/order/oms';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import type { BlotterOrderValues, OrderErrorCode } from 'state/blotter/blotterEntryModel';
import type { BlotterOrderValidationDetail } from '../../epics/blotter/utils';

export type OrderAction =
  | OrderRequestEmailsEpic
  | OrderDefaultEmails
  | OrderEditingEmails
  | OrderStartDateNowChanged
  | OrderPropertyChanged
  | OrderPropertyChangedFromTemplate
  | OrderTemplateChanged
  | OrderValidationRequested
  | OrderValidationReceived
  | OrderValidationFailed
  | OrderAmountCurrencyChanged
  | OrderSubmission
  | OrderSubmissionSent
  | OrderSubmissionSuccess
  | OrderSubmissionStillPending
  | OrderSubmissionFailed
  | OrderErrorDismissed
  | OrderTemplateSaved
  | OrderTemplateUpdated
  | OrderTemplateApplied
  | OrderTemplateDeleted
  | OrderCancellationStarted
  | OrderCancellationSuccess
  | OrderCancellationStillPending
  | OrderCancellationFailed;

export interface OrderRequestEmailsEpic {
  type: 'ORDER_REQUEST_EMAILS_EPIC';
  bdrId: string;
  tabId: string;
}

export function orderRequestEmailsEpic(bdrId: string, tabId: string): OrderRequestEmailsEpic {
  return {
    type: 'ORDER_REQUEST_EMAILS_EPIC',
    bdrId,
    tabId,
  };
}

export interface OrderDefaultEmails {
  type: 'ORDER_DEFAULT_EMAILS';
  bdrId: string;
  emails: readonly string[];
}

export function orderDefaultEmails(bdrId: string, emails: readonly string[]): OrderDefaultEmails {
  return {
    type: 'ORDER_DEFAULT_EMAILS',
    bdrId,
    emails,
  };
}

export interface OrderEditingEmails {
  type: 'ORDER_EDITING_EMAILS';
  quoteId: string;
  editingEmails: boolean;
}

export function orderEditingEmails(quoteId: string, editingEmails: boolean): OrderEditingEmails {
  return {
    type: 'ORDER_EDITING_EMAILS',
    quoteId,
    editingEmails,
  };
}

export interface OrderStartDateNowChanged {
  type: 'ORDER_START_DATE_NOW_CHANGED';
  quoteId: string;
  isStartDateNow: boolean;
}

export function orderStartDateNowChanged(quoteId: string, isStartDateNow: boolean): OrderStartDateNowChanged {
  return {
    type: 'ORDER_START_DATE_NOW_CHANGED',
    quoteId,
    isStartDateNow,
  };
}

export interface OrderPropertyChanged {
  type: 'ORDER_PROPERTY_CHANGED';
  quoteId: string;
  patch: Partial<IFxOrderInputs>;
  isInternalSales?: boolean;
}

export function orderPropertyChanged(
  quoteId: string,
  patch: Partial<IFxOrderInputs>,
  isInternalSales: boolean | undefined = undefined,
): OrderPropertyChanged {
  return {
    type: 'ORDER_PROPERTY_CHANGED',
    quoteId,
    patch,
    isInternalSales,
  };
}

export interface OrderPropertyChangedFromTemplate {
  type: 'ORDER_PROPERTY_CHANGED_FROM_TEMPLATE';
  quoteId: string;
  patch: Partial<IFxOrderInputs>;
}

export function orderPropertyChangedFromTemplate(
  quoteId: string,
  patch: Partial<IFxOrderInputs>,
): OrderPropertyChangedFromTemplate {
  return {
    type: 'ORDER_PROPERTY_CHANGED_FROM_TEMPLATE',
    quoteId,
    patch,
  };
}

export interface OrderTemplateChanged {
  type: 'ORDER_TEMPLATE_CHANGED';
  quoteId: string;
  templateId: string;
}

export function orderTemplateChanged(quoteId: string, templateId: string): OrderTemplateChanged {
  return {
    type: 'ORDER_TEMPLATE_CHANGED',
    quoteId,
    templateId,
  };
}

export interface OrderValidationRequested {
  type: 'ORDER_VALIDATION_REQUESTED';
  quoteId: string;
}

export function orderValidationRequested(quoteId: string): OrderValidationRequested {
  return {
    type: 'ORDER_VALIDATION_REQUESTED',
    quoteId,
  };
}

export type OrderValidationReceived = {
  type: 'ORDER_VALIDATION_RECEIVED';
  quoteId: string;
  clockOffset?: number;
} & OrderValidationReceivedDetails<OrderValidationDetail>;

export interface OrderValidationReceivedDetails<T extends OrderValidationDetail | BlotterOrderValidationDetail> {
  validationDetails: readonly T[];
  isReadyToSubmit: boolean;
  expiryDay?: string | null | undefined;
  expiryTime?: string | null | undefined;
  maturityDay?: string | null | undefined;
  limitPrice?: number | null | undefined;
  customerPrice?: number | null | undefined;
  fixingMarginType?: FixingMarginType;
  fixingTypes: string[] | null;
  isCcyForcedBidAsk: boolean | null;
  isStandardGroup: boolean | null;
  fixingTimesOfSelectedType: string[] | null | undefined;
  sourceNameOfSelectedType: string[] | null | undefined;
  marginInBps: number | null;
  margin?: number | null;
  canModifyMarginInBps: boolean;
  ndfFixingDate: string | null | undefined;
  ndfFixingSource: string | null | undefined;
}

export const defaultOrderValidationReceivedDetails: OrderValidationReceivedDetails<OrderValidationDetail> = {
  validationDetails: [],
  isReadyToSubmit: false,
  expiryDay: undefined,
  expiryTime: undefined,
  maturityDay: undefined,
  limitPrice: null,
  fixingTypes: null,
  isCcyForcedBidAsk: null,
  isStandardGroup: null,
  fixingTimesOfSelectedType: null,
  sourceNameOfSelectedType: null,
  marginInBps: null,
  margin: null,
  canModifyMarginInBps: false,
  ndfFixingDate: null,
  ndfFixingSource: null,
  fixingMarginType: 'MarginInBps',
};

export function orderValidationReceived(
  quoteId: string,
  details: OrderValidationReceivedDetails<OrderValidationDetail>,
  clockOffset?: number,
): OrderValidationReceived {
  return {
    type: 'ORDER_VALIDATION_RECEIVED',
    quoteId,
    ...details,
    clockOffset,
  };
}

export interface OrderValidationFailed {
  type: 'ORDER_VALIDATION_FAILED';
  quoteId: string;
  error: string;
}

export function orderValidationFailed(quoteId: string, error: string): OrderValidationFailed {
  return {
    type: 'ORDER_VALIDATION_FAILED',
    error,
    quoteId,
  };
}

export interface OrderAmountCurrencyChanged {
  type: 'ORDER_AMOUNT_CURRENCY_CHANGED';
  quoteId: string;
  currencyChoice: CurrencyChoice;
}

export function orderAmountCurrencyChanged(
  quoteId: string,
  currencyChoice: CurrencyChoice,
): OrderAmountCurrencyChanged {
  return {
    type: 'ORDER_AMOUNT_CURRENCY_CHANGED',
    quoteId,
    currencyChoice,
  };
}

export interface OrderSubmission {
  type: 'ORDER_SUBMISSION_EPIC';
  quoteId: string;
}

export function orderSubmissionEpic(quoteId: string): OrderSubmission {
  return {
    type: 'ORDER_SUBMISSION_EPIC',
    quoteId,
  };
}

export interface OrderSubmissionSent {
  type: 'ORDER_SUBMISSION_SENT';
  quoteId: string;
}

export function orderSubmissionSent(quoteId: string): OrderSubmissionSent {
  return {
    type: 'ORDER_SUBMISSION_SENT',
    quoteId,
  };
}

export interface OrderSubmissionSuccess {
  type: 'ORDER_SUBMISSION_SUCCESS';
  quoteId: string;
  orderId: string | null;
  receivedDate: number;
  order: Omit<BlotterOrderValues, 'updateTime'>;
}

export function orderSubmissionSuccess(
  quoteId: string,
  orderId: string | null,
  receivedDate: number,
  order: Omit<BlotterOrderValues, 'updateTime'>,
): OrderSubmissionSuccess {
  return {
    type: 'ORDER_SUBMISSION_SUCCESS',
    quoteId,
    orderId,
    receivedDate,
    order,
  };
}

export interface OrderSubmissionStillPending {
  type: 'ORDER_SUBMISSION_STILL_PENDING';
  quoteId: string;
}

export function orderSubmissionStillPending(quoteId: string): OrderSubmissionStillPending {
  return {
    type: 'ORDER_SUBMISSION_STILL_PENDING',
    quoteId,
  };
}

export interface OrderSubmissionFailed {
  type: 'ORDER_SUBMISSION_FAILED';
  quoteId: string;
  errorCode: OrderErrorCode;
  reason?: string;
}

export function orderSubmissionFailed(
  quoteId: string,
  errorCode: OrderErrorCode,
  reason?: string,
): OrderSubmissionFailed {
  return {
    type: 'ORDER_SUBMISSION_FAILED',
    quoteId,
    errorCode,
    reason,
  };
}

export interface OrderErrorDismissed {
  type: 'ORDER_ERROR_DISMISSED';
  quoteId: string;
}

export function orderErrorDismissed(quoteId: string): OrderErrorDismissed {
  return {
    type: 'ORDER_ERROR_DISMISSED',
    quoteId,
  };
}

export interface OrderTemplateSaved {
  type: 'ORDER_TEMPLATE_SAVED';
  template: OrderTemplate;
}

export function orderTemplateSaved(template: OrderTemplate): OrderTemplateSaved {
  return {
    type: 'ORDER_TEMPLATE_SAVED',
    template,
  };
}

export interface OrderTemplateApplied {
  type: 'ORDER_TEMPLATE_APPLIED';
  templateId: string;
}

export function orderTemplateApplied(templateId: string): OrderTemplateApplied {
  return {
    type: 'ORDER_TEMPLATE_APPLIED',
    templateId,
  };
}

export interface OrderTemplateUpdated {
  type: 'ORDER_TEMPLATE_UPDATED';
  id: OrderTemplate['id'];
  fields: OrderTemplate['fields'];
}

export function orderTemplateUpdated(id: OrderTemplate['id'], fields: OrderTemplate['fields']): OrderTemplateUpdated {
  return {
    type: 'ORDER_TEMPLATE_UPDATED',
    id,
    fields,
  };
}

export interface OrderTemplateDeleted {
  type: 'ORDER_TEMPLATE_DELETED';
  templateId: string;
}

export function orderTemplateDeleted(templateId: string): OrderTemplateDeleted {
  return {
    type: 'ORDER_TEMPLATE_DELETED',
    templateId,
  };
}

export interface OrderCancellationStarted {
  type: 'ORDER_CANCELLATION_STARTED';
  quoteId: string;
}

export function orderCancellationStarted(quoteId: string): OrderCancellationStarted {
  return {
    type: 'ORDER_CANCELLATION_STARTED',
    quoteId,
  };
}

export interface OrderCancellationSuccess {
  type: 'ORDER_CANCELLATION_SUCCESS';
  quoteId: string;
}

export function orderCancellationSuccess(quoteId: string): OrderCancellationSuccess {
  return {
    type: 'ORDER_CANCELLATION_SUCCESS',
    quoteId,
  };
}

export interface OrderCancellationStillPending {
  type: 'ORDER_CANCELLATION_STILL_PENDING';
  quoteId: string;
}

export function orderCancellationStillPending(quoteId: string): OrderCancellationStillPending {
  return {
    type: 'ORDER_CANCELLATION_STILL_PENDING',
    quoteId,
  };
}

export interface OrderCancellationFailed {
  type: 'ORDER_CANCELLATION_FAILED';
  quoteId: string;
  errorCode: OrderErrorCode;
}

export function orderCancellationFailed(quoteId: string, errorCode: OrderErrorCode): OrderCancellationFailed {
  return {
    type: 'ORDER_CANCELLATION_FAILED',
    quoteId,
    errorCode,
  };
}
