import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { AppState } from 'state/model';
import type { OmsOrderSimpleRequest } from 'api/order/oms';
import { fieldData } from 'utils/fieldSelectors';
import { getBlotterOrderProduct } from 'state/blotter/selectors/blotterOrderSelectors';

const orderMetaSelectors = { getBlotterOrderProduct };
export type OrderMetaSelectors = typeof orderMetaSelectors;

export const metaSelectorBlotterOrderSimpleRequestData: MapStateToMetadataHOF<
  OmsOrderSimpleRequest,
  { orderId: string },
  AppState,
  OrderMetaSelectors
> =
  sl =>
  (state, { orderId }) => ({
    orderType: fieldData(sl.getBlotterOrderProduct(state, orderId)).data,
    payload: {
      orderRef: orderId,
    },
  });

export const getBlotterOrderSimpleRequest =
  metaSelectorBlotterOrderSimpleRequestData(orderMetaSelectors);
