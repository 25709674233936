import type { Collection } from 'typings/utils';
import type { IFormData } from '../share/form';
import type { CurrencyChoice, Side } from '../share/productModel/litterals';
import { makeValuesPredicate } from '@sgme/fp';
import { strictEntries } from 'utils/object/entries';

export const limitOrderTypes = ['TakeProfit', 'StopLoss', 'Call', 'Fixing'] as const;
export type LimitOrderType = (typeof limitOrderTypes)[number];
export const isLimitOrder = makeValuesPredicate(limitOrderTypes);
export const isFixingOrder = makeValuesPredicate(['Fixing']);

export const algoOrderTypes = ['Twap', 'Nightjar', 'Falcon'] as const;
export type AlgoOrderType = (typeof algoOrderTypes)[number];
export const isAlgoOrder = makeValuesPredicate(algoOrderTypes);

export const orderTypes = [...limitOrderTypes, ...algoOrderTypes] as const;
export type OrderType = (typeof orderTypes)[number];

export const fixingPriceTypes = ['MID', 'BIDASK'] as const;
export type FixingPriceType = (typeof fixingPriceTypes)[number];

export const fixingMarginTypes = ['BidAskMidSpread', 'MarginInBps'] as const;
export type FixingMarginType = (typeof fixingMarginTypes)[number];

export type OrderGroup = 'Limit' | 'Algo';

const orderTypeByGroupMap: Record<OrderGroup, readonly OrderType[]> = {
  Limit: limitOrderTypes,
  Algo: algoOrderTypes,
};

export const orderTypeByGroup = strictEntries(orderTypeByGroupMap);

export const triggers = ['Spot', 'BidOffer', 'RateOneTouch'] as const;
export type Trigger = (typeof triggers)[number];
export const clippingModes = ['Automatic', 'Manual', 'Incremental', 'Decremental', 'Splayed'] as const;
export type ClippingMode = (typeof clippingModes)[number];

const liquidityPools = ['Internal', 'Markets', 'Curex', 'FXSpotStream'] as const;
const liquidityPoolsFalcon = ['Internal', 'Markets', 'Curex', 'FXSpotStream'] as const;
export type LiquidityPool = (typeof liquidityPools)[number];
export type LiquidityPoolsFalcon = (typeof liquidityPoolsFalcon)[number];

export function getOrderLiquidityPoolOptions(orderType: OrderType): readonly LiquidityPool[] {
  switch (orderType) {
    case 'Falcon':
      return liquidityPoolsFalcon;
    default:
      return liquidityPools;
  }
}

export const speeds = ['VerySlow', 'Slow', 'Normal', 'Fast', 'VeryFast'] as const;
export const speedsFalcon = ['Slow', 'Normal', 'Fast'] as const;
export type Speed = (typeof speeds)[number];
export type SpeedFalcon = (typeof speedsFalcon)[number];

// OMS currently manage only these currencies in algo order
export const allowedNdfCurrencies = ['USD/KRW', 'USD/TWD', 'USD/INR', 'USD/PHP'] as const;

export interface IFxOrderValues {
  alphaSeeker: boolean;
  currencyPair: string | null;
  currency1: string | null;
  currency2: string | null;
  type: OrderType;
  amount: number | null;
  amountCurrency: CurrencyChoice;
  expiryDay: string | null;
  expiryDayTenor: string | null;
  expiryTime: string | null;
  limitPrice: string | null;
  customerPrice: string | null;
  way: Side;
  trigger: Trigger;
  emailsOrders: string | null;
  emails: string | null;
  isGtc: boolean;
  maturityDate: string | null;
  tenor: string | null;
  startDate: string | null;
  startTime: string | null;
  liquidityPool: readonly LiquidityPool[];
  speed: Speed;
  clippingMode: ClippingMode;
  clipSize: string | null;
  randomize: boolean;
  spreadCapture: boolean;
  fixingTypes: string[] | null;
  fixingBenchmark: string | null;
  isCcyForcedBidAsk: boolean | null;
  fixingPriceType: FixingPriceType | null;
  fixingDateUtc: string | null;
  isStandardGroup: boolean | null;
  fixingTimesOfSelectedType: string[] | null;
  fixingTime: string | null;
  sourceNameOfSelectedType: string[] | null;
  fixingPlace: string | null;
  fixingMarginType: FixingMarginType | null;
  marginInBps: number | null;
  margin: number | null;
  canModifyMarginInBps: boolean;
  ndfFixingDate: string | null;
  ndfFixingSource: string | null;
}

export interface IFxOrderInputs {
  alphaSeeker: boolean;
  currencyPair: string | null;
  amount: string | null;
  amountCurrency: CurrencyChoice;
  expiryDay: string | null;
  expiryDayTenor: string | null;
  expiryTime: string | null;
  type: OrderType;
  limitPrice: string | null;
  customerPrice: string | null;
  way: Side;
  trigger: Trigger;
  emails: string | null;
  emailsOrders: string | null;
  isGtc: boolean;
  maturityDate: string | null;
  tenor: string | null;
  startDate: string | null;
  startTime: string | null;
  liquidityPool: readonly LiquidityPool[];
  speed: Speed;
  clippingMode: ClippingMode;
  clipSize: string | null;
  randomize: boolean;
  spreadCapture: boolean;
  fixingBenchmark: string;
  fixingPriceType: FixingPriceType | null;
  fixingDateUtc: string | null;
  fixingTime: string | null;
  fixingPlace: string | null;
  fixingMarginType: FixingMarginType | null;
  marginInBps: number | null;
  margin: number | null;
  ndfFixingDate: string | null;
  ndfFixingSource: string | null;
}

export type IFxOrderProperties = IFormData<IFxOrderValues, IFxOrderInputs>;

export type OrderStatus =
  | null
  | 'PropertiesRequested'
  | 'PropertiesError'
  | 'Pending'
  | 'Success'
  | 'StillPending'
  | 'Timeouted'
  | 'Failed';

export const finalStatuses = ['Done', 'Finished', 'Cancelled', 'Expired', 'Partially done', 'InError', 'Rejected'];

export interface IFxOrderMetadata {
  currentExecutionId: string | null;
  currentEspStreamId: string | null;
  isReadyToSubmit: boolean;
  editingEmails: boolean;
  isStartDateNow: boolean;
  orderStatus: OrderStatus;
  propertiesRequestError: string | null;
  currentTemplateId?: string;
  clockOffset?: number;
}

export type FxOrderState = Readonly<IFxOrderProperties & IFxOrderMetadata>;

export const emptyFxOrderState: FxOrderState = {
  values: {
    alphaSeeker: false,
    currencyPair: null,
    amount: null,
    amountCurrency: 1,
    currency1: null,
    currency2: null,
    expiryDay: null,
    expiryDayTenor: null,
    expiryTime: null,
    type: 'StopLoss',
    way: 'Sell',
    limitPrice: null,
    trigger: 'Spot',
    emails: null,
    emailsOrders: null,
    isGtc: true,
    customerPrice: null,
    maturityDate: null,
    tenor: null,
    clippingMode: 'Automatic',
    clipSize: null,
    startDate: null,
    startTime: null,
    liquidityPool: [],
    speed: 'Normal',
    randomize: true,
    spreadCapture: true,
    fixingBenchmark: null,
    fixingTypes: null,
    isCcyForcedBidAsk: null,
    fixingPriceType: null,
    fixingDateUtc: null,
    fixingPlace: null,
    fixingTime: null,
    fixingTimesOfSelectedType: null,
    isStandardGroup: null,
    sourceNameOfSelectedType: null,
    canModifyMarginInBps: false,
    fixingMarginType: null,
    marginInBps: null,
    margin: null,
    ndfFixingDate: null,
    ndfFixingSource: null,
  },
  inputs: {},
  errors: {},
  warnings: {},
  isReadyToSubmit: false,
  editingEmails: false,
  orderStatus: null,
  isStartDateNow: true,
  propertiesRequestError: null,
  currentExecutionId: null,
  currentEspStreamId: null,
  clockOffset: undefined,
};

type IFxOrderStateMap = Collection<FxOrderState>;
export type FxOrderStateMap = Readonly<IFxOrderStateMap>;

export interface OrderTemplate {
  id: string;
  name: string;
  fields: Partial<IFxOrderInputs>;
}

interface IFxOrdersState {
  orders: FxOrderStateMap;
  defaultEmailsByBdrId: Collection<readonly string[]>;
  templates: Collection<OrderTemplate>;
}

export type FxOrdersState = Readonly<IFxOrdersState>;
