import { type ReactNode, createElement, Fragment } from 'react';
import type { ElementProps } from 'typings/utils';

export const tagWithProps =
  <K extends keyof JSX.IntrinsicElements>(tag: K) =>
  (constantProps: ElementProps<JSX.IntrinsicElements[K]> = {}) =>
  (...nodes: readonly ReactNode[]) =>
    createElement(
      tag,
      constantProps,
      nodes.map((node, key) => createElement(Fragment, { key }, node)),
    );
