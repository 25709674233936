import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { CashRfsExecutePayload } from 'api/multipass/cash/rfs/cashRfsQuoteExecution';
import { getRelevantPriceFromBidAsk } from 'state/share/productModel/utils';
import type { Way } from 'state/share/productModel/litterals';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import { getDateForMultipass } from 'utils/dateFormats';
import { assertIsPricing } from 'state/fxCashs/fxCashsModel';
import { isEmpty } from '@sgme/fp';

export const cashRfsExecuteRequestWith: MapStateToMetadataHOF<
  CashRfsExecutePayload,
  { cashId: string; way: Way; streamId: string; tradeDate: Date },
  AppState,
  Selectors
> =
  sl =>
  (state, { cashId, way, streamId: rfsId, tradeDate }) => {
    const streamState = sl.getCashRfsStreamState(state, rfsId);
    assertIsPricing(streamState);
    const { quoteId, spotWithoutMargin: fullSpotWithoutMargin } = streamState.quote;

    const fullSpotWithMargin = sl.getCashRfsSpotPriceWithMargin(state, cashId);
    const forwardPtsWithMargin = sl.getCashRfsForwardPointsWithMargin(state, cashId);

    const replyToStream = sl.getConnectionId(state);

    const spotWithoutMargin = getRelevantPriceFromBidAsk(fullSpotWithoutMargin, way);
    const spotWithMargin = getRelevantPriceFromBidAsk(fullSpotWithMargin, way);
    const forwardPoints = getRelevantPriceFromBidAsk(forwardPtsWithMargin, way);
    const { emails, splitNotificationsEmailsCash, splitNotifications } =
      sl.getUserPreferenceData(state);
    let emailNotificationList;
    if (splitNotifications && !isEmpty(splitNotificationsEmailsCash)) {
      emailNotificationList = splitNotificationsEmailsCash.join(';');
    } else {
      emailNotificationList = emails.join(';');
    }
    const userLocalDateTime = getDateForMultipass(tradeDate);
    const lastQuoteFromBackTimestamp = streamState.quote.backTimestamp;
    const lastQuoteReceivedByFrontTimestamp = streamState.quote.frontTimestamp.getTime();
    const executionClickByFrontTimestamp = tradeDate.getTime();

    return {
      replyToStream,
      rfsId,
      quoteId,
      way,
      spotWithoutMargin,
      spotWithMargin,
      forwardPoints,
      emailNotificationList,
      userLocalDateTime,
      lastQuoteFromBackTimestamp,
      lastQuoteReceivedByFrontTimestamp,
      executionClickByFrontTimestamp,
    };
  };
