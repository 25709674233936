import { assertUnreachable } from '@sgme/fp';
import type { Updater } from '../typings';
import { composeLeftFunction } from 'utils/composer';
import type { SavedWorkspace } from './model/savedWorkspaceModel';
import {
  defaultSavedBlotter,
  columnsDefinition,
  type BlotterTab,
  type ColumnId,
} from './model/blotterModel';

const currentVersion = 1;

export const updater1: Updater<any, SavedWorkspace> = {
  version: currentVersion,
  canUpdate(data: any) {
    return !('version' in data);
  },
  update: (previousWorkspace: any) => {
    const operations = composeLeftFunction(
      tryToUpdateFormat,
      discardIfOldFormat,
      defaultTabType,
      addBlotterColumn('portfolio', 'cash'),
      addBlotterColumn('portfolio', 'option'),
      addBlotterColumn('limitPrice', 'order'),
      addBlotterColumn('customerPrice', 'order'),
      removeBlotterColumn('orderRate', 'order'),
      removeBlotterColumn('proximityRate', 'order'),
      addBlotterColumn('executionPrice', 'order', 'amountDone'),
      addBlotterColumn('premiumCurrency', 'option', 'premium'),
      addBlotterColumn('xone', 'cash', 'id'),
    );

    const savedWorkspace: SavedWorkspace = operations(previousWorkspace);
    savedWorkspace.version = 1;

    return savedWorkspace;
  },
};

function tryToUpdateFormat(previousWorkspace: any): any | null {
  if (previousWorkspace === null) {
    return null;
  }
  if (Array.isArray(previousWorkspace)) {
    return {
      canTrade: true,
      tabs: previousWorkspace,
      blotter: defaultSavedBlotter,
    };
  }
  if (previousWorkspace.blotter === undefined) {
    const blotter = {
      ...defaultSavedBlotter,
      panelHeight: (previousWorkspace as any).blotterPanelHeight || defaultSavedBlotter.panelHeight,
    };

    return {
      canTrade: previousWorkspace.canTrade,
      tabs: previousWorkspace.tabs,
      blotter,
    };
  }

  return previousWorkspace;
}

function discardIfOldFormat(previousWorkspace: any | null): any | null {
  if (previousWorkspace === null) {
    return null;
  }
  if (!('tabs' in previousWorkspace)) {
    return null;
  }
  if (
    !previousWorkspace.tabs ||
    (previousWorkspace.tabs as readonly any[]).some(({ tiles }) =>
      (tiles as readonly any[]).some(tile => tile.size === undefined),
    )
  ) {
    return null;
  }
  return previousWorkspace;
}

function defaultTabType(savedWorkspace: any | null): any | null {
  if (savedWorkspace === null) {
    return null;
  }
  return {
    ...savedWorkspace,
    tabs: (savedWorkspace.tabs as readonly any[]).map(tab =>
      tab.tabType ? tab : { ...tab, tabType: 'tiles' },
    ),
  };
}

const removeBlotterColumn =
  (columnId: string, blotterTab: BlotterTab): ((savedWorkspace: any | null) => any | null) =>
  savedWorkspace => {
    if (savedWorkspace === null) {
      return null;
    }

    const tabToModify = getSavedTab(blotterTab);

    const blotterStateToModify =
      savedWorkspace.blotter[tabToModify] || defaultSavedBlotter[tabToModify];

    const newWorkspace = {
      ...savedWorkspace,
      blotter: {
        ...savedWorkspace.blotter,
        [tabToModify]: {
          ...blotterStateToModify,
          columnsState: blotterStateToModify.columnsState.filter(
            (column: { colId: string }) => column.colId !== columnId,
          ),
        },
      },
    };

    return newWorkspace;
  };

const addBlotterColumn =
  (
    columnId: ColumnId,
    blotterTab: BlotterTab,
    after?: ColumnId,
  ): ((savedWorkspace: any | null) => any | null) =>
  savedWorkspace => {
    if (savedWorkspace === null) {
      return null;
    }

    const addColumn = ({ columnsState: legacyColumnState, ...other }: any): any => {
      if (legacyColumnState.some((col: { colId: string }) => col.colId === columnId)) {
        return { ...other, columnsState: legacyColumnState };
      }

      if (after === undefined) {
        return {
          ...other,
          columnsState: [...legacyColumnState, columnsDefinition[columnId]],
        };
      }

      const index = legacyColumnState.findIndex(
        (value: { colId: string }) => value.colId === after,
      );
      if (index === -1) {
        return {
          ...other,
          columnsState: [...legacyColumnState, columnsDefinition[columnId]],
        };
      } else {
        legacyColumnState.splice(index + 1, 0, columnsDefinition[columnId]);
        return { ...other, columnsState: legacyColumnState };
      }
    };

    const tabToModify = getSavedTab(blotterTab);

    return {
      ...savedWorkspace,
      blotter: {
        ...savedWorkspace.blotter,
        [tabToModify]: addColumn(
          savedWorkspace.blotter[tabToModify] || defaultSavedBlotter[tabToModify],
        ),
      },
    };
  };

function getSavedTab(blotterTab: BlotterTab): 'cashTab' | 'optionTab' | 'orderTab' {
  switch (blotterTab) {
    case 'cash':
      return 'cashTab';
    case 'option':
      return 'optionTab';
    case 'order':
      return 'orderTab';
    default:
      assertUnreachable(blotterTab, 'One tab definition is missing');
  }
}
