import type { MapStateToPropsFactoryHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { Collection } from 'typings/utils';
import type { GridItemPosition, GridItemSize, Columns } from 'state/gridLayout/gridLayoutModels';
import { colWidth } from 'styles/constants';
import { createSelector } from 'reselect';

export interface GridContainerConnectOwnProps {
  tabId: string;
}
interface IGridContainerStateProps {
  gridItemPositions: Collection<GridItemPosition>;
  gridItemSizes: Collection<GridItemSize>;
  style: { width: number };
}

interface IGridContainerIdleStateProps {
  draggingStatus: 'IDLE';
  addTilePositions: readonly GridItemPosition[];
}

interface IGridContainerDraggingStateProps {
  draggingStatus: 'DRAGGING';
  gridItemPositions: Collection<GridItemPosition>;
  draggingGridItemId: string;
}

type GridContainerConnectStateProps = IGridContainerStateProps &
  (IGridContainerIdleStateProps | IGridContainerDraggingStateProps);

export interface GridContainerConnectDispatchProps {
  onDrag(gridItemId: string, newPostion: GridItemPosition): void;
  onDragEnd(): void;
}

// you can whitelist selectors after implementation
export type GridContainerConnectSelectors = Selectors;
/*
export type GridContainerConnectSelectorsKeys = 'aSelector'; // example
export type GridContainerConnectSelectors = Pick<Selectors, GridContainerConnectSelectorsKeys>;
*/

function getColumnsNumber(columns: Columns) {
  return columns.length;
}

export const mapStateToPropsGridContainer: MapStateToPropsFactoryHOF<
  GridContainerConnectStateProps,
  GridContainerConnectOwnProps,
  AppState,
  GridContainerConnectSelectors
> = sl => () => {
  const getStyle = createSelector(getColumnsNumber, columnsNumber => ({
    width: (columnsNumber + 2) * colWidth,
  }));
  return (state, { tabId }) => {
    const gridState = sl.getGridById(state, tabId);
    if (gridState.draggingStatus === 'IDLE') {
      return {
        style: getStyle(gridState.gridLayout.columns),
        draggingStatus: gridState.draggingStatus,
        gridItemPositions: gridState.gridLayout.gridItemPositions,
        gridItemSizes: gridState.gridLayout.gridItemSizes,
        addTilePositions: gridState.addTilePositions,
      };
    } else if (gridState.draggingStatus === 'DRAGGING') {
      return {
        style: getStyle(gridState.gridLayout.columns),
        draggingStatus: gridState.draggingStatus,
        gridItemPositions: gridState.draggingGridLayout.gridItemPositions,
        gridItemSizes: gridState.draggingGridLayout.gridItemSizes,
        draggingGridItemId: gridState.draggingGridItemId,
      };
    } else {
      throw Error('Unknow dragging status');
    }
  };
};

// you can whitelist action creators after implementation
export type GridContainerConnectActionCreators = ActionCreators;
/*
export type GridContainerConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type GridContainerConnectActionCreators = Pick<ActionCreators, GridContainerConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsGridContainer: MapDispatchToPropsHOF<
  GridContainerConnectDispatchProps,
  GridContainerConnectOwnProps,
  GridContainerConnectActionCreators
> =
  ac =>
  (dispatch, { tabId }) => ({
    onDrag(gridItemId: string, newPostion: GridItemPosition) {
      dispatch(ac.gridItemDragThunk(tabId, gridItemId, newPostion));
    },
    onDragEnd() {
      dispatch(ac.gridItemDragEnd(tabId));
    },
  });
