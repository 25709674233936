import { connect } from 'react-redux';
import { mapStateToPropsUserHasAccess } from './connectUserHasAccess';
import { selectors } from 'state/selectors';
import { mapStateToPropsUserHasProducts } from './connectUserHasProducts';
import {
  mapStateToPropsFeatureToggle,
  mapStateToPropsAtLeastFeatureToggle,
} from './connectFeatureToggle';
import { mapStateToPropsIsProductName } from './connectIsProductName';

export const connectUserHasAccess = connect(mapStateToPropsUserHasAccess(selectors));

export const connectUserHasProducts = connect(mapStateToPropsUserHasProducts(selectors));

export const connectFeatureToggle = connect(mapStateToPropsFeatureToggle(selectors));

export const connectAtLeastFeatureToggle = connect(mapStateToPropsAtLeastFeatureToggle(selectors));

export const connectIsProductName = connect(mapStateToPropsIsProductName(selectors));
