import { deriveStateOnChangedProps, type PreviousProps } from 'utils/stateProps';
import { input } from 'components/Form/Inputs/e2e';
import { Component, type FocusEvent, type KeyboardEvent, type ChangeEvent } from 'react';

export interface IInputProps {
  value: string;
  className: string;
  e2eHandle?: string;
  name?: string;
  autofocus?: boolean;
  onSubmit: (value: string) => void;
  onBlurPristine: () => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

interface IInputState extends PreviousProps<IInputProps> {
  value: string;
}

export class EnhancedInput extends Component<IInputProps, IInputState> {
  public static getDerivedStateFromProps = deriveStateOnChangedProps(
    (props: Readonly<IInputProps>, _state: Readonly<IInputState>) =>
      props.autofocus === true
        ? null
        : {
            value: props.value,
          },
  );
  public state = { value: this.props.value };

  private onChange = (e: ChangeEvent<HTMLInputElement>) => this.setState({ value: e.target.value });

  private afterUserInput = () => {
    if (this.state.value !== this.props.value) {
      this.props.onSubmit(this.state.value);
    } else {
      this.props.onBlurPristine();
    }
  };

  private onBlur = (e: FocusEvent<HTMLInputElement>) => {
    this.afterUserInput();
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(e);
    }
  };

  private onFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select();
    if (this.props.onFocus !== undefined) {
      this.props.onFocus(e);
    }
  };

  private onkeydown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Escape': {
        e.persist();
        e.stopPropagation();
        const blur = e.currentTarget.blur.bind(e.currentTarget);
        this.setState({ value: this.props.value }, blur);
        break;
      }
      case 'Enter': {
        e.stopPropagation();
        this.afterUserInput();
        break;
      }
    }
  };

  public render() {
    return (
      <input
        type="text"
        autoComplete="off"
        data-e2e={input(this.props.e2eHandle)}
        autoFocus={this.props.autofocus}
        className={this.props.className}
        name={this.props.name}
        value={this.state.value}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onKeyDown={this.onkeydown}
      />
    );
  }
}
