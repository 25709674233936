import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';
import type { FormDomain } from 'state/referenceData';
import type { ValueKeys } from 'state/share/productModel';

export interface FieldErrorTooltipConnectOwnProps {
  quoteId: string;
}

export interface FieldErrorTooltipConnectDispatchProps<I extends FormDomain> {
  setFieldError(instrument: I, field: ValueKeys<I>, error: string): void;
}

export type FieldErrorTooltipConnectActionCreators = ActionCreators;

/** @todo use localValidate */
export const mapDispatchToPropsFieldError: MapDispatchToPropsHOF<
  FieldErrorTooltipConnectDispatchProps<FormDomain>,
  FieldErrorTooltipConnectOwnProps,
  FieldErrorTooltipConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    setFieldError(instrument, field, messageId) {
      dispatch(ac.localFieldValidationSet(instrument, quoteId, field, 'invalid', messageId));
    },
  });
