import { setupSGWTConnect } from '@sgwt/connect-core';
import type { ISGWTConnectIdTokenClaims } from '@sgwt/connect-core/dist/src/SGWTConnectIdTokenClaims';
import type { ISGWTConnectOpenIDStandardClaims } from '@sgwt/connect-core/dist/src/SGWTConnectOpenIDUserInfo';

const originalSgwtConnect = setupSGWTConnect(window.sgmeConfiguration.sgConnect);

export const getFakeUser = (): string | undefined => {
  let user: string | undefined;
  const standardSgConnect = originalSgwtConnect;

  const params = new URLSearchParams(self.location.search);

  if (params.has('user')) {
    user = params.get('user') ?? undefined;
  } else if (window.sgmeConfiguration.env.toLowerCase().includes('demo')) {
    user = standardSgConnect.getIdTokenClaims()?.sub;
  } else if (window.sgmeConfiguration.useFakeSgConnect) {
    user = 'dow.jones@sgcib.com';
  }

  return user;
};

const getFakeOverridenSGConnect = () => {
  const standardSgConnect = originalSgwtConnect;
  const fakeUserEmail = getFakeUser()!;

  standardSgConnect.getIdTokenClaims = () => ({ sub: fakeUserEmail } as ISGWTConnectIdTokenClaims);

  const originalFetchUserInfo = standardSgConnect.fetchUserInfo.bind(standardSgConnect);
  standardSgConnect.fetchUserInfo = callback => {
    originalFetchUserInfo((error, claims) => {
      const fakeUser = {
        ...claims,
        sub: fakeUserEmail,
        mail: fakeUserEmail,
        name: getNameFromEmail(fakeUserEmail),
      } as ISGWTConnectOpenIDStandardClaims;
      callback(error, fakeUser);
    });
    return standardSgConnect;
  };

  return standardSgConnect;
};

export const sgwtConnect = window.sgmeConfiguration.useFakeSgConnect
  ? getFakeOverridenSGConnect()
  : originalSgwtConnect;

const getNameFromEmail = (email: string) =>
  email.split('@')[0].replace('-ext', '').replace('.', ' ');
