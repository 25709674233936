import type { Reducer } from 'redux';
import type { FxBulkStreamStateMap, FxBulkStreamState } from 'state/fxBulks/fxBulksModel';
import type { Action } from 'state/actions';
import { addKey, removeKey, updateKey } from 'utils/stateMap';
export const bulkStreamsReducer: Reducer<FxBulkStreamStateMap> = (
  state: FxBulkStreamStateMap = {},
  action: Action,
): FxBulkStreamStateMap => {
  switch (action.type) {
    case 'BULK_RFS_STARTED': {
      return addKey(state, action.streamId, {
        status: 'AWAITING',
      } as FxBulkStreamState);
    }
    case 'BULK_QUOTE_RECEIVED':
      return updateKey(
        state,
        action.streamId,
        currentStreamState =>
          ({
            status: 'PRICING',
            quote: action.quote,
            tiering: action.tiering,
            initialRfsWindow:
              currentStreamState.status === 'PRICING'
                ? currentStreamState.initialRfsWindow
                : action.quote.rfsWindow,
          } as FxBulkStreamState),
      );
    case 'BULK_RFS_CANCEL':
    case 'BULK_RFS_FAILED':
    case 'BULK_RFS_TERMINATED':
      return removeKey(state, action.streamId);
    case 'BULK_EXECUTION_SENT':
      return removeKey(state, action.executionId);
  }
  return state;
};
