import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';

export interface ChangeTabTypeButtonConnectOwnProps {
  tabId: string;
}

export interface ChangeTabTypeButtonConnectStateProps {
  tabType: string;
}

export interface ChangeTabTypeButtonConnectDispatchProps {
  toggleTabType(): void;
}

export type ChangeTabTypeButtonConnectSelectorsKeys = 'getClientWorkspaceTab';
export type ChangeTabTypeButtonConnectSelectors = Pick<
  Selectors,
  ChangeTabTypeButtonConnectSelectorsKeys
>;

export const mapStateToPropsChangeTabTypeButton: MapStateToPropsHOF<
  ChangeTabTypeButtonConnectStateProps,
  ChangeTabTypeButtonConnectOwnProps,
  AppState,
  ChangeTabTypeButtonConnectSelectors
> =
  sl =>
  (state, { tabId }) => ({
    tabType: sl.getClientWorkspaceTab(state, tabId).type,
  });

export type ChangeTabTypeButtonConnectActionCreatorsKeys = 'clientWorkspaceTabTypeToggleThunk';
export type ChangeTabTypeButtonConnectActionCreators = Pick<
  ActionCreators,
  ChangeTabTypeButtonConnectActionCreatorsKeys
>;

export const mapDispatchToPropsChangeTabTypeButton: MapDispatchToPropsHOF<
  ChangeTabTypeButtonConnectDispatchProps,
  ChangeTabTypeButtonConnectOwnProps,
  ChangeTabTypeButtonConnectActionCreators
> =
  ac =>
  (dispatch, { tabId }) => ({
    toggleTabType: () => dispatch(ac.clientWorkspaceTabTypeToggleThunk(tabId)),
  });
