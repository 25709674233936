import type { SchedulerLike, Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';

export function measuredDelay(now: () => number, delayMs: number, scheduler?: SchedulerLike) {
  return function operator<T>(source$: Observable<T>): Observable<readonly [T, number]> {
    return source$.pipe(
      map(t => [t, now()] as const),
      delay(delayMs, scheduler),
      map(([t, start]) => [t, now() - start] as const),
    );
  };
}
