import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { CollapsableKey } from 'state/clientWorkspace/clientWorkspaceModel';

export interface CollapsableConnectOwnProps {
  quoteId: string;
  keyName: CollapsableKey;
}

export interface CollapsableConnectStateProps {
  isCollapsed: boolean;
}

export interface CollapsableConnectDispatchProps {
  toggleCollapsed(): void;
}

export type CollapsableConnectSelectorsKeys = 'isKeyCollapsedInTile';
export type CollapsableConnectSelectors = Pick<Selectors, CollapsableConnectSelectorsKeys>;

export const mapStateToPropsCollapsable: MapStateToPropsHOF<
  CollapsableConnectStateProps,
  CollapsableConnectOwnProps,
  AppState,
  CollapsableConnectSelectors
> =
  sl =>
  (state, { quoteId, keyName }) => ({
    isCollapsed: sl.isKeyCollapsedInTile(state, quoteId, keyName),
  });

export type CollapsableConnectActionCreatorsKeys = 'toggleCollapsed';
export type CollapsableConnectActionCreators = Pick<
  ActionCreators,
  CollapsableConnectActionCreatorsKeys
>;

export const mapDispatchToPropsCollapsable: MapDispatchToPropsHOF<
  CollapsableConnectDispatchProps,
  CollapsableConnectOwnProps,
  CollapsableConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId, keyName }) => ({
    toggleCollapsed() {
      dispatch(ac.toggleCollapsed(quoteId, keyName));
    },
  });
