import { CheckboxUserPref } from './CheckboxUserPref';

// export const OneClickExecPref = CheckboxUserPref('oneClickExecution');
export const ShowSwapPointsPref = CheckboxUserPref('showSwapPoints');
export const SplitNotificationsPref = CheckboxUserPref('splitNotifications');
export const ResetTileToSpotOnQuoteExecutionPref = CheckboxUserPref(
  'resetTileOnSuccessfulExecution',
);
export const ShowDeltaStrikePref = CheckboxUserPref('showDeltaStrike');
export const OptionOneWayQuotationPref = CheckboxUserPref('optionOneWayQuotation');
export const OptionExpandGreekAndMktPref = CheckboxUserPref('optionExpandGreekAndMkt');
export const OptionDisplayMidVolatilityPref = CheckboxUserPref('optionDisplayMidVolatility');
export const OptionStrategyGroupLegsPref = CheckboxUserPref('optionStrategyGroupLegs');
export const OptionAlignCurrenciesPref = CheckboxUserPref('optionAlignCurrencies');
export const OrderQuickLimitPref = CheckboxUserPref('orderQuickLimit');
