import type { ActionCreators } from 'state/actions';
import type { ModeStatus, OrderResultMode } from 'state/blotter/blotterEntryModel';
import type { OrderStatus } from 'state/fxOrders/fxOrdersModel';
import type { AppState } from 'state/model';
import type { Selectors } from 'state/selectors';
import type { MapDispatchToPropsHOF, MapStateToPropsHOF } from 'typings/redux-utils';

interface ExecutionOverlayOwnProps {
  quoteId: string;
}

interface ExecutionOverlayStateProps {
  modeStatus: ModeStatus;
  mode: OrderResultMode;
}

interface ExecutionOverlayDispatchProps {
  onDismiss(): void;
  onViewLog(): void;
  onClose(): void;
}

function toModeStatus(orderStatus: OrderStatus): ModeStatus {
  switch (orderStatus) {
    case 'Pending':
      return 'Pending';
    case 'StillPending':
      return 'StillPending';
    case 'Timeouted':
      return 'StillPending';
    case 'Failed':
      return 'Error';
    case 'Success':
      return 'Success';
    default:
      return 'Idle';
  }
}

export const mapStateToPropsOrderResultOverlay: MapStateToPropsHOF<
  ExecutionOverlayStateProps,
  ExecutionOverlayOwnProps,
  AppState,
  Selectors
> =
  sl =>
  (state, { quoteId }) => {
    if (!sl.isInBlotterIntraday(state, quoteId)) {
      return {
        mode: 'Display',
        modeStatus: toModeStatus(sl.getOrderStatus(state, quoteId)),
      };
    }
    const order = sl.getBlotterOrderById(state, quoteId);
    return {
      mode: order.mode,
      modeStatus: order.modeStatus,
    };
  };

export const mapDispatchToPropsOrderResultOverlay: MapDispatchToPropsHOF<
  ExecutionOverlayDispatchProps,
  ExecutionOverlayOwnProps,
  ActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    onDismiss() {
      dispatch(ac.orderErrorDismissed(quoteId));
      dispatch(ac.blotterOrderToggleMode(quoteId, 'Edit'));
    },
    onViewLog() {
      dispatch(ac.displayPropertiesErrorDetail(quoteId));
    },
    onClose() {
      dispatch(ac.tileClosedThunk(quoteId));
    },
  });
