import type { UserPreferencesData } from './userPreferencesModel';

export type UserPreferencesAction =
  | UserPreferenceToggleModal
  | UserPreferenceDataReceived
  | UserPreferencesDataChanged
  | UserPreferencesDataNotSaved;

export interface UserPreferenceToggleModal {
  type: 'USER_PREFERENCES_TOGGLE_MODAL';
  open: boolean;
}

export function userPreferenceToggleModal(open: boolean): UserPreferenceToggleModal {
  return {
    type: 'USER_PREFERENCES_TOGGLE_MODAL',
    open,
  };
}

export interface UserPreferenceDataReceived {
  type: 'USER_PREFERENCES_DATA_RECEIVED';
  data: Partial<UserPreferencesData>;
}

export function userPreferenceDataReceived(
  data: Partial<UserPreferencesData>,
): UserPreferenceDataReceived {
  return {
    type: 'USER_PREFERENCES_DATA_RECEIVED',
    data,
  };
}

export interface UserPreferencesDataChanged {
  type: 'USER_PREFERENCES_DATA_CHANGED';
  patch: Partial<UserPreferencesData>;
}

export function userPreferenceDataChanged(
  patch: Partial<UserPreferencesData>,
): UserPreferencesDataChanged {
  return {
    type: 'USER_PREFERENCES_DATA_CHANGED',
    patch,
  };
}

/**
 * This action is only emitted for logging purposes for the time being
 */
export interface UserPreferencesDataNotSaved {
  type: 'USER_PREFERENCES_DATA_NOT_SAVED';
  patch: Partial<UserPreferencesData>;
}

export function userPreferencesDataNotSaved(
  patch: Partial<UserPreferencesData>,
): UserPreferencesDataNotSaved {
  return {
    type: 'USER_PREFERENCES_DATA_NOT_SAVED',
    patch,
  };
}
