import type { Way } from '../../share/productModel/litterals';
import type { LegType } from '../model/optionsLegs';

export interface OptionSolvingRequested {
  type: 'OPTION_SOLVING_REQUESTED';
  quoteId: string;
}

export function optionSolvingRequested(quoteId: string): OptionSolvingRequested {
  return {
    type: 'OPTION_SOLVING_REQUESTED',
    quoteId,
  };
}

export interface ToggleSolveStrikeModal {
  type: 'TOGGLE_SOLVE_STRIKE_MODAL';
  display: boolean;
  quoteId: string;
}

export function toggleSolveStrikeModal(quoteId: string, display: boolean): ToggleSolveStrikeModal {
  return {
    type: 'TOGGLE_SOLVE_STRIKE_MODAL',
    display,
    quoteId,
  };
}

export interface OptionSolvingWanted {
  type: 'OPTION_SOLVING_WANTED';
  quoteId: string;
  legId: string;
  field: 'strike';
}

export function optionSolvingWanted(
  quoteId: string,
  legId: string,
  field: 'strike',
): OptionSolvingWanted {
  return {
    type: 'OPTION_SOLVING_WANTED',
    quoteId,
    legId,
    field,
  };
}

export interface OptionSolvingStarted {
  type: 'OPTION_SOLVING_STARTED';
  quoteId: string;
  productName: LegType;
  premiumWay: Way;
  premiumAmount: string;
  isCompactView: boolean;
  hasMultilegDifferentLegSide: boolean;
  callSide?: 'Buy' | 'Sell';
}

export function optionSolvingStarted(
  quoteId: string,
  productName: LegType,
  premiumWay: Way,
  premiumAmount: string,
  isCompactView: boolean,
  hasMultilegDifferentLegSide: boolean,
  callSide?: 'Buy' | 'Sell',
): OptionSolvingStarted {
  return {
    type: 'OPTION_SOLVING_STARTED',
    quoteId,
    productName,
    premiumWay,
    premiumAmount,
    isCompactView,
    hasMultilegDifferentLegSide,
    callSide,
  };
}

export interface OptionStrikeSolved {
  type: 'OPTION_STRIKE_SOLVED';
  optionId: string;
  strikes: ReadonlyArray<[legId: string, strike: string | null]>;
}

export function optionStrikeSolved(
  optionId: string,
  strikes: ReadonlyArray<[string, string | null]>,
): OptionStrikeSolved {
  return {
    type: 'OPTION_STRIKE_SOLVED',
    optionId,
    strikes,
  };
}
