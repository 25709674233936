import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { UserType } from 'state/referenceData/referenceDataModel';

export interface UserTypeConnectOwnProps {}

export interface UserTypeConnectStateProps {
  userType: UserType;
}

export type UserTypeConnectSelectors = Selectors;

export const mapStateToPropsUserType: MapStateToPropsHOF<
  UserTypeConnectStateProps,
  UserTypeConnectOwnProps,
  AppState,
  UserTypeConnectSelectors
> = sl => (state, _ownProps) => ({
  userType: sl.getUserInfo(state).userType,
});
