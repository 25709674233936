import { Observable } from 'rxjs';
import { logger } from '../logging/logger';

type CrashEventSub = (err: Error, ...rest: readonly any[]) => any;

export const createCircuitBreaker = function () {
  let fused: boolean = false;
  let error: Error;
  let subs: CrashEventSub[] = [];

  function crash(err: Error, ...rest: readonly any[]) {
    if (fused === false) {
      error = err;
      fused = true;
      subs.forEach(cb => cb(err, ...rest));
      // release scope of callbacks
      subs = [];
    }
  }

  function onCrash(cb: CrashEventSub) {
    if (fused === true) {
      // immediately call back with last error
      cb(error);
    } else {
      subs.push(cb);
    }
  }

  function crashStatus() {
    return fused;
  }

  const crash$ = new Observable<Error>(obs =>
    onCrash(e => {
      logger.logDebug('Application terminated');
      obs.next(e);
      obs.complete();
    }),
  );
  return { crash, onCrash, crashStatus, crash$ };
};
