import type {
  AddEmail,
  AddEmails,
  RemoveEmail,
  TypeOfEmail,
  ActionTypeOfEmail,
} from './emailsInputModels';

export function add(email: string, typeOfState: TypeOfEmail, type: ActionTypeOfEmail): AddEmail {
  return { type: type.add, [typeOfState]: email };
}

export function adds(
  emails: readonly string[],
  typeOfState: TypeOfEmail,
  type: ActionTypeOfEmail,
): AddEmails {
  return { type: type.adds, [typeOfState]: emails };
}

export function remove(
  email: string,
  typeOfState: TypeOfEmail,
  type: ActionTypeOfEmail,
): RemoveEmail {
  return { type: type.remove, [typeOfState]: email };
}
