/**
 * @todo This is dirty as hell, but at the time there is no better option.
 * If you, reader from future, have one, feel free to nuke that.
 */
export function saveFile(fileName: string, file: Blob) {
  const url = URL.createObjectURL(file).toString();
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.rel = 'noopener';
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
