import type { Observable } from 'rxjs';
import type { HttpCall, ISgmeHttp } from 'utils/sgmeHttp';
import type { Way } from 'state/share/productModel/litterals';
import { transformEmailsToBase64 } from 'utils/base64';

export interface EspExecutePayload {
  executionId: string;
  multipassTargetInstance: string;
  dataStreamId: string;
  currency1: string;
  currency2: string;
  negotiatedCurrency: string;
  quoteId: string;
  counterPartyBdrId: number;
  way: Way;
  negotiatedAmount: number;
  valueDate: Date;
  streamDepth: number;
  clientSpotRate: number;
  emailNotificationList: string;
  userLocalDateTime: string;
  lastQuoteFromBackTimestamp: number;
  lastQuoteReceivedByFrontTimestamp: number;
  executionClickByFrontTimestamp: number;
  isNdf?: boolean;
}

export interface ICashEspQuoteExecuteRequestApi {
  msApiTimeout: number;
  executeQuoteRequest(payload: EspExecutePayload): Observable<HttpCall<void>>;
}

export function cashEspQuoteExecuteRequestApi(http: ISgmeHttp): ICashEspQuoteExecuteRequestApi {
  return {
    executeQuoteRequest(payload: EspExecutePayload) {
      return http.post<void>(
        'api/fxSpotFwd/esp/execute',
        transformEmailsToBase64(payload, 'emailNotificationList', 'email64NotificationList'),
        { 'X-Correlation-ID': payload.executionId },
      );
    },
    msApiTimeout: 60_000,
  };
}
