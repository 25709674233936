import type { GridState, GridItemPosition, GridIdleState } from '../gridLayoutModels';
import { defaultCashHeight, colWidth } from 'styles/constants';

export function updateAddTilePositions({
  gridLayout,
}: GridState): Pick<GridIdleState, 'addTilePositions'> {
  const addTilePositions: GridItemPosition[] = [];
  gridLayout.columns.forEach((column, columnIndex) => {
    let previousBottom = 0;
    column.forEach(position => {
      if (position.top - previousBottom >= defaultCashHeight) {
        const newAddTilePosition: GridItemPosition = {
          top: previousBottom,
          left: columnIndex * colWidth,
        };
        addTilePositions.push(newAddTilePosition);
      }
      previousBottom = position.bottom;
    });
    addTilePositions.push({
      top: previousBottom,
      left: columnIndex * colWidth,
    });
  });
  addTilePositions.push({
    top: 0,
    left: gridLayout.columns.length * colWidth,
  });

  return { addTilePositions };
}
