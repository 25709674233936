interface ChildrenProp {
  children: React.ReactNode;
}

export type RenderWhenProps<P extends string = 'condition'> = {
  [p in P]: boolean;
};

export function renderWhen<P extends string>(prop: P, whenTrue = true) {
  return function RenderWhenComp({
    [prop]: condition,
    children,
  }: RenderWhenProps<P> & ChildrenProp) {
    return condition === whenTrue ? (children as React.ReactElement<any>) : null;
  };
}

export const RenderWhen = renderWhen('condition');
