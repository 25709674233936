import { type ReactNode, useCallback, useState } from 'react';
import { getTheme, setTheme, type Theme as ThemeType } from 'utils/theme';
import { ThemeProvider } from 'components/contexts/ThemeContext';

interface ThemeProps {
  children: ReactNode;
}

export function Theme({ children }: ThemeProps) {
  const [theme, setThemeState] = useState(getTheme());
  const setThemeFunction = useCallback(
    (newTheme: ThemeType) => {
      setTheme(newTheme);
      setThemeState(newTheme);
    },
    [setThemeState],
  );
  const toggleTheme = useCallback(() => {
    setThemeFunction(theme === 'DARK' ? 'LIGHT' : 'DARK');
  }, [theme, setThemeFunction]);
  return (
    <ThemeProvider value={{ theme, setTheme: setThemeFunction, toggleTheme }}>
      {children}
    </ThemeProvider>
  );
}
