import type { OmsOrderSubmissionPayload, OmsOrderValidationResponse } from 'api/order/oms';
import type {
  BlotterEntry,
  BlotterOrderAllValuesKeys,
  BlotterOrderValues,
  OrderBlotterEntry,
  TradeBlotterEntry,
  TradeType,
} from 'state/blotter/blotterEntryModel';
import type { BlotterData } from 'state/blotter/blotterModel';
import type { IBlotterOrderNotificationData, IBlotterTradeNotificationData } from 'bootstrap/streams';
import type { EffectsExtraArgument } from 'state';
import { distinct } from 'utils/array';
import { logger } from 'logging/logger';
import { extract } from 'utils/object';
import {
  type FixingMarginType,
  isAlgoOrder,
  isLimitOrder,
  type LiquidityPool,
  type OrderType,
} from 'state/fxOrders/fxOrdersModel';
import { extractDayAndTime } from 'utils/dateFormats';
import { getLimitPrice, parseAndExtractDay, parseAndExtractDayAndTime } from 'epics/order/utils';
import type { Side } from '../../state/share/productModel/litterals';
import { isDefined } from '@sgme/fp';

const nullifyEmptyDate = (date: string | null) =>
  date === '' || (date !== null && date.includes('0001-01-01')) ? null : date;

export const tradeNotificationToTradeBlotterEntryWith =
  (getDateNow: EffectsExtraArgument['getDateNow']) =>
  (data: IBlotterTradeNotificationData, childrenIds?: readonly string[]): TradeBlotterEntry => ({
    instrument: data.blotterType,
    id: data.id,
    childrenIds,
    strategyReference: data.strategyReference,
    receivedDate: getDateNow().getTime(),
    electronicReference: data.electronicReference,
    values: {
      counterpartyBdrId: data.counterpartyBdrId,
      account: data.account,
      accountLongName: data.accountLongName,
      currencyPair: data.currencyPair,
      date: data.date,
      dealtAmount: data.dealtAmount,
      dealtCurrency: data.dealtCurrency,
      farDealtAmount: data.farDealtAmount,
      contraAmount: data.contraAmount,
      contraCurrency: data.contraCurrency,
      farContraAmount: data.farContraAmount,
      points: data.points,
      product: data.product as TradeType,
      spot: data.spot,
      status: data.status,
      salesFullName: data.salesFullName,
      venue: data.venue,
      way: data.way,
      expiry: nullifyEmptyDate(data.expiry),
      premium: data.premium,
      strike: data.strike,
      farDate: data.farDate,
      nearDate: data.nearDate,
      nearRate: data.nearRate,
      farRate: data.farRate,
      spotDate: data.spotDate,
      portfolio: data.portfolio,
      premiumCurrency: data.premiumCurrency,
      premiumSide: data.premiumSide === 'Receive' || data.premiumSide === 'Pay' ? data.premiumSide : null,
      updateTime: data.updateTime,
      electronicAccount: data.electronicAccount,
      optionType: data.optionType,
      cutOffPlace: data.cutOffPlace,
      deliveryDate: data.deliveryDate,
      deliveryType: data.deliveryType,
      premiumPaymentDate: data.premiumPaymentDate,
    },
    inputs: {},
    warnings: {},
    errors: {},
  });

export const getChildrenIdsInBlotterData = (
  tradesList: BlotterData,
  trade: TradeBlotterEntry,
): readonly string[] | undefined => {
  if (trade.instrument !== 'Option') {
    return undefined;
  }

  return Object.values(tradesList).filter(isChildrenOf(trade.id)).map(extract('id'));
};

const isChildrenOf =
  (strategyId: string) =>
  (entry: BlotterEntry | undefined): entry is TradeBlotterEntry =>
    entry !== undefined && entry.instrument !== 'Order' && entry.strategyReference === strategyId;

export const getChildrenIds = (
  tradeNotificationsData: readonly IBlotterTradeNotificationData[],
  tradeData: IBlotterTradeNotificationData,
): readonly string[] | undefined => {
  if (tradeData.blotterType !== 'Option') {
    return undefined;
  }

  return tradeNotificationsData
    .filter(trade => trade.strategyReference === tradeData.id)
    .map(extract('id'))
    .filter(distinct);
};

export const orderNotificationToOrderBlotterEntryWith = (
  getDateNow: EffectsExtraArgument['getDateNow'],
  parse: (dateString: string) => Date,
) => {
  const mapValues = mapValuesWith(parse);
  const convertExpiryToUserTimezone = convertExpiryToUserTimezoneWith(parse);
  return (data: IBlotterOrderNotificationData): OrderBlotterEntry => {
    const localExpiryData = convertExpiryToUserTimezone(data);
    return {
      id: data.id,
      instrument: 'Order',
      receivedDate: getDateNow().getTime(),
      values: mapValues(data.orderType as OrderType, data, localExpiryData) as any, // Sorry, I didn't manage to make Typescript do what I wanted
      inputs: {},
      errors: {},
      warnings: {},
      mode: 'Display',
      modeStatus: 'Idle',
    };
  };
};

interface ExpiryData {
  isGtc: boolean | null;
  expiryDay: string | null;
  expiryTime: string | null;
}

export const convertExpiryToUserTimezoneWith =
  (parse: (dateString: string) => Date) =>
  (data: Pick<IBlotterOrderNotificationData, 'expiryDay' | 'expiryTime' | 'goodTillCancelled' | 'id'>): ExpiryData => {
    if (data.goodTillCancelled) {
      return {
        isGtc: true,
        expiryDay: null,
        expiryTime: null,
      };
    } else {
      const datePartlyNull = data.expiryDay === null || data.expiryTime === null;

      if (datePartlyNull === true) {
        const fields = ['expiryDay', 'expiryTime'] as const;
        logger.logError(
          'Order blotter notification {dataId_s} is invalid: GTC is false and the following field(s) {fields_s} are null',
          data.id,
          fields.filter(val => data[val] === null).join(','),
        );

        return {
          isGtc: false,
          expiryDay: null,
          expiryTime: null,
        };
      }

      const dayTime = extractDayAndTime(parse(`${data.expiryDay}T${data.expiryTime}Z`));
      return {
        isGtc: data.goodTillCancelled,
        expiryDay: dayTime.day,
        expiryTime: dayTime.time,
      };
    }
  };

export const omsFieldToBlotterOrderStateField: Record<
  keyof OmsOrderSubmissionPayload,
  BlotterOrderAllValuesKeys | undefined
> = {
  bdrId: undefined,
  bdrIni: undefined,
  ulysseLogin: undefined,
  owner: undefined,
  ccyPair: 'currencyPair',
  amountInCcy1: 'notional',
  amountInCcy2: 'notional',
  customerPrice: 'customerPrice',
  expiryDay: 'expiryDay',
  expiryTime: 'expiryTime',
  base64Emails: undefined,
  limitPrice: 'limitPrice',
  triggerMode: undefined,
  way: 'way',
  isGtc: 'isGtc',
  maturityDateString: 'maturityDate',
  startDateUtc: 'startTime',
  endDateUtc: 'endTime',
  clippingMode: 'clippingMode',
  clipSize: 'clipSize',
  liquidityPool: 'liquidityPool',
  dodging: 'randomize',
  spreadCapture: 'spreadCapture',
  alphaSeeker: 'alphaSeeker',
  speed: undefined,
  fixingBenchmark: undefined,
  fixingPriceType: undefined,
  fixingDateUtc: undefined,
  fixingTime: undefined,
  fixingPlace: undefined,
  fixingMarginType: undefined,
  marginInBps: undefined,
  margin: undefined,
  isNdf: undefined,
  localTimeWithOffSet: undefined,
};

export const extractBlotterOrderValidationDetails = (
  response: OmsOrderValidationResponse,
  orderType: OrderType,
): BlotterOrderValidationResponse => {
  const validationResponseDetailContent = response.validationResponseDetailContent.reduce((acc, omsError) => {
    const field = omsFieldToBlotterOrderStateField[omsError.field as keyof OmsOrderSubmissionPayload];
    if (field === undefined) {
      return acc;
    }
    const code = `error-oms-${omsError.errorCode}`;
    const error: BlotterOrderValidationDetail = {
      code,
      field,
      description: omsError.errorDescription,
    };
    acc.push(error);
    return acc;
  }, [] as BlotterOrderValidationDetail[]);

  const { day: expiryDay, time: expiryTime } = parseAndExtractDayAndTime(response.expiryDay);
  const maturityDay = parseAndExtractDay(response.maturityDay);

  return {
    isReadyToSubmit: response.isReadyToSubmit,
    validationResponseDetailContent,
    expiryDay,
    expiryTime,
    maturityDay,
    limitPrice: getLimitPrice(response, orderType),
    customerPrice: isDefined(response.customerPrice) ? response.customerPrice : undefined,
    margin: response.margin,
    fixingTypes: response.fixingTypes,
    isCcyForcedBidAsk: response.isCcyForcedBidAsk,
    isStandardGroup: response.isStandardGroup,
    fixingTimesOfSelectedType: response.fixingTimesOfSelectedType,
    sourceNameOfSelectedType: response.sourceNameOfSelectedType,
    canModifyMarginInBps: response.canModifyMarginInBps,
    marginInBps: response.marginInBps,
    fixingMarginType: response.fixingMarginType,
    ndfFixingDate: response.ndfFixingDate,
    ndfFixingSource: response.ndfFixingSource,
  };
};

export interface BlotterOrderValidationDetail {
  code: string;
  field: Partial<BlotterOrderAllValuesKeys>;
  description: string;
}

export interface BlotterOrderValidationResponse {
  isReadyToSubmit: boolean;
  validationResponseDetailContent: readonly BlotterOrderValidationDetail[];
  expiryDay?: string | null;
  expiryTime?: string | null;
  maturityDay?: string | null;
  limitPrice?: number | null;
  customerPrice?: number | null;
  fixingTypes: string[] | null;
  isCcyForcedBidAsk: boolean | null;
  isStandardGroup: boolean | null;
  fixingTimesOfSelectedType: string[] | null;
  sourceNameOfSelectedType: string[] | null;
  margin?: number | null;
  marginInBps: number | null;
  canModifyMarginInBps: boolean;
  fixingMarginType: FixingMarginType;
  ndfFixingDate?: string | null;
  ndfFixingSource?: string | null;
}

const mapValuesWith =
  (parse: (dateString: string) => Date) =>
  (
    orderType: OrderType,
    data: IBlotterOrderNotificationData,
    localExpiryData: ExpiryData,
  ): Readonly<BlotterOrderValues> => {
    const startDayTime =
      isAlgoOrder(orderType) && data.startTime ? extractDayAndTime(parse(data.startTime)) : undefined;
    const endDayTime = isAlgoOrder(orderType) && data.endTime ? extractDayAndTime(parse(data.endTime)) : undefined;

    const getMargin = getMarginWith(data.yourWay);
    const margin = data.orderType === 'TakeProfit' ? getMargin(data.limitPrice, data.customerPrice) : undefined;

    return {
      status: data.status,
      account: data.account,
      currencyPair: data.currencyPair,
      way: data.yourWay,
      notional: data.notional,
      notionalCurrency: data.notionalCurrency,
      executionPrice: data.executionPrice,
      executedNotional: data.executedAmount,
      remainingAmount: data.remainingAmount ?? null,
      rejectReason: data.rejectReason,
      updateTime: data.updateTime,

      ...localExpiryData,
      ...(isLimitOrder(orderType)
        ? {
            product: orderType,
            limitPrice: data.limitPrice,
            customerPrice: data.customerPrice,
            fixingBenchmark: data.fixingBenchmark,
            fixingPlace: data.fixingPlace,
            fixingTime: data.fixingTime,
            fixingDateUtc: data.fixingDateUtc,
            fixingPriceType: data.fixingPriceType,
            fixingMarginType: data.fixingMarginType,
            margin,
            marginInBps: data.marginInBps,
            price: data.price,
          }
        : {
            product: orderType,
            startDate: startDayTime?.day,
            startTime: startDayTime?.time,
            endDate: endDayTime?.day,
            endTime: endDayTime?.time,
            maturityDate: data.maturityDate,
            liquidityPool: (data.liquidityPool?.split(',') as readonly LiquidityPool[]) ?? undefined,
            clippingMode: data.clippingMode ?? undefined,
            clipSize: data.clipSize,
            randomize: data.randomize ?? undefined,
            swapPoints: data.swapPoints,
            forwardPrice: data.forwardPrice,
            averagePrice: data.averagePrice,
            noWorseThan: data.noWorseThan,
            spreadCapture: data.spreadCapture ?? undefined,
            alphaSeeker: data.alphaSeeker ?? undefined,
            speed: data.speed ?? 'Normal',
          }),
    };
  };

function getMarginWith(way: Side | null) {
  return (limitPrice: number | null, customerPrice: number | null) => {
    if (way !== null && limitPrice !== null && customerPrice !== null) {
      const firstPrice = way === 'Sell' ? limitPrice : customerPrice;
      const secondPrice = way === 'Sell' ? customerPrice : limitPrice;

      return parseFloat(((firstPrice - secondPrice) * 10000).toFixed(5));
    }

    return undefined;
  };
}
