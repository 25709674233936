import type { Reducer } from 'redux';
import type { Action } from 'state/actions';

export const userPreferenceShowReducer: Reducer<boolean> = (state = false, action: Action) => {
  switch (action.type) {
    case 'USER_PREFERENCES_TOGGLE_MODAL':
      return action.open;
  }
  return state;
};
