import type { ISgmeHttp } from 'utils/sgmeHttp';
import { ignoreElements } from 'rxjs/operators';
import type { Observable } from 'rxjs';

export interface IAccumulatorRfsCancelRequestApi {
  cancelRfsRequest(rfsId: string): Observable<never>;
}

export function accumulatorRfsCancelApi(http: ISgmeHttp): IAccumulatorRfsCancelRequestApi {
  return {
    cancelRfsRequest(rfsId: string) {
      return http
        .post<void>('api/fxAccumulator/rfs/cancel', { rfsId }, { 'X-Correlation-ID': rfsId })
        .pipe(ignoreElements());
    },
  };
}
