import type { ISgmeHttp } from 'utils/sgmeHttp';
import { ignoreElements } from 'rxjs/operators';
import type { IBulkQuoteRequestApi } from './multipassModel';

export function bulkQuoteRequestApi(http: ISgmeHttp): IBulkQuoteRequestApi {
  return {
    msToFirstHearbeat: 120_000,
    createQuoteRequest(request) {
      return http
        .post<void>('api/fxBulk/rfs/request', request, { 'X-Correlation-ID': request.rfsId })
        .pipe(ignoreElements());
    },
  };
}
