import type { ItemPosition } from './freeLayoutModel';

export type FreeLayoutAction = FreeLayoutItemPositionChanged;

export interface FreeLayoutItemPositionChanged {
  type: 'FREELAYOUT_ITEM_POSITION_CHANGED';
  clientWorkspaceId: string;
  itemId: string;
  position: ItemPosition;
}

export function freeLayoutItemPositionChanged(
  clientWorkspaceId: string,
  itemId: string,
  position: ItemPosition,
): FreeLayoutItemPositionChanged {
  return {
    type: 'FREELAYOUT_ITEM_POSITION_CHANGED',
    clientWorkspaceId,
    itemId,
    position,
  };
}
