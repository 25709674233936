import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import { withQuoteId } from 'components/contexts';
import { InstrumentChooserRaw } from './InstrumentChooser';
import {
  mapStateToPropsInstrumentChooser,
  mapDispatchToPropsInstrumentChooser,
} from './connectInstrumentChooser';

const connectInstrumentChooser = connect(
  mapStateToPropsInstrumentChooser(selectors),
  mapDispatchToPropsInstrumentChooser(actionCreators),
);

export const InstrumentChooser = withQuoteId(connectInstrumentChooser(InstrumentChooserRaw));
