import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';

export interface TabNameConnectOwnProps {
  tabId: string;
}

export interface TabNameConnectStateProps {
  name: string;
}

export interface TabNameConnectDispatchProps {
  rename(name: string): void;
}

// you can whitelist selectors after implementation
export type TabNameConnectSelectors = Selectors;
/*
export type TabNameConnectSelectorsKeys = 'aSelector'; // example
export type TabNameConnectSelectors = Pick<Selectors, TabNameConnectSelectorsKeys>;
*/

export const mapStateToPropsTabName: MapStateToPropsHOF<
  TabNameConnectStateProps,
  TabNameConnectOwnProps,
  AppState,
  TabNameConnectSelectors
> =
  sl =>
  (state, { tabId }) => ({
    name: sl.getClientWorkspaceTab(state, tabId).name,
  });

// you can whitelist action creators after implementation
export type TabNameConnectActionCreators = ActionCreators;
/*
export type TabNameConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type TabNameConnectActionCreators = Pick<ActionCreators, TabNameConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsTabName: MapDispatchToPropsHOF<
  TabNameConnectDispatchProps,
  TabNameConnectOwnProps,
  TabNameConnectActionCreators
> =
  ac =>
  (dispatch, { tabId }) => ({
    rename: name => dispatch(ac.clientWorkspaceTabRenamed(tabId, name)),
  });
