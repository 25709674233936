import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

export interface QuoteErrorConnectOwnProps {
  quoteId: string;
  close?: boolean;
}

export interface QuoteErrorConnectDispatchProps {
  showErrorDetails(): void;
  reset(): void;
}

// you can whitelist action creators after implementation
export type QuoteErrorConnectActionCreators = ActionCreators;
/*
export type QuoteErrorConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type QuoteErrorConnectActionCreators = Pick<ActionCreators, QuoteErrorConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsQuoteError: MapDispatchToPropsHOF<
  QuoteErrorConnectDispatchProps,
  QuoteErrorConnectOwnProps,
  QuoteErrorConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId, close = false }) => ({
    showErrorDetails() {
      dispatch(ac.displayPropertiesErrorDetail(quoteId));
    },
    reset() {
      dispatch(close ? ac.tileClosedThunk(quoteId) : ac.resetTileThunk(quoteId));
    },
  });
