import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { ITradeCaptureMetaData, TcErrors, TcWarnings } from 'api/tradeCapture/tradeCaptureModel';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { clearUndefined } from 'utils/clearUndefined';
import {
  fromTcError,
  fromTcErrorAsString,
  fromTcWarning,
  fromTcWarningAsString,
} from 'api/tradeCapture/tradeCaptureMappingHelper';
import type { CurrencyChoice, PropertyErrors, PropertyWarnings } from 'state/share/productModel/litterals';
import {
  TradeCaptureAmericanForwardErrors,
  TradeCaptureAmericanForwardHedgeType,
  TradeCaptureAmericanForwardLegPreviousValues,
  TradeCaptureAmericanForwardMetaData,
  TradeCaptureAmericanForwardResponseWrapper,
  TradeCaptureAmericanForwardWarnings,
} from '../../../api/tradeCapture/americanForward/tradeCaptureModel';
import {
  FxAmericanForwardPatch,
  FxAmericanForwardValues,
  HedgeType,
} from '../../../state/fxAmericanForward/model/fxAmericanForwardProductModel';
import { isDefined } from '@sgme/fp';

type ActionPatch = TradeCaptureAmericanForwardResponseWrapper & WithAmericanForwardId;

export type TradeCaptureAmericanForwardResponsePatch = TradeCaptureAmericanForwardResponseWrapper &
  WithAmericanForwardId;

export type AmericanForwardTradeCapturePatch = ITradeCaptureMetaData &
  TradeCaptureAmericanForwardMetaData &
  FxAmericanForwardPatch;

interface WithAmericanForwardId {
  quoteId: string;
}

type TradeCaptureFromBackendMetaSelectorsKeys = 'getAmericanForwardCurrencyPairInput';

type TradeCaptureFromBackendMetaSelectorSelectors = Pick<Selectors, TradeCaptureFromBackendMetaSelectorsKeys>;

export const metaSelectorTradeCaptureFromBackendWith: MapStateToMetadataHOF<
  AmericanForwardTradeCapturePatch,
  TradeCaptureAmericanForwardResponsePatch,
  AppState,
  TradeCaptureFromBackendMetaSelectorSelectors
> = (sl) => (state, patch) => {
  const getValues = makeGetValues(state, sl);
  const tcErrors = getTradeCaptureErrors(patch.errors);
  const tcWarnings = getTradeCaptureWarnings(patch.warnings);

  return {
    ...getMetadata(patch),
    // values: getValues(patch, tcErrors),
    values: getValues(patch),
    errors: getErrors(tcErrors),
    warnings: getWarnings(tcWarnings),
  };
};

const getMetadata = (patch: ActionPatch): ITradeCaptureMetaData & TradeCaptureAmericanForwardMetaData => ({
  idVersion: patch.idVersion,
  isReadyToPrice: patch.changedFields.isReadyToPrice || false,
  isPriceObsolete: patch.changedFields.isPriceObsolete || false,
  isProductObsolete: patch.changedFields.isProductObsolete || false,
});

const makeGetValues =
  (_state: AppState, _sl: TradeCaptureFromBackendMetaSelectorSelectors) =>
    (
      { changedFields }: TradeCaptureAmericanForwardResponsePatch,
      // errors: TcErrors<TradeCaptureAmericanForwardLegPreviousValues>, // TODO ABO, manage fields errors
    ): Partial<FxAmericanForwardValues> => {
      const {
        currencyPair,
        hedgeType,
        hedgePaymentDate,
        hedgeAmountInCcy1String,
        hedgeAmountInCcy2String,
        hedgePriceString,
        markupCurrency,
      } = changedFields;

      const {
        side,
        currency1,
        currency2,
        amount1,
        amount2,
        quantity,
        startDate,
        startDateTenor,
        callabilityStartDate,
        callabilityStartDateTenor,
        callabilityType,
        expiryDate,
        expiryDateTenor,
        deliveryDate,
        deliveryDateTenor,
        premiumPaymentDate,
        premiumPaymentDateTenor,
        possibleCutOffMarketPlaces,
        cutOffMarketPlace,
        premiumPaymentAmountBidString,
        premiumPaymentAmountAskString,
        forwardRateString,
      } = changedFields.legs[0];


      const hasAmount = amount1 || amount2;
      const isAmount1 = amount1 !== null && amount1 !== undefined;
      const amountValues: { amount: number | null | undefined; amountCurrency: CurrencyChoice | undefined } = hasAmount
        ? isAmount1
          ? { amount: amount1, amountCurrency: 1 }
          : { amount: amount2, amountCurrency: 2 }
        : { amount: undefined, amountCurrency: undefined };

      const hasHedgeAmount = hedgeAmountInCcy1String || hedgeAmountInCcy2String;
      const isHedgeAmount1 = isDefined(hedgeAmountInCcy1String);
      const HedgeAmountValues: { hedgeAmount: string | null | undefined; hedgeCurrency: CurrencyChoice | undefined } =
        hasHedgeAmount
          ? isHedgeAmount1
            ? {
              hedgeAmount: hedgeAmountInCcy1String === '0' ? null : hedgeAmountInCcy1String?.toString(),
              hedgeCurrency: 1,
            }
            : {
              hedgeAmount: hedgeAmountInCcy2String === '0' ? null : hedgeAmountInCcy2String?.toString(),
              hedgeCurrency: 2,
            }
          : { hedgeAmount: undefined, hedgeCurrency: undefined };

      const hasStartDate = startDate !== undefined || callabilityStartDate !== undefined;
      const startDateValues = hasStartDate
        ? startDate !== undefined
          ? { startDate, startDateTenor }
          : { startDate: callabilityStartDate, startDateTenor: callabilityStartDateTenor }
        : { startDate: undefined, startDateTenor: undefined };

      const hedgeRate = hedgePriceString === '0' ? null : hedgePriceString;

      return clearUndefined<Partial<FxAmericanForwardValues>>({
        currencyPair,
        hedgeType: mapToHedgeType(hedgeType),
        hedgePaymentDate,
        ...HedgeAmountValues,
        hedgeRate,
        currency1,
        callabilityType,
        currency2,
        side,
        ...amountValues,
        quantity,
        ...startDateValues,
        expiryDate,
        expiryDateTenor,
        deliveryDate,
        deliveryDateTenor,
        premiumDate: premiumPaymentDate,
        premiumDateTenor: premiumPaymentDateTenor,
        possibleMarketPlaces: possibleCutOffMarketPlaces,
        marketPlace: cutOffMarketPlace,
        markupCurrency,
        premiumPaymentAmount: premiumPaymentAmountBidString ?? premiumPaymentAmountAskString,
        forwardRate: forwardRateString,
        // amountCurrency: mapToCurrencyChoice(amountCurrency, currencyPairRef),
      });
    };

const getErrors = (
  errors: TcErrors<TradeCaptureAmericanForwardLegPreviousValues>,
): PropertyErrors<FxAmericanForwardValues> =>
  clearUndefined<PropertyErrors<FxAmericanForwardValues>>({
    amount: fromTcErrorAsString(errors.amount),
    expiryDate: fromTcError(errors.expiryDate),
  });

const getWarnings = (
  warnings: TcWarnings<TradeCaptureAmericanForwardLegPreviousValues>,
): PropertyWarnings<FxAmericanForwardValues> =>
  clearUndefined<PropertyWarnings<FxAmericanForwardValues>>({
    amount: fromTcWarningAsString(warnings.amount),
    expiryDate: fromTcWarning(warnings.expiryDate),
  });

const getTradeCaptureErrors = (responseError: TradeCaptureAmericanForwardErrors | null) =>
  responseError?.legs?.[0] ?? {};

const getTradeCaptureWarnings = (responseWarning: TradeCaptureAmericanForwardWarnings | null) =>
  responseWarning?.legs?.[0] ?? {};

// const mapToCurrencyChoice = (
//   amountCurrency: string | undefined,
//   currencyPair: string | null,
// ): CurrencyChoice | undefined => {
//   if (isEmptyOrNotDefined(amountCurrency)) {
//     return undefined;
//   }
//   if (isEmptyOrNotDefined(currencyPair)) {
//     throw new Error('Should not have no currency pair with a amountCurrency');
//   }
//   const currencies = currencyPair.split('/');
//   return amountCurrency === currencies[0] ? 1 : 2;
// };

function mapToHedgeType(value: TradeCaptureAmericanForwardHedgeType | undefined): HedgeType | undefined {
  if (value === 'None' || value === null) {
    return 'Live';
  }
  return value;
}
