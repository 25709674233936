import { getAccumulatorMetadata } from './factories';

export const getAccumulatorPropertiesRequested = getAccumulatorMetadata('propertiesRequested');

export const getAccumulatorTradeCaptureCurrentSessionId =
  getAccumulatorMetadata('currentSessionId');

export const getAccumulatorTradeCaptureIdVersion = getAccumulatorMetadata('tradeCaptureIdVersion');

export const getAccumulatorIsPriceable = getAccumulatorMetadata('isPriceable');

export const getAccumulatorRfsStreamId = getAccumulatorMetadata('currentStreamId');

export const getAccumulatorLastStreamId = getAccumulatorMetadata('lastStreamId');

export const getAccumulatorSummary = getAccumulatorMetadata('summary');

export const getAccumulatorDisplayMode = getAccumulatorMetadata('displayMode');

export const getAccumulatorNumberOfSettlements = getAccumulatorMetadata('numberOfSettlements');

type SelectorArgs = Parameters<ReturnType<typeof getAccumulatorMetadata>>;
type Selector<T> = (...args: SelectorArgs) => T | null;

const prioritySelect =
  (normal: Selector<string>, priority: Selector<string>) =>
  (...args: SelectorArgs) =>
    priority(...args) ?? normal(...args);

export const getAccumulatorActiveStreamId = prioritySelect(
  getAccumulatorRfsStreamId,
  getAccumulatorLastStreamId,
);
