import type { AppState } from 'state/model';
import {
  type StreamingConnectionState,
  isStreamingStatusConnected,
  isStreamingStatusDisconnected,
  isStreamingStatusInitialising,
} from './streamingConnectionModel';

function pickStreamingConnection(state: AppState): StreamingConnectionState {
  return state.streamingConnection;
}

export function getConnectionId(state: AppState) {
  const streamingConnectionState = pickStreamingConnection(state);
  return isStreamingStatusConnected(streamingConnectionState)
    ? streamingConnectionState.connectionId
    : '';
}

export function isStreamingInitialising(state: AppState) {
  return isStreamingStatusInitialising(pickStreamingConnection(state));
}

export function isStreamingConnected(state: AppState) {
  return isStreamingStatusConnected(pickStreamingConnection(state));
}

export function isStreamingDisconnected(state: AppState) {
  return isStreamingStatusDisconnected(pickStreamingConnection(state));
}

export function isStreamingDegraded(state: AppState) {
  const streamingConnectionState = pickStreamingConnection(state);
  return isStreamingStatusConnected(streamingConnectionState)
    ? streamingConnectionState.degraded
    : true;
}

export function isStreamingNotOptimal(state: AppState) {
  const streamingConnectionState = pickStreamingConnection(state);
  return isStreamingStatusConnected(streamingConnectionState)
    ? streamingConnectionState.driver !== 'WEBSOCKET'
    : true;
}
