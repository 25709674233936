import { timer, start, end } from '.';

/**
 * Repaint
 */
const repaint = Symbol('Repaint');
export const reduxSubTimer = timer(Symbol('Redux Front Repaint'), repaint);

/**
 * Redux middlewares
 */
const reduxMiddleware = Symbol('Redux middleware');
export const circuitBreakerTimer = timer(Symbol('Circuit breaker middleware'), reduxMiddleware);
const actionNotification = Symbol('Action notification');
export const actionNotificationStart = () => start(actionNotification, reduxMiddleware);
export const actionNotificationEnd = () => end(actionNotification, reduxMiddleware);

/**
 * Redux scheduling
 */
const reduxEmitter = Symbol('Redux subscribe');
export const rafSchedulerTimer = timer(Symbol('Raf Scheduler'), reduxEmitter);

/**
 * Reduction
 */
const redux = Symbol('Redux');
export const rootReducerTimer = timer(Symbol('Root Reducer'), redux);

/**
 * RX
 */
const rxFilters = Symbol('Rx Filter');
const rxMaps = Symbol('Rx Map');
/**
 * ESP
 */
export const filterDatastreamTimer = timer(Symbol('filter esp by ID'), rxFilters);
export const filterESPPriceTimer = timer(Symbol('is ESP price'), rxFilters);
export const mapESPActionTimer = timer(Symbol('ESP to Action'), rxMaps);
/**
 * STREAMS
 */
export const deserializeDatastreamTimer = timer(Symbol('deserialize datastream'), rxMaps);
export const datastreamIsDataTimer = timer(Symbol('datastream is data'), rxFilters);
export const datastreamIsESPTimer = timer(Symbol('datastream is ESP'), rxFilters);
export const datastreamIsRFSTimer = timer(Symbol('datastream is RFS'), rxFilters);
export const convertLaddersTimer = timer(Symbol('convert ESP ladders'), rxMaps);
