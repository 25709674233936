import type { TcError, TcErrors, TcWarning } from './tradeCaptureModel';
import type {
  PropertyError,
  CurrencyChoice,
  PropertyWarning,
} from 'state/share/productModel/litterals';

export function previousValue<T>(error?: ReadonlyArray<TcError<T>>): T | null | undefined {
  return error && error[0].previousValue;
}

export function previousValueAsString<T>(
  error?: ReadonlyArray<TcError<T>>,
): string | null | undefined {
  const value = previousValue(error);
  return value && `${value}`;
}

export function fromTcWarning<T>(
  warning?: ReadonlyArray<TcWarning<T>>,
): PropertyWarning<T> | undefined {
  return (
    warning && {
      code: `warning-tc-${warning[0].id || 0}`,
      description: warning[0].description,
      faultyValue: warning[0].faultyValue,
      userNotified: false,
    }
  );
}

export function fromTcWarningAsString<T>(
  warning?: ReadonlyArray<TcWarning<T>>,
): PropertyWarning<string> | undefined {
  return (
    warning && {
      code: `warning-tc-${warning[0].id || 0}`,
      description: warning[0].description,
      faultyValue: warning[0].faultyValue.toString(),
      userNotified: false,
    }
  );
}

export function fromTcError<T>(error?: ReadonlyArray<TcError<T>>): PropertyError<T> | undefined {
  return (
    error && {
      code: `error-tc-${error[0].id || 0}`,
      description: error[0].description,
      faultyValue: error[0].faultyValue,
      userNotified: false,
    }
  );
}
export function fromTcErrorAsString<T>(
  error?: ReadonlyArray<TcError<T>>,
): PropertyError<string> | undefined {
  return (
    error && {
      code: `error-tc-${error[0].id || 0}`,
      description: error[0].description,
      faultyValue: error[0].faultyValue.toString(),
      userNotified: false,
    }
  );
}

export function getPreviousMap<T>(errors?: TcErrors<T>): Partial<T> | undefined {
  return (
    errors &&
    Object.entries(errors as Record<string, ReadonlyArray<TcError<any>>>).reduce(
      (acc, [key, err]) => ({
        ...acc,
        [key]: err[0].previousValue,
      }),
      {},
    )
  );
}

export function mapAmount<T>(
  negotiatedCurrency: CurrencyChoice | undefined,
  amount1: T | undefined,
  amount2: T | undefined,
): T | undefined {
  if (amount1 === undefined && amount2 === undefined) {
    return;
  }
  if (negotiatedCurrency === undefined) {
    return amount1 !== undefined ? amount1 : amount2;
  } else if (negotiatedCurrency === 1) {
    return amount1;
  } else if (negotiatedCurrency === 2) {
    return amount2;
  }
}
