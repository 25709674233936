import { makeValuesPredicate } from '@sgme/fp';
import type { BidAsk, CurrencyChoice } from 'state/share/productModel/litterals';
import type { IFxExecutionState } from 'state/executions/executionsStateModel';
import type { IFxOptionExecutionData } from './actions/optionProduct';

export const optionTypes = ['Call', 'Put'] as const;
export type OptionType = typeof optionTypes[number];
export const isOptionType = makeValuesPredicate(optionTypes);

export const typedOptionTypes = ['Call', 'Put', 'Straddle', 'Strangle', 'RiskReversal'] as const;
export type TypedOptionType = typeof typedOptionTypes[number];

export type SettlementType = 'Cash' | 'Physical' | 'Forward' | 'NDF';

export const allPriceTypes = ['AMOUNT', 'VOLATILITY', 'PERCENT', 'PPS'] as const;
export type PriceType = typeof allPriceTypes[number];
export type DisplayPriceType = [ccyChoice: CurrencyChoice, priceType: PriceType];

export const defaultSolvingFor = 'PremiumPaymentAmount' as const;
export const optionSolvableValues = ['Strike', 'Amount1', defaultSolvingFor] as const;
export type OptionSolvableValue = typeof optionSolvableValues[number];

export const validInputsInFine = ['in fine', 'in-fine', 'infine', 'if'];

export interface IGreeks {
  deltaCcy: number;
  vegaCcy: number;
  gammaCcy: number;
  thetaCcy: number;
}

export type IPremiumPairData = BidAsk<number>;

export type IPremiumPairDataString = BidAsk<string | null>;

export type FxOptionExecutionState = IFxExecutionState & IFxOptionExecutionData;
