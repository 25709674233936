import type { Thunk } from 'state';
import { fieldData } from 'utils/fieldSelectors';
import type { CurrencyChoice } from 'state/share/productModel/litterals';

export function bulkCurrencyChanged(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { data: prevCcy } = fieldData(sl.getBulkAmountCurrency(state, quoteId));
    const amountCurrency: CurrencyChoice = prevCcy === 2 ? 1 : 2;

    dispatch(ac.bulkPropertyChanged(quoteId, { amountCurrency }));
  };
}

export function bulkCurrencyPairChangedThunk(quoteId: string, currencyPair: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    dispatch(ac.bulkPropertyChanged(quoteId, { currencyPair }));
    if (
      currencyPair !== undefined &&
      currencyPair !== sl.getBulkCurrencyPair(state, quoteId).value
    ) {
      dispatch(ac.espStreamRestartThunk(quoteId, currencyPair, undefined, undefined));
    }
  };
}
