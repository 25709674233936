import type { MapStateToMetadataHOF } from 'typings/redux-utils';

import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { TieringRequest } from 'api/multipass/cash/esp/model';
import { fieldData } from 'utils/fieldSelectors';

export const buildTieringRequestWith: MapStateToMetadataHOF<
  TieringRequest,
  { tileId: string; pair: string; companyId: number },
  AppState,
  Selectors
> =
  sl =>
  (state, { tileId, pair, companyId }) => {
    const productName = sl.getProductName(state, tileId);
    const { ccy1, ccy2 } = sl.getCurrencyPairDetails(state, pair)!;
    let maturityDate = new Date();
    if (productName === 'FxSpot' || productName === 'FxFwd') {
      maturityDate = new Date(fieldData(sl.getCashMaturityDate(state, tileId)).data!);
    }

    const request: TieringRequest = {
      ccy1,
      ccy2,
      clientBdrId: companyId,
      maturityDate,
      product: productName === 'FxNdf' ? 'ndf' : 'spot',
    };

    return request;
  };
