import type { Instrument, TileInstrument } from 'state/referenceData/referenceDataModel';
import type { ImmutableCollection } from 'typings/utils';

export type TabType = 'tiles' | 'bulkTrade';

export const tabTypePrettyName: Record<TabType, string> = {
  tiles: 'Workspace',
  bulkTrade: 'Bulk Trade',
};

export const tabNameGenerators: Record<TabType, (index: number) => string> = {
  tiles: (index: number) => `Workspace ${index}`,
  bulkTrade: (index: number) => `Bulk trade ${index}`,
};

export const marketData = 'MarketData' as const;
export const greeks = 'Greeks' as const;
export const commercialMargin = 'CommercialMargin' as const;
export const otherAttributes = 'OtherAttributes' as const;
export const editableAttributes = 'EditableAttributes' as const;

export const collapsableKeys = {
  marketData,
  greeks,
  commercialMargin,
  otherAttributes,
  editableAttributes,
};

export type CollapsableKey = typeof collapsableKeys[keyof typeof collapsableKeys];

export interface IWorkspaceTabState {
  readonly name: string;
  readonly isNamePristine: boolean;
  readonly type: TabType;
  readonly clientId: string | null;
  readonly tiles: readonly string[];
  readonly zoomedTile: string | null;
}
export type IWorkspaceTabs = ImmutableCollection<IWorkspaceTabState>;

export interface IWorkspaceTileState {
  readonly clientError?: boolean;
  readonly expanded: readonly CollapsableKey[];
  readonly instrument: Instrument;
  readonly clientWorkspaceId: string;
  readonly overrideClientId: string | null;
}

export type IWorkspaceTiles = ImmutableCollection<IWorkspaceTileState>;

export interface GrowlData {
  id: string;
  instrument: TileInstrument;
}

export type IWorkspaceGrowls = readonly GrowlData[];

export type SaveState = 'READY' | 'PENDING' | 'FAIL' | 'SUCCESS';

interface IClientWorkspaceState {
  tradingDisabled: boolean;
  saveState: SaveState;
  tabs: IWorkspaceTabs;
  activeTab: string | null;
  tiles: IWorkspaceTiles;
  growls: IWorkspaceGrowls;
}

export type ClientWorkspaceState = Readonly<IClientWorkspaceState>;
