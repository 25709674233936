import type { ReactNode } from 'react';
import { connectInstrument } from 'components/share/connect';
import type { Instrument } from 'state/referenceData/referenceDataModel';
import { InstrumentContext, withQuoteId } from '.';

interface AutoInstrumentProviderProps {
  instrument: Instrument;
  children: ReactNode;
}

const AutoInstrumentProviderRaw: React.FunctionComponent<AutoInstrumentProviderProps> = ({
  instrument,
  children,
}) => <InstrumentContext.Provider value={instrument}>{children}</InstrumentContext.Provider>;

export const AutoInstrumentProvider = withQuoteId(connectInstrument(AutoInstrumentProviderRaw));
