import type { HedgeType, HedgeWay, SettlementMode } from 'state/share/productModel/litterals';
import type { BidAskPair, CurrencyChoice, QuoteContributionType, Side, Way } from '../share/productModel/litterals';
import type { IFormData } from 'state/share/form';
import type { IFxTileMetadata } from 'state/tile/fxTileModel';
import type { Collection } from 'typings/utils';
import type { Patch } from '../share/patchModels';
import type { IFxExecutionData, IFxExecutionState } from 'state/executions/executionsStateModel';
import type { PredealCheck } from 'state/share/predealChecksModel';

export const accumulatorPriceType = ['Amount'] as const;
export type AccumulatorPriceType = (typeof accumulatorPriceType)[number];

export type AccumulatorProductName = 'FxTargetAccumulator' | 'FxForwardAccumulator';

export type AccumulatorCalendar = readonly ForwardAccuCalendarLine[] | readonly TargetAccuCalendarLine[];

export const accumulatorAmountSplitTypes = ['Fixing', 'Total'] as const;
export type AccumulatorAmountSplitType = (typeof accumulatorAmountSplitTypes)[number];

export const targetAccuTypes = ['TargetAccu', 'PIVOTEKI', 'PIVOT', 'EKI'] as const;
export type TargetAccuType = (typeof targetAccuTypes)[number];

export const ForwardAccuTypes = ['Standard', 'Permanent'] as const;
export type ForwardAccuType = (typeof ForwardAccuTypes)[number];

export interface AccumulatorCalendarLine {
  amountDown: number | null;
  amountUp: number | null;
  strike: number | null;
  fixingDate: string | null;
  paymentDate: string | null;
  fixingValue: number | null;
  weight: number | null;
  isDelivered: boolean | null;
  stepDown: number | null;
  stepUp: number | null;
  amountMid: number | null;
  step: number | null;
  power: number | null;
  leverage: number | null;
  targetGapDeltaProfit: number | null;
  couponGapDelta: number | null;
}

export interface ForwardAccuCalendarLine extends AccumulatorCalendarLine {}

export interface TargetAccuCalendarLine extends AccumulatorCalendarLine {}

export interface IFxTargetAccumulatorValues extends IFxAccumulatorValues {
  possibleAccuTypeList: readonly TargetAccuType[];
  accuType: TargetAccuType;
  eki: string | null;
  ekiString: string | null;
}

export interface IFxForwardAccumulatorValues extends IFxAccumulatorValues {
  accuType: ForwardAccuType;
}

export interface IFxAccumulatorValues {
  productName: AccumulatorProductName;
  currencyPair: string | null;
  premiumDate: string | null;
  premiumDateTenor: string | null;

  hedgeType: HedgeType;
  hedgeAmount: string | null;
  hedgePrice: string | null;
  hedgePaymentDate: string | null;
  hedgeCurrency: CurrencyChoice;

  way: Side;
  amount: string | null;
  amountCurrency: CurrencyChoice;
  totalNotional: number | null;
  leverage: string | null;
  leverageAmount: string | null;
  totalLeverage: number | null;
  strike: string | null;
  step: string | null;
  akoTrigger: string | null;
  barrier: AccumulatorBarrier | null;
  ekiTrigger: string | null;
  strikeDown?: string | null;
  pivot?: string | null;
  strikeUp?: string | null;
  stepDown?: number;
  stepUp?: number;
  ekiUp: string | null;
  ekiDown: string | null;
  targetProfitType: string | null;
  targetProfitTypeList: readonly string[];
  target: string | null;
  firstFixingDate: string | null;
  firstFixingDateTenor: string | null;
  expiryDate: string | null;
  expiryTenor: string | null;
  fixingFrequency: string;
  fixingFrequenciesList: readonly string[];
  defaultFixingFrequency: string;
  numberOfFixings: number;
  settlementFrequency: string | null;
  defaultSettlementFrequency: string;
  settlementFrequenciesList: readonly string[];
  settlementMode: SettlementMode;
  settlementModeList: readonly SettlementMode[];
  cashSettlementCurrency: string;
  cashSettlementCurrencyList: readonly string[];
  defaultFixingReference1: string;
  defaultFixingReference2: string;
  fixingReferencesList1: readonly string[];
  fixingReferencesList2: readonly string[];
  fixingReference1: string;
  fixingReference2: string;
  priceType: AccumulatorPriceType;
  priceCurrency: CurrencyChoice;
  possiblePriceTypes: readonly AccumulatorPriceType[];
  possibleHedgeTypes: readonly HedgeType[];
  lastFixingDate: string | null;
  calendar: AccumulatorCalendar;
  deliveryDate: string | null;
  cutOffMarketPlace: string | null;
  markupCurrency: string;
  customFields: readonly CustomFieldValues[];
  isCrossed: boolean;
  crossCurrency: string;
  possibleCrossCurrencies: readonly string[];
  amountSplitType: AccumulatorAmountSplitType;
  strikeComparator: string;
}

export const customField = [
  'SettlementFrequency',
  'FixingFrequency',
  'Strike',
  'StrikeUp',
  'StrikeDown',
  'Step',
  'Amount',
  'LeverageAmount',
] as const;

export type CustomFieldValues = (typeof customField)[number];

export type AccumulatorSchedule = Record<string, AccumulatorScheduleItem>;

export type AccumulatorScheduleItem = IFormData<AccumulatorScheduleValues, AccumulatorScheduleInputs> &
  AccumulatorScheduleMetadata;

export const scheduleCustomFieldValues = [
  'fixingDate',
  'paymentDate',
  'strike',
  'step',
  'stepDown',
  'stepUp',
  'ekiTrigger',
  'amount',
  'leverageAmount',
  'strikeUp',
  'strikeDown',
] as const;

export type ScheduleCustomFieldValues = (typeof scheduleCustomFieldValues)[number];

export interface AccumulatorScheduleValues {
  fixingDate: string | null;
  paymentDate: string | null;
  strike: number | null;
  strikeDown: number | null;
  strikeUp: number | null;
  ekiTrigger?: number | null;
  stepUp?: number | null;
  stepDown?: number | null;
  step: number | null;
  amount: number | null;
  leverageAmount: number | null;
}

export interface AccumulatorScheduleInputs {
  fixingDate: string | null;
  paymentDate: string | null;
  strike: string | null;
  strikeUp: string | null;
  strikeDown: string | null;
  pivot: string | null;
  ekiTrigger?: string | null;
  stepUp: string | null;
  stepDown: string | null;
  step: string | null;
  amount: string | null;
  leverageAmount: string | null;
}

export interface AccumulatorScheduleMetadata {
  custom: readonly ScheduleCustomFieldValues[];
}

export interface FxTargetAccumulatorInputs extends FxAccumulatorInputs {
  ekiString: string | null;
  eki: string | null;
  ekiUp: string | null;
  ekiDown: string | null;
  strikeDownString: string | null;
  strikeDown: string | null;
  pivotString: string | null;
  pivot: string | null;
  strikeUpString: string | null;
  strikeUp: string | null;
}

export interface FxForwardAccumulatorInputs extends FxAccumulatorInputs {}

export interface FxAccumulatorInputs {
  currencyPair: string | null;
  premiumDate: string | null;
  premiumDateTenor: string | null;
  way: Side;
  amount: string | null;
  amountCurrency: CurrencyChoice;
  leverage: string | null;
  leverageAmount: string | null;
  strike: string | null;
  strikeDown: string | null;
  pivot: string | null;
  strikeUp: string | null;
  step: string | null;
  ekiUp: string | null;
  ekiDown: string | null;
  akoTrigger: string | null;
  ekiTrigger: string | null;
  targetProfitType: string;
  target: string | null;
  firstFixingDate: string | null;
  firstFixingDateTenor: string | null;
  priceType: AccumulatorPriceType;
  priceCurrency: CurrencyChoice;

  hedgeCurrency: CurrencyChoice;
  hedgeAmount: string | null;
  hedgeType: HedgeType;
  hedgePrice: string | null;

  fixingFrequency: string;
  numberOfFixings: number;
  settlementFrequency: string | null;
  fixingReference1: string;
  fixingReference2: string;
  settlementMode: SettlementMode;
  cashSettlementCurrency: string;
  expiryDate: string | null;
  expiryTenor: string | null;
  markupCurrency: string;
  schedule: Record<string, Partial<AccumulatorScheduleInputs> | null> | null;
  isCrossed: boolean;
  crossCurrency: string;
  amountSplitType: AccumulatorAmountSplitType;
}

export type IFxTargetAccumulatorProperties = IFormData<IFxTargetAccumulatorValues, FxTargetAccumulatorInputs>;

export type FxTargetAccumulatorState = Readonly<IFxAccumulatorMetadata & IFxTargetAccumulatorProperties>;

export type IFxForwardAccumulatorProperties = IFormData<IFxForwardAccumulatorValues, FxForwardAccumulatorInputs>;

export type FxForwardAccumulatorState = Readonly<IFxAccumulatorMetadata & IFxForwardAccumulatorProperties>;

export interface IFxAccumulatorMetadata extends IFxTileMetadata {
  displayMode: 'SCHEDULER' | 'PRICER';
  legs: readonly string[];
  lastStreamId: string | null;
  summary?: string;
  numberOfSettlements: number;
}

export type FxAccumulatorState = FxTargetAccumulatorState | FxForwardAccumulatorState;

export type FxAccumulatorMap = Collection<FxAccumulatorState>;

interface FxAccuStreamStreamingAwaitingState {
  status: 'AWAITING';
  forceTradeable: boolean;
  traderId: string | null;
}

export interface FxAccuStreamStreamingWithPrice {
  status: 'PRICING' | 'EXPIRED';
  quote: Readonly<FxAccumulatorQuoteWithPrice>;
  lastQuoteReceivedDate: Date;
  initialRfsWindow: number;
  tiering: string | null;
  skippedLimitCheck: boolean;
  traderId: string | null;
  quoteContribution: QuoteContributionType | null;
}

export type FxAccumulatorRfsStream = FxAccuStreamStreamingAwaitingState | FxAccuStreamStreamingWithPrice;

export type FxAccumulatorSchedules = Collection<AccumulatorScheduleItem>;

export type FxAccumulatorRfsStreams = Collection<FxAccumulatorRfsStream>;

export interface IFxAccumulatorsState {
  accumulators: FxAccumulatorMap;
  schedules: FxAccumulatorSchedules;
  rfsStreams: FxAccumulatorRfsStreams;
}

export type FxAccumulatorsState = Readonly<IFxAccumulatorsState>;

export type FxAccumulatorPatch = Patch<IFxAccumulatorValues>;

export interface FxAccumulatorQuoteWithPrice {
  quoteId: string;
  quoteType: 'Indicative' | 'Tradeable';
  rfsWindow: number;
  markupCcy1: number;
  markupCcy2: number;
  premiumCcy1: BidAskPair;
  premiumCcy2: BidAskPair;
  premiumPctCcy1: number;
  premiumPctCcy2: number;

  hedge?: IHedge | IHedge[];

  deltaCcy1: number;
  deltaCcy2: number;
  vegaCcy1: number;
  vegaCcy2: number;
  spot: BidAskPair;
  forward: BidAskPair;
  swapPoints: BidAskPair;
  volatility: number;
  predealChecks: readonly PredealCheck[];
  frontTimestamp: Date;
  backTimestamp: number;
}

export interface IFxAccumulatorExecutionData extends IFxExecutionData {
  instrument: 'Accumulator';
  way: Way;
  amount: number;
  amountCcy: string;
  premium: number;
  premiumCcy: string;
  priceLabelId: 'Receive' | 'Pay';
  accuType: ForwardAccuType | TargetAccuType;
  strike: number;
  step: number;
  akoTrigger?: number | null;
  barrier: AccumulatorBarrier | null;
  ekiTrigger?: number | null;
  target: number;
  firstFixingDate: string;
  firstFixingTenor: string;
  expiryDate: Date;
  expiryTenor: string;
  lastFixingDate: string;
  fixingFrequency: string;
  numberOfFixings: number;
  productName: string | null;
  amountSplitType: AccumulatorAmountSplitType;
}

export type FxAccumulatorExecutionState = IFxExecutionState & IFxAccumulatorExecutionData;

export interface IHedge {
  way: HedgeWay;
  rateMid: string;
  notionalAmount: string;
  notionalCurrency: string;
}

export interface AccumulatorBarrier {
  level: number;
  type: string;
  nature: string;
}
