import type { IMarginPatch } from 'components/share/margin/MarginInputs';
import type { Thunk } from 'state';
import {
  convertToRawWithPrecision,
  updateMargin,
  convertToPointsWithPrecision,
} from 'utils/margin';

interface Margin {
  bidMargin: number | null;
  askMargin: number | null;
}

type BidAskMarginIncrement = [0.1 | -0.1, 0.1 | -0.1];

function computeUpdatedMargin(
  { bidMargin, askMargin }: Margin,
  [bidMarginIncrement, askMarginIncrement]: BidAskMarginIncrement,
  precision: number,
): Margin {
  const toRaw = convertToRawWithPrecision(precision);
  const toPoints = convertToPointsWithPrecision(precision);
  return {
    bidMargin: +toRaw(
      updateMargin(+toPoints(bidMargin ?? 0).toFixed(2), bidMarginIncrement),
    ).toFixed(precision),
    askMargin: +toRaw(
      updateMargin(+toPoints(askMargin ?? 0).toFixed(2), askMarginIncrement),
    ).toFixed(precision),
  };
}

export function swapMarginPlusThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { askMargin, bidMargin } = sl.getSwapState(state, swapId);
    const currencyPair = sl.getSwapCurrencyPair(state, swapId).value;
    const precision = currencyPair ? sl.getCurrencyPrecision(getState(), currencyPair) : 0;

    dispatch(
      ac.swapLocalPropertyChanged(
        swapId,
        computeUpdatedMargin({ bidMargin, askMargin }, [0.1, 0.1], precision),
      ),
    );
  };
}
export function swapSpotMarginPlusThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const spotMargin = sl.getDisplaySpotMargin(state, swapId);
    const currencyPair = sl.getSwapCurrencyPair(state, swapId).value;
    const precision = currencyPair ? sl.getCurrencyPrecision(getState(), currencyPair) : 0;
    const margin = computeUpdatedMargin(
      {
        bidMargin: spotMargin === null ? 0 : spotMargin.raw.bid,
        askMargin: spotMargin === null ? 0 : spotMargin.raw.ask,
      },
      [0.1, 0.1],
      precision,
    );
    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        bidSpotMargin: margin.bidMargin,
        askSpotMargin: margin.askMargin,
      }),
    );
    dispatch(ac.swapResetNearPriceThunk(swapId));
  };
}

export function swapMarginMinusThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { askMargin, bidMargin } = sl.getSwapState(state, swapId);
    const currencyPair = sl.getSwapCurrencyPair(state, swapId).value;
    const precision = currencyPair ? sl.getCurrencyPrecision(getState(), currencyPair) : 0;
    dispatch(
      ac.swapLocalPropertyChanged(
        swapId,
        computeUpdatedMargin({ bidMargin, askMargin }, [-0.1, -0.1], precision),
      ),
    );
  };
}

export function swapSpotMarginMinusThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const spotMargin = sl.getDisplaySpotMargin(state, swapId);
    const currencyPair = sl.getSwapCurrencyPair(state, swapId).value;
    const precision = currencyPair ? sl.getCurrencyPrecision(getState(), currencyPair) : 0;
    const margin = computeUpdatedMargin(
      {
        bidMargin: spotMargin === null ? 0 : spotMargin.raw.bid,
        askMargin: spotMargin === null ? 0 : spotMargin.raw.ask,
      },
      [-0.1, -0.1],
      precision,
    );
    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        bidSpotMargin: margin.bidMargin,
        askSpotMargin: margin.askMargin,
      }),
    );
    dispatch(ac.swapResetNearPriceThunk(swapId));
  };
}

export function swapMarginLeftSkewThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { askMargin, bidMargin } = sl.getSwapState(state, swapId);
    const currencyPair = sl.getSwapCurrencyPair(state, swapId).value;
    const precision = currencyPair ? sl.getCurrencyPrecision(getState(), currencyPair) : 0;
    dispatch(
      ac.swapLocalPropertyChanged(
        swapId,
        computeUpdatedMargin({ bidMargin, askMargin }, [0.1, -0.1], precision),
      ),
    );
  };
}
export function swapSpotMarginLeftSkewThunk(swapId: string): Thunk<void> {
  return (
    dispatch,
    getState,
    {
      selectors: { getDisplaySpotMargin, getCurrencyPrecision, getSwapCurrencyPair },
      actionCreators: ac,
    },
  ) => {
    const state = getState();
    const spotMargin = getDisplaySpotMargin(state, swapId);
    const currencyPair = getSwapCurrencyPair(state, swapId).value;
    const precision = currencyPair ? getCurrencyPrecision(getState(), currencyPair) : 0;

    const margin = computeUpdatedMargin(
      {
        bidMargin: spotMargin === null ? 0 : spotMargin.raw.bid,
        askMargin: spotMargin === null ? 0 : spotMargin.raw.ask,
      },
      [0.1, -0.1],
      precision,
    );
    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        bidSpotMargin: margin.bidMargin,
        askSpotMargin: margin.askMargin,
      }),
    );
    dispatch(ac.swapResetNearPriceThunk(swapId));
  };
}

export function swapMarginRightSkewThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { askMargin, bidMargin } = sl.getSwapState(state, swapId);
    const currencyPair = sl.getSwapCurrencyPair(state, swapId).value;
    const precision = currencyPair ? sl.getCurrencyPrecision(getState(), currencyPair) : 0;
    dispatch(
      ac.swapLocalPropertyChanged(
        swapId,
        computeUpdatedMargin({ bidMargin, askMargin }, [-0.1, 0.1], precision),
      ),
    );
  };
}

export function swapSpotMarginRightSkewThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const spotMargin = sl.getDisplaySpotMargin(state, swapId);
    const currencyPair = sl.getSwapCurrencyPair(state, swapId).value;
    const precision = currencyPair ? sl.getCurrencyPrecision(getState(), currencyPair) : 0;

    const margin = computeUpdatedMargin(
      {
        bidMargin: spotMargin.raw.bid,
        askMargin: spotMargin.raw.ask,
      },
      [-0.1, 0.1],
      precision,
    );
    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        bidSpotMargin: margin.bidMargin,
        askSpotMargin: margin.askMargin,
      }),
    );
    dispatch(ac.swapResetNearPriceThunk(swapId));
  };
}

export function swapMarginResetThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    let bidMargin = null;
    let askMargin = null;

    const state = getState();
    const { currentStreamId } = sl.getSwapState(state, swapId);

    if (currentStreamId !== null) {
      const currentStream = sl.getSwapRfsStream(state, currentStreamId);
      if (currentStream.status === 'PRICING') {
        const { bid, ask } = currentStream.quote.defaultMargin;
        bidMargin = bid;
        askMargin = ask;
      }
    }

    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        bidMargin,
        askMargin,
      }),
    );
  };
}
export function swapSpotMarginResetThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    let bidSpotMargin = null;
    let askSpotMargin = null;

    const state = getState();
    const { currentStreamId } = sl.getSwapState(state, swapId);

    if (currentStreamId !== null) {
      const currentStream = sl.getSwapRfsStream(state, currentStreamId);
      if (currentStream.status === 'PRICING') {
        const { bid, ask } = currentStream.quote.defaultSpotMargin;
        bidSpotMargin = bid;
        askSpotMargin = ask;
      }
    }

    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        bidSpotMargin,
        askSpotMargin,
      }),
    );
    dispatch(ac.swapResetNearPriceThunk(swapId));
  };
}

export function swapMarginChangedThunk(
  swapId: string,
  { askMargin, bidMargin }: IMarginPatch,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const currencyPair = sl.getSwapCurrencyPair(getState(), swapId).value;
    const precision = currencyPair ? sl.getCurrencyPrecision(getState(), currencyPair) : 0;
    const toRaw = convertToRawWithPrecision(precision);
    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        askMargin: askMargin !== null ? toRaw(askMargin) : null,
        bidMargin: bidMargin !== null ? toRaw(bidMargin) : null,
      }),
    );
  };
}
export function swapSpotMarginChangedThunk(
  swapId: string,
  { askMargin, bidMargin }: IMarginPatch,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const currencyPair = sl.getSwapCurrencyPair(state, swapId).value;
    const precision = currencyPair ? sl.getCurrencyPrecision(getState(), currencyPair) : 0;
    const toRaw = convertToRawWithPrecision(precision);
    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        askSpotMargin: askMargin !== null ? toRaw(askMargin) : null,
        bidSpotMargin: bidMargin !== null ? toRaw(bidMargin) : null,
      }),
    );
    dispatch(ac.swapResetNearPriceThunk(swapId));
  };
}

export function swapSwitchMarkupCurrencyThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const { markupCurrency } = sl.getSwapState(getState(), swapId);

    dispatch(
      ac.swapLocalPropertyChanged(swapId, {
        markupCurrency: markupCurrency === 1 ? 2 : 1,
      }),
    );
  };
}

export function swapResetNearPriceThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const { value } = sl.getSwapNearPrice(getState(), swapId);
    if (value !== null) {
      dispatch(
        ac.swapPropertyChanged(swapId, {
          nearPriceReference: null,
        }),
      );
    }
  };
}
