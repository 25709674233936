import { parseISO } from 'date-fns';
import type { AppState } from 'state/model';
import type { FxBulkLegState } from 'state/fxBulks/fxBulksModel';
import { getTileMaxDate } from 'state/referenceData/referenceDataSelectors';
import { getBulkLegState } from './bulkLeg';
import { getBulkLegs } from './bulkMetadata';

export function hasBulkProduct(state: AppState, bulkId: string): boolean {
  return state.fxBulks.bulks[bulkId] !== undefined;
}

function isFarDateValid(maxDate: Date, n: FxBulkLegState): boolean {
  return !n.values.farPaymentDate || parseISO(n.values.farPaymentDate) <= maxDate;
}

function isDateValid(maxDate: Date, n: FxBulkLegState): boolean {
  return !n.values.paymentDate || parseISO(n.values.paymentDate) <= maxDate;
}

export function isValidDateBulk(currentState: AppState, bulkId: string) {
  const maxDate = getTileMaxDate(currentState, bulkId);
  if (maxDate === undefined) {
    return true;
  }
  const legsIds = getBulkLegs(currentState, bulkId);
  const legs = legsIds.map(c => getBulkLegState(currentState, bulkId, c));
  const result = legs.reduce(
    (c, n) => c && isFarDateValid(maxDate, n) && isDateValid(maxDate, n),
    true,
  );
  return result;
}
