import type { UserPrefConnectProps } from './connect/connectUserPref';
import { SwitchInput } from 'components/share/formElements/SwitchInput';
import { FormattedMessage } from 'react-intl';
import { connectUserPref } from './connect';
import { connectBlotterLoading } from 'components/Blotter/connect';
import type { BlotterMode } from 'state/blotter/blotterModel';

type ShowMyMainDealsProps = UserPrefConnectProps<'showMyMainDeals'> & {
  fetching: BlotterMode | null;
  canRefetch: boolean;
};

const ShowMyMainDeals: React.FunctionComponent<ShowMyMainDealsProps> = (props: ShowMyMainDealsProps) => {
  const { userPrefValue: showMyMainDeals, fetching, canRefetch, onChangeUserPref: onShowMyMainDealsChange } = props;

  return (
    <div className="card-body d-flex justify-content-between p-3 px-md-4">
      <SwitchInput
        value={showMyMainDeals}
        onChange={onShowMyMainDealsChange}
        disabled={!canRefetch}
        e2eHandle="showMyMainDeals"
        size="sm"
      >
        <FormattedMessage id="userPreferencesForm.showMyMainDeals.label" />

        {fetching && <div className="ms-2 spinner spinner-sm"></div>}
      </SwitchInput>
    </div>
  );
};

export const ShowMyMainDealsPref = connectBlotterLoading(connectUserPref('showMyMainDeals')(ShowMyMainDeals));
