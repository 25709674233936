import type { IStreamError } from 'state/globalError/globalErrorModel';
import type { Client, Instrument } from 'state/referenceData/referenceDataModel';

export interface IFxExecutionData {
  instrument: Instrument;
  currencyPair: string;
  client: Client;
  tradeDate: Date;
}

export interface IFxExecutionPendingState {
  status: 'Pending';
}

export interface IFxExecutionStillPendingState {
  status: 'StillPending';
}

interface IFxExecutionTimeoutedDealState {
  status: 'TimeoutedDeal';
}

interface IFxExecutionSuccessState {
  status: 'Success';
}

export interface IFxExecutionFillReportState {
  status: 'BackOfficeValidated';
  tradeIds: readonly string[];
}

interface IFxExecutionNotIntegratedState {
  status: 'NotIntegrated';
}

export interface IFxExecutionErrorState {
  status: 'Error';
  streamError: IStreamError;
}

export type IFxExecutionState =
  | IFxExecutionPendingState
  | IFxExecutionStillPendingState
  | IFxExecutionTimeoutedDealState
  | IFxExecutionSuccessState
  | IFxExecutionNotIntegratedState
  | IFxExecutionFillReportState
  | IFxExecutionErrorState;

export type ExecutionStatus = IFxExecutionState['status'];

export const enum TileExecutionStatus {
  'pending',
  'done',
  'failed',
}
