import * as MenuButtons from './MenuButtons';
import { UserHasAccess } from 'components/share/RenderWhen';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import en from './locales/en.json';
import fr from './locales/fr.json';
import { TabList } from "./TabList/TabList";

const messagesMap = { en, fr };

export function NavMenu(): JSX.Element {
  return (
    <UserHasAccess>
      <nav className="d-flex nav-tabs justify-content-between flex-shrink-0">
        <IntlComponentBoundary messagesMap={messagesMap}>
          <div className="overflow-hidden d-flex flex-nowrap flex-grow-1 ps-4">
            <TabList />
            <MenuButtons.AddTab />
          </div>
          <div className="d-flex">
            <MenuButtons.TabsMenu />
            <MenuButtons.SaveWorkspace />
            <MenuButtons.Lock />
          </div>
        </IntlComponentBoundary>
      </nav>
    </UserHasAccess>
  );
}
NavMenu.displayName = 'NavMenu';
