import { FormattedMessage } from "react-intl";
import { Popover } from "reactstrap";
import { withQuoteId } from "components/contexts";
import { RefToggle } from "components/share/RefToggle";
import type { IPredealChecksOwnProps, IPredealChecksStateProps } from "./PredealChecksConnect";
import { connectPredealChecks } from "./PredealChecksConnect";
import type { PredealCheck, PredealCheckStatusHealth } from "state/share/predealChecksModel";

type PredealChecksProps = IPredealChecksOwnProps & IPredealChecksStateProps;

export function PredealChecksRaw({ predealChecks, globalStatus, isVisible }: PredealChecksProps) {
  const [, color] = statusToStylename(globalStatus);

  return (
    <RefToggle<HTMLElement>>
      {popover =>
        isVisible && (
          <>
            <i
              data-e2e-color={color}
              data-e2e="global-predeal"
              className={`icon me-1 cursor-pointer ${color} fs-14`}
              ref={popover.ref}
              onClick={popover.toggle}
            >
              lens
            </i>
            {popover.target !== null && (
              <Popover
                id="predealCheck-popover"
                placement="right"
                trigger="legacy"
                isOpen={popover.isOpen}
                target={popover.target}
                toggle={popover.toggle}
              >
                <div className="card shadow text-primary" data-nodrag>
                  <div className="card-body p-3">
                    <FormattedMessage id="predealChecks.header" tagName="h5" />
                    {(predealChecks || []).map(predealCheck => (
                      <div
                        className="d-flex justify-content-between border-top py-1"
                        key={predealCheck.label}
                      >
                        <PredealCheckLine predealCheck={predealCheck} />
                      </div>
                    ))}
                  </div>
                </div>
              </Popover>
            )}
          </>
        )
      }
    </RefToggle>
  );
}

export const PredealChecks = withQuoteId(connectPredealChecks(PredealChecksRaw));

type Icon = 'lens' | 'close';
export type ClassName = 'text-muted' | 'text-success' | 'text-warning' | 'text-danger';

const statusToIcon: Record<PredealCheckStatusHealth, [Icon, ClassName]> = {
  Unknown: ['lens', 'text-muted'],
  Ok: ['lens', 'text-success'],
  Nok: ['lens', 'text-danger'],
  Warning: ['lens', 'text-warning'],
  Missing: ['close', 'text-muted'],
};

const statusToStylename = (predealCheckStatusHealth: PredealCheckStatusHealth): [Icon, ClassName] =>
  statusToIcon[predealCheckStatusHealth];

const PredealCheckLine: React.FunctionComponent<{
  predealCheck: PredealCheck;
}> = ({ predealCheck }) => {
  switch (predealCheck.type) {
    case 'Icon':
      const [icon, color] = statusToStylename(predealCheck.statusHealth);
      return (
        <>
          {predealCheck.label}{' '}
          <i
            data-e2e-color={color}
            data-e2e={`${predealCheck.label}-predeal`}
            className={`icon ${color}`}
          >
            {icon}
          </i>
        </>
      );
    case 'Label':
      return (
        <>
          <div>{predealCheck.label}</div>{' '}
          <div data-e2e={`${predealCheck.label}-text-predeal`}>{predealCheck.statusValue}</div>
        </>
      );
  }
};
