import { addKey, updateKey, removeKey, removeKeys } from 'utils/stateMap';
import type { Reducer } from 'redux';
import { type FxSwapStateMap, emptyFxSwapState, type FxSwapState } from '../fxSwapsModel';
import type { Action } from 'state/actions';
import { addPriceRecorded } from 'state/share/priceRecordReducerHelper';
import { productPatcher } from 'state/share/patchHelper';
import { validationPartialReducer } from 'state/share/validationReducer';

const swapValidationReducer = validationPartialReducer<'Swap', FxSwapState>('Swap');

export const swapsReducer: Reducer<FxSwapStateMap> = (
  state: FxSwapStateMap = {},
  action: Action,
): FxSwapStateMap => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      return action.savedTile.instrument === 'Swap'
        ? addKey(state, action.tileId, emptyFxSwapState)
        : state;
    case 'RECORD_PRICE_SUCCEEDED':
    case 'RECORD_PRICE_FAILED':
      if (action.instrument !== 'Swap') {
        return state;
      }
      return updateKey(
        state,
        action.tileId,
        addPriceRecorded({
          priceRecord: {
            error: action.error,
            prices: action.error === true ? undefined : action.prices,
            timestamp: action.timestamp,
          },
        }),
      );
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      return action.instrument === 'Swap'
        ? addKey(state, action.tileId, emptyFxSwapState)
        : removeKey(state, action.tileId);
    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
      return action.instrument === 'Swap'
        ? addKey(state, action.tileId, {
            ...emptyFxSwapState,
            ...action.tradeCaptureSessionInfos,
          })
        : removeKey(state, action.tileId);
    case 'CLIENTWORKSPACE_TILE_DELETED':
      return removeKey(state, action.tileId);
    case 'SWAP_PROPERTIES_CHANGED':
      return updateKey(state, action.swapId, swap => ({
        inputs: {
          ...swap.inputs,
          ...action.patch,
        },
      }));
    case 'SWAP_LOCAL_PROPERTY_CHANGED':
      return updateKey(state, action.swapId, () => ({
        ...action.patch,
      }));
    case 'SWAP_PROPERTIES_REQUESTED':
      return updateKey(state, action.swapId, () => ({
        propertiesRequested: true,
        currentSessionId: action.sessionId,
      }));
    case 'SWAP_PROPERTIES_REQUEST_FAILED':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: false,
        propertiesRequestError: action.error,
      }));
    case 'SWAP_PROPERTIES_RECEIVED':
      return productPatcher(state, action, () => ({ isPriceObsolete: action.isPriceObsolete }));
    case 'SWAP_TILE_RESET':
      return updateKey(state, action.quoteId, () => emptyFxSwapState);
    case 'SWAP_RFS_STARTED':
      return updateKey(state, action.swapId, () => ({
        rfsStartedAt: action.rfsStartedAt,
        priceRecords: [],
        currentStreamId: action.streamId,
        lastStreamError: null,
      }));
    case 'SWAP_RFS_FAILED':
      return updateKey(state, action.swapId, _swap => ({
        currentStreamId: null,
        lastStreamError: action.error,
      }));
    case 'SWAP_RFS_CANCEL':
      return updateKey(state, action.swapId, () => ({ currentStreamId: null }));
    case 'SWAP_RFS_CLEAR_ERROR':
      return updateKey(state, action.quoteId, () => ({
        currentStreamId: null,
        lastStreamError: null,
      }));
    case 'SWAP_RFS_TERMINATED':
      return updateKey(state, action.swapId, swap => {
        if (swap.currentStreamId !== action.streamId) {
          return null;
        }
        return {
          askMargin: null,
          bidMargin: null,
          askSpotMargin: null,
          bidSpotMargin: null,
          currentStreamId: null,
          lastStreamError: null,
        };
      });
    case 'SWAP_EXECUTION_SENT':
      return updateKey(state, action.swapId, () => ({
        currentStreamId: null,
        askMargin: null,
        bidMargin: null,
        askSpotMargin: null,
        bidSpotMargin: null,
        warnings: {},
        lastExecutedQuoteId: action.quoteId,
        currentExecutionId: action.executionId,
      }));
    case 'TILE_EXECUTION_OVERLAY_HIDDEN':
      return updateKey(state, action.quoteId, () => ({
        currentExecutionId: null,
      }));
    case 'SWAP_QUOTE_RECEIVED':
      return updateKey(state, action.swapId, swap => {
        if (swap.currentStreamId !== action.streamId) {
          return null;
        }
        return {
          askMargin: swap.askMargin === null ? action.quote.defaultMargin.ask : swap.askMargin,
          bidMargin: swap.bidMargin === null ? action.quote.defaultMargin.bid : swap.bidMargin,
          askSpotMargin:
            swap.askSpotMargin === null ? action.quote.defaultSpotMargin.ask : swap.askSpotMargin,
          bidSpotMargin:
            swap.bidSpotMargin === null ? action.quote.defaultSpotMargin.bid : swap.bidSpotMargin,
        };
      });
    case 'SWAP_PROPERTIES_REMOVE_ERROR':
      return updateKey(state, action.swapId, swap => ({
        ...swap,
        errors: removeKeys(swap.errors, action.keys),
        warnings: removeKeys(swap.warnings, action.keys),
      }));
    case 'SWAP_AMOUNT_CURRENCY_MASK_CHANGED':
      return updateKey(state, action.swapId, keyState => ({
        values: {
          ...keyState.values,
          amountCurrency: action.amountCurrency,
        },
      }));
    case 'FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Swap'
        ? state
        : updateKey(state, action.quoteId, ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));
  }
  return swapValidationReducer(state, action);
};
