import type { Selectors } from 'state/selectors';
import type { Mappers } from './typings';
import { liftToString } from './utils';

export const cashMappers = (sl: Selectors): Mappers<'Cash', 'maturityDateTenor'> => ({
  currencyPair: sl.getCashCurrencyPair,
  amount: liftToString(sl.getCashAmount),
  amountCurrency: sl.getCashAmountCurrency,
  maturityDate: sl.getCashMaturityDate,
  isNonDeliverable: sl.getCashIsNonDeliverable,
  fixingCurrency: sl.getCashFixingCurrency,
  fixingSource: sl.getCashFixingSource,
  sndFixingSource: sl.getCashSndFixingSource,
  xCurrency: sl.getCashXCurrency,
});
