import type { Observable } from 'rxjs';
import type { Way } from 'state/share/productModel/litterals';
import type { HttpCall, ISgmeHttp } from 'utils/sgmeHttp';
import { transformEmailsToBase64 } from 'utils/base64';

export interface CashRfsExecutePayload {
  replyToStream: string;
  rfsId: string;
  quoteId: string;
  way: Way;
  spotWithoutMargin: number;
  spotWithMargin: number;
  forwardPoints: number;
  emailNotificationList: string;
  userLocalDateTime: string;
  lastQuoteFromBackTimestamp: number;
  lastQuoteReceivedByFrontTimestamp: number;
  executionClickByFrontTimestamp: number;
}

export interface ICashRfsQuoteExecuteRequestApi {
  msApiTimeout: number;
  executeQuoteRequest(payload: CashRfsExecutePayload): Observable<HttpCall<void>>;
}

export function cashRfsQuoteExecuteRequestApi(http: ISgmeHttp): ICashRfsQuoteExecuteRequestApi {
  return {
    executeQuoteRequest({
      replyToStream,
      rfsId,
      quoteId,
      way,
      spotWithoutMargin,
      spotWithMargin,
      forwardPoints,
      emailNotificationList,
      userLocalDateTime,
      lastQuoteFromBackTimestamp,
      lastQuoteReceivedByFrontTimestamp,
      executionClickByFrontTimestamp,
    }) {
      const payload = {
        replyToStream,
        rfsId,
        quoteId,
        way,
        priceWithoutMargin: spotWithoutMargin,
        priceWithMargin: spotWithMargin,
        forwardPoints,
        emailNotificationList,
        userLocalDateTime,
        lastQuoteFromBackTimestamp,
        lastQuoteReceivedByFrontTimestamp,
        executionClickByFrontTimestamp,
      };
      return http.post<void>(
        'api/fxSpotFwd/rfs/execute',
        transformEmailsToBase64(payload, 'emailNotificationList', 'email64NotificationList'),
        { 'X-Correlation-ID': payload.rfsId },
      );
    },
    msApiTimeout: 60_000,
  };
}
