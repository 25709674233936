import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import type { Store } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import { ServiceLoader } from '@sgme/ui';

import { IntlProvider, langKey } from 'components/contexts/IntlContext';
import { CrashModal } from 'components/Modals';

import type { AppState } from 'state/model';
import { App } from './components/App';
import { initSgBootstrap } from './utils/theme';

import './main.scss';

import 'react-datepicker/dist/react-datepicker.css';

import en from 'components/share/locales/en.json';
import fr from 'components/share/locales/fr.json';
import { SgwtWebAnalytics, SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';
import { getConfig } from 'config/config';

const globalMessagesMap = { en, fr };

initSgBootstrap();

function getLang() {
  const lang = localStorage.getItem(langKey);
  // Revert lang to english for users who had selected german
  if (lang === 'de') {
    localStorage.setItem(langKey, 'en');
    return 'en';
  }
  return lang ?? 'en';
}

const lang = getLang();
setTimeout(() => {
  document.querySelector('html')!.lang = lang;
});

export function renderReactApp(store: Store<AppState>) {
  const container = document.getElementById('main');
  const root = createRoot(container!);

  const {
    piwik: { piwikRootUrl, siteId },
    SGWTWidgetConfiguration: { environment },
  } = getConfig();

  root.render(
    <ReduxProvider store={store}>
      <IntlProvider locale={lang} globalMessagesMap={globalMessagesMap}>
        <StrictMode>
          <SgwtWidgetContextProvider infrastructure="azure">
            <SgwtWebAnalytics
              siteId={siteId}
              baseUrl={piwikRootUrl}
              debug={environment === 'homologation'} // To show debug on homolo env
            />
            <ServiceLoader>
              <CrashModal />
              <App />
            </ServiceLoader>
          </SgwtWidgetContextProvider>
        </StrictMode>
      </IntlProvider>
    </ReduxProvider>,
  );
}
