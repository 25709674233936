import styled from 'styled-components';

const Backdrop = styled.div`
  width: 3rem;
  height: 3rem;
`;

export interface EmphasizedIconProps {
  icon: string;
  color: 'info' | 'success' | 'warning' | 'danger';
}

export const EmphasizedIcon: React.FunctionComponent<EmphasizedIconProps> = ({ icon, color }) => (
  <Backdrop
    className={`d-flex align-items-center justify-content-center bg-${color} bg-opacity-10 rounded-circle`}
  >
    <i className={`icon icon-md text-${color} line-height-1`}>{icon}</i>
  </Backdrop>
);
