import { useEffect, useState, type ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  FormattedMessage,
  type WrappedComponentProps as InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import { isCrashError } from 'state/globalError/globalErrorSelectors';
import type { AppState } from 'state/model';
import { HelpButton } from './HelpButton';
import { isDefined, noop } from '@sgme/fp';
import { UncontrolledCollapsable } from 'components/share/formElements/Collapsable';
import styled from 'styled-components';
import type { SendHelpStatus } from 'state/globalError/globalErrorModel';
import { unminifyStackTrace } from '@sgme/stacktrace';
import { logger } from 'logging/logger';

interface CrashModalProps {
  error: string | null;
}

const reload = () => window.location.reload();

const TechnicalDetails = styled.pre`
  min-height: 100px;
  max-height: 500px;
  min-width: 500px;
  overflow: auto;
`;

const CrashModalRaw = ({ error, intl }: CrashModalProps & InjectedIntlProps) => {
  const [userDetails, setUserDetails] = useState("");
  const [stackTrace, setStackTrace] = useState<string | null | undefined>("");
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    const handleError = async (error: string) => {
      let errorMessage = "";
      if (isDefined(error) && typeof error === 'string') {
        errorMessage = error.substring(0,error.indexOf('\n'))
      }
      const beautifulStackTrace = await unminifyStackTrace(error);
      setStackTrace(beautifulStackTrace);
      setErrorMessage(errorMessage);
    }

    if (error) {
      handleError(error).catch((err) => {
        logger.logError('Unable to translate stack trace {message_s}', err.message);
      })
    }
  }, [error])

  if (error === null) {
    return;
  }

  const handChangeUserDetails = (event: ChangeEvent<HTMLTextAreaElement>) => setUserDetails(event.currentTarget.value);

  const getDetails = () => userDetails !== ''
    ? `${userDetails}\n<b>Technical error:</b> ${error}`
    : null;

  const sendHelpRequestCallback = (status: SendHelpStatus) => {
    if (status === 'success') {
      reload();
    }
  };

  if (error === null) {
    return null;
  }

  return (
    <Modal size='lg' isOpen toggle={noop} contentClassName="border border-danger">
      <ModalHeader tag="h4" className="text-danger">
        <FormattedMessage id="modals.crash.title" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage tagName="p" id="modals.crash.body" />
        <textarea
          className="form-control w-100"
          rows={4}
          placeholder={intl.formatMessage({
            id: 'modals.crash.userDetailsPlaceholder',
          })}
          onChange={(e) => handChangeUserDetails(e)}
          value={userDetails}
        />
        <UncontrolledCollapsable
          title={intl.formatMessage({ id: 'modals.crash.moreDetails' })}
          collapsed
          e2eHandle="details"
        >
          <TechnicalDetails className="border bg-lvl2 text-primary mt-3 p-3 text-start">
            <h3>Message</h3>
            <code>
              {errorMessage}
            </code>
            <h4>StackTrace</h4>
            <code>
              {stackTrace}
            </code>
          </TechnicalDetails>
        </UncontrolledCollapsable>
      </ModalBody>
      <ModalFooter>
        {userDetails !== '' ? (
          <HelpButton
            labelId="modals.crash.sendAndReload"
            includeLastActions
            includeState
            details={getDetails}
            statusUpdateCallBack={sendHelpRequestCallback}
          />
        ) : (
          <button type="button" className="btn btn-primary" onClick={reload}>
            <FormattedMessage id="modals.crash.reload" />
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state: AppState): CrashModalProps => ({
  error: isCrashError(state) ? state.globalError.error : null,
});

export const CrashModal = connect(mapStateToProps)(injectIntl(CrashModalRaw));
