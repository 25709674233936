import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { IWorkspaceTabs } from 'state/clientWorkspace';

export interface TabsMenuConnectOwnProps {}

export interface TabsMenuConnectStateProps {
  tabs: IWorkspaceTabs;
  activeTab: string | null;
}

export interface TabsMenuConnectDispatchProps {}

// you can whitelist selectors after implementation
export type TabsMenuConnectSelectors = Selectors;
/*
export type TabsMenuConnectSelectorsKeys = 'aSelector'; // example
export type TabsMenuConnectSelectors = Pick<Selectors, TabsMenuConnectSelectorsKeys>;
*/

export const mapStateToPropsTabsMenu: MapStateToPropsHOF<
  TabsMenuConnectStateProps,
  TabsMenuConnectOwnProps,
  AppState,
  TabsMenuConnectSelectors
> = sl => (state, _ownProps) => ({
  activeTab: sl.getClientWorkspaceActiveTab(state),
  tabs: sl.getClientWorkspaceTabs(state),
});

// you can whitelist action creators after implementation
export type TabsMenuConnectActionCreators = ActionCreators;
/*
export type TabsMenuConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type TabsMenuConnectActionCreators = Pick<ActionCreators, TabsMenuConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsTabsMenu: MapDispatchToPropsHOF<
  TabsMenuConnectDispatchProps,
  TabsMenuConnectOwnProps,
  TabsMenuConnectActionCreators
> = _ac => (_dispatch, _ownProps) => ({});
