import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';

export interface QuickBlotterButtonConnectOwnProps {}

export interface QuickBlotterButtonConnectStateProps {
  isVisible: boolean;
}

export interface QuickBlotterButtonConnectDispatchProps {
  onShowBlotter(): void;
}

// you can whitelist selectors after implementation
export type QuickBlotterButtonConnectSelectors = Selectors;
/*
export type QuickBlotterButtonConnectSelectorsKeys = 'aSelector'; // example
export type QuickBlotterButtonConnectSelectors = Pick<Selectors, QuickBlotterButtonConnectSelectorsKeys>;
*/

export const mapStateToPropsQuickBlotterButton: MapStateToPropsHOF<
  QuickBlotterButtonConnectStateProps,
  QuickBlotterButtonConnectOwnProps,
  AppState,
  QuickBlotterButtonConnectSelectors
> = sl => (state, _ownProps) => ({
  isVisible: !sl.getBlotterIsOpen(state),
});

// you can whitelist action creators after implementation
export type QuickBlotterButtonConnectActionCreators = ActionCreators;
/*
export type QuickBlotterButtonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type QuickBlotterButtonConnectActionCreators = Pick<ActionCreators, QuickBlotterButtonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsQuickBlotterButton: MapDispatchToPropsHOF<
  QuickBlotterButtonConnectDispatchProps,
  QuickBlotterButtonConnectOwnProps,
  QuickBlotterButtonConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  onShowBlotter() {
    dispatch(ac.blotterToggle(true));
  },
});
