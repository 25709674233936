interface IInitialisingState {
  status: 'INITIALISING';
}

interface IConnectedState {
  status: 'CONNECTED';
  connectionId: string;
  degraded: boolean;
  driver: string;
}

interface IDisconnectedState {
  status: 'DISCONNECTED';
  reason: string;
}

export type StreamingConnectionState =
  | Readonly<IInitialisingState>
  | Readonly<IConnectedState>
  | Readonly<IDisconnectedState>;

export const isStreamingStatusInitialising = (
  state: StreamingConnectionState,
): state is IConnectedState => state.status === 'INITIALISING';

export const isStreamingStatusConnected = (
  state: StreamingConnectionState,
): state is IConnectedState => state.status === 'CONNECTED';

export const isStreamingStatusDisconnected = (
  state: StreamingConnectionState,
): state is IDisconnectedState => state.status === 'DISCONNECTED';

export const patchConnected =
  (patch: Partial<IConnectedState>) => (state: StreamingConnectionState) =>
    isStreamingStatusConnected(state) ? { ...state, ...patch } : state;
