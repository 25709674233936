import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { updateKey, addKey, removeKey } from 'utils/stateMap';
import type {
  FxEspStreamsMap,
  EspStreamStreamingState,
  IEspStreamAwaitingState,
  IEspStreamInvalidState,
} from './espStreamsModel';

export const espStreamsReducer: Reducer<FxEspStreamsMap> = (
  state: FxEspStreamsMap = {},
  action: Action,
): FxEspStreamsMap => {
  switch (action.type) {
    case 'ESP_STREAM_TILE_UNSUBSCRIBE':
      return updateKey(state, action.streamKey, ({ refCount }) => ({
        refCount: refCount - 1,
      }));

    case 'ESP_REFCOUNT_RESET':
      const stream: IEspStreamAwaitingState = {
        status: 'AWAITING',
        refCount: 0,
        tiering: action.tiering,
      };

      return addKey(state, action.streamId, stream);

    case 'ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED':
      return updateKey(state, action.streamId, ({ refCount }) => ({
        refCount: refCount + 1,
      }));

    case 'ESP_HEARTBEAT_MISSED':
      return updateKey(
        state,
        action.streamId,
        () =>
          ({
            status: 'AWAITING',
          } as IEspStreamAwaitingState),
      );

    case 'ESP_INVALID_PRICE_RECEIVED':
      return updateKey(
        state,
        action.streamId,
        () =>
          ({
            status: 'INVALID',
          } as IEspStreamInvalidState),
      );

    case 'ESP_PRICE_RECEIVED':
      return updateKey(
        state,
        action.streamId,
        () =>
          ({
            status: 'PRICING',
            quoteId: action.quoteId,
            ladders: action.ladders,
            multipassInstanceId: action.multipassInstanceId,
            priceType: action.priceType,
            missedHeartbeat: false,
            priceDecodedTime: action.priceDecodedTime,
            frontTimestamp: action.frontTimestamp,
            backTimestamp: action.backtimestamp,
          } as EspStreamStreamingState),
      );

    case 'ESP_STREAM_UNSUBSCRIPTION_REQUEST_SENT':
      return removeKey(state, action.espStreamId);

    default:
      return state;
  }
};
