import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { EventLog, LogLevel } from 'api/eventLog';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { getLegDealInfo, getLogInstrumentType } from './tools';

type SelectorKeys = 'getUserInfo' | 'getExecutionById' | 'hasExecution';

export type InjectedSelectors = Pick<Selectors, SelectorKeys>;
export interface ApiTimeoutExecutionEventLogRequest {
  type: LogLevel;
  data: EventLog;
}

export interface ApiTimeoutExecutionEventLogShell {
  executionId: string;
  reason: string;
}

export const metaSelectorBuildApiErrorExecutionEventLogWith: MapStateToMetadataHOF<
  ApiTimeoutExecutionEventLogRequest | undefined,
  ApiTimeoutExecutionEventLogShell,
  AppState,
  InjectedSelectors
> =
  sl =>
  (state, { executionId, reason }) => {
    if (!sl.hasExecution(state, executionId)) {
      return;
    }
    const execution = sl.getExecutionById(state, executionId);
    const [currency1, currency2] = execution.currencyPair.split('/');

    const instrument = getLogInstrumentType(execution);
    const legs = getLegDealInfo(execution);
    const log: EventLog = {
      login: sl.getUserInfo(state).login || 'Unknown',
      userAction: 'Execution',
      actionOutcome: 'Pending',
      correlationId: executionId,
      dealInfo: {
        clientId: execution.client.companyId.toString(),
        clientName: execution.client.companyName,
        currency1,
        currency2,
        legs,
      },
      instrument,
      additionalData: {
        reason,
      },
    };
    return { type: 'warn', data: log };
  };
