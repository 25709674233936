import type { Thunk } from 'state';
import { assertIsDefined } from '@sgme/fp';
import type { OrderTemplate } from '../fxOrdersModel';

export const orderSaveAsNewTemplate =
  (orderId: string, templateName: string): Thunk<void> =>
  (dispatch, getState, { selectors: sl, actionCreators: ac, getNewGuid }) => {
    const state = getState();
    const fields = sl.getFieldsForTemplates(state, orderId);
    const template: OrderTemplate = {
      id: getNewGuid(),
      name: templateName,
      fields,
    };

    dispatch(ac.orderTemplateSaved(template));
    dispatch(ac.orderTemplateChanged(orderId, template.id));
    dispatch(ac.saveOrderTemplatesRequestedEpic(getState().fxOrders.templates));
  };

export const orderUpdateTemplate =
  (orderId: string): Thunk<void> =>
  (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const templateId = sl.getOrderTemplateId(state, orderId);
    assertIsDefined(templateId, 'templateId needed to update');
    const newFields = sl.getFieldsForTemplates(state, orderId);

    dispatch(ac.orderTemplateUpdated(templateId, newFields));
    dispatch(ac.saveOrderTemplatesRequestedEpic(getState().fxOrders.templates));
  };

export const orderApplyTemplate =
  (orderId: string, templateId: string): Thunk<void> =>
  (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const template = sl.getOrderTemplateById(getState(), templateId);
    assertIsDefined(template, `Unexpected undefined template for id ${templateId}`);
    dispatch(ac.orderTemplateApplied(templateId));
    dispatch(ac.orderTemplateChanged(orderId, templateId));
    dispatch(ac.orderPropertyChangedFromTemplate(orderId, template.fields));
  };

export const orderDeleteTemplate =
  (templateId: string): Thunk<void> =>
  (dispatch, getState, { actionCreators: ac }) => {
    dispatch(ac.orderTemplateDeleted(templateId));
    dispatch(ac.saveOrderTemplatesRequestedEpic(getState().fxOrders.templates));
  };
