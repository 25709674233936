import { PureComponent } from 'react';
import type { RenderPropChildren } from 'typings/utils';

interface HandlerState {}
interface HandlerProps<V> {
  value: V;
}

type HandlerFunction<V, T extends readonly any[], R> = (value: V) => ExposedHandler<T, R>;

type ExposedHandler<T extends readonly any[], R> = (...args: T) => R;

export const createHandler = function createHandler<V, A extends readonly any[], R>(
  scopedHandler: HandlerFunction<V, A, R>,
) {
  return class Handler extends PureComponent<
    RenderPropChildren<ExposedHandler<A, R>> & HandlerProps<V>,
    HandlerState
  > {
    private handler: ExposedHandler<A, R> = (...args) => {
      const partiallyAppliedHandler = scopedHandler(this.props.value);
      return partiallyAppliedHandler(...args);
    };
    private get childrenProps(): ExposedHandler<A, R> {
      return this.handler;
    }
    public render() {
      return this.props.children(this.childrenProps);
    }
  };
};
