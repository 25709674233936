import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import {
  defaultUserPreferenceData,
  type UserPreferencesData,
} from 'state/userPreferences/userPreferencesModel';

export const userPreferenceDataReducer: Reducer<UserPreferencesData> = (
  state = defaultUserPreferenceData,
  action: Action,
) => {
  switch (action.type) {
    case 'USER_PREFERENCES_DATA_RECEIVED':
      if (action.data) {
        return { ...state, ...action.data };
      }
      break;
    case 'USER_PREFERENCES_DATA_CHANGED':
      return {
        ...state,
        ...action.patch,
      };
  }
  return state;
};
