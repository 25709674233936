import type { AccumulatorSavedTile } from 'api/workspaceService/model';
import type {
  AccumulatorScheduleInputs,
  FxForwardAccumulatorInputs,
  FxTargetAccumulatorInputs,
  IFxAccumulatorValues,
} from '../fxAccumulatorsModel';
import type {
  AccumulatorPropertiesReceived,
  AccumulatorPropertiesRequested,
  AccumulatorPropertiesRequestFailed,
} from './tradeCapture';
import type {
  AccumulatorRfsCanceled,
  AccumulatorRfsCanceledSent,
  AccumulatorRfsCancelFailed,
  AccumulatorRfsClearError,
  AccumulatorRfsFailed,
  AccumulatorRfsLastRemoved,
  AccumulatorRfsQuoteReceived,
  AccumulatorRfsRequestEpic,
  AccumulatorRfsStarted,
  AccumulatorRfsTerminated,
  AccumulatorTraderNotificationReceived,
} from './rfs';
import type {
  AccumulatorExecutionFailed,
  AccumulatorExecutionReceived,
  AccumulatorExecutionRequested,
  AccumulatorExecutionSent,
  AccumulatorExecutionSentSucceeded,
  AccumulatorFillReportReceived,
} from './execution';
import type { RestoredTiles } from 'state/clientWorkspace';
import type { PatchAccuType } from 'epics/metaSelectors/accumulator/tradeCaptureToBackend';

export * from './rfs';
export * from './tradeCapture';
export * from './execution';

export type FxAccumulatorAction =
  | AccumulatorTileRestoreEpic
  | AccumulatorPropertiesChanged
  | AccumulatorScheduleLegPropertiesChanged
  | AccumulatorScheduleReset
  | AccumulatorScheduleLegAdded
  | AccumulatorScheduleLegRemoved
  | AccumulatorScheduleImported
  | AccumulatorPropertiesPristine
  | AccumulatorPropertiesRemoveError
  | AccumulatorPropertiesRequested
  | AccumulatorPropertiesRequestFailed
  | AccumulatorPropertiesReceived
  | AccumulatorRfsRequestEpic
  | AccumulatorTileReset
  | AccumulatorRfsStarted
  | AccumulatorRfsQuoteReceived
  | AccumulatorTraderNotificationReceived
  | AccumulatorRfsFailed
  | AccumulatorRfsTerminated
  | AccumulatorRfsCanceled
  | AccumulatorRfsCanceledSent
  | AccumulatorRfsCancelFailed
  | AccumulatorRfsClearError
  | AccumulatorRfsLastRemoved
  | AccumulatorExecutionRequested
  | AccumulatorExecutionSent
  | AccumulatorExecutionSentSucceeded
  | AccumulatorExecutionFailed
  | AccumulatorExecutionReceived
  | AccumulatorFillReportReceived
  | AccumulatorToggleDisplayMode;

export interface AccumulatorTileRestoreEpic {
  type: 'ACCUMULATOR_TILE_RESTORE_EPIC';
  tiles: RestoredTiles<AccumulatorSavedTile>;
}

export function accumulatorTileRestoreEpic(
  tiles: RestoredTiles<AccumulatorSavedTile>,
): AccumulatorTileRestoreEpic {
  return {
    type: 'ACCUMULATOR_TILE_RESTORE_EPIC',
    tiles,
  };
}

export interface AccumulatorPropertiesChanged {
  type: 'ACCUMULATOR_PROPERTIES_CHANGED';
  quoteId: string;
  patch: Partial<FxTargetAccumulatorInputs | FxForwardAccumulatorInputs> & PatchAccuType;
}

export function accumulatorPropertiesChanged(
  quoteId: string,
  patch: Partial<FxTargetAccumulatorInputs | FxForwardAccumulatorInputs> & PatchAccuType,
): AccumulatorPropertiesChanged {
  return {
    type: 'ACCUMULATOR_PROPERTIES_CHANGED',
    quoteId,
    patch,
  };
}

export interface AccumulatorScheduleLegPropertiesChanged {
  type: 'ACCUMULATOR_SCHEDULE_LEG_PROPERTIES_CHANGED';
  quoteId: string;
  legId: string;
  patch: Partial<Partial<AccumulatorScheduleInputs>>;
}

export function accumulatorScheduleLegPropertiesChanged(
  quoteId: string,
  legId: string,
  patch: Partial<AccumulatorScheduleInputs>,
): AccumulatorScheduleLegPropertiesChanged {
  return {
    type: 'ACCUMULATOR_SCHEDULE_LEG_PROPERTIES_CHANGED',
    quoteId,
    legId,
    patch,
  };
}

export interface AccumulatorScheduleReset {
  type: 'ACCUMULATOR_SCHEDULE_RESET';
  quoteId: string;
}

export function accumulatorScheduleReset(quoteId: string): AccumulatorScheduleReset {
  return {
    type: 'ACCUMULATOR_SCHEDULE_RESET',
    quoteId,
  };
}

export interface AccumulatorScheduleLegAdded {
  type: 'ACCUMULATOR_SCHEDULE_LEG_ADDED';
  quoteId: string;
  legId: string;
}

export function accumulatorScheduleLegAdded(
  quoteId: string,
  legId: string,
): AccumulatorScheduleLegAdded {
  return {
    type: 'ACCUMULATOR_SCHEDULE_LEG_ADDED',
    quoteId,
    legId,
  };
}

export interface AccumulatorScheduleLegRemoved {
  type: 'ACCUMULATOR_SCHEDULE_LEG_REMOVED';
  quoteId: string;
  legId: string;
}

export function accumulatorScheduleLegRemoved(
  quoteId: string,
  legId: string,
): AccumulatorScheduleLegRemoved {
  return {
    type: 'ACCUMULATOR_SCHEDULE_LEG_REMOVED',
    quoteId,
    legId,
  };
}

export interface AccumulatorScheduleImported {
  type: 'ACCUMULATOR_SCHEDULE_IMPORTED';
  quoteId: string;
  patch: Record<string, Partial<AccumulatorScheduleInputs>>;
}

export function accumulatorScheduleImported(
  quoteId: string,
  patch: Record<string, Partial<AccumulatorScheduleInputs>>,
): AccumulatorScheduleImported {
  return {
    type: 'ACCUMULATOR_SCHEDULE_IMPORTED',
    quoteId,
    patch,
  };
}

export interface AccumulatorPropertiesPristine {
  type: 'ACCUMULATOR_PROPERTIES_PRISTINE';
  quoteId: string;
  fieldName: keyof IFxAccumulatorValues;
}

export function accumulatorPropertiesPristine(
  quoteId: string,
  fieldName: keyof IFxAccumulatorValues,
): AccumulatorPropertiesPristine {
  return {
    type: 'ACCUMULATOR_PROPERTIES_PRISTINE',
    quoteId,
    fieldName,
  };
}

export interface AccumulatorPropertiesRemoveError {
  type: 'ACCUMULATOR_PROPERTIES_REMOVE_ERROR';
  quoteId: string;
  keys: ReadonlyArray<keyof IFxAccumulatorValues>;
}

export function accumulatorPropertiesRemoveError(
  quoteId: string,
  keys: ReadonlyArray<keyof IFxAccumulatorValues>,
): AccumulatorPropertiesRemoveError {
  return {
    type: 'ACCUMULATOR_PROPERTIES_REMOVE_ERROR',
    quoteId,
    keys,
  };
}

export interface AccumulatorTileReset {
  type: 'ACCUMULATOR_TILE_RESET';
  quoteId: string;
}

export function accumulatorTileReset(quoteId: string): AccumulatorTileReset {
  return {
    type: 'ACCUMULATOR_TILE_RESET',
    quoteId,
  };
}

export function accumulatorToggleDisplayMode(quoteId: string) {
  return {
    type: 'ACCUMULATOR_DISPLAYMODE_TOGGLE',
    quoteId,
  } as const;
}

export type AccumulatorToggleDisplayMode = ReturnType<typeof accumulatorToggleDisplayMode>;
