import {
  streamingConnected,
  streamingDegraded,
  streamingDisconnected,
  streamingHealthy,
} from 'state/streamingConnection/streamingConnectionActions';
import { streamingReconnectedThunk } from 'state/streamingConnection/streamingConnectionThunks';
import { renderReactApp } from '../main';
import { listenToAllExecution } from 'execution/handleExecutionStream';
import { sgwtConnect } from 'api/connect';
import { handleSgConnectEvents } from './sgwtConnectEventHandler';
import { getStreams } from './streams';
import { getStore } from './store';
import rafSchedule from 'raf-schd';
import { patchStoreSubscribeWithScheduler } from './storeSubscribeScheduler';
import { filter } from 'rxjs/operators';
import type {
  IDisconnectEvent,
  IReconnectEvent,
  ISessionStreamEvent,
  IStartEvent,
} from 'Workers/streamingTypes';
import { fromWebworker$, setConfig, setUserInfo, terminate } from './streams/streamingWorker';
import { crash$ } from 'circuitBreaker';
import { ofType } from 'redux-observable';
import type { ConnectionStatusMessage, UpstreamPostMessageStreaming } from 'Workers/messages';
import { getApiUrl } from './sgmeConfiguration';
import { getTheme } from 'utils/theme';
import { logger } from '../logging/logger';
import { v4 as uuid } from 'uuid';

function isStart(event: ISessionStreamEvent): event is IStartEvent {
  return event.type === 'START';
}

function isReconnect(event: ISessionStreamEvent): event is IReconnectEvent {
  return event.type === 'RECONNECT';
}

function isDisconnect(event: ISessionStreamEvent): event is IDisconnectEvent {
  return event.type === 'DISCONNECT';
}

export function bootstrap() {
  const login = sgwtConnect.getIdTokenClaims()!.sub;

  setConfig(getApiUrl(), uuid());
  setUserInfo({ login, authorizationHeader: sgwtConnect.getAuthorizationHeader()! });
  crash$.subscribe(terminate);

  const store = getStore();
  const { dataStream$, session$ } = getStreams();

  handleSgConnectEvents(sgwtConnect, store, () =>
    setUserInfo({ login, authorizationHeader: sgwtConnect.getAuthorizationHeader()! }),
  );

  setupSgwtConnectWidget();

  const streaming$ = fromWebworker$.pipe(
    ofType<UpstreamPostMessageStreaming, ConnectionStatusMessage>('CONNECTION'),
  );

  streaming$
    .pipe(filter(msg => msg.status === 'DEGRADED'))
    .subscribe(() => store.dispatch(streamingDegraded()));

  streaming$
    .pipe(filter(msg => msg.status === 'HEALTHY'))
    .subscribe(() => store.dispatch(streamingHealthy()));

  session$.pipe(filter(isStart)).subscribe(({ data: connectionId, driver }) => {
    logger.logInformation('Streaming connected: {driver}, {connectionId}', driver, connectionId);
    return store.dispatch(streamingConnected(connectionId, driver));
  });

  session$.pipe(filter(isReconnect)).subscribe(({ data: connectionId, driver }) => {
    logger.logInformation('Streaming reconnected: {driver}, {connectionId}', driver, connectionId);
    return store.dispatch(streamingReconnectedThunk(connectionId, driver));
  });

  session$
    .pipe(filter(isDisconnect))
    .subscribe(e => store.dispatch(streamingDisconnected(e.reason)));

  session$.connect();

  logger.logInformation('User using theme: {theme}', getTheme());

  const throttleStorePatcher = patchStoreSubscribeWithScheduler(rafSchedule);
  const patchedStore = throttleStorePatcher(store);
  renderReactApp(patchedStore);

  listenToAllExecution(store, dataStream$);
}

function setupSgwtConnectWidget() {
  if (window.sgmeConfiguration.sgConnect.authorization_endpoint === 'fake') {
    return;
  }
  const widget = document.querySelector('sgwt-connect') as any;
  if (widget) {
    // When the code is executed, the widget may not have been initialized. So, we need to check that, otherwise calling
    // `widget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the widget is ready...
      widget.addEventListener(
        'sgwt-connect--ready',
        () => {
          widget.setSgwtConnectInstance(sgwtConnect);
        },
        { once: true },
      );
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}
