import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { Mifid2QuoteStatus } from 'state/mifidPanel';

export interface MifidQuoteConnectOwnProps {
  negotiationId: string;
  quoteId: string;
}

export interface MifidQuoteConnectStateProps {
  timestamp: string;
  status: Mifid2QuoteStatus;
}

export type MifidQuoteConnectSelectorsKeys = 'getMifid2Quote';
export type MifidQuoteConnectSelectors = Pick<Selectors, MifidQuoteConnectSelectorsKeys>;

export const mapStateToPropsMifidQuote: MapStateToPropsHOF<
  MifidQuoteConnectStateProps,
  MifidQuoteConnectOwnProps,
  AppState,
  MifidQuoteConnectSelectors
> =
  sl =>
  (state, { negotiationId, quoteId }) =>
    sl.getMifid2Quote(state, negotiationId, quoteId) ?? {
      status: 'Sent',
      timestamp: '',
    };
