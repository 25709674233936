import { format, isValid } from 'date-fns';
import { toUtc } from 'utils/dateFormats';

function dayAndTimeToDate(day: string, time: string) {
  const normalizedTime = time
    .split(':')
    .map(part => part.padStart(2, '0'))
    .join(':');
  const dateTimeString = `${day}T${normalizedTime}`;
  return new Date(dateTimeString);
}

export function getUtcExpiry(isGtc: boolean, expiryDay: string | undefined, expiryTime = '') {
  if (isGtc) {
    return {
      expiryDay: undefined,
      expiryTime: undefined,
    };
  }
  const expiry = dayAndTimeToDate(expiryDay ?? '', expiryTime);
  if (!isValid(expiry)) {
    // In case of an invalid date, we only send the day to provoke a validation failure
    return {
      expiryDay,
      expiryTime: undefined,
    };
  }
  const utcExpiry = toUtc(expiry);
  return {
    expiryDay: format(utcExpiry, 'yyyy-MM-dd'),
    expiryTime: format(utcExpiry, 'HH:mm:ss'),
  };
}

export function dateAndTimeToUtc(day = '', time = '') {
  const localDateTime = dayAndTimeToDate(day, time);
  return toUtc(localDateTime);
}
