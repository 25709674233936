import { EmailsInput } from 'components/share/EmailsInput/EmailsInput';
import {
  emailsReducerCash,
  emailsReducerDefault,
  emailsReducerOptions,
  emailsReducerOrders,
} from 'components/share/EmailsInput/emailsLocalReducer';
import { usePreferences } from 'components/share/hooks/usePreferences';
import { useMemo, useReducer } from 'react';
import { connectUserPref } from './connect';
import type { UserPrefConnectProps } from './connect/connectUserPref';

const NotificationEmailsRaw: React.FunctionComponent<UserPrefConnectProps<'emails'>> = (props) => {
  const {
    userPrefValue: emails,
    onChangeUserPrefWithSplit: onEmailsChangeWithSplit,
    splitNotificationsEmails: splitEmails,
    notificationsType,
    onChangeUserPref: onEmailsChange,
  } = props;

  const [emailsDefault, dispatchDefault] = useReducer(emailsReducerDefault, emails);
  const [emailsCash, dispatchCash] = useReducer(emailsReducerCash, splitEmails.cash);
  const [emailsOrders, dispatchOrders] = useReducer(emailsReducerOrders, splitEmails.orders);
  const [emailsOptions, dispatchOptions] = useReducer(emailsReducerOptions, splitEmails.options);
  const { splitNotifications } = usePreferences();

  const splitedEmails = useMemo(() => {
    if (!splitNotifications) {
      return { emails: emailsDefault, dispatch: dispatchDefault };
    } else {
      switch (notificationsType) {
        case 'orders':
          return { emails: emailsOrders, dispatch: dispatchOrders };
        case 'cash':
          return { emails: emailsCash, dispatch: dispatchCash };
        case 'options':
          return { emails: emailsOptions, dispatch: dispatchOptions };
        default:
          return { emails: [], dispatch: dispatchDefault };
      }
    }
  }, [
    emailsCash,
    emailsDefault,
    emailsOptions,
    emailsOrders,
    notificationsType,
    splitNotifications,
  ]);

  return (
    <div className="form-group">
      <EmailsInput
        live
        notificationsType={notificationsType!}
        emails={splitedEmails.emails}
        dispatch={splitedEmails.dispatch}
        onSubmit={onEmailsChange}
        onsubmitWithSplit={onEmailsChangeWithSplit}
        e2eHandle={`email-notification-${notificationsType}`}
      />
    </div>
  );
};
export const NotificationEmails = connectUserPref('emails')(NotificationEmailsRaw);
