import type { Selectors } from 'state/selectors';
import type { Mappers } from './typings';
import { liftToString } from './utils';

export const accuMappers = (
  sl: Selectors,
): Mappers<
  'Accumulator',
  | 'premiumDateTenor'
  | 'firstFixingDateTenor'
  | 'settlementMode'
  | 'cashSettlementCurrency'
  | 'markupCurrency'
  | 'schedule'
> => ({
  currencyPair: sl.getAccumulatorCurrencyPairInput,
  priceType: sl.getAccumulatorPriceType,
  priceCurrency: sl.getAccumulatorPriceCurrency,
  premiumDate: sl.getAccumulatorPremiumDate,
  hedgeType: sl.getAccumulatorHedgeType,
  hedgePrice: sl.getAccumulatorHedgePrice,
  hedgeAmount: sl.getAccumulatorHedgeAmount,
  hedgeCurrency: sl.getAccumulatorHedgeCurrency,
  way: sl.getAccumulatorWay,
  ekiDown: sl.getAccumulatorEkiDown,
  ekiUp: sl.getAccumulatorEkiUp,
  amount: liftToString(sl.getAccumulatorAmount),
  amountCurrency: sl.getAccumulatorAmountCurrency,
  // TODO : NDR - Manage Target / Forward
  amountSplitType: sl.getAccumulatorAmountSplitType,
  leverage: sl.getAccumulatorLeverage,
  leverageAmount: sl.getAccumulatorLeverageAmount,
  strike: sl.getAccumulatorStrike,
  strikeDown: sl.getAccumulatorStrikeDown,
  pivot: sl.getAccumulatorPivot,
  strikeUp: sl.getAccumulatorStrikeUp,
  step: sl.getAccumulatorStep,
  akoTrigger: sl.getAccumulatorAkoTrigger,
  ekiTrigger: sl.getAccumulatorEkiTrigger,
  targetProfitType: sl.getAccumulatorTargetProfitType,
  target: sl.getAccumulatorTarget,
  expiryDate: sl.getAccumulatorExpiryDate,
  expiryTenor: sl.getAccumulatorExpiryTenor,
  fixingFrequency: sl.getAccumulatorFixingFrequency,
  numberOfFixings: sl.getAccumulatorNumberOfFixings,
  settlementFrequency: sl.getAccumulatorSettlementFrequency,
  firstFixingDate: sl.getAccumulatorFirstFixingDate,
  fixingReference1: sl.getAccumulatorFixingReference1,
  fixingReference2: sl.getAccumulatorFixingReference2,
  isCrossed: sl.getAccumulatorIsCrossed,
  crossCurrency: sl.getAccumulatorCrossCurrency,
});
