/* eslint-disable no-case-declarations */
import type { AppState } from 'state/model';
import type { PredealCheck, PredealCheckStatusHealth } from 'state/share/predealChecksModel';
import { assertUnreachable, isDefined } from '@sgme/fp';
import type { HedgeValueKeys, LegValueKeys, ProductName, ValueKeys } from './share/productModel';
import {
  getTileCurrencyPair,
  getTileCurrentEspStreamId,
  getTileCurrentStreamId,
  getTileLastStreamError,
} from './tile/selectors';
import { getQuoteInstrument, getTileState, isTilePresent, userCanTrade } from './clientWorkspace/selectors';

import { getOptionHedge, getOptionLeg, getOptionState, getOptionStreamState } from './fxOptions/selectors';
import { getSwapRfsStream, getSwapState } from './fxSwaps/selectors';
import { getBulkLegState, getBulkStreamState } from './fxBulks/selectors';
import { isStreamingConnected } from './streamingConnection/streamingConnectionSelectors';
import { type FormDomain, type HedgedInstrument, isLeggedInstrument, type LeggedInstrument } from './referenceData';
import { getBulkState } from './fxBulks/selectors/bulk';
import {
  getAccumulatorProductName,
  getAccumulatorRfsState,
  getAccumulatorSchedule,
  getAccumulatorState,
} from './fxAccumulators/selectors';
import { getBlotterEditedOrderById, getBlotterOrderStreamId, getBlotterTradeById } from './blotter/selectors';
import type { IFormData } from './share/form';
import { fieldData } from 'utils/fieldSelectors';
import { getEspSpotMidRate } from './esp/espStreamsSelectors';
import { getCurrencyPrecision } from './referenceData/referenceDataSelectors';
import { roundToPrecision } from 'utils/number';
import { getOrderState } from './fxOrders/selectors';
import { getLegKey } from './share/patchHelper';
import { assertUnhandled } from '../utils/error';
import { getCashProductName } from './fxCashs/selectors/cashValue';
import { getCashRfsStreamState } from './fxCashs/selectors/fxCashsRfsStreamsSelectors';
import { getCashState } from './fxCashs/selectors/cashState';
import { getAmericanForwardState, getAmericanForwardStreamState } from './fxAmericanForward/selectors';

interface ValidationValidState {
  alertLevel: undefined;
}

interface ValidationNotValidState {
  alertLevel: 'valid' | 'warning' | 'invalid';
  messageId: string;
  seen: boolean;
}

export function getFieldValidationState<I extends FormDomain>(
  state: AppState,
  instrument: I,
  field: ValueKeys<I>,
  quoteId: string,
): ValidationNotValidState | ValidationValidState {
  const fieldsState = stateAccessors[instrument](state, quoteId);
  const fieldErrorState = fieldsState.errors[field];
  const fieldWarningState = fieldsState.warnings[field];
  return fieldErrorState !== undefined
    ? {
      alertLevel: 'invalid',
      messageId: fieldErrorState.code,
      seen: fieldErrorState.userNotified,
    }
    : fieldWarningState !== undefined
      ? {
        alertLevel: 'warning',
        messageId: fieldWarningState.code,
        seen: fieldWarningState.userNotified,
      }
      : { alertLevel: undefined };
}

// encapsulate field recommendedHedgeAmountInPercent from sgmeConfiguration behind a selector so it can be mocked
export function getRecommendedHedgeAmount() {
  return Number(window.sgmeConfiguration.recommendedHedgeAmountInPercent);
}

function isLegExist(state: AppState, instrument: LeggedInstrument, quoteId: string, legId: string): boolean {
  if (instrument === 'Option') {
    return isDefined(state.fxOptions.legs[legId]);
  }

  const legKey = getLegKey(quoteId, legId);

  switch (instrument) {
    case 'Bulk':
      return isDefined(state.fxBulks.legs[legKey]);
    case 'Accumulator':
      return isDefined(state.fxAccumulators.schedules[legKey]);
  }
  return false;
}

function isHedgeExist(state: AppState, hedgeId: string): boolean {
  return isDefined(state.fxOptions.hedges[hedgeId]);
}

export function getLegFieldValidationState<I extends LeggedInstrument>(
  state: AppState,
  instrument: I,
  field: LegValueKeys<I>,
  quoteId: string,
  legId: string,
): ValidationNotValidState | ValidationValidState {
  if (!isLegExist(state, instrument, quoteId, legId)) {
    return { alertLevel: undefined };
  }

  const legFieldsState = legStateAccessors[instrument](state, quoteId, legId);

  const legFieldErrorState = legFieldsState.errors[field];
  const legFieldWarningState = legFieldsState.warnings[field];

  return legFieldErrorState !== undefined
    ? {
      alertLevel: 'invalid',
      messageId: legFieldErrorState.code,
      seen: legFieldErrorState.userNotified,
    }
    : legFieldWarningState !== undefined
      ? {
        alertLevel: 'warning',
        messageId: legFieldWarningState.code,
        seen: legFieldWarningState.userNotified,
      }
      : { alertLevel: undefined };
}

export function getHedgeFieldValidationState<I extends HedgedInstrument>(
  state: AppState,
  instrument: I,
  field: HedgeValueKeys<I>,
  quoteId: string,
  hedgeId: string,
): ValidationNotValidState | ValidationValidState {
  if (!isHedgeExist(state, hedgeId)) {
    return { alertLevel: undefined };
  }

  const hedgeFieldsState = hedgeStateAccessors[instrument](state, quoteId, hedgeId);

  const hedgeFieldErrorState = hedgeFieldsState?.errors[field];
  const hedgeFieldWarningState = hedgeFieldsState?.warnings[field];

  return hedgeFieldErrorState !== undefined
    ? {
      alertLevel: 'invalid',
      messageId: hedgeFieldErrorState.code,
      seen: hedgeFieldErrorState.userNotified,
    }
    : hedgeFieldWarningState !== undefined
      ? {
        alertLevel: 'warning',
        messageId: hedgeFieldWarningState.code,
        seen: hedgeFieldWarningState.userNotified,
      }
      : { alertLevel: undefined };
}

export function isTileDirty(state: AppState, quoteId: string) {
  const instrument = getQuoteInstrument(state, quoteId);

  if (instrument === 'SmartRfs') {
    return assertUnhandled('Current implementation of smartRfs Tile has no product state related to it', instrument);
  }

  const productState = stateAccessors[instrument](state, quoteId);
  const { inputs: productInputs } = productState;
  const productDirty = Object.keys(productInputs).length > 0;
  if (isLeggedInstrument(instrument)) {
    return ((productState as unknown as { legs: readonly string[] }).legs ?? []).reduce((acc, curr) => {
      const legAccessor = legStateAccessors[instrument];
      const { inputs } = legAccessor(state, quoteId, curr);
      return acc || Object.keys(inputs).length > 0;
    }, productDirty);
  }
  return productDirty;
}

const stateAccessors: Record<FormDomain, (state: AppState, quoteId: string) => IFormData<any, any>> = {
  Cash: getCashState,
  Swap: getSwapState,
  Option: getOptionState,
  AmericanForward: getAmericanForwardState,
  Bulk: getBulkState,
  Accumulator: getAccumulatorState,
  Order: getOrderState,
  BlotterTrade: getBlotterTradeById,
  BlotterOrder: getBlotterEditedOrderById,
};

const legStateAccessors: Record<
  LeggedInstrument,
  (state: AppState, quoteId: string, legId: string) => IFormData<any, any>
> = {
  Option: (state: AppState, _quoteId: string, legId: string) => getOptionLeg(state, legId),
  Bulk: getBulkLegState,
  Accumulator: getAccumulatorSchedule,
  AmericanForward: getAmericanForwardState,
};

const hedgeStateAccessors: Record<
  HedgedInstrument,
  (state: AppState, quoteId: string, hedgeId: string) => IFormData<any, any> | undefined
> = {
  Option: (state: AppState, _quoteId: string, hedgeId: string) => getOptionHedge(state, hedgeId),
};

/* Selector which are shared between Cash, Swap and Option */
export function getProductName(state: AppState, tileId: string): ProductName {
  const { instrument } = getTileState(state, tileId);
  switch (instrument) {
    case 'Cash':
      return getCashProductName(state, tileId);
    case 'Option':
      return 'FxOption';
    case 'Swap':
      return 'FxSwap';
    case 'Accumulator':
      return getAccumulatorProductName(state, tileId);
    case 'Order':
    case 'BlotterOrder':
      return 'FxOrder';
    case 'Bulk':
      return 'FxBulk';
    case 'SmartRfs':
      return 'FxSmartRfs';
    case 'AmericanForward':
      return 'FxAmericanForward';
  }
}

/**
 * @todo
 * rename to Quote or Product
 */
export function getTileEspStreamId(state: AppState, tileId: string): string | null {
  if (!isTilePresent(state, tileId)) {
    return null;
  }
  const { instrument } = getTileState(state, tileId);
  switch (instrument) {
    case 'Cash':
      return getCashState(state, tileId).currentEspStreamId;
    case 'Option':
      return getOptionState(state, tileId).currentEspStreamId;
    case 'Swap':
      return getSwapState(state, tileId).currentEspStreamId;
    case 'Accumulator':
      return getAccumulatorState(state, tileId).currentEspStreamId;
    case 'Order':
      return getOrderState(state, tileId).currentEspStreamId;
    case 'Bulk':
      return getBulkState(state, tileId).currentEspStreamId;
    case 'BlotterOrder':
      return getBlotterOrderStreamId(state, tileId);
    case 'AmericanForward':
      return getAmericanForwardState(state, tileId).currentEspStreamId;
    // smartRfs has no stream associated to it as it is only a tile to create other product
    case 'SmartRfs':
      return null;
    default:
      assertUnreachable(instrument, 'Unhandled instrument in getTileEspStreamId');
  }
}

export function getTileRfsStream(state: AppState, tileId: string) {
  const { instrument } = getTileState(state, tileId);
  const currentStreamId = getTileCurrentStreamId(state, tileId);
  const lastStreamError = getTileLastStreamError(state, tileId);
  if (currentStreamId === null) {
    return lastStreamError ? { status: 'ERROR' as 'ERROR', lastStreamError } : null;
  }
  switch (instrument) {
    case 'Cash':
      return getCashRfsStreamState(state, currentStreamId);
    case 'Option':
      return getOptionStreamState(state, currentStreamId);
    case 'Swap':
      return getSwapRfsStream(state, currentStreamId);
    case 'Bulk':
      return getBulkStreamState(state, currentStreamId);
    case 'Order':
    case 'BlotterOrder':
      return {};
    case 'Accumulator':
      return getAccumulatorRfsState(state, currentStreamId);
    case 'AmericanForward':
      return getAmericanForwardStreamState(state, currentStreamId);
    // smartRfs has no stream associated to it as it is only a tile to create other product
    case 'SmartRfs':
      return null;
    default:
      assertUnreachable(instrument, 'Unhandled instrument');
  }
}

export function getRfsIsSalesTier(state: AppState, tileId: string): boolean {
  const rfsState = getTileRfsStream(state, tileId);
  return rfsState !== null && rfsState.status === 'PRICING' && rfsState.tiering === 'STREAMDEFAULT';
}

function getGlobalStatus(predealChecks: readonly PredealCheck[]): PredealCheckStatusHealth {
  return !predealChecks.length
    ? 'Unknown'
    : predealChecks.filter((s) => s.type === 'Icon' && s.statusHealth === 'Nok').length
      ? 'Nok'
      : 'Ok';
}

const emptyPreDealChecks: readonly PredealCheck[] = [];

export function getTilePredealChecks(
  state: AppState,
  tileId: string,
): {
  predealChecks: readonly PredealCheck[];
  globalStatus: PredealCheckStatusHealth;
} {
  const stream = getTileRfsStream(state, tileId);
  if (stream !== null) {
    if (stream.status === 'PRICING') {
      const predealChecks = stream.quote.predealChecks;
      return { predealChecks, globalStatus: getGlobalStatus(predealChecks) };
    } else if (stream.status === 'ERROR' && stream.lastStreamError) {
      const predealChecks = stream.lastStreamError.predealChecks || emptyPreDealChecks;
      return { predealChecks, globalStatus: getGlobalStatus(predealChecks) };
    }
  }
  return {
    predealChecks: emptyPreDealChecks,
    globalStatus: 'Unknown',
  };
}

export interface TradeCaptureSessionInfos {
  currentSessionId: string | null;
  tradeCaptureIdVersion: number | null;
}

export function getTradeCaptureSessionInfos(state: AppState, tileId: string): TradeCaptureSessionInfos {
  const { instrument } = getTileState(state, tileId);
  /**
   * @todo tile selector
   */
  switch (instrument) {
    case 'Option': {
      const { currentSessionId, tradeCaptureIdVersion } = getOptionState(state, tileId);
      return { currentSessionId, tradeCaptureIdVersion };
    }
    case 'Cash': {
      const { currentSessionId, tradeCaptureIdVersion } = getCashState(state, tileId);
      return { currentSessionId, tradeCaptureIdVersion };
    }
    case 'Swap': {
      const { currentSessionId, tradeCaptureIdVersion } = getSwapState(state, tileId);
      return { currentSessionId, tradeCaptureIdVersion };
    }
    case 'Accumulator': {
      const { currentSessionId, tradeCaptureIdVersion } = getAccumulatorState(state, tileId);
      return { currentSessionId, tradeCaptureIdVersion };
    }
    case 'AmericanForward':
      const { currentSessionId, tradeCaptureIdVersion } = getAmericanForwardState(state, tileId);
      return { currentSessionId, tradeCaptureIdVersion };
    // smart Rfs has no related session/version but needs to be there to ensure
    // a smooth transition between other instrument and smart Rfs
    case 'SmartRfs':
      return { currentSessionId: null, tradeCaptureIdVersion: null };
    case 'Bulk':
      throw new Error('No Bulk instrument implementation yet');
    case 'Order':
    case 'BlotterOrder':
      throw new Error('No Order instrument implementation yet');
  }
}

export function isCanTradeState(state: AppState) {
  return userCanTrade(state) && isStreamingConnected(state);
}

export function getMidSpot(state: AppState, quoteId: string) {
  const espStreamId = getTileCurrentEspStreamId(state, quoteId);
  const currencyPair = fieldData(getTileCurrencyPair(state, quoteId)).data;
  if (espStreamId && currencyPair) {
    const midSpot = getEspSpotMidRate(state, espStreamId);
    if (midSpot !== null) {
      const precision = getCurrencyPrecision(state, currencyPair);
      return roundToPrecision(midSpot, precision - 1);
    }
  }
  return null;
}
