import type { AppState } from 'state/model';
import { getOrderStatus, getOrderTemplateId } from './orderMetadata';
import {
  type OrderType,
  orderTypes,
  algoOrderTypes,
  type AlgoOrderType,
  type LimitOrderType,
} from '../fxOrdersModel';
import type { ProductAccessKeys } from 'state/referenceData/referenceDataModel';
import { getProductsAccess } from 'state/referenceData/referenceDataSelectors';
import { createSelector } from 'reselect';
import { isEmpty } from '@sgme/fp';
import { fieldData } from 'utils/fieldSelectors';
import { getOrderStartDate, getOrderStartTime } from './orderInput';

export function isOrderFrozen(state: AppState, orderId: string): boolean {
  // When order was submitted, it can no longer be changed
  switch (getOrderStatus(state, orderId)) {
    case 'Failed':
    case 'Pending':
      return true;
    default:
      return false;
  }
}

const emptyEmails: readonly string[] = [];
export function getOrderDefaultEmails(state: AppState, bdrId: string): readonly string[] {
  return state.fxOrders.defaultEmailsByBdrId[bdrId] || emptyEmails;
}

const orderTypeToProductAccess: Record<OrderType, ProductAccessKeys> = {
  StopLoss: 'stopLoss',
  TakeProfit: 'takeProfit',
  Call: 'callOrder',
  Twap: 'twap',
  Nightjar: 'nightjar',
  Falcon: 'falcon',
  Fixing: 'fixing',
};

export const getAvailableOrderTypes: (state: AppState) => readonly OrderType[] =
  makeGetAvailableOrderTypes(orderTypes);

export function hasOrderProductAccess(state: AppState) {
  return !isEmpty(getAvailableOrderTypes(state));
}

export const getAvailableAlgoOrderTypes: (state: AppState) => readonly AlgoOrderType[] =
  makeGetAvailableOrderTypes(algoOrderTypes);

function makeGetAvailableOrderTypes<T extends AlgoOrderType | LimitOrderType | OrderType>(
  orders: readonly T[],
): (state: AppState) => readonly T[] {
  return createSelector([getProductsAccess], (productAccess) =>
    orders.filter((ot) => productAccess[orderTypeToProductAccess[ot]]),
  );
}

export const getOrderTemplatesIds = createSelector(
  (state: AppState) => state.fxOrders.templates,
  Object.keys,
);

export const getOrderTemplateById = (state: AppState, templateId: string) =>
  state.fxOrders.templates[templateId];

export const getOrderTemplateByOrderId = (state: AppState, orderId: string) => {
  const templateId = getOrderTemplateId(state, orderId);
  const template = templateId ? getOrderTemplateById(state, templateId) : undefined;

  return template;
};


export const getStartDate = (quoteId: string) => createSelector(
  [(state: AppState) => getOrderStartDate(state, quoteId)],
  (startDate) => fieldData(startDate).data
);

export const getStartTime = (quoteId: string) => createSelector(
  [(state: AppState) => getOrderStartTime(state, quoteId)],
  (startTime) => fieldData(startTime).data
);