import { updateKey, removeKey, addKey, setKeyIfExisty } from 'utils/stateMap';
import type {
  FxOptionStreamState,
  FxOptionStreamMap,
  IFxOptionStreamAwaitingState,
} from '../model/optionsStreams';
import type { Action } from '../../actions';
import type { Reducer } from 'redux';
import { immutableReferenceUpdate } from 'utils/immutableReferenceUpdate';

// STREAMS

export const optionsStreamsReducer: Reducer<FxOptionStreamMap> = (
  state: FxOptionStreamMap = {},
  action: Action,
): FxOptionStreamMap => {
  switch (action.type) {
    case 'OPTION_STREAM_STARTED':
      return addKey<FxOptionStreamState>(state, action.streamId, {
        version: 0,
        status: 'AWAITING',
        forceTradeable: action.forceTradeable,
        traderId: null,
      });

    case 'OPTION_STREAM_UPDATED':
      return setKeyIfExisty<FxOptionStreamState>(state, action.streamId, prevStream => {
        if (prevStream.status === 'INVALID') {
          return prevStream;
        }

        const result: IFxOptionStreamAwaitingState = {
          status: 'AWAITING',
          forceTradeable: prevStream.forceTradeable,
          traderId: prevStream.traderId,
          version: prevStream.version + 1,
        };

        return result;
      });

    case 'OPTION_TRADER_NOTIFICATION_RECEIVED':
      return updateKey<FxOptionStreamState>(state, action.streamId, () => ({
        status: 'AWAITING',
        traderId: action.traderId,
      }));

    case 'OPTION_QUOTE_RECEIVED':
      return setKeyIfExisty<FxOptionStreamState>(state, action.streamId, stream => {
        if (stream.status === 'INVALID') {
          return stream;
        }

        const traderId =
          stream.status === 'AWAITING' || stream.status === 'PRICING' ? stream.traderId : null;

        const result: FxOptionStreamState = {
          status: 'PRICING',
          quote:
            stream.status === 'PRICING'
              ? immutableReferenceUpdate(stream.quote, action.quote)
              : action.quote,
          initialRfsWindow:
            stream.status === 'PRICING' ? stream.initialRfsWindow : action.quote.rfsWindow,
          tiering: action.tiering,
          skippedLimitCheck: action.skippedLimitCheck,
          forceTradeable: stream.forceTradeable,
          traderId,
          quoteContribution: action.quoteContribution,
          version: stream.version,
        };

        return result;
      });

    case 'OPTION_STREAM_CANCELED':
    case 'OPTION_STREAM_TERMINATED':
      if (!action.shouldKeepAsExpired) {
        return removeKey(state, action.streamId);
      }
      return updateKey<FxOptionStreamState>(state, action.streamId, () => ({
        status: 'EXPIRED',
      }));

    case 'OPTION_STREAM_FAILED':
    case 'OPTION_STREAM_LAST_REMOVED':
      return removeKey(state, action.streamId);

    case 'OPTION_EXECUTION_SENT':
      return updateKey<FxOptionStreamState>(state, action.executionId, () => ({
        status: 'EXPIRED',
      }));

    case 'OPTION_STREAM_INVALIDATED':
      return setKeyIfExisty<FxOptionStreamState>(state, action.streamId, stream => ({
        status: 'INVALID',
        version: stream.version,
      }));

    default:
      return state;
  }
};
