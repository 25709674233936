import type { AppState } from 'state/model';
import { getOptionState } from './fxOptionsProductSelectors';
import type { CurrencyChoice, StrategyTypeName } from 'state/share/productModel/litterals';
import { getOptionIsStrategy, getOptionUserSetGroup } from './optionMetadata';
import { getDefaultPriceType } from 'state/userPreferences/selectors/userPreferenceSelectors';
import { getOptionVanillaLegStrike } from './optionLegInput';
import { isAfter, parseISO } from 'date-fns';
import type { OptionSolvableValue } from '../fxOptionsModel';
import {
  getAllVanillaLegIdsOfOption,
  getAllVanillaLegsOfOption,
  getFxOptionMultilegOfOption,
  getOptionLeg,
} from './fxOptionsLegsSelectors';
import { getOptionVanillaLegSolvingFor } from './optionLegMetadata';
import { isDefined, isNonEmpty, isNotDefined } from '@sgme/fp';
import { isVanillaLeg } from '../utilities';
import { getTileMaxDate } from 'state/referenceData/referenceDataSelectors';
import { getOptionHedgeType } from './optionInput';
import { fieldData } from 'utils/fieldSelectors';

export function getOptionTradeCaptureCurrentSessionId(
  currentState: AppState,
  optionId: string,
): string | null {
  const { currentSessionId } = getOptionState(currentState, optionId);
  return currentSessionId;
}

export function getOptionTradeCaptureIdVersion(
  currentState: AppState,
  optionId: string,
): number | null {
  const { tradeCaptureIdVersion } = getOptionState(currentState, optionId);
  return tradeCaptureIdVersion;
}

export function getMarkupCurrency(state: AppState, optionId: string): string | null {
  const {
    values: { markupCurrency },
  } = getOptionState(state, optionId);

  return markupCurrency;
}

/**
 * @todo NUKE IT
 */
export function getCurrencyPair(state: AppState, optionId: string) {
  const {
    values: { currencyPair },
  } = getOptionState(state, optionId);

  if (currencyPair !== null) {
    const [ccy1, ccy2] = (currencyPair || '/').split('/');
    return { ccy1, ccy2 };
  }

  return null;
}

export function getMarkupCurrencyChoice(state: AppState, optionId: string): CurrencyChoice | null {
  const currencyPair = getCurrencyPair(state, optionId);

  if (currencyPair === null) {
    return null;
  }

  const markupCurrency = getMarkupCurrency(state, optionId);

  switch (markupCurrency) {
    case currencyPair.ccy1:
      return 1;
    case currencyPair.ccy2:
      return 2;
    case null:
    default:
      return null;
  }
}

export function getDefaultPriceTypeForOption(state: AppState, optionId: string) {
  const defaultPriceType = getDefaultPriceType(state);
  const isStrategyView = getOptionIsStrategy(state, optionId);
  const multileg = getFxOptionMultilegOfOption(state, optionId);
  const isOneLegStrategy = multileg.legIds.length === 1;

  if (isStrategyView && !isOneLegStrategy && defaultPriceType === 'VOLATILITY') {
    return 'AMOUNT';
  }

  return defaultPriceType;
}

export function isValidDateOptionInAllLegs(state: AppState, optionId: string) {
  const maxDate = getTileMaxDate(state, optionId);
  if (maxDate === undefined) {
    return true;
  }

  return getAllVanillaLegsOfOption(state, optionId).every((leg) => {
    const expiry = leg.values.expiryDate;
    return isNotDefined(expiry) || !isAfter(parseISO(expiry), maxDate);
  });
}

export function getStrategyType(state: AppState, optionId: string): StrategyTypeName {
  const legIds = getFxOptionMultilegOfOption(state, optionId).legIds;

  if (legIds.length > 1) {
    return 'Multileg';
  }

  const firstLeg = getOptionLeg(state, legIds[0]);
  return firstLeg.productName as StrategyTypeName;
}

// TODO: remove the old subLegsIds for a vanilla because now a vanilla has an empty array
// const vanillaSubLegId = [undefined];

export function getSubLegIdsOfOptionLeg(
  state: AppState,
  legId: string,
): Readonly<Array<string | undefined>> {
  const leg = getOptionLeg(state, legId);
  return isVanillaLeg(leg) ? [undefined] : leg.legIds;
}

export function isOneLegSolvableInOption(state: AppState, optionId: string) {
  return getAllVanillaLegsOfOption(state, optionId).some(
    (leg) => leg.solvingFor === leg.values.solvable,
  );
}

export function isAllLegsSolvingInOption(
  state: AppState,
  optionId: string,
  solvingFor: OptionSolvableValue,
) {
  const allVanillaLegIds = getAllVanillaLegIdsOfOption(state, optionId);

  if (solvingFor === 'PremiumPaymentAmount' || solvingFor === 'Amount1') {
    return allVanillaLegIds.every(
      (vanillaLegId) => getOptionVanillaLegSolvingFor(state, vanillaLegId) === solvingFor,
    );
  }

  const solvingStrikeVanillaLegs = allVanillaLegIds.filter(
    (vanillaLegId) => getOptionVanillaLegSolvingFor(state, vanillaLegId) === 'Strike',
  );

  const strikeSetInValuesLegIds = allVanillaLegIds.filter((vanillaLegId) => {
    const strike = getOptionVanillaLegStrike(state, vanillaLegId);
    return isDefined(strike.value) && isNonEmpty(strike.value);
  });

  return (
    solvingStrikeVanillaLegs.length === 1 &&
    strikeSetInValuesLegIds.length === allVanillaLegIds.length - 1
  );
}

export const getOptionExpandedLegIds = (state: AppState, optionId: string) => {
  const option = getOptionState(state, optionId);

  return option.expanded;
};

export function getOptionGroup(state: AppState, optionId: string) {
  const userSetGroup = getOptionUserSetGroup(state, optionId);
  const hedgeType = fieldData(getOptionHedgeType(state, optionId)).data;

  return hedgeType === 'Forward' || userSetGroup;
}
