import { combineReducers } from 'redux';
import type { FxAccumulatorsState } from '../fxAccumulatorsModel';
import { fxAccumulatorsProductReducer } from './fxAccumulatorsProductReducer';
import { fxAccumulatorsRfsStreams } from './fxAccumulatorsRfsStreamsReducer';
import { fxAccumulatorsScheduleReducer } from './fxAccumulatorsScheduleReducer';

export const fxAccumulatorsReducer = combineReducers<FxAccumulatorsState>({
  accumulators: fxAccumulatorsProductReducer,
  rfsStreams: fxAccumulatorsRfsStreams,
  schedules: fxAccumulatorsScheduleReducer,
});
