import { assertUnreachable, isDefined, isNotDefined } from '@sgme/fp';
import { logger } from 'logging/logger';
import type { Middleware } from 'redux';
import type { Action } from 'state/actions';
import { getQuoteInstrument } from 'state/clientWorkspace/selectors';
import type { OptionStrategyTypeChanged } from 'state/fxOptions/actions/optionProduct';
import { getOptionIsStrategy } from 'state/fxOptions/selectors';
import { type Action as MatomoAction, type Category, getSgwtWidgetAnalytics } from 'utils/matomoUtils';
import type { IFxVanillaLegInputs } from '../../state/fxOptions/model/optionsLegs';

const actionsToCategories: Record<string, Category> = {
  CASH: 'Cash',
  ESP: 'Cash',
  SWAP: 'Swap',
  OPTION: 'Option',
  BULK: 'Bulk',
  ACCUMULATOR: 'Accumulator',
  ORDER: 'Order',
  SMART_RFS: 'SmartRfs',
};

const extractTypeRegex = /^([^_]+)_.*$/;
/**
 * Extract category from action type
 *
 * This function uses a regex to get the beginning of the action type
 * @param action A redux action
 * @returns The matching category
 */
function mapActionToCategory(action: Action): Category {
  return actionsToCategories[action.type.replace(extractTypeRegex, '$1')];
}

export const matomoHandlerInitializer: Middleware =
  ({ getState }) =>
    (next) =>
      (action: Action) => {
        const matomo = getSgwtWidgetAnalytics();
        const state = getState();

        // @TODO is for e2e check, on linux it's lead to crash app
        if (isNotDefined(matomo)) {
          return next(action);
        }

        if (isNotDefined(matomo.trackEvent)) {
          return next(action);
        }

        switch (action.type) {
          case 'REFERENCE_DATA_RECEIVED':
            // @TODO what would we want to do here ?
            logger.logInformation('REFERENCE_DATA_RECEIVED: {matomoLogin}', action.matomoLogin);
            break;
          case 'CLIENTWORKSPACE_TAB_ADDED':
            matomo?.trackEvent('Workspace', 'Add tab');
            break;
          case 'CLIENTWORKSPACE_TAB_REMOVED':
            matomo?.trackEvent('Workspace', 'Remove tab');
            break;
          case 'CLIENTWORKSPACE_CLIENT_CHANGED':
            matomo?.trackEvent('Workspace', 'Change client');
            break;
          case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
            matomo?.trackEvent('Workspace', 'Add tile', action.instrument);
            break;
          case 'CLIENTWORKSPACE_TILE_DUPLICATED':
            matomo?.trackEvent('Workspace', 'Duplicate tile', action.instrument);
            break;
          case 'CLIENTWORKSPACE_TILE_DELETED':
            if (!action.automatically) {
              matomo?.trackEvent('Workspace', 'Remove tile');
            }
            break;
          case 'ORDER_TEMPLATE_SAVED':
            matomo?.trackEvent('Workspace', 'Order template created');
            break;
          case 'ORDER_TEMPLATE_UPDATED':
            matomo?.trackEvent('Workspace', 'Order template updated');
            break;
          case 'ORDER_TEMPLATE_APPLIED':
            matomo?.trackEvent('Workspace', 'Order template applied');
            break;
          case 'ORDER_TEMPLATE_DELETED':
            matomo?.trackEvent('Workspace', 'Order template deleted');
            break;
          case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
            matomo?.trackEvent(action.instrument, 'Change product');
            break;
          case 'CLIENTWORKSPACE_TILE_CLIENT_OVERRIDDEN':
          case 'CLIENTWORKSPACE_TILE_CLIENT_RESET': {
            const instrument = getQuoteInstrument(state, action.quoteId);
            if (instrument !== 'BlotterOrder') {
              matomo?.trackEvent(
                instrument,
                action.type === 'CLIENTWORKSPACE_TILE_CLIENT_OVERRIDDEN' ? 'Tile client changed' : 'Tile client reset',
              );
            }
            break;
          }
          case 'USER_PREFERENCES_TOGGLE_MODAL':
            if (action.open) {
              matomo?.trackEvent('Workspace', 'Open preferences');
            }
            break;
          case 'USER_PREFERENCES_DATA_CHANGED':
            matomo?.trackEvent('Workspace', 'Preference changed', Object.keys(action.patch).join(','));
            break;
          case 'TOGGLE_MIFID2_PANEL':
            matomo?.trackEvent('Mifid2', action.open ? 'Open MIFID2 panel' : 'Close MIFID2 panel');
            break;
          case 'RECORD_PRICE_SUCCEEDED':
            matomo?.trackEvent('Mifid2', 'Price recorded');
            break;
          case 'BLOTTER_TOGGLE':
            matomo?.trackEvent('Blotter', action.isOpen ? 'Open blotter' : 'Close blotter');
            break;
          case 'SAVE_WORKSPACE_DONE':
            matomo?.trackEvent('Workspace', 'Save workspace');
            break;
          case 'TOGGLE_TRADING_DISABLED':
            matomo?.trackEvent('Workspace', 'Workspace lock toggled');
            break;
          case 'CASH_PROPERTIES_CHANGED':
          case 'SWAP_PROPERTIES_CHANGED':
          case 'OPTION_PROPERTY_CHANGED':
          case 'BULK_PROPERTY_CHANGED':
          case 'ACCUMULATOR_PROPERTIES_CHANGED':
          case 'ORDER_PROPERTY_CHANGED':
            if (action.patch.currencyPair) {
              matomo?.trackEvent(mapActionToCategory(action), 'Change currency pair');
            }
            if (action.type === 'OPTION_PROPERTY_CHANGED') {
              if (action.patch.amountReference) {
                matomo?.trackEvent(mapActionToCategory(action), 'Amount ref overriden in option strategy');
              }
              if (action.patch.hedgeType === 'Forward') {
                matomo?.trackEvent(mapActionToCategory(action), 'Hedge forward');
              }
            }
            if (action.type === 'ACCUMULATOR_PROPERTIES_CHANGED') {
              if (action.patch.ekiTrigger) {
                matomo?.trackEvent(mapActionToCategory(action), 'Accumulator type changed to permanent');
              }
            }
            break;
          case 'OPTION_HEDGE_PROPERTY_CHANGED':
            matomo?.trackEvent(mapActionToCategory(action), 'Custom hedge');
            break;
          case 'OPTION_LEG_PROPERTY_CHANGED': {
            // tracked properties are only exposed on subLegs
            const patch = action.patch as Partial<IFxVanillaLegInputs>;
            if (patch.strike) {
              switch (patch.strike.toLowerCase()) {
                case 'atm':
                case 'atmf':
                case 'atms':
                  matomo?.trackEvent(mapActionToCategory(action), 'Strike shortcut used');
                  break;
              }
            }
            if (isDefined(patch.volatilityAsk) || isDefined(patch.volatilityBid)) {
              matomo?.trackEvent(mapActionToCategory(action), 'Volatility has been overriden');
            }
            break;
          }
          case 'BULK_LEGS_EXCLUDED':
            matomo?.trackEvent('Bulk', 'Exclude from bulk');
            break;
          case 'BULK_CREATED':
            matomo?.trackEvent('Bulk', 'Create bulk');
            break;
          case 'BULK_IMPORTED':
            matomo?.trackEvent('Bulk', 'Import bulk');
            break;
          case 'BULK_LEG_ADDED':
            matomo?.trackEvent('Bulk', 'Add product to bulk');
            break;
          case 'BULK_LEGS_REMOVED':
            matomo?.trackEvent('Bulk', 'Remove product(s) from bulk', `${action.legIds.length}`);
            break;
          case 'BULK_RESET':
            matomo?.trackEvent('Bulk', 'Reset bulk');
            break;
          case 'OPTION_STREAM_STARTED':
          case 'ACCUMULATOR_RFS_REQUEST_EPIC':
            matomo?.trackEvent(
              mapActionToCategory(action),
              action.forceTradeable ? 'Tradeable stream request' : 'Stream request',
            );
            break;
          case 'ACCUMULATOR_SCHEDULE_LEG_PROPERTIES_CHANGED':
            matomo?.trackEvent('Accumulator', 'Calendar overridden');
            break;
          case 'CASH_RFS_STARTED':
          case 'SWAP_RFS_STARTED':
          case 'BULK_RFS_STARTED':
            matomo?.trackEvent(mapActionToCategory(action), 'Stream request');
            break;
          case 'CASH_RFS_CANCEL':
          case 'SWAP_RFS_CANCEL':
          case 'OPTION_STREAM_CANCELED':
          case 'BULK_RFS_CANCEL':
          case 'ACCUMULATOR_RFS_CANCELED':
            matomo?.trackEvent(mapActionToCategory(action), 'Stream cancel');
            break;
          case 'CASH_RFS_EXECUTION_REQUESTED':
          case 'CASH_ESP_EXECUTION_REQUESTED':
          case 'SWAP_RFS_EXECUTION_REQUESTED_EPIC':
          case 'OPTION_EXECUTION_REQUESTED':
          case 'AMERICAN_FORWARD_EXECUTION_REQUESTED':
          case 'BULK_EXECUTION_REQUESTED_EPIC':
          case 'ACCUMULATOR_EXECUTION_REQUESTED':
            matomo?.trackEvent(mapActionToCategory(action), 'Execution request');
            break;
          case 'ORDER_SUBMISSION_EPIC':
            matomo?.trackEvent('Order', 'Order submission request');
            break;
          case 'CASH_EXECUTION_RECEIVED':
          case 'SWAP_EXECUTION_RECEIVED':
          case 'OPTION_EXECUTION_RECEIVED':
          case 'BULK_EXECUTION_RECEIVED':
          case 'AMERICAN_FORWARD_EXECUTION_RECEIVED':
          case 'ACCUMULATOR_EXECUTION_RECEIVED':
            matomo?.trackEvent(mapActionToCategory(action), 'Execution success');
            break;
          case 'ORDER_SUBMISSION_SUCCESS':
            matomo?.trackEvent('Order', 'Order submission success');
            break;
          case 'AMERICAN_FORWARD_EXECUTION_FAILED':
          case 'CASH_EXECUTION_FAILED':
          case 'SWAP_EXECUTION_FAILED':
          case 'OPTION_EXECUTION_FAILED':
          case 'BULK_EXECUTION_FAILED':
          case 'ACCUMULATOR_EXECUTION_FAILED':
            matomo?.trackEvent(mapActionToCategory(action), 'Execution failure');
            break;
          case 'ORDER_SUBMISSION_FAILED':
            matomo?.trackEvent('Order', 'Order submission failure');
            break;
          case 'BLOTTER_ORDER_MODIFY_EPIC':
            matomo?.trackEvent('Order', 'Order modify');
            break;
          case 'BLOTTER_ORDER_FILL_EPIC':
            matomo?.trackEvent('Order', 'Order fill now');
            break;
          case 'BLOTTER_ORDER_PAUSE_EPIC':
            matomo?.trackEvent('Order', 'Order pause');
            break;
          case 'BLOTTER_ORDER_RESUME_EPIC':
            matomo?.trackEvent('Order', 'Order resume');
            break;
          case 'BLOTTER_ORDER_CANCELLATION_EPIC':
            matomo?.trackEvent('Order', 'Order cancel');
            break;
          case 'BLOTTER_OPEN_TILE_FROM_TRADE':
            matomo?.trackEvent('Blotter', 'Open tile from blotter');
            break;
          case 'OPTION_LEG_ADDED':
            matomo?.trackEvent('Option', 'Add leg to option');
            break;
          case 'OPTION_LEG_DUPLICATED':
            matomo?.trackEvent('Option', 'Duplicate option leg');
            break;
          case 'OPTION_LEG_REMOVED':
            matomo?.trackEvent('Option', 'Remove leg from option');
            break;
          case 'OPTION_LEGS_IMPORTED':
            matomo?.trackEvent('Option', 'Import option legs');
            break;
          case 'OPTION_LEG_CURRENCY_LINKED_TOGGLED':
            matomo?.trackEvent('Option', 'Notional currency pairs link toggled');
            break;
          case 'OPTION_LEGS_PREMIUM_PIN_TOGGLED':
            matomo?.trackEvent('Option', 'Legs premiums pin toggled');
            break;
          case 'OPTION_SOLVING_REQUESTED': {
            const isStrategyView = getOptionIsStrategy(state, action.quoteId);
            if (isStrategyView) {
              matomo?.trackEvent('Option', 'Solving requested in strategy view');
            } else {
              matomo?.trackEvent('Option', 'Solving requested in compact view');
            }
            break;
          }
          case 'ACCUMULATOR_SCHEDULE_IMPORTED':
            matomo?.trackEvent('Accumulator', 'Import TA schedule');
            break;
          case 'CLIENTWORKSPACE_TILE_ZOOMED':
            matomo?.trackEvent('Workspace', 'Zoom tile');
            break;
          case 'CLIENTWORKSPACE_TILE_MINIMIZED':
            matomo?.trackEvent('Workspace', 'Reduce tile');
            break;
          case 'OPTION_ORIENTATION_TOGGLED':
            matomo?.trackEvent(
              'Option',
              action.orientation === 'horizontal' ? 'Switch to horizontal leg' : 'Switch to vertical leg',
            );
            break;
          case 'OPTION_TOGGLE_GROUP':
            matomo?.trackEvent('Option', action.isGroup ? 'Group strategy legs by expiry' : 'Ungroup strategy legs');
            break;
          case 'OPTION_COPY_PASTED':
            matomo?.trackEvent('Option', 'Paste in option strategy');
            break;
          case 'PIN_CLIENT':
            matomo?.trackEvent('Workspace', 'Pin client');
            break;
          case 'UNPIN_CLIENT':
            matomo?.trackEvent('Workspace', 'Unpin client');
            break;
          case 'MOVE_UP_PINNED_CLIENT':
            matomo?.trackEvent('Workspace', 'Move client up');
            break;
          case 'MOVE_DOWN_PINNED_CLIENT':
            matomo?.trackEvent('Workspace', 'Move client down');
            break;
          case 'CASH_TILE_OPEN_FROM_BLOTTER_EPIC':
          case 'SWAP_TILE_OPEN_FROM_BLOTTER_EPIC':
          case 'OPTION_TILE_OPEN_FROM_BLOTTER_EPIC':
            break;
          case 'OPTION_STRATEGY_TYPE_CHANGED': {
            const trackedOptionStrategyType = mapDisplayType(action.strategyType);
            matomo?.trackEvent('Option', trackedOptionStrategyType);
            break;
          }
        }
        return next(action);
      };

function mapDisplayType(strategyType: OptionStrategyTypeChanged['strategyType']): MatomoAction {
  switch (strategyType) {
    case 'Vanilla':
      return 'Call / Put';
    case 'Straddle':
      return 'Straddle';
    case 'Strangle':
      return 'Strangle';
    case 'RiskReversal':
      return 'Risk Reversal';
    default:
      assertUnreachable(strategyType, 'Unhandled option strategy type');
  }
}
