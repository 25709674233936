import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import type { AppState } from 'state/model';
import { getSessionState } from 'state/session/sessionSelectors';

const reload = () => window.location.reload();
const email = 'sg-markets@sgcib.com';

interface ISessionDisconnectedStateProps {
  errorMsg: string | null;
}

const SessionDisconnectedModalRaw: React.FunctionComponent<ISessionDisconnectedStateProps> = ({
  errorMsg,
}) => (
  <Modal
    isOpen={errorMsg !== null}
    backdrop="static"
    className="modal-dialog-centered"
    contentClassName="shadow-max border border-danger"
  >
    <ModalHeader>
      <div
        className="rounded-circle bg-danger bg-opacity-10 d-flex align-items-center justify-content-center"
        style={{ width: '3rem', height: '3rem' }}
      >
        <i className="icon icon-md text-danger line-height-1"> power_off </i>
      </div>
    </ModalHeader>
    <ModalBody className="pb-0">
      <h3 className="text-danger">
        <FormattedMessage id="modals.disconnected.title" />
      </h3>
      <p className="text-secondary text-large">
        <FormattedMessage id={`modals.disconnected.body.reason.${errorMsg}`} />
      </p>
      <p className="text-large">
        <FormattedMessage
          id={`modals.disconnected.body.assistance.${errorMsg}`}
          values={{ email: <a href={`mailto:${email}`}>{email}</a>, br: <br /> }}
        />
      </p>
    </ModalBody>
    <ModalFooter>
      <button type="button" className="btn btn-lg btn-default" onClick={reload}>
        <FormattedMessage id="modals.disconnected.footer.button" />
      </button>
    </ModalFooter>
  </Modal>
);

function mapStateToProps(state: AppState): ISessionDisconnectedStateProps {
  const session = getSessionState(state);
  if (session.isValid === true) {
    return { errorMsg: null };
  }
  return { errorMsg: session.logoffReason };
}

export const SessionDisconnectedModal = connect(mapStateToProps)(SessionDisconnectedModalRaw);

export default SessionDisconnectedModal;
