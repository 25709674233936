import type { ISgmeHttp, HttpCall } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { extract } from 'utils/object';

export const pinnedCompaniesServiceEndPoint = 'api/userdata/pinned/';

export interface IPinnedCompaniesServiceApi {
  timeout: number;
  save(workspace: readonly number[], workspaceId?: number): Observable<HttpCall<void>>;
  get(workspaceId?: number): Observable<readonly number[] | undefined | null>;
}
interface PinnedCompaniesPayload {
  pinnedCompanies: readonly number[];
}
export function pinnedCompaniesServiceApi(http: ISgmeHttp): IPinnedCompaniesServiceApi {
  return {
    save(pinnedCompanies: readonly number[]) {
      return http.post<void>(pinnedCompaniesServiceEndPoint, { pinnedCompanies });
    },
    get() {
      return http.getJSON<PinnedCompaniesPayload>(pinnedCompaniesServiceEndPoint).pipe(
        map(extract('payload')),
        map(c => c?.pinnedCompanies),
      );
    },
    timeout: 10_000,
  };
}
