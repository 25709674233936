import { MenuButton } from './MenuButton';
import { FormattedMessage } from 'react-intl';
import { connectMifidPanelButton } from './connect';

export interface MifidPanelButtonProps {
  toggleMifidPanel(): void;
}

export const MifidPanelButtonRaw: React.FunctionComponent<MifidPanelButtonProps> = ({
  toggleMifidPanel,
}) => (
  <MenuButton
    e2eHandle="toggle-mifid"
    onClick={toggleMifidPanel}
    tooltip={<FormattedMessage id="navMenuButtons.mifidPanel" />}
  >
    <i className="icon icon-md">perm_phone_msg</i>
  </MenuButton>
);

export const MifidPanelButton = connectMifidPanelButton(MifidPanelButtonRaw);
