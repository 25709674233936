import type { PredealCheck } from 'state/share/predealChecksModel';

export const GLOBAL_ERROR_AUTH_EXPIRED = 'AUTH_EXPIRED';

export type SendHelpStatus = 'sending' | 'failed' | 'success';

interface IGlobalErrorState {
  error: string | null;
  tileId: string | null;
  executionId: string | null;
  isOffline: boolean;
}

export interface IStreamError {
  code: string | null;
  message?: string | null;
  multipassErrorCode?: string | null;
  multipassSessionId?: string | null;
  predealChecks?: readonly PredealCheck[];
}

export interface IOrderError {
  orderId: string | null;
  message?: string | null;
}

export type GlobalErrorState = Readonly<IGlobalErrorState>;
