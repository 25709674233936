import type { Thunk } from 'state';
import type { CashTradeCapturePatch } from 'api/tradeCapture/cash/tradeCaptureModel';
import type { TradeCaptureError } from 'state/tile/fxTileModel';
import { isDefined } from '@sgme/fp';
// import { isProductEspEnabledToDelete } from 'state/esp/utils';
// import { isProductEspCompatible } from "../../esp/espStreamsSelectors";

export function cashPropertiesReceivedThunk(cashId: string, cashPatch: CashTradeCapturePatch): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const oldState = getState();

    if (!sl.isTilePresent(oldState, cashId) || sl.getQuoteInstrument(oldState, cashId) !== 'Cash') {
      return;
    }

    const oldProductName = sl.getCashProductName(oldState, cashId);
    const oldTenor = sl.getCashMaturityDateTenor(oldState, cashId);
    const oldCurrencyPair = sl.getCashCurrencyPair(oldState, cashId).value;
    const isPreviouslyESPEnabled = sl.isProductEspCompatible(oldState, cashId);

    const {
      idVersion,
      isReadyToPrice,
      isPriceObsolete: isPreviousRFSObsolete,
      productName,
      ...receivedPatch
    } = cashPatch;

    const tenor = receivedPatch.values.maturityDateTenor ?? oldTenor.value;
    const currencyPair = receivedPatch.values.currencyPair ?? oldCurrencyPair;

    const hasChangedProductName = productName !== oldProductName;
    const hasChangedCurrencyPair = isDefined(currencyPair) && currencyPair !== oldCurrencyPair;
    const hasChangedTenor = isDefined(tenor) && tenor !== oldTenor.value;

    const hasChangedNdfProperties =
      productName === 'FxNdf' &&
      ((isDefined(cashPatch.values.fixingSource) &&
        cashPatch.values.fixingSource !== sl.getCashFixingSource(oldState, cashId).value) ||
        (isDefined(cashPatch.values.sndFixingSource) &&
          cashPatch.values.sndFixingSource !== sl.getCashSndFixingSource(oldState, cashId).value) ||
        (isDefined(cashPatch.values.fixingCurrency) &&
          cashPatch.values.fixingCurrency !== sl.getCashFixingCurrency(oldState, cashId).value));

    // isPriceObsolete RFS obsolete
    // isReadyToPrice RFS possible
    dispatch(
      ac.cashPropertiesReceived(
        cashId,
        { productName, ...receivedPatch },
        idVersion,
        isReadyToPrice ?? null,
        isPreviousRFSObsolete,
      ),
    );

    const state = getState();

    const isESPEnabled = sl.isProductEspCompatible(state, cashId);

    /** SHOULD WE CANCEL RFS? */
    if (isPreviousRFSObsolete || hasChangedProductName) {
      dispatch(ac.cashRfsCancelThunk(cashId));
    }

    /** SHOULD WE RESTART ESP? */
    if (
      (isPreviouslyESPEnabled || isESPEnabled) &&
      (hasChangedCurrencyPair || hasChangedTenor || hasChangedProductName || hasChangedNdfProperties)
    ) {
      dispatch(ac.espStreamRestartThunk(cashId, currencyPair, productName, undefined, tenor));
    }

    if (hasChangedCurrencyPair || hasChangedProductName || hasChangedTenor) {
      /** SHOULD WE RESET MARGIN? */
      dispatch(
        ac.cashLocalPropertyChanged(cashId, {
          bidMargin: null,
          askMargin: null,
          bidForwardMargin: null,
          askForwardMargin: null,
        }),
      );
    }

    const requesterCounterpartyId = sl.getClientForTile(state, cashId).companyId;

    // we need to trigger the fixingReference epic whenever a new cash ndf is sent by tradeCapture, this covers:
    // - when a cash tile switch from a non ndf product to a ndf product
    // - when a new ndf currencyPair is selected

    const isNewNdf = productName === 'FxNdf' && oldProductName !== 'FxNdf';
    const hasNewNdfCurrencyPair = productName === 'FxNdf' && oldCurrencyPair !== currencyPair;

    if ((isNewNdf || hasNewNdfCurrencyPair) && currencyPair) {
      //FX is hardcoded as the asset for now, as it is the only one supported. Might need to change if support is extended
      dispatch(ac.getFixingReferencesRequested('FX', currencyPair, requesterCounterpartyId.toString(), cashId));
    }
  };
}

// ---------------------------------------

export function cashPropertiesRequestFailedThunk(cashId: string, error: TradeCaptureError): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    if (!sl.isTilePresent(state, cashId) || sl.getQuoteInstrument(state, cashId) !== 'Cash') {
      return;
    }

    dispatch(ac.cashPropertiesRequestFailed(cashId, error));

    const { currentStreamId, currentEspStreamId } = sl.getCashState(state, cashId);
    if (currentStreamId !== null) {
      dispatch(ac.cashRfsCancelEpic(cashId, currentStreamId));
    }
    if (currentEspStreamId !== null) {
      dispatch(ac.espTileStreamUnsubscribeThunk(cashId));
    }
  };
}
