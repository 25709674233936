import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { IOptionQuoteUpdateApi } from 'epics/option';
import { ignoreElements } from 'rxjs/operators';

export function optionQuoteUpdateApi(http: ISgmeHttp): IOptionQuoteUpdateApi {
  return {
    updateQuoteRequest(request) {
      return http
        .post<void>('api/fxOption/rfs/update', request, { 'X-Correlation-ID': request.rfsId })
        .pipe(ignoreElements());
    },
  };
}
