/**
 * @private
 * these exports are shared helper for exported selectors
 *
 * don't export this file in barrel ('./index.ts')
 */

import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';
import { isDefined } from '@sgme/fp';
import type { IMargedLeg, ISimpleLeg } from '../fxBulksModel';
import { makeBulkLegId } from './utils';
import type { Collection } from 'typings/utils';

export function getBulkLegState(state: AppState, bulkId: string, legId: string) {
  const fullLegId = makeBulkLegId(bulkId, legId);
  return throwIfUndefined(
    state.fxBulks.legs[fullLegId],
    `Leg ${fullLegId} does not exist in state`,
  );
}

export const getBulkSplitLegs = (state: AppState, bulkId: string) =>
  getLegsForBulk(state.fxBulks.splitLegs, bulkId);

export const getBulkNettedLegs = (state: AppState, bulkId: string) =>
  getLegsForBulk(state.fxBulks.nettedLegs, bulkId);

const getLegsForBulk = <L extends ISimpleLeg | IMargedLeg>(
  legs: Collection<L>,
  bulkId: string,
): L[] =>
  Object.entries(legs)
    .filter(([id]) => id.startsWith(bulkId))
    .map(([, leg]) => leg)
    .filter(isDefined);

const getNettedLegField =
  <F extends keyof IMargedLeg>(field: F) =>
  (state: AppState, bulkId: string, date: string): IMargedLeg[F] | null => {
    const nettedLeg = state.fxBulks.nettedLegs[makeBulkLegId(bulkId, date)];
    return nettedLeg === undefined ? null : nettedLeg[field];
  };

export const getBulkNettedLegMargin = getNettedLegField('margin');
export const getBulkNettedLegWay = getNettedLegField('way');
