import { getOptionMetadata } from './factories';

export type { OptionMetadataStatus } from './factories';

export const getOptionEspStreamId = getOptionMetadata('currentEspStreamId');
export const getOptionRfsStreamId = getOptionMetadata('currentStreamId');
export const getOptionPropertiesRequested = getOptionMetadata('propertiesRequested');
export const getOptionDisplayPriceType = getOptionMetadata('displayPriceType');
export const getOptionIsStrategy = getOptionMetadata('isStrategy');
// export const getOptionLegIds = getOptionMetadata('legIds');
export const getOptionLegsCurrencyLinked = getOptionMetadata('legsCurrencyLinked');
export const getOptionNextLegId = getOptionMetadata('nextLegId');
export const getOptionLastStreamId = getOptionMetadata('lastStreamId');
export const getOptionUserSetGroup = getOptionMetadata('group');
export const getOptionDisplaySolveStrike = getOptionMetadata('displaySolveStrikeModal');
