import { SgwtAccountCenter } from '@sgwt/sgwt-widgets-react';
import { getConfig } from 'config/config';
import { useIntl } from 'react-intl';

export const AccountCenter = () => {
  const {
    SGWTWidgetConfiguration: { environment },
  } = getConfig();
  const availableLanguages = ['fr', 'en'];
  const { locale } = useIntl();

  return (
    <SgwtAccountCenter
      authentication="sg-connect"
      availableLanguages={availableLanguages}
      debug={environment === 'homologation'} // To show debug on homolo env
      environment={environment === 'homologation' ? environment: ''}
      language={locale}
      mode="sg-markets"
      producerCode="sgm_exec_fx"
    />
  );
};
