import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';

export function getFreeLayoutState(state: AppState) {
  return state.freeLayout;
}

export function getFreeLayoutById(state: AppState, layoutId: string) {
  return throwIfUndefined(
    getFreeLayoutState(state)[layoutId],
    `Free layout state for ${layoutId} should exist at this stage`,
  );
}
