import { getOrderMetadata } from './factories';
import type { AppState } from 'state/model';

export const getOrderIsReadyToSubmit = getOrderMetadata('isReadyToSubmit');
export const getOrderPropertiesRequestError = getOrderMetadata('propertiesRequestError');

export const getOrderStatus = getOrderMetadata('orderStatus');
export const getOrderEditingEmails = getOrderMetadata('editingEmails');
export const getOrderClockOffset = getOrderMetadata('clockOffset');
export const getOrderPropertiesRequested = (state: AppState, orderId: string) =>
  getOrderStatus(state, orderId) === 'PropertiesRequested';
export const getOrderIsStartDateNow = getOrderMetadata('isStartDateNow');
export const getOrderTemplateId = getOrderMetadata('currentTemplateId');
