import { memo } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { connectTabMenuItem, connectTabsMenu } from './connect';
import type { IWorkspaceTabs } from 'state/clientWorkspace';
import { MenuButton } from './MenuButton';

interface TabsMenuProps {
  tabs: IWorkspaceTabs;
  activeTab: string | null;
}

interface TabMenuItemProps {
  tabName: string;
  selected: boolean;

  onSwitchToTab(): void;
}

function TabMenuItemRaw({ tabName, selected, onSwitchToTab }: TabMenuItemProps): JSX.Element {
  return (
    <DropdownItem onClick={onSwitchToTab} className={selected ? 'active' : undefined}>
      {tabName}
    </DropdownItem>
  );
}

const TabMenuItem = connectTabMenuItem(TabMenuItemRaw);

function TabsMenuRaw({ tabs, activeTab }: TabsMenuProps): JSX.Element {
  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="span">
        <MenuButton e2eHandle="tabs-list" color="secondary" data-toggle="dropdown">
          <i className="icon icon-md px-2">keyboard_arrow_down</i>
        </MenuButton>
      </DropdownToggle>

      <DropdownMenu
        end
        style={{
          overflow: 'auto',
          maxHeight: '50vh',
        }}
      >
        {Object.entries(tabs).map(([tabId, tab]) => (
          <TabMenuItem
            key={tabId}
            tabId={tabId}
            tabName={tab!.name}
            selected={tabId === activeTab}
          />
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export const TabsMenu = connectTabsMenu(memo(TabsMenuRaw));
