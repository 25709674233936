import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import type { UserPreferencesData } from 'state/userPreferences';
import { mapStateToPropsUserPref, mapDispatchToPropsUserPref } from './connectUserPref';
import {
  mapStateToPropsDefaultHedgeType,
  mapDispatchToPropsDefaultHedgeType,
} from './connectDefaultHedgeType';
import {
  mapStateToPropsDefaultPriceType,
  mapDispatchToPropsDefaultPriceType,
} from './connectDefaultPriceType';

export const connectUserPref = <K extends keyof UserPreferencesData>(key: K) =>
  connect(mapStateToPropsUserPref(key)(selectors), mapDispatchToPropsUserPref(key)(actionCreators));

export const connectDefaultHedgeType = connect(
  mapStateToPropsDefaultHedgeType(selectors),
  mapDispatchToPropsDefaultHedgeType(actionCreators),
);

export const connectDefaultPriceType = connect(
  mapStateToPropsDefaultPriceType(selectors),
  mapDispatchToPropsDefaultPriceType(actionCreators),
);
