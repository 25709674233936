import type { IFormData, SharedKey, SharedPropertyStatus, ValueKey } from './formModel';

export function getValues<T>({ values }: IFormData<T, any>) {
  return values;
}

export function getInputs<T>({ inputs }: IFormData<any, T>) {
  return inputs;
}

export function getErrors<T>({ errors }: IFormData<T, any>) {
  return errors;
}

export function getWarnings<T>({ warnings }: IFormData<T, any>) {
  return warnings;
}

export function getSharedProperty<Values, Inputs, PropertyName extends SharedKey<Values, Inputs>>(
  property: PropertyName,
) {
  return (
    form: IFormData<Values, Inputs>,
  ): SharedPropertyStatus<
    IFormData<Values, Inputs>['values'][PropertyName],
    IFormData<Values, Inputs>['inputs'][PropertyName]
  > => ({
    value: getValues(form)[property],
    error: getErrors(form)[property],
    warning: getWarnings(form)[property],
    input: getInputs(form)[property],
  });
}

export function getValueProperty<V, I, T extends ValueKey<V, I>>(property: T) {
  return (form: IFormData<V, I>): V[T] => getValues(form)[property];
}
