import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';

import { mapStateToPropsMifidPanel, mapDispatchToPropsMifidPanel } from './connectMifidPanel';
export const connectMifidPanel = connect(
  mapStateToPropsMifidPanel(selectors),
  mapDispatchToPropsMifidPanel(actionCreators),
);

import { mapStateToPropsNegotiation, mapDispatchToPropsNegotiation } from './connectNegotiation';
export const connectNegotiation = connect(
  mapStateToPropsNegotiation(selectors),
  mapDispatchToPropsNegotiation(actionCreators),
);

import { mapStateToPropsMifidQuote } from './connectMifidQuote';
export const connectMifidQuote = connect(mapStateToPropsMifidQuote(selectors));
