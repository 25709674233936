import type { AppState } from 'state/model';
import { assertIsDefined } from '@sgme/fp';
import { isFxOptionMultileg, isVanillaLeg } from '../utilities';
import type {
  FxOptionLegState,
  FxOptionMultilegState,
  TypedStrategyLegType,
  VanillaLegState,
} from '../model/optionsLegs';
import type { IFxOptionsState } from '../model';

//  ██████╗ ██████╗ ███╗   ███╗███╗   ███╗ ██████╗ ███╗   ██╗
// ██╔════╝██╔═══██╗████╗ ████║████╗ ████║██╔═══██╗████╗  ██║
// ██║     ██║   ██║██╔████╔██║██╔████╔██║██║   ██║██╔██╗ ██║
// ██║     ██║   ██║██║╚██╔╝██║██║╚██╔╝██║██║   ██║██║╚██╗██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║ ╚═╝ ██║╚██████╔╝██║ ╚████║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
//

export function getOptionLeg(state: AppState, legId: string): FxOptionLegState {
  const leg = state.fxOptions.legs[legId];
  assertIsDefined(leg, `Leg ${legId} does not exist in state`);
  return leg;
}

//  ██████╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ██╔═══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██║   ██║██████╔╝   ██║   ██║██║   ██║██╔██╗ ██║
// ██║   ██║██╔═══╝    ██║   ██║██║   ██║██║╚██╗██║
// ╚██████╔╝██║        ██║   ██║╚██████╔╝██║ ╚████║
//  ╚═════╝ ╚═╝        ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
//

export function getOptionSubLegIdsByLegId(
  state: AppState,
  optionId: string,
): Record<string, string[] | [undefined]> {
  return getAllLegsOfOptionWithId(state, optionId).reduce(
    toSubLegIdsByLegId,
    {} as Record<string, string[]>,
  );
}

const toSubLegIdsByLegId = (
  subLegIdsByLegId: Record<string, string[] | [undefined]>,
  [legId, leg]: readonly [string, FxOptionLegState],
): Record<string, string[] | [undefined]> => {
  subLegIdsByLegId[legId] = isVanillaLeg(leg) ? [undefined] : leg.legIds;

  return subLegIdsByLegId;
};

export function getProductNameAndSublegIdsOfAllLegsOfOptionById(
  state: IFxOptionsState,
  optionId: string,
): Record<
  string,
  { productName: 'Vanilla' | 'FxOptionMultileg' | TypedStrategyLegType; legIds?: string[] }
> {
  return Object.entries(state.legs)
    .filter(([legId, _leg]) => legId.startsWith(optionId))
    .reduce((acc, [legId, leg]) => {
      if (leg && !isVanillaLeg(leg)) {
        acc[legId] = { legIds: leg.legIds, productName: leg.productName };
      } else {
        acc[legId] = { productName: leg!.productName };
      }
      return acc;
    }, {} as Record<string, { productName: 'Vanilla' | 'FxOptionMultileg' | TypedStrategyLegType; legIds?: string[] }>);
}

// TODO: useful ?? better use = getFxOptionMultilegOfOption ?
export function getFirstLegIdOfOption(state: AppState, optionId: string) {
  const [firstLegId] = getFxOptionMultilegOfOption(state, optionId).legIds;

  return firstLegId;
}

export function getAllLegIdsOfOption(state: AppState, optionId: string) {
  const optionPrefixLegId = `${optionId}/`;

  return Object.keys(state.fxOptions.legs).filter(legId => legId.startsWith(optionPrefixLegId));
}

export function getAllLegsOfOption(
  state: AppState,
  optionId: string,
): Array<Readonly<FxOptionLegState>> {
  return getAllLegIdsOfOption(state, optionId).map(legId => getOptionLeg(state, legId));
}

export function getAllLegsOfOptionWithId(
  state: AppState,
  optionId: string,
): Array<readonly [string, FxOptionLegState]> {
  return getAllLegIdsOfOption(state, optionId).map(legId => [legId, getOptionLeg(state, legId)]);
}

// ███╗   ███╗██╗   ██╗██╗  ████████╗██╗██╗     ███████╗ ██████╗
// ████╗ ████║██║   ██║██║  ╚══██╔══╝██║██║     ██╔════╝██╔════╝
// ██╔████╔██║██║   ██║██║     ██║   ██║██║     █████╗  ██║  ███╗
// ██║╚██╔╝██║██║   ██║██║     ██║   ██║██║     ██╔══╝  ██║   ██║
// ██║ ╚═╝ ██║╚██████╔╝███████╗██║   ██║███████╗███████╗╚██████╔╝
// ╚═╝     ╚═╝ ╚═════╝ ╚══════╝╚═╝   ╚═╝╚══════╝╚══════╝ ╚═════╝
//

export function getFxOptionMultilegOfOption(state: AppState, optionId: string) {
  const [fxOptionMultilegId] = state.fxOptions.options[optionId]?.legIds!;
  const leg = getOptionLeg(state, fxOptionMultilegId);

  if (!isFxOptionMultileg(leg)) {
    throw new Error(`No multileg in option: ${optionId}`);
  }

  return leg;
}

// TODO: use getFxOptionMultilegOfOption instead ?
export function getLegIdsOfMultilegOfOption(state: AppState, optionId: string) {
  return getFxOptionMultilegOfOption(state, optionId).legIds;
}

export function getAllLegsOfMultilegOfOptionWithId(
  state: AppState,
  optionId: string,
): Array<readonly [string, FxOptionLegState]> {
  return getLegIdsOfMultilegOfOption(state, optionId).map(legId => [
    legId,
    getOptionLeg(state, legId),
  ]);
}

export function getFirstLegOfMultilegOfOption(state: AppState, optionId: string) {
  const multileg = getFxOptionMultilegOfOption(state, optionId);
  const [firstLegId] = multileg.legIds;

  return getOptionLeg(state, firstLegId) as Exclude<FxOptionLegState, FxOptionMultilegState>;
}

export function getSublegIdsOfLegsOfFxOptionMultiLegById(
  state: AppState,
  optionId: string,
): Record<string, string[] | [undefined]> {
  return getAllLegsOfMultilegOfOptionWithId(state, optionId).reduce(
    toSubLegIdsByLegId,
    {} as Record<string, string[]>,
  );
}

// ██╗   ██╗ █████╗ ███╗   ██╗██╗██╗     ██╗      █████╗
// ██║   ██║██╔══██╗████╗  ██║██║██║     ██║     ██╔══██╗
// ██║   ██║███████║██╔██╗ ██║██║██║     ██║     ███████║
// ╚██╗ ██╔╝██╔══██║██║╚██╗██║██║██║     ██║     ██╔══██║
//  ╚████╔╝ ██║  ██║██║ ╚████║██║███████╗███████╗██║  ██║
//   ╚═══╝  ╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚══════╝╚══════╝╚═╝  ╚═╝
//

export function getVanillaLeg(state: AppState, legId: string) {
  const leg = getOptionLeg(state, legId);

  if (!isVanillaLeg(leg)) {
    throw new Error(`Leg is not a vanilla: ${legId}`);
  }

  return leg;
}

export function assertLegIsVanilla(state: AppState, legId: string) {
  getVanillaLeg(state, legId); // must throw an error if the leg is undefined or not a vanilla

  return true;
}

export function getAllVanillaLegsOfOption(
  state: AppState,
  optionId: string,
): Readonly<VanillaLegState[]> {
  return getAllLegsOfOption(state, optionId).filter(leg => isVanillaLeg(leg)) as Readonly<
    VanillaLegState[]
  >;
}

export function getAllVanillaLegIdsOfOption(state: AppState, optionId: string): string[] {
  return getAllLegsOfOptionWithId(state, optionId)
    .filter(([, leg]) => isVanillaLeg(leg))
    .map(([legId]) => legId);
}

export function getCallPutLegIdsOfOption(state: AppState, optionId: string): [ string, string ] | [ undefined, undefined ] {
  const [ legId1, legId2 ] = getAllVanillaLegIdsOfOption(state, optionId)

  if (legId1.endsWith("call")) {
    return [ legId1, legId2 ]
  }

  if (legId2.endsWith("call")) {
    return [ legId2, legId1 ]
  }

  return [ undefined, undefined ]
}

export function getAllVanillaLegsOfOptionWithId(
  state: AppState,
  optionId: string,
): Array<readonly [string, VanillaLegState]> {
  return getAllLegsOfOptionWithId(state, optionId).filter(([, leg]) => isVanillaLeg(leg)) as Array<
    readonly [string, VanillaLegState]
  >;
}

export function getFirstVanillaLegOfOption(state: AppState, optionId: string): VanillaLegState {
  const [firstLegId] = getFxOptionMultilegOfOption(state, optionId).legIds;
  const firstLeg = getOptionLeg(state, firstLegId);

  if (isVanillaLeg(firstLeg)) {
    return firstLeg;
  }

  return getVanillaLeg(state, firstLeg.legIds[0]);
}

export function getFirstVanillaLegIdOfOption(state: AppState, optionId: string): string {
  const [firstLegId] = getFxOptionMultilegOfOption(state, optionId).legIds;
  const firstLeg = getOptionLeg(state, firstLegId);

  if (isVanillaLeg(firstLeg)) {
    return firstLegId;
  }

  const firstSubLegId = firstLeg.legIds[0];
  assertLegIsVanilla(state, firstSubLegId);

  return firstSubLegId;
}
