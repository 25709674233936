import { getRelevantPriceFromBidAsk } from 'state/share/productModel/utils';
import type { Way } from 'state/share/productModel/litterals';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { SwapRfsExecutePayload } from 'api/multipass/swap/swapRfsQuoteExecution';
import { getDateForMultipass } from 'utils/dateFormats';
import { assertIsDefined, isEmpty } from '@sgme/fp';

type MySelectorKeys =
  | 'getSwapRfsStream'
  | 'getDisplayNearPrice'
  | 'getDisplayFarPrice'
  | 'getDisplaySpot'
  | 'getDisplayNearPoints'
  | 'getDisplayFarPoints'
  | 'getConnectionId'
  | 'getUserPreferenceData';

type MySelectors = Pick<Selectors, MySelectorKeys>;

export const swapRfsExecuteRequestWith: MapStateToMetadataHOF<
  SwapRfsExecutePayload,
  { swapId: string; way: Way; streamId: string; tradeDate: Date },
  AppState,
  MySelectors
> =
  sl =>
  (state, { swapId, way, streamId, tradeDate }) => {
    const streamState = sl.getSwapRfsStream(state, streamId);

    if (streamState.status !== 'PRICING') {
      throw 'error : can not execute when stream is not pricing';
    }
    const {
      quoteId,
      nearSalesAllInRate,
      farSalesAllInRate,
      farPoints: farPointsTrader,
      frontTimestamp,
      backTimestamp,
    } = streamState.quote;
    const nearPrice = sl.getDisplayNearPrice(state, swapId).value;
    const farPrice = sl.getDisplayFarPrice(state, swapId).value;

    assertIsDefined(nearPrice, 'nearPrice should be defined for execution');
    assertIsDefined(farPrice, 'farPrice should be defined for execution');

    const spot = sl.getDisplaySpot(state, swapId);
    const spotWithWay = getRelevantPriceFromBidAsk(spot, way);
    const nearPriceWithWay = getRelevantPriceFromBidAsk(nearPrice, way);
    const farPriceWithWay = getRelevantPriceFromBidAsk(farPrice, way);
    const nearPoints = sl.getDisplayNearPoints(state, swapId);
    const farPoints = sl.getDisplayFarPoints(state, swapId);
    const farPointsWithMarginWithWay = getRelevantPriceFromBidAsk(farPoints.raw, way);
    const nearPointsWithWay = getRelevantPriceFromBidAsk(nearPoints.raw, way);
    const farPointsWithoutMarginWithWay = getRelevantPriceFromBidAsk(farPointsTrader, way);
    const nearSalesAllInRateWithWay = getRelevantPriceFromBidAsk(nearSalesAllInRate, way);
    const farSalesAllInRateWithWay = getRelevantPriceFromBidAsk(farSalesAllInRate, way);
    const { emails, splitNotificationsEmailsCash, splitNotifications } =
      sl.getUserPreferenceData(state);
    let emailNotificationList;
    if (splitNotifications && !isEmpty(splitNotificationsEmailsCash)) {
      emailNotificationList = splitNotificationsEmailsCash.join(';');
    } else {
      emailNotificationList = emails.join(';');
    }
    const userLocalDateTime = getDateForMultipass(tradeDate);
    const lastQuoteFromBackTimestamp = backTimestamp;
    const lastQuoteReceivedByFrontTimestamp = frontTimestamp.getTime();
    const executionClickByFrontTimestamp = tradeDate.getTime();

    const connectionId = sl.getConnectionId(state);
    const payload: SwapRfsExecutePayload = {
      replyToStream: connectionId,
      rfsId: streamId,
      quoteId,
      way,
      spot: spotWithWay,
      nearPrice: nearPriceWithWay,
      farPrice: farPriceWithWay,
      nearPoints: nearPointsWithWay,
      farPointsWithoutMargin: farPointsWithoutMarginWithWay,
      farPointsWithMargin: farPointsWithMarginWithWay,
      nearSalesAllInRate: nearSalesAllInRateWithWay,
      farSalesAllInRate: farSalesAllInRateWithWay,
      emailNotificationList,
      userLocalDateTime,
      lastQuoteFromBackTimestamp,
      lastQuoteReceivedByFrontTimestamp,
      executionClickByFrontTimestamp,
    };
    return payload;
  };
