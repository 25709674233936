import type { IFxHedgeInputs } from 'state/fxOptions/model/optionHedges';
import type { IFxVanillaLegInputs, TypedStrategyLegType } from 'state/fxOptions/model/optionsLegs';
import type { IFxOptionInputs } from '../../../state/fxOptions/model/optionProduct';
import type { CurrencyChoice } from '../../../state/share/productModel/litterals';

// ████████╗ ██████╗    ████████╗ ██████╗     ██████╗  █████╗  ██████╗██╗  ██╗███████╗███╗   ██╗██████╗
// ╚══██╔══╝██╔════╝    ╚══██╔══╝██╔═══██╗    ██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝████╗  ██║██╔══██╗
//    ██║   ██║            ██║   ██║   ██║    ██████╔╝███████║██║     █████╔╝ █████╗  ██╔██╗ ██║██║  ██║
//    ██║   ██║            ██║   ██║   ██║    ██╔══██╗██╔══██║██║     ██╔═██╗ ██╔══╝  ██║╚██╗██║██║  ██║
//    ██║   ╚██████╗       ██║   ╚██████╔╝    ██████╔╝██║  ██║╚██████╗██║  ██╗███████╗██║ ╚████║██████╔╝
//    ╚═╝    ╚═════╝       ╚═╝    ╚═════╝     ╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝╚═════╝

export type TradeCaptureToBackendMetadata = {
  patch: OptionStoreModelChanges;
} & WithOptionId;

//  ██████╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ██╔═══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██║   ██║██████╔╝   ██║   ██║██║   ██║██╔██╗ ██║
// ██║   ██║██╔═══╝    ██║   ██║██║   ██║██║╚██╗██║
// ╚██████╔╝██║        ██║   ██║╚██████╔╝██║ ╚████║
//  ╚═════╝ ╚═╝        ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

export type OptionStoreModelChanges = Partial<IFxOptionInputs> & {
  legs: Record<string, FxOptionMultilegModelChanges>;
  hedges: OptionHedgesStoreModelChanges;
  // TODO 4287: move into FxOptionMultilegModelChanges legs after the activation of multi-hedges
  hedgePaymentDate?: string | null;
  hedgeNotional?: string | null;
  hedgeNotionalCurrency?: CurrencyChoice;
  hedgePrice?: string | null;
};

//  ██████╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗    ███╗   ███╗██╗   ██╗██╗  ████████╗██╗██╗     ███████╗ ██████╗
// ██╔═══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║    ████╗ ████║██║   ██║██║  ╚══██╔══╝██║██║     ██╔════╝██╔════╝
// ██║   ██║██████╔╝   ██║   ██║██║   ██║██╔██╗ ██║    ██╔████╔██║██║   ██║██║     ██║   ██║██║     █████╗  ██║  ███╗
// ██║   ██║██╔═══╝    ██║   ██║██║   ██║██║╚██╗██║    ██║╚██╔╝██║██║   ██║██║     ██║   ██║██║     ██╔══╝  ██║   ██║
// ╚██████╔╝██║        ██║   ██║╚██████╔╝██║ ╚████║    ██║ ╚═╝ ██║╚██████╔╝███████╗██║   ██║███████╗███████╗╚██████╔╝
//  ╚═════╝ ╚═╝        ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝    ╚═╝     ╚═╝ ╚═════╝ ╚══════╝╚═╝   ╚═╝╚══════╝╚══════╝ ╚═════╝

export interface FxOptionMultilegModelChanges {
  productName: 'FxOptionMultileg';
  legs: TypedStrategyOptionLegsStoreModelChanges | VanillaOptionLegsStoreModelChanges;
}

// ████████╗██╗   ██╗██████╗ ███████╗██████╗     ███████╗████████╗██████╗  █████╗ ████████╗███████╗ ██████╗██╗   ██╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔══██╗    ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔════╝╚██╗ ██╔╝
//    ██║    ╚████╔╝ ██████╔╝█████╗  ██║  ██║    ███████╗   ██║   ██████╔╝███████║   ██║   █████╗  ██║  ███╗╚████╔╝
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ██║  ██║    ╚════██║   ██║   ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║   ██║ ╚██╔╝
//    ██║      ██║   ██║     ███████╗██████╔╝    ███████║   ██║   ██║  ██║██║  ██║   ██║   ███████╗╚██████╔╝  ██║
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚═════╝     ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝ ╚═════╝   ╚═╝

export type TypedStrategyOptionLegsStoreModelChanges = Record<
  string,
  TypedStrategyOptionLegsModelChanges
>;

export interface TypedStrategyOptionLegsModelChanges {
  productName: TypedStrategyLegType;
  legs: VanillaOptionLegsStoreModelChanges;
}

// ██╗   ██╗ █████╗ ███╗   ██╗██╗██╗     ██╗      █████╗
// ██║   ██║██╔══██╗████╗  ██║██║██║     ██║     ██╔══██╗
// ██║   ██║███████║██╔██╗ ██║██║██║     ██║     ███████║
// ╚██╗ ██╔╝██╔══██║██║╚██╗██║██║██║     ██║     ██╔══██║
//  ╚████╔╝ ██║  ██║██║ ╚████║██║███████╗███████╗██║  ██║
//   ╚═══╝  ╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚══════╝╚══════╝╚═╝  ╚═╝

export type VanillaOptionLegsStoreModelChanges = Record<string, VanillaOptionLegsModelChanges>;

export type VanillaOptionLegsModelChanges = Partial<IFxVanillaLegInputs> & {
  productName: 'Vanilla';
  // It's mandatory to check of the leg strategy
};

export function isVanilla(
  leg: TypedStrategyOptionLegsModelChanges | VanillaOptionLegsModelChanges,
): leg is VanillaOptionLegsModelChanges {
  return leg.productName === 'Vanilla';
}

// ██╗  ██╗███████╗██████╗  ██████╗ ███████╗
// ██║  ██║██╔════╝██╔══██╗██╔════╝ ██╔════╝
// ███████║█████╗  ██║  ██║██║  ███╗█████╗
// ██╔══██║██╔══╝  ██║  ██║██║   ██║██╔══╝
// ██║  ██║███████╗██████╔╝╚██████╔╝███████╗
// ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚══════╝
//

export type OptionHedgesStoreModelChanges = Record<string, OptionHedgesModelChanges>;

export type OptionHedgesModelChanges = Partial<IFxHedgeInputs> & {
  productName: 'FxOptionHedge';
};

// ███╗   ███╗██╗███████╗ ██████╗███████╗██╗     ██╗      █████╗ ███╗   ██╗███████╗ ██████╗ ██╗   ██╗███████╗
// ████╗ ████║██║██╔════╝██╔════╝██╔════╝██║     ██║     ██╔══██╗████╗  ██║██╔════╝██╔═══██╗██║   ██║██╔════╝
// ██╔████╔██║██║███████╗██║     █████╗  ██║     ██║     ███████║██╔██╗ ██║█████╗  ██║   ██║██║   ██║███████╗
// ██║╚██╔╝██║██║╚════██║██║     ██╔══╝  ██║     ██║     ██╔══██║██║╚██╗██║██╔══╝  ██║   ██║██║   ██║╚════██║
// ██║ ╚═╝ ██║██║███████║╚██████╗███████╗███████╗███████╗██║  ██║██║ ╚████║███████╗╚██████╔╝╚██████╔╝███████║
// ╚═╝     ╚═╝╚═╝╚══════╝ ╚═════╝╚══════╝╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝ ╚═════╝  ╚═════╝ ╚══════╝

export interface WithOptionId {
  optionId: string;
}
