import { isDefined, isNonEmpty, isEmpty, isNotDefined } from '@sgme/fp';
import type {
  PremiumType,
  TcHedgeType,
  TCOptionHedgeValues,
  TCOptionLegValues,
  TradeCaptureFxOptionMultileg,
  TradeCaptureOption,
  TradeCaptureOptionRequestWrapper,
  TradeCaptureVanillaOptionLeg,
} from 'api/tradeCapture/option/tradeCaptureOptionModel';
import type { PriceType } from 'state/fxOptions/fxOptionsModel';
import type { IFxVanillaLegInputs, LegType } from 'state/fxOptions/model/optionsLegs';
import type { AppState } from 'state/model';
import type { Selectors } from 'state/selectors';
import {
  type CurrencyChoice,
  FIRST_CURRENCY,
  type HedgeType,
  SECOND_CURRENCY,
  type Side,
} from 'state/share/productModel/litterals';
import type { DateInputCultureInfo } from 'state/userPreferences';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import { clearUndefined } from 'utils/clearUndefined';
import { mapOptional } from 'utils/optional';
import { parseWithCultureInfo } from 'utils/parseDateWithCultureInfo';
import {
  type FxOptionMultilegModelChanges,
  isVanilla,
  type OptionHedgesStoreModelChanges,
  type OptionStoreModelChanges,
  type TradeCaptureToBackendMetadata,
  type TypedStrategyOptionLegsModelChanges,
  type VanillaOptionLegsModelChanges,
  type WithOptionId,
} from './tradeCaptureModel';
import { convertLegOptionTypeToTradeCaptureType } from './utils/optionTypesMap';
import { convertLegSettlementTypeToTradeCaptureSettlementType } from './utils/settlementTypesMap';

export type TradeCaptureToBackendMetaSelectorsKeys =
  | 'getOptionLegProductName'
  | 'getOptionVanillaLegOptionType'
  | 'getOptionTradeCaptureIdVersion'
  | 'getOptionNextLegId'
  | 'getLegIdsOfMultilegOfOption'
  | 'getUserPreferenceData'
  | 'getOptionHedgeCurrency'
  | 'getFirstLegIdOfOption'
  | 'getFeatureToggles'
  | 'getAllHedgeIdsOfOption';

export type TradeCaptureToBackendMetaSelectorSelectors = Pick<
  Selectors,
  TradeCaptureToBackendMetaSelectorsKeys
>;
// ---------------------------------------------------------------------------------
// Public meta selector
// ---------------------------------------------------------------------------------

export const buildTradeCaptureRequestCreator: MapStateToMetadataHOF<
  TradeCaptureOptionRequestWrapper,
  TradeCaptureToBackendMetadata,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> =
  (sl) =>
    // todo 4285 - the patch is based on FxOptionMultilegModelChanges which has some properties specific to the state input / values such  as strike : string
    //  whereas the TradeCaptureOptionRequestWrapper seem to have the values both sent and receive from TC (strike : number) - seems suprising that we have the same typing for both the request and the response
    //  how was it working before
    (state, { optionId, patch }) => {
      const idVersion = getNextIdVersion(sl)(state, optionId, patch.legs);

      const changedOptionFields = getChangedOptionFieldsWith(sl)(state, {
        optionId,
        patch,
      });

      const legs = getNewModelPatchedLegs(patch, sl, state, optionId);
      return {
        idVersion,
        changedFields: {
          ...changedOptionFields,
          legs,
        },
      };
    };

function getNewModelPatchedLegs(
  patch: OptionStoreModelChanges,
  sl: TradeCaptureToBackendMetaSelectorSelectors,
  state: AppState,
  optionId: string,
) {
  const optionLegs = mapOptionLegs(sl, state, patch.legs);

  const hedges = getEmptyHedges(sl)(state, optionId);

  const hedgesWithPatch = {
    ...hedges,
    ...mapOptionHedges(
      patch.hedges,
      (hedgeId) => sl.getOptionHedgeCurrency(state, hedgeId)?.value ?? FIRST_CURRENCY,
    ),
  };

  const legsWithHedges = {
    ...optionLegs,
    '0': {
      ...optionLegs['0'],
      legs: {
        ...optionLegs['0'].legs,
        ...hedgesWithPatch,
      },
    },
  };

  return patch.legs === undefined || isEmpty(Object.keys(patch.legs)) // object is empty
    ? {
      [sl.getOptionNextLegId(state, optionId)]: {
        productName: 'FxOptionMultileg' as const,
        legs: {
          '0': {
            productName: 'Vanilla' as const,
          },
        },
      },
    }
    : legsWithHedges;
}

function getNextIdVersion(sl: TradeCaptureToBackendMetaSelectorSelectors) {
  return (
    state: AppState,
    optionId: string,
    patchLegs: Record<string, FxOptionMultilegModelChanges>,
  ) => {
    const typedStrategyLegOrVanillaLegs = patchLegs['0']?.legs ?? {};
    const [firstLegId] = Object.keys(typedStrategyLegOrVanillaLegs);
    const { productName: firstLegChangedProductName } =
      typedStrategyLegOrVanillaLegs[firstLegId] ?? {};
    const firstStateLegId = sl.getFirstLegIdOfOption(state, optionId);
    const firstStateLegProductName = sl.getOptionLegProductName(state, firstStateLegId);

    return firstLegChangedProductName !== undefined &&
      firstLegChangedProductName !== firstStateLegProductName
      ? 0
      : incrementIdVersion(sl.getOptionTradeCaptureIdVersion(state, optionId));
  };
}

function mapOptionLegs(
  sl: TradeCaptureToBackendMetaSelectorSelectors,
  state: AppState,
  legs: Record<string, FxOptionMultilegModelChanges>,
): Record<string, Partial<TradeCaptureFxOptionMultileg>> {
  const vanillaOrTypedStrategyLegs = legs[0].legs;

  const mappedOptionLegs: Record<string, Partial<TradeCaptureFxOptionMultileg>> = {
    ...legs,
  } as Record<string, Partial<TradeCaptureFxOptionMultileg>>;

  const { dateInputCultureInfo: cultureInfo } = sl.getUserPreferenceData(state);
  mappedOptionLegs[0].legs = Object.entries(vanillaOrTypedStrategyLegs).reduce(
    (
      acc,
      [legId, leg]: [string, TypedStrategyOptionLegsModelChanges | VanillaOptionLegsModelChanges],
    ) => {
      if (isVanilla(leg)) {
        acc[legId] = {
          ...metaSelectorOptionLegToTradeCapturePatch(leg, cultureInfo),
        };
      } else {
        // is a typed strategy
        const { productName, legs: childrens, ...legPatch } = leg;

        const {
          legs: childrenLegs,
          legSide,
          legStrike,
          firstChildrenLeg,
        } = Object.entries(childrens).reduce(
          (childrenAcc, [childrenLegId, childrenLeg]) => {
            if (isVanilla(childrenLeg)) {
              if (!isDefined(childrenAcc.firstChildrenLeg)) {
                // Exclude data
                const {
                  side: _side,
                  strike: _strike,
                  premiumBid: _premiumBid,
                  premiumAsk: _premiumAsk,
                  markup: _markup,
                  volatilityBid: _volatilityBid,
                  volatilityAsk: _volatilityAsk,
                  ...rest
                } = childrenLeg;
                childrenAcc.firstChildrenLeg = rest;
              }

              if (!isDefined(childrenAcc.legSide)) {
                childrenAcc.legSide = childrenLeg.side;
              }

              if (!isDefined(childrenAcc.legStrike)) {
                childrenAcc.legStrike = childrenLeg.strike;
              }

              childrenAcc.legs[childrenLegId] = {
                ...metaSelectorOptionLegToTradeCapturePatch(
                  {
                    productName: 'Vanilla',
                    premiumBid: childrenLeg.premiumBid,
                    premiumAsk: childrenLeg.premiumAsk,
                    volatilityBid: childrenLeg.volatilityBid,
                    volatilityAsk: childrenLeg.volatilityAsk,
                    markup: childrenLeg.markup,
                    ...(leg.productName !== 'RiskReversal' ? {} : { side: childrenLeg.side }), // side needs to be at subleg level for RiskReversal
                    ...(leg.productName === 'Straddle' ? {} : { strike: childrenLeg.strike }), // strike needs to be at subleg level for all other typed strategies
                  },
                  cultureInfo,
                ),
              };
            }
            return childrenAcc;
          },
          { legs: {} } as {
            legs: Record<string, Partial<TradeCaptureVanillaOptionLeg>>;
            legStrike?: string | null;
            legSide?: Side;
            firstChildrenLeg: VanillaOptionLegsModelChanges;
          },
        );
        // @TODO is it really necessary ?  LegPatch is a strategy so don't have value except productname ?
        let legPatchInputs = metaSelectorOptionLegToTradeCapturePatch(legPatch, cultureInfo);
        if (productName === 'RiskReversal') {
          // @ts-expect-error is the rest of TypedStrategyOptionLegsModelChanges
          const { _strikeString, ...possibleLegPatchInputs } = legPatch;
          legPatchInputs = metaSelectorOptionLegToTradeCapturePatch(
            possibleLegPatchInputs,
            cultureInfo,
          );
        }

        acc[legId] = {
          ...metaSelectorOptionLegToTradeCapturePatch(
            {
              ...firstChildrenLeg,
              ...(leg.productName !== 'RiskReversal' ? { side: legSide } : {}),
              ...(leg.productName === 'Straddle' ? { strike: legStrike } : {}), // strike needs to be at leg level for straddles,
            },
            cultureInfo,
          ),
          ...legPatchInputs,
          productName: productName as LegType,
          // productName: leg.productName,
          legs: childrenLegs,
        };
      }
      return acc;
    },
    {} as Record<string, Partial<TCOptionLegValues>>,
  );

  return mappedOptionLegs;
}

function mapOptionHedges(
  hedges: OptionHedgesStoreModelChanges,
  getHedgeCurrency: (hedgeId: string) => CurrencyChoice,
): Record<string, Partial<TCOptionHedgeValues>> {
  return Object.entries(hedges).reduce((result, [hedgeId, hedge]) => {
    result[hedgeId] = {
      productName: 'FxOptionHedge',
      priceString: hedge.rate,
    };

    result[hedgeId][
      (hedge.currency ?? getHedgeCurrency(hedgeId)) === SECOND_CURRENCY
        ? 'amountInCcy2String'
        : 'amountInCcy1String'
    ] = hedge.amount;

    return result;
  }, {} as Record<string, Partial<TCOptionHedgeValues>>);
}

// ---------------------------------------------------------------------------------
// Private metaSelector
// ---------------------------------------------------------------------------------
function incrementIdVersion(lastIdVersion: number | null): number {
  return lastIdVersion === null ? 0 : lastIdVersion + 1;
}

const getChangedOptionFieldsWith: MapStateToMetadataHOF<
  Partial<TradeCaptureOption>,
  { patch: OptionStoreModelChanges } & WithOptionId,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> =
  (sl) =>
    (state, { patch }) => {
      const { dateInputCultureInfo: cultureInfo } = sl.getUserPreferenceData(state);

      return clearUndefined({
        currencyPair: patch.currencyPair,
        hedgeType: hedgeTypeToPatch(patch.hedgeType),
        markupCurrency: patch.markupCurrency,
        premiumPaymentDateString: dateToPatch(patch.premiumDate, patch.premiumDateTenor, cultureInfo),
        isInFine: patch.isInFine,
        amountReferenceString: patch.amountReference,
      });
    };

const metaSelectorOptionLegToTradeCapturePatch = (
  patch: Partial<IFxVanillaLegInputs>,
  cultureInfo: DateInputCultureInfo,
): Partial<TradeCaptureVanillaOptionLeg> =>
  clearUndefined({
    productName: 'Vanilla',
    type: optionTypeToPatch(patch.optionType),
    side: patch.side,
    expiryDateString: dateToPatch(patch.expiryDate, patch.expiryDateTenor, cultureInfo),
    strikeString: patch.strike === '?' ? '' : patch.strike,
    amount1String:
      patch.notionalCurrency === 1 && patch.notionalAmount !== null
        ? patch.notionalAmount
        : undefined,
    amount2String:
      patch.notionalCurrency === 2 && patch.notionalAmount !== null
        ? patch.notionalAmount
        : undefined,
    deliveryDateString: dateToPatch(patch.deliveryDate, undefined, cultureInfo),
    settlementType: settlementTypeToPatch(patch.settlementType),
    cashSettlementCurrency: patch.cashSettlementCurrency,
    fixingReference1: patch.fixingReference1,
    cutOffMarketPlace: patch.marketPlace,
    premiumPaymentDateString: dateToPatch(patch.premiumDate, patch.premiumDateTenor, cultureInfo),
    isInFine: patch.isInFine,
    premiumPaymentAmountBidString: patch.premiumBid,
    premiumPaymentAmountAskString: patch.premiumAsk,
    premiumType: mapToPremiumType(patch.premiumTypeString, patch.premiumCurrency),
    markupAmountString: patch.markup,
    clientVolBidString: patch.volatilityBid,
    clientVolAskString: patch.volatilityAsk,
    negotiatedCurrency: patch.notionalCurrency,
  });

const priceAndCcyToPremiumType: Record<PriceType, [ccy1: PremiumType, ccy2: PremiumType]> = {
  VOLATILITY: ['SmiledVolatility1', 'SmiledVolatility2'],
  PPS: ['Pip12', 'Pip21'],
  AMOUNT: ['AmountCurrency1', 'AmountCurrency2'],
  PERCENT: ['PercentageCurrency1', 'PercentageCurrency2'],
};

function mapToPremiumType(
  priceType: PriceType | null | undefined,
  amountCcy: CurrencyChoice | null | undefined,
): PremiumType | undefined {
  if (isNotDefined(priceType) || isNotDefined(amountCcy)) {
    return undefined;
  }

  return priceAndCcyToPremiumType[priceType][amountCcy - 1];
}

function hedgeTypeToPatch(value: HedgeType | undefined): TcHedgeType | undefined {
  if (value === 'Live') {
    return 'None';
  }
  return value;
}

function dateToPatch(
  date: string | undefined | null,
  tenor: string | undefined | null,
  cultureInfo: DateInputCultureInfo,
) {
  if (date === null) {
    return null;
  }
  if (date === '') {
    return '';
  }
  if (isDefined(date) && isNonEmpty(date)) {
    return parseWithCultureInfo(cultureInfo, date);
  }
  if (isDefined(tenor) && isNonEmpty(tenor)) {
    return tenor;
  }
  return undefined;
}

function getEmptyHedges(sl: TradeCaptureToBackendMetaSelectorSelectors) {
  return (state: AppState, optionId: string) => {
    const hedgesIds = sl.getAllHedgeIdsOfOption(state, optionId).map((id) => {
      const [_optionId, hedgeId] = id.split('/');

      return hedgeId;
    });

    return hedgesIds.reduce(
      (allHedges, hedgeId) => ({
        ...allHedges,
        [hedgeId]: {
          productName: 'FxOptionHedge',
        },
      }),
      {},
    );
  };
}

const optionTypeToPatch = mapOptional(convertLegOptionTypeToTradeCaptureType);

const settlementTypeToPatch = mapOptional(convertLegSettlementTypeToTradeCaptureSettlementType);
