import { tenPower } from 'utils/number';

/**
 * Update margin by 0.1 increment
 * @description should behave this way: 2.11 + 0.1 => 2.2, 2.11 - 0.1 => 2.1, 2.1 + 0.1 => 2.2, 2.1 - 0.1 => 2.0
 * @param margin original margin
 * @param increment margin increment
 * @see https://modernweb.com/what-every-javascript-developer-should-know-about-floating-points/ for why we multiply and divide by ten
 */
export function updateMargin(margin: number, increment: -0.1 | 0.1) {
  const tenNewMargin = margin * 10 + increment * 10;
  return (increment > 0 ? Math.floor(tenNewMargin) : Math.ceil(tenNewMargin)) / 10;
}

export const convertToRawWithPrecision =
  (precision: number) =>
  (points: number | null): number =>
    (points || 0) / tenPower(precision - 1);

export const convertToPointsWithPrecision =
  (precision: number) =>
  (rawValue: number): number =>
    rawValue * tenPower(precision - 1);
