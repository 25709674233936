import type {
  IFxBulkInputs,
  FxBulkLegInputs,
  IFxBulkLegValues,
  BulkLineProduct,
  IQuote,
  IFxBulkExecutionData,
} from './fxBulksModel';
import type { BulkTradeCapturePatch } from 'api/tradeCapture/bulk/tradeCaptureModel';
import type { TradeCaptureError } from 'state/tile/fxTileModel';
import type { IStreamError } from 'state/globalError/globalErrorModel';

export type FxBulkAction =
  | BulkCreated
  | BulkClosed
  | BulkPropertyChanged
  | BulkPropertiesRequested
  | BulkPropertiesReceived
  | BulkPropertiesRequestFailed
  | BulkSelectionChanged
  | BulkLegAdded
  | BulkLegReset
  | BulkLegsRemoved
  | BulkLegsExcluded
  | BulkLegPropertyChanged
  | BulkLegsPropertyChanged
  | BulkRfsRequestedEpic
  | BulkRfsStarted
  | BulkQuoteReceived
  | BulkRfsFailed
  | BulkRfsClearError
  | BulkRfsCancel
  | BulkRfsCancelEpic
  | BulkRfsCancelSent
  | BulkRfsCancelFailed
  | BulkRfsTerminated
  | BulkLegPropertiesRemoveError
  | BulkExecutionRequestedEpic
  | BulkExecutionSent
  | BulkExecutionFailed
  | BulkExecutionReceived
  | BulkFillReportReceived
  | BulkRestart
  | BulkReset
  | BulkResetAll
  | BulkMarginAction
  | BulkImported
  | BulkExecutionSentSucceeded;

export type BulkMarginAction = BulkIncrementMargin | BulkDecrementMargin | BulkSetMargin;

export interface BulkCreated {
  type: 'BULK_CREATED';
  bulkId: string;
}

export function bulkCreated(bulkId: string): BulkCreated {
  return {
    type: 'BULK_CREATED',
    bulkId,
  };
}

export interface BulkClosed {
  type: 'BULK_CLOSED';
  bulkId: string;
}

export function bulkClosed(bulkId: string): BulkClosed {
  return {
    type: 'BULK_CLOSED',
    bulkId,
  };
}

export interface BulkPropertyChanged {
  type: 'BULK_PROPERTY_CHANGED';
  bulkId: string;
  patch: Partial<IFxBulkInputs>;
  patchLegs?: { [legId: string]: Partial<FxBulkLegInputs> };
}

export function bulkPropertyChanged(
  bulkId: string,
  patch: Partial<IFxBulkInputs>,
  patchLegs?: { [legId: string]: Partial<FxBulkLegInputs> },
): BulkPropertyChanged {
  return {
    type: 'BULK_PROPERTY_CHANGED',
    bulkId,
    patch,
    patchLegs,
  };
}

export interface BulkImported {
  type: 'BULK_IMPORTED';
  bulkId: string;
  patch: Partial<IFxBulkInputs>;
  patchLegs: Record<string, Partial<FxBulkLegInputs>>;
}

export function bulkImported(
  bulkId: string,
  patch: Partial<IFxBulkInputs>,
  patchLegs: Record<string, Partial<FxBulkLegInputs>>,
): BulkImported {
  return {
    type: 'BULK_IMPORTED',
    bulkId,
    patch,
    patchLegs,
  };
}

export interface BulkPropertiesRequested {
  type: 'BULK_PROPERTIES_REQUESTED';
  bulkId: string;
  sessionId: string;
}

export function bulkPropertiesRequested(
  bulkId: string,
  sessionId: string,
): BulkPropertiesRequested {
  return {
    type: 'BULK_PROPERTIES_REQUESTED',
    bulkId,
    sessionId,
  };
}

export interface BulkPropertiesReceived {
  type: 'BULK_PROPERTIES_RECEIVED';
  bulkId: string;
  patch: BulkTradeCapturePatch;
  excludedLegsIds: readonly string[];
}

export function bulkPropertiesReceived(
  bulkId: string,
  patch: BulkTradeCapturePatch,
  excludedLegsIds: readonly string[],
): BulkPropertiesReceived {
  return {
    type: 'BULK_PROPERTIES_RECEIVED',
    bulkId,
    patch,
    excludedLegsIds,
  };
}

export interface BulkPropertiesRequestFailed {
  type: 'BULK_PROPERTIES_REQUEST_FAILED';
  bulkId: string;
  error: TradeCaptureError;
}

export function bulkPropertiesRequestFailed(
  bulkId: string,
  error: TradeCaptureError,
): BulkPropertiesRequestFailed {
  return {
    type: 'BULK_PROPERTIES_REQUEST_FAILED',
    bulkId,
    error,
  };
}

export interface BulkSelectionChanged {
  type: 'BULK_SELECTION_CHANGED';
  bulkId: string;
  legIds: readonly string[];
}

export function bulkSelectionChanged(
  bulkId: string,
  legIds: readonly string[],
): BulkSelectionChanged {
  return {
    type: 'BULK_SELECTION_CHANGED',
    bulkId,
    legIds,
  };
}

export interface BulkLegsExcluded {
  type: 'BULK_LEGS_EXCLUDED';
  bulkId: string;
  legIds: readonly string[];
  activatedLegsIds: readonly string[];
}

export function bulkLegsExcluded(
  bulkId: string,
  legIds: readonly string[],
  activatedLegsIds: readonly string[],
): BulkLegsExcluded {
  return {
    type: 'BULK_LEGS_EXCLUDED',
    bulkId,
    legIds,
    activatedLegsIds,
  };
}

export interface BulkLegAdded {
  type: 'BULK_LEG_ADDED';
  bulkId: string;
  product: BulkLineProduct;
}

export function bulkLegAdded(bulkId: string, product: BulkLineProduct): BulkLegAdded {
  return {
    type: 'BULK_LEG_ADDED',
    bulkId,
    product,
  };
}

export interface BulkLegReset {
  type: 'BULK_LEG_RESET';
  bulkId: string;
  legId: string;
}

export function bulkLegReset(bulkId: string, legId: string): BulkLegReset {
  return {
    type: 'BULK_LEG_RESET',
    bulkId,
    legId,
  };
}

export interface BulkLegsRemoved {
  type: 'BULK_LEGS_REMOVED';
  bulkId: string;
  legIds: readonly string[];
}

export function bulkLegsRemoved(bulkId: string, legIds: readonly string[]): BulkLegsRemoved {
  return {
    type: 'BULK_LEGS_REMOVED',
    bulkId,
    legIds,
  };
}

export interface BulkReset {
  type: 'BULK_RESET';
  bulkId: string;
}

export function bulkReset(bulkId: string): BulkReset {
  return {
    type: 'BULK_RESET',
    bulkId,
  };
}
export interface BulkResetAll {
  type: 'BULK_RESET_ALL';
  bulkId: string;
}

export function bulkResetAll(bulkId: string): BulkResetAll {
  return {
    type: 'BULK_RESET_ALL',
    bulkId,
  };
}

export interface BulkLegPropertyChanged {
  type: 'BULK_LEG_PROPERTY_CHANGED';
  bulkId: string;
  legId: string;
  patch: Partial<FxBulkLegInputs>;
}

export function bulkLegPropertyChanged(
  bulkId: string,
  legId: string,
  patch: Partial<FxBulkLegInputs>,
): BulkLegPropertyChanged {
  return {
    type: 'BULK_LEG_PROPERTY_CHANGED',
    bulkId,
    legId,
    patch,
  };
}

/* Change of a property stored at the leg level that impacts every leg, eg. ND */
export interface BulkLegsPropertyChanged {
  type: 'BULK_LEGS_PROPERTY_CHANGED';
  bulkId: string;
  patch: Partial<FxBulkLegInputs>;
}

export function bulkLegsPropertyChanged(
  bulkId: string,
  patch: Partial<FxBulkLegInputs>,
): BulkLegsPropertyChanged {
  return {
    type: 'BULK_LEGS_PROPERTY_CHANGED',
    bulkId,
    patch,
  };
}

export interface BulkLegPropertiesRemoveError {
  type: 'BULK_LEG_PROPERTIES_REMOVE_ERROR';
  bulkId: string;
  legId: string;
  keys: ReadonlyArray<keyof IFxBulkLegValues>;
}

export function bulkLegPropertiesRemoveError(
  bulkId: string,
  legId: string,
  keys: ReadonlyArray<keyof IFxBulkLegValues>,
): BulkLegPropertiesRemoveError {
  return {
    type: 'BULK_LEG_PROPERTIES_REMOVE_ERROR',
    bulkId,
    legId,
    keys,
  };
}

export interface BulkRfsRequestedEpic {
  type: 'BULK_RFS_REQUESTED_EPIC';
  bulkId: string;
}
export function bulkRfsRequestedEpic(bulkId: string): BulkRfsRequestedEpic {
  return {
    type: 'BULK_RFS_REQUESTED_EPIC',
    bulkId,
  };
}

export interface BulkQuoteReceived {
  type: 'BULK_QUOTE_RECEIVED';
  bulkId: string;
  streamId: string;
  quote: IQuote;
  tiering: string | null;
}

export function bulkQuoteReceived(
  bulkId: string,
  streamId: string,
  quote: IQuote,
  tiering: string | null,
): BulkQuoteReceived {
  return {
    type: 'BULK_QUOTE_RECEIVED',
    bulkId,
    streamId,
    quote,
    tiering,
  };
}

export interface BulkRfsStarted {
  type: 'BULK_RFS_STARTED';
  bulkId: string;
  streamId: string;
  rfsStartedAt: Date;
}

export function bulkRfsStarted(
  bulkId: string,
  streamId: string,
  rfsStartedAt: Date,
): BulkRfsStarted {
  return {
    type: 'BULK_RFS_STARTED',
    bulkId,
    streamId,
    rfsStartedAt,
  };
}

export interface BulkRfsCancelEpic {
  type: 'BULK_RFS_CANCEL_EPIC';
  bulkId: string;
  streamId: string;
}

export function bulkRfsCancelEpic(bulkId: string, streamId: string): BulkRfsCancelEpic {
  return {
    type: 'BULK_RFS_CANCEL_EPIC',
    bulkId,
    streamId,
  };
}

export interface BulkRfsCancel {
  type: 'BULK_RFS_CANCEL';
  bulkId: string;
  streamId: string;
}

export function bulkRfsCancel(bulkId: string, streamId: string): BulkRfsCancel {
  return {
    type: 'BULK_RFS_CANCEL',
    bulkId,
    streamId,
  };
}

export interface BulkRfsClearError {
  type: 'BULK_RFS_CLEAR_ERROR';
  bulkId: string;
}

export function bulkRfsClearError(bulkId: string): BulkRfsClearError {
  return {
    type: 'BULK_RFS_CLEAR_ERROR',
    bulkId,
  };
}

export interface BulkRfsTerminated {
  type: 'BULK_RFS_TERMINATED';
  bulkId: string;
  streamId: string;
}

export function bulkRfsTerminated(bulkId: string, streamId: string): BulkRfsTerminated {
  return {
    type: 'BULK_RFS_TERMINATED',
    bulkId,
    streamId,
  };
}

export interface BulkRfsFailed {
  type: 'BULK_RFS_FAILED';
  bulkId: string;
  streamId: string;
  error: IStreamError;
}

export function bulkRfsFailed(
  bulkId: string,
  streamId: string,
  error: IStreamError,
): BulkRfsFailed {
  return {
    type: 'BULK_RFS_FAILED',
    bulkId,
    streamId,
    error,
  };
}

export interface BulkRfsCancelSent {
  type: 'BULK_RFS_CANCEL_SENT';
  bulkId: string;
  streamId: string;
}

export function bulkRfsCancelSent(bulkId: string, streamId: string): BulkRfsCancelSent {
  return {
    type: 'BULK_RFS_CANCEL_SENT',
    bulkId,
    streamId,
  };
}

export interface BulkRfsCancelFailed {
  type: 'BULK_RFS_CANCEL_FAILED';
  bulkId: string;
  streamId: string;
}

export function bulkRfsCancelFailed(bulkId: string, streamId: string): BulkRfsCancelFailed {
  return {
    type: 'BULK_RFS_CANCEL_FAILED',
    bulkId,
    streamId,
  };
}

export interface BulkExecutionRequestedEpic {
  type: 'BULK_EXECUTION_REQUESTED_EPIC';
  bulkId: string;
  streamId: string;
}
export function bulkExecutionRequestedEpic(
  bulkId: string,
  streamId: string,
): BulkExecutionRequestedEpic {
  return {
    type: 'BULK_EXECUTION_REQUESTED_EPIC',
    bulkId,
    streamId,
  };
}

export interface BulkExecutionSent {
  type: 'BULK_EXECUTION_SENT';
  bulkId: string;
  streamId: string;
  executionId: string;
  execution: IFxBulkExecutionData;
}
export function bulkExecutionSent(
  bulkId: string,
  streamId: string,
  executionId: string,
  execution: IFxBulkExecutionData,
): BulkExecutionSent {
  return {
    type: 'BULK_EXECUTION_SENT',
    bulkId,
    streamId,
    executionId,
    execution,
  };
}

export interface BulkExecutionSentSucceeded {
  type: 'BULK_EXECUTION_SENT_SUCCEEDED';
  streamId: string;
}
export function bulkExecutionSentSucceeded(streamId: string): BulkExecutionSentSucceeded {
  return {
    type: 'BULK_EXECUTION_SENT_SUCCEEDED',
    streamId,
  };
}

export interface BulkExecutionFailed {
  type: 'BULK_EXECUTION_FAILED';
  executionId: string;
  streamError: IStreamError;
}
export function bulkExecutionFailed(
  executionId: string,
  streamError: IStreamError,
): BulkExecutionFailed {
  return {
    type: 'BULK_EXECUTION_FAILED',
    executionId,
    streamError,
  };
}

export interface BulkExecutionReceived {
  type: 'BULK_EXECUTION_RECEIVED';
  executionId: string;
}
export function bulkExecutionReceived(executionId: string): BulkExecutionReceived {
  return {
    type: 'BULK_EXECUTION_RECEIVED',
    executionId,
  };
}

export interface BulkFillReportReceived {
  type: 'BULK_FILL_REPORT_RECEIVED';
  executionId: string;
  tradeIds: readonly string[];
}

export function bulkFillReportReceived(
  executionId: string,
  tradeIds: readonly string[],
): BulkFillReportReceived {
  return {
    type: 'BULK_FILL_REPORT_RECEIVED',
    executionId,
    tradeIds,
  };
}

export interface BulkIncrementMargin {
  type: 'BULK_INCREMENT_MARGIN';
  bulkId: string;
  date: string;
}

export function bulkIncrementMargin(bulkId: string, date: string): BulkIncrementMargin {
  return {
    type: 'BULK_INCREMENT_MARGIN',
    bulkId,
    date,
  };
}

export interface BulkDecrementMargin {
  type: 'BULK_DECREMENT_MARGIN';
  bulkId: string;
  date: string;
}

export function bulkDecrementMargin(bulkId: string, date: string): BulkDecrementMargin {
  return {
    type: 'BULK_DECREMENT_MARGIN',
    bulkId,
    date,
  };
}

export interface BulkSetMargin {
  type: 'BULK_SET_MARGIN';
  bulkId: string;
  date: string;
  value: number;
}

export function bulkSetMargin(bulkId: string, date: string, value: number): BulkSetMargin {
  return {
    type: 'BULK_SET_MARGIN',
    bulkId,
    date,
    value,
  };
}

export interface BulkRestart {
  type: 'BULK_RESTART';
  bulkId: string;
}

export function bulkRestart(bulkId: string): BulkRestart {
  return {
    type: 'BULK_RESTART',
    bulkId,
  };
}
