import type {
  BlotterInstrument,
  BlotterOrderInstrument,
  BlotterOrderStatus,
  BlotterTradeInstrument,
  BlotterTradeOptionType,
  BlotterTradeStatus,
} from 'state/blotter/blotterEntryModel';
import type { ESPHeartbeat, ESPPrice } from 'state/esp/espStreamsModel';
import type { ClippingMode, FixingMarginType, FixingPriceType, Speed } from 'state/fxOrders/fxOrdersModel';
import type { ConnectableObservable, Observable } from 'rxjs';
import type { ISessionStreamEvent } from 'Workers/streamingTypes';
import type { Side } from 'state/share/productModel/litterals';

export type BlotterNotificationType = 'BLOTTER.TRADE' | 'BLOTTER.ORDER';

export interface IStreamingData<T = any> {
  type: 'DATA';
  data: T;
}

export interface IStreamingError<T = any> {
  type: 'ERROR';
  error: T;
}

export declare type StreamingEvent<D = any, E = any> = IStreamingData<D> | IStreamingError<E>;

export function isStreamingData<T>(event: StreamingEvent<T>): event is IStreamingData<T> {
  return event.type === 'DATA';
}

interface BlotterNotificationMetaData {
  blotterType: BlotterInstrument;
}

export interface IBlotterTradeNotificationData extends BlotterNotificationMetaData {
  type: 'BLOTTER.TRADE';
  blotterType: BlotterTradeInstrument;
  id: string;
  status: BlotterTradeStatus | null;
  product: string | null;
  currencyPair: string | null;
  way: Side | null;
  dealtAmount: number | null;
  dealtCurrency: string | null;
  farDealtAmount: number | null;
  contraAmount: number | null;
  contraCurrency: string | null;
  farContraAmount: number | null;
  date: string | null;
  spot: number | null;
  points: number | null;
  venue: string | null;
  account: string | null;
  accountLongName: string | null;
  salesFullName: string | null;
  strike: number | null;
  expiry: string | null;
  premium: number | null;
  premiumCurrency: string | null;
  premiumSide: 'Pay' | 'Receive' | null;
  strategyReference: string | null;
  electronicReference: string | null;
  electronicAccount: string | null;
  farDate: string | null;
  nearDate: string | null;
  nearRate: number | null;
  farRate: number | null;
  spotDate: string | null;
  portfolio: string | null;
  updateTime: string;
  optionType: BlotterTradeOptionType | null;
  deliveryDate: string | null;
  premiumPaymentDate: string | null;
  deliveryType: string | null;
  cutOffPlace: string | null;
  counterpartyBdrId: number | null;
}

export interface IBlotterOrderNotificationData extends BlotterNotificationMetaData {
  type: 'BLOTTER.ORDER';
  blotterType: BlotterOrderInstrument;
  status: BlotterOrderStatus | null;
  id: string;
  goodTillCancelled: boolean | null;
  expiryDay: string | null;
  expiryTime: string | null;
  orderType: string;
  currencyPair: string;
  yourWay: Side | null;
  notional: number | null;
  executionPrice: number | null;
  notionalCurrency: string | null;
  executedAmount: number | null;
  customerPrice: number | null;
  account: string;
  updateTime: string;
  limitPrice: number | null;
  rejectReason: string | null;
  startTime?: string | null;
  endTime?: string | null;
  maturityDate?: string | null;
  liquidityPool?: string | null;
  clippingMode?: ClippingMode | null;
  clipSize?: number | null;
  randomize?: boolean | null;
  swapPoints?: number | null;
  forwardPrice?: number | null;
  remainingAmount?: number | null;
  averagePrice?: number | null;
  noWorseThan?: number | null;
  spreadCapture?: boolean | null;
  alphaSeeker?: boolean | null;
  speed?: Speed | null;
  // TODO ABO, SGEFX-5010: check with Edouard if this shouold be in the blotter order list response (in UAT we don't get it)
  fixingTypes?: string[] | null;
  fixingBenchmark?: string | null;
  fixingPlace?: string | null;
  fixingTime?: string | null;
  fixingDateUtc?: string | null;
  fixingPriceType?: FixingPriceType | null;
  fixingMarginType?: FixingMarginType | null;
  marginInBps?: number | null;
  margin?: number | null;
  price?: number | null;
}

export type IBlotterNotificationData = IBlotterTradeNotificationData | IBlotterOrderNotificationData;
export interface IBlotterNotificationStreamData extends IStreamingData {
  data: IBlotterNotificationData;
}

export interface IDisconnectionNotificationData extends IStreamingData {
  data: { type: 'DISCONNECTION.NOTIFICATION' };
  reason: 'connected_twice' | 'force_logoff_time';
}

/** @todo Should be `{ RequestId: string } & something` instead of `any` */
export interface IRequestForStreamData extends IStreamingData<any> {}

export interface IEspStreamData extends IStreamingData {
  data: ESPPrice | ESPHeartbeat;
}

export interface Streams {
  session$: ConnectableObservable<ISessionStreamEvent>;
  dataStream$: Observable<IStreamingData>;
  disconnectionNotification$: Observable<IDisconnectionNotificationData>;
  esp$: Observable<IEspStreamData>;
  rfs$: Observable<IRequestForStreamData>;
  blotterNotification$: Observable<IBlotterNotificationStreamData>;
  heartbeatMissed$: Observable<string>;
}
