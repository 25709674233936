import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import type {
  CashTradeCapturePatch,
  TradeCaptureCashRequestWrapper,
  TradeCaptureCashResponseWrapper,
} from './tradeCaptureModel';
import { mapTCCashResponseToCashPropertiesPatch } from './tradeCaptureMapping';
import { map, mergeAll } from 'rxjs/operators';

export interface TradeCaptureApiRequest {
  sessionId: string;
  tileId: string;
  request: TradeCaptureCashRequestWrapper;
}

export interface ITradeCaptureCashApi {
  timeout: (nbOfRequest: number) => number;
  createNewVersion(
    requests: readonly TradeCaptureApiRequest[],
  ): Observable<TradeCaptureBatchedPatch>;
}

export interface TradeCaptureBatchedResponses {
  [cashId: string]: TradeCaptureCashResponseWrapper;
}

export interface TradeCaptureBatchedPatch {
  cashId: string;
  patch: CashTradeCapturePatch | null;
}

export function tradeCaptureApi(http: ISgmeHttp): ITradeCaptureCashApi {
  return {
    createNewVersion(queries) {
      return http.post<TradeCaptureBatchedResponses>(`api/tradeCaptureBatch/FxSpot`, queries).pipe(
        map(httpCall => {
          const data = httpCall.payload;
          const patches = queries
            .filter(a => data[a.tileId] !== undefined)
            .map(query => {
              const response = data[query.tileId];

              const res: TradeCaptureBatchedPatch = {
                cashId: query.tileId,
                patch: response !== null ? mapTCCashResponseToCashPropertiesPatch(response) : null,
              };
              return res;
            });
          return patches;
        }),
        mergeAll(),
      );
    },
    timeout: (numberOfRequests: number) => {
      /* Increase timeout when sending a large number of queries to TC */
      const defaultTimeout = 10000;
      if (numberOfRequests === 1) {
        return defaultTimeout;
      }
      if (numberOfRequests <= 5) {
        return defaultTimeout * 2;
      }
      if (numberOfRequests <= 30) {
        return defaultTimeout * 3;
      }
      return defaultTimeout * 6;
    },
  };
}
