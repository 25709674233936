import { isEmpty } from '@sgme/fp';
import type { Thunk } from 'state';

export function bulkLegSelected(quoteId: string, legId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const legIds = sl.getBulkLegs(state, quoteId);

    const previouslySelectedLegs = sl
      .getBulkSelectedLegs(state, quoteId)
      .filter(leg => legIds.includes(leg));

    let selectedLegs: readonly string[];
    if (previouslySelectedLegs.includes(legId)) {
      selectedLegs = previouslySelectedLegs.filter(leg => leg !== legId);
    } else {
      selectedLegs = [...previouslySelectedLegs, legId];
    }

    dispatch(ac.bulkSelectionChanged(quoteId, selectedLegs));
  };
}

export function bulkAllSelected(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const legIds = sl.getBulkLegs(state, quoteId);

    const previouslySelectedLegs = sl
      .getBulkSelectedLegs(state, quoteId)
      .filter(leg => legIds.includes(leg));

    let selectedLegs: readonly string[];
    if (previouslySelectedLegs.length < legIds.length) {
      selectedLegs = legIds;
    } else {
      selectedLegs = [];
    }

    dispatch(ac.bulkSelectionChanged(quoteId, selectedLegs));
  };
}

export function bulkAllUnselected(quoteId: string): Thunk<void> {
  return (dispatch, _, { actionCreators: ac }) => {
    dispatch(ac.bulkSelectionChanged(quoteId, []));
  };
}

export function bulkRemoveSelectedLegs(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const selectedLegs = sl.getBulkSelectedLegs(getState(), quoteId);
    const legs = sl.getBulkLegs(getState(), quoteId);
    if (!isEmpty(selectedLegs)) {
      // eslint-disable-next-line no-unused-expressions
      legs.length === selectedLegs.length
        ? dispatch(ac.bulkReset(quoteId))
        : dispatch(ac.bulkLegsRemoved(quoteId, selectedLegs));
    }
  };
}

export function bulkActivateSelectedLegs(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const previouslyExcludedLegs = sl.getBulkExcludedLegs(getState(), quoteId);
    const selectedLegs = sl.getBulkSelectedLegs(getState(), quoteId);

    const excludedLegs = previouslyExcludedLegs.filter(legId => !selectedLegs.includes(legId));
    if (!isEmpty(selectedLegs)) {
      dispatch(
        ac.bulkLegsExcluded(
          quoteId,
          excludedLegs,
          previouslyExcludedLegs.filter(legId => !excludedLegs.includes(legId)),
        ),
      );
      dispatch(ac.bulkAllUnselected(quoteId));
    }
  };
}

export function bulkExcludeSelectedLegs(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const previouslyExcludedLegs = sl.getBulkExcludedLegs(getState(), quoteId);
    const selectedLegs = sl
      .getBulkSelectedLegs(getState(), quoteId)
      .filter(legId => !previouslyExcludedLegs.includes(legId));

    const excludedLegs = [...previouslyExcludedLegs, ...selectedLegs];
    if (!isEmpty(selectedLegs)) {
      dispatch(ac.bulkLegsExcluded(quoteId, excludedLegs, []));
      dispatch(ac.bulkAllUnselected(quoteId));
    }
  };
}
