import { isDefined, isNotDefined } from '@sgme/fp';
import type { PropertyError, PropertyWarning } from 'state/share/productModel/litterals';

export interface FieldSelector<V, I> {
  value: V;
  input?: I;
  error?: PropertyError<V>;
  warning?: PropertyWarning<V>;
}

// TODO: remove this complex function because the only returned property used is "data"
export function fieldData<V, I>({
  input,
  value,
  error,
  warning,
}: FieldSelector<V, I>): FieldData<V, I> {
  const errorId = error === undefined ? undefined : error.code.toString();
  const userNotifiedError = error === undefined ? undefined : error.userNotified;
  const warningId = warning === undefined ? undefined : warning.code.toString();
  const userNotifiedWarning = warning === undefined ? undefined : warning.userNotified;

  const shared = { errorId, userNotifiedError, warningId, userNotifiedWarning };

  return input !== undefined
    ? { isInput: true, data: input, ...shared }
    : { isInput: false, data: value, ...shared };
}

export function getFieldContent<V, I>(field: FieldSelector<V, I> | undefined) {
  if (isNotDefined(field)) {
    return undefined;
  }

  const { input, value } = field;

  return isDefined(input) ? input : isDefined(value) ? value : null;
}

export function fieldUndefinableData<V, I>(
  field: FieldSelector<V, I> | undefined,
): FieldData<V, I> | undefined {
  if (isNotDefined(field)) {
    return undefined;
  }

  const { input, value, error, warning } = field;

  const errorId = error === undefined ? undefined : error.code.toString();
  const userNotifiedError = error === undefined ? undefined : error.userNotified;
  const warningId = warning === undefined ? undefined : warning.code.toString();
  const userNotifiedWarning = warning === undefined ? undefined : warning.userNotified;

  const shared = { errorId, userNotifiedError, warningId, userNotifiedWarning };

  return input !== undefined
    ? { isInput: true, data: input, ...shared }
    : { isInput: false, data: value, ...shared };
}

interface IsInputData<I> {
  isInput: true;
  data: I;
}

interface IsValueData<V> {
  isInput: false;
  data: V;
}

export type FieldData<V, I> = {
  errorId?: string;
  userNotifiedError?: boolean;
  warningId?: string;
  userNotifiedWarning?: boolean;
} & (IsValueData<V> | IsInputData<I>);

export const mapValueData =
  <V, I>(mapper: (value: V) => I) =>
  (shared: FieldData<V, I>): FieldData<I, I> =>
    shared.isInput === true ? shared : { ...shared, data: mapper(shared.data) };
