import type { OptionType } from 'state/fxOptions/fxOptionsModel';
import type { TradeCaptureOptionLegCallPutType } from 'api/tradeCapture/option/tradeCaptureOptionModel';

const mapLegOptionTypeToTradeCaptureType: Record<OptionType, TradeCaptureOptionLegCallPutType> = {
  Call: 'Call',
  Put: 'Put',
};

export function convertLegOptionTypeToTradeCaptureType(
  type: OptionType,
): TradeCaptureOptionLegCallPutType {
  return mapLegOptionTypeToTradeCaptureType[type];
}

const mapTradeCaptureTypeToLegOptionType: Record<TradeCaptureOptionLegCallPutType, OptionType> = {
  Call: 'Call',
  Put: 'Put',
};

export function convertTradeCaptureTypeToLegOptionType(
  type: TradeCaptureOptionLegCallPutType,
): OptionType {
  return mapTradeCaptureTypeToLegOptionType[type];
}
