import type { Updater } from '../typings';
import type {
  OptionSavedTile as OptionSavedTile5,
  SavedWorkspace as SavedWorkspace5,
} from '../updater5/model';
import type {
  OptionSavedTile as OptionSavedTile6,
  SavedWorkspace as SavedWorkspace6,
} from './model';

const currentVersion = 6;

export const updater6: Updater<SavedWorkspace5, SavedWorkspace6> = {
  version: currentVersion,
  canUpdate(data: any) {
    return data.version === currentVersion - 1;
  },
  update: (previousWorkspace: SavedWorkspace5): SavedWorkspace6 => {
    const savedWorkspace: SavedWorkspace6 = {
      ...previousWorkspace,
      version: currentVersion,
      tabs: updateOptionTiles(previousWorkspace.tabs),
    };

    return savedWorkspace;
  },
};

function updateOptionTiles(tabs: SavedWorkspace5['tabs']): SavedWorkspace6['tabs'] {
  return tabs.map(tab => ({
    ...tab,
    tiles: tab.tiles.map(tile =>
      tile.instrument === 'Option'
        ? {
            ...tile,
            legs: updateOptionTileLegs(tile.legs),
          }
        : tile,
    ),
  }));
}

function updateOptionTileLegs(legs: OptionSavedTile5['legs'] = []): OptionSavedTile6['legs'] {
  const firstLegProduct = legs[0]?.productName ?? 'Vanilla';
  // First leg is Vanilla or we don't have 2 legs, we transform the legs to Vanilla
  if (firstLegProduct === 'Vanilla' || legs.length !== 2) {
    return legs.map(leg => ({ ...leg, productName: 'Vanilla' }));
  }

  // Otherwise, we transform the typed strategy to the new format with sublegs
  return [
    {
      productName: firstLegProduct,
      subLegs: {
        call: {
          ...(legs.find(leg => leg.putOrCall === 'Call') ?? legs[0]),
          productName: 'Vanilla',
        },
        put: {
          ...(legs.find(leg => leg.putOrCall === 'Put') ?? legs[1]),
          productName: 'Vanilla',
        },
      },
    },
  ];
}
