import type { Reducer } from 'redux';
import {
  type CashProductName,
  emptyFxCashState,
  type FxCashState,
  type FxCashStateMap,
  type IFxCashInputs,
  type IFxCashStateMap,
} from '../fxCashsModel';
import type { Action } from 'state/actions';
import { addKey, removeKey, removeKeys, updateKey, updateProperty } from 'utils/stateMap';
import { addPriceRecorded } from 'state/share/priceRecordReducerHelper';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import type { InstrumentChoice } from 'state/referenceData/referenceDataModel';
import type { TradeCaptureSessionInfos } from 'state/sharedSelectors';
import type { EspPriceReceived } from 'state/esp/espStreamsActions';
import type { IESPTraderPriceUnderThreshold } from 'state/esp/espStreamsModel';
import { getEspPriceByNotional } from 'state/esp/utils';
import { nullify, strictEntries } from 'utils/object';
import { productPatcher } from 'state/share/patchHelper';
import { validationPartialReducer } from 'state/share/validationReducer';
import { isDefined, isEmpty } from '@sgme/fp';

const nullifyNDFields = nullify(
  'fixingCurrency',
  'fixingDate',
  'fixingSource',
  'sndFixingSource',
  'xCurrency',
  'possibleFixingCurrencies',
  'possibleFixingSources',
  'possibleSndFixingSources',
  'possibleXCurrencies',
);

const cashValidationReducer = validationPartialReducer<'Cash', FxCashState>('Cash');

export const fxCashsProductReducer: Reducer<FxCashStateMap> = (
  state: FxCashStateMap = {},
  action: Action,
): FxCashStateMap => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      if (action.savedTile.instrument !== 'Cash') {
        return state;
      }
      return addCashForTileIfNeeded(
        state,
        action.tileId,
        action.savedTile.instrument,
        action.savedTile.productName,
        action.savedTile.amountCurrency,
      );
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
      return addCashForTileIfNeeded(state, action.tileId, action.instrument);
    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      return addCashForTileIfNeeded(state, action.tileId, action.instrument, action.productName as CashProductName);
    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
      if (action.instrument !== 'Cash' && state[action.tileId] !== undefined) {
        return removeKey(state, action.tileId);
      }
      return addCashForTileIfNeeded(
        state,
        action.tileId,
        action.instrument,
        undefined,
        undefined,
        action.tradeCaptureSessionInfos,
      );
    case 'CLIENTWORKSPACE_TILE_DELETED':
      return removeKey(state, action.tileId);
    case 'CASH_RFS_STARTED':
      return updateKey(state, action.cashId, () => ({
        currentStreamId: action.streamId,
        lastStreamError: null,
        rfsStartedAt: action.rfsStartedAt,
        priceRecords: [],
      }));
    case 'CASH_RFS_CANCEL':
      return updateKey(state, action.cashId, () => ({
        currentStreamId: null,
      }));
    case 'CASH_RFS_FAILED':
      return updateKey(state, action.cashId, () => ({
        currentStreamId: null,
        lastStreamError: action.error,
      }));
    case 'CASH_RFS_CLEAR_ERROR':
      return updateKey(state, action.quoteId, () => {
        const patch: Partial<FxCashState> = {
          lastStreamError: null,
        };
        return patch;
      });
    case 'ESP_PRICE_RECEIVED':
      return updateCashMarginOnEspPrice(action)(state);
    case 'CASH_QUOTE_RECEIVED':
      const { quote } = action;
      return updateKey(state, action.cashId, (tile) => ({
        askMargin: tile.askMargin === null ? quote.defaultSpotMargin.ask : tile.askMargin,
        bidMargin: tile.bidMargin === null ? quote.defaultSpotMargin.bid : tile.bidMargin,
        askForwardMargin: tile.askForwardMargin === null ? quote.defaultForwardMarginPoints.ask : tile.askForwardMargin,
        bidForwardMargin: tile.askForwardMargin === null ? quote.defaultForwardMarginPoints.bid : tile.bidForwardMargin,
      }));
    case 'CASH_RFS_EXECUTION_SENT':
      return updateKey(state, action.cashId, () => ({
        lastExecutedQuoteId: action.quoteId,
        currentExecutionId: action.executionId,
        currentStreamId: null,
        askMargin: null,
        bidMargin: null,
        askForwardMargin: null,
        bidForwardMargin: null,
        /**
         * @nb remove warning but not errors ?
         */
        warnings: {},
      }));
    case 'CASH_ESP_EXECUTION_SENT':
      return updateKey(state, action.cashId, () => ({
        lastExecutedQuoteId: action.quoteId,
        currentExecutionId: action.executionId,
      }));
    case 'TILE_EXECUTION_OVERLAY_HIDDEN':
      return updateKey(state, action.quoteId, () => ({
        currentExecutionId: null,
      }));
    case 'CASH_PROPERTIES_REQUEST_FAILED':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: false,
        propertiesRequestError: action.error,
      }));
    case 'CASH_TILE_RESET':
      return updateKey(state, action.quoteId, () => emptyFxCashState);
    case 'ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamId,
        isEspDefaultTiering: action.isDefaultTiering,
      }));
    case 'ESP_STREAM_TILE_UNSUBSCRIBE':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: null,
      }));
    case 'ESP_STREAM_RECONNECTED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamKey,
        isEspDefaultTiering: action.isDefaultTIering,
      }));
    case 'ESP_STREAM_KEY_REQUEST_PENDING':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: null,
      }));
    case 'CASH_LOCAL_PROPERTY_CHANGED':
      return updateKey(state, action.tileId, () => ({ ...action.patch }));
    case 'CASH_PROPERTIES_CHANGED':
      return updateKey(
        state,
        action.cashId,
        () => (action.patch.currencyPair === undefined ? null : { lastStreamError: null }),
        updateProperty('inputs')<Readonly<Partial<IFxCashInputs>>>(() => action.patch),
      );

    case 'CASH_PROPERTIES_RECEIVED':
      return productPatcher(
        state,
        action,
        ({ dirtyFields }) => {
          const patchedFields = Object.keys(action.patch.values);
          return {
            dirtyFields: dirtyFields.filter((field) => !patchedFields.includes(field)),
          };
        },
        ({ values }) => ({
          values:
            values.isNonDeliverable === false || action.patch.values.isNonDeliverable === false
              ? nullifyNDFields(values)
              : values,
        }),
      );
    case 'CASH_PROPERTIES_REQUESTED':
      return updateKey(state, action.cashId, () => ({
        propertiesRequested: true,
        currentSessionId: action.sessionId,
      }));
    case 'RECORD_PRICE_SUCCEEDED':
    case 'RECORD_PRICE_FAILED':
      if (action.instrument !== 'Cash') {
        return state;
      }
      return updateKey(
        state,
        action.tileId,
        addPriceRecorded({
          priceRecord: {
            error: action.error,
            prices: action.error === true ? undefined : action.prices,
            timestamp: action.timestamp,
          },
        }),
      );
    case 'CASH_RFS_TERMINATED':
      return updateKey(state, action.cashId, (cash) =>
        cash.currentStreamId !== action.streamId
          ? null
          : {
              askMargin: null,
              bidMargin: null,
              askForwardMargin: null,
              bidForwardMargin: null,
              currentStreamId: null,
              lastStreamError: null,
            },
      );
    case 'CASH_AMOUNT_CURRENCY_MASK_CHANGED':
      return updateKey(
        state,
        action.cashId,
        updateProperty('values')(() => ({
          amountCurrency: action.amountCurrency,
        })),
      );
    case 'FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Cash'
        ? state
        : updateKey(state, action.quoteId, ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));

    case 'CASH_PROPERTIES_REMOVE_ERROR':
      return updateKey(state, action.cashId, ({ errors }) => ({
        errors: removeKeys(errors, action.keys),
      }));
    case 'CASH_PROPERTIES_DIRTY':
      return updateKey(state, action.cashId, ({ dirtyFields }) => ({
        dirtyFields: [...dirtyFields, action.fieldName],
      }));
    case 'CASH_DEFAULT_FIXING_SOURCE_OVERRIDDEN':
      if (state[action.cashId]) {
        return updateKey(
          state,
          action.cashId,
          updateProperty('values')(() => ({
            fixingSourceOverriddenDefault: action.defaultFixingSource,
            sndFixingSourceOverriddenDefault: action.defaultSndFixingSource,
          })),
        );
      } else {
        return state;
      }
    case 'GET_FIXING_REFERENCES_REQUESTED':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: true,
      }));
    case 'GET_FIXING_REFERENCES_DONE':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: false,
      }));
    case 'CASH_PROPERTIES_PRISTINE':
      return updateKey(state, action.cashId, ({ dirtyFields }) => ({
        dirtyFields: dirtyFields.filter((field) => field !== action.fieldName),
      }));

    default:
      return cashValidationReducer(state, action);
  }
};

function addCashForTileIfNeeded(
  state: FxCashStateMap,
  tileId: string,
  instrument: InstrumentChoice | 'Order' | 'BlotterOrder',
  productName: CashProductName = 'FxSpot',
  amountCurrency: CurrencyChoice = emptyFxCashState.values.amountCurrency,
  tradeCaptureSessionInfos?: TradeCaptureSessionInfos,
): FxCashStateMap {
  if (instrument !== 'Cash') {
    return state;
  }

  return addKey(state, tileId, {
    ...emptyFxCashState,
    ...tradeCaptureSessionInfos,
    values: { ...emptyFxCashState.values, productName, amountCurrency },
  });
}

export const updateCashMarginOnEspPrice =
  ({ streamId, ...espStreamState }: EspPriceReceived) =>
  (state: FxCashStateMap): FxCashStateMap => {
    if (espStreamState.priceType === 'ESP.CLIENT.PRICE') {
      return state;
    }
    const newCashsState: IFxCashStateMap = {};

    strictEntries(state).forEach(([tileId, cash]) => {
      if (cash.currentEspStreamId === streamId) {
        const {
          values: { amountCurrency, amount: notional },
        } = cash;
        const price = getEspPriceByNotional(espStreamState, notional, amountCurrency)
          .price as IESPTraderPriceUnderThreshold;

        if (!isDefined(price)) {
          return state;
        }
        const isMarginsSet = cash.askMargin !== null || cash.bidMargin !== null;
        if (price.valid && !isMarginsSet) {
          // First valid price received => set margin
          newCashsState[tileId] = {
            ...cash,
            askMargin: price.marginAsk,
            bidMargin: price.marginBid,
          };
        } else if (!price.valid && isMarginsSet) {
          newCashsState[tileId] = {
            ...cash,
            askMargin: null,
            bidMargin: null,
          };
        }
      }
    });

    if (isEmpty(Object.keys(newCashsState))) {
      return state;
    }

    return { ...state, ...newCashsState };
  };
