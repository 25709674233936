import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

export interface CloseTileConnectOwnProps {
  quoteId: string;
}

export interface CloseTileConnectDispatchProps {
  closeTile(): void;
}

export type CloseTileConnectActionCreatorsKeys = 'tileClosedThunk';
export type CloseTileConnectActionCreators = Pick<
  ActionCreators,
  CloseTileConnectActionCreatorsKeys
>;

export const mapDispatchToPropsCloseTile: MapDispatchToPropsHOF<
  CloseTileConnectDispatchProps,
  CloseTileConnectOwnProps,
  CloseTileConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    closeTile: () => {
      dispatch(ac.tileClosedThunk(quoteId));
    },
  });
