import type { AppState } from 'state/model';
import {
  getValueProperty,
  type ValueKey,
  type SharedKey,
  getSharedProperty,
  type SharedPropertyStatus,
} from 'state/share/form';
import type {
  IFxBulkMetadata,
  IFxBulkValues as BulkValue,
  IFxBulkInputs as BulkInput,
  IFxBulkLegValues as BulkLegValue,
  FxBulkLegInputs as BulkLegInput,
} from '../fxBulksModel';
import { getBulkState } from './bulk';
import { getBulkLegState } from './bulkLeg';

export type BulkMetadataStatus<T extends keyof IFxBulkMetadata> = IFxBulkMetadata[T];

export function getBulkMetadata<T extends keyof IFxBulkMetadata>(property: T) {
  return (state: AppState, bulkId: string): BulkMetadataStatus<T> =>
    getBulkState(state, bulkId)[property];
}
type BulkValuePropertyKey = ValueKey<BulkValue, BulkInput>;

export function getBulkValue<T extends BulkValuePropertyKey>(prop: T) {
  const get = getValueProperty<BulkValue, BulkInput, T>(prop);
  return (state: AppState, bulkId: string) => get(getBulkState(state, bulkId));
}

type BulkSharedPropertyKey = SharedKey<BulkValue, BulkInput>;

export function getBulkInput<T extends BulkSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<BulkValue, BulkInput, T>(prop);
  return (state: AppState, bulkId: string) => get(getBulkState(state, bulkId));
}

export type BulkSharedPropertyStatus<T extends BulkSharedPropertyKey> = SharedPropertyStatus<
  BulkValue[T],
  BulkInput[T]
>;

export function getBulkLegValue<T extends ValueKey<BulkLegValue, BulkLegInput>>(prop: T) {
  const get = getValueProperty<BulkLegValue, BulkLegInput, T>(prop);
  return (state: AppState, bulkId: string, legId: string) =>
    get(getBulkLegState(state, bulkId, legId));
}

type BulkLegSharedPropertyKey = SharedKey<BulkLegValue, BulkLegInput>;

export function getBulkLegInput<T extends BulkLegSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<BulkLegValue, BulkLegInput, T>(prop);
  return (state: AppState, bulkId: string, legId: string) =>
    get(getBulkLegState(state, bulkId, legId));
}

export type BulkLegSharedPropertyStatus<T extends BulkLegSharedPropertyKey> = SharedPropertyStatus<
  BulkLegValue[T],
  BulkLegInput[T]
>;
