import { format } from 'date-fns';
import type { Thunk } from 'state';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import { fieldData } from 'utils/fieldSelectors';
import { saveFile } from 'utils/saveFile';
import type { IQuoteLeg } from '../fxBulksModel';
import {
  getBidAskValueWith,
  makeForwardPointWithMargin,
  sideToWay,
  makeAllIn,
} from '../selectors/utils';

export function bulkExportCsv(bulkId: string): Thunk<void> {
  return (_dispatch, getState, { selectors: sl }) => {
    const state = getState();
    const ccyPair = fieldData(sl.getBulkCurrencyPair(state, bulkId)).data!;
    const precision = sl.getCurrencyPrecision(state, ccyPair);
    const amountCcy = fieldData(sl.getBulkAmountCurrency(state, bulkId)).data!;
    const streamId = sl.getBulkCurrentStreamId(state, bulkId);
    if (streamId === null) {
      return;
    }
    const streamState = sl.getBulkStreamState(state, streamId);
    if (streamState.status !== 'PRICING') {
      return null;
    }

    const csvContent = formatCsv(
      streamState.quote.nettedLegs,
      streamState.quote.legs,
      mapLegWith(ccyPair, amountCcy, precision),
    );

    const fileName = `Export-SGMarketsFX-BulkTrade-${format(
      streamState.quote.frontTimestamp,
      'yyyy-MM-dd',
    )}-${format(streamState.quote.frontTimestamp, 'HH:mm:ss')}.csv`;
    saveFile(fileName, new Blob([csvContent], { type: 'text/csv' }));
  };
}

const formatCsv = (
  nettedLegs: readonly IQuoteLeg[],
  splitLegs: readonly IQuoteLeg[],
  legMapper: (leg: IQuoteLeg) => readonly string[],
) => {
  const separator = ';';
  const csvHeaders = [
    'Type',
    'CCY Pair',
    'Dealt CCY',
    'Way',
    'Amount',
    'Date',
    'Tenor',
    'Spot',
    'Points',
    'All in',
  ];

  return [
    ['Pricing'],
    csvHeaders,
    ...nettedLegs.map(legMapper),
    [],
    ['Booking'],
    csvHeaders,
    ...splitLegs.map(legMapper),
  ]
    .map(row => row.join(separator))
    .join('\n');
};

const mapLegWith = (ccyPair: string, amountCcy: CurrencyChoice, precision: number) => {
  const [ccy1, ccy2] = ccyPair.split('/');
  const dealtCcy = amountCcy === 1 ? ccy1 : ccy2;
  return (leg: IQuoteLeg) => {
    const getBidAskValue = getBidAskValueWith(leg.customerWay);
    const spotWithMargin = getBidAskValue(leg.spotWithMargin);
    const forwardPointsWithMargin = makeForwardPointWithMargin(
      sideToWay(leg.customerWay),
      getBidAskValue(leg.forwardPoints),
      getBidAskValue(leg.forwardMarginPoints),
    );
    const allInWithMargin = makeAllIn(spotWithMargin, forwardPointsWithMargin, precision);

    return [
      leg.isForward ? 'FORWARD' : 'SPOT',
      ccyPair,
      dealtCcy,
      leg.customerWay,
      leg.amount.toString(),
      leg.date,
      leg.dateTenor,
      spotWithMargin.toString(),
      forwardPointsWithMargin.toString(),
      allInWithMargin.toString(),
    ];
  };
};
