import type { AppState } from 'state/model';
import {
  getValueProperty,
  type ValueKey,
  type SharedKey,
  getSharedProperty,
  type SharedPropertyStatus,
} from 'state/share/form';
import type {
  IFxAccumulatorMetadata,
  IFxAccumulatorValues as AccumulatorValue,
  FxAccumulatorInputs as AccumulatorInput,
  IFxTargetAccumulatorValues as TargetAccumulatorValue,
  FxTargetAccumulatorInputs as TargetAccumulatorInput,
  IFxForwardAccumulatorValues as ForwardAccumulatorValue,
  FxForwardAccumulatorInputs as ForwardAccumulatorInput,
} from '../fxAccumulatorsModel';
import {
  getAccumulatorState,
  getForwardAccumulatorState,
  getTargetAccumulatorState,
} from './accumulator';

export type AccumulatorMetadataStatus<T extends keyof IFxAccumulatorMetadata> =
  IFxAccumulatorMetadata[T];

export function getAccumulatorMetadata<T extends keyof IFxAccumulatorMetadata>(property: T) {
  return (state: AppState, accumulatorId: string): AccumulatorMetadataStatus<T> =>
    getAccumulatorState(state, accumulatorId)[property];
}

// common accumulator value
type AccumulatorValuePropertyKey = ValueKey<AccumulatorValue, AccumulatorInput>;

export function getAccumulatorValue<T extends AccumulatorValuePropertyKey>(prop: T) {
  const get = getValueProperty<AccumulatorValue, AccumulatorInput, T>(prop);
  return (state: AppState, accumulatorId: string) => get(getAccumulatorState(state, accumulatorId));
}

// target accumulator value
type TargetAccumulatorValuePropertyKey = ValueKey<TargetAccumulatorValue, TargetAccumulatorInput>;

export function getTargetAccumulatorValue<T extends TargetAccumulatorValuePropertyKey>(prop: T) {
  const get = getValueProperty<TargetAccumulatorValue, TargetAccumulatorInput, T>(prop);
  return (state: AppState, accumulatorId: string) =>
    get(getTargetAccumulatorState(state, accumulatorId));
}

// forward accumulator value
type ForwardAccumulatorValuePropertyKey = ValueKey<
  ForwardAccumulatorValue,
  ForwardAccumulatorInput
>;

export function getForwardAccumulatorValue<T extends ForwardAccumulatorValuePropertyKey>(prop: T) {
  const get = getValueProperty<ForwardAccumulatorValue, ForwardAccumulatorInput, T>(prop);
  return (state: AppState, accumulatorId: string) =>
    get(getForwardAccumulatorState(state, accumulatorId));
}

// common accumulator input
type AccumulatorSharedPropertyKey = SharedKey<AccumulatorValue, AccumulatorInput>;

export function getAccumulatorInput<T extends AccumulatorSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<AccumulatorValue, AccumulatorInput, T>(prop);
  return (state: AppState, AccumulatorId: string) => get(getAccumulatorState(state, AccumulatorId));
}

// target accumulator input
type TargetAccumulatorSharedPropertyKey = SharedKey<TargetAccumulatorValue, TargetAccumulatorInput>;

export function getTargetAccumulatorInput<T extends TargetAccumulatorSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<TargetAccumulatorValue, TargetAccumulatorInput, T>(prop);
  return (state: AppState, AccumulatorId: string) =>
    get(getTargetAccumulatorState(state, AccumulatorId));
}

export type ForwardAccumulatorSharedPropertyStatus<T extends AccumulatorSharedPropertyKey> =
  SharedPropertyStatus<ForwardAccumulatorValue[T], ForwardAccumulatorInput[T]>;

// forward accumulator input
type ForwardAccumulatorSharedPropertyKey = SharedKey<
  ForwardAccumulatorValue,
  ForwardAccumulatorInput
>;

export function getForwardAccumulatorInput<T extends ForwardAccumulatorSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<ForwardAccumulatorValue, ForwardAccumulatorInput, T>(prop);
  return (state: AppState, AccumulatorId: string) =>
    get(getForwardAccumulatorState(state, AccumulatorId));
}

export type AccumulatorSharedPropertyStatus<T extends AccumulatorSharedPropertyKey> =
  SharedPropertyStatus<AccumulatorValue[T], AccumulatorInput[T]>;
