import { connectAddTabButton } from './connect';
import { MenuButton } from './MenuButton';

export interface AddTabButtonProps {
  addTab(): void;
}

export const AddTabButtonRaw: React.FunctionComponent<AddTabButtonProps> = ({ addTab }) => (
  <MenuButton e2eHandle="add-tab" color="secondary" onClick={addTab}>
    <i className="icon icon-md px-2">add</i>
  </MenuButton>
);

export const AddTabButton = connectAddTabButton(AddTabButtonRaw);
