import type { Thunk } from 'state';
import type { Way } from 'state/share/productModel/litterals';
import { logger } from 'logging/logger';

/**
 * @todo remove ac imports
 */
import { cashRfsExecutionRequested, cashEspExecutionRequested } from '../fxCashsActions';

export function cashExecutionRequestedThunk(cashId: string, way: Way): Thunk<void> {
  return (dispatch, getState, { selectors: sl }) => {
    const state = getState();
    const canTrade = sl.userCanTrade(state) && sl.isStreamingConnected(state);
    if (canTrade !== true) {
      return;
    }

    const currencyPair = sl.getCashCurrencyPair(state, cashId).value;
    if (currencyPair === null) {
      return;
    }
    const { currentStreamId, currentEspStreamId } = sl.getCashState(state, cashId);

    const precision = sl.getCurrencyPrecision(state, currencyPair);
    if (currentStreamId !== null) {
      dispatch(cashRfsExecutionRequested(cashId, currentStreamId, way));
    } else if (currentEspStreamId !== null) {
      const stream = sl.getEspStreamState(state, currentEspStreamId);
      if (stream !== undefined && stream.status === 'PRICING' && stream.missedHeartbeat === false) {
        const priceAge = Date.now() - stream.priceDecodedTime;
        logger.logInformation('User executed an ESP whose price was aged {priceAge_n}', priceAge);
        dispatch(cashEspExecutionRequested(cashId, currentEspStreamId, way, precision));
      }
    }
  };
}
