import { type MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Autosizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, type ListChildComponentProps as ListRowProps } from 'react-window';
import styled from 'styled-components';
import { currencyPairEmphasize, currencyPairFilter } from 'utils/filter';
import { CurrencyPickerContext } from './CurrencyPickerContext';
import { pick } from 'utils/object';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCcyPairs } from 'state/referenceData/referenceDataSelectors';
import type { CurrencyPair, IndexedCurrencyPairs, Instrument } from 'state/referenceData/referenceDataModel';
import { currencyPairChangeThunk } from 'state/tile/tileThunks';
import { InstrumentContext, QuoteIdContext } from '../contexts';
import { localFieldValidationSet } from 'state/globalActions';
import { useProductsAccess } from 'components/share/hooks/useProductAccess';
import type { Predicate } from 'utils/predicates';
import { sortCurrencies } from 'utils/sortCurrencies';
import { assertUnhandled } from 'utils/error';
import { useOrderTypeContext } from 'components/contexts/OrderTypeContext';
import { allowedNdfCurrencies, isAlgoOrder } from 'state/fxOrders/fxOrdersModel';
import { assertUnreachable } from '@sgme/fp';
import type { DispatchWithThunkExt } from 'state';
import { useInstrumentAllowingCurrency } from '../share/hooks/useInstrumentAllowingCurrency';

const selectClass = 'active';
const invertedClass = 'text-secondary';

const ccyListContainerClass = 'w-100 p-0 overflow-x-hidden overflow-y-hidden text-center';

export interface CurrencyListProps {
  className?: string;
}

const rowHeight = 24;

function filterCcy(input: string, all: readonly CurrencyPair[]) {
  const filterFn = currencyPairFilter(input);
  return input === '' ? all : all.filter(filterFn);
}

function useEventHandler<K extends keyof HTMLElementEventMap>(
  target: MutableRefObject<HTMLInputElement | null>,
  type: K,
  handler: (this: HTMLInputElement, ev: HTMLElementEventMap[K]) => void,
) {
  useEffect(() => {
    const currentTarget = target.current;
    if (currentTarget !== null) {
      currentTarget.addEventListener(type, handler);
      return () => currentTarget.removeEventListener(type, handler);
    }
  }, [handler, target, type]);
}

function filterCcyPairs(allCurrencyPairs: Readonly<IndexedCurrencyPairs>, predicate: Predicate<CurrencyPair>) {
  return Object.values(allCurrencyPairs).filter(predicate).sort(sortCurrencies);
}

function makeFilterCcyPairsForInstrument(
  instrument: Instrument,
  canTradeNonDeliverable: boolean,
  orderType: string | null,
): Predicate<CurrencyPair> {
  switch (instrument) {
    case 'Cash':
    case 'Swap':
      return (ccyPair) => canTradeNonDeliverable || ccyPair.canBeDelivered;
    case 'Order':
      return (ccyPair) =>
        (canTradeNonDeliverable && isAlgoOrderNdfAllowed(orderType, ccyPair.pair)) || ccyPair.canBeDelivered;

    case 'Accumulator':
    case 'Option':
      return (ccyPair) => ccyPair.isOptionCurrency;
    case 'Bulk':
      return (ccyPair) => ccyPair.canBeDelivered;
    case 'AmericanForward':
      return (ccyPair) => ccyPair.canBeDelivered;
    case 'BlotterOrder':
      assertUnhandled('Blotter order currency pair cannot be changed', instrument);
      break;
    case 'SmartRfs':
      assertUnhandled('SmartRfs has no associated currency pair', instrument);
      break;
    default:
      assertUnreachable(instrument, 'Unhandled instrument');
  }
}

export function isAlgoOrderNdfAllowed(orderType: string | null, currencyPair: string): boolean {
  return isAlgoOrder(orderType) && allowedNdfCurrencies.some((a) => a === currencyPair);
}

function useCurrencyPairs(): readonly CurrencyPair[] {
  const instrument = useContext(InstrumentContext) as Instrument;
  const allCurrencyPairs = useSelector(getAllCcyPairs);
  const { nonDeliverable } = useProductsAccess();
  const orderType = useOrderTypeContext();

  const ccyPairsForInstrument = useMemo(
    () =>
      instrument === undefined
        ? []
        : filterCcyPairs(allCurrencyPairs, makeFilterCcyPairsForInstrument(instrument, nonDeliverable, orderType)),
    [allCurrencyPairs, instrument, nonDeliverable, orderType],
  );
  return ccyPairsForInstrument;
}

export const CurrencyList: React.FunctionComponent<CurrencyListProps> = ({ className }: CurrencyListProps) => {
  const { currencyPair, inputRef } = useContext(CurrencyPickerContext);
  const quoteId = useContext(QuoteIdContext);
  const instrument = useInstrumentAllowingCurrency();

  const dispatch: DispatchWithThunkExt = useDispatch();

  const allCurrencyPairs = useSelector(getAllCcyPairs);
  const availableCcyPairs = useCurrencyPairs();

  const [inputValue, setInputValue] = useState(currencyPair ?? '');

  useEventHandler(inputRef, 'keyup', () => {
    setInputValue(inputRef.current?.value ?? '');
  });

  const filteredCurrencyPairs = useMemo(
    () => filterCcy(inputValue, availableCcyPairs),
    [availableCcyPairs, inputValue],
  );

  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectCcyPair = useCallback(
    (ccyPair: string) => {
      dispatch(currencyPairChangeThunk(quoteId, ccyPair));
    },
    [dispatch, quoteId],
  );

  const triggerError = useCallback(() => {
    dispatch(localFieldValidationSet(instrument!, quoteId, 'currencyPair', 'invalid', 'currency.invalidCurrencyPair'));
  }, [dispatch, instrument, quoteId]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          setSelectedIndex((index) => Math.max(0, index - 1));
          break;
        case 'ArrowDown':
          event.preventDefault();
          setSelectedIndex((index) => Math.min(filteredCurrencyPairs.length - 1, index + 1));
          break;
        case 'Enter':
        case 'Tab': {
          const selectedCurrencyPair = filteredCurrencyPairs[selectedIndex];
          if (inputRef.current !== null && selectedCurrencyPair !== undefined) {
            inputRef.current.value = selectedCurrencyPair.pair;
          }
          break;
        }
      }
    },
    [filteredCurrencyPairs, inputRef, selectedIndex],
  );

  useEffect(() => {
    setSelectedIndex(0);
  }, [inputValue]);

  const onClickListItem: React.MouseEventHandler<HTMLLIElement> = useCallback(
    (event) => {
      const ccyPair = event.currentTarget.getAttribute('data-value');
      if (inputRef.current !== null) {
        inputRef.current.value = ccyPair ?? '';
      }
    },
    [inputRef],
  );

  const [shouldAppear, setShouldAppear] = useState(false);

  useEventHandler(inputRef, 'focus', () => {
    const currentInput = inputRef.current?.value;
    setShouldAppear(currentInput !== currencyPair);
  });

  useEventHandler(inputRef, 'keydown', (e) => {
    setShouldAppear(true);
    onKeyDown(e);
  });

  useEventHandler(inputRef, 'blur', () => {
    setShouldAppear(false);
    if (inputRef.current !== null) {
      const ccyPair = inputRef.current?.value;
      if (allCurrencyPairs[ccyPair] === undefined) {
        triggerError();
        inputRef.current.value = currencyPair ?? '';
        setInputValue(currencyPair ?? '');
      } else if (currencyPair !== ccyPair) {
        selectCcyPair(ccyPair);
        setInputValue(ccyPair);
      }
    }
  });

  const listRef = useRef<List>(null);

  useEffect(() => {
    listRef.current?.scrollToItem(selectedIndex);
  }, [selectedIndex]);

  return shouldAppear ? (
    <CurrencyListContainer className={className}>
      <Autosizer>
        {({ width, height }) => {
          const rowRenderer = (props: ListRowProps) => (
            <CurrencyListItem
              ccyInputValue={inputRef.current?.value ?? ''}
              filteredCcyPairs={filteredCurrencyPairs}
              selectedIndex={selectedIndex}
              onCcyPairMouseDown={onClickListItem}
              {...props}
            />
          );

          // width and heigth  casted as per https://github.com/bvaughn/react-virtualized-auto-sizer/issues/45
          return (
            <List
              width={width}
              height={height}
              itemSize={rowHeight}
              itemCount={filteredCurrencyPairs.length}
              className="dropdown-menu show no-scrollbar"
              ref={listRef}
            >
              {rowRenderer}
            </List>
          );
        }}
      </Autosizer>
    </CurrencyListContainer>
  ) : null;
};

interface CurrencyListItemProps {
  ccyInputValue: string;
  filteredCcyPairs: readonly CurrencyPair[];
  selectedIndex: number | null;
  onCcyPairMouseDown: React.MouseEventHandler<HTMLLIElement>;
}

const getListRowProps = pick('key', 'style');

const CurrencyListItem: React.FunctionComponent<CurrencyListItemProps & ListRowProps> = ({
  ccyInputValue,
  filteredCcyPairs,
  index,
  selectedIndex,
  ...props
}: CurrencyListItemProps & ListRowProps) => (
  <li
    {...getListRowProps(props)}
    data-value={filteredCcyPairs[index].pair}
    className={`${
      selectedIndex === index ? selectClass : filteredCcyPairs[index].isInverted ? invertedClass : ''
    } dropdown-item text-center p-1`}
    onMouseDown={props.onCcyPairMouseDown}
  >
    <div
      className="w-100 text-center cursor-default"
      dangerouslySetInnerHTML={{
        __html: currencyPairEmphasize(ccyInputValue)(filteredCcyPairs[index]),
      }}
    />
  </li>
);

const CurrencyListContainerUl = styled.ul`
  z-index: 1000;

  /* hide the checkmark */

  & .dropdown-item.active::after {
    display: none;
  }
`;

interface CurrencyListContainerProps {
  className?: string;
  children: React.ReactNode;
}

const CurrencyListContainer: React.FunctionComponent<CurrencyListContainerProps> = ({
  className = '',
  children,
}: CurrencyListContainerProps) => (
  <CurrencyListContainerUl className={`${className} ${ccyListContainerClass}`} data-nodrag>
    {children}
  </CurrencyListContainerUl>
);
