import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import { fieldData } from 'utils/fieldSelectors';
import { isAlgoOrder } from 'state/fxOrders/fxOrdersModel';

export interface NDCheckboxConnectOwnProps {
  quoteId: string;
}

export interface NDCheckboxConnectStateProps {
  value: boolean;
  show: boolean;
  disabled: boolean;
  label: string;
}

export interface NDCheckboxConnectDispatchProps {
  onChange: (value: boolean) => void;
}

export type NDCheckboxConnectSelectorsKeys =
  | 'getQuoteInstrument'
  | 'getTileCurrencyPair'
  | 'getCashIsNonDeliverable'
  | 'getSwapIsNonDeliverable'
  | 'getCurrencyPairDetails'
  | 'getProductsAccess'
  | 'getOrderType';

export type NDCheckboxConnectSelectors = Pick<Selectors, NDCheckboxConnectSelectorsKeys>;

export const mapStateToPropsNDCheckbox: MapStateToPropsHOF<
  NDCheckboxConnectStateProps,
  NDCheckboxConnectOwnProps,
  AppState,
  NDCheckboxConnectSelectors
> =
  sl =>
  (state, { quoteId }) => {
    const instrument = sl.getQuoteInstrument(state, quoteId);
    const currencyPair = fieldData(sl.getTileCurrencyPair(state, quoteId)).data;

    const extCcyPair =
      currencyPair !== null ? sl.getCurrencyPairDetails(state, currencyPair) : null;

    const { nonDeliverable: canDealND } = sl.getProductsAccess(state);

    switch (instrument) {
      case 'Cash':
        const isNonDeliverableCash = fieldData(sl.getCashIsNonDeliverable(state, quoteId)).data;

        return {
          value: !!isNonDeliverableCash,
          label: 'NDF',
          show:
            extCcyPair !== null &&
            canDealND &&
            (extCcyPair.notDeliverable || extCcyPair.isCashDeliverable),
          disabled: extCcyPair !== null && !extCcyPair.canBeDelivered,
        };
      case 'Swap':
        const isNonDeliverableSwap = fieldData(sl.getSwapIsNonDeliverable(state, quoteId)).data;

        return {
          value: !!isNonDeliverableSwap,
          label: 'NDS',
          show: extCcyPair !== null && canDealND && extCcyPair.notDeliverable,
          disabled: extCcyPair !== null && !extCcyPair.canBeDelivered,
        };
      case 'Order':
        const orderType = sl.getOrderType(state, quoteId).value;
        return {
          value: !!extCcyPair?.notDeliverable,
          label: 'NDF',
          show: isAlgoOrder(orderType),
          disabled: true,
        };
      default:
        return {
          value: false,
          label: '',
          show: false,
          disabled: true,
        };
    }
  };

export type NDCheckboxConnectActionCreatorsKeys = 'nonDeliverableFlagChangeThunk';
export type NDCheckboxConnectActionCreators = Pick<
  ActionCreators,
  NDCheckboxConnectActionCreatorsKeys
>;

export const mapDispatchToPropsNDCheckbox: MapDispatchToPropsHOF<
  NDCheckboxConnectDispatchProps,
  NDCheckboxConnectOwnProps,
  NDCheckboxConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    onChange: (value: boolean) => {
      dispatch(ac.nonDeliverableFlagChangeThunk(quoteId, value));
    },
  });
