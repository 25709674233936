import type {
  AccumulatorScheduleInputs,
  ForwardAccuType,
  FxAccumulatorInputs,
  TargetAccuType,
} from 'state/fxAccumulators/fxAccumulatorsModel';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type {
  FxForwardAccumulatorLegRequest,
  FxTargetAccumulatorLegRequest,
  RequestBarrier,
  TcAccumulatorHedgeType,
  TradeCaptureAccumulator,
  TradeCaptureAccumulatorLegRequest,
  TradeCaptureAccumulatorRequestWrapper,
  TradeCaptureAccumulatorScheduleItemRequest,
} from 'api/tradeCapture/accumulator/tradeCaptureModel';
import { clearUndefined } from 'utils/clearUndefined';
import { parseWithCultureInfo } from 'utils/parseDateWithCultureInfo';
import type { DateInputCultureInfo } from 'state/userPreferences';
import { fieldData } from 'utils/fieldSelectors';
import type { CurrencyChoice, HedgeType } from 'state/share/productModel/litterals';
import { FIRST_CURRENCY } from 'state/share/productModel/litterals';
import { isDefined, isEmpty, isEmptyOrNotDefined } from '@sgme/fp';

export type AccumulatorStoreModelChanges = Partial<FxAccumulatorInputs>;

interface WithQuoteId {
  quoteId: string;
}

export type TradeCaptureToBackendMetadata = {
  patch: AccumulatorStoreModelChanges;
} & WithQuoteId;

export type TradeCaptureToBackendMetaSelectorsKeys =
  | 'getAccumulatorTradeCaptureIdVersion'
  | 'getUserPreferenceData'
  | 'getAccumulatorCurrencyPairInput'
  | 'getTargetAccumulatorAccuType'
  | 'getAccumulatorProductName';

export type TradeCaptureToBackendMetaSelectorSelectors = Pick<Selectors, TradeCaptureToBackendMetaSelectorsKeys>;

export const metaSelectorTradeCaptureToBackendWith: MapStateToMetadataHOF<
  TradeCaptureAccumulatorRequestWrapper,
  TradeCaptureToBackendMetadata,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> = sl => {
  const toTradeCapturePatch = metaSelectorAccumulatorToTradeCapturePatchWith(sl);
  const toTradeCaptureLegPatch = metaSelectorAccumulatorToTradeCaptureLegPatchWith(sl);

  return (state, { quoteId, patch }) => {
    const idVersion = getNextIdVersion(sl.getAccumulatorTradeCaptureIdVersion(state, quoteId));
    const productChangedFields = toTradeCapturePatch(state, patch);
    const legChangedFields = toTradeCaptureLegPatch(state, {
      quoteId,
      ...patch,
    });
    return {
      idVersion,
      changedFields: {
        ...productChangedFields,
        legs: {
          0: legChangedFields,
        },
      },
    };
  };
};

function getNextIdVersion(lastIdVersion: number | null): number {
  return lastIdVersion === null ? 0 : lastIdVersion + 1;
}

const metaSelectorAccumulatorToTradeCapturePatchWith: MapStateToMetadataHOF<
  Partial<TradeCaptureAccumulator>,
  Partial<FxAccumulatorInputs>,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> = _sl => (_state, patch) => {
  const leg = clearUndefined({ premiumDate: patch.premiumDate });
  const isFirstHedgeCurrency = patch.hedgeCurrency === FIRST_CURRENCY;

  const product = clearUndefined({
    currencyPair: patch.currencyPair,
    hedgeType: mapToTcHedgeType(patch.hedgeType as 'Live' | 'Spot'),
    hedgePriceString: patch.hedgePrice,
    hedgeAmountInCcy1String: isFirstHedgeCurrency ? patch.hedgeAmount : undefined,
    hedgeAmountInCcy2String: !isFirstHedgeCurrency ? patch.hedgeAmount : undefined,
    markupCurrency: patch.markupCurrency,
  });

  return {
    ...product,
    legs: {
      0: leg,
    },
  };
};

export type AccumulatorType = TargetAccuType | ForwardAccuType;

export interface PatchAccuType {
  accuType?: AccumulatorType;
}

const metaSelectorAccumulatorToTradeCaptureLegPatchWith: MapStateToMetadataHOF<
  Partial<TradeCaptureAccumulatorLegRequest>,
  Partial<FxAccumulatorInputs> & WithQuoteId & PatchAccuType,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> =
  sl =>
  (state, { quoteId, ...patch }) => {
    const { dateInputCultureInfo: cultureInfo } = sl.getUserPreferenceData(state);
    const currencyPairRef = patch.currencyPair || fieldData(sl.getAccumulatorCurrencyPairInput(state, quoteId)).data;
    const expiryDateString = patch.expiryDate
      ? dateToPatch(patch.expiryDate, null, cultureInfo) || patch.expiryDate
      : patch.expiryTenor;

    const productName = sl.getAccumulatorProductName(state, quoteId);
    let ekiTrigger:
      | { ekiString: string | null | undefined }
      | {
          step2String: string | null | undefined;
        } = { ekiString: undefined };

    const targetAccuType = patch.accuType ?? undefined;
    if (productName === 'FxTargetAccumulator') {
      // is a targetAccu need to send eki on ekiString
      // depending on targetAccuType value, need to send ekiString ou step2String property with ekiTrigger value
      if (targetAccuType === 'EKI') {
        // TODO ABO, SGEFX-5007: harmonize eki variant name (eki, ekiTrigger, step)
        // @ts-ignore
        ekiTrigger = { ekiString: patch.ekiTrigger ?? patch.eki?.toString() };
      }
    } else {
      ekiTrigger = { step2String: patch.ekiTrigger };
    }
    return clearUndefined<Partial<FxTargetAccumulatorLegRequest | FxForwardAccumulatorLegRequest>>({
      productName,
      premiumPaymentCurrency: mapToPremiumPaymentCurrency(patch.priceCurrency, currencyPairRef),
      premiumPaymentDateString: dateToPatch(patch.premiumDate, patch.premiumDateTenor, cultureInfo),
      side: patch.way,
      amountString: patch.amount,
      amountSplitType: patch.amountSplitType,
      amountCurrency: mapToCurrency(patch.amountCurrency, currencyPairRef),
      leverageString: patch.leverage,
      leverageAmountString: patch.leverageAmount,
      strikeString: patch.strike,
      stepString: patch.step,
      accuType: targetAccuType,
      ...ekiTrigger,
      EkiDownString: patch.ekiDown,
      EkiUpString: patch.ekiUp,
      strikeDownString: patch.strikeDown,
      pivotString: patch.pivot,
      strikeUpString: patch.strikeUp,
      barrier: mapAkoTriggerToBackend(patch.akoTrigger),
      koPaymentConvention: patch.targetProfitType,
      fixingFrequency: patch.fixingFrequency,
      numberOfFixings: patch.numberOfFixings,
      fixingReference1: patch.fixingReference1,
      fixingReference2: patch.fixingReference2,
      settlementFrequency: patch.settlementFrequency,
      isCrossed: patch.isCrossed,
      crossCurrency: patch.crossCurrency,
      targetString: patch.target,
      firstFixingDateString: dateToPatch(patch.firstFixingDate, null, cultureInfo) || patch.firstFixingDateTenor,
      schedule: mapToTradeCaptureSchedule(patch.schedule, targetAccuType, cultureInfo),
      deliveryType: patch.settlementMode,
      cashSettlementCurrency: patch.cashSettlementCurrency,
      expiryDateString,
    });
  };

const mapAkoTriggerToBackend = (akoTrigger: string | null | undefined): RequestBarrier | null | undefined => {
  if (isDefined(akoTrigger)) {
    return akoTrigger === '' ? null : { levelString: akoTrigger };
  }

  return undefined;
};

const dateToPatch = (
  date: string | undefined | null,
  tenor: string | undefined | null,
  cultureInfo: DateInputCultureInfo,
) => {
  const ref = date || tenor;
  return ref ? parseWithCultureInfo(cultureInfo, ref) : undefined;
};

const mapToPremiumPaymentCurrency = (
  priceCurrency: CurrencyChoice | undefined,
  currencyPair: string | null,
): string | undefined => {
  if (priceCurrency === undefined || isEmptyOrNotDefined(currencyPair)) {
    return undefined;
  }
  const [currency1, currency2] = currencyPair.split('/');
  return priceCurrency === 1 ? currency1 : currency2;
};

const mapToCurrency = (amountCurrency: CurrencyChoice | undefined, currencyPair: string | null): string | undefined => {
  if (amountCurrency === undefined || isEmptyOrNotDefined(currencyPair)) {
    return undefined;
  }
  const currencies = currencyPair.split('/');
  return currencies[amountCurrency - 1];
};

function mapToTradeCaptureSchedule(
  schedule: Record<string, Partial<AccumulatorScheduleInputs> | null> | null | undefined,
  accuType: AccumulatorType | undefined,
  cultureInfo: DateInputCultureInfo,
): Record<string, Partial<TradeCaptureAccumulatorScheduleItemRequest> | null> | null | undefined {
  if (schedule === undefined) {
    return undefined;
  }
  if (schedule === null) {
    return null;
  }

  const result = Object.entries(schedule).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        value === null
          ? null
          : {
              fixingDateString: dateToPatch(value.fixingDate, null, cultureInfo),
              paymentDateString:
                value.paymentDate?.length !== 0 ? dateToPatch(value.paymentDate, null, cultureInfo) : '',
              strikeString: value.strike?.toString(),
              strikeDownString: value.strikeDown?.toString(),
              stepDownString: value.stepDown?.toString(),
              stepUpString: value.stepUp?.toString(),
              strikeUpString: value.strikeUp?.toString(),
              step2String: value.ekiTrigger?.toString(),
              stepString: accuType === 'PIVOT' ? value.pivot?.toString() : value.step?.toString(),
              amountString: value.amount?.toString(),
              leverageAmountString: value.leverageAmount?.toString(),
            },
    }),
    {} as Record<string, Partial<TradeCaptureAccumulatorScheduleItemRequest | null>>,
  );

  return isEmpty(Object.keys(result)) ? undefined : result;
}

function mapToTcHedgeType(value: Exclude<HedgeType, 'Forward'> | undefined): TcAccumulatorHedgeType | undefined {
  if (value === 'Live') {
    return 'None';
  }
  return value;
}
