import type { Thunk } from 'state';

export function cashTileClosed(cashId: string, automatically = false): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    if (!sl.isTilePresent(state, cashId)) {
      return;
    }
    const tabId = sl.getClientWorkspaceIdByQuoteId(state, cashId);
    dispatch(ac.espTileStreamUnsubscribeThunk(cashId));
    dispatch(ac.cashRfsCancelThunk(cashId));
    dispatch(ac.clientWorkspaceTileDeleted(cashId, tabId, automatically));
  };
}

export function cashTileStateCleaned(cashId: string): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(ac.espTileStreamUnsubscribeThunk(cashId));
    dispatch(ac.cashRfsCancelThunk(cashId));
  };
}

export function cashClientChangedThunk(cashId: string, clientId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    // always cancel pending rfs if it exists
    dispatch(ac.cashRfsCancelThunk(cashId));
    // restart esp or try to start it
    const companyId = sl.getCompanyIdFromClientId(state, clientId);
    dispatch(ac.espStreamRestartThunk(cashId, undefined, undefined, companyId));
    dispatch(
      ac.cashLocalPropertyChanged(cashId, {
        bidMargin: null,
        askMargin: null,
        bidForwardMargin: null,
        askForwardMargin: null,
      }),
    );
  };
}
