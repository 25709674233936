import type { Thunk } from 'state';

export function retrieveMissingEspLimits(): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    const missingEspLimits = sl.getMissingEspLimits(state);
    dispatch(ac.retrieveEspLimitsEpic(missingEspLimits));
  };
}

export function retrieveEspLimits(currencies: readonly string[]): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(ac.espLimitsAddCurrencies(currencies));
    dispatch(retrieveMissingEspLimits());
  };
}

export function retrieveEspLimitsFailed(delayInSeconds: number): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    const missingEspLimits = sl.getMissingEspLimits(state);
    dispatch(ac.retrieveEspLimitsFailedEpic(missingEspLimits, delayInSeconds));
  };
}
