/* 
  Typings became more complex than expected :D 
  feel free to nuke that if you want
*/

type FieldInNames =
  | 'emailNotificationList'
  | 'emails'
  | 'splitNotificationsEmailsCash'
  | 'splitNotificationsEmailsOrders'
  | 'splitNotificationsEmailsOptions';
type FieldOutNames =
  | 'email64NotificationList'
  | 'emails'
  | 'splitNotificationsEmailsCash'
  | 'splitNotificationsEmailsOrders'
  | 'splitNotificationsEmailsOptions';
type FieldValue = string | readonly string[];

type IWithEmail<FieldName extends FieldInNames> = {
  [name in FieldName]: FieldValue;
};

type IWithBase64Email<FieldName extends FieldOutNames> = {
  [name in FieldName]: FieldValue;
};

type WithOptionalEmail<T, IN extends FieldInNames, OUT extends FieldOutNames> = Omit<T, IN> &
  IWithBase64Email<OUT> & { [k in IN]?: FieldValue };

type WithoutEmail<T, IN extends FieldInNames, OUT extends FieldOutNames> = Omit<T, IN> &
  IWithBase64Email<OUT>;

export function transformEmailsToBase64<
  T extends IWithEmail<IN>,
  IN extends FieldInNames,
  OUT extends FieldOutNames,
>(payload: T, fieldIn: IN, fieldOut: OUT): WithoutEmail<T, IN, OUT> {
  const field: FieldValue = payload[fieldIn];

  const result: WithOptionalEmail<T, IN, OUT> = {
    ...payload,
    ...({
      [fieldOut]: typeof field === 'string' ? btoa(field) : field.map(btoa),
    } as IWithBase64Email<FieldOutNames>),
  };
  if ((fieldIn as FieldInNames) !== fieldOut) {
    delete result[fieldIn];
  }

  return result;
}
