import type { HedgeType } from 'state/share/productModel/litterals';
import type { PriceType } from 'state/fxOptions/fxOptionsModel';

export const dateInputCultureInfoOptions = ['en', 'fr'] as const;
export type DateInputCultureInfo = typeof dateInputCultureInfoOptions[number];

export const booleanUserPrefsKeys = [
  'oneClickExecution',
  'resetTileOnSuccessfulExecution',
  'showSwapPoints',
  'showDeltaStrike',
  'showMyMainDeals',
  'optionOneWayQuotation',
  'optionDisplayMidVolatility',
  'optionStrategyGroupLegs',
  'optionExpandGreekAndMkt',
  'optionAlignCurrencies',
  'orderQuickLimit',
  'extendedSwapPrecision',
  'splitNotifications',
] as const;

export const autoCloseGrowlAfterChoices = [null, 5000, 15000, 60000] as const;
export type AutoCloseGrowlAfter = typeof autoCloseGrowlAfterChoices[number];

export type BooleanUserPrefKeys = typeof booleanUserPrefsKeys[number];

export type BooleanUserPref = Record<BooleanUserPrefKeys, boolean>;

export const accountLengthOptions = ['Long', 'Short'] as const;
export type AccountLengthOption = typeof accountLengthOptions[number];

interface IUserPreferencesData {
  dateInputCultureInfo: DateInputCultureInfo;
  autoCloseGrowlAfter: AutoCloseGrowlAfter;
  defaultNotional: string | null;
  emails: readonly string[];
  accountNameLength: AccountLengthOption;
  defaultHedgeType: HedgeType;
  defaultPriceType: PriceType;
  splitNotificationsEmailsCash: readonly string[];
  splitNotificationsEmailsOrders: readonly string[];
  splitNotificationsEmailsOptions: readonly string[];
}

export type UserPreferencesData = Readonly<IUserPreferencesData & BooleanUserPref>;

export interface UserPreferencesState {
  showUserPreferences: boolean;
  data: UserPreferencesData;
}

export const defaultUserPreferenceData: UserPreferencesData = {
  dateInputCultureInfo: 'en',
  autoCloseGrowlAfter: null,
  showSwapPoints: true,
  showDeltaStrike: false,
  splitNotifications: false,
  resetTileOnSuccessfulExecution: true,
  defaultNotional: null,
  optionOneWayQuotation: true,
  optionDisplayMidVolatility: true,
  optionStrategyGroupLegs: false,
  optionExpandGreekAndMkt: false,
  optionAlignCurrencies: false,
  showMyMainDeals: true,
  emails: [],
  accountNameLength: 'Long',
  orderQuickLimit: true,
  extendedSwapPrecision: false,
  oneClickExecution: false,
  defaultHedgeType: 'Live',
  defaultPriceType: 'AMOUNT',
  splitNotificationsEmailsCash: [],
  splitNotificationsEmailsOrders: [],
  splitNotificationsEmailsOptions: [],
};
