import { combineReducers } from 'redux';
import type { ClientWorkspaceState } from '../clientWorkspaceModel';
import { clientWorkspaceTabsReducer } from './clientWorkspaceTabsReducer';
import { clientWorkspaceActiveTabReducer } from './clientWorkspaceActiveTabReducer';
import { clientWorkspaceTilesReducer } from './clientWorkspaceTilesReducer';
import { clientWorkspaceGrowlsReducer } from './clientWorkspaceGrowlsReducer';
import { tradingDisabledReducer } from './clientWorkspaceTradingDisabledReducer';
import { saveStateReducer } from './clientWorkspaceSavingReducer';

export const clientWorkspaceReducer = combineReducers<ClientWorkspaceState>({
  tabs: clientWorkspaceTabsReducer,
  activeTab: clientWorkspaceActiveTabReducer,
  tiles: clientWorkspaceTilesReducer,
  growls: clientWorkspaceGrowlsReducer,
  tradingDisabled: tradingDisabledReducer,
  saveState: saveStateReducer,
});
