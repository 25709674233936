import type { Thunk } from 'state';
import { assertUnreachable, isEmpty } from '@sgme/fp';
import { parse, format, isFuture } from 'date-fns';
import type { FxOptionLegInputs } from '../model/optionsLegs';

interface CellPosition {
  legIndex: number;
  propertyIndex: number;
}

interface LegValue {
  position: CellPosition;
  value: string;
}

export function optionPastedValuesThunk(
  quoteId: string,
  legValues: readonly LegValue[],
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    if (isEmpty(legValues)) {
      return;
    }

    dispatch(ac.optionCopyPasted()); // used only for matomo

    const toProperty = (line: number) =>
      line === 0 ? 'expiryDate' : line === 1 ? 'strike' : 'notionalAmount';
    const state = getState();
    const legIds = sl.getLegIdsOfMultilegOfOption(state, quoteId);

    const patch = legValues.reduce((prev, { value, position: { legIndex, propertyIndex } }) => {
      const legId = legIds[legIndex];
      /** @todo paste needs to be fixed for typed strategies in table view */
      const propertyName = toProperty(propertyIndex);
      switch (propertyName) {
        case 'expiryDate':
          return {
            ...prev,
            [legId]: {
              ...prev[legId],
              expiryDate: pastedValueToDate(value),
            },
          };
        case 'strike':
          pastedValueToNumber(value);
          return {
            ...prev,
            [legId]: {
              ...prev[legId],
              strike: pastedValueToNumber(value),
            },
          };
        case 'notionalAmount':
          return {
            ...prev,
            [legId]: {
              ...prev[legId],
              notionalAmount: pastedValueToNumber(value),
              notionalCurrency: sl.getOptionVanillaLegNotionalCurrency(state, legId).value,
            },
          };
        default:
          assertUnreachable(propertyName, 'Unexpected column');
      }
    }, {} as Record<string, Partial<FxOptionLegInputs>>);

    dispatch(ac.optionLegsPropertyChanged(quoteId, patch));
  };
}

// Saparator is consider to be a decimal separator when it appears only once, one or two digits before the end,
const manageSeparator = (separator: string) => {
  const searchRegExp = new RegExp(separator === '.' ? '\\.' : separator, 'g');
  return (value: string) => {
    if (value.split(searchRegExp).length > 2) {
      return value.replace(searchRegExp, '');
    }
    const index = value.indexOf(separator);
    if (index === -1) {
      return value;
    } else if (index + 1 === value.length - 2) {
      return value.replace(searchRegExp, '.');
    } else if (index + 1 === value.length - 1) {
      return value.replace(searchRegExp, '.') + '0';
    } else {
      return value.replace(searchRegExp, '');
    }
  };
};

const manageCommaSeparator = manageSeparator(',');
const managePointSeparator = manageSeparator('.');

const pastedValueToNumber = (pastedValue: string) =>
  [manageCommaSeparator, managePointSeparator].reduce(
    (acc, fct) => fct(acc),
    pastedValue.replace(/ /g, ''),
  );

const possibleFormats = [
  'yyyy-MM-dd',
  'yy-MM-dd',
  'dd-MM-yyyy',
  'dd-MM-yy',
  'MM-dd-yyyy',
  'MM-dd-yy',
  'yyyy-dd-MM',
  'yy-dd-MM',
];
const pastedValueToDate = (pastedValue: string) => {
  const valueRef = pastedValue.replace(/\//g, '-');
  const now = new Date();
  for (const possibleFormat of possibleFormats) {
    const parsedDate = parse(valueRef, possibleFormat, now);
    if (isFuture(parsedDate)) {
      return format(parsedDate, 'yyyy-MM-dd');
    }
  }
  return pastedValue;
};
