import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { logger } from 'logging/logger';

export interface IEventLogApi {
  send: (request: { type: LogLevel; data: EventLog }) => Observable<never>;
  msApiTimeout: number;
}
export const eventLogApi = (http: ISgmeHttp): IEventLogApi => {
  const api: IEventLogApi = {
    send: request => {
      let headers = {};
      // for cloud, we need sg-connect
      try {
        const bearer = window.sgwtConnect.getAuthorizationHeader();
        headers = { Authorization: bearer };
      } catch (error: any) {
        logger.logError('Error when retrieved authorization header {message_s}', error.message);
      }
      return http.post<void>('api/eventLog', request, headers).pipe(ignoreElements());
    },
    msApiTimeout: 5000,
  };
  return api;
};

export type LogActionType = 'Execution';
export type LogLevel = 'info' | 'warn' | 'error';
export interface PerformanceLog {
  login: string;
  userAction: 'Performance';
  pingHttpMs: number;
  pingStreamingMs: number;
  deltaHttpStreamingMs: number;
  busySinceLastMs: number;
}

export interface HttpPerformanceLog {
  login: string;
  userAction: 'HttpPerformance';
  requestType: string;
  requestId: string;
  timeSpentInMs: number;
}

export interface HistogramLog {
  userAction: 'Histogram';
  name: string;
  count: number;
  mean: number;
  p90: number;
  p99: number;
  p999: number;
  max: number;
}

export interface FunctionalEventLog {
  login: string;
  instrument: LogInstrumentType;
  userAction: LogActionType;
  correlationId: string;
  dealInfo: LogDealInfo;
  actionOutcome: LogActionOutcomeType;
  additionalData?: { [key: string]: any };
}

export interface MemoryLog {
  login: string;
  userAction: 'Memory';
  memoryUsage: number;
}

export type EventLog =
  | FunctionalEventLog
  | PerformanceLog
  | HistogramLog
  | MemoryLog
  | HttpPerformanceLog;

export type LogInstrumentType =
  | 'Unknown'
  | 'Spot'
  | 'Forward'
  | 'Swap'
  | 'Option'
  | 'Bulk'
  | 'Order'
  | 'Accumulator'
  | 'AmericanForward';

export interface LogDealInfo {
  clientName: string;
  clientId: string;
  currency1: string;
  currency2: string;
  legs: readonly LogDealInfoLeg[];
}

export interface LogDealInfoLeg {
  index: string;
  negotiatedAmount: number;
  negotiatedCurrency: string;
}

export type LogActionOutcomeType = 'Success' | 'Failure' | 'Pending';
