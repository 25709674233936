export const SPOT_TENOR = 'SP';

export const DEFAULT_TENORS = [
  'TOD',
  'TOM',
  'ON',
  SPOT_TENOR,
  'TN',
  'SN',
  '1W',
  '2W',
  '3W',
  '1M',
  '2M',
  '3M',
  '6M',
  '9M',
  '12M',
  '18M',
  '2Y',
  '3Y',
  '4Y',
  '5Y',
  '10Y', // not in PREMIUM_DATE_WITH_IN_FINE_TENORS
  'IMM1',
  'IMM2',
  'BMF',
];

export const PREMIUM_DATE_WITH_IN_FINE_TENORS = [
  'in fine', // not in DEFAULT_TENORS
  'TOD',
  'TOM',
  'ON',
  SPOT_TENOR,
  'TN',
  'SN',
  '1W',
  '2W',
  '3W',
  '1M',
  '2M',
  '3M',
  '6M',
  '9M',
  '12M',
  '18M',
  '2Y',
  '3Y',
  '4Y',
  '5Y',
  'IMM1',
  'IMM2',
  'BMF',
];
