import type { AppState } from 'state/model';
import { getTileMaxDate } from 'state/referenceData/referenceDataSelectors';
import { getAccumulatorState } from './accumulator';
import { getAccumulatorPriceCurrency } from './accumulatorInput';
import { getAccumulatorRfsPremiumByQuoteId } from './accumulatorRfsByQuoteId';

export function isValidDateAccumulator(currentState: AppState, accuId: string) {
  const state = getAccumulatorState(currentState, accuId);
  const maxDate = getTileMaxDate(currentState, accuId);
  return (
    !state.values.expiryDate ||
    maxDate === undefined ||
    new Date(state.values.expiryDate) <= maxDate
  );
}
export const getPremiumSign = (state: AppState, quoteId: string) => {
  const premium = getAccumulatorRfsPremiumByQuoteId(state, quoteId);
  const priceCurrency = getAccumulatorPriceCurrency(state, quoteId);

  const priceCurrencyPremium = premium[priceCurrency.value - 1];
  return priceCurrencyPremium.ask > 0;
};
