import type { Updater } from '../typings';
import type {
  SavedWorkspace as SavedWorkspace7,
  OptionSavedTile as OptionSavedTile7,
  OptionVanillaLegSavedTile,
  OptionTypedStrategyLegSavedTile,
} from '../updater7/model';
import type {
  SavedBlotter,
  SavedWorkspace as SavedWorkspace8,
  OptionSavedTile as OptionSavedTile8,
} from './model';
import type { Collection } from '../../../../typings/utils';

const currentVersion = 8;

export const updater8: Updater<SavedWorkspace7, SavedWorkspace8> = {
  version: currentVersion,
  canUpdate(data: any) {
    return data.version === currentVersion - 1;
  },
  update: (previousWorkspace: SavedWorkspace7): SavedWorkspace8 => {
    const savedWorkspace: SavedWorkspace8 = {
      ...previousWorkspace,
      blotter: previousWorkspace.blotter as SavedBlotter,
      version: currentVersion,
      tabs: updateOptionTiles(previousWorkspace.tabs),
    };

    return savedWorkspace;
  },
};

function updateOptionTiles(tabs: SavedWorkspace7['tabs']): SavedWorkspace8['tabs'] {
  return tabs.map(tab => ({
    ...tab,
    tiles: tab.tiles.map(tile =>
      tile.instrument === 'Option'
        ? {
            ...tile,
            legIds: ['0'],
            legs: updateOptionTileLegs(tile.legs),
          }
        : tile,
    ),
  }));
}
export function isTypedLeg(
  leg: OptionVanillaLegSavedTile | OptionTypedStrategyLegSavedTile,
): leg is OptionTypedStrategyLegSavedTile {
  return leg.productName !== 'Vanilla';
}

const computeVanillaLegs = (
  subLegs: Record<string, OptionVanillaLegSavedTile>,
  parentKey: string,
) =>
  Object.entries(subLegs).reduce((acc, [way, leg]) => {
    acc[`${parentKey}/${way}`] = leg;
    return acc;
  }, {} as Collection<OptionVanillaLegSavedTile>);

function updateOptionTileLegs(legs: OptionSavedTile7['legs'] = []): OptionSavedTile8['legs'] {
  let updatedLegs = {};
  updatedLegs = {
    ['0']: {
      productName: 'FxOptionMultileg',
      legIds: isTypedLeg(legs[0]) ? ['0/0'] : legs.map((_leg, index) => `0/${index}`),
    },
  };

  if (isTypedLeg(legs[0])) {
    const typedLeg = {
      ['0/0']: {
        productName: legs[0].productName,
        legIds: ['0/0/call', '0/0/put'],
      },
    };

    const vanillaLegs = computeVanillaLegs(legs[0].subLegs, `0/0`);

    updatedLegs = {
      ...updatedLegs,
      ...typedLeg,
      ...vanillaLegs,
    };
  } else {
    const legsWithIndexKey = (legs as OptionVanillaLegSavedTile[]).reduce((acc, leg, index) => {
      acc[index] = leg;
      return acc;
    }, {} as Record<string, OptionVanillaLegSavedTile>);

    const vanillaLegs = computeVanillaLegs(legsWithIndexKey, `0`);

    updatedLegs = {
      ...updatedLegs,
      ...vanillaLegs,
    };
  }

  return updatedLegs;
}
