import { getTheme, type Theme } from 'utils/theme';
import { noop } from '@sgme/fp';
import { createContext } from 'react';

interface ThemeProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeProps>({
  theme: getTheme(),
  toggleTheme: noop,
  setTheme: noop,
});
ThemeContext.displayName = 'ThemeContext';
export const { Provider: ThemeProvider } = ThemeContext; // Only expose provider to force using hooks API
