import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import {
  type Client,
  type CurrencyPair,
  type CurrencyPairData,
  defaultUserInfo,
  type IndexedCurrencyPairs,
  type ReferenceDataState,
} from './referenceDataModel';
import { type ClosedDates, defaultFeatureToggles, defaultProductsAccess } from 'state/referenceData/referenceDataModel';
import { currencies } from 'data/currencies';
import { strictEntries } from 'utils/object';
import { isEmpty } from '@sgme/fp';

export const initialState: ReferenceDataState = {
  instruments: [],
  ccyPairs: addCurrencyPairRuntimeFields(currencies),
  userInfo: defaultUserInfo,
  userClients: {},
  /**
   * default in model
   */
  featureToggles: defaultFeatureToggles,
  productsAccess: defaultProductsAccess,
  espLimits: {},
  localMarketsCcy: [],
  pinnedCompanies: [],
};

export const referenceDataReducer: Reducer<ReferenceDataState> = (
  state = initialState,
  action: Action,
): ReferenceDataState => {
  switch (action.type) {
    case 'REFERENCE_DATA_RECEIVED':
      // user needs both toggleFeature and option access as smartRfs only support Option creation
      // this works for now as a POC but the product access should be checked later on when we have smartRfs TC response
      // to be able to take the decision based on response product type
      const hasSmartRfsAccess = action.featureToggles.smartRFS && action.productsAccess.option;

      return {
        ...state,
        instruments: [...action.productTypes, ...(hasSmartRfsAccess ? (['SmartRfs'] as const) : [])],
        userInfo: action.userInfo,
        userClients: toClientsMap(action.userClients),
        featureToggles: action.featureToggles,
        productsAccess: action.productsAccess,
        localMarketsCcy: action.localMarketsCcy || [],
        ccyPairs: updateCcyPairsWithLocalMarket(action.localMarketsCcy || [], state.ccyPairs),
      };
    case 'CLOSED_DATES_RETRIEVED':
      return {
        ...state,
        ccyPairs: updateClosedDates(state.ccyPairs, action.closedDates),
      };

    case 'MAX_DATE_SET':
      const existingCcyPair = state.ccyPairs[action.currencyPair];
      return {
        ...state,
        ccyPairs: {
          ...state.ccyPairs,
          [action.currencyPair]: {
            ...existingCcyPair,
            tenorDatesCache: {
              ...existingCcyPair.tenorDatesCache,
              [action.maxTenor]: action.maxDate,
            },
          },
        },
      };

    case 'ESP_LIMITS_ADD_CURRENCIES':
      const newCurrencies = action.currencies.filter(ccy => !state.espLimits.hasOwnProperty(ccy));
      if (isEmpty(newCurrencies)) {
        return state;
      }
      return {
        ...state,
        espLimits: newCurrencies.reduce(
          (acc, val) => ({
            ...acc,
            [val]: undefined,
          }),
          state.espLimits,
        ),
      };
    case 'ESP_LIMITS_RETRIEVED':
      return {
        ...state,
        espLimits: { ...state.espLimits, ...action.limits },
      };
    case 'PIN_CLIENT':
      return {
        ...state,
        pinnedCompanies: [...state.pinnedCompanies, action.companyId],
      };
    case 'UNPIN_CLIENT':
      return {
        ...state,
        pinnedCompanies: state.pinnedCompanies.filter(c => c !== action.companyId),
      };

    case 'MOVE_UP_PINNED_CLIENT':
      return {
        ...state,
        pinnedCompanies: swapElements(
          state.pinnedCompanies,
          state.pinnedCompanies.indexOf(action.companyId),
          state.pinnedCompanies.indexOf(action.companyId) - 1,
        ),
      };

    case 'MOVE_DOWN_PINNED_CLIENT':
      return {
        ...state,
        pinnedCompanies: swapElements(
          state.pinnedCompanies,
          state.pinnedCompanies.indexOf(action.companyId),
          state.pinnedCompanies.indexOf(action.companyId) + 1,
        ),
      };

    case 'RETRIEVE_PINNED_CLIENT':
      return {
        ...state,
        pinnedCompanies: action.clients,
      };

    default:
      return state;
  }
};

function swapElements(pinnedCompanies: readonly number[], index: number, newIndex: number) {
  if (newIndex >= pinnedCompanies.length || newIndex < 0) {
    return pinnedCompanies;
  }

  const pinneds = [...pinnedCompanies];
  const tmp = pinneds[newIndex];
  pinneds[newIndex] = pinneds[index];
  pinneds[index] = tmp;
  return pinneds;
}

export function updateCcyPairsWithLocalMarket(
  localMarketsCcy: readonly string[],
  ccyPairs: Readonly<IndexedCurrencyPairs>,
) {
  const ccyPairsKey = Object.keys(ccyPairs);

  return ccyPairsKey
    .map(ccy => ccyPairs[ccy])
    .map(ccyPair => {
      if (localMarketsCcy.includes(ccyPair.ccy1) || localMarketsCcy.includes(ccyPair.ccy2)) {
        return {
          ...ccyPair,
          canBeDelivered: true,
        };
      }
      return ccyPair;
    })
    .reduce((acc, current) => {
      acc[current.pair] = current;
      return acc;
    }, {} as Record<CurrencyPair['pair'], CurrencyPair>);
}

function toClientsMap(clients: readonly Client[]) {
  return clients.reduce((map, client) => {
    map[client.companyId] = client;
    return map;
  }, {} as Record<number, Client>);
}

function updateClosedDates(existingPairs: Readonly<IndexedCurrencyPairs>, closedDates: ClosedDates) {
  return Object.entries(closedDates || {}).reduce(
    (acc, [pair, dates]) => ({
      ...acc,
      [pair]: {
        ...acc[pair],
        closedDates: dates,
      },
    }),
    existingPairs,
  );
}

function addCurrencyPairRuntimeFields(
  currenciesData: Record<CurrencyPair['pair'], CurrencyPairData>,
): Record<CurrencyPair['pair'], CurrencyPair> {
  return Object.fromEntries(
    strictEntries(currenciesData).map(([pair, ccyPairData]) => [
      pair,
      {
        ...ccyPairData,
        closedDates: [],
        tenorDatesCache: {},
      },
    ]),
  );
}
