import type {
  BidAsk,
  BidAskPair,
  CurrencyChoice,
  Possible,
  Way,
} from 'state/share/productModel/litterals';
import type { IFxExecutionData, IFxExecutionState } from 'state/executions/executionsStateModel';
import type { PredealCheck } from 'state/share/predealChecksModel';
import type { Collection } from 'typings/utils';
import type { IFormData } from 'state/share/form';
import type { IFxTileMetadata } from '../tile/fxTileModel';
import type { Patch } from '../share/patchModels';

export type SwapProductName = 'FxSwap' | 'FxNdSwap' | 'FxRollover' | 'FxPredeliver';
// state types
// all properties are sent to TC - put transient info in metadata

// //////////////////////////////////////////////////////////////////
// Swaps
// /////////////////////////////////////////////////////////////////

/**
 * @todo audit why so many non inputable properties
 */
export interface IFxSwapValues {
  // TC non inputable
  productName: SwapProductName;
  currency1: string | null;
  currency2: string | null;
  nearPaymentDateTenor: string | null;
  nearFixingDate: string | null;
  farFixingDate: string | null;
  possibleFixingSources: Possible<string> | null;
  farPaymentDateTenor: string | null;
  fixingCurrency: string | null;
  isCrossed: boolean | null;
  possibleXCurrencies: Possible<string> | null;
  possibleSndFixingSources: Possible<string> | null;
  nearAmount: number | null;
  farAmount: number | null;
  // TC inputable
  isNonDeliverable: boolean;
  currencyPair: string | null;
  isUneven: boolean;
  isOffMarket: boolean;
  amountCurrency: CurrencyChoice;
  nearPaymentDate: string | null;
  farPaymentDate: string | null;
  fixingSource: string | null;
  xCurrency: string | null;
  sndFixingSource: string | null;
  nearPriceReference: BidAsk<number> | null;
  farPriceReference: BidAsk<number> | null;
}

export interface IFxSwapInputs {
  isNonDeliverable: boolean;
  currencyPair: string | null;
  isUneven: boolean;
  isOffMarket: boolean;
  amountCurrency: CurrencyChoice;
  nearPaymentDate: string | null;
  nearPaymentDateTenor: string | null;
  farPaymentDate: string | null;
  farPaymentDateTenor: string | null;
  fixingSource: string | null;
  xCurrency: string | null;
  sndFixingSource: string | null;
  farAmount: string | null;
  nearAmount: string | null;
  nearPriceReference: BidAsk<string> | null;
  farPriceReference: BidAsk<string> | null;
}

export type IFxSwapProperties = IFormData<IFxSwapValues, IFxSwapInputs>;

export type SwapOffMarketType = 'predeliver' | 'rollover';

export interface IFxSwapMetadata extends IFxTileMetadata {
  /**
   * @todo audit: share or delete
   */
  isPriceObsolete: boolean;
  askMargin: number | null;
  bidMargin: number | null;
  askSpotMargin: number | null;
  bidSpotMargin: number | null;
  markupCurrency: CurrencyChoice;
}

export type FxSwapState = Readonly<IFxSwapMetadata & IFxSwapProperties>;

export const emptyFxSwapState: FxSwapState = {
  values: {
    productName: 'FxSwap',
    isNonDeliverable: false,
    currencyPair: null,
    currency1: null,
    currency2: null,
    nearAmount: null,
    amountCurrency: 1,
    nearPaymentDate: null,
    nearPaymentDateTenor: null,
    farAmount: null,
    farPaymentDate: null,
    farPaymentDateTenor: null,
    isUneven: false,
    isOffMarket: false,
    fixingSource: null,
    nearFixingDate: null,
    farFixingDate: null,
    fixingCurrency: null,
    possibleFixingSources: null,
    isCrossed: null,
    possibleSndFixingSources: null,
    possibleXCurrencies: null,
    sndFixingSource: null,
    xCurrency: null,
    nearPriceReference: null,
    farPriceReference: null,
  },
  errors: {},
  warnings: {},
  inputs: {},
  propertiesRequested: false,
  currentSessionId: null,
  propertiesRequestError: null,
  tradeCaptureIdVersion: null,
  currentStreamId: null,
  currentEspStreamId: null,
  isPriceable: false,
  isPriceObsolete: false,
  lastStreamError: null,
  askMargin: null,
  bidMargin: null,
  askSpotMargin: null,
  bidSpotMargin: null,
  markupCurrency: 2,
  lastExecutedQuoteId: null,
  currentExecutionId: null,
  rfsStartedAt: null,
  priceRecords: [],
};

export type IFxSwapStateMap = Collection<FxSwapState>;
export type FxSwapStateMap = Readonly<IFxSwapStateMap>;
// //////////////////////////////////////////////////////////////////
// Executions
// /////////////////////////////////////////////////////////////////
export interface IFxSwapExecutionData extends IFxExecutionData {
  instrument: 'Swap';
  productName: SwapProductName;
  way: Way;
  swapPoints: number;
  nearNotionalAmount: number;
  farNotionalAmount: number;
  notionalCurrency: string;
  nearPrice: number;
  farPrice: number;
  nearDate: Date;
  farDate: Date;
  precision: number;
}

export type FxSwapExecutionState = IFxExecutionState & IFxSwapExecutionData;

// //////////////////////////////////////////////////////////////////
// Streams
// /////////////////////////////////////////////////////////////////

interface IFxSwapStreamAwaitingState {
  status: 'AWAITING';
}

export type QuoteType = 'Tradeable' | 'Withdraw';

export interface IQuote {
  quoteId: string;
  quoteType: QuoteType;
  rfsWindow: number;
  frontTimestamp: Date;
  backTimestamp: number;
  defaultMargin: BidAskPair;
  defaultSpotMargin: BidAskPair;
  spot: BidAskPair;
  nearPoints: BidAskPair;
  nearSalesPoints: BidAskPair;
  farPoints: BidAskPair;
  farMktPoints: BidAskPair;
  nearSalesAllInRate: BidAskPair;
  farSalesAllInRate: BidAskPair;
  nearDf2: number;
  farDf2: number;
  midAllInRate: number;
  delta: number;
  traderId: string | null;
  predealChecks: readonly PredealCheck[];
}

interface IFxSwapStreamStreamingState {
  status: 'PRICING';
  quote: Readonly<IQuote>;
  initialRfsWindow: number;
  tiering: string | null;
}

export type IFxSwapStreamState = IFxSwapStreamAwaitingState | IFxSwapStreamStreamingState;

export type FxSwapStreamState = Readonly<IFxSwapStreamState>;

type IFxSwapStreamStateMap = Collection<FxSwapStreamState>;

export type FxSwapStreamStateMap = Readonly<IFxSwapStreamStateMap>;

// //////////////////////////////////////////////////////////////////
// Combine state
// /////////////////////////////////////////////////////////////////
export type FxSwapPatch = {
  productName: SwapProductName;
} & Patch<IFxSwapValues>;

export interface IFxSwapsState {
  swaps: FxSwapStateMap;
  streams: FxSwapStreamStateMap;
}
export type FxSwapsState = Readonly<IFxSwapsState>;

export const emptyFxSwapsState: FxSwapsState = {
  swaps: {},
  streams: {},
};

export interface SwapTileOpenFromBlotter {
  instrument: 'Swap';
  productName: SwapProductName;
  isNonDeliverable: boolean;
  currencyPair?: string | null;
  amountCurrency?: CurrencyChoice;
  nearAmount?: number;
  farAmount?: number;
  nearPaymentDateTenor?: string;
  farPaymentDateTenor?: string;
}

export interface PatchLegs {
  currencyPair?: string | null;
  legs: Partial<PatchLeg>;
}

export interface PatchLeg {
  // swap legId
  0: Partial<IFxSwapInputs>;
}
