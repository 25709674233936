import type { Thunk } from 'state';

export function bulkExecutionRequestedThunk(bulkId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const canTrade = sl.isCanTradeState(state);
    if (canTrade !== true) {
      return;
    }

    const streamId = sl.getBulkCurrentStreamId(state, bulkId);
    if (streamId == null) {
      return;
    }

    dispatch(ac.bulkExecutionRequestedEpic(bulkId, streamId));
  };
}

export function bulkRestartThunk(bulkId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const executionId = sl.getTileCurrentExecutionId(getState(), bulkId);
    dispatch(ac.bulkRestart(bulkId));
    if (executionId !== null) {
      dispatch(ac.executionCleanup(executionId));
    }
  };
}
