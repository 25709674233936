import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { addKey, removeKey, removeKeys, unpatchedEntries, updateKey } from 'utils/stateMap';

import {
  addTileIdToLegKey,
  createEmptyFxOptionMultilegState,
  createEmptyFxOptionsState,
  createEmptyFxOptionVanillaLegState,
  createEmptyTypedLegState,
  emptyFxOptionState,
  isFxOptionMultilegSavedTile,
  isVanillaLeg,
  isVanillaLegSavedTile,
  updateLegIdsWithNewTileId
} from '../utilities';

import { getLegKey } from 'state/share/patchHelper';
import type { IPatch } from 'state/share/patchModels';
import type { Collection } from 'typings/utils';
import { strictEntries } from 'utils/object/entries';
import type {
  OptionLegSavedTile,
  OptionMultilegSavedTile,
  OptionTypedStrategyLegSavedTile,
} from '../../../api/workspaceService/updaters/updater9/model';
import { clearUndefined } from '../../../utils/clearUndefined';
import type { PropertyErrors } from '../../share/productModel/litterals';
import {
  defaultSolvingFor,
  type FxOptionLegInputs,
  type FxOptionLegsPatchedValuesRecords,
  type FxOptionLegState,
  type FxOptionLegStateMap,
  type FxOptionMultilegState,
  type IFxVanillaLegValues,
  type OptionSolvableValue,
  type TypedLegState,
  type VanillaLegState,
} from '../model/optionsLegs';

export const optionsLegsReducer: Reducer<FxOptionLegStateMap> = (
  state: FxOptionLegStateMap = {},
  action: Action,
): FxOptionLegStateMap => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      if (action.savedTile.instrument === 'Option') {
        const tileId = action.tileId;

        return Object.entries(action.savedTile.legs).reduce(
          (acc, [legKey, leg]) => {
            if (isVanillaLegSavedTile(leg as OptionLegSavedTile)) {
              acc[addTileIdToLegKey(tileId, legKey)] = createEmptyFxOptionVanillaLegState();
            } else if (isFxOptionMultilegSavedTile(leg as OptionLegSavedTile)) {
              acc[addTileIdToLegKey(tileId, legKey)] = createEmptyFxOptionMultilegState(
                updateLegIdsWithNewTileId(tileId, (leg as OptionMultilegSavedTile).legIds),
              );
            }
            // we are in typed Leg
            else {
              acc[addTileIdToLegKey(action.tileId, legKey)] = createEmptyTypedLegState(
                (leg as OptionTypedStrategyLegSavedTile).productName,
                updateLegIdsWithNewTileId(tileId, (leg as OptionTypedStrategyLegSavedTile).legIds),
              );
            }

            return acc;
          },
          { ...state },
        );
      } else {
        return state;
      }

    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      if (action.instrument === 'Option') {
        const multilegId = `${action.tileId}/0`;
        const vanillaLegId = `${action.tileId}/0/0`;

        const stateWithMultileg = addKey(state, multilegId, createEmptyFxOptionMultilegState([vanillaLegId]));

        return addKey(stateWithMultileg, vanillaLegId, createEmptyFxOptionVanillaLegState());
      }

      return state;

    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
      if (action.instrument === 'Option') {
        return getLegKeysForOption(state, action.originalTileId).reduce((stateAcc, currentLegKey) => {
          const currentLegId = currentLegKey.replace(`${action.originalTileId}/`, '');
          return addKey(stateAcc, getLegKey(action.tileId, currentLegId), createEmptyFxOptionVanillaLegState());
        }, state);
      } else {
        return state;
      }

    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
      return action.instrument === 'Option'
        ? createEmptyFxOptionsState(state, action.tileId)
        : removeKeys(state, getLegKeysForOption(state, action.tileId));

    case 'CLIENTWORKSPACE_TILE_DELETED':
      return removeKeys(state, getLegKeysForOption(state, action.tileId));

    case 'OPTION_TILE_RESET': {
      // OPTION_TILE_RESET is trigger with dispatch(partialResetTileThunk)
      // it's set currencyPair for the option just after the reset
      const cleanState = removeKeys(state, getLegKeysForOption(state, action.quoteId));
      return createEmptyFxOptionsState(cleanState, action.quoteId)
    }
    case 'OPTION_NOTIONAL_CURRENCY_CHANGED':
      return action.legsId.reduce(
        (stateAcc, legId) =>
          updateKey(stateAcc, legId, (leg) => {
            if (isVanillaLeg(leg)) {
              return {
                values: {
                  ...leg.values,
                  notionalCurrency: action.notionalCurrency,
                },
              };
            } else {
              return leg;
            }
          }),
        state,
      );

    case 'OPTION_LEGS_PROPERTY_CHANGED':
      return Object.entries(action.patch).reduce(
        (stateAcc, [legId, legPatch]) => updateLeg(stateAcc, action.optionId, legId, legPatch),
        state,
      );

    case 'OPTION_STRIKE_SOLVED':
      return action.strikes.reduce(
        (stateAcc, [legId, strike]) =>
          updateKey(stateAcc, legId, (leg) => {
            if (!isVanillaLeg(leg) || strike === null) {
              return leg;
            }

            const updatedVanilla: VanillaLegState = {
              ...leg,
              values: {
                ...leg.values,

                solvable: defaultSolvingFor,
                // TODO: ask Sylvain for those values
                premiumBid: null,
                premiumAsk: null,
              },
              inputs: {
                strike,
              },
              solved: 'Strike',
              solvingFor: 'PremiumPaymentAmount',
            };

            return updatedVanilla;
          }),
        state,
      );

    case 'OPTION_LEG_PROPERTY_CHANGED':
      return updateLeg(state, action.optionId, action.legId, action.patch);

    case 'OPTION_LEG_PROPERTY_REMOVE_ERROR':
      return updateKey(state, action.legId, (leg) => {
        const errors = strictEntries(leg.errors as PropertyErrors<IFxVanillaLegValues>) // cast because TS has an issue to handle union types here
          .filter(([errorKey]) => !action.keys.includes(errorKey))
          .reduce(
            (newErrors, [key, legError]) => ({
              ...newErrors,
              [key]: legError,
            }),
            {},
          );
        return {
          ...leg,
          errors,
        };
      });

    case 'OPTION_PROPERTIES_RECEIVED':
      return patchOptionLegs(state, action.quoteId, action.legsPatch);

    case 'LOCAL_LEG_FIELD_VALIDATION_SET':
      return action.instrument !== 'Option'
        ? state
        : updateKey(state, getLegKey(action.quoteId, action.legId), ({ errors, warnings }) => {
            const validation = {
              code: action.messageId,
              userNotified: false,
            };
            return action.validationStatus === 'warning'
              ? { warnings: { ...warnings, [action.field]: validation } }
              : { errors: { ...errors, [action.field]: validation } };
          });

    case 'LOCAL_LEG_FIELD_VALIDATION_CLEAR':
      return action.instrument !== 'Option'
        ? state
        : updateKey(state, getLegKey(action.quoteId, action.legId), ({ errors, warnings }) => ({
            errors: removeKey(errors, action.field),
            warnings: removeKey(warnings, action.field),
          }));

    case 'LEG_FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Option'
        ? state
        : updateKey(state, getLegKey(action.quoteId, action.legId), ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));

    case 'OPTION_STRATEGY_TYPE_CHANGED':
      const legIdKeys = getLegKeysForOption(state, action.optionId);
      const notionalCurrency = createEmptyFxOptionVanillaLegState().values.notionalCurrency;

      const multilegId = legIdKeys.find((legId) => /^[^/]+\/[^/]+$/.test(legId)) as string;
      const childrenLegIds = legIdKeys.filter((legId) => new RegExp(`^${multilegId}\/[^/]+$`).test(legId));

      if (childrenLegIds.length > 1) {
        return state;
      }

      return legIdKeys.reduce(
        (stateAcc, legId) =>
          updateKey(stateAcc, legId, (leg) => ({
            values: isVanillaLeg(leg)
              ? {
                  ...leg.values,
                  expiryDate: null,
                  expiryDateTenor: null,
                  strike: null,
                  notionalAmount: null,
                  notionalCurrency,
                  settlementType: leg.values.possibleSettlementTypes?.default ?? null,
                  marketPlace: leg.values.possibleMarketPlaces?.default ?? null,
                  fixingReference1: null,
                  /** @todo use user prefs values instead of emptyFxOptionState */
                  premiumTypeString: emptyFxOptionState.displayPriceType[1],
                  premiumCurrency: emptyFxOptionState.displayPriceType[0],
                  deliveryDate: null,
                  deliveryDateTenor: null,
                }
              : leg.values,
            inputs: legId === childrenLegIds[0] ? { productName: action.strategyType } : {},
            errors: {},
            warnings: {},
          })),
        state,
      );

    case 'OPTION_LEG_ADDED':
      // Solving is not supported for multilegs, so we reset solvingFor field for now
      const allLegIds = getLegKeysForOption(state, action.optionId);
      return allLegIds.reduce(
        (stateAcc, legId) =>
          updateKey(stateAcc, legId, (leg) => ({
            ...leg,
            solvingFor: defaultSolvingFor,
          })),
        state,
      );

    case 'OPTION_SOLVING_WANTED':
      const firstLeg = getFirstLegOfMultilegOfOption(state, action.quoteId);

      return isAlreadyStrikeSolving(state, action.quoteId) || firstLeg.productName === 'Straddle'
        ? state
        : updateKey(state, action.legId, (leg) => {
            const solvingFor: OptionSolvableValue = action.field === 'strike' ? 'Strike' : defaultSolvingFor;

            if (isVanillaLeg(leg)) {
              return {
                solvingFor,
              };
            }

            return leg;
          });

    default:
      return state;
  }
};

function fillLeg<OptionLegState extends FxOptionLegState>(
  emptyLeg: OptionLegState,
  legPatch: IPatch<OptionLegState['values']>,
): OptionLegState {
  return {
    ...emptyLeg,
    ...legPatch,
    values: {
      ...emptyLeg.values,
      ...legPatch.values,
    },
    errors: legPatch.errors,
    warnings: legPatch.warnings,
  };
}

function patchLeg<OptionLegState extends FxOptionLegState>(
  existingLeg: OptionLegState,
  legPatch: IPatch<OptionLegState['values']>,
): OptionLegState {
  return {
    ...existingLeg,
    ...legPatch,
    inputs: {},
    values: {
      ...existingLeg.values,
      ...legPatch.values,
    },
    errors: {
      ...unpatchedEntries(existingLeg.errors as Collection<any>, legPatch.values), // TODO: how to avoid the as Collection<any> ??
      ...legPatch.errors,
    },
    warnings: {
      ...unpatchedEntries(existingLeg.warnings as Collection<any>, legPatch.values), // TODO: how to avoid the as Collection<any> ??
      ...legPatch.warnings,
    },
  };
}

export function patchOptionLegs(
  state: Collection<FxOptionLegState>,
  quoteId: string,
  legsPatch: FxOptionLegsPatchedValuesRecords,
): Collection<FxOptionLegState> {
  const patchLegIds = Object.keys(legsPatch);

  const quoteIdKey = `${quoteId}/`;
  const legsIdsToRemove = Object.keys(state).filter((id) => id.startsWith(quoteIdKey) && !patchLegIds.includes(id));

  const stateWithoutRemovedLegs = removeKeys(state, legsIdsToRemove);

  return Object.entries(legsPatch).reduce((stateAcc, [patchedLegId, legPatch]) => {
    const stateWithoutCurrentLeg = removeKey(stateAcc, patchedLegId);
    const currentLeg = stateAcc[patchedLegId];

    // leg creation
    if (
      currentLeg === undefined ||
      (legPatch.productName !== undefined && legPatch.productName !== currentLeg.productName)
    ) {
      const emptyFxOptionLegState =
        legPatch.productName === 'Vanilla'
          ? createEmptyFxOptionVanillaLegState()
          : ({
              values: {},
              inputs: {},
              errors: {},
              warnings: {},
            } as TypedLegState);

      return addKey(stateWithoutCurrentLeg, patchedLegId, fillLeg(emptyFxOptionLegState, legPatch));
    }
    // leg update
    const updatedLeg: FxOptionLegState = patchLeg(currentLeg, legPatch);

    return addKey(stateWithoutCurrentLeg, patchedLegId, updatedLeg);
  }, stateWithoutRemovedLegs);
}

const updateLeg = (
  state: FxOptionLegStateMap,
  optionId: string,
  legId: string,
  legPatch: Partial<FxOptionLegInputs>,
): FxOptionLegStateMap =>
  updateKey(state, legId, (leg) => {
    const firstLeg = getFirstLegOfMultilegOfOption(state, optionId);

    if (!isVanillaLeg(leg) || firstLeg.productName === 'Straddle') {
      return { ...leg, inputs: legPatch } as FxOptionLegState;
    }

    const legPatchWithoutStrikeSolvingDoublon =
      'strike' in legPatch && legPatch.strike === '?' && isAlreadyStrikeSolving(state, optionId)
        ? (removeKey(legPatch, 'strike') as Partial<FxOptionLegInputs>)
        : legPatch;

    return clearUndefined({
      ...leg,
      inputs: legPatchWithoutStrikeSolvingDoublon,
      solvingFor: !('strike' in legPatchWithoutStrikeSolvingDoublon)
        ? // disable solver if multileg
          undefined
        : getStrikeToSolvingFor(legPatchWithoutStrikeSolvingDoublon.strike),
      solved: null,
    } as VanillaLegState);
  });

function isAlreadyStrikeSolving(state: FxOptionLegStateMap, optionId: string) {
  return getAllVanillaLegsOfOption(state, optionId).some((leg) => leg.solvingFor === 'Strike');
}

// TODO: try to merge with fx-web/app/src/state/fxOptions/selectors/fxOptionsLegsSelectors.ts same functions
const getAllVanillaLegsOfOption = (state: FxOptionLegStateMap, optionId: string): VanillaLegState[] =>
  getAllLegsOfOption(state, optionId).filter(isVanillaLeg);

const getAllLegsOfOption = (state: FxOptionLegStateMap, optionId: string): FxOptionLegState[] =>
  getAllLegIdsOfOption(state, optionId).map((legId) => state[legId] as FxOptionLegState);

const getAllLegIdsOfOption = (state: FxOptionLegStateMap, optionId: string) => {
  const optionPrefixLegId = `${optionId}/`;

  return Object.keys(state).filter((legId) => legId.startsWith(optionPrefixLegId));
};

const getFirstLegOfMultilegOfOption = (state: FxOptionLegStateMap, optionId: string) => {
  const multileg = state[`${optionId}/0`] as FxOptionMultilegState; // TODO: we can't get the option to get the real id like fxOptionsLegsSelectors
  const [firstLegId] = multileg.legIds;

  return state[firstLegId] as Exclude<FxOptionLegState, FxOptionMultilegState>;
};

const getLegKeysForOption = (state: FxOptionLegStateMap, optionId: string) =>
  Object.keys(state).filter((optionLegId) => optionLegId.startsWith(optionId));

function getStrikeToSolvingFor(strike: string | undefined | null): OptionSolvableValue | undefined {
  switch (strike) {
    case '?':
      return 'Strike';
    case undefined:
    case null:
      return undefined;
    default:
      return 'PremiumPaymentAmount';
  }
}
