import type { TileInstrumentChanged } from 'state/clientWorkspace';
import { selectors } from 'state/selectors';
import { generatePathFromResponseCreator } from './tradeCaptureFromBackend';
import type { OptionStoreModelChanges } from './tradeCaptureModel';
import {
  legAddToPatchWith,
  legChangedToPatchWith,
  legGroupRemovedToPatchWith,
  legRemovedToPatchWith,
  legsChangedToPatchWith,
  legsPremiumPinToggledToPatchWith,
  legsImportedToPatchWith,
  optionPropertyChangedToPatchWith,
  optionTileResetToPatchWith,
  optionStrategyTypeChangedToPatchWith,
  optionStrikeSolvedToPatchWith,
  optionSolvingWantedToPatchWith,
  optionTileRestoreToPatchWith,
  legDuplicateToPatchWith,
  optionHedgePropertyChangedToPatchWith,
} from './tradeCapturePatchBuilderFromAction';
import { buildTradeCaptureRequestCreator } from './tradeCaptureToBackend';

export const optionResponseToPatchMetaSelector = generatePathFromResponseCreator(selectors);

export const createTradeCaptureRequest = buildTradeCaptureRequestCreator(selectors);

export const optionStrategyTypeChangedToPatch = optionStrategyTypeChangedToPatchWith(selectors);

export const legAddedToPatch = legAddToPatchWith(selectors);

export const legDuplicatedToPatch = legDuplicateToPatchWith(selectors);

export const legChangedToPatch = legChangedToPatchWith(selectors);

export const legsChangedToPatch = legsChangedToPatchWith(selectors);

export const optionStrikeSolvedToPatch = optionStrikeSolvedToPatchWith(selectors);

export const legsPremiumPinToggledToPatch = legsPremiumPinToggledToPatchWith(selectors);

export const legRemovedToPatch = legRemovedToPatchWith(selectors);

export const legGroupRemovedToPatch = legGroupRemovedToPatchWith(selectors);

export const legsImportedToPatch = legsImportedToPatchWith(selectors);

export const optionPropertyChangedToPatch = optionPropertyChangedToPatchWith(selectors);

export const optionHedgePropertyChangedToPatch = optionHedgePropertyChangedToPatchWith(selectors);

export const optionTileResetToPatch = optionTileResetToPatchWith(selectors);

export const optionSolvingWantedToPatch = optionSolvingWantedToPatchWith(selectors);

export const optionTileRestoreToPatch = optionTileRestoreToPatchWith(selectors);

export { optionTileOpenFromBlotterToPatch } from './tradeCapturePatchBuilderFromAction';

export const optionTileInstrumentChangedToPatch = ({
  tileId,
  currencyPair,
}: TileInstrumentChanged): [string, OptionStoreModelChanges] => [
  tileId,
  {
    currencyPair,
    legs: {
      0: {
        productName: 'FxOptionMultileg',
        legs: {
          0: {
            productName: 'Vanilla',
          },
        },
      },
    },
    hedges: {},
  },
];
