import { v4 as getNewGuid } from 'uuid';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import type { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { getApiUrl } from 'bootstrap/sgmeConfiguration';

const apiUrl = getApiUrl();

export interface HttpCall<T> {
  payload: T;
  timeTaken: number;
}

export const correlationIdHeaderName = 'X-Correlation-ID';

export const mockPost = () => of({ payload: {} as any, timeTaken: 0 });

export interface ISgmeHttp {
  post<T = {}>(url: string, body?: any, headers?: object): Observable<HttpCall<T>>;
  put<T = {}>(url: string, body?: any, headers?: object): Observable<HttpCall<T>>;
  getJSON<T>(url: string, headers?: object): Observable<HttpCall<T>>;
}

export interface IAjaxAlike {
  post(url: string, body?: any, headers?: object): Observable<AjaxResponse>;
  put(url: string, body?: any, headers?: object): Observable<AjaxResponse>;
  getJSON<T>(url: string, headers?: object): Observable<T>;
}

function buildContentTypeHeader() {
  return {
    'Content-Type': 'application/json',
  };
}

function buildCorrelationIdHeader(headers?: object) {
  return Object.keys(headers || {}).some(header => header === correlationIdHeaderName)
    ? undefined
    : { [correlationIdHeaderName]: getNewGuid() };
}

function buildFakeAuthenticationHeader(fakeUser: string) {
  return { 'X-Fake-Authentication': fakeUser };
}

function cleanUpSensitiveInformation(err?: AjaxError) {
  if (err !== undefined && err.request !== undefined && err.request.headers !== undefined) {
    const headers: any = err.request.headers;
    if (headers.Authorization !== undefined) {
      headers.Authorization = '*** SECRET ***';
    }
  }
  return err;
}

export function SgmeHttp(
  getAuthorizationHeader: () => string | null | never,
  ajaxApi: IAjaxAlike,
  fakeUser?: string,
): ISgmeHttp {
  const fakeAuthenticationHeader =
    fakeUser !== undefined && window.sgmeConfiguration.useFakeSgConnect
      ? buildFakeAuthenticationHeader(fakeUser)
      : undefined;

  function buildCommonHeaders(headers?: object) {
    return {
      ...headers,
      Application: 'FX',
      ...buildCorrelationIdHeader(headers),
      ...fakeAuthenticationHeader,
      Authorization: getAuthorizationHeader(),
    };
  }

  const buildGetHeaders = (headers?: object) => ({
    // don't put Content-Type in GET
    ...buildCommonHeaders(headers),
  });

  const buildPostHeaders = (headers?: object) => ({
    ...buildContentTypeHeader(),
    ...buildCommonHeaders(headers),
  });

  return {
    post<T>(url: string, body?: any, headers?: object): Observable<HttpCall<T>> {
      const start = performance.now();
      return ajaxApi.post(apiUrl + url, body, buildPostHeaders(headers)).pipe(
        map(r => {
          const end = performance.now();
          return {
            payload: r.response as T,
            timeTaken: end - start,
          };
        }),
        catchError(err => {
          throw cleanUpSensitiveInformation(err);
        }),
      );
    },
    put<T>(url: string, body?: any, headers?: object): Observable<HttpCall<T>> {
      const start = performance.now();
      return ajaxApi.put(apiUrl + url, body, buildPostHeaders(headers)).pipe(
        map(r => {
          const end = performance.now();
          return {
            payload: r.response as T,
            timeTaken: end - start,
          };
        }),
        catchError(err => {
          throw cleanUpSensitiveInformation(err);
        }),
      );
    },
    getJSON<T>(url: string, headers?: object): Observable<HttpCall<T>> {
      const start = performance.now();
      return ajaxApi.getJSON<T>(apiUrl + url, buildGetHeaders(headers)).pipe(
        map((payload: T) => {
          const end = performance.now();
          return {
            payload,
            timeTaken: end - start,
          };
        }),
        catchError(err => {
          throw cleanUpSensitiveInformation(err);
        }),
      );
    },
  };
}
