import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';
import type { DateRange } from 'state/blotter/blotterModel';

export interface HistoricalDateRangeOptionConnectOwnProps {
  range: DateRange;
  additionalAction?(): void;
}

export interface HistoricalDateRangeOptionConnectDispatchProps {
  onSelect(): void;
}

export type HistoricalDateRangeOptionConnectActionCreatorsKeys = 'blotterChangeDateRange';
export type HistoricalDateRangeOptionConnectActionCreators = Pick<
  ActionCreators,
  HistoricalDateRangeOptionConnectActionCreatorsKeys
>;

export const mapDispatchToPropsHistoricalDateRangeOption: MapDispatchToPropsHOF<
  HistoricalDateRangeOptionConnectDispatchProps,
  HistoricalDateRangeOptionConnectOwnProps,
  HistoricalDateRangeOptionConnectActionCreators
> =
  ac =>
  (dispatch, { range, additionalAction }) => ({
    onSelect() {
      dispatch(ac.blotterChangeDateRange(range));
      if (additionalAction !== undefined) {
        additionalAction();
      }
    },
  });
