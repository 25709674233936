import { useContext } from 'react';
import { InstrumentContext } from '../../contexts';
import { assertUnhandled } from '../../../utils/error';

export const useInstrumentAllowingCurrency = () => {
  const instrument = useContext(InstrumentContext);

  if (instrument === 'SmartRfs') {
    assertUnhandled(
      'SmartRfs is not a domain instrument (cannot be priced / executed) as it as no product state associated to it ' +
        'and therfore should not be used in components which expect product state',
      instrument,
    );
  }

  return instrument;
};
