import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

export interface CatchTileConnectOwnProps {
  quoteId: string;
}

export interface CatchTileConnectDispatchProps {
  onCatch(...any: readonly any[]): void;
}

// you can whitelist action creators after implementation
export type CatchTileConnectActionCreators = ActionCreators;
/*
export type CatchTileConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type CatchTileConnectActionCreators = Pick<ActionCreators, CatchTileConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsCatchTile: MapDispatchToPropsHOF<
  CatchTileConnectDispatchProps,
  CatchTileConnectOwnProps,
  CatchTileConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    onCatch() {
      dispatch(ac.changeTileClientError(quoteId, true));
    },
  });
