import type { CloseTileProps } from './typings';
import { button } from 'components/Form/Inputs/e2e';
import styled from 'styled-components';

const CloseButton = styled.button.attrs({ type: 'button' })`
  z-index: 9;
`;

export const CloseTile: React.FunctionComponent<CloseTileProps> = ({ closeTile }) => (
  <div className="d-flex flex-grow-1">
    <CloseButton
      data-nodrag
      className="align-self-start ms-auto btn btn-flat-secondary btn-icon px-2 py-0 mb-2"
      onClick={closeTile}
      data-e2e={button('closeTile')}
    >
      <i className="icon text-secondary">close</i>
    </CloseButton>
  </div>
);
