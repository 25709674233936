import { getTileMetadata, tileSelector } from './factories';
import type { AppState } from 'state/model';
import { isNotDefined } from '@sgme/fp';
import { getOptionCurrencyPair } from 'state/fxOptions/selectors/optionInput';
import { getCashCurrencyPair } from 'state/fxCashs/selectors/cashInput';
import { getSwapCurrencyPair } from 'state/fxSwaps/selectors/swapInput';
import { getAccumulatorCurrencyPairInput } from 'state/fxAccumulators/selectors/accumulatorInput';
import { getBulkCurrencyPair } from 'state/fxBulks/selectors/bulkInput';
import { getOrderCurrencyPair } from 'state/fxOrders/selectors/orderInput';
import { getBlotterOrderCurrencyPair } from 'state/blotter/selectors/blotterOrderSelectors';
import { getAmericanForwardCurrencyPairInput } from 'state/fxAmericanForward/selectors/americanForwardInput';

export const getTileCurrentStreamId = getTileMetadata('currentStreamId');
export const getTileCurrentEspStreamId = getTileMetadata('currentEspStreamId');
export const getTileIsPriceable = getTileMetadata('isPriceable');
export const getTileLastExecutedQuoteId = getTileMetadata('lastExecutedQuoteId');
export const getTileLastStreamError = getTileMetadata('lastStreamError');
export const getTileCurrentExecutionId = getTileMetadata('currentExecutionId');
export const getTileRfsStartedAt = getTileMetadata('rfsStartedAt');
export const getTilePriceRecords = getTileMetadata('priceRecords');
export const getTilePropertiesRequestError = getTileMetadata('propertiesRequestError');

export const getTileCurrencyPair = tileSelector({
  Option: getOptionCurrencyPair,
  Cash: getCashCurrencyPair,
  Swap: getSwapCurrencyPair,
  Accumulator: getAccumulatorCurrencyPairInput,
  Bulk: getBulkCurrencyPair,
  Order: getOrderCurrencyPair,
  BlotterOrder: (state, quoteId) => ({
    value: getBlotterOrderCurrencyPair(state, quoteId),
    input: undefined,
  }),
  // smartRfs has nos currency associated to it
  SmartRfs: () => ({
    value: null,
    input: null,
  }),
  AmericanForward: getAmericanForwardCurrencyPairInput,
});

export const isTileFirstCurrencyPairSelection = (state: AppState, tileId: string): boolean => {
  const currencyPair = getTileCurrencyPair(state, tileId);
  return (
    isNotDefined(currencyPair) ||
    (currencyPair.input === undefined && currencyPair.value === null && currencyPair.error === undefined)
  );
};
