import type { Collection } from 'typings/utils';
import type { IPatch } from './patchModels';
import { removeKeys, updateKey, addKey, unpatchedEntries } from 'utils/stateMap';
import type { IFormData } from './form';
import type { IFxTileMetadata } from 'state/tile/fxTileModel';

interface TcEvent<VALUES> {
  quoteId: string;
  patch: IPatch<VALUES>;
  idVersion: number;
  isReadyToPrice: boolean | null;
}

export const productPatcher = <
  VALUES extends {},
  INPUTS,
  STATE_TYPE extends IFormData<VALUES, INPUTS> & IFxTileMetadata = IFormData<VALUES, INPUTS> &
    IFxTileMetadata,
>(
  state: Collection<STATE_TYPE>,
  { quoteId, patch, idVersion, isReadyToPrice }: TcEvent<VALUES>,
  ...patchers: ReadonlyArray<(current: STATE_TYPE) => Partial<STATE_TYPE> | null>
): Collection<STATE_TYPE> =>
  updateKey<STATE_TYPE>(
    state,
    quoteId,
    (product): STATE_TYPE => ({
      ...product,
      inputs: {},
      values: {
        ...product.values,
        ...patch.values,
      },
      errors: {
        ...removeKeys(
          product.errors,
          Object.keys(patch.values).filter(field =>
            product.errors[field as keyof VALUES]?.code?.startsWith('error-tc-'),
          ),
        ),
        ...patch.errors,
      },
      warnings: {
        ...removeKeys(
          product.warnings,
          Object.keys(patch.values).filter(field =>
            product.warnings[field as keyof VALUES]?.code?.startsWith('warning-tc-'),
          ),
        ),
        ...patch.warnings,
      },
      propertiesRequested: false,
      tradeCaptureIdVersion: idVersion,
      ...(isReadyToPrice === null ? {} : { isPriceable: isReadyToPrice }),
    }),
    ...patchers,
  );

export const legsPatcher =
  <
    LEGVALUES extends {},
    LEGINPUTS,
    STATE_TYPE extends IFormData<LEGVALUES, LEGINPUTS> = IFormData<LEGVALUES, LEGINPUTS>,
  >(
    emptyLeg: STATE_TYPE,
  ) =>
  (
    state: Collection<STATE_TYPE>,
    quoteId: string,
    legsPatch: Record<string, IPatch<LEGVALUES>>,
    excludedLegsIds?: readonly string[],
  ): Collection<STATE_TYPE> => {
    const patchLegIds = Object.keys(legsPatch).map(legId => getLegKey(quoteId, legId));
    const quoteIdKey = `${quoteId}/`;
    const legsIdsToRemove = Object.keys(state).filter(
      id =>
        id.startsWith(quoteIdKey) &&
        !patchLegIds.includes(id) &&
        (excludedLegsIds ? !excludedLegsIds.includes(id.replace(quoteIdKey, '')) : true),
    );

    const stateWithoutRemovedLegs = removeKeys(state, legsIdsToRemove);

    return Object.entries(legsPatch).reduce((stateAcc, [legId, maybeLegPatch]) => {
      const legKey = getLegKey(quoteId, legId);
      const legPatch = maybeLegPatch || {
        values: {},
        errors: {},
        warnings: {},
      };
      if (stateAcc[legKey] === undefined) {
        return addKey<STATE_TYPE>(stateAcc, legKey, {
          ...emptyLeg,
          ...legPatch,
          values: {
            ...emptyLeg.values,
            ...legPatch.values,
          },
          errors: legPatch.errors,
          warnings: legPatch.warnings,
        });
      }
      return updateKey<STATE_TYPE>(stateAcc, legKey, leg => ({
        ...leg,
        ...legPatch,
        inputs: {} as Readonly<Partial<LEGINPUTS>>,
        values: {
          ...leg.values,
          ...legPatch.values,
        },
        errors: {
          ...unpatchedEntries(leg.errors, legPatch.values),
          ...legPatch.errors,
        },
        warnings: {
          ...unpatchedEntries(leg.warnings, legPatch.values),
          ...legPatch.warnings,
        },
      }));
    }, stateWithoutRemovedLegs);
  };

const defaultLegKey = '0';

// WARNING: don't use this function for a leg of an option (because the legId has already the quoteId inside
export const getLegKey = (quoteId: string, legId: string = defaultLegKey) => `${quoteId}/${legId}`;

export function buildLegsMap<T>(legs: ReadonlyArray<{ legId: string; patch: T }>) {
  return legs.reduce((acc, { legId, patch }) => {
    acc[legId] = patch;
    return acc;
  }, {} as Record<string, T>);
}
