import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { ICashRfsCreateRequest } from '../../cash';
import { assertIsDefined } from '@sgme/fp';

interface QuoteRequestBuilderMetadata {
  quoteId: string;
  rfsId: string;
}

type InjectedSelectorsKeys =
  | 'getCashCurrencyPair'
  | 'getCashState'
  | 'getClientForTile'
  | 'getCurrencyPrecision'
  | 'getCashProductName'
  | 'getConnectionId';
type InjectedSelectors = Pick<Selectors, InjectedSelectorsKeys>;

export const metaSelectorQuoteRequestBuilderWith: MapStateToMetadataHOF<
  ICashRfsCreateRequest,
  QuoteRequestBuilderMetadata,
  AppState,
  InjectedSelectors
> =
  sl =>
  (state, { quoteId, rfsId }) => {
    const { values } = sl.getCashState(state, quoteId);
    const { currencyPair, currency1, currency2, amount: negociatedAmount, maturityDate } = values;

    assertIsDefined(currencyPair, 'currencyPair is mandatory for pricing');
    assertIsDefined(currency1, 'currency1 is mandatory for pricing');
    assertIsDefined(currency2, 'currency2 is mandatory for pricing');
    assertIsDefined(negociatedAmount, 'amount is mandatory for pricing');
    assertIsDefined(maturityDate, 'maturityDate is mandatory for pricing');

    const clientId = sl.getClientForTile(state, quoteId).companyId;
    const currencyPrecision = sl.getCurrencyPrecision(state, currencyPair);
    const cashType = sl.getCashProductName(state, quoteId);
    if (cashType === null) {
      throw 'cash tile not found:' + quoteId;
    }
    const ndfData =
      values.isNonDeliverable === true
        ? {
            fixingDate: values.fixingDate ?? undefined,
            fixingCurrency: values.fixingCurrency ?? undefined,
            fixingSource: values.fixingSource ?? undefined,
            sndFixingSource: values.isCrossed ? values.sndFixingSource ?? undefined : undefined,
            xCurrency: values.isCrossed ? values.xCurrency ?? undefined : undefined,
          }
        : {
            fixingDate: undefined,
            fixingCurrency: undefined,
            fixingSource: undefined,
            sndFixingSource: undefined,
            xCurrency: undefined,
          };

    return {
      fxCashProduct: {
        currencyPair,
        currency1,
        currency2,
        negociatedAmount,
        negociatedCurrency: values.amountCurrency,
        expiryDate: new Date(maturityDate),
        spotPrecision: currencyPrecision,
        cashType,
        ...ndfData,
      },
      replyToStream: sl.getConnectionId(state),
      rfsId,
      requesterCounterpartyId: clientId,
      forceTradable: true,
    };
  };
