import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import type { AppState } from 'state/model';
import type { IStreamError, SendHelpStatus } from 'state/globalError/globalErrorModel';
import type { Action } from 'state/actions';
import { closeErrorDetail } from 'state/clientWorkspace/clientWorkspaceActions';
import { HelpButton } from './HelpButton';
import type { Dispatch } from 'redux';
import { getStreamError } from 'state/globalError/globalErrorSelectors';
import { isDefined } from '@sgme/fp';

interface IErrorDetailStateProps {
  streamError: IStreamError | null;
}

interface IErrorDetailDispatchProps {
  close(): void;
}

type ErrorDetailProps = IErrorDetailStateProps & IErrorDetailDispatchProps;

const mapStateToProps = (state: AppState): IErrorDetailStateProps => ({
  streamError: getStreamError(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): IErrorDetailDispatchProps => ({
  close: () => dispatch(closeErrorDetail()),
});

interface IErrorLineProps {
  title: React.ReactNode;
  message: React.ReactNode;
}

const ErrorLine: React.FunctionComponent<IErrorLineProps> = ({ title, message }) => (
  <div className="row flex-nowrap mb-3">
    <div className="col-4 text-end">{title}</div>
    <div className="col">
      <strong>{message}</strong>
    </div>
  </div>
);

const sentCallback = (close: () => void) => (status: SendHelpStatus | null) => {
  // eslint-disable-next-line no-unused-expressions
  status === 'success' && setTimeout(close, 1000);
};

const formatDetails = (streamError: IStreamError | null) => () => streamError;

export function ErrorDetail({ streamError, close }: ErrorDetailProps): JSX.Element {
  const isOpen = isDefined(streamError);
  return (
    <Modal isOpen={isOpen} toggle={close} backdrop contentClassName="border border-danger">
      <ModalHeader tag="h4" className="text-danger">
        <FormattedMessage id="modals.errorDetails.title" />
      </ModalHeader>
      {isOpen && (
        <ModalBody>
          <div className="d-flex flex-column">
            <ErrorLine
              title={<FormattedMessage id="modals.errorDetails.codeType" />}
              message={streamError!.code}
            />
            <ErrorLine
              title={<FormattedMessage id="modals.errorDetails.reasonType" />}
              message={streamError!.message}
            />
            <ErrorLine
              title={<FormattedMessage id="modals.errorDetails.mpSessionId" />}
              message={streamError!.multipassSessionId}
            />
            <ErrorLine
              title={<FormattedMessage id="modals.errorDetails.mpErrorCode" />}
              message={streamError!.multipassErrorCode}
            />
          </div>
        </ModalBody>
      )}
      <ModalFooter>
        <HelpButton
          includeState
          details={formatDetails(streamError)}
          statusUpdateCallBack={sentCallback(close)}
        />
        <button type="button" className="btn btn-secondary" onClick={close}>
          <FormattedMessage id="modals.errorDetails.footer.close" />
        </button>
      </ModalFooter>
    </Modal>
  );
}

export const ErrorDetailsModal = connect(mapStateToProps, mapDispatchToProps)(ErrorDetail);

export default ErrorDetailsModal;
