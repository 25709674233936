import type { Selectors } from 'state/selectors';
import type { Mappers } from './typings';

export const americanForwardMappers = (sl: Selectors): Mappers<'AmericanForward'> => ({
  currencyPair: sl.getAmericanForwardCurrencyPairInput,
  amountCurrency: sl.getAmericanForwardAmountCurrency,
  amount: sl.getAmericanForwardAmount,
  premiumPaymentAmount: sl.getAmericanForwardPremiumPaymentAmount,
  side: sl.getAmericanForwardSide,
  startDate: sl.getAmericanForwardStartDate,
  startDateTenor: sl.getAmericanForwardStartDateTenor,
  expiryDate: sl.getAmericanForwardExpiryDate,
  expiryDateTenor: sl.getAmericanForwardExpiryDateTenor,
  deliveryDate: sl.getAmericanForwardDeliveryDate,
  deliveryDateTenor: sl.getAmericanForwardDeliveryDateTenor,
  marketPlace: sl.getAmericanForwardMarketPlace,
  priceType: sl.getAmericanForwardPriceType,
  premiumDate: sl.getAmericanForwardPremiumDate,
  premiumDateTenor: sl.getAmericanForwardPremiumDateTenor,
  rate: sl.getAmericanForwardRate,
  hedgeCurrency: sl.getAmericanForwardHedgeCurrency,
  hedgeAmount: sl.getAmericanForwardHedgeAmount,
  hedgeType: sl.getAmericanForwardHedgeType,
  hedgeRate: sl.getAmericanForwardHedgeRate,
  markupCurrency: sl.getAmericanForwardMarkupCurrency,
  forwardRate: sl.getAmericanForwardRate

});
