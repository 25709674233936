import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { type FreeLayout, emptyFreeLayout } from '../freeLayoutModel';
import type { Collection } from 'typings/utils';
import { addKey, removeKey, updateKey } from 'utils/stateMap';
import { freeLayoutStatePatcher } from './freeLayoutStatePatcher';
import { strictEntries } from 'utils/object/entries';

const openTileOffset = 40;

export const freeLayoutCollectionReducer: Reducer<Collection<FreeLayout>, Action> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TAB_ADDED':
      return addKey(state, action.tabId, emptyFreeLayout);
    case 'CLIENTWORKSPACE_TAB_REMOVED':
      return removeKey(state, action.tabId);
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
      if (action.instrument === 'Order') {
        return updateKey(state, action.clientWorkspaceId, freeLayoutStatePatcher(action));
      }
      break;
    case 'ORDER_SUBMISSION_SUCCESS':
      if (action.orderId === null) {
        return state;
      }
      const [
        workspaceId,
        {
          itemPositions: { [action.quoteId]: existing, ...otherItemPositions },
        },
      ] = strictEntries(state).find(
        ([, { itemPositions }]) => itemPositions[action.quoteId] !== undefined,
      ) ?? [undefined, { itemPositions: {} } as FreeLayout];
      return updateKey(state, workspaceId!, () => {
        if (action.order === undefined) {
          return null;
        }
        return {
          itemPositions: { ...otherItemPositions, [action.orderId!]: existing! },
        };
      });
    case 'BLOTTER_ORDER_OPEN_TILE':
      return updateKey(state, action.currentTabId, layout => {
        const keys = Object.keys(layout.itemPositions);
        return keys.includes(action.orderId)
          ? layout
          : {
              itemPositions: {
                ...layout.itemPositions,
                [action.orderId]: {
                  top: (keys.length + 1) * openTileOffset,
                  left: (keys.length + 1) * openTileOffset,
                },
              },
            };
      });
    case 'CLIENTWORKSPACE_TILE_DELETED':
    case 'FREELAYOUT_ITEM_POSITION_CHANGED':
      return updateKey(state, action.clientWorkspaceId, freeLayoutStatePatcher(action));
  }
  return state;
};
