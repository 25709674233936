import type { Updater } from '../typings';
import type { SavedWorkspace as SavedWorkspace8 } from '../updater8/model';
import type {
  OptionHedgeSavedTile,
  SavedBlotter,
  SavedWorkspace as SavedWorkspace9,
} from './model';
import type { Collection } from 'typings/utils';

const currentVersion = 9;

export const updater9: Updater<SavedWorkspace8, SavedWorkspace9> = {
  version: currentVersion,
  canUpdate(data: any) {
    return data.version === currentVersion - 1;
  },
  update: (previousWorkspace: SavedWorkspace8): SavedWorkspace9 => ({
    ...previousWorkspace,
    blotter: previousWorkspace.blotter as SavedBlotter,
    version: currentVersion,
    tabs: updateOptionTiles(previousWorkspace.tabs),
  }),
};

function updateOptionTiles(tabs: SavedWorkspace8['tabs']): SavedWorkspace9['tabs'] {
  return tabs.map(tab => ({
    ...tab,
    tiles: tab.tiles.map(tile =>
      tile.instrument === 'Option'
        ? {
            ...tile,
            orientation: 'vertical',
            hedges: {} as Collection<OptionHedgeSavedTile>,
          }
        : tile,
    ),
  }));
}
