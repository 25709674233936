import type { Client } from 'state/referenceData/referenceDataModel';
import { cleanDiacritics } from './string';

const collator = new Intl.Collator();

export const compareCompaniesPuttingTheseFirst = (...specialValues: readonly string[]) => {
  const normalizedSpecials = specialValues
    .filter(s => !s.match(/^\s+$/))
    .map(s => cleanDiacritics(s.toLowerCase()));

  return (a: Client, b: Client) => {
    const indexA = getIndex(normalizedSpecials, a);
    const indexB = getIndex(normalizedSpecials, b);

    if (indexA === -1 && indexB === -1) {
      return collator.compare(a.companyName, b.companyName);
    }
    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }
    return indexA - indexB;
  };
};

function getIndex(specials: readonly string[], client: Client) {
  const companyName = cleanDiacritics(client.companyName.toLowerCase());
  const i = specials.indexOf(companyName);
  if (i === -1) {
    return specials.indexOf(`${client.companyId}`);
  }
  return i;
}
