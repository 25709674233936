import { useMemo } from 'react';
import type { RenderPropChildren } from 'typings/utils';
import type { Client } from 'state/referenceData/referenceDataModel';

interface ClientRowProps {
  companyId: Client['companyId'];
  index: number;
  onClientChange(companyId: string): void;
  gotoClient(index: number): void;
  close(): void;
}

interface ClientRowChildrenProps {
  onMouseEnter: React.MouseEventHandler;
  onClick: React.MouseEventHandler;
}

export const ClientRow: React.FunctionComponent<
  RenderPropChildren<ClientRowChildrenProps> & ClientRowProps
> = ({ children, companyId, index, onClientChange, close, gotoClient }) => {
  const handlers = useMemo(
    () => ({
      onClick: () => {
        onClientChange(companyId.toString());
        close();
      },
      onMouseEnter: () => gotoClient(index),
    }),
    [close, companyId, gotoClient, index, onClientChange],
  );
  return <>{children(handlers)}</>;
};
