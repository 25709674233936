import type {
  IFxSwapInputs,
  FxSwapPatch,
  IQuote,
  IFxSwapMetadata,
  IFxSwapExecutionData,
  IFxSwapValues,
  SwapTileOpenFromBlotter,
} from './fxSwapsModel';
import type { CurrencyChoice, Way } from 'state/share/productModel/litterals';
import type { IStreamError } from 'state/globalError/globalErrorModel';
import type { SwapSavedTile } from 'api/workspaceService/model';
import type { TradeCaptureError } from 'state/tile/fxTileModel';
import type { RestoredTiles } from 'state/clientWorkspace';

export type FxSwapAction =
  | SwapTileReset
  | SwapTileRestoreEpic
  | SwapTileOpenFromBlotterEpic
  | SwapPropertyChanged
  | SwapLocalPropertyChanged
  | SwapPropertiesRemoveError
  | SwapPropertiesRequested
  | SwapPropertiesRequestFailed
  | SwapPropertiesReceived
  | SwapAmountCurrencyMaskChanged
  | SwapRfsStarted
  | SwapRfsExecutionRequestedEpic
  | SwapRfsTerminated
  | SwapRfsFailed
  | SwapQuoteReceived
  | SwapRfsRequestedEpic
  | SwapRfsCancelEpic
  | SwapRfsClearError
  | SwapRfsCancel
  | SwapRfsCancelFailed
  | SwapRfsCancelSent
  | SwapExecutionSent
  | SwapExecutionFailed
  | SwapExecutionReceived
  | SwapFillReportReceived
  | SwapExecutionSentSucceeded;

export interface SwapTileReset {
  type: 'SWAP_TILE_RESET';
  quoteId: string;
}

export function swapTileReset(quoteId: string): SwapTileReset {
  return {
    type: 'SWAP_TILE_RESET',
    quoteId,
  };
}

export interface SwapTileRestoreEpic {
  type: 'SWAP_TILE_RESTORE_EPIC';
  tiles: RestoredTiles<SwapSavedTile>;
}

export function swapTileRestoreEpic(tiles: RestoredTiles<SwapSavedTile>): SwapTileRestoreEpic {
  return {
    type: 'SWAP_TILE_RESTORE_EPIC',
    tiles,
  };
}

export interface SwapTileOpenFromBlotterEpic {
  type: 'SWAP_TILE_OPEN_FROM_BLOTTER_EPIC';
  tileId: string;
  tile: SwapTileOpenFromBlotter;
}

export function swapTileOpenFromBlotterEpic(
  tileId: string,
  tile: SwapTileOpenFromBlotter,
): SwapTileOpenFromBlotterEpic {
  return {
    type: 'SWAP_TILE_OPEN_FROM_BLOTTER_EPIC',
    tileId,
    tile,
  };
}

export interface SwapPropertyChanged {
  type: 'SWAP_PROPERTIES_CHANGED';
  swapId: string;
  patch: Partial<IFxSwapInputs>;
}

export function swapPropertyChanged(
  swapId: string,
  patch: Partial<IFxSwapInputs>,
): SwapPropertyChanged {
  return {
    type: 'SWAP_PROPERTIES_CHANGED',
    swapId,
    patch,
  };
}

export interface SwapLocalPropertyChanged {
  type: 'SWAP_LOCAL_PROPERTY_CHANGED';
  swapId: string;
  patch: Partial<IFxSwapMetadata>;
}

export function swapLocalPropertyChanged(
  swapId: string,
  patch: Partial<IFxSwapMetadata>,
): SwapLocalPropertyChanged {
  return {
    type: 'SWAP_LOCAL_PROPERTY_CHANGED',
    swapId,
    patch,
  };
}

export interface SwapPropertiesRequested {
  type: 'SWAP_PROPERTIES_REQUESTED';
  swapId: string;
  sessionId: string;
}

export function swapPropertiesRequested(
  swapId: string,
  sessionId: string,
): SwapPropertiesRequested {
  return {
    type: 'SWAP_PROPERTIES_REQUESTED',
    swapId,
    sessionId,
  };
}

export interface SwapPropertiesReceived {
  type: 'SWAP_PROPERTIES_RECEIVED';
  quoteId: string;
  patch: FxSwapPatch;
  isReadyToPrice: boolean | null;
  isPriceObsolete: boolean;
  idVersion: number;
}

export function swapPropertiesReceived(
  quoteId: string,
  patch: FxSwapPatch,
  idVersion: number,
  isReadyToPrice: boolean | null,
  isPriceObsolete: boolean,
): SwapPropertiesReceived {
  return {
    type: 'SWAP_PROPERTIES_RECEIVED',
    quoteId,
    patch,
    isReadyToPrice,
    isPriceObsolete,
    idVersion,
  };
}

export interface SwapPropertiesRequestFailed {
  type: 'SWAP_PROPERTIES_REQUEST_FAILED';
  quoteId: string;
  error: TradeCaptureError;
}

export function swapPropertiesRequestFailed(
  quoteId: string,
  error: TradeCaptureError,
): SwapPropertiesRequestFailed {
  return {
    type: 'SWAP_PROPERTIES_REQUEST_FAILED',
    quoteId,
    error,
  };
}

export interface SwapPropertiesRemoveError {
  type: 'SWAP_PROPERTIES_REMOVE_ERROR';
  swapId: string;
  keys: ReadonlyArray<keyof IFxSwapValues>;
}

export function swapPropertiesRemoveError(
  swapId: string,
  keys: ReadonlyArray<keyof IFxSwapValues>,
): SwapPropertiesRemoveError {
  return {
    type: 'SWAP_PROPERTIES_REMOVE_ERROR',
    swapId,
    keys,
  };
}

export interface SwapAmountCurrencyMaskChanged {
  type: 'SWAP_AMOUNT_CURRENCY_MASK_CHANGED';
  swapId: string;
  amountCurrency: CurrencyChoice;
}

export function swapAmountCurrencyMaskChanged(
  swapId: string,
  amountCurrency: CurrencyChoice,
): SwapAmountCurrencyMaskChanged {
  return {
    type: 'SWAP_AMOUNT_CURRENCY_MASK_CHANGED',
    swapId,
    amountCurrency,
  };
}

export interface SwapRfsCancelEpic {
  type: 'SWAP_RFS_CANCEL_EPIC';
  swapId: string;
  streamId: string;
}

export function swapRfsCancelEpic(swapId: string, streamId: string): SwapRfsCancelEpic {
  return {
    type: 'SWAP_RFS_CANCEL_EPIC',
    swapId,
    streamId,
  };
}
export interface SwapRfsRequestedEpic {
  type: 'SWAP_RFS_REQUESTED_EPIC';
  swapId: string;
  previousStreamId?: string | null;
}

export function swapRfsRequestedEpic(
  swapId: string,
  previousStreamId?: string | null,
): SwapRfsRequestedEpic {
  return {
    type: 'SWAP_RFS_REQUESTED_EPIC',
    swapId,
    previousStreamId,
  };
}

export interface SwapQuoteReceived {
  type: 'SWAP_QUOTE_RECEIVED';
  swapId: string;
  streamId: string;
  quote: IQuote;
  tiering: string | null;
}

export function swapQuoteReceived(
  swapId: string,
  streamId: string,
  quote: IQuote,
  tiering: string | null,
): SwapQuoteReceived {
  return {
    type: 'SWAP_QUOTE_RECEIVED',
    swapId,
    streamId,
    quote,
    tiering,
  };
}

export interface SwapRfsFailed {
  type: 'SWAP_RFS_FAILED';
  swapId: string;
  streamId: string;
  error: IStreamError;
}

export function swapRfsFailed(
  swapId: string,
  streamId: string,
  error: IStreamError,
): SwapRfsFailed {
  return {
    type: 'SWAP_RFS_FAILED',
    swapId,
    streamId,
    error,
  };
}

export interface SwapRfsTerminated {
  type: 'SWAP_RFS_TERMINATED';
  swapId: string;
  streamId: string;
}

export function swapRfsTerminated(swapId: string, streamId: string): SwapRfsTerminated {
  return {
    type: 'SWAP_RFS_TERMINATED',
    swapId,
    streamId,
  };
}

export interface SwapRfsExecutionRequestedEpic {
  type: 'SWAP_RFS_EXECUTION_REQUESTED_EPIC';
  swapId: string;
  streamId: string;
  way: Way;
}

export function swapRfsExecutionRequestedEpic(
  swapId: string,
  streamId: string,
  way: Way,
): SwapRfsExecutionRequestedEpic {
  return {
    type: 'SWAP_RFS_EXECUTION_REQUESTED_EPIC',
    swapId,
    streamId,
    way,
  };
}

export interface SwapRfsStarted {
  type: 'SWAP_RFS_STARTED';
  swapId: string;
  streamId: string;
  rfsStartedAt: Date;
}

export function swapRfsStarted(
  swapId: string,
  streamId: string,
  rfsStartedAt: Date,
): SwapRfsStarted {
  return {
    type: 'SWAP_RFS_STARTED',
    swapId,
    streamId,
    rfsStartedAt,
  };
}

export interface SwapRfsCancel {
  type: 'SWAP_RFS_CANCEL';
  swapId: string;
  streamId: string;
}

export function swapRfsCancel(swapId: string, streamId: string): SwapRfsCancel {
  return {
    type: 'SWAP_RFS_CANCEL',
    swapId,
    streamId,
  };
}

export interface SwapRfsCancelFailed {
  type: 'SWAP_RFS_CANCEL_FAILED';
  swapId: string;
  streamId: string;
}

export function swapRfsCancelFailed(swapId: string, streamId: string): SwapRfsCancelFailed {
  return {
    type: 'SWAP_RFS_CANCEL_FAILED',
    swapId,
    streamId,
  };
}

export interface SwapRfsCancelSent {
  type: 'SWAP_RFS_CANCEL_SENT';
  quoteId: string;
  streamId: string;
}

export function swapRfsCancelSent(quoteId: string, streamId: string): SwapRfsCancelSent {
  return {
    type: 'SWAP_RFS_CANCEL_SENT',
    quoteId,
    streamId,
  };
}

export interface SwapRfsClearError {
  type: 'SWAP_RFS_CLEAR_ERROR';
  quoteId: string;
}

export function swapRfsClearError(quoteId: string): SwapRfsClearError {
  return { type: 'SWAP_RFS_CLEAR_ERROR', quoteId };
}

export interface SwapExecutionReceived {
  type: 'SWAP_EXECUTION_RECEIVED';
  executionId: string;
}

export function swapExecutionReceived(executionId: string): SwapExecutionReceived {
  return {
    type: 'SWAP_EXECUTION_RECEIVED',
    executionId,
  };
}

export interface SwapFillReportReceived {
  type: 'SWAP_FILL_REPORT_RECEIVED';
  executionId: string;
  tradeIds: readonly string[];
}

export function swapFillReportReceived(
  executionId: string,
  tradeIds: readonly string[],
): SwapFillReportReceived {
  return {
    type: 'SWAP_FILL_REPORT_RECEIVED',
    executionId,
    tradeIds,
  };
}

export interface SwapExecutionSent {
  type: 'SWAP_EXECUTION_SENT';
  swapId: string;
  quoteId: string;
  executionId: string;
  execution: IFxSwapExecutionData;
  instrument: 'Swap';
}

export function swapExecutionSent(
  swapId: string,
  quoteId: string,
  executionId: string,
  execution: IFxSwapExecutionData,
): SwapExecutionSent {
  return {
    type: 'SWAP_EXECUTION_SENT',
    swapId,
    quoteId,
    executionId,
    execution,
    instrument: 'Swap',
  };
}

export interface SwapExecutionSentSucceeded {
  type: 'SWAP_EXECUTION_SENT_SUCCEEDED';
  executionId: string;
}

export function swapExecutionSentSucceeded(executionId: string): SwapExecutionSentSucceeded {
  return {
    type: 'SWAP_EXECUTION_SENT_SUCCEEDED',
    executionId,
  };
}

export interface SwapExecutionFailed {
  type: 'SWAP_EXECUTION_FAILED';
  executionId: string;
  streamError: IStreamError;
}

export function swapExecutionFailed(
  executionId: string,
  streamError: IStreamError,
): SwapExecutionFailed {
  return {
    type: 'SWAP_EXECUTION_FAILED',
    streamError,
    executionId,
  };
}
