import type { InstrumentChoice } from 'state/referenceData/referenceDataModel';
import type { Theme } from 'utils/theme';

export const colWidth = 305;
export const growlWidth = 350;
export const orderTileWidth = 350;
export const smartRfsTileWidth = 256;
export const normalDetailsPanelWidth = 300;
export const xlDetailsPanelWidth = 520;
export const tileContainerPadding = 5;
export const ratesHeight = 100;
export const defaultCashHeight = 256;
export const defaultSwapHeight = 256;
export const defaultAccumulatorHeight = 448;
export const defaultAmericanForwardHeight = 512;
export const defaultOptionHeight = 512;
export const defaultOrderHeight = 448;

export const xlBreakpoint = '1200px';

export const tableBorderColorByTheme: Record<Theme, string> = {
  DARK: 'var(--bs-dark)',
  LIGHT: 'var(--bs-light)',
};

export const defaultHeights: Record<InstrumentChoice | 'Order' | 'BlotterOrder', number> = {
  Cash: defaultCashHeight,
  Swap: defaultSwapHeight,
  Option: defaultOptionHeight,
  AmericanForward: defaultAmericanForwardHeight,
  TargetAccumulator: defaultAccumulatorHeight,
  ForwardAccumulator: defaultAccumulatorHeight,
  Order: defaultOrderHeight,
  SmartRfs: defaultCashHeight - 10, // todo-5153 find a way for the layout to be only determined by defaultCashHeight
  BlotterOrder: defaultOrderHeight,
};
