import type { Thunk } from 'state';
import { isTraceLogEnabledQueryParameter } from 'utils/url';
import { getFakeUser } from 'api/connect';

export function initStreamThunk(): Thunk<void> {
  return async (_dispatch, _getState, _extraArg) => {
    // a static import lead to unit test failure (we try to start the worker while we cannot load it)
    const { startSignalR, startRemoteLogging, startStreamingPing } = await import(
      'bootstrap/streams/streamingWorker'
    );

    const isTraceLogEnabled = isTraceLogEnabledQueryParameter();

    const fakeUser = window.sgmeConfiguration.useFakeSgConnect ? getFakeUser() : undefined;

    const logIntervalMs = Number(window.sgmeConfiguration.logIntervalMs);
    const pingInterval = Number(window.sgmeConfiguration.pingIntervalMs);
    const maxEspPingDelayMs = Number(window.sgmeConfiguration.maxEspPingDelayMs);

    startSignalR({
      ...window.sgmeConfiguration.signalR,
      fakeUser,
      isTraceLogEnabled,
    });

    startRemoteLogging(logIntervalMs);
    startStreamingPing(pingInterval, maxEspPingDelayMs);
  };
}
