import { InputToggle } from 'components/share/InputToggle';
import { EnhancedInput } from 'components/share/EnhancedInput';
import { withTabId } from 'components/NavMenu';
import { connectTabName } from './connect';
import styled from 'styled-components';

export interface TabNameProps {
  name: string;
  isEditable: boolean;
  rename(name: string): void;
}

const displayClassName = 'form-control form-control-plaintext text-truncate p-2';

const inputClassName = 'form-control w-100';

const DisplayName = styled.strong.attrs({
  className: displayClassName,
})`
  ${({ isEditable }: Pick<TabNameProps, 'isEditable'>) => (isEditable ? '' : 'pointer-events: none;')}
`;

export const TabNameRaw = ({ name, rename, isEditable }: TabNameProps) => (
  <InputToggle onChange={rename} isEditable={isEditable}>
    {({ isOpen, toggle, onChange }) =>
      isOpen ? (
        <EnhancedInput autofocus value={name} className={inputClassName} onSubmit={onChange} onBlurPristine={toggle} />
      ) : (
        <DisplayName isEditable={isEditable} onClick={isEditable ? toggle : undefined}>
          {name}
        </DisplayName>
      )
    }
  </InputToggle>
);

export const TabName = withTabId(connectTabName(TabNameRaw));
