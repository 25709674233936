import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import { type Selectors, selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type {
  IBulkRfsCreateRequest,
  IBulkProduct,
  IBulkLeg,
  IBulkLegNonDeliverableFixingInfos,
} from 'api/multipass/bulk';

import { SPOT_TENOR } from 'components/share/tenors';
import { assertIsDefined } from '@sgme/fp';

interface QuoteRequestBuilderMetadata {
  quoteId: string;
  rfsId: string;
}

type InjectedSelectorsKeys =
  | 'getBulkCurrencyPair'
  | 'getBulkAmountCurrency'
  | 'getCompanyIdFromQuoteId'
  | 'getConnectionId'
  | 'getBulkLegs'
  | 'getBulkExcludedLegs';
export type InjectedSelectors = Pick<Selectors, InjectedSelectorsKeys>;

// ---------------------------------------------------------------------------------
// Public meta selector
// ---------------------------------------------------------------------------------
export const metaSelectorQuoteRequestBuilderWith: MapStateToMetadataHOF<
  IBulkRfsCreateRequest,
  QuoteRequestBuilderMetadata,
  AppState,
  InjectedSelectors
> =
  (sl) =>
    (state, { rfsId, quoteId: bulkId }) => {
      const excludedLegs = sl.getBulkExcludedLegs(state, bulkId);
      const legs = sl
        .getBulkLegs(state, bulkId)
        .reduce(
          (processedLegs, legId) => [
            ...processedLegs,
            ...(excludedLegs.includes(legId)
              ? []
              : toLegQuoteRequest({ state, bulkId, legId, sl: selectors })),
          ],
          [] as readonly IBulkLeg[],
        );

      const currencyPair = sl.getBulkCurrencyPair(state, bulkId).value;
      assertIsDefined(currencyPair, 'currencyPair needs to be defined for pricing');
      const fxBulkProduct: IBulkProduct = {
        currencyPair,
        amountCurrency: sl.getBulkAmountCurrency(state, bulkId).value,
        legs,
      };

      const requesterCounterpartyId = sl.getCompanyIdFromQuoteId(state, bulkId);
      assertIsDefined(requesterCounterpartyId, 'companyId needs to be defined for pricing');

      return {
        fxBulkProduct,
        replyToStream: sl.getConnectionId(state),
        rfsId,
        requesterCounterpartyId,
      };
    };

type InjectedLegSelectorsKeys =
  | 'getBulkLegProduct'
  | 'getBulkLegWay'
  | 'getBulkLegAmount'
  | 'getBulkLegPaymentDate'
  | 'getBulkLegPaymentDateTenor'
  | 'getBulkLegFarAmount'
  | 'getBulkLegFarPaymentDate'
  | 'getBulkLegFarPaymentDateTenor';
export type InjectedLegSelectors = Pick<Selectors, InjectedLegSelectorsKeys>;

export const toLegQuoteRequest = ({
  state,
  bulkId,
  legId,
  sl,
}: {
  state: AppState;
  bulkId: string;
  legId: string;
  sl: InjectedLegSelectors;
}): readonly IBulkLeg[] => {
  const product = sl.getBulkLegProduct(state, bulkId, legId).value;
  if (product === null) {
    return [];
  }

  const way = sl.getBulkLegWay(state, bulkId, legId).value;
  const amount = sl.getBulkLegAmount(state, bulkId, legId).value;
  const paymentDate = sl.getBulkLegPaymentDate(state, bulkId, legId).value;
  const paymentDateTenor = sl.getBulkLegPaymentDateTenor(state, bulkId, legId).value;

  assertIsDefined(way, 'way needs to be defined for pricing');
  assertIsDefined(amount, 'way needs to be defined for pricing');
  assertIsDefined(paymentDate, 'paymentDate needs to be defined for pricing');
  assertIsDefined(paymentDateTenor, 'paymentDateTenor needs to be defined for pricing');

  const fixingInfos: IBulkLegNonDeliverableFixingInfos | null = null;
  if (product !== 'FxSwap' && product !== 'FxNdSwap') {
    return [
      {
        id: legId,
        product,
        way,
        amount,
        paymentDate,
        paymentDateTenor,
        ndFixingInfos: fixingInfos,
      } as IBulkLeg,
    ];
  }

  const nearLeg: IBulkLeg = {
    id: `${legId}-near`,
    product: paymentDateTenor === SPOT_TENOR ? 'FxSpot' : product === 'FxSwap' ? 'FxFwd' : 'FxNdf',
    way,
    amount,
    paymentDate,
    paymentDateTenor,
    ndFixingInfos: fixingInfos,
  };

  const farAmount = sl.getBulkLegFarAmount(state, bulkId, legId).value;
  const farPaymentDate = sl.getBulkLegFarPaymentDate(state, bulkId, legId).value;
  const farPaymentDateTenor = sl.getBulkLegFarPaymentDateTenor(state, bulkId, legId).value;

  assertIsDefined(farAmount, 'farAmount needs to be defined for pricing');
  assertIsDefined(farPaymentDate, 'farPaymentDate needs to be defined for pricing');
  assertIsDefined(farPaymentDateTenor, 'farPaymentDateTenor needs to be defined for pricing');

  const farLeg: IBulkLeg = {
    id: `${legId}-far`,
    product: product === 'FxSwap' ? 'FxFwd' : 'FxNdf',
    way: way === 'Buy' ? 'Sell' : 'Buy',
    amount: farAmount,
    paymentDate: farPaymentDate,
    paymentDateTenor: farPaymentDateTenor,
    ndFixingInfos: fixingInfos,
  };

  return [nearLeg, farLeg];
};
