import type { EmailAction } from './emailsInputModels';

export function emailsReducerDefault(state: readonly string[], action: EmailAction) {
  switch (action.type) {
    case 'add':
      if (state.includes(action.emailsDefault!)) {
        return state;
      }
      return [...state, action.emailsDefault!];
    case 'adds':
      return [...state, ...action.emailsDefault!.filter(m => !state.includes(m))];
    case 'remove':
      return state.filter(mail => mail !== action.emailsDefault);
    default:
      return state;
  }
}
export function emailsReducerCash(state: readonly string[], action: EmailAction) {
  switch (action.type) {
    case 'addCash':
      if (state.includes(action.emailsCash!)) {
        return state;
      }
      return [...state, action.emailsCash!];
    case 'addsCash':
      return [...state, ...action.emailsCash!.filter(m => !state.includes(m))];
    case 'removeCash':
      return state.filter(mail => mail !== action.emailsCash);
    default:
      return state;
  }
}
export function emailsReducerOrders(
  state: readonly string[],
  action: EmailAction,
): readonly string[] {
  switch (action.type) {
    case 'addOrder':
      if (state.includes(action.emailsOrder!)) {
        return state;
      }
      return [...state, action.emailsOrder!];
    case 'addsOrder':
      return [...state, ...action.emailsOrder!.filter(m => !state.includes(m))];
    case 'removeOrder':
      return state.filter(mail => mail !== action.emailsOrder);
    default:
      return state;
  }
}
export function emailsReducerOptions(
  state: readonly string[],
  action: EmailAction,
): readonly string[] {
  switch (action.type) {
    case 'addOptions':
      if (state.includes(action.emailsOptions!)) {
        return state;
      }
      return [...state, action.emailsOptions!];
    case 'addsOptions':
      return [...state, ...action.emailsOptions!.filter(m => !state.includes(m))];
    case 'removeOptions':
      return state.filter(mail => mail !== action.emailsOptions);
    default:
      return state;
  }
}
