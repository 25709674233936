const collator = new Intl.Collator();
export const compareKey =
  (key: string) =>
  <T extends { [key: string]: any }>(a: T, b: T) =>
    collator.compare(a[key], b[key]);

export const isNotUnionLitteral =
  <T extends string, U extends T>(enumType: readonly U[]) =>
  (value: T): value is Exclude<T, U> =>
    !(enumType as readonly T[]).includes(value);

/**
 * Gets the nearest neighbour from the array, next element if possible or previous if value is the last element.
 * Returns null if the array only has one element.
 */
export const getNearestNeighbour = <T>(array: readonly T[], value: T): T | null => {
  if (array.length <= 1) {
    return null;
  }
  const positionOfValue = array.indexOf(value);
  if (positionOfValue === -1) {
    return null;
  }
  const lastPosition = array.length - 1;
  if (positionOfValue >= lastPosition) {
    return array[lastPosition - 1];
  } else {
    return array[positionOfValue + 1];
  }
};

/**
 * Predicate to filter out duplicate values from an array
 */
export const distinct = <T>(value: T, index: number, array: readonly T[]): boolean =>
  array.indexOf(value) === index;

/**
 * Check if _value_ is present in _array_ without having to resort to typecast _array_
 */
export const contains = <T>(array: readonly T[], value: T) => array.includes(value);
