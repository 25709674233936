import type { Thunk } from 'state';
import type { ImportedScheduleRow } from 'utils/csvParsing/accuSchedule';
import type { AccumulatorScheduleInputs } from '../fxAccumulatorsModel';

export function scheduleImportedThunk(
  quoteId: string,
  importedScheduleRows: readonly ImportedScheduleRow[],
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac, getNewGuid }) => {
    const legsPatch = Object.fromEntries(
      importedScheduleRows.map(row => [getNewGuid(), mapScheduleRowToFixing(row)]),
    );

    dispatch(ac.accumulatorScheduleImported(quoteId, legsPatch));
  };
}

const mapScheduleRowToFixing = (row: ImportedScheduleRow): Partial<AccumulatorScheduleInputs> => ({
  fixingDate: row.fixingDate,
  paymentDate: row.settlementDate,
  strike: row.strike,
  amount: row.notional,
  leverageAmount: row.leverageAmount,
});
