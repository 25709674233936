import type { Reducer } from 'redux';
import { type FxBulkStateMap, emptyFxBulkState, type FxBulkState } from '../fxBulksModel';
import type { Action } from 'state/actions';
import { addKey, updateKey, removeKey, unpatchedEntries } from 'utils/stateMap';
import { addPriceRecorded } from 'state/share/priceRecordReducerHelper';
import { validationPartialReducer } from 'state/share/validationReducer';

const bulkValidationReducer = validationPartialReducer<'Bulk', FxBulkState>('Bulk');

export const bulksReducer: Reducer<FxBulkStateMap> = (
  state: FxBulkStateMap = {},
  action: Action,
): FxBulkStateMap => {
  switch (action.type) {
    case 'BULK_CREATED':
      return addKey(state, action.bulkId, emptyFxBulkState);
    case 'BULK_CLOSED':
      return removeKey(state, action.bulkId);
    case 'BULK_IMPORTED':
    case 'BULK_PROPERTY_CHANGED':
      return updateKey(state, action.bulkId, bulk => ({
        inputs: {
          ...bulk.inputs,
          ...action.patch,
        },
      }));
    case 'BULK_PROPERTIES_REQUESTED':
      return updateKey(state, action.bulkId, () => ({
        propertiesRequested: true,
        currentSessionId: action.sessionId,
      }));
    case 'BULK_PROPERTIES_REQUEST_FAILED':
      return updateKey(state, action.bulkId, () => ({
        propertiesRequested: false,
        propertiesRequestError: action.error,
      }));
    case 'BULK_PROPERTIES_RECEIVED':
      return updateKey(state, action.bulkId, bulk => {
        const { patch } = action;

        const errorsToKeep = unpatchedEntries(bulk.errors, patch.values);

        const isReadyToPrice =
          patch.isReadyToPrice === null ? bulk.isPriceable : patch.isReadyToPrice;

        const legsIds = Object.keys(patch.legs);
        const potentialLastLegId = Math.max(...legsIds.map(Number));

        return {
          ...bulk,
          propertiesRequested: false,
          isPriceable: isReadyToPrice,
          tradeCaptureIdVersion: patch.idVersion,
          lastLegIdRequested:
            potentialLastLegId > bulk.lastLegIdRequested
              ? potentialLastLegId
              : bulk.lastLegIdRequested,
          legs: [...bulk.excludedLegs, ...legsIds]
            .sort((a, b) => Number(a) - Number(b))
            .reduce((acc, val) => {
              if (!acc.includes(val)) {
                acc.push(val);
              }
              return acc;
            }, [] as string[]),
          values: {
            ...bulk.values,
            ...patch.values,
          },
          errors: {
            ...errorsToKeep,
            ...patch.errors,
          },
          inputs: {},
        };
      });
    case 'BULK_SELECTION_CHANGED':
      return updateKey(state, action.bulkId, bulk => ({
        ...bulk,
        selectedLegs: action.legIds,
      }));
    case 'BULK_LEG_ADDED':
      return updateKey(state, action.bulkId, bulk => ({
        propertiesRequested: true,
        lastLegIdRequested: bulk.lastLegIdRequested + 1,
      }));
    case 'BULK_RESET':
      return updateKey(state, action.bulkId, bulk => ({
        propertiesRequested: true,
        lastLegIdRequested: bulk.lastLegIdRequested + 1,
        selectedLegs: [],
        excludedLegs: [],
      }));
    case 'BULK_RESET_ALL':
      return updateKey(state, action.bulkId, () => emptyFxBulkState);
    case 'BULK_LEGS_EXCLUDED':
      return updateKey(state, action.bulkId, bulk => ({
        ...bulk,
        excludedLegs: action.legIds,
      }));
    case 'BULK_LEGS_REMOVED':
      return updateKey(state, action.bulkId, bulk => ({
        propertiesRequested: true,
        excludedLegs: bulk.excludedLegs.filter(id => !action.legIds.includes(id)),
        selectedLegs: bulk.selectedLegs.filter(id => !action.legIds.includes(id)),
      }));
    case 'BULK_RFS_CLEAR_ERROR':
      return updateKey(state, action.bulkId, _bulk => ({
        lastStreamError: null,
      }));
    case 'BULK_RFS_STARTED':
      return updateKey(state, action.bulkId, () => ({
        priceRecords: [],
        rfsStartedAt: action.rfsStartedAt,
        currentStreamId: action.streamId,
        lastStreamError: null,
      }));
    case 'BULK_QUOTE_RECEIVED':
      return updateKey(state, action.bulkId, _bulk => ({
        step: 2,
      }));
    case 'BULK_RFS_FAILED':
      return updateKey(state, action.bulkId, _bulk => ({
        step: 1,
        currentStreamId: null,
        lastStreamError: action.error,
      }));
    case 'BULK_RFS_CANCEL':
    case 'BULK_RFS_TERMINATED':
    case 'BULK_RESTART':
      return updateKey(state, action.bulkId, _bulk => ({
        step: 1,
        currentStreamId: null,
        currentExecutionId: null,
      }));
    case 'BULK_EXECUTION_SENT':
      return updateKey(state, action.bulkId, _bulk => ({
        step: 3,
        currentStreamId: null,
        currentExecutionId: action.executionId,
      }));
    case 'RECORD_PRICE_SUCCEEDED':
    case 'RECORD_PRICE_FAILED':
      if (action.instrument !== 'Bulk') {
        return state;
      }
      return updateKey(
        state,
        action.tileId,
        addPriceRecorded({
          priceRecord: {
            error: action.error,
            prices: action.error === true ? undefined : action.prices,
            timestamp: action.timestamp,
          },
        }),
      );
    case 'FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Bulk'
        ? state
        : updateKey(state, action.quoteId, ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));
    case 'ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamId,
      }));
    case 'ESP_STREAM_RECONNECTED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamKey,
      }));
    case 'ESP_STREAM_TILE_UNSUBSCRIBE':
    case 'ESP_STREAM_KEY_REQUEST_PENDING':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: null,
      }));
    default:
      return bulkValidationReducer(state, action);
  }
};
