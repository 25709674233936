import type { LegType } from 'state/fxOptions/model/optionsLegs';
import type { CurrencyChoice, Possible, Side } from 'state/share/productModel/litterals';
import type {
  Legs,
  TcErrors,
  ErrorLegs,
  TradeCaptureRequestWrapper,
  TradeCaptureResponseWrapper,
  TcWarnings,
  WarningLegs,
} from '../tradeCaptureModel';

export type TradeCaptureOptionChanges = Partial<TradeCaptureOption> & Legs<TCOptionLegValues>;
export type TradeCaptureOptionErrors = TcErrors<TradeCaptureOption> &
  NestedTradeCaptureErrors<TradeCaptureOptionLegPreviousValues | TCOptionHedgeValues>;

interface NestedTradeCaptureErrors<TLeg> {
  legs?: {
    [legId: string]: TcErrors<TLeg> &
      // for options, error legs can be nested 😣
      ErrorLegs<TLeg>;
  };
}

export type TradeCaptureOptionWarnings = TcWarnings<TradeCaptureOption> &
  NestedTradeCaptureWarnings<TradeCaptureOptionLegPreviousValues | TCOptionHedgeValues>;

interface NestedTradeCaptureWarnings<TLeg> {
  legs?: {
    [legId: string]: TcWarnings<TLeg> &
      // for options, error legs can be nested 😣
      WarningLegs<TLeg>;
  };
}

export type TradeCaptureOptionRequestWrapper =
  TradeCaptureRequestWrapper<TradeCaptureOptionChanges>;

export type TradeCaptureOptionResponseWrapper = TradeCaptureResponseWrapper<
  TradeCaptureOptionChanges,
  TradeCaptureOptionErrors,
  TradeCaptureOptionWarnings
>;

export interface TradeCaptureOption {
  currencyPair: string | null;
  hedgeType: TcHedgeType;
  hedgePaymentDate: string | null;
  hedgeAmountInCcy1: number | null;
  hedgeAmountInCcy1String: string | null;
  hedgeAmountInCcy2: number | null;
  hedgeAmountInCcy2String: string | null;
  hedgePrice: number | null;
  hedgePriceString: string | null;
  markupCurrency: string | null;
  amountReference: number | null;
  amountReferenceCurrency: string | null;
  isOverriddenAmountReference: boolean;
  isReadyToPrice: boolean;
  isPriceObsolete: boolean;
  isProductObsolete: boolean;
  premiumPaymentDateString: string | null;
  premiumDate: string | null;
  premiumDateTenor: string | null;
  isInFine: boolean | null;
}

export interface TradeCaptureOptionPreviousValues {
  currencyPair: string | null;
  hedgeType: TcHedgeType;
  type: TcHedgeType;
  hedgeAmountInCcy1: number | null;
  amountInCcy1: number | null;
  hedgeAmountInCcy1String: string | null;
  amountInCcy1String: string | null;
  hedgeAmountInCcy2: number | null;
  amountInCcy2: number | null;
  hedgeAmountInCcy2String: string | null;
  amountInCcy2String: string | null;
  hedgePrice: number | null;
  price: number | null;
  hedgePriceString: string | null;
  priceString: string | null;
  amountReference: number | null;
  premiumPaymentDate: string | null;
}

export type PremiumType =
  | 'AmountCurrency1'
  | 'AmountCurrency2'
  | 'SmiledVolatility1'
  | 'SmiledVolatility2'
  | 'Pip12'
  | 'Pip21'
  | 'PercentageCurrency1'
  | 'PercentageCurrency2';

export const tradeCaptureOptionLegCallPutType = ['Call', 'Put'] as const;
export type TradeCaptureOptionLegCallPutType = typeof tradeCaptureOptionLegCallPutType[number];

// todo 4285 - see with Sylvain if values should be identical in setltlementType between TCmodel and our internal state and include forward ?
export const tradeCaptureOptionLegSettlementType = ['Cash', 'Physical', 'NDF'] as const;
export type TradeCaptureOptionLegSettlementType =
  typeof tradeCaptureOptionLegSettlementType[number];

export const tradeCaptureOptionLegSolvableType = [
  'Strike',
  'Amount1',
  'PremiumPaymentAmount',
] as const;
export type TradeCaptureOptionLegSolvableType = typeof tradeCaptureOptionLegSolvableType[number];

/**
 * @todo split in shared, patch -> tc (upward) and tc -> patch (downward)
 */
export interface TCOptionLegValues {
  cutOffMarketPlace: string | null;
  possibleCutOffMarketPlaces: Possible<string> | null;
  currencyPair: string;
  currency1: string;
  currency2: string;
  type: TradeCaptureOptionLegCallPutType | null;
  expiryDate: string | null;
  expiryDateString: string | null;
  expiryDateTenor: string | null;
  deliveryDate: string | null;
  deliveryDateString: string | null;
  deliveryDateTenor: string | null;
  settlementType: TradeCaptureOptionLegSettlementType | null;
  possibleSettlementTypes: Possible<TradeCaptureOptionLegSettlementType> | null;
  cashSettlementCurrency: string | null;
  possibleCashSettlementCurrencies: Possible<string> | null;
  fixingReference1: string | null;
  possibleFixingReferences1: Possible<string> | null;
  fixingReference2: string | null;
  possibleFixingReferences2: Possible<string> | null;
  isCrossed: boolean | null;
  crossCurrency: string | null;
  possibleCrossCurrencies: Possible<string> | null;
  strikeString: string | null;
  strikeShortcut: string | null;
  strike: number | null;
  strikeInDelta: string | null;
  strikeWithQuotity: number | null;
  amount1String: string;
  amount2String: string;
  amount1: number | null;
  amount2: number | null;
  negotiatedCurrency: CurrencyChoice;
  premiumPaymentDate: string | null;
  premiumPaymentDateString: string | null;
  premiumPaymentDateTenor: string | null;
  isInFine: boolean;
  side: Side | null;
  clientVolAsk: number | null;
  clientVolBid: number | null;
  clientVolAskString: string | null;
  clientVolBidString: string | null;
  premiumPaymentAmountBid: number | null;
  premiumPaymentAmountBidString: string | null;
  premiumPaymentAmountAsk: number | null;
  premiumPaymentAmountAskString: string | null;
  premiumType: PremiumType | null; // Used by TC to determine if they should tell us price is obsolete
  markupAmount: number | null;
  markupAmountString: string | null;
  solvable: TradeCaptureOptionLegSolvableType | null;
  productName: LegType | 'FxOptionHedge';
  isReadyToPrice: boolean;
  isPriceObsolete: boolean;
  isProductObsolete: boolean;
  hedgeType: string | undefined;
  hedgeAmountInCcy1: number | null;
  hedgeAmountInCcy1String: string | null;
  hedgeAmountInCcy2: number | null;
  hedgeAmountInCcy2String: string | null;
  hedgePrice: number | null;
  hedgePriceString: string | null;
  legs: {
    [key: string]: Partial<TCOptionLegValues>;
  };
}

export interface TradeCaptureFxOptionMultileg {
  productName: Extract<LegType, 'FxOptionMultileg'>;
  legs: {
    [key: string]: Partial<TCOptionLegValues> | Partial<TCOptionHedgeValues>;
  };
}

export interface TradeCaptureVanillaOptionLeg extends TCOptionLegValues {
  productName: Extract<LegType, 'Vanilla'>;
}

export function isVanilla(leg: Partial<TCOptionLegValues>): boolean {
  return leg.productName === 'Vanilla';
}

export interface TradeCaptureOptionLegPreviousValues {
  expiryDate: string | null;
  expiryDateTenor: string | null;
  strike: number | null;
  strikeInDelta: string | null;
  amount1: number | null;
  amount2: number | null;
  deliveryDate: string | null;
  markupAmount: number | null;
  premiumPaymentAmountBid: number | null;
  premiumPaymentAmountAsk: number | null;
  premiumPaymentDate: string | null;
  clientVolAsk: number | null;
  clientVolBid: number | null;
}

export type TcHedgeType = 'None' | 'Spot' | 'Forward';

export interface TCOptionHedgeValues {
  productName: 'FxOptionHedge';
  type: 'Spot' | 'Forward';
  deliveryDateTenor: string | null;
  deliveryDate: string | null;
  deliveryDateString: string | null;
  amountInCcy1: number | null;
  amountInCcy1String: string | null;
  amountInCcy2: null;
  amountInCcy2String: string | null;
  price: number | null;
  priceString: string | null;
}

export interface TradeCaptureStrippingRequest {
  idVersion: number;
  frequency: StrippingFrequency;
  startDate: string;
  iterations: number | undefined;
  endDate: string | undefined;
}

export const strippingFrequencies = ['Daily', 'Weekly', 'Monthly'] as const;

export type StrippingFrequency = typeof strippingFrequencies[number];
