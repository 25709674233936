import type { Client } from 'state/referenceData/referenceDataModel';
import { normalize } from './string';

export const filterClientsBy = (searchString: string) => {
  const normalizedSearch = normalize(searchString);
  return ({ companyName, companyId, externalCompanyName }: Client) =>
    `${companyId}`.includes(searchString) ||
    normalize(companyName).includes(normalizedSearch) ||
    normalize(externalCompanyName ?? '').includes(normalizedSearch);
};
