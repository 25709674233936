import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { Collection } from 'typings/utils';
import type { GridItemPosition } from 'state/gridLayout/gridLayoutModels';
import type { ItemPosition } from 'state/freeLayout/freeLayoutModel';

export interface FreeLayoutContainerConnectOwnProps {
  tabId: string;
}

export interface FreeLayoutContainerConnectStateProps {
  itemPositions: Collection<GridItemPosition>;
}

export interface FreeLayoutContainerConnectDispatchProps {
  onPositionChanged: (itemId: string, position: ItemPosition) => void;
}

export type FreeLayoutContainerConnectSelectorsKeys = 'getItemPositionsByLayoutId';
export type FreeLayoutContainerConnectSelectors = Pick<
  Selectors,
  FreeLayoutContainerConnectSelectorsKeys
>;

export const mapStateToPropsFreeLayoutContainer: MapStateToPropsHOF<
  FreeLayoutContainerConnectStateProps,
  FreeLayoutContainerConnectOwnProps,
  AppState,
  FreeLayoutContainerConnectSelectors
> =
  sl =>
  (state, { tabId }) => ({
    itemPositions: sl.getItemPositionsByLayoutId(state, tabId),
  });

// you can whitelist action creators after implementation
export type FreeLayoutContainerConnectActionCreators = ActionCreators;
/*
export type FreeLayoutContainerConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type FreeLayoutContainerConnectActionCreators = Pick<ActionCreators, FreeLayoutContainerConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsFreeLayoutContainer: MapDispatchToPropsHOF<
  FreeLayoutContainerConnectDispatchProps,
  FreeLayoutContainerConnectOwnProps,
  FreeLayoutContainerConnectActionCreators
> =
  ac =>
  (dispatch, { tabId }) => ({
    onPositionChanged: (itemId: string, position: ItemPosition) => {
      dispatch(ac.freeLayoutItemPositionChanged(tabId, itemId, position));
    },
  });
