import { getAccumulatorState } from 'state/fxAccumulators/selectors/accumulator';
import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';

export function getAccumulatorRfsState(state: AppState, streamId: string) {
  return throwIfUndefined(
    state.fxAccumulators.rfsStreams[streamId],
    `Target Accumulator stream state for ${streamId} does not exist`,
  );
}

export function getAccumulatorRfsStatus(state: AppState, streamId: string | null) {
  if (streamId === null) {
    return null;
  }
  const streamState = getAccumulatorRfsState(state, streamId);
  return streamState.status;
}

export function getAccumulatorRfsQuote(state: AppState, streamId: string | null) {
  if (streamId === null) {
    return null;
  }
  const streamState = getAccumulatorRfsState(state, streamId);
  if (streamState.status === 'PRICING') {
    return streamState.quote;
  }
}

export function getAccumulatorRfsQuoteId(state: AppState, streamId: string | null) {
  const quote = getAccumulatorRfsQuote(state, streamId);
  return quote?.quoteId;
}

export function getAccumulatorStreamError(state: AppState, quoteId: string) {
  const targetAccu = getAccumulatorState(state, quoteId);
  return targetAccu.lastStreamError;
}

export function getAccumulatorTraderId(state: AppState, streamId: string) {
  if (streamId === null) {
    return null;
  }
  const streamState = getAccumulatorRfsState(state, streamId);
  if (
    streamState.status === 'AWAITING' ||
    streamState.status === 'PRICING' ||
    streamState.status === 'EXPIRED'
  ) {
    return streamState.traderId;
  }
  return null;
}

export function getAccumulatorQuoteContribution(state: AppState, streamId: string) {
  if (streamId === null) {
    return null;
  }
  const streamState = getAccumulatorRfsState(state, streamId);
  if (streamState.status === 'PRICING' || streamState.status === 'EXPIRED') {
    return streamState.quoteContribution;
  }
  return null;
}

export function getAccumulatorRfsQuoteIsExecutable(state: AppState, streamId: string | null) {
  if (streamId === null) {
    return null;
  }
  const streamState = getAccumulatorRfsState(state, streamId);
  if (streamState.status === 'PRICING') {
    return streamState.quote.quoteType === 'Tradeable';
  }
  return true;
}
