export type ObjectPick<T, K> = Pick<T, Extract<keyof T, K>>;

export function pick<K extends string>(...properties: readonly K[]) {
  return <T extends Readonly<{}>>(obj: T): ObjectPick<T, K> =>
    properties.reduce((handle, property) => {
      if (obj.hasOwnProperty(property)) {
        const key = property as Extract<keyof T, K>;
        handle[key] = obj[key];
      }
      return handle;
    }, {} as ObjectPick<T, K>);
}
