import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import {
  mapDispatchToPropsViewSgLogExecutionError,
  mapStateToPropsViewSgLogExecutionError,
} from './connectViewSgLogExecutionError';
import { mapStateToPropsCurrencyPair } from './connectCurrencyPair';
import { mapStateToPropsExecStatus, mapDispatchToPropsExecStatus } from './connectExecutionStatus';
import { mapStateToPropsInstrument } from './connectInstrument';
import { mapStateToPropsTraderInfos } from './connectTraderInfos';
import { renderPropsConnect } from 'components/share/renderPropsConnect';
import { withQuoteId } from 'components/contexts';
import { mapDispatchToPropsMifidButton, mapStateToPropsMifidButton } from './connectMifidButton';
import { mapDispatchToPropsFieldError } from './connectFieldError';
import {
  mapStateToPropsOrderResultOverlay,
  mapDispatchToPropsOrderResultOverlay,
} from './connectOrderResultOverlay';

export const connectViewSgLogExecutionError = connect(
  mapStateToPropsViewSgLogExecutionError(selectors),
  mapDispatchToPropsViewSgLogExecutionError(actionCreators),
);

export const connectCurrencyPair = connect(mapStateToPropsCurrencyPair(selectors));

export const CurrencyPairRenderProps = withQuoteId(
  renderPropsConnect(mapStateToPropsCurrencyPair(selectors)),
);

export const connectTileExecutionStatus = connect(
  mapStateToPropsExecStatus(selectors),
  mapDispatchToPropsExecStatus(actionCreators),
);

export const connectInstrument = connect(mapStateToPropsInstrument(selectors));

export const connectTraderInfos = connect(mapStateToPropsTraderInfos(selectors));

export const connectMifidButton = connect(
  mapStateToPropsMifidButton(selectors),
  mapDispatchToPropsMifidButton(actionCreators),
);

export const connectFieldError = connect(null, mapDispatchToPropsFieldError(actionCreators));

export const connectOrderOverlay = connect(
  mapStateToPropsOrderResultOverlay(selectors),
  mapDispatchToPropsOrderResultOverlay(actionCreators),
);
