import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import { map, ignoreElements } from 'rxjs/operators';

export interface ICashStreamCreateRequest {
  dataStreamId: string;
  bdrId: number;
  currencyPair: string;
}

export interface ICashStreamDeleteRequest {
  dataStreamId: string;
  streamKey: string;
}

export interface ICashStreamsRefreshRequest {
  dataStreamId: string;
  streamKeys: readonly string[];
}

export interface EspSubscribeRequest {
  dataStreamId: string;
  streamKey: string;
  tileId: string;
  currencyPair: string;
  companyId: number;
}

export interface EspSubscribeResponse {
  streamKey: string;
  tileId: string;
  espTiering: string;
  currencyPair: string;
  companyId: number;
  isSuccess: boolean;
}

export type ICashStreamRequestApi = (
  request: EspSubscribeRequest,
) => Observable<EspSubscribeResponse>;

export type ICashStreamsRefreshApi = (request: ICashStreamsRefreshRequest) => Observable<void>;

export function cashStreamRequestApi(http: ISgmeHttp): ICashStreamRequestApi {
  return request =>
    http
      .post<EspSubscribeResponse[]>('api/fxSpotFwd/esp/request', [request])
      .pipe(map(res => res.payload[0]));
}

export function cashStreamsRefreshApi(http: ISgmeHttp): ICashStreamsRefreshApi {
  return (request: ICashStreamsRefreshRequest) =>
    http.post<void>('api/fxSpotFwd/esp/refresh', request).pipe(ignoreElements());
}
