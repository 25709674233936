import type { FxAccumulatorQuoteWithPrice } from '../fxAccumulatorsModel';
import type { IStreamError } from 'state/globalError/globalErrorModel';
import type { QuoteContributionType } from 'state/share/productModel/litterals';

export interface AccumulatorRfsStarted {
  type: 'ACCUMULATOR_RFS_STARTED';
  quoteId: string;
  rfsId: string;
  rfsStartedAt: Date;
  forceTradeable: boolean;
}

export function accumulatorRfsStarted(
  quoteId: string,
  rfsId: string,
  rfsStartedAt: Date,
  forceTradeable: boolean,
): AccumulatorRfsStarted {
  return {
    type: 'ACCUMULATOR_RFS_STARTED',
    quoteId,
    rfsId,
    rfsStartedAt,
    forceTradeable,
  };
}

export interface AccumulatorRfsRequestEpic {
  type: 'ACCUMULATOR_RFS_REQUEST_EPIC';
  quoteId: string;
  forceTradeable: boolean;
  bypassCheck: boolean;
}

export function accumulatorRfsRequestEpic(
  quoteId: string,
  forceTradeable: boolean,
  bypassCheck: boolean,
): AccumulatorRfsRequestEpic {
  return {
    type: 'ACCUMULATOR_RFS_REQUEST_EPIC',
    quoteId,
    forceTradeable,
    bypassCheck,
  };
}

export interface AccumulatorRfsQuoteReceived {
  type: 'ACCUMULATOR_RFS_QUOTE_RECEIVED';
  quoteId: string;
  rfsId: string;
  quote: FxAccumulatorQuoteWithPrice;
  quoteDate: Date;
  tiering: string | null;
  skippedLimitCheck: boolean;
  quoteContribution: QuoteContributionType | null;
}

export function accumulatorRfsQuoteReceived(
  quoteId: string,
  rfsId: string,
  quote: FxAccumulatorQuoteWithPrice,
  quoteDate: Date,
  tiering: string | null,
  skippedLimitCheck: boolean,
  quoteContribution: QuoteContributionType | null,
): AccumulatorRfsQuoteReceived {
  return {
    type: 'ACCUMULATOR_RFS_QUOTE_RECEIVED',
    quoteId,
    quote,
    rfsId,
    quoteDate,
    tiering,
    skippedLimitCheck,
    quoteContribution,
  };
}

export interface AccumulatorTraderNotificationReceived {
  type: 'ACCUMULATOR_TRADER_NOTIFICATION_RECEIVED';
  quoteId: string;
  rfsId: string;
  traderId: string | null;
  quoteContribution: QuoteContributionType | null;
}

export function accumulatorTraderNotificationReceived(
  quoteId: string,
  rfsId: string,
  traderId: string | null,
  quoteContribution: QuoteContributionType | null,
): AccumulatorTraderNotificationReceived {
  return {
    type: 'ACCUMULATOR_TRADER_NOTIFICATION_RECEIVED',
    quoteId,
    rfsId,
    traderId,
    quoteContribution,
  };
}

export interface AccumulatorRfsFailed {
  type: 'ACCUMULATOR_RFS_FAILED';
  quoteId: string;
  rfsId: string;
  error: IStreamError;
}

export function accumulatorRfsFailed(
  quoteId: string,
  rfsId: string,
  error: IStreamError,
): AccumulatorRfsFailed {
  return {
    type: 'ACCUMULATOR_RFS_FAILED',
    quoteId,
    rfsId,
    error,
  };
}

export interface AccumulatorRfsCanceled {
  type: 'ACCUMULATOR_RFS_CANCELED';
  quoteId: string;
  rfsId: string;
  shouldKeepAsExpired: boolean;
}

export function accumulatorRfsCanceled(
  quoteId: string,
  rfsId: string,
  shouldKeepAsExpired: boolean,
): AccumulatorRfsCanceled {
  return {
    type: 'ACCUMULATOR_RFS_CANCELED',
    quoteId,
    rfsId,
    shouldKeepAsExpired,
  };
}
export interface AccumulatorRfsCanceledSent {
  type: 'ACCUMULATOR_RFS_CANCELED_SENT';
  quoteId: string;
  rfsId: string;
}

export function accumulatorRfsCanceledSent(
  quoteId: string,
  rfsId: string,
): AccumulatorRfsCanceledSent {
  return {
    type: 'ACCUMULATOR_RFS_CANCELED_SENT',
    quoteId,
    rfsId,
  };
}

export interface AccumulatorRfsCancelFailed {
  type: 'ACCUMULATOR_RFS_CANCEL_FAILED';
  quoteId: string;
  rfsId: string;
}

export function accumulatorRfsCancelFailed(
  quoteId: string,
  rfsId: string,
): AccumulatorRfsCancelFailed {
  return {
    type: 'ACCUMULATOR_RFS_CANCEL_FAILED',
    quoteId,
    rfsId,
  };
}

export interface AccumulatorRfsTerminated {
  type: 'ACCUMULATOR_RFS_TERMINATED';
  quoteId: string;
  rfsId: string;
  shouldKeepAsExpired: boolean;
}

export function accumulatorRfsTerminated(
  quoteId: string,
  rfsId: string,
  shouldKeepAsExpired: boolean,
): AccumulatorRfsTerminated {
  return {
    type: 'ACCUMULATOR_RFS_TERMINATED',
    quoteId,
    rfsId,
    shouldKeepAsExpired,
  };
}

export interface AccumulatorRfsLastRemoved {
  type: 'ACCUMULATOR_RFS_LAST_REMOVED';
  quoteId: string;
  rfsId: string;
}

export function accumulatorRfsLastRemoved(
  quoteId: string,
  rfsId: string,
): AccumulatorRfsLastRemoved {
  return {
    type: 'ACCUMULATOR_RFS_LAST_REMOVED',
    quoteId,
    rfsId,
  };
}

export interface AccumulatorRfsClearError {
  type: 'ACCUMULATOR_RFS_CLEAR_ERROR';
  quoteId: string;
}

export function accumulatorRfsClearError(quoteId: string): AccumulatorRfsClearError {
  return {
    type: 'ACCUMULATOR_RFS_CLEAR_ERROR',
    quoteId,
  };
}
