import { isDefined } from '@sgme/fp';
import type {
  IBlotterNotificationStreamData,
  IDisconnectionNotificationData,
  IEspStreamData,
  IRequestForStreamData,
  IStreamingData,
} from './typings';

const blotterDataTypes = ['BLOTTER.TRADE', 'BLOTTER.ORDER'];
export function isBlotterLiveNotification(
  event: IStreamingData,
): event is IBlotterNotificationStreamData {
  return blotterDataTypes.includes(event.data.type);
}

export function isDisconnectionNotification(
  event: IStreamingData,
): event is IDisconnectionNotificationData {
  return event.data.type === 'DISCONNECTION.NOTIFICATION';
}

const streamingDataTypes = ['ESP.HEARTBEAT', 'ESP.CLIENT.PRICE', 'ESP.TRADER.PRICE'];
export function isEspStreamData(event: IStreamingData): event is IEspStreamData {
  return event.data.streamId !== undefined && streamingDataTypes.includes(event.data.priceType);
}

export function isRequestForStreamData(event: IStreamingData): event is IRequestForStreamData {
  return isDefined(event.data.RequestId);
}
