import type { Store } from 'redux';
import { rafSchedulerTimer, reduxSubTimer } from 'utils/Timer/Timers';

type Scheduler = (listener: Listener) => Listener;
type Listener = () => void;

export const patchStoreSubscribeWithScheduler =
  (scheduler: Scheduler) =>
  <S>(store: Store<S>): Store<S> => ({
    ...store,
    subscribe: (listener: Listener) =>
      store.subscribe(rafSchedulerTimer(scheduler(reduxSubTimer(listener)))),
  });
