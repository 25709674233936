import type {
  AccumulatorProductName,
  AccumulatorScheduleValues,
  TargetAccuType,
} from 'state/fxAccumulators/fxAccumulatorsModel';
import { calendarProperties, type SchedulerCalendarProperty } from './calendarProperties';
import { formatDateTimeWithLocale } from 'utils/dateFormats';
import { isDefined } from '@sgme/fp';

export function getForwardAccumulatorCalendarProperties(isMaturitySettlementFrequency: boolean) {
  const propertiesToRemove = ['PIVOT', 'strikeUp', 'strikeDown', 'ekiUp', 'ekiDown'];

  if (isMaturitySettlementFrequency) {
    propertiesToRemove.push('paymentDate');
  }

  return calendarProperties.filter((property) => !propertiesToRemove.includes(property));
}

export function getTargetAccumulatorCalendarProperties(
  accumulatorType: TargetAccuType | 'ForwardAccu',
): Array<Partial<SchedulerCalendarProperty>> {
  switch (accumulatorType) {
    case 'EKI':
      return ['fixingDate', 'paymentDate', 'EKI', 'strike', 'amount', 'leverageAmount', 'deleteIcon'];
    case 'PIVOT':
      return ['fixingDate', 'paymentDate', 'strikeUp', 'PIVOT', 'strikeDown', 'amount', 'leverageAmount', 'deleteIcon'];
    case 'PIVOTEKI':
      return [
        'fixingDate',
        'paymentDate',
        'ekiUp',
        'strikeUp',
        'PIVOT',
        'strikeDown',
        'ekiDown',
        'amount',
        'leverageAmount',
        'deleteIcon',
      ];

    default:
      return ['fixingDate', 'paymentDate', 'strike', 'amount', 'leverageAmount', 'deleteIcon'];
  }
}

export function downloadCSV(content: string) {
  const uri = `data:text/csv;charset=utf-8,${escape(content)}`;
  const link = document.createElement('a');
  link.href = uri;
  link.className = 'd-none';
  link.download = `FX ${formatDateTimeWithLocale(new Date(), 'en')}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function headerExport(property: SchedulerCalendarProperty) {
  switch (property) {
    case 'PIVOT':
      return 'accu.scheduler.calendar.pivot';
    case 'amount':
      return 'accu.scheduler.calendar.notionalToExport';
    case 'fixingDate':
      return 'accu.scheduler.calendar.fixingDate';
    case 'leverageAmount':
      return 'accu.scheduler.calendar.leverageAmountToExport';
    case 'paymentDate':
      return 'accu.scheduler.calendar.paymentDate';
    case 'strike':
      return 'accu.scheduler.calendar.strike';
    case 'strikeUp':
      return 'accu.scheduler.calendar.strikeUp';
    case 'strikeDown':
      return 'accu.scheduler.calendar.strikeDown';
    case 'ekiDown':
      return 'accu.scheduler.calendar.ekiDown';
    case 'ekiUp':
      return 'accu.scheduler.calendar.ekiUp';
    case 'EKI':
      return 'accu.scheduler.calendar.eki';
    case 'step':
      return 'accu.scheduler.calendar.step';
    default:
      break;
  }
}

export const mapScheduleValues = (
  scheduleValues: Array<Readonly<AccumulatorScheduleValues | undefined>>,
  calendarProps: SchedulerCalendarProperty[],
  productName: AccumulatorProductName,
) =>
  scheduleValues.map((value) => {
    if (isDefined(value)) {
      return Object.entries(value).reduce((acc, [key, usedValue]) => {
        if (calendarProps.includes(key as Partial<SchedulerCalendarProperty>)) {
          acc[key] = usedValue;
        }

        const isTargetAccumulator = productName === 'FxTargetAccumulator';

        if (calendarProps.includes('EKI')) {
          if ((key === 'step' && isTargetAccumulator) || (key === 'ekiTrigger' && !isTargetAccumulator)) {
            acc.EKI = usedValue;
          }
        }

        if (calendarProps.includes('PIVOT') && key === 'step') {
          acc.PIVOT = usedValue;
        }

        if (calendarProps.includes('ekiDown') && key === 'stepDown') {
          acc.ekiDown = usedValue;
        }

        if (calendarProps.includes('ekiUp') && key === 'stepUp') {
          acc.ekiUp = usedValue;
        }

        return acc;
      }, {} as Record<string, string | number | null>);
    }
  });
