import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

export interface AddTabButtonConnectOwnProps {}
export interface AddTabButtonConnectDispatchProps {
  addTab(): void;
}

// you can whitelist action creators after implementation
export type AddTabButtonConnectActionCreators = ActionCreators;
/*
export type AddTabButtonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type AddTabButtonConnectActionCreators = Pick<ActionCreators, AddTabButtonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsAddTabButton: MapDispatchToPropsHOF<
  AddTabButtonConnectDispatchProps,
  AddTabButtonConnectOwnProps,
  AddTabButtonConnectActionCreators
> = ac => dispatch => ({
  addTab: () => dispatch(ac.clientWorkspaceTabAddedThunk('tiles')),
});
