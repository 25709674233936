import { isNotDefined } from '@sgme/fp';
import type * as blotterOrderSelectors from 'state/blotter/selectors';
import { isAlgoOrder } from 'state/fxOrders/fxOrdersModel';
import type { AppState } from 'state/model';
import { getFullDateForOMS } from 'utils/dateFormats';
import { fieldData } from 'utils/fieldSelectors';
import { dateAndTimeToUtc } from '../order/utils';

type ValueOf<T> = T[keyof T];

type BlotterOrderSelectors = typeof blotterOrderSelectors;

export function getDatesDataHOF(sl: BlotterOrderSelectors) {
  return (state: AppState, orderId: string) => {
    const get = (selector: ValueOf<Pick<BlotterOrderSelectors, 'getBlotterOrderStartDate'>>) =>
      fieldData(selector(state, orderId)).data ?? undefined;
    const startDate = get(sl.getBlotterOrderStartDate);
    const startTime = get(sl.getBlotterOrderStartTime);
    const endDate = get(sl.getBlotterOrderEndDate);
    const endTime = get(sl.getBlotterOrderEndTime);
    return {
      startDateUtc: getFullDateForOMS(dateAndTimeToUtc(startDate, startTime)),
      endDateUtc: getFullDateForOMS(dateAndTimeToUtc(endDate, endTime)),
    };
  };
}

export function getEditedDatesDataHOF(sl: BlotterOrderSelectors) {
  return (state: AppState, orderId: string) => {
    const get = (selector: ValueOf<Pick<BlotterOrderSelectors, 'getBlotterOrderStartDate'>>) =>
      fieldData(selector(state, orderId)).data ?? undefined;
    const startDate = get(sl.getBlotterEditedOrderStartDate);
    const startTime = get(sl.getBlotterEditedOrderStartTime);
    const endDate = get(sl.getBlotterEditedOrderEndDate);
    const endTime = get(sl.getBlotterEditedOrderEndTime);

    return {
      startDateUtc: getFullDateForOMS(dateAndTimeToUtc(startDate, startTime)),
      endDateUtc: getFullDateForOMS(dateAndTimeToUtc(endDate, endTime)),
    };
  };
}

export function getAmountsHOF(sl: BlotterOrderSelectors) {
  return (state: AppState, orderId: string) => {
    const amountCurrency = sl.getBlotterOrderNotionalCurrency(state, orderId);
    if (amountCurrency === null) {
      throw new Error(`amountCurrency shoud not be null for order ${orderId}`);
    }
    const currencyPair = sl.getBlotterOrderCurrencyPair(state, orderId);
    if (currencyPair === null) {
      throw new Error(`currencyPair shoud not be null for order ${orderId}`);
    }
    const isFirstCurrency = currencyPair.indexOf(amountCurrency) === 0;
    const notional = fieldData(sl.getBlotterOrderNotionalInput(state, orderId)).data;
    const amount = notional !== null ? Number(notional) : undefined;
    return {
      amountInCcy1: isFirstCurrency ? amount : undefined,
      amountInCcy2: !isFirstCurrency ? amount : undefined,
    };
  };
}

export function getEditedAmountsHOF(sl: BlotterOrderSelectors) {
  return (state: AppState, orderId: string) => {
    const amountCurrency = sl.getBlotterEditedOrderNotionalCurrency(state, orderId);
    if (amountCurrency === null) {
      throw new Error(`amountCurrency shoud not be null for order ${orderId}`);
    }
    const currencyPair = sl.getBlotterOrderCurrencyPair(state, orderId);
    if (currencyPair === null) {
      throw new Error(`currencyPair shoud not be null for order ${orderId}`);
    }
    const isFirstCurrency = currencyPair.indexOf(amountCurrency) === 0;
    const notional = fieldData(sl.getBlotterEditedOrderNotionalInput(state, orderId)).data;
    const amount = notional !== null ? Number(notional) : undefined;
    return {
      amountInCcy1: isFirstCurrency ? amount : undefined,
      amountInCcy2: !isFirstCurrency ? amount : undefined,
    };
  };
}

export function getLimitPriceHOF(sl: BlotterOrderSelectors) {
  return (state: AppState, orderId: string) => {
    const orderType = fieldData(sl.getBlotterOrderProduct(state, orderId)).data;
    const sharedProperty = isAlgoOrder(orderType)
      ? sl.getBlotterOrderNoWorseThan(state, orderId)
      : sl.getBlotterOrderLimitPriceInput(state, orderId);
    const data = fieldData(sharedProperty)?.data;

    if (data === undefined || typeof data === 'string') {
      return data;
    }

    return `${data}`;
  };
}

export function getEditedLimitPriceHOF(sl: BlotterOrderSelectors) {
  return (state: AppState, orderId: string) => {
    const orderType = fieldData(sl.getBlotterOrderProduct(state, orderId)).data;
    const sharedProperty = isAlgoOrder(orderType)
      ? sl.getBlotterEditedOrderNoWorseThan(state, orderId)
      : sl.getBlotterEditedOrderLimitPriceInput(state, orderId);
    const data = fieldData(sharedProperty)?.data;

    if (data === undefined || typeof data === 'string') {
      return data;
    }

    return `${data}`;
  };
}

export function getClipDataHOF(sl: BlotterOrderSelectors) {
  return (state: AppState, orderId: string) => {
    const clippingMode = fieldData(sl.getBlotterOrderClippingMode(state, orderId))?.data;
    if (isNotDefined(clippingMode)) {
      return { clippingMode: undefined, clipSize: undefined };
    }
    if (clippingMode !== 'Manual') {
      return { clippingMode, clipSize: undefined };
    }
    const rowClipSize = fieldData(sl.getBlotterOrderClipSize(state, orderId)).data;
    const clipSize = typeof rowClipSize === 'string' ? Number(rowClipSize) : rowClipSize ?? undefined;
    return { clippingMode, clipSize };
  };
}

export function getEditedClipDataHOF(sl: BlotterOrderSelectors) {
  return (state: AppState, orderId: string) => {
    const clippingMode = fieldData(sl.getBlotterEditedOrderClippingModeInput(state, orderId))?.data;
    if (isNotDefined(clippingMode)) {
      return { clippingMode: undefined, clipSize: undefined };
    }
    if (clippingMode !== 'Manual') {
      return { clippingMode, clipSize: undefined };
    }
    const rowClipSize = fieldData(sl.getBlotterEditedOrderClipSizeInput(state, orderId)).data;
    const clipSize = typeof rowClipSize === 'string' ? Number(rowClipSize) : rowClipSize ?? undefined;
    return { clippingMode, clipSize };
  };
}
