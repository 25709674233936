import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

export interface UserPrefButtonConnectOwnProps {}

export interface UserPrefButtonConnectDispatchProps {
  toggleUserPref(): void;
}

// you can whitelist action creators after implementation
export type UserPrefButtonConnectActionCreators = ActionCreators;
/*
export type UserPrefButtonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type UserPrefButtonConnectActionCreators = Pick<ActionCreators, UserPrefButtonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsUserPrefButton: MapDispatchToPropsHOF<
  UserPrefButtonConnectDispatchProps,
  UserPrefButtonConnectOwnProps,
  UserPrefButtonConnectActionCreators
> = ac => dispatch => ({
  toggleUserPref: () => dispatch(ac.userPreferenceToggleModal(true)),
});
