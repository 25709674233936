import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { LeggedInstrument } from 'state/referenceData/referenceDataModel';
import type { LegValueKeys } from 'state/share/productModel';
import type { AlertLevel, ValidationResult } from 'components/Form/Inputs/typings';

export interface LegFieldTooltipConnectOwnProps<I extends LeggedInstrument> {
  instrument: I;
  field: LegValueKeys<I>;
  quoteId: string;
  legId: string;
}

interface LegFieldNoTooltipConnectStateProps {
  alertLevel: undefined;
}

export interface LegFieldTooltipConnectStateProps {
  alertLevel: AlertLevel;
  messageId: string;
  seen: boolean;
}

export interface LegFieldTooltipConnectDispatchProps {
  setValidationState(result: ValidationResult): void;
  onSeen(): void;
}

export type LegFieldTooltipConnectSelectorsKeys = 'getLegFieldValidationState';
export type LegFieldTooltipConnectSelectors = Pick<Selectors, LegFieldTooltipConnectSelectorsKeys>;

export const mapStateToPropsLegFieldTooltip =
  (sl: LegFieldTooltipConnectSelectors) =>
  <I extends LeggedInstrument>(
    state: AppState,
    { instrument, field, quoteId, legId }: LegFieldTooltipConnectOwnProps<I>,
  ): LegFieldNoTooltipConnectStateProps | LegFieldTooltipConnectStateProps =>
    sl.getLegFieldValidationState(state, instrument, field, quoteId, legId);

export type LegFieldTooltipConnectActionCreatorsKeys =
  | 'legFieldTooltipSeen'
  | 'localLegFieldValidationSet'
  | 'localLegFieldValidationClear';
export type LegFieldTooltipConnectActionCreators = Pick<
  ActionCreators,
  LegFieldTooltipConnectActionCreatorsKeys
>;

export const mapDispatchToPropsLegFieldTooltip: MapDispatchToPropsHOF<
  LegFieldTooltipConnectDispatchProps,
  LegFieldTooltipConnectOwnProps<LeggedInstrument>,
  LegFieldTooltipConnectActionCreators
> =
  ac =>
  (dispatch, { instrument, field, quoteId, legId }): LegFieldTooltipConnectDispatchProps => ({
    onSeen: () => dispatch(ac.legFieldTooltipSeen(instrument, field, quoteId, legId)),
    setValidationState(result) {
      dispatch(
        result.status === 'valid'
          ? ac.localLegFieldValidationClear(instrument, quoteId, legId, field)
          : ac.localLegFieldValidationSet(
              instrument,
              quoteId,
              legId,
              field,
              result.status,
              result.messageId,
            ),
      );
    },
  });
