import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { ISgmeHttp } from 'utils/sgmeHttp';
export interface IDateApi {
  getDateFromTenor: (tenor: string, currencyPair: string) => Observable<Date>;
}

export const dateApi = (http: ISgmeHttp): IDateApi => ({
  getDateFromTenor(tenor: string, currencyPair: string) {
    return http
      .getJSON<string>(
        `api/dates/from-tenor/${tenor}?currencyPair=${encodeURIComponent(currencyPair)}`,
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      )
      .pipe(map(c => new Date(c.payload)));
  },
});
