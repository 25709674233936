import { createSelector } from 'reselect';
import type { MapStateToPropsFactoryHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { isEmpty } from '@sgme/fp';

export interface TabListConnectOwnProps {}

export interface TabListConnectStateProps {
  tabIds: readonly string[];
  activeTab: string | null;
  hasClients: boolean;
}

export type TabListConnectSelectorsKeys =
  | 'getClientWorkspaceTabs'
  | 'getClientWorkspaceActiveTab'
  | 'getAllUserClients';
export type TabBarConnectSelectors = Pick<Selectors, TabListConnectSelectorsKeys>;

export const mapStateToPropsTabList: MapStateToPropsFactoryHOF<
  TabListConnectStateProps,
  TabListConnectOwnProps,
  AppState,
  TabBarConnectSelectors
> = sl => () => {
  const getTabIds = createSelector(sl.getClientWorkspaceTabs, Object.keys);
  const getClientsIds = createSelector(sl.getAllUserClients, Object.keys);
  return state => ({
    tabIds: getTabIds(state),
    activeTab: sl.getClientWorkspaceActiveTab(state),
    hasClients: !isEmpty(getClientsIds(state)),
  });
};
