import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { IOptionQuoteRequestApi } from 'epics/option';
import { ignoreElements } from 'rxjs/operators';
export function optionQuoteRequestApi(http: ISgmeHttp): IOptionQuoteRequestApi {
  return {
    createQuoteRequest(request) {
      return http
        .post<void>('api/fxOption/rfs/request', request, { 'X-Correlation-ID': request.rfsId })
        .pipe(ignoreElements());
    },
  };
}
