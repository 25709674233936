import type { FreeLayout } from '../freeLayoutModel';
import type { Action } from 'state/actions';
import { type Patcher, addKey, updateKey, removeKey } from 'utils/stateMap';

export const freeLayoutStatePatcher = (action: Action): Patcher<FreeLayout> => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
      return (state: FreeLayout) => ({
        itemPositions: addKey(
          state.itemPositions,
          action.tileId,
          action.position || { top: 0, left: 0 },
        ),
      });
    case 'CLIENTWORKSPACE_TILE_DELETED':
      return (state: FreeLayout) => ({
        itemPositions: removeKey(state.itemPositions, action.tileId),
      });
    case 'FREELAYOUT_ITEM_POSITION_CHANGED': {
      return (state: FreeLayout) => ({
        itemPositions: updateKey(
          state.itemPositions,
          action.itemId,
          () => action.position || { top: 0, left: 0 },
        ),
      });
    }
  }
  return () => null;
};
