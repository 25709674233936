import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { fieldData } from 'utils/fieldSelectors';
import type { LegMappers, Mappers } from './typings';
import { liftToString } from './utils';
import { strictEntries } from 'utils/object/entries';
import type { IFxVanillaLegInputs } from 'state/fxOptions/model/optionsLegs';
import type { LegInputs } from '../productModel';
import { isVanillaLeg } from '../../fxOptions/utilities';
import { isDefined } from '@sgme/fp';
import type { IFxHedgeInputs } from '../../fxOptions/model/optionHedges';
import { FIRST_CURRENCY } from '../productModel/litterals';

export const optionMappers = (
  sl: Selectors,
): Mappers<'Option', 'premiumDateTenor' | 'isInFine'> => ({
  currencyPair: sl.getOptionCurrencyPair,
  premiumDate: sl.getOptionPremiumDate,
  hedgeType: sl.getOptionHedgeType,
  markupCurrency: sl.getOptionMarkupCurrency,
  amountReference: sl.getOptionAmountReference,
});

export const vanillaLegsMappers = (
  sl: Selectors,
): LegMappers<'Option', 'premiumDateTenor' | 'isInFine'> => ({
  productName: () => ({ value: 'Vanilla' as const }),
  legIds: () => ({ value: [undefined] as [undefined] | null, input: null as [undefined] | null }),
  optionType: sl.getOptionVanillaLegOptionType,
  side: sl.getOptionVanillaLegSide,
  expiryDate: sl.getOptionVanillaLegExpiry,
  expiryDateTenor: sl.getOptionVanillaLegExpiryTenor,
  strike: liftToString(sl.getOptionVanillaLegStrike),
  notionalAmount: liftToString(sl.getOptionVanillaLegNotional),
  notionalCurrency: sl.getOptionVanillaLegNotionalCurrency,
  deliveryDate: sl.getOptionVanillaLegDelivery,
  premiumBid: sl.getOptionVanillaLegPremiumBid,
  premiumAsk: sl.getOptionVanillaLegPremiumAsk,
  premiumDate: sl.getOptionVanillaLegPremiumDate,
  premiumCurrency: sl.getOptionVanillaLegPremiumCurrency,
  premiumTypeString: sl.getOptionVanillaLegPremiumTypeString,
  markup: liftToString(sl.getOptionVanillaLegMarkup),
  settlementType: sl.getOptionVanillaLegSettlementType,
  marketPlace: sl.getOptionVanillaLegMarketPlace,
  cashSettlementCurrency: sl.getOptionVanillaLegCashSettlementCurrency,
  fixingReference1: sl.getOptionVanillaLegFixingReference1,
  volatilityBid: sl.getOptionVanillaLegVolatilityBid,
  volatilityAsk: sl.getOptionVanillaLegVolatilityAsk,
});

export const getAllLegsInputs = (
  sl: Selectors,
  state: AppState,
  optionId: string,
): Record<string, Partial<LegInputs<'Option'>>> => {
  const allLegs = sl.getAllLegsOfOptionWithId(state, optionId);

  return allLegs.reduce((acc, [legId, leg]) => {
    if (isVanillaLeg(leg)) {
      const vanillaSelectors = strictEntries(vanillaLegsMappers(sl));

      acc[legId] = vanillaSelectors.reduce((vanillaLeg, [field, getter]) => {
        const legFieldValue = fieldData(getter!(state, legId)).data;

        // we do not need legIds on Vanilla for TC update
        if (isDefined(legFieldValue) && field !== 'legIds') {
          vanillaLeg[field] = legFieldValue;
        }
        return vanillaLeg;
      }, {} as Partial<IFxVanillaLegInputs>);
    } else {
      acc[legId] = {
        productName: leg.productName,
        legIds: leg.legIds,
      };
    }

    return acc;
  }, {} as Record<string, Partial<LegInputs<'Option'>>>);
};

export function getOptionLegInputs(
  sl: Selectors,
  state: AppState,
  legId: string,
): Partial<IFxVanillaLegInputs> {
  const legInputs = strictEntries(vanillaLegsMappers(sl)).reduce((vanillaLeg, [field, getter]) => {
    const legFieldValue = isDefined(legId) ? fieldData(getter!(state, legId)).data : undefined;

    // we do not need legIds on Vanilla for TC update
    if (isDefined(legFieldValue) && field !== 'legIds') {
      vanillaLeg[field] = legFieldValue;
    }

    return vanillaLeg;
  }, {} as Partial<IFxVanillaLegInputs>);

  return { productName: 'Vanilla', ...legInputs };
}

export const getAllHedgesInputs = (
  sl: Selectors,
  state: AppState,
  optionId: string,
): Record<string, Partial<IFxHedgeInputs>> => {
  const allHedges = sl.getAllHedgesOfOptionWithId(state, optionId);

  return allHedges.reduce((acc, [hedgeId, hedge]) => {
    acc[hedgeId] = {
      amount: hedge.inputs.amount ?? isDefined(hedge.values.amount) ? String(hedge.values.amount) : undefined,
      currency: hedge.inputs.currency ?? hedge.values.currency ?? FIRST_CURRENCY,
      rate: hedge.inputs.rate ?? isDefined(hedge.values.rate) ? String(hedge.values.rate) : undefined,
    }

    return acc;
  }, {} as Record<string, Partial<IFxHedgeInputs>>);
};
