import type { Thunk } from 'state';

export function cashRfsRequestedThunk(cashId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const { currentStreamId } = sl.getCashState(getState(), cashId);
    dispatch(ac.cashRfsRequestedEpic(cashId, currentStreamId));
  };
}

export function cashRfsCancelThunk(cashId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const { currentStreamId } = sl.getCashState(getState(), cashId);
    if (currentStreamId !== null) {
      dispatch(ac.cashRfsCancel(cashId, currentStreamId));
      dispatch(ac.cashRfsCancelEpic(cashId, currentStreamId));
    }
    dispatch(ac.cashRfsClearError(cashId));
  };
}
