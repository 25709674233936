import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { UserPreferencesData } from 'state/userPreferences';
import type {
  NotificationsType,
  TypeOfEmail,
} from 'components/share/EmailsInput/emailsInputModels';

export type UserPrefConnectOwnProps = UserPrefConnect;

export interface UserPrefConnectStateProps<K extends keyof UserPreferencesData> {
  userPrefValue: UserPreferencesData[K];
  splitNotificationsEmails: splitNotificationsEmails;
}
interface splitNotificationsEmails {
  cash: readonly string[];
  orders: readonly string[];
  options: readonly string[];
}
export interface UserPrefConnect {
  tooltipId?: string;
  notificationsType?: NotificationsType;
  live?: true;
}

export interface UserPrefConnectDispatchProps<K extends keyof UserPreferencesData> {
  onChangeUserPref(
    value: UserPreferencesData[K] extends string ? string : UserPreferencesData[K],
  ): void;
  onChangeUserPrefWithSplit(value: readonly string[] | null, typeofEmail: TypeOfEmail): void;
}

export type UserPrefConnectProps<K extends keyof UserPreferencesData> =
  UserPrefConnectStateProps<K> & UserPrefConnectDispatchProps<K> & UserPrefConnect;

export type UserPrefConnectSelectorsKeys = 'getUserPreferenceData';
export type UserPrefConnectSelectors = Pick<Selectors, UserPrefConnectSelectorsKeys>;

export const mapStateToPropsUserPref =
  <K extends keyof UserPreferencesData>(
    key: K,
  ): MapStateToPropsHOF<
    UserPrefConnectStateProps<K>,
    UserPrefConnectOwnProps,
    AppState,
    UserPrefConnectSelectors
  > =>
  sl =>
  (state, _ownProps) => {
    const splitNotificationsEmails = {
      cash: sl.getUserPreferenceData(state).splitNotificationsEmailsCash,
      orders: sl.getUserPreferenceData(state).splitNotificationsEmailsOrders,
      options: sl.getUserPreferenceData(state).splitNotificationsEmailsOptions,
    };

    return {
      userPrefValue: sl.getUserPreferenceData(state)[key],
      splitNotificationsEmails,
    };
  };

export type UserPrefConnectActionCreatorsKeys = 'userPreferenceDataChanged';
export type UserPrefConnectActionCreators = Pick<ActionCreators, UserPrefConnectActionCreatorsKeys>;

export const mapDispatchToPropsUserPref =
  <K extends keyof UserPreferencesData>(
    key: K,
  ): MapDispatchToPropsHOF<
    UserPrefConnectDispatchProps<K>,
    UserPrefConnectOwnProps,
    UserPrefConnectActionCreators
  > =>
  ac =>
  (dispatch, _ownProps) => ({
    onChangeUserPref(value) {
      dispatch(ac.userPreferenceDataChanged({ [key]: value as UserPreferencesData[K] }));
    },
    onChangeUserPrefWithSplit(
      value: readonly string[],
      typeofEmail: 'emailsCash' | 'emailsOrder' | 'emailsOptions',
    ) {
      switch (typeofEmail) {
        case 'emailsCash':
          dispatch(
            ac.userPreferenceDataChanged({
              splitNotificationsEmailsCash: value,
            }),
          );
          break;
        case 'emailsOrder':
          dispatch(
            ac.userPreferenceDataChanged({
              splitNotificationsEmailsOrders: value,
            }),
          );
          break;
        case 'emailsOptions':
          dispatch(
            ac.userPreferenceDataChanged({
              splitNotificationsEmailsOptions: value,
            }),
          );
          break;
      }
    },
  });
