// @ts-ignore
const devEnv: boolean = import.meta.env.VITE_FX_NODE_ENV === 'development';
// @ts-ignore
const devServerBackend = import.meta.env.VITE_DEVSERVER_BACKEND;

export function getEnvironment() {
  const environment =
    typeof window !== 'undefined' ? window.sgmeConfiguration.env : devServerBackend;
  if (environment !== 'PROD') {
    return `${environment}${
      typeof window !== 'undefined' && window.location.href.includes('local') ? ' local' : ''
    }`;
  }
}

export const isDev =
  devEnv ||
  (devServerBackend || '').toLowerCase().includes('dev') ||
  (typeof window !== 'undefined' && window.location.href.includes('dev'));

export const agGridLicenseKey =
  'CompanyName=SOCIETE GENERALE,LicensedGroup=SG Markets FX,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=2,AssetReference=AG-025632,ExpiryDate=2_April_2023_[v2]_MTY4MDM5MDAwMDAwMA==0ddb8fc70cf4cf2ae9d823aa6d8fad00';

