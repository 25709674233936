import type { Updater } from '../typings';
import type { SavedWorkspace as SavedWorkspace4 } from '../updater4/model';
import type { SavedWorkspace as SavedWorkspace5 } from './model';

const currentVersion = 5;

export const updater5: Updater<SavedWorkspace4, SavedWorkspace5> = {
  version: currentVersion,
  canUpdate(data: any) {
    return data.version === currentVersion - 1;
  },
  update: (previousWorkspace: SavedWorkspace4): SavedWorkspace5 => {
    const savedWorkspace: SavedWorkspace5 = {
      ...previousWorkspace,
      version: currentVersion,
    };

    return savedWorkspace;
  },
};
