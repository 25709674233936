import type { IStreamError } from '../../globalError/globalErrorModel';
import type { QuoteContributionType } from '../../share/productModel/litterals';
import type { IHedge, IQuote } from '../model/optionsStreams';

export interface OptionStreamRequested {
  type: 'OPTION_STREAM_REQUESTED';
  optionId: string;
  forceTradable: boolean;
  bypassCheck: boolean;
}

export function optionStreamRequested(
  optionId: string,
  forceTradable: boolean,
  bypassCheck: boolean,
): OptionStreamRequested {
  return {
    type: 'OPTION_STREAM_REQUESTED',
    optionId,
    forceTradable,
    bypassCheck,
  };
}

export interface OptionStreamStarted {
  type: 'OPTION_STREAM_STARTED';
  optionId: string;
  streamId: string;
  rfsStartedAt: Date;
  forceTradeable: boolean;
}

export function optionStreamStarted(
  optionId: string,
  streamId: string,
  rfsStartedAt: Date,
  forceTradeable: boolean,
): OptionStreamStarted {
  return {
    type: 'OPTION_STREAM_STARTED',
    optionId,
    streamId,
    rfsStartedAt,
    forceTradeable,
  };
}

export interface OptionStreamCanceled {
  type: 'OPTION_STREAM_CANCELED';
  optionId: string;
  streamId: string;
  shouldKeepAsExpired: boolean;
}

export function optionStreamCanceled(
  optionId: string,
  streamId: string,
  shouldKeepAsExpired: boolean,
): OptionStreamCanceled {
  return {
    type: 'OPTION_STREAM_CANCELED',
    optionId,
    streamId,
    shouldKeepAsExpired,
  };
}

export interface OptionStreamUpdated {
  type: 'OPTION_STREAM_UPDATED';
  optionId: string;
  streamId: string;
}

export function optionStreamUpdated(optionId: string, streamId: string): OptionStreamUpdated {
  return {
    type: 'OPTION_STREAM_UPDATED',
    optionId,
    streamId,
  };
}

export interface OptionStreamInvalidated {
  type: 'OPTION_STREAM_INVALIDATED';
  optionId: string;
  streamId: string;
}

export function optionStreamInvalidated(
  optionId: string,
  streamId: string,
): OptionStreamInvalidated {
  return {
    type: 'OPTION_STREAM_INVALIDATED',
    optionId,
    streamId,
  };
}

export interface OptionStreamLastRemoved {
  type: 'OPTION_STREAM_LAST_REMOVED';
  optionId: string;
  streamId: string;
}

export function optionStreamLastRemoved(
  optionId: string,
  streamId: string,
): OptionStreamLastRemoved {
  return {
    type: 'OPTION_STREAM_LAST_REMOVED',
    optionId,
    streamId,
  };
}

export interface OptionStreamCanceledSent {
  type: 'OPTION_STREAM_CANCELED_SENT';
  quoteId: string;
  streamId: string;
}

export function optionStreamCanceledSent(
  quoteId: string,
  streamId: string,
): OptionStreamCanceledSent {
  return {
    type: 'OPTION_STREAM_CANCELED_SENT',
    quoteId,
    streamId,
  };
}

export interface OptionStreamCancelFailed {
  type: 'OPTION_STREAM_CANCEL_FAILED';
  optionId: string;
  streamId: string;
}

export function optionStreamCancelFailed(
  optionId: string,
  streamId: string,
): OptionStreamCancelFailed {
  return {
    type: 'OPTION_STREAM_CANCEL_FAILED',
    optionId,
    streamId,
  };
}

export interface OptionStreamUpdateFailed {
  type: 'OPTION_STREAM_UPDATE_FAILED';
  optionId: string;
  streamId: string;
}

export function optionStreamUpdateFailed(
  optionId: string,
  streamId: string,
): OptionStreamUpdateFailed {
  return {
    type: 'OPTION_STREAM_UPDATE_FAILED',
    optionId,
    streamId,
  };
}

export interface OptionStreamFailed {
  type: 'OPTION_STREAM_FAILED';
  optionId: string;
  streamId: string;
  error: IStreamError;
}

export function optionStreamFailed(
  optionId: string,
  streamId: string,
  error: IStreamError,
): OptionStreamFailed {
  return {
    type: 'OPTION_STREAM_FAILED',
    optionId,
    streamId,
    error,
  };
}

export interface OptionStreamTerminated {
  type: 'OPTION_STREAM_TERMINATED';
  optionId: string;
  streamId: string;
  shouldKeepAsExpired: boolean;
}

export function optionStreamTerminated(
  optionId: string,
  streamId: string,
  shouldKeepAsExpired: boolean,
): OptionStreamTerminated {
  return {
    type: 'OPTION_STREAM_TERMINATED',
    optionId,
    streamId,
    shouldKeepAsExpired,
  };
}

export interface OptionRfsClearError {
  type: 'OPTION_RFS_CLEAR_ERROR';
  quoteId: string;
}

export function optionRfsClearError(quoteId: string): OptionRfsClearError {
  return { type: 'OPTION_RFS_CLEAR_ERROR', quoteId };
}

export interface OptionTraderNotificationReceived {
  type: 'OPTION_TRADER_NOTIFICATION_RECEIVED';
  optionId: string;
  streamId: string;
  traderId: string | null;
  quoteContribution: QuoteContributionType | null;
}

export function optionTraderNotificationReceived(
  optionId: string,
  streamId: string,
  traderId: string | null,
  quoteContribution: QuoteContributionType | null,
): OptionTraderNotificationReceived {
  return {
    type: 'OPTION_TRADER_NOTIFICATION_RECEIVED',
    optionId,
    streamId,
    traderId,
    quoteContribution,
  };
}

export interface OptionQuoteReceived {
  type: 'OPTION_QUOTE_RECEIVED';
  optionId: string;
  streamId: string;
  quote: IQuote<readonly IHedge[]>;
  tiering: string | null;
  skippedLimitCheck: boolean;
  quoteContribution: QuoteContributionType | null;
}

export function optionQuoteReceived(
  optionId: string,
  streamId: string,
  quote: IQuote<readonly IHedge[]>,
  tiering: string | null,
  skippedLimitCheck: boolean,
  quoteContribution: QuoteContributionType | null,
): OptionQuoteReceived {
  return {
    type: 'OPTION_QUOTE_RECEIVED',
    optionId,
    streamId,
    quote,
    tiering,
    skippedLimitCheck,
    quoteContribution,
  };
}
