import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { fieldData } from 'utils/fieldSelectors';
import type { CashTileStatus } from '../fxCashsModel';
import { isAmountInsideValidLadders } from 'state/esp/utils';

export const getStreamingStatus = (
  state: AppState,
  cashId: string,
  sl: Selectors,
): CashTileStatus => {
  const currentEspStreamId = sl.getTileCurrentEspStreamId(state, cashId);
  if (currentEspStreamId !== null) {
    const espStream = sl.getEspStreamState(state, currentEspStreamId);

    const amount = sl.getCashAmount(state, cashId).value;
    const { data: amountCurrency } = fieldData(sl.getCashAmountCurrency(state, cashId));
    if (
      espStream !== undefined &&
      espStream.status === 'PRICING' &&
      isAmountInsideValidLadders(amount, amountCurrency, espStream)
    ) {
      return 'ESP_PRICING';
    }
  }

  const currentStreamId = sl.getTileCurrentStreamId(state, cashId);
  if (currentStreamId !== null) {
    const rfsState = sl.getCashRfsStreamState(state, cashId);
    if (rfsState.status === 'PRICING') {
      return 'RFS_PRICING';
    }
  }

  return 'NO_PRICE';
};
