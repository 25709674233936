import { type ReactNode, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import { button } from './e2e';

interface DisableableButtonWithTooltipProps {
  className: string;
  tooltipContents?: React.ReactNode;
  disabled: boolean;
  e2eHandle: string;
  children: ReactNode;
  onClick(): void;
}

const DisabledButtonLike = styled.div`
  cursor: not-allowed;
`;

export function DisableableButtonWithTooltip({
  className,
  tooltipContents,
  disabled,
  e2eHandle,
  children,
  onClick,
}: DisableableButtonWithTooltipProps): JSX.Element {
  const [btnLikeRef, setBtnLikeRef] = useState<HTMLDivElement | null>(null);
  const [btnRef, setBtnRef] = useState<HTMLButtonElement | null>(null);
  const tooltipTarget = disabled ? btnLikeRef : btnRef;
  return (
    <>
      {
        disabled
          ? (
            <DisabledButtonLike
              data-e2e={button(e2eHandle)}
              className={`${className} disabled`}
              ref={setBtnLikeRef}
            >
              {children}
            </DisabledButtonLike>
          )
          : (
            <button
              type="button"
              className={className}
              ref={setBtnRef}
              onClick={onClick}
              data-e2e={button(e2eHandle)}
            >
              {children}
            </button>
          )
      }

      {tooltipTarget !== null && tooltipContents !== undefined && (
        <UncontrolledTooltip
          placement="bottom"
          delay={0}
          innerClassName="mw-100"
          fade={false}
          target={tooltipTarget}
        >
          {tooltipContents}
        </UncontrolledTooltip>
      )}
    </>
  );
}
