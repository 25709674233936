import type { Observable } from 'rxjs';
import type { HttpCall, ISgmeHttp } from 'utils/sgmeHttp';
import type { Side } from 'state/share/productModel/litterals';
import { transformEmailsToBase64 } from 'utils/base64';

export interface IBulkQuoteExecutionPayload {
  rfsId: string;
  quoteId: string;
  replyToStream: string;
  emailNotificationList: string;
  userLocalDateTime: string;
  lastQuoteFromBackTimestamp: number;
  lastQuoteReceivedByFrontTimestamp: number;
  executionClickByFrontTimestamp: number;
  legs: readonly IBulkLegQuoteExecutionPayload[];
}

export interface IBulkLegQuoteExecutionPayload {
  amount: number;
  customerWay: Side;
  valueDate: string;
  valueDateTenor: string;

  spotTrader?: number;
  spotClient: number;
  forwardPoints: number;
}

export interface IBulkQuoteExecuteRequestApi {
  msApiTimeout: number;
  executeQuoteRequest(payload: IBulkQuoteExecutionPayload): Observable<HttpCall<void>>;
}

export function bulkQuoteExecuteApi(http: ISgmeHttp): IBulkQuoteExecuteRequestApi {
  return {
    executeQuoteRequest(payload) {
      return http.post<void>(
        'api/fxBulk/rfs/execute',
        transformEmailsToBase64(payload, 'emailNotificationList', 'email64NotificationList'),
        { 'X-Correlation-ID': payload.rfsId },
      );
    },
    msApiTimeout: 60_000,
  };
}
