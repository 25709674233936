import { Suspense } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { lazyWithRetry } from 'components/share/lazy';
import { GridContainer } from './GridContainer';
import { getClientWorkspaceActiveTab } from 'state/clientWorkspace/selectors';
import type { AppState } from 'state/model';
import type { TabType } from 'state/clientWorkspace';
import { assertUnreachable } from '@sgme/fp';
import { FreeLayoutContainer } from './FreeLayoutContainer';
import { connectTabContents } from './connect';
import { ZoomedTileInstrument } from 'components/GenericTile/TileInstrument';
import { QuoteIdContext, WorkspaceIdContext } from 'components/contexts';
import { AutoInstrumentProvider } from 'components/contexts/AutoInstrumentProvider';

const Growls = lazyWithRetry(() => import('components/Growl/Growls'));
const BulkPricer = lazyWithRetry(() => import('components/BulkTrade'));

interface IClientWorkspaceStateProps {
  activeTabId: string | null;
}

// delegates scroll to GPU, avoids stutters during scrolls
const Workspace = styled.div`
  transform: translateZ(0);
  z-index: 0;
`;

function ClientWorkspaceRaw({ activeTabId }: IClientWorkspaceStateProps) {
  return (
    <Suspense
      fallback={
        <div className="h-100 w-100 d-flex justify-content-around align-items-center">
          <div className="spinner spinner-xl"></div>
        </div>
      }
    >
      <Growls />
      {activeTabId && <TabContents tabId={activeTabId} />}
    </Suspense>
  );
}

interface TabContentsProps {
  tabId: string | null;
  tabType: TabType;
  zoomedTile: string | null;
}

const TabContentsRaw: React.FunctionComponent<TabContentsProps> = ({
  tabId,
  tabType,
  zoomedTile,
}) => {
  if (tabId === null || tabType === null) {
    return null;
  }
  switch (tabType) {
    case 'tiles':
      if (zoomedTile !== null) {
        return (
          <WorkspaceIdContext.Provider value={tabId}>
            <QuoteIdContext.Provider value={zoomedTile}>
              <AutoInstrumentProvider>
                <ZoomedTileInstrument />
              </AutoInstrumentProvider>
            </QuoteIdContext.Provider>
          </WorkspaceIdContext.Provider>
        );
      }
      return (
        <Workspace className="overflow-auto bg-lvl4 w-100 p-1">
          <GridContainer tabId={tabId} />
          <FreeLayoutContainer tabId={tabId} />
        </Workspace>
      );
    case 'bulkTrade':
      return <BulkPricer quoteId={tabId} />;
    default:
      assertUnreachable(tabType, 'Tab type not handled');
  }
};

const TabContents = connectTabContents(TabContentsRaw);

const mapStateToProps = (state: AppState): IClientWorkspaceStateProps => ({
  activeTabId: getClientWorkspaceActiveTab(state),
});

export const ClientWorkspace = connect(mapStateToProps)(ClientWorkspaceRaw);
