import type { AppState } from 'state/model';
import { isDefined } from '@sgme/fp';
import type { BlotterEntry, BlotterInstrument, OrderBlotterEntry, TradeBlotterEntry } from '../blotterEntryModel';
import { createSelector } from 'reselect';
import { throwIfUndefined } from 'utils/maps';
import type { BlotterHistoricalError, BlotterMode, DateRange } from '../blotterModel';
import type { ImmutableCollection } from 'typings/utils';
import { logger } from 'logging/logger';

export const getBlotter = (state: AppState) => state.blotter;

export const getBlotterIsOpen = (state: AppState) => state.blotter.metadata.isOpen;

export const getBlotterMode = (state: AppState): BlotterMode => state.blotter.metadata.mode;

export const getBlotterFetching = (state: AppState) => state.blotter.metadata.fetching;

export const getBlotterCanRefetch = (state: AppState) => state.blotter.metadata.canRefetch;

export const getBlotterHistoricalError = (state: AppState): BlotterHistoricalError =>
  state.blotter.metadata.historical ? state.blotter.metadata.historical.error : null;

export const getBlotterHistoricalDateRange = (state: AppState): DateRange =>
  state.blotter.metadata.historical ? state.blotter.metadata.historical.dateRange : 'yesterday';

export const getBlotterHistoricalStartDate = (state: AppState): Date | null =>
  state.blotter.metadata.historical ? state.blotter.metadata.historical.startDate : null;

export const getBlotterHistoricalEndDate = (state: AppState): Date | null =>
  state.blotter.metadata.historical ? state.blotter.metadata.historical.endDate : null;

export const getBlotterAllIntraday = (state: AppState) => getBlotter(state).intraday;
export const getBlotterAllHistorical = (state: AppState) => getBlotter(state).historical;
export const getBlotterAllEditedOrders = (state: AppState) => getBlotter(state).editedOrders;

export const isInBlotterIntraday = (state: AppState, id: string): boolean =>
  getBlotterAllIntraday(state)[id] !== undefined;

export const getBlotterDataById = (state: AppState, id: string): BlotterEntry =>
  throwIfUndefined(
    getBlotterAllIntraday(state)[id] ?? getBlotterAllHistorical(state)[id],
    `Blotter entry not found for id ${id}`,
  );

export const getBlotterEditedDataById = (state: AppState, id: string): BlotterEntry =>
  throwIfUndefined(getBlotterAllEditedOrders(state)[id], `Blotter entry not found for id ${id}`);

const makeGetBlotterDataByInstrument = (
  getBlotterData: (state: AppState) => ImmutableCollection<BlotterEntry>,
  instrument: BlotterInstrument,
) => {
  const filterProduct = (data: BlotterEntry) => data.instrument === instrument;
  return createSelector(getBlotterData, data => Object.values(data).filter(isDefined).filter(filterProduct));
};

export const getBlotterIntradayCashTrades = makeGetBlotterDataByInstrument(getBlotterAllIntraday, 'Cash');

export const getBlotterIntradayOptionTrades = makeGetBlotterDataByInstrument(getBlotterAllIntraday, 'Option');
export const getBlotterOrders = makeGetBlotterDataByInstrument(getBlotterAllIntraday, 'Order');
export const getBlotterIntradayAccumulatorTrades = makeGetBlotterDataByInstrument(getBlotterAllIntraday, 'Accumulator');
export const getBlotterHistoricalCashTrades = makeGetBlotterDataByInstrument(getBlotterAllHistorical, 'Cash');
export const getBlotterHistoricalOptionTrades = makeGetBlotterDataByInstrument(getBlotterAllHistorical, 'Option');
export const getBlotterHistoricalAccumulatorTrades = makeGetBlotterDataByInstrument(
  getBlotterAllHistorical,
  'Accumulator',
);

function assertOrder(blotterEntry: BlotterEntry): asserts blotterEntry is OrderBlotterEntry {
  if (blotterEntry.instrument !== 'Order') {
    const err = new Error('This should have been an order');
    logger.logError(
      'This should have been an order : {blotterEntry} from : {stack}, source: state',
      JSON.stringify(blotterEntry),
      err.stack,
    );
    throw err;
  }
}

function assertTrade(blotterEntry: BlotterEntry): asserts blotterEntry is TradeBlotterEntry {
  if (blotterEntry.instrument === 'Order') {
    const err = new Error('This should have been a trade');
    logger.logError(
      'This should have been a trade : {blotterEntry} from : {stack}, source: state',
      JSON.stringify(blotterEntry),
      err.stack,
    );
    throw err;
  }
}

export const getBlotterOrderById = (state: AppState, id: string): OrderBlotterEntry => {
  const foundEntry = getBlotterDataById(state, id);
  assertOrder(foundEntry);
  return foundEntry;
};

export const getBlotterEditedOrderById = (state: AppState, id: string): OrderBlotterEntry => {
  const foundEntry = getBlotterEditedDataById(state, id);
  assertOrder(foundEntry);
  return foundEntry;
};

export const getBlotterTradeById = (state: AppState, id: string): TradeBlotterEntry => {
  const foundEntry = getBlotterDataById(state, id);
  assertTrade(foundEntry);
  return foundEntry;
};

export const getBlotterChildrenTrades = (state: AppState, childrenIds: readonly string[]) =>
  childrenIds.map(id => getBlotterDataById(state, id));
