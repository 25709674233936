import { isDefined } from '@sgme/fp';
import type { LocusCoordinates, Locus, GridLayout } from '../../gridLayoutModels';
import { getOccupiedColumnsById } from './gridLayoutColumns';
import { getNextLocusInColumn, getPreviousLocusInColumn } from './gridLayoutLocus';

interface IRightNeighbourCoordinates {
  colIndex: number;
  neighbours: readonly LocusCoordinates[] | undefined;
}

type RightNeighbourCoordinates = Readonly<IRightNeighbourCoordinates>;

export function getDownwardNeighbourGridItem(
  gridLayout: GridLayout,
  gridItemId: string,
): readonly Locus[] {
  return getOccupiedColumnsById(gridLayout, gridItemId)
    .map(colIndex => getNextLocusInColumn(gridLayout.columns[colIndex], gridItemId))
    .filter(isDefined);
}

export function getUpwardNeighbourGridItem(
  gridLayout: GridLayout,
  gridItemId: string,
): ReadonlyArray<Pick<Locus, 'bottom'>> {
  return getOccupiedColumnsById(gridLayout, gridItemId).map(
    colIndex =>
      getPreviousLocusInColumn(gridLayout.columns[colIndex], gridItemId) || {
        bottom: 0,
      },
  );
}

export function getRightNeighbourGridItemCoordinates(
  gridLayout: GridLayout,
  gridItemId: string,
): RightNeighbourCoordinates {
  // get top and bottom boundaries of the current item
  const occupiedColumns = getOccupiedColumnsById(gridLayout, gridItemId);
  const rightHandSizeColumn = occupiedColumns[occupiedColumns.length - 1];
  const currentLocus = gridLayout.columns[occupiedColumns[0]].find(
    locus => locus.gridItemId === gridItemId,
  );

  const colIndex = rightHandSizeColumn + 1;
  const nextColumn = gridLayout.columns[colIndex];
  if (nextColumn === undefined || currentLocus === undefined) {
    return {
      colIndex,
      neighbours: undefined,
    };
  }

  const neighbours = nextColumn
    .map((locus, locusIndex) => ({ locus, locusIndex, colIndex }))
    .filter(({ locus: { top, bottom } }) => bottom > currentLocus.top && top < currentLocus.bottom);

  return {
    colIndex,
    neighbours,
  };
}
