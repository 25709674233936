/**
 * @private
 * these exports are shared helper for exported selectors
 *
 * don't export this file in barrel ('./index.ts')
 */

import type { AppState } from 'state/model';
import type { VanillaLegState } from '../model/optionsLegs';
import { isVanillaLeg } from '../utilities';
import { getOptionLeg } from './fxOptionsLegsSelectors';

export function getOptionLeafLegState(state: AppState, legId: string): VanillaLegState {
  const leg = getOptionLeg(state, legId);

  if (isVanillaLeg(leg)) {
    return leg;
  }

  return getOptionLeafLegState(state, leg.legIds[0]);
}
