import { convertToPointsWithPrecision } from 'utils/margin';
import { throwIfUndefined } from 'utils/maps';
import { precisionAdderWithPrecision } from 'utils/number';
import type { AppState } from 'state/model';
import type { CurrencyChoice, BidAskPair } from 'state/share/productModel/litterals';
import { getCurrencyPrecision, getTileMaxDate } from 'state/referenceData/referenceDataSelectors';
import { getSwapRfsStream } from './fxSwapsStreamsSelectors';
import { getSwapCurrencyPair } from './swapInput';
import type { FxSwapState } from 'state/fxSwaps/fxSwapsModel';

export function getSwapState(state: AppState, tileId: string): FxSwapState {
  return throwIfUndefined(state.fxSwaps.swaps[tileId], `Swap state for ${tileId} does not exist`);
}

export function getSwapTradeCaptureCurrentSessionId(
  currentState: AppState,
  titleId: string,
): string | null {
  const { currentSessionId } = getSwapState(currentState, titleId);
  return currentSessionId;
}

export function getSwapTradeCaptureIdVersion(
  currentState: AppState,
  titleId: string,
): number | null {
  const { tradeCaptureIdVersion } = getSwapState(currentState, titleId);
  return tradeCaptureIdVersion;
}

export function getCurrentSwapCurrencyPair(state: AppState, swapId: string) {
  const currencyPair = getSwapCurrencyPair(state, swapId);
  return currencyPair.value;
}

export function getSwapMargin(state: AppState, tileId: string) {
  const currencyPair = getSwapCurrencyPair(state, tileId).value;
  if (currencyPair !== null) {
    const { bidMargin, askMargin } = getSwapState(state, tileId);
    const precision = getCurrencyPrecision(state, currencyPair);
    const toPoints = convertToPointsWithPrecision(precision);

    return {
      bidMargin: +toPoints(bidMargin ?? 0).toFixed(2),
      askMargin: +toPoints(askMargin ?? 0).toFixed(2),
    };
  }
  return {
    bidMargin: 0,
    askMargin: 0,
  };
}

export function getSwapMarkup(state: AppState, swapId: string) {
  const defaultMarkup = { bidMarkup: 0, askMarkup: 0 };
  const {
    currentStreamId,
    markupCurrency,
    bidMargin: bidSwapMargin,
    askMargin: askSwapMargin,
    bidSpotMargin,
    askSpotMargin,
    values: { amountCurrency, nearAmount, farAmount, currencyPair, nearPriceReference },
  } = getSwapState(state, swapId);

  // data common to all string types
  if (farAmount === null || nearAmount === null || currencyPair === null) {
    return defaultMarkup;
  }

  const precision = getCurrencyPrecision(state, currencyPair);

  if (currentStreamId !== null) {
    const swapStreamState = getSwapRfsStream(state, currentStreamId);
    if (swapStreamState.status !== 'PRICING') {
      return defaultMarkup;
    }

    const {
      spot: salesSpot,
      nearPoints: nearClientPoints,
      nearSalesPoints,
      farPoints: farSalesPoints,
      nearDf2,
      farDf2,
    } = swapStreamState.quote;

    const { bid: bidMarkup, ask: askMarkup } = computeMarkup(
      nearAmount,
      farAmount,
      salesSpot,
      nearPriceReference,
      nearSalesPoints,
      nearClientPoints,
      farSalesPoints,
      { bid: bidSpotMargin || 0, ask: askSpotMargin || 0 },
      { bid: bidSwapMargin || 0, ask: askSwapMargin || 0 },
      nearDf2,
      farDf2,
      precision,
      amountCurrency,
      markupCurrency,
    );
    return { bidMarkup, askMarkup };
  }

  return defaultMarkup;
}

export function computeMarkup(
  nearNotional: number,
  farNotional: number,
  salesSpot: BidAskPair,
  clientNearPrice: BidAskPair | null,
  nearSalesPoints: BidAskPair,
  nearClientPoints: BidAskPair,
  farSalesPoints: BidAskPair,
  spotMargin: BidAskPair,
  swapMargin: BidAskPair,
  nearDf2: number,
  farDf2: number,
  precision: number,
  amountCurrency: CurrencyChoice,
  markupCurrency: CurrencyChoice,
): BidAskPair {
  const precisionAdder = precisionAdderWithPrecision(precision + 1);
  const sumPairs = sumPairsWithPrecision(precision + 1);
  const unevenFactor = Math.sign(farNotional - nearNotional);
  const clientSpotBid =
    clientNearPrice !== null
      ? clientNearPrice.bid - nearClientPoints.bid
      : precisionAdder(salesSpot.bid, -unevenFactor * spotMargin.bid);
  const clientSpotAsk =
    clientNearPrice !== null
      ? clientNearPrice.ask - nearClientPoints.ask
      : precisionAdder(salesSpot.ask, unevenFactor * spotMargin.ask);
  const nearEvenValues = computeNearEvenMarkups(
    nearNotional,
    salesSpot,
    { bid: clientSpotBid, ask: clientSpotAsk },
    nearSalesPoints,
    nearClientPoints,
    precision,
    nearDf2,
    amountCurrency,
  );

  const farEvenValues = computeFarEvenMarkups(
    farNotional,
    salesSpot,
    { bid: clientSpotBid, ask: clientSpotAsk },
    farSalesPoints,
    swapMargin,
    precision,
    farDf2,
    amountCurrency,
  );

  const swapMarkup = sumPairs(nearEvenValues.markup, farEvenValues.markup);

  const spotMarkup = {
    bid:
      (clientNearPrice === null ? spotMargin.bid : unevenFactor * (salesSpot.bid - clientSpotBid)) *
      Math.abs(
        amountCurrency === 1
          ? farNotional - nearNotional
          : farNotional / farEvenValues.clientPrice.bid -
              nearNotional / nearEvenValues.clientPrice.bid,
      ),
    ask:
      (clientNearPrice === null ? spotMargin.ask : unevenFactor * (clientSpotAsk - salesSpot.ask)) *
      Math.abs(
        amountCurrency === 1
          ? farNotional - nearNotional
          : farNotional / farEvenValues.clientPrice.ask -
              nearNotional / nearEvenValues.clientPrice.ask,
      ),
  };

  const totalMarkup = sumPairs(swapMarkup, spotMarkup);

  return markupCurrency === 2
    ? totalMarkup
    : {
        bid: totalMarkup.bid / salesSpot.ask,
        ask: totalMarkup.ask / salesSpot.bid,
      };
}

export function computeNearEvenMarkups(
  nearNotional: number,
  salesSpot: BidAskPair,
  clientSpot: BidAskPair,
  nearSalesPoints: BidAskPair,
  nearClientPoints: BidAskPair,
  precision: number,
  nearDf2: number,
  amountCurrency: CurrencyChoice,
) {
  const sumPairs = sumPairsWithPrecision(precision + 1);

  const nearClientPrice = sumPairs(clientSpot, nearClientPoints);
  const nearSalesPrice = sumPairs(salesSpot, nearSalesPoints);

  const nearEvenMarkupBid =
    (((nearClientPrice.bid - nearSalesPrice.bid) * nearDf2 - (clientSpot.bid - salesSpot.bid)) *
      nearNotional) /
    (amountCurrency === 1 ? 1 : nearClientPrice.bid);

  const nearEvenMarkupAsk =
    ((clientSpot.ask - salesSpot.ask - (nearClientPrice.ask - nearSalesPrice.ask) * nearDf2) *
      nearNotional) /
    (amountCurrency === 1 ? 1 : nearClientPrice.ask);

  return {
    markup: { bid: nearEvenMarkupBid, ask: nearEvenMarkupAsk },
    clientPrice: nearClientPrice,
  };
}

export function computeFarEvenMarkups(
  farNotional: number,
  salesSpot: BidAskPair,
  clientSpot: BidAskPair,
  farSalesPoints: BidAskPair,
  swapMargin: BidAskPair,
  precision: number,
  farDf2: number,
  amountCurrency: CurrencyChoice,
) {
  const sumPairs = sumPairsWithPrecision(precision + 1);

  const farClientPoints = {
    bid: farSalesPoints.bid - swapMargin.bid,
    ask: farSalesPoints.ask + swapMargin.ask,
  };

  const farClientPrice = sumPairs(clientSpot, farClientPoints);

  const farSalesPrice = sumPairs(salesSpot, farSalesPoints);

  const farEvenMarkupBid =
    ((clientSpot.bid - salesSpot.bid - (farClientPrice.bid - farSalesPrice.bid) * farDf2) *
      farNotional) /
    (amountCurrency === 1 ? 1 : farClientPrice.bid);
  const farEvenMarkupAsk =
    (((farClientPrice.ask - farSalesPrice.ask) * farDf2 - (clientSpot.ask - salesSpot.ask)) *
      farNotional) /
    (amountCurrency === 1 ? 1 : farClientPrice.ask);

  return {
    markup: { bid: farEvenMarkupBid, ask: farEvenMarkupAsk },
    clientPrice: farClientPrice,
  };
}

const sumPairsWithPrecision = (precision: number) => {
  const adder = precisionAdderWithPrecision(precision);
  return (pair1: BidAskPair, pair2: BidAskPair): BidAskPair => ({
    bid: adder(pair1.bid, pair2.bid),
    ask: adder(pair1.ask, pair2.ask),
  });
};

export const getNearAmount = ({ values: { nearAmount } }: Readonly<FxSwapState>) => nearAmount;

export const getFarAmount = ({ values: { farAmount } }: Readonly<FxSwapState>) => farAmount;

export function isValidDateSwap(currentState: AppState, quoteId: string) {
  const state = getSwapState(currentState, quoteId);
  const maxDate = getTileMaxDate(currentState, quoteId);
  return (
    !state.values.farFixingDate ||
    maxDate === undefined ||
    new Date(state.values.farFixingDate) <= maxDate
  );
}
