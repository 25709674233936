import type { Updater } from '../typings';
import type { SavedWorkspace as SavedWorkspace3 } from '../updater3/model';
import type { SavedWorkspace as SavedWorkspace4 } from './model';
import { composeLeftFunction } from 'utils/composer';

const currentVersion = 4;

export const updater4: Updater<SavedWorkspace3, SavedWorkspace4> = {
  version: currentVersion,
  canUpdate(data: any) {
    return data.version === currentVersion - 1;
  },
  update: (previousWorkspace: SavedWorkspace3): SavedWorkspace4 => {
    const operations = composeLeftFunction(
      renameBlotterTabColumn('orderTab', { action: 'orderAction' }),
      renameBlotterTabColumn('cashTab', { xone: 'defaultAction' }),
      renameBlotterTabColumn('optionTab', { xone: 'defaultAction' }),
      renameBlotterTabColumn('accumulatorTab', { xone: 'defaultAction' }),
    );

    const newWorkspace: SavedWorkspace4 = {
      ...operations(previousWorkspace),
      version: currentVersion,
    };
    return newWorkspace;
  },
};

type BlotterTab = 'cashTab' | 'optionTab' | 'orderTab' | 'accumulatorTab';

const renameBlotterTabColumn =
  (blotterTab: BlotterTab, columnIds: Record<string, string>): ((previousWorkspace: any) => any) =>
  previousWorkspace => {
    const previousblotter = previousWorkspace.blotter[blotterTab];
    const newWorkspace = {
      ...previousWorkspace,
      blotter: {
        ...previousWorkspace.blotter,
        [blotterTab]: {
          ...previousblotter,
          columnsState: previousblotter.columnsState.map((columnState: any) => {
            const colId = columnIds[columnState.colId] ?? columnState.colId;
            return { ...columnState, colId };
          }),
        },
      },
    };
    return newWorkspace;
  };
