import { connect } from 'react-redux';
import { mapDispatchToPropsCollapsable, mapStateToPropsCollapsable } from './connectCollapsable';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import { withQuoteId } from 'components/contexts';
import { renderPropsConnect } from 'components/share/renderPropsConnect';
import { CollapsableComponent } from './Collapsables';
import { CollapsableBlockComponent } from './CollapsableBlock';

export const connectCollapsable = connect(
  mapStateToPropsCollapsable(selectors),
  mapDispatchToPropsCollapsable(actionCreators),
);

export const Collapsable = withQuoteId(connectCollapsable(CollapsableComponent));

export { UncontrolledCollapsable } from './Collapsables';

export const CollapsedRenderProps = withQuoteId(
  renderPropsConnect(
    mapStateToPropsCollapsable(selectors),
    mapDispatchToPropsCollapsable(actionCreators),
  ),
);

export const CollapsableBlock = CollapsableBlockComponent;
