import type { AlertLevel, VisualEmphasis } from './typings';

/**
 * @todo
 * expose this file at the root of the repo
 * for shared access to a single source of truth
 * from e2e and application
 */
function stringOrUndefined(
  fn: (a: string) => string,
): (a: string | undefined) => string | undefined {
  return a => (a === undefined ? undefined : fn(a));
}

export const tile = stringOrUndefined((e2e: string) => `${e2e}-tile`);
export const growl = stringOrUndefined((e2e: string) => `${e2e}-growl`);
export const tileWrapper = () => `tile`;
export const overlay = stringOrUndefined((e2e: string) => `${e2e}-overlay`);
export const errorPanel = stringOrUndefined((e2e: string) => `${e2e}-errorPanel`);
export const collapsable = stringOrUndefined((e2e: string) => `${e2e}-collapsable`);
export const rate = stringOrUndefined((e2e: string) => `${e2e.toLowerCase()}-rate`);
export const input = stringOrUndefined((e2e: string) => `${e2e}Input`);
export const checkBox = stringOrUndefined((e2e: string) => `${e2e}-checkbox`);
export const button = stringOrUndefined((e2e: string) => `${e2e}-btn`);
export const tenor = stringOrUndefined((e2e: string) => `${e2e}-tenor`);
export const selector = stringOrUndefined((e2e: string) => `${e2e}-selector`);
export const itemSelector = stringOrUndefined((e2e: string) => `${e2e}-item-selector`);
export const label = stringOrUndefined((e2e: string) => `${e2e}-label`);
export const price = stringOrUndefined((e2e: string) => `${e2e}-price`);
export const field = stringOrUndefined((e2e: string) => `${e2e}-field`);
export const message = stringOrUndefined((e2e: string) => `${e2e}-message`);
export const text = stringOrUndefined((e2e: string) => `${e2e}-text`);
export const item = stringOrUndefined((e2e: string) => `${e2e}-item`);
export const chip = stringOrUndefined((e2e: string) => `${e2e}-chip`);
export const container = stringOrUndefined((e2e: string) => `${e2e}-container`);
export const margin = stringOrUndefined((e2e: string) => `${e2e}-margin`);
export const tab = stringOrUndefined((e2e: string) => `${e2e}-tab`);
export const menu = stringOrUndefined((e2e: string) => `${e2e}-menu`);
export const menuItem = stringOrUndefined((e2e: string) => `${e2e}-menu-item`);

const inputLevelE2eHandle: Record<AlertLevel, string> = {
  warning: 'warning',
  invalid: 'error',
  valid: 'valid',
};
export const inputErrorLevel = (alertLevel?: AlertLevel) =>
  alertLevel === undefined ? '' : inputLevelE2eHandle[alertLevel];
const buttonLevelE2eHandle: Record<VisualEmphasis, string | undefined> = {
  none: undefined,
  table: undefined,
  default: undefined,
  readonly: undefined,
  warning: 'warning',
  error: 'error',
  info: 'info',
  success: 'success',
};
export const buttonErrorLevel = (emphasis: VisualEmphasis = 'none') =>
  buttonLevelE2eHandle[emphasis];

export const cancelPricing = button('cancel-pricing');
export const spotMid = text('spot-mid');

export const currenciesErrorLevel = (hasError: boolean, hasWarning: boolean) => {
  if (hasError === true) {
    return inputErrorLevel('invalid');
  }
  if (hasWarning === true) {
    return inputErrorLevel('warning');
  }
  return '';
};

export const isModifiedHandleValue = (isModified?: boolean) => isModified ?? false;
