import type { Observable } from 'rxjs';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import { map } from 'rxjs/operators';

export interface IOrderEmailsApi {
  timeout: number;
  getEmails(bdrId: string): Observable<readonly string[]>;
}

export function orderEmailsApi(http: ISgmeHttp): IOrderEmailsApi {
  return {
    timeout: 2000,
    getEmails: bdrId => {
      const url = `api/oms/emails/${bdrId}`;
      return http.getJSON<readonly string[]>(url).pipe(map(httpCall => httpCall.payload));
    },
  };
}
