import { isEmpty } from '@sgme/fp';
import { cleanDiacritics, trimAll } from './string';

const toLowerWithoutAccents = (value: string) => cleanDiacritics(value.toLowerCase());

interface TextPart {
  highlight: boolean;
  text: string;
}

export const textPart = (text: string, highlight: boolean = false) => ({
  highlight,
  text,
});

export const emphasizeWithAccentsIgnored = (
  text: string,
  searchString: string,
): readonly TextPart[] => {
  const trimmedSearchString = trimAll(searchString);
  // /!\ if this test is removed, the while loop will be infinite
  if (isEmpty(trimmedSearchString)) {
    return [textPart(text)];
  }

  const trimmedText = trimAll(text);

  const normalizedText = toLowerWithoutAccents(trimmedText);
  const normalizedSearchString = toLowerWithoutAccents(trimmedSearchString);

  const ret: TextPart[] = [];

  let lastIndex = 0;

  while (true) {
    const currIndex = normalizedText.indexOf(normalizedSearchString, lastIndex);
    if (currIndex === -1) {
      break;
    }
    ret.push(textPart(trimmedText.substring(lastIndex, currIndex)));
    ret.push(textPart(trimmedText.substr(currIndex, trimmedSearchString.length), true));

    lastIndex = currIndex + trimmedSearchString.length;
  }

  if (lastIndex < trimmedText.length) {
    ret.push(textPart(trimmedText.substr(lastIndex)));
  }

  return ret;
};
