import type { IFormData } from './../../share/form/formModel';
import {
  type FxBulkLegStateMap,
  type FxBulkLegState,
  type FxBulkLegInputs,
  emptyFxBulkLegState,
  type FxBulkSplitLegStateMap,
  type IQuoteLeg,
  type ISimpleLeg,
  type FxBulkNettedLegStateMap,
  type IMargedLeg,
  type ILegMargin,
  type IFxBulkLegValues,
} from '../fxBulksModel';
import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { addKey, removeKey, removeKeys, updateKey } from 'utils/stateMap';
import { getLegKey, legsPatcher } from 'state/share/patchHelper';
import type { CashProductName } from 'state/fxCashs/fxCashsModel';
import type { Collection } from 'typings/utils';
import { getBidAskValueWith, makeBulkLegId } from '../selectors';
import type { BulkMarginAction } from '../fxBulksActions';
import { precisionAdderWithPrecision } from 'utils/number';

const patchBulkLegs = legsPatcher<
  IFxBulkLegValues,
  FxBulkLegInputs,
  IFormData<IFxBulkLegValues, FxBulkLegInputs>
>(emptyFxBulkLegState);

const resetLeg = (state: FxBulkLegStateMap, quoteId: string, legId: string) => {
  const legKey = getLegKey(quoteId, legId);
  return updateKey<FxBulkLegState>(state, legKey, leg => {
    const legValues = leg.values;
    const values = {
      ...emptyFxBulkLegState.values,
      product: legValues.product,
      currency1: legValues.currency1,
      currency2: legValues.currency2,
      amount: legValues.amount,
    };
    return {
      inputs: {},
      values,
      errors: {},
      warnings: {},
    };
  });
};

export const legsReducer: Reducer<FxBulkLegStateMap> = (
  state: FxBulkLegStateMap = {},
  action: Action,
): FxBulkLegStateMap => {
  switch (action.type) {
    case 'BULK_CREATED':
      // return addKey(state, getLegKey(action.bulkId), emptyFxBulkLegState);
      return state;
    case 'BULK_RESET_ALL':
    case 'BULK_CLOSED':
      return removeKeys(state, getLegKeys(state, action.bulkId));
    case 'BULK_LEG_PROPERTY_CHANGED':
      return updateBulkLeg(state, action.bulkId, action.legId, action.patch);
    case 'BULK_LEGS_PROPERTY_CHANGED':
      return getLegKeys(state, action.bulkId).reduce(
        (accumulatedState, legKey) =>
          updateKey(accumulatedState, legKey, leg => ({
            inputs: action.patch,
            errors: leg.errors,
            values: leg.values,
          })),
        state,
      );
    case 'BULK_PROPERTIES_RECEIVED':
      return patchBulkLegs(state, action.bulkId, action.patch.legs, action.excludedLegsIds);
    case 'BULK_IMPORTED':
      const initialState = Object.keys(action.patchLegs).reduce(
        (_stateAcc, legId) => addKey(state, getLegKey(action.bulkId, legId), emptyFxBulkLegState),
        state,
      );
      return Object.keys(action.patchLegs).reduce(
        (stateAcc, legId) =>
          updateBulkLeg(stateAcc, action.bulkId, legId, action.patchLegs![legId]),
        initialState,
      );
    case 'BULK_PROPERTY_CHANGED':
      if (action.patchLegs === undefined) {
        return state;
      }
      return Object.keys(action.patchLegs).reduce(
        (stateAcc, legId) =>
          updateBulkLeg(stateAcc, action.bulkId, legId, action.patchLegs![legId]),
        state,
      );
    case 'LOCAL_LEG_FIELD_VALIDATION_SET':
      return action.instrument !== 'Bulk'
        ? state
        : updateKey(state, getLegKey(action.quoteId, action.legId), ({ errors, warnings }) => {
            const validation = {
              code: action.messageId,
              userNotified: false,
            };
            return action.validationStatus === 'warning'
              ? { warnings: { ...warnings, [action.field]: validation } }
              : { errors: { ...errors, [action.field]: validation } };
          });
    case 'LOCAL_LEG_FIELD_VALIDATION_CLEAR':
      return action.instrument !== 'Bulk'
        ? state
        : updateKey(state, getLegKey(action.quoteId, action.legId), ({ errors, warnings }) => ({
            errors: removeKey(errors, action.field),
            warnings: removeKey(warnings, action.field),
          }));
    case 'LEG_FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Bulk'
        ? state
        : updateKey(state, getLegKey(action.quoteId, action.legId), ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));
    case 'BULK_LEG_RESET': {
      return resetLeg(state, action.bulkId, action.legId);
    }
    case 'BULK_EXECUTION_SENT': {
      return getLegKeys(state, action.bulkId).reduce(
        (accumulatedState, legKey) =>
          updateKey(accumulatedState, legKey, _leg => ({
            warnings: {},
          })),
        state,
      );
    }
    default:
      return state;
  }
};

export const splitLegsReducer: Reducer<FxBulkSplitLegStateMap> = (
  state: FxBulkSplitLegStateMap = {},
  action: Action,
) => {
  switch (action.type) {
    case 'BULK_RFS_CANCEL':
    case 'BULK_RFS_TERMINATED':
    case 'BULK_RESTART':
      return removeKeys(state, getLegKeys(state, action.bulkId));
    case 'BULK_QUOTE_RECEIVED':
      return action.quote.legs.reduce(
        (s, leg, index) => ({
          ...s,
          [`${action.bulkId}/${index}`]: toSimpleLeg(leg, index),
        }),
        state,
      );
    default:
      return state;
  }
};

export const nettedLegsReducer: Reducer<FxBulkNettedLegStateMap> = (
  state: FxBulkNettedLegStateMap = {},
  action: Action,
) => {
  switch (action.type) {
    case 'BULK_RFS_CANCEL':
    case 'BULK_RFS_TERMINATED':
    case 'BULK_RESTART':
      return removeKeys(state, getLegKeys(state, action.bulkId));
    case 'BULK_QUOTE_RECEIVED':
      return action.quote.nettedLegs.reduce(
        (s, leg) => ({
          ...s,
          [makeBulkLegId(action.bulkId, leg.date)]: toMargedLeg(
            leg,
            leg.date,
            s[makeBulkLegId(action.bulkId, leg.date)],
          ) as IMargedLeg,
        }),
        state,
      );
    case 'BULK_INCREMENT_MARGIN':
    case 'BULK_DECREMENT_MARGIN':
    case 'BULK_SET_MARGIN':
      return updateKey(state, makeBulkLegId(action.bulkId, action.date), old => ({
        margin: old.margin === null ? null : marginReducer(old.margin, action),
      }));
    default:
      return state;
  }
};

const precisionAdder = precisionAdderWithPrecision(1);

export const marginReducer = (state: ILegMargin, action: BulkMarginAction): ILegMargin => {
  switch (action.type) {
    case 'BULK_INCREMENT_MARGIN':
      return {
        spotMargin: precisionAdder(state.spotMargin, 0.1),
        forwardMargin: state.forwardMargin,
      };
    case 'BULK_DECREMENT_MARGIN':
      return {
        spotMargin: precisionAdder(state.spotMargin, -0.1),
        forwardMargin: state.forwardMargin,
      };
    case 'BULK_SET_MARGIN':
      // TODO : add some smart algorithm to spread margin between spot and forward
      return {
        spotMargin: action.value,
        forwardMargin: state.forwardMargin,
      };
    default:
      return state;
  }
};

const getLegKeys = <T>(state: Collection<T>, bulkId: string) =>
  Object.keys(state).filter(bulkLegId => bulkLegId.startsWith(bulkId));

const updateBulkLeg = (
  state: FxBulkLegStateMap,
  bulkId: string,
  legId: string,
  legPatch: Partial<FxBulkLegInputs>,
) =>
  updateKey(state, getLegKey(bulkId, legId), leg => ({
    inputs: legPatch,
    errors: leg.errors,
    values: leg.values,
  }));

const toSimpleLeg = (quoteLeg: IQuoteLeg, index: number | string): ISimpleLeg => {
  const simpleLeg: ISimpleLeg = {
    id: index.toString(),
    amount: quoteLeg.amount,
    paymentDate: quoteLeg.date,
    paymentDateTenor: quoteLeg.dateTenor,
    product: (quoteLeg.isForward ? 'FxFwd' : 'FxSpot') as CashProductName,
    way: quoteLeg.customerWay,
  };
  return simpleLeg;
};

const toMargedLeg = (
  quoteLeg: IQuoteLeg,
  index: number | string,
  old: IMargedLeg | undefined,
): IMargedLeg => {
  const simpleLeg = toSimpleLeg(quoteLeg, index);
  const margin = old?.margin ?? computeMargin(quoteLeg);
  return { ...simpleLeg, ...{ margin } };
};

const computeMargin = (quoteLeg: IQuoteLeg): ILegMargin => {
  const getBidAskValueWithWay = getBidAskValueWith(quoteLeg.customerWay);

  return {
    forwardMargin: getBidAskValueWithWay(quoteLeg.forwardMarginPoints),
    spotMargin: getBidAskValueWithWay(quoteLeg.defaultMargin),
  };
};
