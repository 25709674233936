import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { FxOptionPatchWithTradeCaptureMetaData } from 'state/fxOptions/actions/optionProduct';
import { extract } from 'utils/object';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import type {
  TradeCaptureOptionRequestWrapper,
  TradeCaptureOptionResponseWrapper,
  TradeCaptureStrippingRequest,
} from './tradeCaptureOptionModel';

export interface TradeCaptureApiRequest {
  sessionId: string;
  tileId: string;
  request: TradeCaptureOptionRequestWrapper;
}
export interface ITradeCaptureOptionApi {
  timeout: number;
  createNewVersions(request: TradeCaptureApiRequest[]): Observable<TradeCaptureBatchedResponses>;
  createNewVersionsMultiLegs(
    request: TradeCaptureApiRequest[],
  ): Observable<TradeCaptureBatchedResponses>;
  createStrip(
    sessionId: string,
    request: TradeCaptureStrippingRequest,
  ): Observable<TradeCaptureOptionResponseWrapper>;
  createStripMultileg(
    sessionId: string,
    request: TradeCaptureStrippingRequest,
  ): Observable<TradeCaptureOptionResponseWrapper>;
}

export interface TradeCaptureBatchedResponses {
  [optionId: string]: TradeCaptureOptionResponseWrapper;
}

export interface FxOptionPatchWithOptionId {
  optionId: string;
  patch: FxOptionPatchWithTradeCaptureMetaData | null;
}

export function tradeCaptureApi(http: ISgmeHttp): ITradeCaptureOptionApi {
  return {
    createNewVersions(queries: TradeCaptureApiRequest[]) {
      const uri = 'api/tradeCaptureBatch/Vanilla';

      return http
        .post<TradeCaptureBatchedResponses>(uri, queries)
        .pipe(map(httpCall => httpCall.payload));
    },
    createNewVersionsMultiLegs(queries: TradeCaptureApiRequest[]) {
      const uri = 'api/tradeCaptureBatch/FxOptionMultileg';

      return http
        .post<TradeCaptureBatchedResponses>(uri, queries)
        .pipe(map(httpCall => httpCall.payload));
    },
    createStrip(sessionId: string, request: TradeCaptureStrippingRequest) {
      return http
        .post<TradeCaptureOptionResponseWrapper>(
          `api/tradeCapture/Vanilla/${sessionId}/stripping`,
          request,
        )
        .pipe(map(extract('payload')));
    },
    createStripMultileg(sessionId: string, request: TradeCaptureStrippingRequest) {
      return http
        .post<TradeCaptureOptionResponseWrapper>(
          `api/tradeCapture/FxOptionMultileg/${sessionId}/stripping`,
          request,
        )
        .pipe(map(extract('payload')));
    },
    timeout: 10_000,
  };
}
