import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { TradeCaptureApiRequest } from 'api/tradeCapture/cash/tradeCapture';
import type {
  CashPropertiesChangedPatch,
  CashTradeCaptureRequestLegProperties,
  TradeCaptureCashRequestWrapper,
} from 'api/tradeCapture/cash/tradeCaptureModel';
import type { CashProductName } from 'state/fxCashs/fxCashsModel';
import type { DateInputCultureInfo } from 'state/userPreferences';
import { parseWithCultureInfo } from 'utils/parseDateWithCultureInfo';
import { isDefined } from '@sgme/fp';

// Mapping WEB APP => XONE-TC
export function mapCashPropertiesPatchToTCRequest(
  patch: CashPropertiesChangedPatch,
  idVersion: number,
  productName: CashProductName,
  cultureInfo: DateInputCultureInfo,
): TradeCaptureCashRequestWrapper {
  const {
    legs: { 0: legPatch },
    ...productPatch
  } = patch;

  let tradeCapturelegPatch: Partial<CashTradeCaptureRequestLegProperties> = {
    productName,
  };

  if (legPatch !== undefined) {
    const maturityDate =
      legPatch.maturityDate === undefined
        ? undefined
        : legPatch.maturityDate === null
        ? null
        : parseWithCultureInfo(cultureInfo, legPatch.maturityDate);

    tradeCapturelegPatch = {
      ...tradeCapturelegPatch,
      maturityDateString: maturityDate ?? legPatch.maturityDateTenor,
      isNonDeliverable: legPatch.isNonDeliverable!,
      fixingCurrency: legPatch.fixingCurrency,
      xCurrency: legPatch.xCurrency,
      fixingSource: legPatch.fixingSource,
      sndFixingSource: legPatch.sndFixingSource,
    };

    if (legPatch.amount !== undefined) {
      if (legPatch.amountCurrency === 1) {
        tradeCapturelegPatch.amount1String = legPatch.amount;
      } else if (legPatch.amountCurrency === 2) {
        tradeCapturelegPatch.amount2String = legPatch.amount;
      }
    }
  }

  return {
    idVersion,
    changedFields: {
      ...productPatch,
      legs: {
        0: tradeCapturelegPatch,
      },
    },
  };
}

export const cashBuildTradeCaptureRequestWith: MapStateToMetadataHOF<
  TradeCaptureApiRequest,
  {
    cashId: string;
    patch: CashPropertiesChangedPatch;
    getNewGuid: () => string;
  },
  AppState,
  Selectors
> =
  sl =>
  (state, { cashId, getNewGuid, patch }) => {
    const currentSessionId = sl.getCashTradeCaptureCurrentSessionId(state, cashId);
    const sessionId = currentSessionId || getNewGuid();

    const productName = sl.getCashProductName(state, cashId);
    const { dateInputCultureInfo } = sl.getUserPreferenceData(state);
    if (productName === null) {
      throw 'cash tile not found:' + cashId;
    }

    if (
      isDefined(patch.legs[0]) &&
      isDefined(patch.legs[0].currencyPair) &&
      sl.getProductsAccess(state).nonDeliverable === false
    ) {
      patch.legs[0].isNonDeliverable = false;
    }

    const request: TradeCaptureCashRequestWrapper = mapCashPropertiesPatchToTCRequest(
      patch,
      0,
      productName,
      dateInputCultureInfo,
    );

    return {
      sessionId,
      tileId: cashId,
      request,
    };
  };
