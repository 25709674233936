import { useCallback } from 'react';
import { withTabId } from 'components/NavMenu';
import { connectCloseTabButton } from './connect';

export interface CloseTabButtonProps {
  close(): void;
}

export const CloseTabButtonRaw: React.FunctionComponent<CloseTabButtonProps> = ({ close }) => {
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      close();
    },
    [close],
  );
  return (
    <button
      type="button"
      className="align-self-baseline btn btn-flat-secondary btn-icon p-0 ms-1"
      onClick={onClick}
    >
      <i className="icon icon-sm px-2">close</i>
    </button>
  );
};

export const CloseTabButton = withTabId(connectCloseTabButton(CloseTabButtonRaw));
