import type { Thunk } from 'state';
import type { QuoteContributionType } from 'state/share/productModel/litterals';
import type { FxAccumulatorQuoteWithPrice } from '../fxAccumulatorsModel';

export function accumulatorRfsCancelThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const currentStreamId = sl.getAccumulatorRfsStreamId(state, quoteId);

    if (currentStreamId !== null) {
      const isPricing = sl.getAccumulatorRfsStatus(state, currentStreamId) === 'PRICING';

      dispatch(ac.accumulatorRfsCanceled(quoteId, currentStreamId, isPricing));
    }
    /**
     * @todo cancel pending request
     */
    dispatch(ac.accumulatorRfsClearError(quoteId));
  };
}

export function accumulatorExecutionRequestedThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (sl.isCanTradeState(state) !== true) {
      return;
    }

    const rfsStreamId = sl.getAccumulatorRfsStreamId(state, quoteId);
    if (rfsStreamId === null) {
      return;
    }

    dispatch(ac.accumulatorExecutionRequested(quoteId, rfsStreamId));
  };
}

export function accumulatorQuoteReceivedThunk(
  quoteId: string,
  rfsId: string,
  quote: FxAccumulatorQuoteWithPrice,
  quoteDate: Date,
  tiering: string | null,
  skippedLimitCheck: boolean,
  quoteContribution: QuoteContributionType | null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (!sl.isQuotePresentWithInstrument(state, quoteId, 'Accumulator')) {
      return;
    }
    const lastStreamId = sl.getAccumulatorLastStreamId(state, quoteId);
    if (lastStreamId !== null) {
      dispatch(ac.accumulatorRfsLastRemoved(quoteId, lastStreamId));
    }
    dispatch(
      ac.accumulatorRfsQuoteReceived(
        quoteId,
        rfsId,
        quote,
        quoteDate,
        tiering,
        skippedLimitCheck,
        quoteContribution,
      ),
    );
  };
}

export function accumulatorTraderNotificationReceivedThunk(
  quoteId: string,
  rfsId: string,
  traderId: string | null,
  quoteContribution: QuoteContributionType | null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (!sl.isTilePresent(state, quoteId)) {
      return;
    }
    const lastStreamId = sl.getAccumulatorLastStreamId(state, quoteId);
    if (lastStreamId !== null) {
      dispatch(ac.accumulatorRfsLastRemoved(quoteId, lastStreamId));
    }
    dispatch(ac.accumulatorTraderNotificationReceived(quoteId, rfsId, traderId, quoteContribution));
  };
}
