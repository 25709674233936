import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import type { AppState } from 'state/model';
import { isAuthExpired } from 'state/globalError/globalErrorSelectors';

const reload = () => window.location.reload();

interface ISessionTimeoutStateProps {
  sessionTimeout: boolean;
}

type SessionTimeoutProps = ISessionTimeoutStateProps;

const SessionTimeoutModalRaw: React.FunctionComponent<SessionTimeoutProps> = ({
  sessionTimeout,
}) => (
  <Modal
    isOpen={sessionTimeout}
    backdrop="static"
    className="modal-dialog-centered"
    contentClassName="shadow-max border border-danger"
  >
    <ModalHeader>
      <div
        className="rounded-circle bg-danger bg-opacity-10 d-flex align-items-center justify-content-center"
        style={{ width: '3rem', height: '3rem' }}
      >
        <i className="icon icon-md text-danger line-height-1"> power_off </i>
      </div>
    </ModalHeader>
    <ModalBody className="pb-0">
      <h3 className="text-danger">
        <FormattedMessage id="modals.timeout.title" />
      </h3>
      <p className="text-secondary text-large">
        <FormattedMessage id="modals.timeout.body.reason" />
      </p>
      <p className="text-large">
        <FormattedMessage id="modals.timeout.body.assistance" />
      </p>
    </ModalBody>
    <ModalFooter>
      <button type="button" className="btn btn-lg btn-default" onClick={reload}>
        <FormattedMessage id="modals.timeout.footer.button" />
      </button>
    </ModalFooter>
  </Modal>
);

const mapStateToProps = (state: AppState): ISessionTimeoutStateProps => ({
  sessionTimeout: isAuthExpired(state),
});

export const SessionTimeoutModal = connect(mapStateToProps)(SessionTimeoutModalRaw);

export default SessionTimeoutModal;
