import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { IBlotterErrorType } from 'state/blotter/blotterModel';

export interface BlotterErrorsConnectOwnProps {}

export interface BlotterErrorsConnectStateProps {
  errors: readonly IBlotterErrorType[];
}

// you can whitelist selectors after implementation
export type BlotterErrorsConnectSelectors = Selectors;
/*
export type BlotterErrorsConnectSelectorsKeys = 'aSelector'; // example
export type BlotterErrorsConnectSelectors = Pick<Selectors, BlotterErrorsConnectSelectorsKeys>;
*/

export const mapStateToPropsBlotterErrors: MapStateToPropsHOF<
  BlotterErrorsConnectStateProps,
  BlotterErrorsConnectOwnProps,
  AppState,
  BlotterErrorsConnectSelectors
> = sl => (state, _ownProps) => ({
  errors: sl.getBlotterErrors(state),
});
