import React from 'react';
import type { RenderPropChildren } from 'typings/utils';

type ScopedEventHandler<V, E, K extends keyof ReactHandlers<E>> = (value: V) => ReactHandlers<E>[K];

export type ScopedEventHandlers<V, E, K extends keyof ReactHandlers<E>> = {
  [P in K]: ScopedEventHandler<V, E, P>;
};

interface EventHandlersProps<V> {
  value: V;
}

export type HandlersChildrenProps<E, K extends keyof ReactHandlers<E>> = Pick<ReactHandlers<E>, K>;

export function createEventHandler<V, E, K extends keyof ReactHandlers<E>>(
  scopedEventHandlers: ScopedEventHandlers<V, E, K>,
): React.ComponentClass<
  EventHandlersProps<V> & RenderPropChildren<HandlersChildrenProps<E, K>>,
  Record<string, never>
> {
  return class EventHandlerRenderProps extends React.Component<
    EventHandlersProps<V> & RenderPropChildren<HandlersChildrenProps<E, K>>,
    Record<string, never>
  > {
    private eventHandlers = ((scopedHandlers: ScopedEventHandlers<V, E, K>) => {
      const handlers = {} as HandlersChildrenProps<E, K>;
      const keys = Object.keys(scopedHandlers) as K[];
      keys.forEach(cb => {
        if (scopedHandlers.hasOwnProperty(cb)) {
          handlers[cb] = (event: any) => {
            scopedHandlers[cb](this.props.value)(event);
          };
        }
      });
      return handlers;
    })(scopedEventHandlers);
    public render() {
      return this.props.children(this.eventHandlers);
    }
  };
}

type ReactHandlers<T> = ClipboardHandlers<T> &
  CompositionHandlers<T> &
  FocusHandlers<T> &
  FormHandlers<T> &
  ImageHandlers<T> &
  KeyboardHandlers<T> &
  MediaHandlers<T> &
  MouseHandlers<T> &
  SelectionHandlers<T> &
  TouchHandlers<T> &
  UIHandlers<T> &
  WheelHandlers<T> &
  AnimationHandlers<T> &
  TransitionHandlers<T>;

interface ClipboardHandlers<T> {
  onCopy: React.ClipboardEventHandler<T>;
  onCopyCapture: React.ClipboardEventHandler<T>;
  onCut: React.ClipboardEventHandler<T>;
  onCutCapture: React.ClipboardEventHandler<T>;
  onPaste: React.ClipboardEventHandler<T>;
  onPasteCapture: React.ClipboardEventHandler<T>;
}
interface CompositionHandlers<T> {
  onCompositionEnd: React.CompositionEventHandler<T>;
  onCompositionEndCapture: React.CompositionEventHandler<T>;
  onCompositionStart: React.CompositionEventHandler<T>;
  onCompositionStartCapture: React.CompositionEventHandler<T>;
  onCompositionUpdate: React.CompositionEventHandler<T>;
  onCompositionUpdateCapture: React.CompositionEventHandler<T>;
}

interface FocusHandlers<T> {
  onFocus: React.FocusEventHandler<T>;
  onFocusCapture: React.FocusEventHandler<T>;
  onBlur: React.FocusEventHandler<T>;
  onBlurCapture: React.FocusEventHandler<T>;
}

interface FormHandlers<T> {
  onChange: React.FormEventHandler<T>;
  onChangeCapture: React.FormEventHandler<T>;
  onInput: React.FormEventHandler<T>;
  onInputCapture: React.FormEventHandler<T>;
  onReset: React.FormEventHandler<T>;
  onResetCapture: React.FormEventHandler<T>;
  onSubmit: React.FormEventHandler<T>;
  onSubmitCapture: React.FormEventHandler<T>;
  onInvalid: React.FormEventHandler<T>;
  onInvalidCapture: React.FormEventHandler<T>;
}

interface ImageHandlers<T> {
  onLoad: React.ReactEventHandler<T>;
  onLoadCapture: React.ReactEventHandler<T>;
  onError: React.ReactEventHandler<T>; // also a Media Event
  onErrorCapture: React.ReactEventHandler<T>; // also a Media Event
}

interface KeyboardHandlers<T> {
  onKeyDown: React.KeyboardEventHandler<T>;
  onKeyDownCapture: React.KeyboardEventHandler<T>;
  onKeyPress: React.KeyboardEventHandler<T>;
  onKeyPressCapture: React.KeyboardEventHandler<T>;
  onKeyUp: React.KeyboardEventHandler<T>;
  onKeyUpCapture: React.KeyboardEventHandler<T>;
}

interface MediaHandlers<T> {
  onAbort: React.ReactEventHandler<T>;
  onAbortCapture: React.ReactEventHandler<T>;
  onCanPlay: React.ReactEventHandler<T>;
  onCanPlayCapture: React.ReactEventHandler<T>;
  onCanPlayThrough: React.ReactEventHandler<T>;
  onCanPlayThroughCapture: React.ReactEventHandler<T>;
  onDurationChange: React.ReactEventHandler<T>;
  onDurationChangeCapture: React.ReactEventHandler<T>;
  onEmptied: React.ReactEventHandler<T>;
  onEmptiedCapture: React.ReactEventHandler<T>;
  onEncrypted: React.ReactEventHandler<T>;
  onEncryptedCapture: React.ReactEventHandler<T>;
  onEnded: React.ReactEventHandler<T>;
  onEndedCapture: React.ReactEventHandler<T>;
  onLoadedData: React.ReactEventHandler<T>;
  onLoadedDataCapture: React.ReactEventHandler<T>;
  onLoadedMetadata: React.ReactEventHandler<T>;
  onLoadedMetadataCapture: React.ReactEventHandler<T>;
  onLoadStart: React.ReactEventHandler<T>;
  onLoadStartCapture: React.ReactEventHandler<T>;
  onPause: React.ReactEventHandler<T>;
  onPauseCapture: React.ReactEventHandler<T>;
  onPlay: React.ReactEventHandler<T>;
  onPlayCapture: React.ReactEventHandler<T>;
  onPlaying: React.ReactEventHandler<T>;
  onPlayingCapture: React.ReactEventHandler<T>;
  onProgress: React.ReactEventHandler<T>;
  onProgressCapture: React.ReactEventHandler<T>;
  onRateChange: React.ReactEventHandler<T>;
  onRateChangeCapture: React.ReactEventHandler<T>;
  onSeeked: React.ReactEventHandler<T>;
  onSeekedCapture: React.ReactEventHandler<T>;
  onSeeking: React.ReactEventHandler<T>;
  onSeekingCapture: React.ReactEventHandler<T>;
  onStalled: React.ReactEventHandler<T>;
  onStalledCapture: React.ReactEventHandler<T>;
  onSuspend: React.ReactEventHandler<T>;
  onSuspendCapture: React.ReactEventHandler<T>;
  onTimeUpdate: React.ReactEventHandler<T>;
  onTimeUpdateCapture: React.ReactEventHandler<T>;
  onVolumeChange: React.ReactEventHandler<T>;
  onVolumeChangeCapture: React.ReactEventHandler<T>;
  onWaiting: React.ReactEventHandler<T>;
  onWaitingCapture: React.ReactEventHandler<T>;
}

interface MouseHandlers<T> {
  onClick: React.MouseEventHandler<T>;
  onClickCapture: React.MouseEventHandler<T>;
  onContextMenu: React.MouseEventHandler<T>;
  onContextMenuCapture: React.MouseEventHandler<T>;
  onDoubleClick: React.MouseEventHandler<T>;
  onDoubleClickCapture: React.MouseEventHandler<T>;
  onDrag: React.DragEventHandler<T>;
  onDragCapture: React.DragEventHandler<T>;
  onDragEnd: React.DragEventHandler<T>;
  onDragEndCapture: React.DragEventHandler<T>;
  onDragEnter: React.DragEventHandler<T>;
  onDragEnterCapture: React.DragEventHandler<T>;
  onDragExit: React.DragEventHandler<T>;
  onDragExitCapture: React.DragEventHandler<T>;
  onDragLeave: React.DragEventHandler<T>;
  onDragLeaveCapture: React.DragEventHandler<T>;
  onDragOver: React.DragEventHandler<T>;
  onDragOverCapture: React.DragEventHandler<T>;
  onDragStart: React.DragEventHandler<T>;
  onDragStartCapture: React.DragEventHandler<T>;
  onDrop: React.DragEventHandler<T>;
  onDropCapture: React.DragEventHandler<T>;
  onMouseDown: React.MouseEventHandler<T>;
  onMouseDownCapture: React.MouseEventHandler<T>;
  onMouseEnter: React.MouseEventHandler<T>;
  onMouseLeave: React.MouseEventHandler<T>;
  onMouseMove: React.MouseEventHandler<T>;
  onMouseMoveCapture: React.MouseEventHandler<T>;
  onMouseOut: React.MouseEventHandler<T>;
  onMouseOutCapture: React.MouseEventHandler<T>;
  onMouseOver: React.MouseEventHandler<T>;
  onMouseOverCapture: React.MouseEventHandler<T>;
  onMouseUp: React.MouseEventHandler<T>;
  onMouseUpCapture: React.MouseEventHandler<T>;
}

interface SelectionHandlers<T> {
  onSelect: React.ReactEventHandler<T>;
  onSelectCapture: React.ReactEventHandler<T>;
}

interface TouchHandlers<T> {
  onTouchCancel: React.TouchEventHandler<T>;
  onTouchCancelCapture: React.TouchEventHandler<T>;
  onTouchEnd: React.TouchEventHandler<T>;
  onTouchEndCapture: React.TouchEventHandler<T>;
  onTouchMove: React.TouchEventHandler<T>;
  onTouchMoveCapture: React.TouchEventHandler<T>;
  onTouchStart: React.TouchEventHandler<T>;
  onTouchStartCapture: React.TouchEventHandler<T>;
}

interface UIHandlers<T> {
  onScroll: React.UIEventHandler<T>;
  onScrollCapture: React.UIEventHandler<T>;
}
interface WheelHandlers<T> {
  onWheel: React.WheelEventHandler<T>;
  onWheelCapture: React.WheelEventHandler<T>;
}
interface AnimationHandlers<T> {
  onAnimationStart: React.AnimationEventHandler<T>;
  onAnimationStartCapture: React.AnimationEventHandler<T>;
  onAnimationEnd: React.AnimationEventHandler<T>;
  onAnimationEndCapture: React.AnimationEventHandler<T>;
  onAnimationIteration: React.AnimationEventHandler<T>;
  onAnimationIterationCapture: React.AnimationEventHandler<T>;
}
interface TransitionHandlers<T> {
  onTransitionEnd: React.TransitionEventHandler<T>;
  onTransitionEndCapture: React.TransitionEventHandler<T>;
}
