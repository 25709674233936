import type { GridState } from '../gridLayoutModels';
import { type Patcher, composeLeftPatcher } from 'utils/stateMap';
import type { Action } from 'state/actions';
import { addGridItem } from './addGridItem';
import { updateAddTilePositions } from './updateAddTilePositions';
import { removeGridItem } from './removeGridItem';
import { stripTrailingEmptyColumns } from './stripTrailingEmptyColumns';
import { gridItemSizeChanged } from './gridItemSizeChange';
import { setDraggingStatus, dragGridItem, submitDraggingLayout } from './dragGridItem';
import { restoreGridItem } from './restoreGridItem';

export const gridStatePatcher = (action: Action): Patcher<GridState> => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      return composeLeftPatcher(addGridItem(action), updateAddTilePositions);
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      return composeLeftPatcher(restoreGridItem(action), updateAddTilePositions);
    case 'CLIENTWORKSPACE_TILE_DELETED':
      return composeLeftPatcher(
        removeGridItem(action),
        stripTrailingEmptyColumns,
        updateAddTilePositions,
      );
    case 'GRID_ITEM_SIZE_CHANGED':
      return composeLeftPatcher(
        gridItemSizeChanged(action),
        stripTrailingEmptyColumns,
        updateAddTilePositions,
      );
    case 'GRID_ITEM_DRAG':
      return composeLeftPatcher(
        setDraggingStatus(action.gridItemId),
        dragGridItem(action.position),
      );
    case 'GRID_ITEM_DRAG_END':
      return composeLeftPatcher(
        submitDraggingLayout,
        stripTrailingEmptyColumns,
        updateAddTilePositions,
      );
  }
  return () => null;
};
