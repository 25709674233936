import { getNextFocus } from 'utils/getNextFocus';

export const changeFocusOnKeyDown: React.KeyboardEventHandler<HTMLElement> = ev => {
  if (ev.key !== 'Tab' && ev.key !== 'Enter') {
    return;
  }
  ev.preventDefault();

  const next = getNextFocus(ev.currentTarget, ev.target as HTMLElement, ev.shiftKey);
  if (next !== undefined) {
    next.focus();
  } else {
    (ev.target as HTMLElement).blur();
  }
};
