import { connect } from 'react-redux';
import type { AppState } from 'state/model';
import { getGridItemSize, getGridItemPosition } from 'state/gridLayout/selectors';

interface IGridDraggingPlaceholderOwnProps {
  gridItemId: string;
  gridId: string;
}

interface IGridDraggingPlaceholderStateProps {
  top: number;
  left: number;
  height: number;
  width: number;
}

type GridDraggingPlaceholderProps = IGridDraggingPlaceholderOwnProps &
  IGridDraggingPlaceholderStateProps;

const GridDraggingPlaceholderRaw: React.FunctionComponent<GridDraggingPlaceholderProps> = props => (
  <div style={props} className="position-absolute bg-secondary" />
);

function mapStateToProps(
  state: AppState,
  { gridItemId, gridId }: IGridDraggingPlaceholderOwnProps,
): IGridDraggingPlaceholderStateProps {
  return {
    ...getGridItemSize(state, gridId, gridItemId),
    ...getGridItemPosition(state, gridId, gridItemId),
  };
}

export const GridDraggingPlaceholder = connect(mapStateToProps)(GridDraggingPlaceholderRaw);
