import type { Reducer } from 'redux';
import { addKey, updateKey, removeKey } from 'utils/stateMap';
import type { Collection } from 'typings/utils';
import type { Mifid2Negotiation } from '../mifidPanelModel';
import type { Mifid2PanelActions } from '../mifidPanelActions';

export const mifid2NegotiationsReducer: Reducer<
  Collection<Mifid2Negotiation>,
  Mifid2PanelActions
> = (state = {}, action) => {
  switch (action.type) {
    case 'MIFID2_START_NEW_NEGOTIATION':
      return addKey(state, action.negotiationId, {
        startTime: action.timestamp,
        status: 'Open',
        quotes: [],
      });
    case 'MIFID2_FINISH_NEGOTIATION':
      return updateKey(state, action.negotiationId, () => ({
        status: [action.dealDone ? 'Done' : 'Missed', false],
      }));
    case 'MIFID2_FINISH_NEGOTIATION_FAILED':
      return updateKey(state, action.negotiationId, ({ status }) => ({
        status: status === 'Open' ? status : [status[0], true],
      }));
    case 'MIFID2_REMOVE_NEGOTIATION':
      return removeKey(state, action.negotiationId);
    case 'MIFID2_RECORD_TIMESTAMP_SENT':
      return updateKey(state, action.negotiationId, ({ quotes }) => ({
        quotes: [...quotes, action.quoteId],
      }));
    default:
      return state;
  }
};
