import { combineReducers } from 'redux';
import type { FxBulksState } from '../fxBulksModel';
import { bulksReducer } from './fxBulksProductReducer';
import { bulkStreamsReducer } from './fxBulkStreamReducer';
import { legsReducer, splitLegsReducer, nettedLegsReducer } from './fxBulksLegReducer';

export const fxBulksReducer = combineReducers<FxBulksState>({
  bulks: bulksReducer,
  legs: legsReducer,
  streams: bulkStreamsReducer,
  splitLegs: splitLegsReducer,
  nettedLegs: nettedLegsReducer,
});
