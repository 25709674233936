import type { RenderPropChildren } from 'typings/utils';
import { createHandler } from './createHandler';
import { useToggle } from './hooks/useToggle';

interface InputToggleProps {
  isEditable: boolean;
  onChange(str: string): void;
}
interface InputToggleChildrenProps {
  isOpen: boolean;
  toggle(): void;
  close(delay?: number): void;
  onChange(str: string): void;
}

export function InputToggle({
  children,
  ...props
}: RenderPropChildren<InputToggleChildrenProps> & InputToggleProps): JSX.Element {
  const [isOpen, toggle, close] = useToggle();
  return (
    <Handler value={{ toggle, onChange: props.onChange }}>
      {onChange => children({ onChange, isOpen, toggle, close })}
    </Handler>
  );
}

interface V {
  toggle(): void;
  onChange(s: string): void;
}

const Handler = createHandler((value: V) => (str: string) => {
  value.toggle();
  if (str.length > 1) {
    value.onChange(str);
  }
});
