import type { AccumulatorAmountSplitType, IFxAccumulatorExecutionData } from 'state/fxAccumulators/fxAccumulatorsModel';
import { sideToWay } from 'state/fxBulks/selectors';
import type { AppState } from 'state/model';
import type { CurrencyPair } from 'state/referenceData/referenceDataModel';
import type { Selectors } from 'state/selectors';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import { fieldData } from 'utils/fieldSelectors';
import { assertIsDefined } from '@sgme/fp';

const toCurrencyLabel =
  ({ ccy1, ccy2 }: CurrencyPair) =>
  (ccy: CurrencyChoice) =>
    ccy === 1 ? ccy1 : ccy2;

export const buildAccuExecutionReportWith: MapStateToMetadataHOF<
  IFxAccumulatorExecutionData,
  {
    accumulatorId: string;
    rfsId: string;
    tradeDate: Date;
    productName: string | null;
    amountSplitType: AccumulatorAmountSplitType;
  },
  AppState,
  Selectors
> =
  sl =>
  (state, { accumulatorId, rfsId, tradeDate, productName, amountSplitType }) => {
    const client = sl.getClientForTile(state, accumulatorId);
    const currencyPair = fieldData(sl.getAccumulatorCurrencyPairInput(state, accumulatorId)).data!;
    const pair = sl.getCurrencyPairDetails(state, currencyPair)!;
    const toCurrencyLabelWithPair = toCurrencyLabel(pair!);
    const amount = fieldData(sl.getAccumulatorAmount(state, accumulatorId)).data;
    const amountCurrency = fieldData(sl.getAccumulatorAmountCurrency(state, accumulatorId)).data;

    const expiryDate = fieldData(sl.getAccumulatorExpiryDate(state, accumulatorId)).data!;
    const expiryTenor = fieldData(sl.getAccumulatorExpiryTenor(state, accumulatorId)).data!;
    const firstFixingDate = fieldData(sl.getAccumulatorFirstFixingDate(state, accumulatorId)).data!;
    const firstFixingTenor = fieldData(sl.getAccumulatorFirstFixingTenor(state, accumulatorId)).data!;
    const lastFixingDate = sl.getAccumulatorLastFixingDate(state, accumulatorId)!;
    const fixingFrequency = fieldData(sl.getAccumulatorFixingFrequency(state, accumulatorId)).data!;
    const numberOfFixings = fieldData(sl.getAccumulatorNumberOfFixings(state, accumulatorId)).data;
    const target = fieldData(sl.getAccumulatorTarget(state, accumulatorId)).data!;
    const side = fieldData(sl.getAccumulatorWay(state, accumulatorId)).data!;
    const quote = sl.getAccumulatorRfsQuote(state, rfsId);

    assertIsDefined(quote, 'quote should exist during execution');

    const accuType =
      productName === 'FxForwardAccumulator'
        ? sl.getForwardAccumulatorAccuType(state, accumulatorId)
        : sl.getTargetAccumulatorAccuType(state, accumulatorId);

    const strike = fieldData(sl.getAccumulatorStrike(state, accumulatorId)).data;

    const akoTriggerValue = sl.getAccumulatorAkoTrigger(state, accumulatorId);
    const akoTrigger = akoTriggerValue.value !== null ? Number(fieldData(akoTriggerValue).data) : undefined;
    const barrier = sl.getAccumulatorBarrier(state, accumulatorId);

    const ekiTriggerValue = sl.getAccumulatorEkiTrigger(state, accumulatorId);
    const ekiTrigger = ekiTriggerValue.value !== null ? Number(fieldData(ekiTriggerValue).data) : undefined;

    const step = fieldData(sl.getAccumulatorStep(state, accumulatorId)).data;

    const execution: IFxAccumulatorExecutionData = {
      instrument: 'Accumulator',
      way: sideToWay(side),
      currencyPair,
      amount: Number(amount!),
      amountCcy: toCurrencyLabelWithPair(amountCurrency),
      premium: Math.abs(quote.premiumCcy1.ask),
      premiumCcy: toCurrencyLabelWithPair(1),
      priceLabelId: quote.premiumCcy1.ask >= 0 ? 'Pay' : 'Receive',
      accuType,
      strike: Number(strike),
      akoTrigger,
      barrier,
      ekiTrigger,
      step: Number(step),
      target: Number(target),
      firstFixingDate,
      firstFixingTenor,
      expiryDate: new Date(expiryDate),
      expiryTenor,
      lastFixingDate,
      fixingFrequency,
      numberOfFixings,
      client,
      tradeDate,
      productName,
      amountSplitType,
    };

    return execution;
  };
