import { getOrderValue } from './factories';

export const getOrderCurrency1 = getOrderValue('currency1');
export const getOrderCurrency2 = getOrderValue('currency2');
export const getOrderFixingTypes = getOrderValue('fixingTypes');
export const getOrderIsCcyForcedBidAsk = getOrderValue('isCcyForcedBidAsk');
export const getOrderIsStandardGroup = getOrderValue('isStandardGroup');
export const getOrderFixingTimesOfSelectedType = getOrderValue('fixingTimesOfSelectedType');
export const getOrderSourceNameOfSelectedType = getOrderValue('sourceNameOfSelectedType');
export const getOrderCanModifyMarginInBps = getOrderValue('canModifyMarginInBps');
