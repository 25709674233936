import type { AppState } from 'state/model';
import {
  getValueProperty,
  type ValueKey,
  type SharedKey,
  getSharedProperty,
  type SharedPropertyStatus,
} from 'state/share/form';
import type {
  IFxSwapMetadata,
  IFxSwapValues as SwapValue,
  IFxSwapInputs as SwapInput,
} from '../fxSwapsModel';
import { getSwapState } from './swap';

export type SwapMetadataStatus<T extends keyof IFxSwapMetadata> = IFxSwapMetadata[T];

export function getSwapMetadata<T extends keyof IFxSwapMetadata>(property: T) {
  return (state: AppState, SwapId: string): SwapMetadataStatus<T> =>
    getSwapState(state, SwapId)[property];
}
type SwapValuePropertyKey = ValueKey<SwapValue, SwapInput>;

export function getSwapValue<T extends SwapValuePropertyKey>(prop: T) {
  const get = getValueProperty<SwapValue, SwapInput, T>(prop);
  return (state: AppState, SwapId: string) => get(getSwapState(state, SwapId));
}

type SwapSharedPropertyKey = SharedKey<SwapValue, SwapInput>;

export function getSwapInput<T extends SwapSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<SwapValue, SwapInput, T>(prop);
  return (state: AppState, swapId: string) => get(getSwapState(state, swapId));
}

export type SwapSharedPropertyStatus<T extends SwapSharedPropertyKey> = SharedPropertyStatus<
  SwapValue[T],
  SwapInput[T]
>;
