import type { ISgmeHttp } from 'utils/sgmeHttp';

import type { IAccumulatorRfsExecuteApi, ForexOptionExecuteRequestFrontDto } from './model';
import { transformEmailsToBase64 } from 'utils/base64';

export function accumulatorRfsExecuteApi(http: ISgmeHttp): IAccumulatorRfsExecuteApi {
  return {
    executeQuote(payload: ForexOptionExecuteRequestFrontDto) {
      return http.post<void>(
        'api/fxOption/rfs/execute',
        transformEmailsToBase64(payload, 'emailNotificationList', 'email64NotificationList'),
        { 'X-Correlation-ID': payload.rfsId },
      );
    },
    msApiTimeout: 60_000,
  };
}
