import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface BamSearchResult {
  status: string;
  comment: string;
  bookingId: string;
}
export interface IBamSearchApi {
  findDeal: (dealId: string) => Observable<BamSearchResult | undefined>;
  msApiTimeout: number;
}
export const bamSearchApi = (http: ISgmeHttp): IBamSearchApi => ({
  findDeal: dealId =>
    http
      .getJSON<BamSearchResult | undefined>(`api/bamSearch?dealId=${dealId}`)
      .pipe(map(res => res.payload)),
  msApiTimeout: 1000,
});
