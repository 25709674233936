import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import {
  type SwapTradeCapturePatch,
  type TradeCaptureSwapResponseWrapper,
  SwapLegId,
  type SwapTradeCaptureResponseLegProperties,
  type SwapTradeCaptureResponseProperties,
} from 'api/tradeCapture/swap/tradeCaptureModel';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { clearUndefined } from 'utils/clearUndefined';
import {
  getPreviousMap,
  previousValue,
  fromTcWarning,
  fromTcError,
  mapAmount,
} from 'api/tradeCapture/tradeCaptureMappingHelper';
import type { BidAsk } from 'state/share/productModel/litterals';
import type { IFxSwapValues } from 'state/fxSwaps/fxSwapsModel';
import { isDefined } from '@sgme/fp';

interface WithSwapId {
  swapId: string;
}

export type TradeCaptureFromBackendMetaSelectorsKeys = 'getSwapNearPrice' | 'getSwapFarPrice';
export type TradeCaptureFromBackendMetaSelectorSelectors = Pick<
  Selectors,
  TradeCaptureFromBackendMetaSelectorsKeys
>;

// ---------------------------------------------------------------------------------
// Public meta selector
// ---------------------------------------------------------------------------------

export const swapTradeCaptureFromBackendWith: MapStateToMetadataHOF<
  SwapTradeCapturePatch,
  TradeCaptureSwapResponseWrapper & WithSwapId,
  AppState,
  TradeCaptureFromBackendMetaSelectorSelectors
> =
  sl =>
  (state, { changedFields: tcChangedFields, errors, warnings, idVersion, swapId }) => {
    const { legs, ...swapProperties } = tcChangedFields;
    const { legs: legsErrors, ...swapErrors } = errors || { legs: {} };
    const { legs: legsWarnings, ...swapWarnings } = warnings || { legs: {} };

    const {
      isReadyToPrice: _legIsReadyToPrice = false,
      isPriceObsolete: _legIsPriceObsolete = false,
      ...legProperties
    } = legs[SwapLegId] || {};

    const legErrors = legsErrors?.[SwapLegId] ?? {};
    const legWarnings = legsWarnings?.[SwapLegId] ?? {};

    const { isReadyToPrice } = legs[SwapLegId];

    const stateNearPrice = sl.getSwapNearPrice(state, swapId).value;
    const stateFarPrice = sl.getSwapFarPrice(state, swapId).value;
    return {
      idVersion,
      values: {
        ...clearUndefined({
          ...getPreviousMap(swapErrors),
          ...getPreviousMap(swapWarnings),
          ...getPreviousMap(legErrors),
          ...getPreviousMap(legWarnings),
          nearAmount: previousValue(legErrors.nearAmount1 ?? legErrors.nearAmount2),
          farAmount: previousValue(legErrors.farAmount1 ?? legErrors.farAmount2),
          nearPriceReferenceBid: undefined,
          nearPriceReferenceAsk: undefined,
          nearPriceReference: mapPriceReferenceFromTradeCapture(
            stateNearPrice,
            previousValue(legErrors.nearPriceReferenceBid),
            previousValue(legErrors.nearPriceReferenceAsk),
          ),
          farPriceReference: mapPriceReferenceFromTradeCapture(
            stateFarPrice,
            previousValue(legErrors.farPriceReferenceBid),
            previousValue(legErrors.farPriceReferenceAsk),
          ),
        }),
        ...mapSwapTCPropertiesToSwapStoreProperties(swapProperties),
        ...metaSelectorTradeCaptureFromBackendToSwapLegValuesWith(sl)(state, {
          responseSwapLeg: legProperties,
          swapId,
        }),
      },
      errors: {
        ...swapErrors,
        ...clearUndefined({
          ...legErrors,
          farPaymentDate: fromTcError(legErrors.farPaymentDate),
          nearPaymentDate: fromTcError(legErrors.nearPaymentDate),
          nearAmount: fromTcError(legErrors.nearAmount1 ?? legErrors.nearAmount2),
          farAmount: fromTcError(legErrors.farAmount1 ?? legErrors.farAmount2),
          nearPriceReferenceAsk: undefined,
          nearPriceReferenceBid: undefined,
          nearPriceReference: fromTcError(
            legErrors.nearPriceReferenceBid ?? legErrors.nearPriceReferenceAsk,
          ),
          farPriceReferenceAsk: undefined,
          farPriceReferenceBid: undefined,
          farPriceReference: fromTcError(
            legErrors.farPriceReferenceBid ?? legErrors.farPriceReferenceAsk,
          ),
          nearAmount1: undefined,
          nearAmount2: undefined,
          farAmount1: undefined,
          farAmount2: undefined,
        }),
      },
      warnings: {
        ...swapWarnings,
        ...clearUndefined({
          ...legsWarnings,
          nearAmount: fromTcWarning(legWarnings.nearAmount1 ?? legWarnings.nearAmount2),
          farAmount: fromTcWarning(legWarnings.farAmount1 ?? legWarnings.farAmount2),
          nearAmount1: undefined,
          nearAmount2: undefined,
          farAmount1: undefined,
          farAmount2: undefined,
        }),
      },
      isReadyToPrice: isReadyToPrice ?? null,
      isPriceObsolete: legs[SwapLegId].isPriceObsolete ?? false,
      productName: legs[SwapLegId].productName ?? 'FxSwap',
    } as SwapTradeCapturePatch;
  };

// ---------------------------------------------------------------------------------
// Private metaSelector
// ---------------------------------------------------------------------------------

const metaSelectorTradeCaptureFromBackendToSwapLegValuesWith: MapStateToMetadataHOF<
  Partial<IFxSwapValues>,
  {
    responseSwapLeg: Partial<SwapTradeCaptureResponseLegProperties>;
  } & WithSwapId,
  AppState,
  TradeCaptureFromBackendMetaSelectorSelectors
> =
  sl =>
  (state, { responseSwapLeg, swapId }) => {
    const stateNearPrice = sl.getSwapNearPrice(state, swapId).value;
    const stateFarPrice = sl.getSwapFarPrice(state, swapId).value;

    const mappedSwapLegProperties: Partial<IFxSwapValues> = {
      currencyPair: responseSwapLeg.currencyPair,
      productName: responseSwapLeg.productName,
      currency1: responseSwapLeg.currency1,
      currency2: responseSwapLeg.currency2,
      nearAmount: mapAmount(
        responseSwapLeg.negotiatedCurrency,
        responseSwapLeg.nearAmount1,
        responseSwapLeg.nearAmount2,
      ),
      farAmount: mapAmount(
        responseSwapLeg.negotiatedCurrency,
        responseSwapLeg.farAmount1,
        responseSwapLeg.farAmount2,
      ),
      amountCurrency: responseSwapLeg.negotiatedCurrency,
      nearPaymentDate: responseSwapLeg.nearPaymentDate,
      nearPaymentDateTenor: responseSwapLeg.nearPaymentDateTenor,
      farPaymentDate: responseSwapLeg.farPaymentDate,
      farPaymentDateTenor: responseSwapLeg.farPaymentDateTenor,
      isUneven: responseSwapLeg.isUneven,
      isOffMarket: responseSwapLeg.swapOffMarket,
      nearFixingDate: responseSwapLeg.nearFixingDate,
      farFixingDate: responseSwapLeg.farFixingDate,
      fixingSource: responseSwapLeg.fixingSource,
      fixingCurrency: responseSwapLeg.fixingCurrency,
      possibleFixingSources: responseSwapLeg.possibleFixingSources,
      isCrossed: responseSwapLeg.isCrossed,
      possibleXCurrencies: responseSwapLeg.possibleXCurrencies,
      possibleSndFixingSources: responseSwapLeg.possibleSndFixingSources,
      xCurrency: responseSwapLeg.xCurrency,
      sndFixingSource: responseSwapLeg.sndFixingSource,
      nearPriceReference: mapPriceReferenceFromTradeCapture(
        stateNearPrice,
        responseSwapLeg.nearPriceReferenceBid,
        responseSwapLeg.nearPriceReferenceAsk,
      ),
      farPriceReference: mapPriceReferenceFromTradeCapture(
        stateFarPrice,
        responseSwapLeg.farPriceReferenceBid,
        responseSwapLeg.farPriceReferenceAsk,
      ),
    };
    return clearUndefined(mappedSwapLegProperties);
  };

function mapSwapTCPropertiesToSwapStoreProperties(
  responseSwap: Partial<SwapTradeCaptureResponseProperties>,
): Partial<IFxSwapValues> {
  const mappedSwapProperties: Partial<IFxSwapValues> = {
    isNonDeliverable: responseSwap.isNonDeliverable,
  };
  return clearUndefined(mappedSwapProperties);
}

function mapPriceReferenceFromTradeCapture(
  priceState: BidAsk<number> | null,
  priceReferenceBid: number | null | undefined,
  priceReferenceAsk: number | null | undefined,
): BidAsk<number> | null | undefined {
  if (priceReferenceAsk === undefined && priceReferenceBid === undefined) {
    return undefined;
  }
  if (priceReferenceAsk === null && priceReferenceBid === null) {
    return null;
  }

  if (isDefined(priceReferenceBid) && isDefined(priceReferenceAsk)) {
    return {
      ask: priceReferenceAsk,
      bid: priceReferenceBid,
    };
  }

  if (priceState === null) {
    return {
      bid: isDefined(priceReferenceBid) ? priceReferenceBid : 0,
      ask: isDefined(priceReferenceAsk) ? priceReferenceAsk : 0,
    };
  } else {
    return {
      bid: isDefined(priceReferenceBid) ? priceReferenceBid : priceState.bid,
      ask: isDefined(priceReferenceAsk) ? priceReferenceAsk : priceState.ask,
    };
  }
}
