import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';

export interface IRfsQuoteCancelRequestApi {
  cancelQuoteRequest(rfsId: string): Observable<never>;
}

export function quoteCancelRequestApi(http: ISgmeHttp): IRfsQuoteCancelRequestApi {
  return {
    cancelQuoteRequest(rfsId) {
      return http
        .post<void>('api/fxOption/rfs/cancel', { rfsId }, { 'X-Correlation-ID': rfsId })
        .pipe(ignoreElements());
    },
  };
}
