import type { ClientWorkspaceTileRestored } from 'state/clientWorkspace/clientWorkspaceActions';
import type { Columns, GridState } from '../gridLayoutModels';
import type { SavedTileSize, SavedTilePosition } from 'api/workspaceService/model';
import { colWidth } from 'styles/constants';

export const restoreGridItem =
  ({ tileId, savedTile: { position, size } }: ClientWorkspaceTileRestored) =>
  ({ gridLayout: { columns, gridItemPositions, gridItemSizes } }: GridState) => ({
    gridLayout: {
      columns: addLoci(columns, tileId, position, size),
      gridItemPositions: {
        ...gridItemPositions,
        [tileId]: { top: position.top, left: position.colPosition * colWidth },
      },
      gridItemSizes: {
        ...gridItemSizes,
        [tileId]: { height: size.height, width: size.colSize * colWidth },
      },
    },
  });

function addLoci(
  columns: Columns,
  gridItemId: string,
  { top, colPosition }: SavedTilePosition,
  { height, colSize }: SavedTileSize,
) {
  let newColumns = [...columns];
  const columnIndexes = Array.from(Array(colSize), (_item, index) => index + colPosition);
  const maxIndex = Math.max(...columnIndexes);
  if (newColumns.length <= maxIndex) {
    const missingColNumber = maxIndex + 1 - newColumns.length;
    const missingColumns =
      missingColNumber > 0 ? Array.from(Array(missingColNumber), () => []) : [];

    newColumns = newColumns.concat(missingColumns);
  }
  columnIndexes.forEach(index => {
    newColumns[index] = [...newColumns[index], { top, bottom: top + height, gridItemId }].sort(
      ({ top: a }, { top: b }) => a - b,
    );
  });
  return newColumns;
}
