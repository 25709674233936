type KeyType = string | number | symbol;

type ExtractValue<T, K extends KeyType> = T extends {
  [key in K]: infer V;
}
  ? V
  : never;

export type StrictExtract<T, U extends T> = Extract<T, U>;

export const extract =
  <K extends KeyType>(key: K) =>
  <T extends { [k in K]?: any }>(value: T): ExtractValue<T, K> =>
    value[key];
