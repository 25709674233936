import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { productPatcher } from 'state/share/patchHelper';
import { addPriceRecorded } from 'state/share/priceRecordReducerHelper';
import { validationPartialReducer } from 'state/share/validationReducer';
import { addKey, removeKey, removeKeys, updateKey, updateProperty } from 'utils/stateMap';
import type { FxAccumulatorInputs, FxAccumulatorMap, FxAccumulatorState } from '../fxAccumulatorsModel';

export const defaultFxAccumulatorState: FxAccumulatorState = {
  errors: {},
  values: {
    productName: 'FxTargetAccumulator',
    ekiDown: null,
    ekiUp: null,
    currencyPair: null,
    premiumDate: null,
    premiumDateTenor: null,
    way: 'Buy',
    amount: null,
    amountSplitType: 'Fixing',
    amountCurrency: 1,
    leverage: '',
    leverageAmount: null,
    strike: null,
    step: null,
    akoTrigger: null,
    barrier: null,
    ekiTrigger: null,
    stepDown: undefined,
    stepUp: undefined,
    priceType: 'Amount',
    priceCurrency: 1,
    possiblePriceTypes: ['Amount'],
    hedgeType: 'Live',
    hedgePrice: null,
    hedgePaymentDate: null,
    hedgeAmount: null,
    hedgeCurrency: 1,
    possibleHedgeTypes: ['Live', 'Spot'],
    targetProfitTypeList: [''],
    targetProfitType: null,
    target: null,
    firstFixingDate: null,
    firstFixingDateTenor: null,
    defaultFixingFrequency: '',
    fixingFrequenciesList: [],
    fixingFrequency: '',
    numberOfFixings: 0,
    settlementFrequency: '',
    defaultSettlementFrequency: '',
    settlementFrequenciesList: [],
    settlementMode: 'Physical_Spot',
    settlementModeList: [],
    cashSettlementCurrency: '',
    cashSettlementCurrencyList: [],
    expiryDate: null,
    expiryTenor: null,
    fixingReferencesList1: [],
    defaultFixingReference1: '',
    fixingReference1: '',
    fixingReferencesList2: [],
    defaultFixingReference2: '',
    fixingReference2: '',
    lastFixingDate: null,
    calendar: [],
    deliveryDate: '',
    cutOffMarketPlace: '',
    totalLeverage: null,
    totalNotional: null,
    markupCurrency: 'ccy1',
    customFields: [],
    isCrossed: false,
    crossCurrency: '',
    possibleCrossCurrencies: [],
    accuType: 'TargetAccu',
    possibleAccuTypeList: ['TargetAccu', 'PIVOT', 'EKI', 'PIVOTEKI'],
    strikeComparator: '',
    eki: null,
    ekiString: '',
  },
  inputs: {},
  warnings: {},
  displayMode: 'PRICER',
  currentEspStreamId: null,
  currentSessionId: null,
  isPriceable: false,
  currentExecutionId: null,
  currentStreamId: null,
  lastStreamId: null,
  lastExecutedQuoteId: null,
  lastStreamError: null,
  priceRecords: [],
  propertiesRequested: false,
  propertiesRequestError: null,
  rfsStartedAt: null,
  tradeCaptureIdVersion: null,
  legs: [],
  numberOfSettlements: 0,
};

const defaultFxAccumulatorsState: FxAccumulatorMap = {};

const updateInput = updateProperty('inputs');

const accumulatorValidationReducer = validationPartialReducer<'Accumulator', FxAccumulatorState>('Accumulator');

// TODO: review all cases execpt CLIENTWORKSPACE_TILE_RESTORED because instrument is accumulator now
export const fxAccumulatorsProductReducer: Reducer<FxAccumulatorMap, Action> = (
  state = defaultFxAccumulatorsState,
  action,
) => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      return action.savedTile.instrument === 'Accumulator'
        ? addKey(state, action.tileId, {
            ...defaultFxAccumulatorState,
            values: {
              ...defaultFxAccumulatorState.values,
              productName: action.savedTile.productName,
            },
          })
        : state;
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
      return action.instrument === 'TargetAccumulator' || action.instrument === 'ForwardAccumulator'
        ? addKey(state, action.tileId, defaultFxAccumulatorState)
        : state;
    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      return action.instrument === 'TargetAccumulator' || action.instrument === 'ForwardAccumulator'
        ? addKey(state, action.tileId, {
            ...defaultFxAccumulatorState,
            values: {
              ...defaultFxAccumulatorState.values,
              productName: action.instrument === 'TargetAccumulator' ? 'FxTargetAccumulator' : 'FxForwardAccumulator',
            },
          })
        : state;
    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
      if (action.instrument === 'TargetAccumulator' || action.instrument === 'ForwardAccumulator') {
        const newState: FxAccumulatorState = {
          ...defaultFxAccumulatorState,
          values: {
            ...defaultFxAccumulatorState.values,
            productName: action.instrument === 'TargetAccumulator' ? 'FxTargetAccumulator' : 'FxForwardAccumulator',
          },
        };
        if (state[action.tileId] !== undefined) {
          return addKey(removeKey(state, action.tileId), action.tileId, newState);
        } else {
          return addKey(state, action.tileId, newState);
        }
      } else {
        return removeKey(state, action.tileId);
      }
    case 'CLIENTWORKSPACE_TILE_DELETED':
      return removeKey(state, action.tileId);
    case 'ACCUMULATOR_PROPERTIES_CHANGED':
      return updateKey(
        state,
        action.quoteId,
        updateInput<Partial<FxAccumulatorInputs>>(() => action.patch),
        () =>
          action.patch.schedule === undefined
            ? { lastStreamError: null }
            : {
                legs: action.patch.schedule === null ? [] : Object.keys(action.patch.schedule),
              },
      );
    case 'ACCUMULATOR_PROPERTIES_REQUESTED':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: true,
        currentSessionId: action.sessionId,
      }));
    case 'ACCUMULATOR_PROPERTIES_REQUEST_FAILED':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: false,
        propertiesRequestError: action.error,
      }));
    case 'ACCUMULATOR_PROPERTIES_RECEIVED':
      return productPatcher(state, action, accumulator => ({
        propertiesRequestError: null,
        summary: action.summary,
        numberOfSettlements: action.numberOfSettlements || accumulator.numberOfSettlements,
        legs: action.schedule !== undefined ? Object.keys(action.schedule) : accumulator.legs,
      }));
    case 'ACCUMULATOR_SCHEDULE_LEG_ADDED':
      return updateKey(state, action.quoteId, ({ legs }) => ({
        legs: [...legs, action.legId],
      }));
    case 'ACCUMULATOR_TILE_RESET':
      return updateKey(state, action.quoteId, legacyAccu => ({
        ...defaultFxAccumulatorState,
        displayMode: legacyAccu.displayMode,
        values: {
          ...defaultFxAccumulatorState.values,
          productName: legacyAccu.values.productName,
        },
      }));
    case 'ACCUMULATOR_PROPERTIES_REMOVE_ERROR':
      return updateKey(state, action.quoteId, quoteState => ({
        errors: removeKeys(quoteState.errors, action.keys),
      }));
    case 'FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Accumulator'
        ? state
        : updateKey(state, action.quoteId, ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));
    case 'ACCUMULATOR_PROPERTIES_PRISTINE':
      return updateKey(state, action.quoteId, cashState => ({
        ...cashState,
        dirtyFields: removeKeys(cashState.errors, [action.fieldName]),
      }));
    case 'ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamId,
      }));
    case 'ESP_STREAM_RECONNECTED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamKey,
      }));
    case 'ESP_STREAM_TILE_UNSUBSCRIBE':
    case 'ESP_STREAM_KEY_REQUEST_PENDING':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: null,
      }));
    case 'ACCUMULATOR_RFS_STARTED':
      return updateKey(state, action.quoteId, () => ({
        currentStreamId: action.rfsId,
        lastStreamError: null,
        rfsStartedAt: action.rfsStartedAt,
        priceRecords: [],
      }));
    case 'ACCUMULATOR_RFS_CANCELED':
    case 'ACCUMULATOR_RFS_TERMINATED':
      return updateKey(state, action.quoteId, ({ currentStreamId }) =>
        currentStreamId !== action.rfsId
          ? null
          : {
              currentStreamId: null,
              lastStreamId: action.shouldKeepAsExpired ? currentStreamId : null,
              lastStreamError: null,
            },
      );
    case 'ACCUMULATOR_RFS_FAILED':
      return updateKey(state, action.quoteId, () => ({
        currentStreamId: null,
        lastStreamId: null,
        lastStreamError: action.error,
      }));
    case 'ACCUMULATOR_RFS_CLEAR_ERROR':
      return updateKey(state, action.quoteId, () => ({
        lastStreamError: null,
      }));
    case 'ACCUMULATOR_RFS_LAST_REMOVED':
      return updateKey(state, action.quoteId, () => ({
        lastStreamId: null,
      }));
    case 'ACCUMULATOR_EXECUTION_SENT':
      return updateKey(state, action.quoteId, ({ currentStreamId }) => ({
        currentStreamId: null,
        lastStreamId: currentStreamId,
        lastStreamError: null,
        currentExecutionId: action.executionId,
        lastExecutedQuoteId: action.executedQuoteId,
        warnings: {},
      }));
    case 'TILE_EXECUTION_OVERLAY_HIDDEN':
      return updateKey(state, action.quoteId, () => ({
        currentExecutionId: null,
      }));
    case 'ACCUMULATOR_DISPLAYMODE_TOGGLE':
      return updateKey(state, action.quoteId, ({ displayMode }) => ({
        displayMode: displayMode === 'PRICER' ? 'SCHEDULER' : 'PRICER',
      }));
    case 'RECORD_PRICE_SUCCEEDED':
    case 'RECORD_PRICE_FAILED':
      if (action.instrument !== 'Accumulator') {
        return state;
      }
      return updateKey(
        state,
        action.tileId,
        addPriceRecorded({
          priceRecord: {
            error: action.error,
            prices: action.error === true ? undefined : action.prices,
            timestamp: action.timestamp,
          },
        }),
      );
  }
  return accumulatorValidationReducer(state, action);
};
