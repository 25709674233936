import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import { withQuoteId } from 'components/contexts';
import {
  mapStateToPropsTileClientPicker,
  mapDispatchToPropsTileClientPicker,
} from './connectTileClientPicker';
import { TileClientPicker as TileClientPickerRaw } from './TileClientPicker';

const connectTileClientPicker = connect(
  mapStateToPropsTileClientPicker(selectors),
  mapDispatchToPropsTileClientPicker(actionCreators),
);

export const TileClientPicker = withQuoteId(connectTileClientPicker(TileClientPickerRaw));
