import type { IFxAccumulatorExecutionData } from '../fxAccumulatorsModel';
import type { IStreamError } from 'state/globalError/globalErrorModel';

export interface AccumulatorExecutionRequested {
  type: 'ACCUMULATOR_EXECUTION_REQUESTED';
  quoteId: string;
  rfsId: string;
}

export function accumulatorExecutionRequested(
  quoteId: string,
  rfsId: string,
): AccumulatorExecutionRequested {
  return {
    type: 'ACCUMULATOR_EXECUTION_REQUESTED',
    quoteId,
    rfsId,
  };
}

export interface AccumulatorExecutionSentSucceeded {
  type: 'ACCUMULATOR_EXECUTION_SENT_SUCCEEDED';
  executionId: string;
}

export function accumulatorExecutionSentSucceeded(
  executionId: string,
): AccumulatorExecutionSentSucceeded {
  return {
    type: 'ACCUMULATOR_EXECUTION_SENT_SUCCEEDED',
    executionId,
  };
}

export interface AccumulatorExecutionSent {
  type: 'ACCUMULATOR_EXECUTION_SENT';
  instrument: 'Accumulator';
  quoteId: string;
  rfsId: string;
  executionId: string;
  executedQuoteId: string;
  execution: IFxAccumulatorExecutionData;
}

export function accumulatorExecutionSent(
  quoteId: string,
  rfsId: string,
  executionId: string,
  executedQuoteId: string,
  execution: IFxAccumulatorExecutionData,
): AccumulatorExecutionSent {
  return {
    type: 'ACCUMULATOR_EXECUTION_SENT',
    instrument: 'Accumulator',
    quoteId,
    rfsId,
    executionId,
    executedQuoteId,
    execution,
  };
}

export interface AccumulatorExecutionFailed {
  type: 'ACCUMULATOR_EXECUTION_FAILED';
  streamError: IStreamError;
  executionId: string;
}

export function accumulatorExecutionFailed(
  executionId: string,
  streamError: IStreamError,
): AccumulatorExecutionFailed {
  return {
    type: 'ACCUMULATOR_EXECUTION_FAILED',
    streamError,
    executionId,
  };
}

export interface AccumulatorExecutionReceived {
  type: 'ACCUMULATOR_EXECUTION_RECEIVED';
  executionId: string;
}

export function accumulatorExecutionReceived(executionId: string): AccumulatorExecutionReceived {
  return {
    type: 'ACCUMULATOR_EXECUTION_RECEIVED',
    executionId,
  };
}

export interface AccumulatorFillReportReceived {
  type: 'ACCUMULATOR_FILL_REPORT_RECEIVED';
  executionId: string;
  tradeIds: readonly string[];
}

export function accumulatorFillReportReceived(
  executionId: string,
  tradeIds: readonly string[],
): AccumulatorFillReportReceived {
  return {
    type: 'ACCUMULATOR_FILL_REPORT_RECEIVED',
    executionId,
    tradeIds,
  };
}
