import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import type { ICashStreamDeleteRequest } from 'api/multipass/cash/esp/cashStreamRequest';
import { ignoreElements } from 'rxjs/operators';
export interface IUnsubscribeEspStreamRequestApi {
  unsubscribeEspStreamRequest(request: ICashStreamDeleteRequest): Observable<never>;
}

export function espStreamUnsubscribeRequestApi(http: ISgmeHttp): IUnsubscribeEspStreamRequestApi {
  return {
    unsubscribeEspStreamRequest(request) {
      return http.post<void>('api/fxSpotFwd/esp/cancel', request).pipe(ignoreElements());
    },
  };
}
