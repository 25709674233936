import type { Dispatch, Middleware, MiddlewareAPI, Action } from 'redux';
import type { DispatchWithThunkExt } from 'state';

/**
 * @param shouldBreak should return true when circuit breaker middleware prevents state updates
 */
export function circuitBreakerMiddleware<S>(shouldBreak: () => boolean) {
  return ((store: MiddlewareAPI<DispatchWithThunkExt, S>) =>
    (next: Dispatch<Action>) =>
    (action: Action) => {
      if (shouldBreak() === true) {
        // stop propagation to other middlewares or reducer
        // state will be returned as is, no mutation from any dispatch
        return store.getState();
      } else {
        next(action);
      }
    }) as Middleware;
}
