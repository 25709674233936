import { useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Toast } from './Toast';
import type { AppState } from 'state/model';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import type { ToastItem } from 'state/toasts/toastsModel';
import { actionCreators, type ActionCreators } from 'state/actions';
import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import en from './locales/en.json';
import fr from './locales/fr.json';

const messagesMap = { en, fr };

interface ToastsStateProps {
  toasts: readonly ToastItem[];
}
interface ToastsDispatchProps {
  remove(id: string): void;
}
type ToastsProps = ToastsStateProps & ToastsDispatchProps;

const ToastsContainer = styled.div.attrs({ className: 'position-absolute' })`
  top: 50px;
  right: 0;
  width: 22.5rem;
  z-index: 50;
`;

function ToastsRaw({ remove, toasts }: ToastsProps) {
  const removeGrowl = useCallback((id: string) => () => remove(id), [remove]);
  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <ToastsContainer>
        {toasts.map(toast => (
          <Toast
            close={removeGrowl(toast.id)}
            key={toast.id}
            name={toast.name}
            type={toast.type}
            values={toast.values}
          />
        ))}
      </ToastsContainer>
    </IntlComponentBoundary>
  );
}

const mapStateToProps = (state: AppState): ToastsStateProps => ({
  toasts: state.toasts.toasts,
});

export const mapDispatchToProps: MapDispatchToPropsHOF<
  ToastsDispatchProps,
  Record<string, never>,
  ActionCreators
> = ac => dispatch => ({
  remove(id) {
    dispatch(ac.removeToastAction(id));
  },
});

export const Toasts = connect(mapStateToProps, mapDispatchToProps(actionCreators))(ToastsRaw);

export default Toasts;
