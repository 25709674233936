import { selectors } from 'state/selectors';

import { metaSelectorBuildPendingExecutionEventLogWith } from './buildPendingExecutionEventLog';
import { metaSelectorBuildExecutionPendingMailPayloadWith } from './buildPendingExecutionMailPayload';
import { metaSelectorBuildApiErrorExecutionEventLogWith } from './buildApiTimeoutExecutionEventLog';

export const buildPendingExecutionEventLog =
  metaSelectorBuildPendingExecutionEventLogWith(selectors);

export const buildPendingExecutionMailPayload =
  metaSelectorBuildExecutionPendingMailPayloadWith(selectors);

export const buildApiErrorExecutionEventLog =
  metaSelectorBuildApiErrorExecutionEventLogWith(selectors);
