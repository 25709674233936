export const strictKeys: <K extends string | number | symbol, T>(obj: {
  [k in K]?: T;
}) => readonly K[] = Object.keys;

/**
 * Gets the keys of T that extend E
 */
export type GetKeysExtending<T, E> = {
  [K in keyof T]: T[K] extends E ? K : never;
}[keyof T];
