import { forwardRef, useContext, type ReactNode, type Ref } from 'react';
import { FormTypeContext } from './formTypeContext';
import type { Size } from './typings';

// Pass size by props is more easy than use context
// Archi is to hard ton understand where to set good size.
// Ex: in Tile the size is more "sm" but in bulk it's more "md" or default.
type InputGroupProps = { className?: string; size?: Size; children?: ReactNode };
export const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>(
  ({ className = '', children, size = "sm" }: InputGroupProps, ref: Ref<HTMLDivElement>) => {
    const formType = useContext(FormTypeContext);

    // @TODO what if size is default ? force to "md" ?
    return formType === 'form' ? (
      <div className={`input-group input-group-${size} ${className}`} ref={ref}>
        {children}
      </div>
    ) : (
      <td className={`p-0 ${className}`}>
        <div className={`input-group input-group-${size}`} ref={ref}>
          {children}
        </div>
      </td>
    );
  },
);

InputGroup.displayName = 'InputGroup';
