import { type ChangeEventHandler, Component, type ReactNode } from 'react';
import styled from 'styled-components';
import type { FunctionChildren, RenderPropChildren } from 'typings/utils';
import * as e2e from 'components/Form/Inputs/e2e';
import type { StrictOmit } from 'utils/object';
import type { E2E } from 'typings/e2e';

interface SelectState {}

interface SelectHighlightProps {
  highlight?: 'primary' | 'warning' | 'danger' | 'info' | 'success';
}

interface BaseSelectProps<T> extends RenderPropChildren<SelectChildrenProps<T>> {
  value: T;
  onChange(value: T): void;
}

interface SimpleSelectProps<T> {
  options: readonly T[];
}

interface GroupSelectProps<T> {
  groupOptions: ReadonlyArray<[string, readonly T[]]>;
  groupRender: FunctionChildren<{ groupName: string; children: ReactNode }>;
}

type SelectProps<T> = StrictOmit<
  JSX.IntrinsicElements['select'],
  'onChange' | 'value' | 'ref' | 'children'
> &
  BaseSelectProps<T> &
  (SimpleSelectProps<T> | GroupSelectProps<T>) &
  SelectHighlightProps &
  E2E;

export interface SelectChildrenProps<T> {
  value: string;
  key: number;
  option: T;
}

const SelectColored = styled.select<SelectHighlightProps>`
  ${({ highlight }) =>
    highlight === undefined
      ? ''
      : `color: var(--${highlight}) !important;
         option {
           color: var(--bs-primary);
         }`}
`;

// TODO ABO SGEFX-4435 convert Select to functional component
// const SelectTest = <T>(props: PropsWithChildren<SelectProps<T>>) => {return <div></div>}
// function SelectTest<T>(props: PropsWithChildren<SelectProps<T>>) {return <div></div>}

export class Select<T> extends Component<SelectProps<T>, SelectState> {
  public onChange: ChangeEventHandler<HTMLSelectElement> = event => {
    this.props.onChange(JSON.parse(event.target.value));
  };

  public render() {
    const { children, value, onChange: _, e2eHandle, e2eIsModified, ...selectProps } = this.props;
    return (
      <SelectColored
        {...selectProps}
        data-e2e={e2e.selector(e2eHandle)}
        data-e2e-is-modified={e2e.isModifiedHandleValue(e2eIsModified)}
        value={JSON.stringify(this.props.value)}
        onChange={this.onChange}
      >
        {'options' in this.props
          ? this.props.options.map((option, index) =>
              children({
                value: JSON.stringify(option),
                key: index,
                option,
              }),
            )
          : 'groupOptions' in this.props
          ? this.props.groupOptions.map(([groupName, options]) =>
              (this.props as GroupSelectProps<T>).groupRender({
                groupName,
                children: options.map((option, index) =>
                  children({
                    value: JSON.stringify(option),
                    key: index,
                    option,
                  }),
                ),
              }),
            )
          : null}
      </SelectColored>
    );
  }
}
