import type { Thunk } from 'state';

import { fieldData } from 'utils/fieldSelectors';
import { makeTransformPremiumInput } from '../selectors/optionPremium';
import { assertIsDefined, isDefined, isEmpty } from '@sgme/fp';
import { transformStringNum } from 'utils/number';
import type { FxOptionPatchWithTradeCaptureMetaData } from '../actions/optionProduct';
import type { FxOptionLegInputs, IFxOptionTypedStrategyLegInputs } from '../model/optionsLegs';

export function optionPropertiesReceivedThunk(
  optionId: string,
  optionPatch: FxOptionPatchWithTradeCaptureMetaData,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isQuotePresentWithInstrument(state, optionId, 'Option')) {
      return;
    }

    const optionPatchValues = optionPatch.option[optionId];

    const { idVersion, isReadyToPrice, isPriceObsolete, isProductObsolete, displayPriceType } =
      optionPatchValues;

    // If we have an expired stream and the price is obsolete, we clear it because the price needs to be updated after the properties change
    const lastStreamId = sl.getOptionLastStreamId(state, optionId);
    if (lastStreamId !== null && isPriceObsolete) {
      dispatch(ac.optionStreamLastRemoved(optionId, lastStreamId));
    }

    dispatch(
      ac.optionPropertiesReceived(
        optionId,
        optionPatch.legs,
        optionPatch.hedges,
        optionPatchValues,
        idVersion,
        isReadyToPrice,
        isPriceObsolete,
        isProductObsolete,
        displayPriceType,
      ),
    );

    const newState = getState();

    const firstLeg = sl.getFirstLegOfMultilegOfOption(newState, optionId);
    const vanillaLegIds = sl.getAllVanillaLegIdsOfOption(newState, optionId);

    const hasSolvableLeg = vanillaLegIds.some(
      vanillaLegId =>
        isDefined(sl.getOptionVanillaLegNotional(newState, vanillaLegId).value) &&
        isDefined(sl.getOptionVanillaLegExpiry(newState, vanillaLegId).value) &&
        sl.getOptionVanillaLegSolvingFor(newState, vanillaLegId) === 'Strike',
    );

    const optionIsSolvable =
      hasSolvableLeg &&
      vanillaLegIds.every(
        vanillaLegId =>
          (isDefined(sl.getOptionVanillaLegNotional(newState, vanillaLegId).value) &&
            isDefined(sl.getOptionVanillaLegExpiry(newState, vanillaLegId).value) &&
            isDefined(sl.getOptionVanillaLegStrike(newState, vanillaLegId).value)) ||
          sl.getOptionVanillaLegSolvingFor(newState, vanillaLegId) === 'Strike',
      );

    // Use for solving on Strangle SGEFX-4238
    const productName = firstLeg.productName;

    const allLegsHaveStrike = sl.isAllLegsSolvingInOption(newState, optionId, 'Strike');

    const canSolveStrangle =
      productName === 'Strangle' && allLegsHaveStrike;

    const canSolveRiskReversal =
      productName === 'RiskReversal' && allLegsHaveStrike;

    const isSolvingStrikeModalOpened =
      (canSolveStrangle || productName === 'Vanilla' || canSolveRiskReversal) &&
      optionIsSolvable &&
      sl.getOptionDisplaySolveStrike(newState, optionId) === false;

    if (isSolvingStrikeModalOpened) {
      dispatch(ac.toggleSolveStrikeModal(optionId, true));
    }

    const currencyPair = optionPatchValues.values.currencyPair;

    if (
      currencyPair !== undefined &&
      currencyPair !== sl.getOptionCurrencyPair(state, optionId).value
    ) {
      dispatch(ac.espStreamRestartThunk(optionId, currencyPair, undefined, undefined));
    }
  };
}

/**
 * to avoid accessing state in patch to trade capture conversion
 * this thunk implement a hack that lived in the component
 * @todo delete this thunk and allow patch mapper to access both
 * inputs (patch) and values (last TC state)
 * ie : execute selectors and aggregate data in TC request epic
 */
export function optionLegNotionalAmountChangedThunk(
  optionId: string,
  legId: string,
  notionalAmount: string,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    const notionalCurrency = sl.getOptionVanillaLegNotionalCurrency(state, legId).value;
    dispatch(
      ac.optionLegPropertyChanged(optionId, legId, {
        notionalCurrency,
        notionalAmount,
      }),
    );
  };
}

/** @todo check this thunk when enabling typed strategies in table view */
export function optionLegCurrencyChangedThunk(optionId: string, legId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    const legsCurrencyLinked = sl.getOptionLegsCurrencyLinked(state, optionId);
    const allVanillaLegIds = legsCurrencyLinked
      ? sl.getAllVanillaLegIdsOfOption(state, optionId)
      : [legId];

    const { data: oldCurrency } = fieldData(sl.getOptionVanillaLegNotionalCurrency(state, legId));
    const notionalCurrency = oldCurrency === 1 ? 2 : 1;

    const { localCurrencyChangeLegIds, legsPatch } = allVanillaLegIds.reduce(
      (acc, vanillaLegId) => {
        const { data: notionalAmount } = fieldData(
          sl.getOptionVanillaLegNotional(state, vanillaLegId),
        );

        if (isDefined(notionalAmount)) {
          acc.legsPatch[vanillaLegId] = {
            notionalAmount: notionalAmount.toString(),
            notionalCurrency,
          };
        } else {
          acc.localCurrencyChangeLegIds.push(vanillaLegId);
        }

        return acc;
      },
      {
        localCurrencyChangeLegIds: [] as string[],
        legsPatch: {} as Record<string, Partial<FxOptionLegInputs>>,
      },
    );

    if (!isEmpty(localCurrencyChangeLegIds)) {
      dispatch(
        ac.optionNotionalCurrencyChanged(optionId, localCurrencyChangeLegIds, notionalCurrency),
      );
    }

    if (Object.keys(legsPatch).length > 0) {
      dispatch(ac.optionLegsPropertyChanged(optionId, legsPatch));
    }

    // @TODO demander à Sylvain pourquoi c'est sur la premiere
    if (sl.getUserOptionAlignCurrencies(state) && allVanillaLegIds.length === 1) {
      const firstLegId = allVanillaLegIds[0];
      const premiumTypeString = sl.getOptionVanillaLegPremiumTypeString(state, firstLegId).value;
      dispatch(
        ac.optionLegsPropertyChanged(optionId, {
          [firstLegId]: { premiumCurrency: notionalCurrency, premiumTypeString },
        }),
      );
    }
  };
}

export function optionLegsWayToggledThunk(optionId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const allVanillaLegsWithId = sl.getAllVanillaLegsOfOptionWithId(state, optionId);

    const patchLegs = allVanillaLegsWithId.reduce((acc, [legId, vanillaLeg]) => {
      acc[legId] = {
        side: vanillaLeg.values.side === 'Buy' ? 'Sell' : 'Buy',
      };
      return acc;
    }, {} as Record<string, IFxOptionTypedStrategyLegInputs | Partial<FxOptionLegInputs>>);

    if (Object.keys(patchLegs).length > 0) {
      dispatch(ac.optionLegsPropertyChanged(optionId, patchLegs));
    }
  };
}

export function optionLegsGlobalPropertyChangeThunk(
  optionId: string,
  patch: Partial<FxOptionLegInputs>,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const vanillaLegIds = sl.getAllVanillaLegIdsOfOption(state, optionId);

    const patchLegs: Record<string, Partial<FxOptionLegInputs>> = {};

    vanillaLegIds.forEach(legId => {
      patchLegs[legId] = patch;
    });

    if (Object.keys(patchLegs).length > 0) {
      dispatch(ac.optionLegsPropertyChanged(optionId, patchLegs));
    }
  };
}

export function optionLegPremiumChangedThunk(
  optionId: string,
  legId: string,
  premiumInputBid: string | null,
  premiumInputAsk: string | null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const [, priceType] = sl.getOptionDisplayPriceType(state, optionId);

    const ccyPair = sl.getOptionCurrencyPair(state, optionId).value;
    assertIsDefined(ccyPair, 'Ccy pair not found to get precision');

    const precision = sl.getCurrencyPrecisionForPps(state, ccyPair);
    const mapPremium = makeTransformPremiumInput(priceType, precision);

    dispatch(
      ac.optionLegPropertyChanged(optionId, legId, {
        premiumBid: transformStringNum(mapPremium)(premiumInputBid),
        premiumAsk: transformStringNum(mapPremium)(premiumInputAsk),
      }),
    );
  };
}

export function optionDuplicateLegThunk(quoteId: string, legId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const [ccyChoice, priceType] = sl.getOptionDisplayPriceType(state, quoteId);

    if (priceType === 'VOLATILITY') {
      dispatch(ac.optionDisplayPriceTypeChanged(quoteId, [ccyChoice, 'AMOUNT']));
    }
    dispatch(ac.optionLegDuplicated(quoteId, legId));
  };
}

export function optionLegAddedThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const [ccyChoice, priceType] = sl.getOptionDisplayPriceType(state, quoteId);
    if (priceType === 'VOLATILITY' && sl.getLegIdsOfMultilegOfOption(state, quoteId).length === 1) {
      dispatch(ac.optionDisplayPriceTypeChanged(quoteId, [ccyChoice, 'AMOUNT']));
    }
    dispatch(ac.optionLegAdded(quoteId));
  };
}

export function optionRemoveLegOrSwitchToSimpleThunk(optionId: string, legId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const allVanillaLegs = sl.getAllVanillaLegsOfOption(state, optionId);
    if (allVanillaLegs.length > 1) {
      dispatch(ac.optionLegRemoved(optionId, legId));
    } else {
      dispatch(ac.optionToggleStrategyThunk(optionId, false));
    }
  };
}
