import type { AppState } from 'state/model';
import type { ExecutionStatus } from 'state/executions/executionsStateModel';
import type { Instrument } from 'state/referenceData';
import type { Selectors } from 'state/selectors';
import type { MapDispatchToPropsHOF, MapStateToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

interface ExecutionOverlayOwnProps {
  quoteId: string;
  overlay?: boolean;
}

interface ExecutionOverlayStateCommonProps {
  overlay?: boolean;
}

interface ExecutionOverlayStateWithExecutionProps {
  executionId: string;
  executionStatus: ExecutionStatus;
  instrument: Instrument;
}

interface ExecutionOverlayStateWithoutExecutionProps {
  executionId: null;
  executionStatus: null;
}

interface ExecutionOverlayDispatchProps {
  onDismiss: (executionId: string | null) => void;
  onViewLog: (executionId: string) => void;
  onClose: (executionId: string) => void;
}

type ExecutionOverlayStateProps = ExecutionOverlayStateCommonProps &
  (ExecutionOverlayStateWithExecutionProps | ExecutionOverlayStateWithoutExecutionProps);

export const mapStateToPropsExecStatus: MapStateToPropsHOF<
  ExecutionOverlayStateProps,
  ExecutionOverlayOwnProps,
  AppState,
  Selectors
> =
  sl =>
  (state: AppState, { quoteId }) => {
    if (quoteId === null) {
      return {
        executionId: null,
        executionStatus: null,
      };
    }
    const currentExecutionId = sl.getTileCurrentExecutionId(state, quoteId);

    if (currentExecutionId === null || !sl.hasExecution(state, currentExecutionId)) {
      return {
        executionId: null,
        executionStatus: null,
      };
    }

    const { status, instrument } = sl.getExecutionById(state, currentExecutionId);
    return {
      instrument,
      executionId: currentExecutionId,
      executionStatus: status === 'BackOfficeValidated' || status === 'NotIntegrated' ? 'Success' : status,
    };
  };

export const mapDispatchToPropsExecStatus: MapDispatchToPropsHOF<
  ExecutionOverlayDispatchProps,
  ExecutionOverlayOwnProps,
  ActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    onDismiss(executionId: string | null) {
      dispatch(
        executionId
          ? ac.orderErrorDismissedThunk(executionId, quoteId)
          : ac.blotterOrderToggleModeThunk(quoteId, 'Edit'),
      );
    },
    onViewLog(executionId) {
      dispatch(ac.displayExecutionErrorDetail(executionId));
    },
    onClose(executionId: string) {
      dispatch(ac.orderErrorDismissedThunk(executionId, quoteId));
      dispatch(ac.tileClosedThunk(quoteId));
    },
  });
