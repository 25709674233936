import type { SwapTradeCapturePatch } from 'api/tradeCapture/swap/tradeCaptureModel';
import type { Thunk } from 'state';

export function swapPropertiesReceivedThunk(
  swapId: string,
  swapPatch: SwapTradeCapturePatch,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    if (!sl.isTilePresent(state, swapId) || sl.getQuoteInstrument(state, swapId) !== 'Swap') {
      return;
    }

    const { idVersion, isReadyToPrice, isPriceObsolete, ...receivedPatch } = swapPatch;
    dispatch(
      ac.swapPropertiesReceived(swapId, receivedPatch, idVersion, isReadyToPrice, isPriceObsolete),
    );

    if (isPriceObsolete === true) {
      dispatch(ac.swapRfsCancelThunk(swapId));
    }

    if (swapPatch === undefined) {
      return;
    }
    const { currencyPair, nearPaymentDateTenor, farPaymentDateTenor } = swapPatch.values;
    if (
      (currencyPair !== undefined &&
        currencyPair !== sl.getSwapCurrencyPair(state, swapId).value) ||
      nearPaymentDateTenor !== undefined ||
      farPaymentDateTenor !== undefined
    ) {
      dispatch(
        ac.swapLocalPropertyChanged(swapId, {
          bidMargin: null,
          askMargin: null,
          bidSpotMargin: null,
          askSpotMargin: null,
        }),
      );
    }
  };
}
