export interface AppCrashedAction {
  type: 'APP_CRASHED';
  source: string;
  error: any;
}

export function createAppCrashedAction(source: string, error?: any): AppCrashedAction {
  return {
    type: 'APP_CRASHED',
    source,
    error,
  };
}

export interface UserOfflineAction {
  type: 'USER_OFFLINE';
}

export function userOfflineAction(): UserOfflineAction {
  return {
    type: 'USER_OFFLINE',
  };
}

export interface AuthExpired {
  type: 'AUTH_EXPIRED';
}

export function authExpiredAction(): AuthExpired {
  return {
    type: 'AUTH_EXPIRED',
  };
}

export interface NoClients {
  type: 'NO_CLIENTS';
}
export function noClientsAction(): NoClients {
  return {
    type: 'NO_CLIENTS',
  };
}

export interface NoProducts {
  type: 'NO_PRODUCTS';
}
export function noProductsAction(): NoProducts {
  return {
    type: 'NO_PRODUCTS',
  };
}

export type GlobalErrorAction =
  | AppCrashedAction
  | AuthExpired
  | NoClients
  | NoProducts
  | UserOfflineAction;
