import type {
  AccumulatorScheduleInputs,
  AccumulatorScheduleItem,
  AccumulatorScheduleValues,
  FxAccumulatorSchedules,
} from '../fxAccumulatorsModel';
import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { addKey, removeKey, updateKey, updateProperty } from 'utils/stateMap';
import { getLegKey, legsPatcher } from 'state/share/patchHelper';

const updateInput = updateProperty('inputs');

const emptyLeg: AccumulatorScheduleItem = {
  values: {
    fixingDate: null,
    amount: null,
    paymentDate: null,
    strikeDown: null,
    strikeUp: null,
    strike: null,
    leverageAmount: null,
    step: null,
  },
  inputs: {},
  errors: {},
  warnings: {},
  custom: [],
};

const legPatcher = legsPatcher<
  AccumulatorScheduleValues,
  AccumulatorScheduleInputs,
  AccumulatorScheduleItem
>(emptyLeg);

export const fxAccumulatorsScheduleReducer: Reducer<FxAccumulatorSchedules, Action> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case 'ACCUMULATOR_PROPERTIES_RECEIVED':
      return action.patch === undefined
        ? state
        : legPatcher(state, action.quoteId, action.schedule!);
    case 'ACCUMULATOR_SCHEDULE_LEG_PROPERTIES_CHANGED':
      return updateKey(
        state,
        getLegKey(action.quoteId, action.legId),
        updateInput(() => action.patch),
      );
    case 'ACCUMULATOR_SCHEDULE_LEG_ADDED':
      return addKey<AccumulatorScheduleItem>(
        state,
        getLegKey(action.quoteId, action.legId),
        emptyLeg,
      );
    case 'LOCAL_LEG_FIELD_VALIDATION_SET':
      return action.instrument !== 'Accumulator'
        ? state
        : updateKey(state, getLegKey(action.quoteId, action.legId), ({ errors, warnings }) => {
            const validation = {
              code: action.messageId,
              userNotified: false,
            };
            return action.validationStatus === 'warning'
              ? { warnings: { ...warnings, [action.field]: validation } }
              : { errors: { ...errors, [action.field]: validation } };
          });
    case 'LOCAL_LEG_FIELD_VALIDATION_CLEAR':
      return action.instrument !== 'Accumulator'
        ? state
        : updateKey(state, getLegKey(action.quoteId, action.legId), ({ errors, warnings }) => ({
            errors: removeKey(errors, action.field),
            warnings: removeKey(warnings, action.field),
          }));
    case 'LEG_FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Accumulator'
        ? state
        : updateKey(state, getLegKey(action.quoteId, action.legId), ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));
    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
      return removeSchedules(action.tileId, state);

    default:
      return state;
  }
};

function removeSchedules(tileId: string, state: FxAccumulatorSchedules) {
  const prefixScheduleId = `${tileId}/`;

  return Object.keys(state).reduce((previousState, scheduleId) => {
    if (!scheduleId.startsWith(prefixScheduleId)) {
      return previousState;
    }

    const { [scheduleId]: deletedSchedule, ...otherSchedules } = previousState;

    return otherSchedules;
  }, state);
}
