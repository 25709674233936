import type { ISgmeHttp, HttpCall } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';

export function sessionServiceApi(http: ISgmeHttp): ISessionServiceRequest {
  return {
    register(connectionId: string) {
      return http.post<void>('api/register/user', { dataStreamId: connectionId });
    },
  };
}

export interface ISessionServiceRequest {
  register(connectionId: string): Observable<HttpCall<void>>;
}
