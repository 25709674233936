import { getSwapInput } from './factories';

export const getSwapCurrencyPair = getSwapInput('currencyPair');
export const getSwapIsNonDeliverable = getSwapInput('isNonDeliverable');
export const getSwapIsUneven = getSwapInput('isUneven');
export const getSwapIsOffMarket = getSwapInput('isOffMarket');
export const getSwapNearAmount = getSwapInput('nearAmount');
export const getSwapFarAmount = getSwapInput('farAmount');
export const getSwapAmountCurrency = getSwapInput('amountCurrency');
export const getSwapNearPaymentDate = getSwapInput('nearPaymentDate');
export const getSwapNearPaymentDateTenor = getSwapInput('nearPaymentDateTenor');
export const getSwapFarPaymentDate = getSwapInput('farPaymentDate');
export const getSwapFarPaymentDateTenor = getSwapInput('farPaymentDateTenor');
export const getSwapNearPrice = getSwapInput('nearPriceReference');
export const getSwapFarPrice = getSwapInput('farPriceReference');
export const getSwapFixingSource = getSwapInput('fixingSource');
export const getSwapSndFixingSource = getSwapInput('sndFixingSource');
