export function mapOptional<T, U>(
  transformValue: (val: T) => U,
): (val: T | null | undefined) => U | null | undefined {
  return val => (val === null ? null : val === undefined ? undefined : transformValue(val));
}

export function mapOrDefault<T, U>(
  transformValue: (val: T) => U,
  defaultValue: U,
): (val: T | null | undefined) => U {
  return val => (val ? transformValue(val) : defaultValue);
}
