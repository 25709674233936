import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';

export function getGridLayoutState(state: AppState) {
  return state.gridLayout;
}

export function getGridById(state: AppState, gridId: string) {
  return throwIfUndefined(
    getGridLayoutState(state)[gridId],
    `Grid layout state for ${gridId} should exist at this stage`,
  );
}
