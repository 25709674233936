import type { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import type { DispatchWithThunkExt } from 'state';
import { logger } from '../logging/logger';

export type OnCatchActionCreator<S> = (err: Error, action: any, state: S) => Action;

export function tryCatchMiddleware<S>(onCatchActionCreator?: OnCatchActionCreator<S>) {
  return ((store: MiddlewareAPI<DispatchWithThunkExt, S>) =>
    (next: Dispatch<Action>) =>
    (action: Action) => {
      try {
        return next(action);
      } catch (error: any) {
        logger.logError('Caught an unexpected error {message_m}', error.message);
        if (onCatchActionCreator === undefined) {
          return store.getState();
        }
        return next(onCatchActionCreator(error, action, store.getState()));
      }
    }) as Middleware<DispatchWithThunkExt, S, Dispatch<AnyAction>>;
}
