import type { SgwtWebAnalyticsHTMLElement } from "@sgwt/sgwt-widgets-react";

export interface IMatomoConfig {
  piwikRootUrl: string;
  siteId: string;
}

export type Category =
  | 'Workspace'
  | 'Mifid2'
  | 'Blotter'
  | 'Cash'
  | 'Swap'
  | 'Option'
  | 'Accumulator'
  | 'TargetAccumulator'
  | 'ForwardAccumulator'
  | 'Bulk'
  | 'Order'
  | 'SmartRfs';

export type Action =
  | 'Accumulator type changed to permanent'
  | 'Change product'
  | 'Change currency pair'
  | 'Create bulk'
  | 'Import bulk'
  | 'Exclude from bulk'
  | 'Add product to bulk'
  | 'Remove product(s) from bulk'
  | 'Reset bulk'
  | 'Stream request'
  | 'Tradeable stream request'
  | 'Stream cancel'
  | 'Execution request'
  | 'Execution failure'
  | 'Execution success'
  | 'Order creation cancelled'
  | 'Order submission request'
  | 'Order submission success'
  | 'Order submission failure'
  | 'Order cancel'
  | 'Order pause'
  | 'Order resume'
  | 'Order fill now'
  | 'Order modify'
  | 'Order view'
  | 'Order click'
  | 'Add tab'
  | 'Change client'
  | 'Remove tab'
  | 'Save workspace'
  | 'Workspace lock toggled'
  | 'Switch theme'
  | 'Add tile'
  | 'Duplicate tile'
  | 'Remove tile'
  | 'Tile client changed'
  | 'Tile client reset'
  | 'Zoom tile'
  | 'Switch to horizontal leg'
  | 'Switch to vertical leg'
  | 'Group strategy legs by expiry'
  | 'Ungroup strategy legs'
  | 'Notional currency pairs link toggled'
  | 'Legs premiums pin toggled'
  | 'Solving requested in compact view'
  | 'Solving requested in strategy view'
  | 'Reduce tile'
  | 'Open preferences'
  | 'Preference changed'
  | 'Open MIFID2 panel'
  | 'Close MIFID2 panel'
  | 'Price recorded'
  | 'Open blotter'
  | 'Close blotter'
  | 'Add leg to option'
  | 'Duplicate option leg'
  | 'Remove leg from option'
  | 'Import option legs'
  | 'Import TA schedule'
  | 'Paste in option strategy'
  | 'Option stripping request'
  | 'Strike shortcut used'
  | 'Order template created'
  | 'Order template updated'
  | 'Order template applied'
  | 'Order template deleted'
  | 'Open tile from blotter'
  | 'Open cambist calendar'
  | 'Pin client'
  | 'Unpin client'
  | 'Move client up'
  | 'Move client down'
  | 'Amount ref overriden in option strategy'
  | 'Hedge forward'
  | 'Custom hedge'
  | 'Call / Put'
  | 'Straddle'
  | 'Strangle'
  | 'Risk Reversal'
  | 'Volatility has been overriden'
  | 'Calendar overridden';

let matomoWidget: SgwtWebAnalyticsHTMLElement | null = null;

export const getSgwtWidgetAnalytics = () => {
  if (matomoWidget) {
    return matomoWidget;
  }
  matomoWidget = document.querySelector('sgwt-web-analytics');
  return matomoWidget;
}


// export function setCustomVariable(
//   index: 1 | 2 | 3 | 4 | 5,
//   name: 'locale' | 'theme',
//   value: string,
//   scope: 'visit' | 'page',
// ): void {
//   window._paq.push(['setCustomVariable', index, name, value, scope]);
// }
