import { sgwtConnect } from "api/connect";

export const BUS_TOPIC_GLOBALLANGUAGE = 'global.language';
export const BUS_TOPIC_SGCONNECT_ACCESSTOKEN = 'sg-connect.access-token';
export const BUS_TOPIC_SGCONNECT_USERCONNECTION = 'sg-connect.user-connection';
export const BUS_TOPIC_SGCONNECT_USERINFO = 'sg-connect.user-info';
export const BUS_TOPIC_SGCONNECT_GRANTEDSCOPE = 'sg-connect.granted-scope';

type BusEventCallback<T> = (payload: T | undefined) => void;
type SubscriptionHandle = any;

export interface SgWidgetsBus {
  dangerouslyGetCurrentValue<T>(topicName: string): T | undefined;
  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;
  unsubscribe(handle: SubscriptionHandle): void;
}

export function getWidgetBus(): SgWidgetsBus | null {
  const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
  return widgetConfiguration && widgetConfiguration.bus ? widgetConfiguration.bus : null;
}

export function getWidget<T extends HTMLElement>(tagName: string) {
  return document.querySelector(tagName) as T;
}

export function getAuthorizationHeader(): string | null {
  return sgwtConnect.getAuthorizationHeader();
}
