import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';

export interface BlotterContentConnectOwnProps {}

export interface BlotterContentConnectStateProps {
  isReadonly: boolean;
}

export interface BlotterContentConnectDispatchProps {}

export type BlotterContentConnectSelectors = Selectors;

export const mapStateToPropsReadonly: MapStateToPropsHOF<
  BlotterContentConnectStateProps,
  BlotterContentConnectOwnProps,
  AppState,
  BlotterContentConnectSelectors
> = sl => (state, _ownProps) => ({
  isReadonly: !sl.userCanTrade(state),
});

export type BlotterContentConnectActionCreators = ActionCreators;
