import type { Reducer } from 'redux';
import type { FxCashStreamMap, FxCashStreamState } from '../fxCashsModel';
import type { Action } from 'state/actions';
import { removeKey, addKey, updateKey } from 'utils/stateMap';

export const fxCashsStreamsReducer: Reducer<FxCashStreamMap> = (
  state: FxCashStreamMap = {},
  action: Action,
): FxCashStreamMap => {
  switch (action.type) {
    case 'CASH_RFS_STARTED':
      return addKey(state, action.streamId, {
        status: 'AWAITING',
      } as FxCashStreamState);
    case 'CASH_QUOTE_RECEIVED':
      const { streamId, quote, tiering } = action;
      return updateKey(state, streamId, stream => {
        const initialRfsWindow =
          stream.status === 'PRICING' ? stream.initialRfsWindow : quote.rfsWindow;
        return {
          status: 'PRICING',
          quote: { ...quote },
          initialRfsWindow,
          tiering,
        } as FxCashStreamState;
      });
    case 'CASH_RFS_TERMINATED':
    case 'CASH_RFS_CANCEL':
    case 'CASH_RFS_FAILED':
      return removeKey(state, action.streamId);
    case 'CASH_RFS_EXECUTION_SENT':
      return removeKey(state, action.executionId);
    default:
      return state;
  }
};
