import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { ProductName } from 'state/share/productModel';

export interface IsProductNameConnectOwnProps {
  quoteId: string;
  not?: true;
  products: readonly ProductName[];
}

export interface IsProductNameConnectStateProps {
  condition: boolean;
}

export interface IsProductNameConnectDispatchProps {}

// you can whitelist selectors after implementation
export type IsProductNameConnectSelectors = Selectors;
/*
export type IsProductNameConnectSelectorsKeys = 'aSelector'; // example
export type IsProductNameConnectSelectors = Pick<Selectors, IsProductNameConnectSelectorsKeys>;
*/

export const mapStateToPropsIsProductName: MapStateToPropsHOF<
  IsProductNameConnectStateProps,
  IsProductNameConnectOwnProps,
  AppState,
  IsProductNameConnectSelectors
> =
  sl =>
  (state, { quoteId, products, not = false }) => ({
    condition: products.includes(sl.getProductName(state, quoteId)!) !== not,
  });
