import type { FxOrderState, FxOrderStateMap } from '../fxOrdersModel';
import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';

export function getOrderState(state: AppState, orderId: string): FxOrderState {
  return throwIfUndefined(
    state.fxOrders.orders[orderId],
    `Order state for ${orderId} does not exist`,
  );
}

export function getOrdersState(state: AppState): FxOrderStateMap {
  return state.fxOrders.orders;
}

export function getOrderByExecutionId(
  state: AppState,
  executionId: string,
): FxOrderState | undefined {
  return Object.values(state.fxOrders.orders).find(
    order => order?.currentExecutionId === executionId,
  );
}
