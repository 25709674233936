import type { Thunk } from "state"


export function americanForwardTileClosed(americanForwardId: string, automatically = false): Thunk<void> {
    return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
        const state = getState()

        if (!sl.isTilePresent(state, americanForwardId)) {
            return;
        }

        const tabId = sl.getClientWorkspaceIdByQuoteId(state, americanForwardId)

        dispatch(ac.americanForwardRfsCancelThunk(americanForwardId))

        const lastStreamId = sl.getAmericanForwardLastStreamId(state, americanForwardId)

        if (lastStreamId !== null) {
            dispatch(ac.americanForwardStreamLastRemoved(americanForwardId, lastStreamId))
        }

        dispatch(ac.espTileStreamUnsubscribeThunk(americanForwardId))
        dispatch(ac.clientWorkspaceTileDeleted(americanForwardId, tabId, automatically))
    }
}

export function americanForwardTileStateCleaned(americanForwardId: string): Thunk<void> {
    return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
        dispatch(ac.americanForwardRfsCancelThunk(americanForwardId));

        const lastStreamId = sl.getAmericanForwardLastStreamId(getState(), americanForwardId);

        if (lastStreamId !== null) {
            dispatch(ac.americanForwardStreamLastRemoved(americanForwardId, lastStreamId));
        }

        dispatch(ac.espTileStreamUnsubscribeThunk(americanForwardId));
    };
}

export function americanForwardClientChangedThunk(americanForwardId: string, clientId: string): Thunk<void> {
    return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
        const companyId = sl.getCompanyIdFromClientId(getState(), clientId);

        dispatch(ac.espStreamRestartThunk(americanForwardId, undefined, undefined, companyId));
        dispatch(ac.americanForwardRfsCancelThunk(americanForwardId));

        const lastStreamId = sl.getAmericanForwardLastStreamId(getState(), americanForwardId);

        if (lastStreamId !== null) {
            dispatch(ac.americanForwardStreamLastRemoved(americanForwardId, lastStreamId));
        }
    };
}