import type { Thunk } from 'state';

export function optionTileClosed(optionId: string, automatically = false): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();

    if (!sl.isTilePresent(state, optionId)) {
      return;
    }

    const tabId = sl.getClientWorkspaceIdByQuoteId(state, optionId);

    dispatch(ac.optionRfsCancelThunk(optionId));

    const lastStreamId = sl.getOptionLastStreamId(state, optionId);

    if (lastStreamId !== null) {
      dispatch(ac.optionStreamLastRemoved(optionId, lastStreamId));
    }

    dispatch(ac.espTileStreamUnsubscribeThunk(optionId));
    dispatch(ac.clientWorkspaceTileDeleted(optionId, tabId, automatically));
  };
}

export function optionTileStateCleaned(optionId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    dispatch(ac.optionRfsCancelThunk(optionId));

    const lastStreamId = sl.getOptionLastStreamId(getState(), optionId);

    if (lastStreamId !== null) {
      dispatch(ac.optionStreamLastRemoved(optionId, lastStreamId));
    }

    dispatch(ac.espTileStreamUnsubscribeThunk(optionId));
  };
}

export function optionClientChangedThunk(optionId: string, clientId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const companyId = sl.getCompanyIdFromClientId(getState(), clientId);

    dispatch(ac.espStreamRestartThunk(optionId, undefined, undefined, companyId));
    dispatch(ac.optionRfsCancelThunk(optionId));

    const lastStreamId = sl.getOptionLastStreamId(getState(), optionId);

    if (lastStreamId !== null) {
      dispatch(ac.optionStreamLastRemoved(optionId, lastStreamId));
    }
  };
}

export function optionToggleStrategyThunk(quoteId: string, isStrategy: boolean): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();

    const [firstLegId] = sl.getFxOptionMultilegOfOption(state, quoteId).legIds;
    const firstLeg = sl.getOptionLeg(state, firstLegId);

    const legId = firstLeg.productName === 'Vanilla' ? firstLegId : firstLeg.legIds[0];

    const isBuy = sl.getOptionVanillaLegSide(state, legId).value === 'Buy';

    if (!isStrategy && !isBuy) {
      dispatch(
        ac.optionLegPropertyChanged(quoteId, legId, {
          side: 'Buy',
        }),
      );
    }

    dispatch(ac.optionToggleStrategy(quoteId, isStrategy));
  };
}

export function optionUseDefaultThunk(quoteId: string, currencyPair: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const defaultPriceType = sl.getDefaultPriceType(getState());
    const defaultHedgeType = sl.getDefaultHedgeType(getState());

    dispatch(
      ac.optionPropertyChanged(quoteId, {
        hedgeType: defaultHedgeType,
        currencyPair,
        legs: {
          [`${quoteId}/0/0`]: {
            premiumCurrency: 1,
            premiumTypeString: defaultPriceType,
          },
        },
      }),
    );
  };
}
