import { FormattedMessage } from 'react-intl';
import { TradeCaptureSpinner } from 'components/share/TradeCaptureSpinner';
import { withQuoteId, withWorkspaceId } from 'components/contexts';
import { connectTileSize } from './connect';
import { TileLayout } from 'components/FxTileLayout';

const emptyTileClass = 'd-flex flex-grow-1 justify-content-center align-items-center text-center text-secondary';

export interface TileEmptyProps {
  height: number;
  width: number;
  children?: React.ReactNode;
}

const br = { br: <br /> };
export function TileEmptyRaw({ height, width, children }: TileEmptyProps) {
  return (
    <TileLayout layout="free-width" height={height} width={width}>
      {children ?? (
        <>
          <TradeCaptureSpinner />
          <div className={emptyTileClass}>
            <FormattedMessage id="tile.startpricing" tagName="p" values={br} />
          </div>
        </>
      )}
    </TileLayout>
  );
}

export const TileEmpty = withWorkspaceId(withQuoteId(connectTileSize(TileEmptyRaw)));
