import type { AppState } from 'state/model';
import { precisionAdderWithPrecision } from 'utils/number';
import type { FxCashStreamState } from '../fxCashsModel';
import { convertToRawWithPrecision } from 'utils/margin';
import type { BidAskPair } from 'state/share/productModel/litterals';
import { throwIfUndefined } from 'utils/maps';
import { getCashCurrencyPair } from './cashInput';
import {
  getCurrencyPrecision,
  isUserInternalSales,
} from 'state/referenceData/referenceDataSelectors';
import { getCashForwardMargin } from './fxCashsMarginSelectors';
import { getEspStreamState } from 'state/esp/espStreamsSelectors';
import { getEspPriceByNotional } from 'state/esp/utils';
import type { IESPTraderPriceUnderThreshold } from 'Workers/streamingTypes';
import { getCashEspSpotPriceWithMarginForExecution } from "./fxCashsSelectors";
import { computeMarkup } from './utils';
import { isDefined } from '@sgme/fp';
import { getCashState } from "./cashState";

export function getCashRfsStreamState(state: AppState, streamId: string): FxCashStreamState {
  return throwIfUndefined(
    state.fxCashs.streams[streamId],
    `Cash stream state ${streamId} does not exist`,
  );
}

export function isCashRfsStreamPresent(state: AppState, streamId: string): boolean {
  return !!state.fxCashs.streams[streamId];
}

export function getCashRfsAllInPrice(state: AppState, tileId: string): BidAskPair {
  const currencyPair = getCashCurrencyPair(state, tileId).value;
  if (currencyPair === null) {
    return { bid: 0, ask: 0 };
  }

  const { bid, ask } = getCashRfsSpotPriceWithMargin(state, tileId);

  const { bid: fwdBidPts, ask: fwdAskPts } = getCashRfsForwardPointsWithMargin(state, tileId);

  const precision = getCurrencyPrecision(state, currencyPair);
  const toRaw = convertToRawWithPrecision(precision);

  const precisionAdder = precisionAdderWithPrecision(precision + 1);

  return {
    bid: precisionAdder(bid, toRaw(fwdBidPts)),
    ask: precisionAdder(ask, toRaw(fwdAskPts)),
  };
}

const emptyBidAskProps: BidAskPair = { bid: 0, ask: 0 };

export function getCashRfsTotalMargin(state: AppState, tileId: string): BidAskPair {
  const {
    bidMargin: bidSpotMargin,
    askMargin: askSpotMargin,
    askForwardMargin,
    bidForwardMargin,
  } = getCashState(state, tileId);

  return {
    bid: (bidSpotMargin || 0) + (bidForwardMargin || 0),
    ask: (askSpotMargin || 0) + (askForwardMargin || 0),
  };
}

export function getCashSpotMarginValue(state: AppState, tileId: string) {
  const { bidMargin, askMargin } = getCashState(state, tileId);
  const currencyPair = getCashCurrencyPair(state, tileId).value;

  if (currencyPair === null) {
    return { askMargin: 0, bidMargin: 0 };
  }
  const toRaw = convertToRawWithPrecision(getCurrencyPrecision(state, currencyPair));
  return {
    bidMargin: toRaw(bidMargin),
    askMargin: toRaw(askMargin),
  };
}

export function getCashRfsSpotPriceWithMargin(state: AppState, tileId: string): BidAskPair {
  const isInternalSales = isUserInternalSales(state);
  const { currentStreamId } = getCashState(state, tileId);
  const currencyPair = getCashCurrencyPair(state, tileId).value;

  if (currentStreamId === null || currencyPair === null) {
    return emptyBidAskProps;
  }

  const cashStreamState = getCashRfsStreamState(state, currentStreamId);
  if (cashStreamState.status !== 'PRICING') {
    return emptyBidAskProps;
  }
  const {
    quote: {
      spotWithoutMargin: { bid, ask },
      spotWithMargin,
    },
  } = cashStreamState;
  const { bidMargin, askMargin } = getCashSpotMarginValue(state, tileId);

  const precisionAdder = precisionAdderWithPrecision(getCurrencyPrecision(state, currencyPair) + 1);

  return {
    bid: isInternalSales ? precisionAdder(bid, -bidMargin) : spotWithMargin.bid,
    ask: isInternalSales ? precisionAdder(ask, askMargin) : spotWithMargin.ask,
  };
}

export function getCashRfsForwardPointsWithMargin(state: AppState, tileId: string): BidAskPair {
  const isInternalSales = isUserInternalSales(state);
  const { currentStreamId } = getCashState(state, tileId);
  const currencyPair = getCashCurrencyPair(state, tileId).value;

  if (currentStreamId === null || currencyPair === null) {
    return emptyBidAskProps;
  }

  const cashStreamState = getCashRfsStreamState(state, currentStreamId);
  if (cashStreamState.status !== 'PRICING') {
    return emptyBidAskProps;
  }

  const { bid: bidMargin, ask: askMargin } = getCashForwardMargin(state, tileId);

  if (isInternalSales) {
    const {
      quote: {
        forwardPointsWithoutMargin: { bid, ask },
      },
    } = cashStreamState;

    const precisionAdder = precisionAdderWithPrecision(
      getCurrencyPrecision(state, currencyPair) + 1,
    );

    return {
      bid: precisionAdder(bid, -bidMargin),
      ask: precisionAdder(ask, askMargin),
    };
  } else {
    const {
      quote: { forwardPointsWithMargin },
    } = cashStreamState;

    return {
      bid: forwardPointsWithMargin.bid,
      ask: forwardPointsWithMargin.ask,
    };
  }
}

export function getRfsDefaultForwardMarginPoints(state: AppState, tileId: string): BidAskPair {
  const { currentStreamId } = getCashState(state, tileId);
  if (currentStreamId === null) {
    return emptyBidAskProps;
  }
  const currentStream = getCashRfsStreamState(state, currentStreamId);
  if (currentStream.status !== 'PRICING') {
    return emptyBidAskProps;
  }
  return currentStream.quote.defaultForwardMarginPoints;
}

export function getCashRfsMidAllInRate(state: AppState, quoteId: string) {
  const { currentStreamId } = getCashState(state, quoteId);
  if (currentStreamId === null) {
    return null;
  }
  const streamState = getCashRfsStreamState(state, currentStreamId);
  if (streamState.status !== 'PRICING') {
    return null;
  }
  return streamState.quote.midAllInRate;
}

export function getCashStreamStatus(state: AppState, tileId: string) {
  const { currentStreamId, currentEspStreamId } = getCashState(state, tileId);
  if (isDefined(currentStreamId)) {
    return getCashRfsStreamState(state, currentStreamId).status;
  } else if (isDefined(currentEspStreamId)) {
    return getEspStreamState(state, currentEspStreamId)!.status;
  } else {
    return 'NONE';
  }
}

export function getCashMarkup(state: AppState, tileId: string) {
  const defaultMarkup = { bidMarkup: 0, askMarkup: 0 };

  const {
    currentStreamId,
    currentEspStreamId,
    markupCurrency,
    values: { amountCurrency, amount: notional },
  } = getCashState(state, tileId);
  const currencyPair = getCashCurrencyPair(state, tileId).value;
  if (notional === null || currencyPair === null) {
    return defaultMarkup;
  }

  const userInputtedValues = {
    notional,
    amountCurrency,
    markupCurrency,
  };

  if (currentStreamId !== null) {
    const allIn = getCashRfsAllInPrice(state, tileId);
    if (allIn.bid === 0 || allIn.ask === 0) {
      return defaultMarkup;
    }
    const totalMargin = getCashRfsTotalMargin(state, tileId);

    const toRaw = convertToRawWithPrecision(getCurrencyPrecision(state, currencyPair));

    const result = computeMarkup({
      allInBid: allIn.bid,
      allInAsk: allIn.ask,
      bidTotalMargin: toRaw(totalMargin.bid),
      askTotalMargin: toRaw(totalMargin.ask),
      ...userInputtedValues,
    });
    return result;
  } else if (currentEspStreamId !== null) {
    const espStreamState = getEspStreamState(state, currentEspStreamId)!;
    if (espStreamState.status !== 'PRICING') {
      return defaultMarkup;
    }

    const { bidMargin: spotBidMargin, askMargin: spotAskMargin } = getCashSpotMarginValue(
      state,
      tileId,
    );

    const { price } = getEspPriceByNotional(espStreamState, notional, amountCurrency);

    if (price === null) {
      return defaultMarkup;
    }
    const { traderBid: bid, traderAsk: ask } = price as IESPTraderPriceUnderThreshold;
    return computeMarkup({
      allInBid: bid,
      allInAsk: ask,
      bidTotalMargin: spotBidMargin,
      askTotalMargin: spotAskMargin,
      ...userInputtedValues,
    });
  }

  return defaultMarkup;
}

export function getCashEspSpotPriceForExecution(state: AppState, tileId: string): BidAskPair {
  const { currentEspStreamId } = getCashState(state, tileId);
  if (currentEspStreamId === null) {
    return { bid: 0, ask: 0 };
  }
  const espStreamState = getEspStreamState(state, currentEspStreamId)!;
  if (espStreamState.status !== 'PRICING') {
    return { bid: 0, ask: 0 };
  }
  /**
   * @todo change selector
   */
  const {
    values: { amount, amountCurrency },
  } = getCashState(state, tileId);

  const userDefinedMargin = getCashSpotMarginValue(state, tileId);
  return getCashEspSpotPriceWithMarginForExecution(
    espStreamState,
    amount,
    amountCurrency,
    userDefinedMargin,
  );
}
