import { FormattedMessage } from 'react-intl';
import * as e2e from 'components/Form/Inputs/e2e';
import { BlotterErrorIndicator } from './LiveBlotterErrorIndicator';
import { connectQuickBlotterButton } from './connect';
import styled from 'styled-components';

const liveBlotterCollapseIcon = {
  icon: <i className="icon">expand_less</i>,
};

interface QuickBlotterButtonProps {
  isVisible: boolean;
  onShowBlotter(): void;
}

const CenteredFloatingButton = styled.button.attrs({
  type: 'button',
  className:
    'btn btn-primary-alt py-1 position-absolute text-nowrap mx-auto border border-bottom-0',
})`
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
`;

const QuickBlotterButtonRaw: React.FunctionComponent<QuickBlotterButtonProps> = ({
  isVisible,
  onShowBlotter,
}) =>
  isVisible ? (
    <CenteredFloatingButton
      onClick={onShowBlotter}
      data-no-resize
      data-e2e={e2e.button('open-blotter')}
    >
      <FormattedMessage
        id="liveBlotter.header.show"
        values={liveBlotterCollapseIcon}
        tagName="strong"
      />{' '}
      <BlotterErrorIndicator />
    </CenteredFloatingButton>
  ) : null;

export const QuickBlotterButton = connectQuickBlotterButton(QuickBlotterButtonRaw);
