import type { AppState } from 'state/model';
import type { SharedPropertyStatus } from 'state/share/form';
import { assertUnhandled } from 'utils/error';
import { type AlgoOrderType, type IFxOrderInputs, isAlgoOrder } from '../fxOrdersModel';
import {
  getOrderAlphaSeeker,
  getOrderClippingMode,
  getOrderClipSize,
  getOrderEmails,
  getOrderLiquidityPool,
  getOrderRandomize,
  getOrderSpeed,
  getOrderSpreadCapture,
  getOrderType,
} from './orderInput';
import { getOrderTemplateByOrderId } from './selectors';

export const isOrderFromTemplateEdited = (state: AppState, orderId: string) => {
  const template = getOrderTemplateByOrderId(state, orderId);
  const orderType = getOrderType(state, orderId).value;

  if (template && isAlgoOrder(orderType)) {
    const orderFields = getFieldsForTemplates(state, orderId);
    return JSON.stringify(template.fields) !== JSON.stringify(orderFields);
  }
};

export const getFieldsForTemplates = (state: AppState, orderId: string) => {
  const orderType = getOrderType(state, orderId).value;
  if (!isAlgoOrder(orderType)) {
    assertUnhandled('Unexpected order type', orderType);
  }

  return getFieldGettersByOrderType[orderType].reduce(
    (agg, [key, getter]) => ({
      ...agg,
      [key]: getter(state, orderId).value,
    }),
    {} as Record<string, unknown>,
  );
};
const getFieldGettersByOrderType: Record<
  AlgoOrderType,
  Array<
    [
      key: keyof IFxOrderInputs,
      getter: (state: AppState, orderId: string) => SharedPropertyStatus<unknown, unknown>,
    ]
  >
> = {
  Twap: [
    ['type', getOrderType],
    ['liquidityPool', getOrderLiquidityPool],
    ['clippingMode', getOrderClippingMode],
    ['clipSize', getOrderClipSize],
    ['randomize', getOrderRandomize],
    ['spreadCapture', getOrderSpreadCapture],
    ['emails', getOrderEmails],
  ],
  Nightjar: [
    ['type', getOrderType],
    ['liquidityPool', getOrderLiquidityPool],
    ['speed', getOrderSpeed],
    ['alphaSeeker', getOrderAlphaSeeker],
    ['emails', getOrderEmails],
  ],
  Falcon: [
    ['type', getOrderType],
    ['liquidityPool', getOrderLiquidityPool],
    ['speed', getOrderSpeed],
    ['emails', getOrderEmails],
  ],
};
