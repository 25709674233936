import type { AppState } from "state/model";
import type { BidAskPair, CurrencyChoice } from "state/share/productModel/litterals";
import type {
  EspStreamStreamingState,
  IESPClientPriceUnderThreshold,
  IEspStreamState,
  IESPTraderPriceUnderThreshold
} from "state/esp/espStreamsModel";
import { getEspPriceByNotional, getLadderIndexFrom } from "state/esp/utils";
import * as utils from "./utils";
import { getCashAmount } from "./cashInput";
import { getEspLimit, getTileMaxDate } from "state/referenceData/referenceDataSelectors";
import { isDefined, isEmpty } from "@sgme/fp";
import { getCashState } from "./cashState";

// duplicate from state/esp/espStreamsSelectors to avoid dependency cycle
export function getEspStreamState(
  state: AppState,
  espStreamId: string,
): IEspStreamState | undefined {
  return state.espStreams[espStreamId];
}

export function getCashTradeCaptureCurrentSessionId(
  currentState: AppState,
  cashId: string,
): string | null {
  const { currentSessionId } = getCashState(currentState, cashId);
  return currentSessionId;
}

export function getCashTradeCaptureIdVersion(
  currentState: AppState,
  cashId: string,
): number | null {
  const { tradeCaptureIdVersion } = getCashState(currentState, cashId);
  return tradeCaptureIdVersion;
}

export type StreamSource = 'ESP' | 'RFS' | 'None';

export function getCashStreamSource(state: AppState, tileId: string): StreamSource {
  const { currentStreamId, currentEspStreamId } = getCashState(state, tileId);
  if (currentStreamId) {
    return 'RFS';
  }
  if (currentEspStreamId) {
    return 'ESP';
  }
  return 'None';
}

export function isCashTileDirty(state: AppState, cashId: string) {
  const { inputs, dirtyFields } = getCashState(state, cashId);
  return Object.keys(inputs).length > 0 || !isEmpty(dirtyFields);
}

export function getAmountCurrency(state: AppState, cashId: string) {
  const {
    values: { currency1: ccy1, currency2: ccy2, amountCurrency: ccy },
  } = getCashState(state, cashId);
  return ccy === 1 ? ccy1 : ccy2;
}

export function getCashMarkupCurrency(state: AppState, tileId: string) {
  return utils.getMarkupCurrency(getCashState(state, tileId));
}

export function getCashEspSpotPriceWithMarginForExecution(
  espStreamingState: EspStreamStreamingState,
  notional: number | null,
  notionalCurrency: CurrencyChoice,
  margin: { bidMargin: number; askMargin: number },
): BidAskPair {
  const { price } = getEspPriceByNotional(espStreamingState, notional, notionalCurrency);
  if (price === null) {
    return { bid: 0, ask: 0 };
  }

  if (espStreamingState.priceType === 'ESP.TRADER.PRICE') {
    const { traderBid, traderAsk } = price as IESPTraderPriceUnderThreshold;
    return {
      bid: traderBid - margin.bidMargin,
      ask: traderAsk + margin.askMargin,
    };
  } else {
    const { clientBid, clientAsk } = price as IESPClientPriceUnderThreshold;
    return { bid: clientBid, ask: clientAsk };
  }
}

export function getLadderIndex(state: AppState, tileId: string, amount: number): number | null {
  const { currentEspStreamId } = getCashState(state, tileId);
  if (currentEspStreamId === null) {
    return null;
  }
  const espStreamState = getEspStreamState(state, currentEspStreamId)!;

  if (espStreamState.status !== 'PRICING' || espStreamState.ladders.length === 0) {
    return null;
  }

  const {
    values: { amountCurrency },
  } = getCashState(state, tileId);

  return getLadderIndexFrom(espStreamState, amount, amountCurrency);
}

export function isCashAmountInsideEspLimits(
  state: AppState,
  tileId: string,
  amount: number | null,
) {
  const amountCcy = getAmountCurrency(state, tileId);
  if (amount === null || amount === 0 || amountCcy === null) {
    return true;
  }
  const espLimit = getEspLimit(state, amountCcy);
  if (espLimit === undefined) {
    return false;
  }
  return amount <= espLimit;
}

export function getCashPriceSourceToDisplay(state: AppState, quoteId: string) {
  const { currentStreamId, currentEspStreamId } = getCashState(state, quoteId);
  if (isDefined(currentStreamId)) {
    return 'RFS';
  }
  if (isDefined(currentEspStreamId)) {
    const notional = getCashAmount(state, quoteId).value;
    const { status } = getEspStreamState(state, currentEspStreamId)!;
    if (
      status === 'PRICING' &&
      (notional === null ||
        (getLadderIndex(state, quoteId, notional) !== null &&
          isCashAmountInsideEspLimits(state, quoteId, notional)))
    ) {
      return 'ESP';
    }
  }
  return 'None';
}

export function isValidDateCash(currentState: AppState, cashId: string) {
  const state = getCashState(currentState, cashId);
  const maxDate = getTileMaxDate(currentState, cashId);
  return (
    !state.values.maturityDate ||
    maxDate === undefined ||
    new Date(state.values.maturityDate) <= maxDate
  );
}
