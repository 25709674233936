import { useCallback } from 'react';

export function useConditionalCallback<T extends any[]>(
  condition: boolean,
  callback?: (...args: T) => void,
) {
  return useCallback(
    (...args: T) => {
      if (condition === true && callback !== undefined) {
        callback(...args);
      }
    },
    [condition, callback],
  );
}
