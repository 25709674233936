import { RenderWhen } from './RenderWhen';
import {
  connectFeatureToggle,
  connectAtLeastFeatureToggle,
  connectUserHasAccess,
  connectUserHasProducts,
  connectIsProductName,
} from './connect';
import { renderPropsConnect } from '../renderPropsConnect';
import { selectors } from 'state/selectors';
import { mapStateToPropsFeatureToggle } from './connect/connectFeatureToggle';
import { withQuoteId } from 'components/contexts';

/**
 * Renders children only if the user has access to the application
 */
export const UserHasAccess = connectUserHasAccess(RenderWhen);
UserHasAccess.displayName = 'UserHasAccess';

/**
 * Renders children only all the passed feature toggles are active for the user
 */
export const FeatureToggle = connectFeatureToggle(RenderWhen);

/**
 * Renders children if at least one of the passed feature toggles is active for the user
 */
export const FeatureToggleAtLeast = connectAtLeastFeatureToggle(RenderWhen);

/**
 * Children is a render props children receiving `{ condition }` which is true if the feature toggle is active and false otherwise
 */
export const FeatureToggleRenderProp = renderPropsConnect(mapStateToPropsFeatureToggle(selectors));

/**
 * Children will render only if user has access to all the products passed
 */
export const UserHasProducts = connectUserHasProducts(RenderWhen);

/**
 * Children will be rendered only when product name is among the listed products (or not)
 */
export const IsProductName = withQuoteId(connectIsProductName(RenderWhen));
