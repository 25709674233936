import type { HttpCall } from 'utils/sgmeHttp';
import { sgwtConnect } from 'api/connect';
import { getStore, getLastActions } from 'bootstrap/store';
import { sgmeHttp } from 'bootstrap/http';
import type { Observable } from 'rxjs';
import { type IHelpRequestAdditionalParams, sendHelpRequestHOF } from './sendHelpRequest';

export type { HelpRequestConfig, IHelpRequestAdditionalParams } from './sendHelpRequest';

let _sendHelpRequest: (params: IHelpRequestAdditionalParams) => Observable<HttpCall<void>>;

export const sendHelpRequest = (additionalParams: IHelpRequestAdditionalParams) =>
  sendHelpRequestObservable(additionalParams).toPromise();

export const sendHelpRequestObservable = (additionalParams: IHelpRequestAdditionalParams) => {
  if (_sendHelpRequest === undefined) {
    _sendHelpRequest = sendHelpRequestHOF(
      sgmeHttp,
      sgwtConnect,
      getStore(),
      getLastActions,
      () => matchMedia('(prefers-reduced-motion: reduce)').matches,
    );
  }
  return _sendHelpRequest(additionalParams);
};
