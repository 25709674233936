import { isEmpty } from '@sgme/fp';
import type React from 'react';

export const crudeEmailRegex = /(?:[^\<]* \<)?([^@\s;,]+@[^\.]+\.[^\>\s;,]+)\>?/g;

export const onKeyDownWith =
  (onAddEmail: (email: string) => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        e.currentTarget.value = '';
        break;
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
      case ';':
        const userInput = e.currentTarget.value;
        if (userInput.match(crudeEmailRegex)) {
          e.preventDefault();
          onAddEmail(userInput.replace(crudeEmailRegex, '$1'));
          e.currentTarget.value = '';
        }
    }
  };

export const onPasteWith =
  (addEmails: (emails: readonly string[]) => void) =>
  (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData('text');
    let possibleMatch = crudeEmailRegex.exec(pastedText);
    const possibleEmails: string[] = [];
    while (possibleMatch !== null) {
      possibleEmails.push(possibleMatch[1]);
      possibleMatch = crudeEmailRegex.exec(pastedText);
    }
    if (isEmpty(possibleEmails)) {
      return;
    }
    e.preventDefault();
    addEmails(possibleEmails);
  };
