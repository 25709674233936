import { createSafeContext } from '@sgme/react';
import { useContext } from 'react';
import { genericContext } from './GenericContext';

export interface StrategyCommonOwnProps {
  quoteId: string;
  legId?: string;
}

//  ██████╗ ██╗   ██╗ ██████╗ ████████╗███████╗
// ██╔═══██╗██║   ██║██╔═══██╗╚══██╔══╝██╔════╝
// ██║   ██║██║   ██║██║   ██║   ██║   █████╗
// ██║▄▄ ██║██║   ██║██║   ██║   ██║   ██╔══╝
// ╚██████╔╝╚██████╔╝╚██████╔╝   ██║   ███████╗
//  ╚══▀▀═╝  ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝

export const [QuoteIdContext, QuoteIdProvider, withQuoteId] = genericContext('quoteId', '@@_MISSING_QUOTEID_PROVIDER');

// ██╗     ███████╗ ██████╗
// ██║     ██╔════╝██╔════╝
// ██║     █████╗  ██║  ███╗
// ██║     ██╔══╝  ██║   ██║
// ███████╗███████╗╚██████╔╝
// ╚══════╝╚══════╝ ╚═════╝

export const [LegIdContext, LegIdProvider, withLegId] = genericContext('legId', '@@_MISSING_LEGID_PROVIDER');

export const withQuoteAndLegIds = <P extends StrategyCommonOwnProps>(Component: React.ComponentType<P>) => {
  const WithQuoteAndLegIds: React.ComponentType<Omit<P, 'quoteId' | 'legId'>> = (props) => {
    const quoteId = useContext(QuoteIdContext);
    const legId = useContext(LegIdContext);

    return <Component {...(props as P)} quoteId={quoteId} legId={legId} />;
  };

  WithQuoteAndLegIds.displayName = 'ComponentWithQuoteAndLegIds';

  return WithQuoteAndLegIds;
};

// ██╗  ██╗███████╗██████╗  ██████╗ ███████╗
// ██║  ██║██╔════╝██╔══██╗██╔════╝ ██╔════╝
// ███████║█████╗  ██║  ██║██║  ███╗█████╗
// ██╔══██║██╔══╝  ██║  ██║██║   ██║██╔══╝
// ██║  ██║███████╗██████╔╝╚██████╔╝███████╗
// ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚══════╝

export const [HedgeIdContext, HedgeIdProvider, withHedgeId] = genericContext<'hedgeId', string | undefined>(
  'hedgeId',
  '@@_MISSING_HEDGEID_PROVIDER',
);

export const [GroupIdProvider, useGroupId] = createSafeContext<string>('groupId');

export const withQuoteAndHedgeIds = <P extends StrategyCommonOwnProps>(Component: React.ComponentType<P>) => {
  const WithQuoteAndHedgeIds: React.ComponentType<Omit<P, 'quoteId' | 'hedgeId'>> = (props) => {
    const quoteId = useContext(QuoteIdContext);
    const hedgeId = useContext(HedgeIdContext);

    return <Component {...(props as P)} quoteId={quoteId} hedgeId={hedgeId} />;
  };

  WithQuoteAndHedgeIds.displayName = 'ComponentWithQuoteAndHedgeIds';

  return WithQuoteAndHedgeIds;
};
