import type { CurrencyChoice } from 'state/share/productModel/litterals';
import type { FxCashState } from 'state/fxCashs/fxCashsModel';
import { assertUnreachable } from '@sgme/fp';

export function getMarkupCurrency({
  markupCurrency,
  values: { currency1, currency2 },
}: Pick<FxCashState, 'markupCurrency' | 'values'>) {
  return markupCurrency === 1 ? currency1 : currency2;
}

export interface MarkupComputationValues {
  amountCurrency: CurrencyChoice;
  markupCurrency: CurrencyChoice;
  notional: number;
  bidTotalMargin: number;
  askTotalMargin: number;
  allInBid: number;
  allInAsk: number;
}

interface MarkupResult {
  bidMarkup: number;
  askMarkup: number;
}

export function computeMarkup(values: MarkupComputationValues): MarkupResult {
  switch (values.amountCurrency) {
    case 1:
      return computeMarkupWithNotionalInCcy1(values);
    case 2:
      return computeMarkupWithNotionalInCcy2(values);
    default:
      assertUnreachable(values.amountCurrency, 'Amount currency is not handled');
  }
}

function computeMarkupWithNotionalInCcy1({
  markupCurrency,
  notional,
  bidTotalMargin,
  askTotalMargin,
  allInBid,
  allInAsk,
}: MarkupComputationValues): MarkupResult {
  const bidMarkupCcy2 = notional * bidTotalMargin;
  const askMarkupCcy2 = notional * askTotalMargin;
  switch (markupCurrency) {
    case 1:
      return {
        bidMarkup: Math.round(bidMarkupCcy2 / allInAsk),
        askMarkup: Math.round(askMarkupCcy2 / allInBid),
      };
    case 2:
      return {
        bidMarkup: Math.round(bidMarkupCcy2),
        askMarkup: Math.round(askMarkupCcy2),
      };
    default:
      assertUnreachable(markupCurrency, 'Markup currency is not handled');
  }
}

function computeMarkupWithNotionalInCcy2({
  markupCurrency,
  notional,
  bidTotalMargin,
  askTotalMargin,
  allInBid,
  allInAsk,
}: MarkupComputationValues): MarkupResult {
  const traderBid = allInBid - bidTotalMargin;
  const traderAsk = allInAsk + askTotalMargin;

  const conversionFactorBid = 1 / traderBid - 1 / allInBid;
  const conversionFactorAsk = 1 / allInAsk - 1 / traderAsk;

  const bidMarkupCcy1 = notional * conversionFactorBid;
  const askMarkupCcy1 = notional * conversionFactorAsk;

  switch (markupCurrency) {
    case 1:
      return {
        bidMarkup: Math.round(bidMarkupCcy1),
        askMarkup: Math.round(askMarkupCcy1),
      };
    case 2:
      return {
        bidMarkup: Math.round(bidMarkupCcy1 * allInAsk),
        askMarkup: Math.round(askMarkupCcy1 * allInBid),
      };
    default:
      assertUnreachable(markupCurrency, 'Markup currency is not handled');
  }
}
