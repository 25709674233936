import type { Action } from 'state/globalActions';
import type { FormDomain } from 'state/referenceData';
import type { Collection } from 'typings/utils';
import { updateKey } from 'utils/stateMap';
import type { IFormData } from './form';
import type { ValueKeys } from './productModel';

export const validationPartialReducer =
  <I extends FormDomain, STATE extends IFormData<unknown, unknown>>(reducerInstrument: I) =>
  (state: Collection<STATE>, action: Action): Collection<STATE> => {
    switch (action.type) {
      case 'LOCAL_FIELD_VALIDATION_SET':
        return action.instrument !== reducerInstrument
          ? state
          : updateKey(state, action.quoteId, ({ errors, warnings }) => {
              const validation = {
                code: action.messageId,
                userNotified: false,
              };
              return (
                action.validationStatus === 'warning'
                  ? { warnings: { ...warnings, [action.field]: validation } }
                  : { errors: { ...errors, [action.field]: validation } }
              ) as Partial<STATE>;
            });
      case 'LOCAL_FIELD_VALIDATION_CLEAR':
        return action.instrument !== reducerInstrument
          ? state
          : updateKey(
              state,
              action.quoteId,
              ({
                errors: { [action.field as ValueKeys<I>]: _error, ...errors },
                warnings: { [action.field as ValueKeys<I>]: _warning, ...warnings },
              }) => ({ errors, warnings } as Partial<STATE>),
            );
      default:
        return state;
    }
  };
