import { type ComponentType, lazy, type LazyExoticComponent } from 'react';
import { logger } from 'logging/logger';

/**
 * Retry loading import once on failure
 * @param factory Factory that returns the import promise
 * @returns the import promise
 */
export function retryLazy<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  return factory().catch(e => {
    logger.logError('Could not load component, retrying once {error}', e);
    return factory();
  });
}

/**
 * Wrapper on `React.lazy` which will retry the import exactly once after it has failed
 * @param factory The same factory accepted by `React.lazy`
 * @returns The exotic component returned by `React.lazy`
 */
export function lazyWithRetry<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): LazyExoticComponent<T> {
  return lazy(() => retryLazy(factory));
}
