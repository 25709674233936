import type {
  BulkTradeCapturePatch,
  TradeCaptureBulkResponseWrapper,
  BulkTradeCaptureResponseLegProperties,
  TradeCaptureBulk,
  TradeCaptureBulkPreviousValues,
  TradeCaptureBulkLegPreviousValues,
} from 'api/tradeCapture/bulk/tradeCaptureModel';
import {
  mapAmount,
  fromTcError,
  previousValue,
  fromTcWarning,
} from 'api/tradeCapture/tradeCaptureMappingHelper';
import type { BulkLineProduct, IFxBulkLegValues, IFxBulkValues } from 'state/fxBulks/fxBulksModel';
import { clearUndefined } from 'utils/clearUndefined';
import type {
  CurrencyChoice,
  PropertyErrors,
  PropertyWarnings,
} from 'state/share/productModel/litterals';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { TcErrors, TcWarnings } from 'api/tradeCapture/tradeCaptureModel';

interface WithBulkId {
  bulkId: string;
}

export type TradeCaptureFromBackendMetaSelectorsKeys =
  | 'getBulkAmountCurrency'
  | 'getBulkLegs'
  | 'getBulkExcludedLegs';
export type TradeCaptureFromBackendMetaSelectorSelectors = Pick<
  Selectors,
  TradeCaptureFromBackendMetaSelectorsKeys
>;

interface MappedResult<T> {
  values: Partial<T>;
  errors: PropertyErrors<T>;
  warnings: PropertyWarnings<T>;
}

// ---------------------------------------------------------------------------------
// Public meta selector
// ---------------------------------------------------------------------------------

export const metaSelectorTradeCaptureFromBackendWith: MapStateToMetadataHOF<
  BulkTradeCapturePatch,
  TradeCaptureBulkResponseWrapper & WithBulkId,
  AppState,
  TradeCaptureFromBackendMetaSelectorSelectors
> =
  sl =>
  (state, { changedFields, errors, warnings, idVersion, bulkId }) => {
    const { legs: legsError, ...bulkErrors } = errors || { legs: {} };
    const { legs: legsWarning, ...bulkWarnings } = warnings || { legs: {} };
    const bulkProperties = metaSelectorTradeCaptureToBulkPropertiesWith(sl)(state, {
      changes: changedFields,
      tcErrors: bulkErrors,
      tcWarnings: bulkWarnings,
    });

    const legs = Object.entries(changedFields.legs).reduce(
      (acc, [legId, leg]) => {
        acc[legId] = metaSelectorTradeCaptureToBulkLegValuesWith(sl)(state, {
          bulkId,
          changes: leg,
          tcErrors: (legsError && legsError[legId]) || {},
          tcWarnings: (legsWarning && legsWarning[legId]) || {},
        });
        return acc;
      },
      {} as {
        [legId: string]: MappedResult<IFxBulkLegValues>;
      },
    );
    const isReadyToPrice =
      changedFields.isReadyToPrice === undefined ? null : changedFields.isReadyToPrice;

    return {
      idVersion,
      ...bulkProperties,
      legs,
      isReadyToPrice,
      isPriceObsolete: changedFields.isPriceObsolete || false,
    };
  };

// ---------------------------------------------------------------------------------
// Private metaSelector
// ---------------------------------------------------------------------------------
interface PrivateMetaSelectorMetaData<TChanges, TPreviousValues> {
  changes: Partial<TChanges>;
  tcErrors: TcErrors<TPreviousValues>;
  tcWarnings: TcWarnings<TPreviousValues>;
}

const metaSelectorTradeCaptureToBulkPropertiesWith: MapStateToMetadataHOF<
  MappedResult<IFxBulkValues>,
  PrivateMetaSelectorMetaData<TradeCaptureBulk, TradeCaptureBulkPreviousValues>,
  AppState,
  TradeCaptureFromBackendMetaSelectorSelectors
> =
  _sel =>
  (_state, { changes, tcErrors }) => ({
    values: {
      ...clearUndefined({
        currencyPair: previousValue(tcErrors.currencyPair),
      }),
      ...clearUndefined({
        currencyPair: changes.currencyPair,
        amountCurrency: changes.negotiatedCurrency,
      }),
    },
    errors: {
      ...clearUndefined({
        currencyPair: fromTcError(tcErrors.currencyPair),
      }),
    },
    warnings: {},
  });

const metaSelectorTradeCaptureToBulkLegValuesWith: MapStateToMetadataHOF<
  MappedResult<IFxBulkLegValues>,
  PrivateMetaSelectorMetaData<
    BulkTradeCaptureResponseLegProperties,
    TradeCaptureBulkLegPreviousValues
  > &
    WithBulkId,
  AppState,
  TradeCaptureFromBackendMetaSelectorSelectors
> =
  sl =>
  (state, { changes, tcErrors, tcWarnings, bulkId }) => {
    let amountCurrency: CurrencyChoice;
    if (changes.negotiatedCurrency === undefined) {
      const { value: amountCurrencyState } = sl.getBulkAmountCurrency(state, bulkId);
      amountCurrency = amountCurrencyState;
    } else {
      amountCurrency = changes.negotiatedCurrency;
    }
    const amount = mapAmount(amountCurrency, changes.amount1, changes.amount2);
    const nearAmount = mapAmount(amountCurrency, changes.nearAmount1, changes.nearAmount2);
    const farAmount = mapAmount(amountCurrency, changes.farAmount1, changes.farAmount2);
    const product = changes.productName as BulkLineProduct;
    const isSwapLeg = product === 'FxSwap' || product === 'FxNdSwap';
    const values: Partial<IFxBulkLegValues> = clearUndefined({
      product,
      isUneven: changes.isUneven,
      way: changes.way,
      amount: isSwapLeg ? nearAmount : amount,
      farAmount,
      currency1: changes.currency1,
      currency2: changes.currency2,
      paymentDate: isSwapLeg ? changes.nearPaymentDate : changes.maturityDate,
      paymentDateTenor: isSwapLeg ? changes.nearPaymentDateTenor : changes.maturityDateTenor,
      farPaymentDate: changes.farPaymentDate,
      farPaymentDateTenor: changes.farPaymentDateTenor,
      isNonDeliverable: changes.isNonDeliverable,
      fixingCurrency: changes.fixingCurrency,
      fixingDate: changes.fixingDate,
      fixingSource: changes.fixingSource,
      isCrossed: changes.isCrossed,
      possibleFixingCurrencies: changes.possibleFixingCurrencies,
      possibleFixingSources: changes.possibleFixingSources,
      possibleSndFixingSources: changes.possibleSndFixingSources,
      possibleXCurrencies: changes.possibleXCurrencies,
      sndFixingSource: changes.sndFixingSource,
      xCurrency: changes.xCurrency,
    } as Partial<IFxBulkLegValues>);
    const amount1Error = isSwapLeg ? tcErrors.nearAmount1 : tcErrors.amount1;
    const amount2Error = isSwapLeg ? tcErrors.nearAmount2 : tcErrors.amount2;
    const amount1Warning = isSwapLeg ? tcWarnings.nearAmount1 : tcWarnings.amount1;
    const amount2Warning = isSwapLeg ? tcWarnings.nearAmount2 : tcWarnings.amount2;
    return {
      values,
      errors: clearUndefined({
        product: fromTcError(tcErrors.productName),
        isUneven: fromTcError(tcErrors.isUneven),
        way: fromTcError(tcErrors.way),
        amount: fromTcError(amountCurrency === 1 ? amount1Error : amount2Error),
        farAmount: fromTcError(amountCurrency === 1 ? tcErrors.farAmount1 : tcErrors.farAmount2),
        paymentDate: fromTcError(isSwapLeg ? tcErrors.nearPaymentDate : tcErrors.maturityDate),
        paymentDateTenor: fromTcError(
          isSwapLeg ? tcErrors.nearPaymentDateTenor : tcErrors.maturityDateTenor,
        ),
        farPaymentDate: fromTcError(tcErrors.farPaymentDate),
        farPaymentDateTenor: fromTcError(tcErrors.farPaymentDateTenor),
      }),
      warnings: clearUndefined({
        amount: fromTcWarning(amountCurrency === 1 ? amount1Warning : amount2Warning),
        farAmount: fromTcWarning(
          amountCurrency === 1 ? tcWarnings.farAmount1 : tcWarnings.farAmount2,
        ),
      }),
    };
  };
