import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TabIdProvider } from 'components/NavMenu';
import { connectTabList } from './connect';
import { TabItem } from './TabItem';

export interface TabListProps {
  tabIds: readonly string[];
  activeTab: string | null;
  hasClients: boolean;
}

const TabBar = styled.ul.attrs({
  className: 'overflow-hidden nav d-flex flex-nowrap',
  role: 'tablist',
})`
  transition: margin-left 0.5s;
`;

export const TabListRaw: React.FunctionComponent<TabListProps> = ({ activeTab, tabIds }) => {
  const [offset, setOffset] = useState(0);
  const [maxOffset, setMaxOffset] = useState(0);

  const tabBarRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (tabBarRef.current !== null) {
      const newMaxOffset = Math.min(
        0,
        tabBarRef.current.parentElement!.clientWidth -
          Array.from(tabBarRef.current.children).reduce(
            (p, c) => p + c.clientWidth,
            64, // magic value which works well, don't ask why
          ),
      );
      if (offset === maxOffset || offset < newMaxOffset) {
        setOffset(newMaxOffset);
      }
      setMaxOffset(newMaxOffset);
    }
  }, [maxOffset, offset, tabIds]);
  useEffect(() => {
    if (activeTab !== null) {
      setOffset(
        maxOffset === 0
          ? 0
          : (maxOffset * tabIds.indexOf(activeTab)) / Math.max(1, tabIds.length - 1),
      );
    }
  }, [activeTab, maxOffset, tabIds]);

  const onWheel = useCallback(
    (e: React.WheelEvent<HTMLElement>) => {
      // Firefox will have deltaMode = DOM_DELTA_LINE (1)
      const delta = e.deltaMode === WheelEvent.DOM_DELTA_PIXEL ? e.deltaY / 2 : e.deltaY * 20;
      setOffset(Math.min(0, Math.max(maxOffset, offset - delta)));
    },
    [offset, maxOffset],
  );
  return (
    <TabBar style={{ marginLeft: offset }} ref={tabBarRef} onWheel={onWheel}>
      {tabIds.map(tabId => (
        <TabIdProvider value={tabId} key={tabId}>
          <TabItem showClose={tabIds.length > 1} />
        </TabIdProvider>
      ))}
    </TabBar>
  );
};
TabListRaw.displayName = 'TabList';

export const TabList = connectTabList(TabListRaw);
