import type { AppState } from 'state/model';
import {
  EVENT_AUTH_EXPIRED,
  EVENT_AUTH_DISCARDED,
  EVENT_AUTH_RENEW_ERROR,
  EVENT_AUTH_RENEW_SUCCESS,
} from '@sgwt/connect-core';
import { authExpiredAction } from 'state/globalError';
import type { EventEmitter } from 'events';
import type { Store } from 'redux';
import { logAction } from 'state/globalActions';

export function handleSgConnectEvents(
  sgwtConnectEventEmitter: EventEmitter,
  store: Store<AppState>,
  notifyWebWorker: () => void,
) {
  if (sgwtConnectEventEmitter.on) {
    sgwtConnectEventEmitter.on(EVENT_AUTH_DISCARDED, () => {
      store.dispatch(logAction('Token is no longer available on the client side.'));
      store.dispatch(authExpiredAction());
    });

    sgwtConnectEventEmitter.on(EVENT_AUTH_EXPIRED, () => {
      store.dispatch(logAction('Token is no longer valid.'));
      store.dispatch(authExpiredAction());
    });

    sgwtConnectEventEmitter.on(EVENT_AUTH_RENEW_ERROR, (error: any) => {
      store.dispatch(logAction(`Failed to renew the token due to ${error}`));
    });

    sgwtConnectEventEmitter.on(EVENT_AUTH_RENEW_SUCCESS, () => {
      store.dispatch(logAction('A fresh token has been acquired.'));
      notifyWebWorker();
    });
  }
}
