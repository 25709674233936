import { getAccumulatorInput, getTargetAccumulatorInput } from './factories';
import { createSelector } from 'reselect';
import type { AppState } from '../../model';
import { fieldData } from '../../../utils/fieldSelectors';
import {
  getTargetAccumulatorScheduleEkiDown,
  getTargetAccumulatorScheduleEkiUp,
  getTargetAccumulatorScheduleStrikeDown,
  getTargetAccumulatorScheduleStrikeUp,
} from './accumulatorSchedule';
import type { SharedPropertyStatus } from 'state/share/form';

export const getAccumulatorCurrencyPairInput = getAccumulatorInput('currencyPair');
export const getAccumulatorCurrencyPair = (quoteId: string) =>
  createSelector([(state: AppState) => getAccumulatorCurrencyPairInput(state, quoteId)], currencyPair =>
    fieldData(currencyPair).data?.toString(),
  );
export const getAccumulatorPriceType = getAccumulatorInput('priceType');
export const getAccumulatorPriceCurrency = getAccumulatorInput('priceCurrency');
export const getAccumulatorPremiumDate = getAccumulatorInput('premiumDate');
export const getAccumulatorPremiumDateTenor = getAccumulatorInput('premiumDateTenor');
export const getAccumulatorHedgeCurrency = getAccumulatorInput('hedgeCurrency');
export const getAccumulatorHedgeAmount = getAccumulatorInput('hedgeAmount');
export const getAccumulatorHedgeType = getAccumulatorInput('hedgeType');
export const getAccumulatorHedgePrice = getAccumulatorInput('hedgePrice');
export const getAccumulatorWay = getAccumulatorInput('way');

export const getAccumulatorAmount = getAccumulatorInput('amount');

export const getAccumulatorAmountCurrency = getAccumulatorInput('amountCurrency');

export const getAccumulatorAmountSplitType = getAccumulatorInput('amountSplitType');

export const getAccumulatorTargetProfitType = getAccumulatorInput('targetProfitType');

export const getAccumulatorLeverage = getAccumulatorInput('leverage');

export const getAccumulatorLeverageAmount = getAccumulatorInput('leverageAmount');

export const getAccumulatorStrike = getAccumulatorInput('strike');

export const getAccumulatorStep = getAccumulatorInput('step');

export const getAccumulatorAkoTrigger = getAccumulatorInput('akoTrigger');

export const getAccumulatorEkiTrigger = getAccumulatorInput('ekiTrigger');

export const getAccumulatorTarget = getAccumulatorInput('target');

export const getAccumulatorFirstFixingDate = getAccumulatorInput('firstFixingDate');

export const getAccumulatorFirstFixingTenor = getAccumulatorInput('firstFixingDateTenor');

export const getAccumulatorExpiryDate = getAccumulatorInput('expiryDate');

export const getAccumulatorExpiryTenor = getAccumulatorInput('expiryTenor');

export const getAccumulatorFixingFrequency = getAccumulatorInput('fixingFrequency');

export const getAccumulatorNumberOfFixings = getAccumulatorInput('numberOfFixings');

export const getAccumulatorSettlementFrequency = getAccumulatorInput('settlementFrequency');

export const getAccumulatorFixingReference1 = getAccumulatorInput('fixingReference1');

export const getAccumulatorFixingReference2 = getAccumulatorInput('fixingReference2');

export const getAccumulatorMarkupCurrency = getAccumulatorInput('markupCurrency');

export const getAccumulatorIsCrossed = getAccumulatorInput('isCrossed');

export const getAccumulatorCrossCurrency = getAccumulatorInput('crossCurrency');

export const getAccumulatorSettlementMode = getAccumulatorInput('settlementMode');

export const getAccumulatorCashSettlementCurrency = getAccumulatorInput('cashSettlementCurrency');

export const getTargetAccumulatorEki = getTargetAccumulatorInput('eki');
export const getAccumulatorEkiDown = getAccumulatorInput('ekiDown');
export const getAccumulatorEkiUp = getAccumulatorInput('ekiUp');

export const getAccumulatorStrikeDown = getAccumulatorInput('strikeDown');
export const getAccumulatorPivot = getAccumulatorInput('pivot');
export const getAccumulatorStrikeUp = getAccumulatorInput('strikeUp');

export const getTargetAccumulatorStrikeUpValue = (quoteId: string, fixingId: string, isSchedule: boolean) =>
  createSelector(
    [
      (state: AppState) => {
        if (isSchedule) {
          return getTargetAccumulatorScheduleStrikeUp(state, quoteId, fixingId);
        }
        return getAccumulatorStrikeUp(state, quoteId);
      },
    ],
    strikeUp =>
      fieldData(
        strikeUp as SharedPropertyStatus<number | null | undefined, string | null | undefined>,
      ).data?.toString(),
  );

export const getTargetAccumulatorStrikeDownValue = (quoteId: string, fixingId: string, isSchedule: boolean) =>
  createSelector(
    [
      (state: AppState) => {
        if (isSchedule) {
          return getTargetAccumulatorScheduleStrikeDown(state, quoteId, fixingId);
        }
        return getAccumulatorStrikeDown(state, quoteId);
      },
    ],
    strikeDown =>
      fieldData(
        strikeDown as SharedPropertyStatus<number | null | undefined, string | null | undefined>,
      ).data?.toString(),
  );
export const getTargetAccumulatorEkiUpValue = (quoteId: string, fixingId: string, isSchedule: boolean) =>
  createSelector(
    [
      (state: AppState) => {
        if (isSchedule) {
          return getTargetAccumulatorScheduleEkiUp(state, quoteId, fixingId);
        }
        return getAccumulatorEkiUp(state, quoteId);
      },
    ],
    EkiUp =>
      fieldData(EkiUp as SharedPropertyStatus<number | null | undefined, string | null | undefined>).data?.toString(),
  );

export const getTargetAccumulatorEkiDownValue = (quoteId: string, fixingId: string, isSchedule: boolean) =>
  createSelector(
    [
      (state: AppState) => {
        if (isSchedule) {
          return getTargetAccumulatorScheduleEkiDown(state, quoteId, fixingId);
        }
        return getAccumulatorEkiDown(state, quoteId);
      },
    ],
    EkiDown =>
      fieldData(EkiDown as SharedPropertyStatus<number | null | undefined, string | null | undefined>).data?.toString(),
  );
