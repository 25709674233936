import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import { getClientName } from 'state/share/clientSelectors';

export interface TabItemConnectOwnProps {
  tabId: string;
}

export interface TabItemConnectStateProps {
  active: boolean;
  clientName: string;
  primeBroker: string | null;
}

export interface TabItemConnectDispatchProps {
  selectTab(): void;
}

export type TabItemConnectSelectorsKeys = 'getClientWorkspaceActiveTab';
export type TabItemConnectSelectors = Pick<Selectors, TabItemConnectSelectorsKeys>;

export const mapStateToPropsTabItem: MapStateToPropsHOF<
  TabItemConnectStateProps,
  TabItemConnectOwnProps,
  AppState,
  Selectors
> =
  sl =>
  (state, { tabId }) => {
    const clientId = sl.getClientWorkspaceTab(state, tabId).clientId;
    const client = sl.getClient(state, clientId);
    return {
      clientName: getClientName(state, client),
      primeBroker: client.primeBroker,
      active: sl.getClientWorkspaceActiveTab(state) === tabId,
    };
  };

export type TabItemConnectActionCreatorsKeys = 'clientWorkspaceTabSwitched';
export type TabItemConnectActionCreators = Pick<ActionCreators, TabItemConnectActionCreatorsKeys>;

export const mapDispatchToPropsTabItem: MapDispatchToPropsHOF<
  TabItemConnectDispatchProps,
  TabItemConnectOwnProps,
  TabItemConnectActionCreators
> =
  ac =>
  (dispatch, { tabId }) => ({
    selectTab: () => dispatch(ac.clientWorkspaceTabSwitched(tabId)),
  });
