import type { CurrencyPair } from 'state/referenceData/referenceDataModel';

export function ccyPairExtendedMessageValues(ccyPairExtended?: CurrencyPair) {
  return ccyPairExtended !== undefined
    ? {
        ccy1: ccyPairExtended.ccy1,
        ccy2: ccyPairExtended.ccy2,
      }
    : undefined;
}
