import type { Thunk } from 'state';
import { type BlotterMode, datesByDateRange, type DateRange } from '../blotterModel';
import { isUserInternalSales } from 'state/referenceData/referenceDataSelectors';
import { isEmpty } from '@sgme/fp';

export function blotterChangeMode(mode: BlotterMode): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    if (mode === 'intraday') {
      dispatch(ac.blotterUpdateModeMetadata({ mode }));
    } else {
      const state = getState();
      if (isUserInternalSales(state)) {
        return;
      }

      const allHistorical = sl.getBlotterAllHistorical(state);
      if (!isEmpty(Object.keys(allHistorical)) || sl.getBlotterFetching(state) === 'historical') {
        const range = sl.getBlotterHistoricalDateRange(state);
        let patch;
        if (range === 'customRange') {
          patch = {
            mode,
            historical: {
              dateRange: range,
              startDate: sl.getBlotterHistoricalStartDate(state),
              endDate: sl.getBlotterHistoricalEndDate(state),
              error: sl.getBlotterHistoricalError(state),
            },
          };
        } else {
          patch = {
            mode,
            historical: {
              dateRange: range,
              startDate: datesByDateRange[range][0],
              endDate: datesByDateRange[range][1],
              error: sl.getBlotterHistoricalError(state),
            },
          };
        }

        dispatch(ac.blotterUpdateModeMetadata(patch));
      } else {
        const defaultDateRange = 'yesterday' as const;
        dispatch(ac.blotterChangeDateRange(defaultDateRange));
      }
    }
  };
}

export function blotterChangeDateRange(dateRange: DateRange): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    if (dateRange === 'customRange') {
      const state = getState();
      const wasCustom = sl.getBlotterHistoricalDateRange(state) === 'customRange';
      const startDate = wasCustom ? sl.getBlotterHistoricalStartDate(state) : null;
      const endDate = wasCustom ? sl.getBlotterHistoricalEndDate(state) : null;
      dispatch(
        ac.blotterUpdateModeMetadata({
          mode: 'historical',
          historical: {
            dateRange,
            startDate,
            endDate,
            error: null,
          },
        }),
      );
      return;
    } else {
      const [startDate, endDate] = datesByDateRange[dateRange];
      dispatch(
        ac.blotterUpdateModeMetadata({
          mode: 'historical',
          historical: {
            dateRange,
            startDate,
            endDate,
            error: null,
          },
        }),
      );
      dispatch(ac.blotterRetrieveHistoricalTradesIfChanged(startDate, endDate));
    }
  };
}

export function blotterChangeCustomDateRange(
  startDate: Date | null,
  endDate: Date | null,
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(
      ac.blotterUpdateModeMetadata({
        mode: 'historical',
        historical: {
          dateRange: 'customRange',
          startDate,
          endDate,
          error: null,
        },
      }),
    );
    dispatch(ac.blotterRetrieveHistoricalTradesIfChanged(startDate, endDate));
  };
}

export function blotterRetrieveHistoricalTradesIfChanged(
  startDate: Date | null,
  endDate: Date | null,
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    if (startDate === null || endDate === null) {
      dispatch(ac.blotterHistoricalTradesReceived([]));
      return;
    }
    dispatch(ac.blotterRetrieveHistoricalTrades());
  };
}
