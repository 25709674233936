import type { Thunk } from 'state';
import { clearUndefined } from 'utils/clearUndefined';
import type { IFxSwapInputs } from '../fxSwapsModel';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import { fieldData } from 'utils/fieldSelectors';

export function swapNearAmountChangedThunk(swapId: string, nearAmount: string): Thunk<void> {
  return (dispatch, getState, { selectors: { getSwapState }, actionCreators: ac }) => {
    const {
      values: { amountCurrency },
    } = getSwapState(getState(), swapId);
    dispatch(ac.swapPropertyChanged(swapId, { nearAmount, amountCurrency }));
  };
}
export function swapFarAmountChangedThunk(swapId: string, farAmount: string): Thunk<void> {
  return (dispatch, getState, { selectors: { getSwapState }, actionCreators: ac }) => {
    const {
      values: { amountCurrency },
    } = getSwapState(getState(), swapId);
    dispatch(ac.swapPropertyChanged(swapId, { farAmount, amountCurrency }));
  };
}

export function swapFarAmountCurrencyChangedThunk(swapId: string, farAmount: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { data: oldAmountCurrency } = fieldData(sl.getSwapAmountCurrency(state, swapId));
    const { data: nearAmount } = fieldData(sl.getSwapNearAmount(state, swapId));
    const { data: oldFarAmount } = fieldData(sl.getSwapFarAmount(state, swapId));
    const { data: isUneven } = fieldData(sl.getSwapIsUneven(state, swapId));
    const amountCurrency: CurrencyChoice = oldAmountCurrency === 1 ? 2 : 1;
    if (nearAmount === null && oldFarAmount === null && farAmount === '') {
      dispatch(ac.swapAmountCurrencyMaskChanged(swapId, amountCurrency));
    } else {
      const patch: Partial<IFxSwapInputs> = {
        amountCurrency,
        ...clearUndefined({
          nearAmount:
            isUneven === false
              ? undefined
              : nearAmount === null
              ? undefined
              : nearAmount.toString(),
          farAmount: farAmount === '' ? (oldFarAmount === null ? undefined : null) : farAmount,
        }),
      };
      dispatch(ac.swapPropertyChanged(swapId, patch));
    }
  };
}
export function swapNearAmountCurrencyChangedThunk(
  swapId: string,
  nearAmount: string,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { data: oldAmountCurrency } = fieldData(sl.getSwapAmountCurrency(state, swapId));
    const { data: oldNearAmount } = fieldData(sl.getSwapNearAmount(state, swapId));
    const { data: isUneven } = fieldData(sl.getSwapIsUneven(state, swapId));
    const { data: farAmount } = fieldData(sl.getSwapFarAmount(state, swapId));
    const amountCurrency: CurrencyChoice = oldAmountCurrency === 1 ? 2 : 1;
    if (oldNearAmount === null && farAmount === null && nearAmount === '') {
      dispatch(ac.swapAmountCurrencyMaskChanged(swapId, amountCurrency));
    } else {
      const patch: Partial<IFxSwapInputs> = {
        amountCurrency,
        ...clearUndefined({
          nearAmount: nearAmount === '' ? (oldNearAmount === null ? undefined : null) : nearAmount,
          farAmount:
            isUneven === false ? undefined : farAmount === null ? undefined : farAmount.toString(),
        }),
      };
      dispatch(ac.swapPropertyChanged(swapId, patch));
    }
  };
}

export function swapCurrencyPairChangeThunk(swapId: string, currencyPair: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const defaultNotional = sl.getUserDefaultNotional(state);
    const values: Partial<IFxSwapInputs> = sl.isTileFirstCurrencyPairSelection(state, swapId)
      ? {
          currencyPair,
          nearAmount: defaultNotional,
          farAmount: defaultNotional,
          amountCurrency: 1,
          nearPriceReference: null,
          farPriceReference: null,
          isOffMarket: false,
        }
      : {
          currencyPair,
          nearPriceReference: null,
          farPriceReference: null,
          isOffMarket: false,
        };
    dispatch(ac.swapPropertyChanged(swapId, values));
  };
}
