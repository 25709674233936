import type { Reducer } from 'redux';
import type { Mifid2PanelActions } from '../mifidPanelActions';

export const mifid2StartingNegotiationReducer: Reducer<boolean, Mifid2PanelActions> = (
  state = false,
  action,
) => {
  switch (action.type) {
    case 'MIFID2_START_NEW_NEGOTIATION_EPIC':
      return true;
    case 'MIFID2_START_NEW_NEGOTIATION':
    case 'MIFID2_START_NEW_NEGOTIATION_FAILED':
      return false;
    default:
      return state;
  }
};
