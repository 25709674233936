import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import { mapStateToPropsTabList } from './connectTabList';
import { mapStateToPropsTabItem, mapDispatchToPropsTabItem } from './connectTabItem';
import { mapStateToPropsTabName, mapDispatchToPropsTabName } from './connectTabName';
import { mapDispatchToPropsCloseTabButton } from './connectCloseTabButton';

export const connectTabList = connect(mapStateToPropsTabList(selectors));

export const connectTabItem = connect(
  mapStateToPropsTabItem(selectors),
  mapDispatchToPropsTabItem(actionCreators),
);

export const connectTabName = connect(
  mapStateToPropsTabName(selectors),
  mapDispatchToPropsTabName(actionCreators),
);

export const connectCloseTabButton = connect(
  null,
  mapDispatchToPropsCloseTabButton(actionCreators),
);
