import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { type FeatureToggles, featureTogglesKeys } from 'state/referenceData/referenceDataModel';
import type { RenderWhenProps } from '../RenderWhen';

type FeatureToggleConnectOwnProps = Partial<FeatureToggles>;

type FeatureToggleConnectStateProps = RenderWhenProps;

export type FeatureToggleConnectSelectorsKeys = 'getFeatureToggles';
export type FeatureToggleConnectSelectors = Pick<Selectors, FeatureToggleConnectSelectorsKeys>;

export const mapStateToPropsFeatureToggle: MapStateToPropsHOF<
  FeatureToggleConnectStateProps,
  FeatureToggleConnectOwnProps,
  AppState,
  FeatureToggleConnectSelectors
> = sl => (state, options) => {
  const toggles = sl.getFeatureToggles(state);
  let toggle: keyof FeatureToggles;
  for (toggle of featureTogglesKeys) {
    if (options[toggle] !== undefined && options[toggle] !== toggles[toggle]) {
      return { condition: false };
    }
  }
  return { condition: true };
};

export const mapStateToPropsAtLeastFeatureToggle: MapStateToPropsHOF<
  FeatureToggleConnectStateProps,
  FeatureToggleConnectOwnProps,
  AppState,
  FeatureToggleConnectSelectors
> = sl => (state, options) => {
  const toggles = sl.getFeatureToggles(state);
  let toggle: keyof FeatureToggles;
  for (toggle of featureTogglesKeys) {
    if (options[toggle] !== undefined && options[toggle] === toggles[toggle]) {
      return { condition: true };
    }
  }
  return { condition: false };
};
