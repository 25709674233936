import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { DateRange } from 'state/blotter/blotterModel';
import type { DateInputCultureInfo } from 'state/userPreferences/userPreferencesModel';

export interface HistoricalDateRangeConnectOwnProps {}

export interface HistoricalDateRangeConnectStateProps {
  selectedRange: DateRange;
  startDate: Date | null;
  endDate: Date | null;
  cultureInfo: DateInputCultureInfo;
}

export interface HistoricalDateRangeConnectDispatchProps {
  setCustomDateRange(startDate: Date | null, endDate: Date | null): void;
}

export type HistoricalDateRangeConnectSelectorsKeys =
  | 'getBlotterHistoricalDateRange'
  | 'getBlotterHistoricalStartDate'
  | 'getBlotterHistoricalEndDate'
  | 'getUserPreferenceData';
export type HistoricalDateRangeConnectSelectors = Pick<
  Selectors,
  HistoricalDateRangeConnectSelectorsKeys
>;

export const mapStateToPropsHistoricalDateRange: MapStateToPropsHOF<
  HistoricalDateRangeConnectStateProps,
  HistoricalDateRangeConnectOwnProps,
  AppState,
  HistoricalDateRangeConnectSelectors
> = sl => (state, _ownProps) => ({
  selectedRange: sl.getBlotterHistoricalDateRange(state),
  startDate: sl.getBlotterHistoricalStartDate(state),
  endDate: sl.getBlotterHistoricalEndDate(state),
  cultureInfo: sl.getUserPreferenceData(state).dateInputCultureInfo,
});

// you can whitelist action creators after implementation
export type HistoricalDateRangeConnectActionCreators = ActionCreators;
/*
export type HistoricalDateRangeConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type HistoricalDateRangeConnectActionCreators = Pick<ActionCreators, HistoricalDateRangeConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsHistoricalDateRange: MapDispatchToPropsHOF<
  HistoricalDateRangeConnectDispatchProps,
  HistoricalDateRangeConnectOwnProps,
  HistoricalDateRangeConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  setCustomDateRange(startDate, endDate) {
    dispatch(ac.blotterChangeCustomDateRange(startDate, endDate));
  },
});
