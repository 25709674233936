import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import type { Collection } from 'typings/utils';
import { addKey, removeKey, updateKey } from 'utils/stateMap';
import type { OrderTemplate } from '../fxOrdersModel';

export const orderTemplatesReducer: Reducer<Collection<OrderTemplate>, Action> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case 'SAVED_ORDER_TEMPLATES_RECEIVED':
      return action.templates;
    case 'ORDER_TEMPLATE_SAVED':
      return addKey(state, action.template.id, action.template);
    case 'ORDER_TEMPLATE_UPDATED':
      return updateKey(state, action.id, _ => ({ fields: action.fields }));
    case 'ORDER_TEMPLATE_DELETED':
      return removeKey(state, action.templateId);
  }
  return state;
};
