import type { OrderValidationDetail } from 'api/order/oms';
import type { Thunk } from 'state';
import type { OrderValidationReceivedDetails } from '../fxOrdersActions';

export function orderValidationReceivedThunk(
  orderId: string,
  details: OrderValidationReceivedDetails<OrderValidationDetail>,
  clockOffset?: number,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const oldState = getState();

    dispatch(ac.orderValidationReceived(orderId, details, clockOffset));

    const state = getState();

    //todo 5010 check if this logic needs to be repplicated in edition mode
    if (
      sl.getOrderType(state, orderId).value === 'Fixing' &&
      ((sl.getOrderFixingBenchmark(oldState, orderId).value == null &&
        sl.getOrderFixingBenchmark(state, orderId).value != null) ||
        (sl.getOrderFixingPriceType(oldState, orderId).value == null &&
          sl.getOrderFixingPriceType(state, orderId).value != null))
    ) {
      dispatch(ac.orderPropertyChanged(orderId, {}));
    }
  };
}
