import type { Reducer } from 'redux';
import type { IWorkspaceGrowls, GrowlData } from '../clientWorkspaceModel';
import type { Action } from 'state/actions';

export const clientWorkspaceGrowlsReducer: Reducer<IWorkspaceGrowls> = (
  growls: IWorkspaceGrowls = [],
  action: Action,
): IWorkspaceGrowls => {
  switch (action.type) {
    case 'GROWL_CLOSED':
      return growls.filter(({ id }) => id !== action.growlId);
    case 'GROWL_OPEN': {
      const newGrowl: GrowlData = {
        id: action.growlData.id,
        instrument: action.growlData.instrument,
      };
      return [newGrowl, ...growls];
    }
    default:
      return growls;
  }
};
