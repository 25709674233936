import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type {
  OrderPropertyChanged,
  OrderPropertyChangedFromTemplate,
  OrderSubmission,
} from 'state/fxOrders/fxOrdersActions';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { fieldData } from 'utils/fieldSelectors';
import type { OmsOrderSubmissionPayload, OrderRequestPayload } from 'api/order/oms';
import { clearUndefined } from 'utils/clearUndefined';
import type { IFxOrderInputs, OrderType } from 'state/fxOrders/fxOrdersModel';
import { dateAndTimeToUtc, getUtcExpiry } from './utils';
import { assertUnreachable, isEmpty } from '@sgme/fp';
import { getFullDateForOMS, toUtc } from 'utils/dateFormats';
import { parseWithCultureInfo } from 'utils/parseDateWithCultureInfo';
import { strictKeys } from 'utils/object';

export type OrderMetaSelectorsKeys =
  | 'getOrderType'
  | 'getOrderAmount'
  | 'getOrderAmountCurrency'
  | 'getClientIdByQuoteId'
  | 'getUserInfo'
  | 'getOrderCurrencyPair'
  | 'getOrderWay'
  | 'getOrderIsGtc'
  | 'getOrderExpiryDay'
  | 'getOrderExpiryTime'
  | 'getOrderLimitPrice'
  | 'getOrderTrigger'
  | 'getOrderEmails'
  | 'getOrderEmailsWithSplit'
  | 'getOrderCustomerPrice'
  | 'getUserInfoBdrId'
  | 'isUserInternalSales'
  | 'getOrderMaturityDate'
  | 'getOrderIsStartDateNow'
  | 'getOrderStartDate'
  | 'getOrderStartTime'
  | 'getOrderClippingMode'
  | 'getOrderClipSize'
  | 'getOrderLiquidityPool'
  | 'getOrderRandomize'
  | 'getOrderSpreadCapture'
  | 'getOrderSpeed'
  | 'getOrderAlphaSeeker'
  | 'getUserPreferenceData'
  | 'getOrderFixingBenchmark'
  | 'getOrderFixingPriceType'
  | 'getOrderFixingDateUtc'
  | 'getOrderFixingTime'
  | 'getOrderFixingPlace'
  | 'getOrderMarginInBps'
  | 'getOrderMargin'
  | 'getOrderFixingMarginType'
  | 'getOrderClockOffset'
  | 'getCurrencyPairDetails'
  | 'getFeatureToggles';

export type OrderMetaSelectors = Pick<Selectors, OrderMetaSelectorsKeys>;

// ---------------------------------------------------------------------------------
// Public meta selector
// ---------------------------------------------------------------------------------

type Action = OrderPropertyChanged | OrderPropertyChangedFromTemplate | OrderSubmission;

const keysWhichShouldMakeFieldReset: ReadonlyArray<keyof IFxOrderInputs> = ['currencyPair', 'way', 'type'];

const shouldResetField = (action: Action, ignoreResetPredicate?: (action: Action) => boolean) => {
  if (action.type === 'ORDER_SUBMISSION_EPIC' || (ignoreResetPredicate && ignoreResetPredicate(action))) {
    return false;
  }

  const patchedKeys = strictKeys(action.patch);
  return patchedKeys.some(patchedKey => keysWhichShouldMakeFieldReset.includes(patchedKey));
};

const computeTakeProfitCustomerPrice = (action: Action, customerPrice: string | undefined | null) => {
  if (shouldResetField(action, ignorePriceResetPredicate('customerPrice'))) {
    return undefined;
  }

  return customerPrice === '' ? undefined : customerPrice ?? undefined;
};

const ignorePriceResetPredicate = (field: keyof IFxOrderInputs) => (action: Action) => {
  if (action.type === 'ORDER_SUBMISSION_EPIC') {
    return false;
  }

  // this is needed when an order is created from quick order button for example, where we have several fields in the same patch to create the order, we do not want any of them to be reset
  return Object.keys(action.patch).includes(field) && Object.keys(action.patch).includes('type');
};

const computeTakeProfitLimitPrice = (
  action: Action,
  limitPrice: string | undefined | null,
) => {

  if (shouldResetField(action, ignorePriceResetPredicate('limitPrice'))) {
    return undefined;
  }

  if (action.type !== 'ORDER_SUBMISSION_EPIC') {
    const hasCustomerPriceInputChanged = action.patch.customerPrice && action.patch.customerPrice !== '';

    if (hasCustomerPriceInputChanged) {
      return undefined;
    }

    return limitPrice ?? undefined;
  }

  return limitPrice ?? undefined;
};

export const metaSelectorOrderToBackend: MapStateToMetadataHOF<
  OrderRequestPayload,
  { action: Action; getNewGuid: () => string; dateNow?: Date },
  AppState,
  OrderMetaSelectors
> =
  sl =>
    (state, { action, getNewGuid, dateNow }) => {
      const makeOrderTypeToBackendPayload = makeOrderTypeToBackendPayloadWith(sl, action);
      const { quoteId } = action;
      const orderTypeToBackendPayload = makeOrderTypeToBackendPayload(state, quoteId);
      const orderType = fieldData(sl.getOrderType(state, quoteId)).data;
      const amount = fieldData(sl.getOrderAmount(state, quoteId)).data;
      const amountCurrency = fieldData(sl.getOrderAmountCurrency(state, quoteId)).data;
      const isGtc = fieldData(sl.getOrderIsGtc(state, quoteId)).data;
      const { splitNotificationsEmailsOrders, splitNotifications } = sl.getUserPreferenceData(state);
      let emailNotificationList;
      if (splitNotifications && !isEmpty(splitNotificationsEmailsOrders)) {
        emailNotificationList = fieldData(sl.getOrderEmailsWithSplit(state, quoteId)).data;
      } else {
        emailNotificationList = fieldData(sl.getOrderEmails(state, quoteId)).data;
      }

      const orderClockOffset = sl.getOrderClockOffset(state, quoteId);

      const payload: Partial<OmsOrderSubmissionPayload> = {
        isGtc,
        bdrId: sl.getClientIdByQuoteId(state, quoteId) ?? undefined,
        amountInCcy1: amountCurrency === 1 && amount !== null ? Number(amount) : undefined,
        amountInCcy2: amountCurrency === 2 && amount !== null ? Number(amount) : undefined,
        ccyPair: fieldData(sl.getOrderCurrencyPair(state, quoteId)).data ?? undefined,
        way: fieldData(sl.getOrderWay(state, quoteId)).data === 'Buy' ? 'buy' : 'sell',
        limitPrice: shouldResetField(action)
          ? undefined
          : fieldData(sl.getOrderLimitPrice(state, quoteId)).data ?? undefined,
        base64Emails: emailNotificationList != null ? btoa(emailNotificationList) : undefined,
        ...orderTypeToBackendPayload(orderType),
        localTimeWithOffSet:
          orderClockOffset !== undefined && dateNow !== undefined ? dateNow.getTime() + orderClockOffset : 0,
      };
      return {
        orderType,
        payload: clearUndefined(payload),
        correlationId: `${quoteId}/${getNewGuid()}`,
      };
    };

function isNdf(state: AppState, sl: OrderMetaSelectors, quoteId: string): boolean {
  const currencyPair = fieldData(sl.getOrderCurrencyPair(state, quoteId)).data ?? undefined;

  const extCcyPair = currencyPair !== undefined ? sl.getCurrencyPairDetails(state, currencyPair) : null;

  return !!extCcyPair?.notDeliverable;
}

const makeOrderTypeToBackendPayloadWith =
  (sl: OrderMetaSelectors, action: OrderPropertyChanged | OrderPropertyChangedFromTemplate | OrderSubmission) =>
    (state: AppState, quoteId: string) => {
      const { dateInputCultureInfo } = sl.getUserPreferenceData(state);
      const isGtc = fieldData(sl.getOrderIsGtc(state, quoteId)).data;
      const expiryDay = isGtc === true ? undefined : fieldData(sl.getOrderExpiryDay(state, quoteId)).data ?? undefined;
      const expiryTime = isGtc === true ? undefined : fieldData(sl.getOrderExpiryTime(state, quoteId)).data ?? undefined;
      const utcExpiry = getUtcExpiry(isGtc, expiryDay, expiryTime);
      const isStartDateNow = sl.getOrderIsStartDateNow(state, quoteId);
      const startDate = fieldData(sl.getOrderStartDate(state, quoteId)).data ?? undefined;
      const startTime = fieldData(sl.getOrderStartTime(state, quoteId)).data ?? undefined;
      const clipSize = fieldData(sl.getOrderClipSize(state, quoteId)).data;
      const maturityDate = fieldData(sl.getOrderMaturityDate(state, quoteId)).data ?? undefined;
      const maturityDateString = maturityDate
        ? parseWithCultureInfo(dateInputCultureInfo, maturityDate) ?? maturityDate
        : undefined;

      const startDateUtc = getFullDateForOMS(isStartDateNow ? toUtc(new Date()) : dateAndTimeToUtc(startDate, startTime));
      const endDateUtc =
        isGtc || expiryDay === undefined || expiryTime === undefined
          ? undefined
          : getFullDateForOMS(dateAndTimeToUtc(expiryDay, expiryTime));
      const liquidityPools = fieldData(sl.getOrderLiquidityPool(state, quoteId)).data;
      const liquidityPool = liquidityPools.join(',');
      const speed = fieldData(sl.getOrderSpeed(state, quoteId)).data ?? undefined;
      const limitPrice = fieldData(sl.getOrderLimitPrice(state, quoteId)).data;
      const customerPrice = fieldData(sl.getOrderCustomerPrice(state, quoteId)).data;

      return (orderType: OrderType): Partial<OmsOrderSubmissionPayload> => {
        switch (orderType) {
          case 'TakeProfit':
            return {
              ...utcExpiry,
              margin: shouldResetField(action)
                ? undefined
                : fieldData(sl.getOrderMargin(state, quoteId)).data ?? undefined,
              customerPrice: computeTakeProfitCustomerPrice(action, customerPrice),
              limitPrice: computeTakeProfitLimitPrice(
                action,
                limitPrice,
              ),
            };
          case 'Call':
            return {
              ...utcExpiry,
              amountInCcy1: undefined,
              amountInCcy2: undefined,
              way: undefined,
            };
          case 'StopLoss':
            return {
              ...utcExpiry,
              triggerMode: fieldData(sl.getOrderTrigger(state, quoteId)).data ?? undefined,
            };
          case 'Fixing':
            return {
              fixingBenchmark: fieldData(sl.getOrderFixingBenchmark(state, quoteId)).data ?? undefined,
              fixingPriceType: fieldData(sl.getOrderFixingPriceType(state, quoteId)).data ?? undefined,
              fixingDateUtc: fieldData(sl.getOrderFixingDateUtc(state, quoteId)).data ?? undefined,
              fixingTime: fieldData(sl.getOrderFixingTime(state, quoteId)).data ?? undefined,
              fixingPlace: fieldData(sl.getOrderFixingPlace(state, quoteId)).data ?? undefined,
              marginInBps: fieldData(sl.getOrderMarginInBps(state, quoteId)).data ?? undefined,
              fixingMarginType: fieldData(sl.getOrderFixingMarginType(state, quoteId)).data ?? undefined,
            };
          case 'Twap':
            return {
              isNdf: isNdf(state, sl, quoteId),
              maturityDateString: isNdf(state, sl, quoteId) ? '1M' : maturityDateString,
              startDateUtc,
              endDateUtc,
              liquidityPool,
              clippingMode: fieldData(sl.getOrderClippingMode(state, quoteId)).data ?? undefined,
              clipSize: clipSize !== null ? Number(clipSize) : undefined,
              dodging: fieldData(sl.getOrderRandomize(state, quoteId)).data,
              spreadCapture: fieldData(sl.getOrderSpreadCapture(state, quoteId)).data,
            };
          case 'Nightjar':
            return {
              isNdf: isNdf(state, sl, quoteId),
              maturityDateString: isNdf(state, sl, quoteId) ? '1M' : maturityDateString,
              startDateUtc,
              endDateUtc,
              liquidityPool,
              speed,
              alphaSeeker: fieldData(sl.getOrderAlphaSeeker(state, quoteId)).data ?? undefined,
            };
          case 'Falcon':
            return {
              isNdf: isNdf(state, sl, quoteId),
              maturityDateString: isNdf(state, sl, quoteId) ? '1M' : maturityDateString,
              startDateUtc,
              endDateUtc,
              liquidityPool,
              speed,
            };
          default:
            assertUnreachable(orderType, 'Unhandled order type');
        }
      };
    };
