import type { Updater } from '../typings';
import type { SavedWorkspace as SavedWorkspace6 } from '../updater6/model';
import type { SavedBlotter, SavedWorkspace as SavedWorkspace7 } from './model';

const currentVersion = 7;

export const updater7: Updater<SavedWorkspace6, SavedWorkspace7> = {
  version: currentVersion,
  canUpdate(data: any) {
    return data.version === currentVersion - 1;
  },
  update: (previousWorkspace: SavedWorkspace6): SavedWorkspace7 => {
    const savedWorkspace: SavedWorkspace7 = {
      ...previousWorkspace,
      blotter: previousWorkspace.blotter as SavedBlotter,
      version: currentVersion,
      tabs: updateAccumulatorTiles(previousWorkspace.tabs),
    };

    return savedWorkspace;
  },
};

function updateAccumulatorTiles(tabs: SavedWorkspace6['tabs']): SavedWorkspace7['tabs'] {
  return tabs.map(tab => ({
    ...tab,
    tiles: tab.tiles.map(tile =>
      tile.instrument === 'TargetAccumulator'
        ? {
            ...tile,
            instrument: 'Accumulator',
            productName: 'FxTargetAccumulator',
          }
        : tile,
    ),
  }));
}
