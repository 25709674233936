import type { Collection } from 'typings/utils';

export interface ItemPosition {
  top: number;
  left: number;
}

interface IFreeLayout {
  itemPositions: Collection<ItemPosition>;
}

export const emptyFreeLayout: FreeLayout = {
  itemPositions: {},
};

export type FreeLayout = Readonly<IFreeLayout>;

export type FreeLayoutCollection = Collection<FreeLayout>;
