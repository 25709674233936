import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import {
  mapStateToPropsChangeTabTypeButton,
  mapDispatchToPropsChangeTabTypeButton,
} from './connectChangeTabTypeButton';
import { mapStateToPropsTabPopover, mapDispatchToPropsTabPopover } from './connectTabPopover';

export const connectChangeTabTypeButton = connect(
  mapStateToPropsChangeTabTypeButton(selectors),
  mapDispatchToPropsChangeTabTypeButton(actionCreators),
);

export const connectTabPopover = connect(
  mapStateToPropsTabPopover(selectors),
  mapDispatchToPropsTabPopover(actionCreators),
);
