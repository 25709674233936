import { useCallback, useContext, useEffect, useState } from 'react';
import { Tooltip, type TooltipProps } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import type { RenderPropChildren } from 'typings/utils';
import { useConditionalCallback } from 'components/share/hooks/useConditionalCallback';
import { TooltipContainerContext } from 'components/contexts/TooltipContainer';
import { noop } from '@sgme/fp';
import type { AlertLevel, ValidationResult } from 'components/Form/Inputs/typings';
import en from './locales/en.json';
import fr from './locales/fr.json';

const messagesMap = { en, fr };

interface ITradeCaptureMessageValues {
  messageValues?: any;
}

type ValidationEvent = 'blur' | 'keypress';
interface ValidationArgs {
  value?: string;
  event: ValidationEvent;
}

export type LocalValidator = (args: ValidationArgs) => ValidationResult | undefined;

interface LocalValidationCallbacks {
  /** Set validation error/warning/valid or keep it with `undefined` */
  setValidationState(validationResult?: ValidationResult): void;
  localValidate?: LocalValidator;
}

interface ITradeCaptureNoTooltip {
  alertLevel: undefined;
}

interface ITradeCaptureTooltip {
  alertLevel: AlertLevel;
  messageId: string;
  seen?: boolean;
  onSeen?(): void;
}

type TradeCaptureTooltipProps = (ITradeCaptureNoTooltip | ITradeCaptureTooltip) &
  LocalValidationCallbacks &
  Pick<TooltipProps, 'placement'>;

function hasError(
  props: ITradeCaptureNoTooltip | ITradeCaptureTooltip,
): props is ITradeCaptureTooltip {
  return props.alertLevel !== undefined;
}

export interface TradeCaptureTooltipChildrenProps {
  alertLevel?: AlertLevel;
  ref(instance: any | null): void;
}

const tooltipSuffix: Record<AlertLevel, string> = {
  invalid: 'danger',
  warning: 'warning',
  valid: 'success',
};

const tooltipClassName = (alertLevel: AlertLevel) => `tooltip-${tooltipSuffix[alertLevel]}`;

const style = { minWidth: 200 };

function callbackOnEventHelper(
  target: HTMLElement | null,
  eventName: 'focus' | 'blur',
  callback: () => void,
) {
  if (target !== null) {
    target.addEventListener(eventName, callback);
    return () => target.removeEventListener('focus', callback);
  }
}

type DisplayTooltipProps = RenderPropChildren<TradeCaptureTooltipChildrenProps> &
  TradeCaptureTooltipProps &
  ITradeCaptureMessageValues;

export function DisplayTooltip({
  children,
  placement = 'right',
  localValidate,
  setValidationState,
  ...props
}: DisplayTooltipProps): JSX.Element {
  const containerRef = useContext(TooltipContainerContext) ?? undefined;

  const {
    seen = true,
    onSeen = noop,
    alertLevel = undefined,
    messageId = undefined,
  } = hasError(props) ? props : {};

  const onSeenCallback = useConditionalCallback(seen === false, onSeen);
  const [target, setTarget] = useState<HTMLInputElement | null>(null);
  const [isOpen, setOpen] = useState(seen === false);
  const [isFocus, setFocus] = useState(false);

  const toggle = useCallback(() => {
    setOpen(!isOpen);
    onSeenCallback();
  }, [isOpen, onSeenCallback]);

  useEffect(() => {
    const focus = () => {
      setFocus(true);
    };
    return callbackOnEventHelper(target, 'focus', focus);
  }, [target]);

  useEffect(() => {
    const blur = () => {
      setFocus(false);
    };
    return callbackOnEventHelper(target, 'blur', blur);
  }, [target]);

  useEffect(() => {
    setOpen(seen === false);
  }, [seen]);

  useEffect(() => {
    if (localValidate === undefined || messageId?.startsWith('error-tc-')) {
      return;
    }
    const newValidationResult = localValidate({
      value: target?.value,
      event: isFocus ? 'keypress' : 'blur',
    });
    if (newValidationResult === undefined || alertLevel === newValidationResult.status) {
      return;
    }
    setValidationState(newValidationResult);
  }, [localValidate, setValidationState, alertLevel, messageId, isFocus, target?.value]);
  return (
    <>
      {children({
        ref: setTarget,
        alertLevel,
      })}
      {target !== null && alertLevel !== undefined && (
        <IntlComponentBoundary messagesMap={messagesMap}>
          <Tooltip
            container={containerRef}
            placement={placement}
            boundariesElement="scrollParent"
            className={tooltipClassName(alertLevel)}
            delay={0}
            fade={false}
            style={style}
            isOpen={isOpen}
            target={target}
            toggle={toggle}
            data-e2e="tooltip"
            data-e2e-level={alertLevel}
            data-e2e-messageid={messageId}
          >
            <FormattedMessage id={messageId} values={props.messageValues} />
          </Tooltip>
        </IntlComponentBoundary>
      )}
    </>
  );
}
