import type { Action } from 'state/actions';
import type { Reducer } from 'redux';
import type { SessionState } from './sessionModel';

const defaultState: SessionState = {
  isValid: true,
};

export const sessionReducer: Reducer<SessionState> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case 'NOTIFY_DISCONNECTION':
      return {
        ...state,
        isValid: false,
        logoffReason: action.reason,
      };

    default:
      return state;
  }
};
