import { useCallback } from 'react';
import { connectChangeTabTypeButton } from './connect';
import { withTabId } from 'components/NavMenu';
import { FormattedMessage } from 'react-intl';
import { button } from 'components/Form/Inputs/e2e';

export interface ChangeTabTypeButtonProps {
  tabType: string;
  toggleTabType(): void;
  close(): void;
}

export const ChangeTabTypeButtonRaw: React.FunctionComponent<ChangeTabTypeButtonProps> = ({
  tabType,
  toggleTabType,
  close,
}) => {
  const onClick = useCallback(() => {
    toggleTabType();
    close();
  }, [close, toggleTabType]);
  return (
    <button
      type="button"
      className="btn ms-3 btn-default py-1"
      onClick={onClick}
      data-e2e={button('switch-workspace-type')}
    >
      <FormattedMessage
        id={tabType === 'tiles' ? 'workspace.types.bulk' : 'workspace.types.tiles'}
      />{' '}
      <i className="icon icon-sm">arrow_forward</i>
    </button>
  );
};

export const ChangeTabTypeButton = withTabId(connectChangeTabTypeButton(ChangeTabTypeButtonRaw));
