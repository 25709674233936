import { noop, identity } from '@sgme/fp';
import type { TimerKey } from './timerKey';

export const start: (key: TimerKey, domain: TimerKey) => void =
  // process.env.DEBUG === 'profiling'
  // ? await import('./Timer')._start(await import('./maps').startMap)
  // :
  noop;

export const end: (key: TimerKey, domain: TimerKey) => void =
  // process.env.DEBUG === 'profiling'
  // ? await import('./Timer')._end(await import('./maps').startMap, await import('./maps').timerMap)
  // :
  noop;

export const timer: (key: TimerKey, domain: TimerKey) => <T>(fn: T) => T =
  // process.env.DEBUG === 'profiling'
  // ? await import('./Timer')._timer(
  // await import('./maps').startMap,
  // await import('./maps').timerMap,
  // )
  // :
  () => identity;

// const interval = 10000;
// if (process.env.DEBUG === 'profiling') {
//   // eslint-disable-next-line no-console
//   console.log('Start Debugging Timer');
//   const flush = await import('./flush')._flush(await import('./maps').timerMap);
//   setInterval(flush, interval);
// }
