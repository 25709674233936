import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type {
  IBulkQuoteExecutionPayload,
  IBulkLegQuoteExecutionPayload,
} from 'api/multipass/bulk/bulkQuoteExecution';
import type { IQuoteLeg, ILegMargin } from 'state/fxBulks/fxBulksModel';
import type { BidAskPair, Side } from 'state/share/productModel/litterals';
import { getDateForMultipass } from 'utils/dateFormats';
import { assertIsDefined, isEmpty } from '@sgme/fp';

interface IBulkQuoteExecutionMetadata {
  streamId: string;
  bulkId: string;
  tradeDate: Date;
}

type selectors =
  | 'isUserInternalSales'
  | 'getBulkCurrencyPair'
  | 'getBulkAmountCurrency'
  | 'getCurrencyPrecision'
  | 'getBulkStreamState'
  | 'getConnectionId'
  | 'getBulkNettedLegMargin'
  | 'getBulkNettedLegWay'
  | 'sideToWay'
  | 'makeSpotWithMargin'
  | 'makeForwardPointWithMargin'
  | 'getUserPreferenceData';

type ActualSelectors = Pick<Selectors, selectors>;

export const metaSelectorQuoteExecutionBuilderWith: MapStateToMetadataHOF<
  IBulkQuoteExecutionPayload,
  IBulkQuoteExecutionMetadata,
  AppState,
  ActualSelectors
> =
  sl =>
  (state, { streamId, bulkId, tradeDate }) => {
    const streamState = sl.getBulkStreamState(state, streamId);
    if (streamState.status !== 'PRICING') {
      throw 'error : cannot execute when stream is not pricing';
    }
    const { quoteId, frontTimestamp, backTimestamp } = streamState.quote;
    const streamLegs = streamState.quote.legs;
    const pair = sl.getBulkCurrencyPair(state, bulkId).value;

    assertIsDefined(pair, 'currencyPair needs to be defined for execution');

    const precision = sl.getCurrencyPrecision(state, pair);

    const { emails, splitNotificationsEmailsCash, splitNotifications } =
      sl.getUserPreferenceData(state);
    let emailNotificationList;
    if (splitNotifications && !isEmpty(splitNotificationsEmailsCash)) {
      emailNotificationList = splitNotificationsEmailsCash.join(';');
    } else {
      emailNotificationList = emails.join(';');
    }
    const userLocalDateTime = getDateForMultipass(tradeDate);
    const isInternalSales = sl.isUserInternalSales(state);
    const lastQuoteFromBackTimestamp = backTimestamp;
    const lastQuoteReceivedByFrontTimestamp = frontTimestamp.getTime();
    const executionClickByFrontTimestamp = tradeDate.getTime();

    return {
      rfsId: streamId,
      replyToStream: sl.getConnectionId(state),
      quoteId,
      emailNotificationList,
      userLocalDateTime,
      lastQuoteFromBackTimestamp,
      lastQuoteReceivedByFrontTimestamp,
      executionClickByFrontTimestamp,
      legs: streamLegs.map(leg => {
        const margin = sl.getBulkNettedLegMargin(state, bulkId, leg.date);
        const dominantWay = sl.getBulkNettedLegWay(state, bulkId, leg.date);

        assertIsDefined(margin, 'netted leg margin needs to be defined for execution');
        assertIsDefined(dominantWay, 'netted leg way needs to be defined for execution');

        return toBulkLegExecution(sl, leg, dominantWay, margin, precision, isInternalSales);
      }),
    };
  };

const toBulkLegExecution = (
  sl: ActualSelectors,
  quoteLeg: IQuoteLeg,
  dominantSide: Side,
  margin: ILegMargin,
  precision: number,
  isInternalSales: boolean,
): IBulkLegQuoteExecutionPayload => {
  const getBidAskValueByWay =
    quoteLeg.customerWay === 'Buy'
      ? ({ ask }: BidAskPair): number => ask
      : ({ bid }: BidAskPair): number => bid;
  const dominantWay = sl.sideToWay(dominantSide);
  const spotTrader = getBidAskValueByWay(quoteLeg.spotWithoutMargin);
  const { spotMargin: spotMarginPoints, forwardMargin: forwardMarginPoints } = margin;
  const spotClient = isInternalSales
    ? sl.makeSpotWithMargin(
        dominantWay,
        getBidAskValueByWay(quoteLeg.spotWithoutMargin),
        spotMarginPoints,
        precision,
      )
    : getBidAskValueByWay(quoteLeg.spotWithMargin);
  const forwardPointsWithoutMargin = getBidAskValueByWay(quoteLeg.forwardPoints);
  const forwardPointsWithMargin = sl.makeForwardPointWithMargin(
    dominantWay,
    forwardPointsWithoutMargin,
    forwardMarginPoints,
  );
  return {
    amount: quoteLeg.amount,
    valueDate: quoteLeg.date,
    valueDateTenor: quoteLeg.dateTenor,
    customerWay: quoteLeg.customerWay,
    spotTrader: isInternalSales ? spotTrader : undefined,
    spotClient,
    forwardPoints: forwardPointsWithMargin,
  };
};
