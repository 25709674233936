import { getOrderInput } from './factories';

export const getOrderCurrencyPair = getOrderInput('currencyPair');
export const getOrderType = getOrderInput('type');
export const getOrderLimitPrice = getOrderInput('limitPrice');
export const getOrderCustomerPrice = getOrderInput('customerPrice');
export const getOrderWay = getOrderInput('way');
export const getOrderAmount = getOrderInput('amount');
export const getOrderAmountCurrency = getOrderInput('amountCurrency');
export const getOrderTrigger = getOrderInput('trigger');
export const getOrderExpiryDay = getOrderInput('expiryDay');
export const getOrderExpiryDayTenor = getOrderInput('expiryDayTenor');
export const getOrderExpiryTime = getOrderInput('expiryTime');
export const getOrderEmails = getOrderInput('emails');
export const getOrderEmailsWithSplit = getOrderInput('emailsOrders');
export const getOrderIsGtc = getOrderInput('isGtc');
export const getOrderMaturityDate = getOrderInput('maturityDate');
export const getOrderTenor = getOrderInput('tenor');
export const getOrderStartDate = getOrderInput('startDate');
export const getOrderStartTime = getOrderInput('startTime');
export const getOrderLiquidityPool = getOrderInput('liquidityPool');
export const getOrderSpeed = getOrderInput('speed');
export const getOrderClippingMode = getOrderInput('clippingMode');
export const getOrderClipSize = getOrderInput('clipSize');
export const getOrderRandomize = getOrderInput('randomize');
export const getOrderSpreadCapture = getOrderInput('spreadCapture');
export const getOrderAlphaSeeker = getOrderInput('alphaSeeker');
export const getOrderFixingBenchmark = getOrderInput('fixingBenchmark');
export const getOrderFixingPriceType = getOrderInput('fixingPriceType');
export const getOrderFixingDateUtc = getOrderInput('fixingDateUtc');
export const getOrderFixingTime = getOrderInput('fixingTime');
export const getOrderFixingPlace = getOrderInput('fixingPlace');
export const getOrderFixingMarginType = getOrderInput('fixingMarginType');
export const getOrderMarginInBps = getOrderInput('marginInBps');
export const getOrderMargin = getOrderInput('margin');
export const getOrderAlgoFixingReference = getOrderInput('ndfFixingSource');
export const getOrderAlgoFixingDate = getOrderInput('ndfFixingDate');
