import { assertUnreachable } from '@sgme/fp';
import type { Thunk } from 'state';

export function optionSubmitStrikeThunk(
  quoteId: string,
  legId: string,
  strike: string,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const productName = sl.getOptionLegProductName(state, legId);

    switch (productName) {
      case 'Vanilla':
        if (strike === '?') {
          dispatch(ac.optionSolvingWanted(quoteId, legId, 'strike'));
        } else {
          dispatch(ac.optionLegPropertyChanged(quoteId, legId, { strike }));
        }
        break;

      case 'Strangle':
        if (strike === '?') {
          dispatch(ac.optionSolvingWanted(quoteId, legId, 'strike'));
        } else {
          dispatch(ac.optionLegPropertyChanged(quoteId, legId, { strike }));
        }
        break;

      case 'Straddle':
        dispatch(ac.optionLegPropertyChanged(quoteId, legId, { strike }));
        break;

      case 'RiskReversal':
        if (strike === '?') {
          dispatch(ac.optionSolvingWanted(quoteId, legId, 'strike'));
        } else {
          dispatch(ac.optionLegPropertyChanged(quoteId, legId, { strike }));
        }
        break;

      case 'FxOptionMultileg':
        break;

      default:
        assertUnreachable(productName, 'New product name to handle');
    }
  };
}
