import { getSwapMetadata } from './factories';
import type { AppState } from 'state/model';
import type { SwapProductName } from '../fxSwapsModel';
import {
  getSwapFarPrice,
  getSwapIsNonDeliverable,
  getSwapIsOffMarket,
  getSwapNearPrice,
} from './swapInput';

export const getSwapPropertiesRequested = getSwapMetadata('propertiesRequested');
export const getSwapIsPriceable = getSwapMetadata('isPriceable');
export const getSwapCurrentStreamId = getSwapMetadata('currentStreamId');
export const getSwapBidSpotMargin = getSwapMetadata('bidSpotMargin');
export const getSwapAskSpotMargin = getSwapMetadata('askSpotMargin');
export const getSwapBidMargin = getSwapMetadata('bidMargin');
export const getSwapAskMargin = getSwapMetadata('askMargin');

export function getSwapProductName(state: AppState, tileId: string): SwapProductName {
  const isOffMarket = getSwapIsOffMarket(state, tileId).value;
  if (isOffMarket) {
    const nearPrice = getSwapNearPrice(state, tileId).value;
    const farPrice = getSwapFarPrice(state, tileId).value;
    if (nearPrice !== null) {
      return 'FxRollover';
    } else if (farPrice !== null) {
      return 'FxPredeliver';
    }
  }

  const { value: isNonDeliverable } = getSwapIsNonDeliverable(state, tileId);
  return isNonDeliverable ? 'FxNdSwap' : 'FxSwap';
}
