import type { ISgmeHttp, HttpCall } from 'utils/sgmeHttp';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { logger } from 'logging/logger';

import { extract } from 'utils/object';
import type { OrderTemplate } from 'state/fxOrders/fxOrdersModel';
import type { Collection } from 'typings/utils';

export const ordersTemplatesServiceEndPoint = 'api/userdata/order_templates/';

function endPoint(workspaceId: string | undefined) {
  return workspaceId !== undefined && !workspaceId.includes('@')
    ? `${ordersTemplatesServiceEndPoint}${workspaceId}`
    : ordersTemplatesServiceEndPoint;
}

export interface IOrdersTemplatesServiceApi {
  timeout: number;
  save(templates: Collection<OrderTemplate>, workspaceId?: string): Observable<HttpCall<void>>;
  get(workspace?: string, workspaceId?: string): Observable<Collection<OrderTemplate> | null>;
}

export function ordersTemplateServiceApi(http: ISgmeHttp): IOrdersTemplatesServiceApi {
  return {
    save(templates: Collection<OrderTemplate>, workspaceId?: string) {
      return http.post<void>(endPoint(workspaceId), templates);
    },
    get(workspaceId?: string) {
      return http.getJSON<Collection<OrderTemplate>>(endPoint(workspaceId)).pipe(
        map(extract('payload')),
        catchError(err => {
          logger.logError('Loading saved orders teamplates failed {message_s}', err.message);
          return of(null);
        }),
      );
    },
    timeout: 10_000,
  };
}
