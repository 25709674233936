import { defaultSolvingFor } from './fxOptionsModel';
import type { FxOptionState } from './model/optionProduct';
import type {
  FxOptionLegState,
  FxOptionMultilegState,
  IFxVanillaLegValues,
  TypedLegState,
  TypedStrategyLegType,
  VanillaLegState,
} from './model/optionsLegs';
import type { Collection } from '../../typings/utils';
import { addKey } from '../../utils/stateMap';
import { getLegKey } from '../share/patchHelper';
import type {
  OptionLegSavedTile,
  OptionMultilegSavedTile,
  OptionVanillaLegSavedTile,
} from '../../api/workspaceService/updaters/updater9/model';

export const emptyFxOptionState: FxOptionState = {
  values: {
    currencyPair: null,
    hedgeType: 'Live',
    markupCurrency: null,
    amountReference: null,
    amountReferenceCurrency: null,
    amountReferenceIsOverridden: false,
    premiumDate: null,
    premiumDateTenor: null,
    isInFine: null,
  },
  errors: {},
  warnings: {},
  inputs: {},
  legIds: [],
  // legIds: ['optionId/0'],
  nextLegId: 0,
  propertiesRequested: false,
  propertiesRequestError: null,
  currentSessionId: null,
  isPriceable: false,
  isStrategy: false,
  orientation: 'vertical',
  currentStreamId: null,
  lastStreamId: null,
  tradeCaptureIdVersion: null,
  lastStreamError: null,
  displayPriceType: [1, 'AMOUNT'],
  legsCurrencyLinked: true,
  group: false,
  expanded: [],
  currentEspStreamId: null,
  lastExecutedQuoteId: null,
  currentExecutionId: null,
  rfsStartedAt: null,
  priceRecords: [],
  displaySolveStrikeModal: false,
};

export function isVanillaLegSavedTile(leg: OptionLegSavedTile): leg is OptionVanillaLegSavedTile {
  return leg.productName === 'Vanilla';
}
export function isFxOptionMultilegSavedTile(
  leg: OptionLegSavedTile,
): leg is OptionMultilegSavedTile {
  return leg.productName === 'FxOptionMultileg';
}

export function isVanillaLeg(leg: FxOptionLegState): leg is VanillaLegState {
  return leg.productName === 'Vanilla';
}

export function isFxOptionMultileg(leg: FxOptionLegState): leg is FxOptionMultilegState {
  return leg.productName === 'FxOptionMultileg';
}

export function createEmptyFxOptionMultilegState(legIds: string[]): FxOptionMultilegState {
  return {
    errors: {},
    warnings: {},
    values: {},
    inputs: {},
    productName: 'FxOptionMultileg',
    legIds,
  };
}

export function createEmptyTypedLegState(
  productName: TypedStrategyLegType,
  legIds: string[],
): TypedLegState {
  return {
    errors: {},
    warnings: {},
    values: {},
    inputs: {},
    productName,
    legIds,
  };
}

export function createEmptyFxOptionVanillaLegState(
  legIds: string[] | undefined = undefined,
  optionVanillaLegValuesPatch: Partial<IFxVanillaLegValues> = {},
): VanillaLegState {
  return {
    productName: 'Vanilla',
    values: {
      currency1: null,
      currency2: null,
      hedgePrice: null,
      optionType: null,
      possibleOptionTypes: null,
      expiryDate: null,
      expiryDateTenor: null,
      strike: null,
      strikeShortcut: null,
      isStrikeInDelta: false,
      notionalAmount: null,
      notionalCurrency: 1,
      deliveryDate: null,
      deliveryDateTenor: null,
      settlementType: null,
      possibleSettlementTypes: null,
      cashSettlementCurrency: null,
      possibleCashSettlementCurrencies: null,
      fixingReference1: null,
      possibleFixingReferences1: null,
      marketPlace: null,
      possibleMarketPlaces: null,
      premiumDate: null,
      premiumDateTenor: null,
      side: null,
      isReadyToPrice: false,
      premiumBid: null,
      premiumAsk: null,
      premiumTypeString: null,
      premiumCurrency: null,
      markup: null,
      isInFine: null,
      solvable: null,
      volatilityBid: null,
      volatilityAsk: null,
      ...optionVanillaLegValuesPatch,
    },
    inputs: {},
    errors: {},
    warnings: {},
    solvingFor: defaultSolvingFor,
    solved: null,
    ...(legIds ? { legIds } : {}),
  };
}

export function createEmptyFxOptionsState(
  state: Readonly<Collection<FxOptionLegState>>,
  optionId: string,
) {
  const vanillaLegId = `${optionId}/0/0`;

  const updatedState = addKey(
    state,
    getLegKey(optionId),
    createEmptyFxOptionMultilegState([vanillaLegId]),
  );
  return addKey(updatedState, vanillaLegId, createEmptyFxOptionVanillaLegState());
}

// todo 4376 see where it could live to be used both in epic and state folder
export const addTileIdToLegKey = (tileId: string, currentKey: string): string =>
  `${tileId}/${currentKey}`;

export const updateLegIdsWithNewTileId = (tileId: string, legIds: string[]) =>
  legIds.map(legId => addTileIdToLegKey(tileId, legId));
