import { type StreamingConnectionState, patchConnected } from './streamingConnectionModel';
import type { Action } from 'state/actions';
import type { Reducer } from 'redux';

const defaultState: StreamingConnectionState = {
  status: 'INITIALISING',
};

const streamingConnectionReducer: Reducer<StreamingConnectionState> = (
  state: StreamingConnectionState = defaultState,
  action: Action,
): StreamingConnectionState => {
  switch (action.type) {
    case 'STREAMING_CONNECTED':
    case 'STREAMING_RECONNECTED':
      return {
        status: 'CONNECTED',
        connectionId: action.connectionId,
        degraded: false,
        driver: action.driver,
      };
    case 'STREAMING_DEGRADED':
      return patchConnected({ degraded: true })(state);
    case 'STREAMING_HEALTHY':
      return patchConnected({ degraded: false })(state);
    case 'STREAMING_DISCONNECTED':
      return { status: 'DISCONNECTED', reason: action.reason };
    default:
      return state;
  }
};

export { streamingConnectionReducer };
