import type { IFxHedgeInputs } from '../model/optionHedges';

export interface OptionHedgePropertyChanged {
  type: 'OPTION_HEDGE_PROPERTY_CHANGED';
  hedgeId: string;
  patch: Partial<IFxHedgeInputs>;
}

export function optionHedgePropertyChanged(
  hedgeId: string,
  patch: Partial<IFxHedgeInputs>,
): OptionHedgePropertyChanged {
  return {
    type: 'OPTION_HEDGE_PROPERTY_CHANGED',
    hedgeId,
    patch,
  };
}

export interface OptionHedgePropertyRemoveError {
  type: 'OPTION_HEDGE_PROPERTY_REMOVE_ERROR';
  hedgeId: string;
  keys: ReadonlyArray<keyof IFxHedgeInputs>;
}

export function optionHedgePropertyRemoveError(
  hedgeId: string,
  keys: ReadonlyArray<keyof IFxHedgeInputs>,
): OptionHedgePropertyRemoveError {
  return {
    type: 'OPTION_HEDGE_PROPERTY_REMOVE_ERROR',
    hedgeId,
    keys,
  };
}
