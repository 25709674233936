import type { AppState } from 'state/model';
import type { BlotterTab } from 'state/blotter/blotterModel';
import type { BlotterMetadata, BlotterTabMetadata, IBlotterErrorType } from '../blotterModel';
import { assertUnreachable } from '@sgme/fp';

const getBlotterMetadata = (state: AppState): BlotterMetadata => state.blotter.metadata;

export const getBlotterErrors = (state: AppState): readonly IBlotterErrorType[] =>
  getBlotterMetadata(state).errors;

export const getBlotterActiveTab = (state: AppState): BlotterTab =>
  getBlotterMetadata(state).activeTab;

export const getBlotterPanelHeight = (state: AppState): number =>
  getBlotterMetadata(state).panelHeight;

export const getBlotterEnableShowMyMainDeals = (state: AppState): boolean =>
  getBlotterMetadata(state).enableShowMyMainDeals;

export const getBlotterOrderRejectedId = (state: AppState): string | null =>
  getBlotterMetadata(state).orderRejectedId;

export const getBlotterOrderShowSaveErrorDialogBox = (state: AppState): boolean =>
  getBlotterMetadata(state).showSaveErrorDialogBox;

export const getBlotterOrderDisplayedErrorId = (state: AppState): string | undefined =>
  getBlotterMetadata(state).displayedErrorId;

export const getBlotterTabMetadata = (state: AppState, tab: BlotterTab): BlotterTabMetadata => {
  switch (tab) {
    case 'cash':
      return getBlotterMetadata(state).cashTab;
    case 'option':
      return getBlotterMetadata(state).optionTab;
    case 'order':
      return getBlotterMetadata(state).orderTab;
    case 'accumulator':
      return getBlotterMetadata(state).accumulatorTab;
    default:
      assertUnreachable(tab, 'Unknown tab type');
  }
};

export const getBlotterDetailsPanel = (state: AppState) => getBlotterMetadata(state).detailsPanel;

export const getBlotterCantReopenReason = (state: AppState) =>
  getBlotterMetadata(state).cantReopenReason;

export const getBlotterDetailsPanelSelectedId = (state: AppState) =>
  getBlotterDetailsPanel(state).selectedId;

export const getBlotterDetailsPanelChildrenIds = (state: AppState) =>
  getBlotterDetailsPanel(state).childrenIds;

export const getBlotterDetailsPanelMode = (state: AppState) => getBlotterDetailsPanel(state).mode;

export const getBlotterDetailsPanelSaveError = (state: AppState) =>
  getBlotterDetailsPanel(state).saveError;

export const getBlotterDetailsPanelHasUnexpectedError = (state: AppState) =>
  getBlotterDetailsPanel(state).hasUnexpectedError;

export function getBlotterDetailsValidationResponse(state: AppState) {
  return getBlotterDetailsPanel(state).validationResponse;
}
