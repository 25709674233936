import type { BlotterTab } from 'state/blotter/blotterModel';
import type { MapDispatchToPropsHOF, MapStateToPropsFactoryHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';

export interface LiveBlotterPanelConnectOwnProps {}

export interface LiveBlotterPanelConnectStateProps {
  isOpen: boolean;
  height: number;
  activeTab: BlotterTab;
}

export interface LiveBlotterPanelConnectDispatchProps {
  onPanelHeightChanged(height: number): void;
}

export type LiveBlotterPanelConnectSelectorsKeys =
  | 'getBlotterIsOpen'
  | 'getBlotterPanelHeight'
  | 'getBlotterErrors'
  | 'getBlotterActiveTab';
export type LiveBlotterPanelConnectSelectors = Pick<
  Selectors,
  LiveBlotterPanelConnectSelectorsKeys
>;

export const mapStateToPropsLiveBlotterPanel: MapStateToPropsFactoryHOF<
  LiveBlotterPanelConnectStateProps,
  LiveBlotterPanelConnectOwnProps,
  AppState,
  LiveBlotterPanelConnectSelectors
> = sl => _ownProps => state => ({
  isOpen: sl.getBlotterIsOpen(state),
  height: sl.getBlotterPanelHeight(state),
  activeTab: sl.getBlotterActiveTab(state),
});

export type LiveBlotterPanelConnectActionCreatorsKeys =
  | 'blotterToggle'
  | 'blotterPanelHeightChanged';
export type LiveBlotterPanelConnectActionCreators = Pick<
  ActionCreators,
  LiveBlotterPanelConnectActionCreatorsKeys
>;

export const mapDispatchToPropsLiveBlotterPanel: MapDispatchToPropsHOF<
  LiveBlotterPanelConnectDispatchProps,
  LiveBlotterPanelConnectOwnProps,
  LiveBlotterPanelConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  onPanelHeightChanged: (height: number) => {
    dispatch(ac.blotterPanelHeightChanged(height));
  },
});
