import type { Reducer } from 'redux';
import type { Action } from 'state/actions';

export const clientWorkspaceActiveTabReducer: Reducer<string | null> = (
  activeTab: string | null = null,
  action: Action,
) => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TAB_SWITCHED':
      return action.tabId;
    case 'CLIENTWORKSPACE_TAB_ADDED':
      if (activeTab === null) {
        return action.tabId;
      }
  }
  return activeTab;
};
