import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import type { Client } from 'state/referenceData/referenceDataModel';
import { HighlightMatches, compareCompaniesPuttingTheseFirst, normalize } from './utils';
import { ClientRow } from './ClientRow';
import { PrimeBrokerBadge } from 'components/NavMenu/PrimeBrokerBadge';
import { IsInternal } from 'components/share/UserType';
import { ClientListRowMenuConnected, type PinnedStatus } from './ClientListRowMenu';

const maxClients = 15;

interface ClientListProps {
  foundClients: readonly Client[];
  highlight: string;
  selected: number;
  onClientChange(companyId: string): void;
  gotoClient(index: number): void;
  close(): void;
  pinned: readonly number[];
}

const widths = {
  table: 600,
  account: 300,
  bdr: 120,
  name: 180,
};

const Table = styled.table.attrs({ className: 'table table-sm table-hover' })`
  width: ${widths.table}px;
`;

const AccountContainer = styled.div.attrs({ className: 'd-flex' })`
  width: ${widths.account}px;
`;

const BdrContainer = styled.div.attrs({ className: 'text-truncate' })`
  width: ${widths.bdr}px;
`;

const NameContainer = styled.div.attrs({ className: 'text-truncate' })`
  width: ${widths.name}px;
`;

export const ClientList: React.FunctionComponent<ClientListProps> = ({
  foundClients,
  highlight,
  selected,
  onClientChange,
  gotoClient,
  close,
  pinned = [],
}: ClientListProps) => (
  <Table>
    <thead>
      <tr>
        <th scope="col">
          <FormattedMessage id="clientpicker.accountNameCol" />
        </th>
        <th scope="col">
          <FormattedMessage id="clientpicker.bdrCol" />
        </th>
        <th scope="col">
          <FormattedMessage id="clientpicker.externalNameCol" />
        </th>
        <IsInternal>
          <th scope="col"></th>
        </IsInternal>
      </tr>
    </thead>
    <tbody>
      {foundClients
        .slice(0) // make a copy because sort mutates array
        .sort(
          compareCompaniesPuttingTheseFirst(
            'WAITSTATE',
            normalize(highlight),
            ...pinned
              .map((c) => foundClients.find((f) => f.companyId === c)?.companyName)
              .filter((c): c is string => c !== undefined),
          ),
        )
        .slice(0, maxClients)
        .map(({ companyId, companyName, externalCompanyName, primeBroker }, index) => (
          <ClientRow
            key={companyId}
            companyId={companyId}
            gotoClient={gotoClient}
            onClientChange={onClientChange}
            close={close}
            index={index}
          >
            {(handlers) => (
              <tr
                {...handlers}
                className={`${selected === index ? 'bg-lvl2' : ''} ${
                  pinned.includes(companyId) || companyName === 'WAITSTATE' ? 'fw-bold' : ''
                }`}
              >
                <td title={companyName}>
                  <AccountContainer>
                    <>
                      <div className="text-truncate">
                        <HighlightMatches value={companyName} match={highlight} />
                      </div>
                      <PrimeBrokerBadge primeBroker={primeBroker} showTooltip />
                    </>
                  </AccountContainer>
                </td>
                <td>
                  <BdrContainer>
                    <HighlightMatches value={`${companyId}`} match={highlight} />
                  </BdrContainer>
                </td>
                <td>
                  <NameContainer>
                    <HighlightMatches value={externalCompanyName || ''} match={highlight} />
                  </NameContainer>
                </td>
                <IsInternal>
                  <td>
                    {companyName !== 'WAITSTATE' && (
                      <ClientListRowMenuConnected
                        companyId={companyId}
                        pinnedStatus={getPinnedStatus(pinned, companyId)}
                      />
                    )}
                  </td>
                </IsInternal>
              </tr>
            )}
          </ClientRow>
        ))}
    </tbody>
  </Table>
);

function getPinnedStatus(pinned: readonly number[], companyId: number): PinnedStatus {
  const i = pinned.indexOf(companyId);
  if (i === -1) {
    return undefined;
  }
  if (pinned.length === 1) {
    return 'only';
  }
  if (i === 0) {
    return 'first';
  }
  if (pinned.length - 1 === i) {
    return 'last';
  }

  return 'pinned';
}
