export type CurrencyChoice = typeof FIRST_CURRENCY | typeof SECOND_CURRENCY;
export const FIRST_CURRENCY = 1;
export const SECOND_CURRENCY = 2;

export type BidAskKeys = 'bid' | 'ask';
export type Way = 'Bid' | 'Ask';
export type LegSide = 'Buy' | 'Sell';
export type Side = LegSide | 'Both';
export type StrategyTypeName = 'Vanilla' | 'Multileg' | 'Straddle' | 'Strangle' | 'RiskReversal';

export const hedgeTypes = ['Live', 'Spot', 'Forward'] as const;
export type HedgeType = (typeof hedgeTypes)[number];
export type HedgeWay = 'WayUnknown' | 'Sell' | 'Buy';
export const settlementModes = ['Cash', 'Physical_Spot', 'Physical_Forward'] as const;
export type SettlementMode = (typeof settlementModes)[number];
export const quoteContributionTypes = ['Automatic', 'Manual'] as const;
export type QuoteContributionType = (typeof quoteContributionTypes)[number];

export interface Possible<T> {
  default: T;
  possible: readonly T[];
}

export type BidAsk<T> = Record<BidAskKeys, T>;

export interface ICcyPair<T> {
  ccy1: T;
  ccy2: T;
}

export type BidAskPair = BidAsk<number>;

interface IStreamingBidAskPair<T> {
  Bid: T;
  Ask: T;
}

export type StreamingBidAskPair<T> = IStreamingBidAskPair<T>;

export interface PropertyError<T> {
  code: string;
  description?: string;
  faultyValue?: T | string;
  userNotified: boolean;
}

export type PropertyErrors<T> = {
  readonly [P in keyof T]?: Readonly<PropertyError<T[P]>>;
};

export interface PropertyWarning<T> {
  code: string;
  description?: string;
  faultyValue?: T | string;
  userNotified: boolean;
}

export type PropertyWarnings<T> = {
  readonly [P in keyof T]?: Readonly<PropertyWarning<T[P]>>;
};
