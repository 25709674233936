import type { AppState } from "../../model";
import type * as fxCashModel from "../fxCashsModel";
import { throwIfUndefined } from "../../../utils/maps";

export function getCashState(state: AppState, tileId: string): fxCashModel.FxCashState {
  return throwIfUndefined(
    state.fxCashs.cashs[tileId],
    `Cash state for tile ${tileId} does not exist`
  );
}
