import { UserHasAccess } from 'components/share';
import { MifidPanel, HelpRequest, UserPref } from './MenuButtons';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import en from './locales/en.json';
import fr from './locales/fr.json';
import { IsInternalSales } from 'components/share/UserType';

const messagesMap = { en, fr };

export function HeaderButtons() {
  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <IsInternalSales>
        <li className="nav-item btn-group">
          <MifidPanel />
        </li>
      </IsInternalSales>

      <li className="nav-item btn-group">
        <HelpRequest />
      </li>

      <UserHasAccess>
        <li className="nav-item btn-group">
          <UserPref />
        </li>
      </UserHasAccess>
    </IntlComponentBoundary>
  );
}
