import type { Reducer } from 'redux';
import { addKey, removeKey, updateKey } from 'utils/stateMap';
import type { Action } from 'state/actions';
import { addPriceRecorded } from 'state/share/priceRecordReducerHelper';
import { strictEntries } from 'utils/object/entries';
import { isDefined } from '@sgme/fp';
import { productPatcher } from 'state/share/patchHelper';
import { validationPartialReducer } from 'state/share/validationReducer';
import { shallowEqual } from 'utils/shallowEqual';
import type { DisplayPriceType, FxOptionState, FxOptionStateMap, OptionLegsOrientation } from '../model/optionProduct';
import { emptyFxOptionState, updateLegIdsWithNewTileId } from '../utilities';

const mapDisplayPriceType = (displayPriceType: DisplayPriceType | undefined) =>
  displayPriceType ? { displayPriceType } : undefined;

const emptyStateWithGrouping = (
  group: boolean | null,
  orientation: OptionLegsOrientation,
  defaultLegIds?: string[],
): FxOptionState => ({
  ...emptyFxOptionState,
  ...(defaultLegIds && { legIds: defaultLegIds }),
  group: group ?? false,
  orientation,
  expanded: [],
});

/**
 * Filter string[] to remove call/put leg and only return number
 * @param legKeys
 * @returns
 */
export const getLastNumberLastLegId = (legKeys: string[]): number => {
  const allIds = legKeys
    .map(legId => {
      const legIdValue = /\/([0-9]+)$/.exec(legId);
      return isDefined(legIdValue) ? Number(legIdValue[1]) : null;
    })
    .filter(isDefined);

  return Math.max(...allIds);
};

const optionValidationReducer = validationPartialReducer<'Option', FxOptionState>('Option');

export const optionsProductReducer: Reducer<FxOptionStateMap> = (
  state: FxOptionStateMap = {},
  action: Action,
): FxOptionStateMap => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      return action.savedTile.instrument === 'Option'
        ? addKey(
            state,
            action.tileId,
            emptyStateWithGrouping(
              action.isOptionGrouped,
              action.savedTile.orientation,
              updateLegIdsWithNewTileId(action.tileId, action.savedTile.legIds),
            ),
          )
        : state;

    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      // todo 4285 should we add legIds when calling emptyStateWithGrouping ? - when do we pass here for option ?
      return action.instrument === 'Option'
        ? addKey(
            state,
            action.tileId,
            emptyStateWithGrouping(action.isOptionGrouped, 'vertical', [`${action.tileId}/0`]),
          )
        : state;

    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
      if (action.instrument === 'Option') {
        const legIds = state[action.originalTileId]?.legIds.map(x => x);
        return addKey(state, action.tileId, emptyStateWithGrouping(action.isOptionGrouped, 'vertical', legIds));
      } else {
        return state;
      }

    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
      return action.instrument === 'Option'
        ? addKey(
            state,
            action.tileId,
            emptyStateWithGrouping(action.isOptionGrouped, 'vertical', [`${action.tileId}/0`]),
          )
        : removeKey(state, action.tileId);

    case 'CLIENTWORKSPACE_TILE_DELETED':
      return removeKey(state, action.tileId);

    case 'OPTION_TILE_RESET':
      return updateKey(state, action.quoteId, ({ isStrategy, legIds, orientation, displayPriceType, group }) => ({
        ...emptyFxOptionState,
        isStrategy,
        legIds: legIds.slice(0, 1),
        orientation,
        displayPriceType,
        group,
      }));

    case 'OPTION_PROPERTY_CHANGED':
      return updateKey(state, action.optionId, option => ({
        inputs: {
          ...option.inputs,
          ...action.patch,
        },
        lastStreamError: null,
      }));

    case 'OPTION_PROPERTY_REMOVE_ERROR':
      return updateKey(state, action.optionId, option => {
        const errors = strictEntries(option.errors)
          .filter(([errorKey]) => !action.keys.includes(errorKey))
          .reduce(
            (newErrors, [key, error]) => ({
              ...newErrors,
              [key]: error,
            }),
            {},
          );
        return {
          ...option,
          errors,
        };
      });
    case 'RECORD_PRICE_SUCCEEDED':
    case 'RECORD_PRICE_FAILED':
      if (action.instrument !== 'Option') {
        return state;
      }
      return updateKey(
        state,
        action.tileId,
        addPriceRecorded({
          priceRecord: {
            error: action.error,
            prices: action.error ? undefined : action.prices,
            timestamp: action.timestamp,
          },
        }),
      );
    case 'OPTION_DISPLAY_PRICE_TYPE_CHANGED':
      return updateKey(state, action.optionId, _ => ({
        displayPriceType: action.displayPriceType,
      }));

    case 'OPTION_LEG_CURRENCY_LINKED_TOGGLED':
      return updateKey(state, action.optionId, option => ({
        legsCurrencyLinked: !option.legsCurrencyLinked,
      }));

    case 'OPTION_PROPERTIES_REQUESTED':
      return updateKey(state, action.optionId, _ => ({
        propertiesRequested: true,
        currentSessionId: action.sessionId,
      }));

    case 'OPTION_PROPERTIES_RECEIVED': {
      return productPatcher(state, { ...action, patch: action.optionPatch }, oldOption => {
        const legIds = Object.keys(action.legsPatch);
        const fxOptionMultilegIds = legIds.filter(legId => /^[^/]+\/[^/]+$/.test(legId));
        const lastLegId = getLastNumberLastLegId(legIds);

        return {
          legIds: shallowEqual(oldOption.legIds, fxOptionMultilegIds) ? oldOption.legIds : fxOptionMultilegIds, // todo SGEFX 4282 do we still need the shallowEqual ?
          nextLegId: lastLegId + 1,
          ...(oldOption.group
            ? {
                expanded: [
                  ...oldOption.expanded,
                  ...Object.values(action.legsPatch)
                    // @TODO @SGME-4285 remove non vanilla productName ?
                    .map(({ values }) => values.expiryDate)
                    .filter(isDefined)
                    .filter(expiry => !oldOption.expanded.includes(expiry)),
                ],
              }
            : {}),
          ...mapDisplayPriceType(action.displayPriceType),
        };
      });
    }

    case 'OPTION_PROPERTIES_REQUEST_FAILED':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: false,
        propertiesRequestError: action.error,
      }));
    case 'OPTION_STRIPPING_FAILED':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: false,
      }));

    case 'OPTION_LEG_PROPERTY_CHANGED':
    case 'OPTION_LEGS_PROPERTY_CHANGED':
      return updateKey(state, action.optionId, () => ({
        lastStreamError: null,
      }));
    case 'OPTION_STRIKE_SOLVED':
      return updateKey(state, action.optionId, () => ({
        lastStreamError: null,
        displaySolveStrikeModal: false,
        wayStrikeModal: 'Bid',
      }));
    case 'FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Option'
        ? state
        : updateKey(state, action.quoteId, ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));
    case 'OPTION_STREAM_STARTED':
      return updateKey(state, action.optionId, () => ({
        priceRecords: [],
        rfsStartedAt: action.rfsStartedAt,
        currentStreamId: action.streamId,
        lastStreamError: null,
      }));

    case 'OPTION_STREAM_CANCELED':
    case 'OPTION_STREAM_TERMINATED':
      return updateKey(state, action.optionId, ({ currentStreamId }) => {
        if (currentStreamId !== action.streamId) {
          return null;
        }
        return {
          currentStreamId: null,
          lastStreamId: action.shouldKeepAsExpired ? currentStreamId : null,
          lastStreamError: null,
        };
      });
    case 'OPTION_STREAM_FAILED':
      return updateKey(state, action.optionId, () => ({
        currentStreamId: null,
        lastStreamId: null,
        lastStreamError: action.error,
      }));

    case 'OPTION_RFS_CLEAR_ERROR':
      return updateKey(state, action.quoteId, () => ({
        currentStreamId: null,
        lastStreamError: null,
      }));

    case 'OPTION_EXECUTION_SENT':
      return updateKey(state, action.optionId, ({ currentStreamId }) => ({
        lastExecutedQuoteId: action.quoteId,
        currentExecutionId: action.executionId,
        currentStreamId: null,
        lastStreamId: currentStreamId,
        lastStreamError: null,
        warnings: {},
      }));
    case 'OPTION_STREAM_LAST_REMOVED':
      return updateKey(state, action.optionId, () => ({
        lastStreamId: null,
      }));
    case 'TILE_EXECUTION_OVERLAY_HIDDEN':
      return updateKey(state, action.quoteId, () => ({
        currentExecutionId: null,
      }));

    case 'OPTION_TOGGLE_STRATEGY':
      return updateKey(state, action.optionId, () => ({
        isStrategy: action.isStrategy,
      }));

    case 'OPTION_STRATEGY_TYPE_CHANGED':
      return updateKey(state, action.optionId, ({ values }) => ({
        values: {
          ...values,
          hedgeType: emptyFxOptionState.values.hedgeType,
        },
      }));

    case 'OPTION_TOGGLE_GROUP':
      return updateKey(state, action.quoteId, () => ({ group: action.isGroup }));
    case 'OPTION_TOGGLE_EXPAND':
      return updateKey(state, action.quoteId, metadata => {
        if (metadata.expanded.includes(action.expiry)) {
          return { expanded: metadata.expanded.filter(expiry => expiry !== action.expiry) };
        } else {
          return { expanded: [...metadata.expanded, action.expiry] };
        }
      });
    case 'OPTION_MASS_EXPAND':
      return updateKey(state, action.quoteId, metadata => {
        if (!metadata.group) {
          return {};
        }
        return { expanded: action.expiries };
      });
    case 'OPTION_LEG_ADDED':
    case 'OPTION_LEG_REMOVED':
      return updateKey(state, action.optionId, () => ({
        propertiesRequested: true,
      }));
    case 'ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamId,
      }));
    case 'ESP_STREAM_RECONNECTED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamKey,
      }));
    case 'ESP_STREAM_TILE_UNSUBSCRIBE':
    case 'ESP_STREAM_KEY_REQUEST_PENDING':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: null,
      }));
    case 'OPTION_ORIENTATION_TOGGLED':
      return updateKey(state, action.tileId, () => ({
        orientation: action.orientation,
      }));
    case 'TOGGLE_SOLVE_STRIKE_MODAL':
      return updateKey(state, action.quoteId, () => ({
        displaySolveStrikeModal: action.display,
        wayStrikeModal: 'Bid',
      }));
    default:
      return optionValidationReducer(state, action);
  }
};
