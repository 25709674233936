import { getOptionVanillaLegValue } from './factories';

export const getOptionLegReadyToPrice = getOptionVanillaLegValue('isReadyToPrice');
export const getOptionLegPossibleSettlementTypes =
  getOptionVanillaLegValue('possibleSettlementTypes');
export const getOptionLegPossibleCashSettlementCurrencies = getOptionVanillaLegValue(
  'possibleCashSettlementCurrencies',
);
export const getOptionLegPossibleFixingReferences1 = getOptionVanillaLegValue(
  'possibleFixingReferences1',
);
export const getOptionLegCurrency1 = getOptionVanillaLegValue('currency1');
export const getOptionLegCurrency2 = getOptionVanillaLegValue('currency2');
export const getOptionLegPossibleMarketPlaces = getOptionVanillaLegValue('possibleMarketPlaces');
export const getOptionLegIsStrikeInDelta = getOptionVanillaLegValue('isStrikeInDelta');
export const getOptionLegSolvable = getOptionVanillaLegValue('solvable');
