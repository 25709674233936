import { FormattedMessage } from 'react-intl';
import { lazyWithRetry } from 'components/share/lazy';
import { MenuButton } from './MenuButton';
import { useToggle } from 'components/share/hooks/useToggle';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import en from 'components/Modals/locales/en.json';
import fr from 'components/Modals/locales/fr.json';
import { Suspense } from 'react';

const messagesMap = { en, fr };

const HelpRequestModal = lazyWithRetry(() => import('components/Modals/HelpRequestModal'));

const tooltip = <FormattedMessage id="navMenuButtons.helpRequest" />;

export function HelpRequestButton(): JSX.Element {
  const [isOpen, toggle, close] = useToggle();
  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <MenuButton e2eHandle="help-request" onClick={toggle} tooltip={tooltip}>
        <i className="icon icon-md">feedback</i>
      </MenuButton>
      {isOpen && (
        <Suspense fallback={null}>
          <HelpRequestModal isOpen={isOpen} toggle={toggle} close={close} shouldTakeScreenshot />
        </Suspense>
      )}
    </IntlComponentBoundary>
  );
}
