import { getSharedProperty, getValueProperty, type IFormData, type SharedKey, type ValueKey } from 'state/share/form';
import type {
  BlotterOrderAlgoInputs,
  BlotterOrderAlgoMetadata,
  BlotterOrderAlgoPropertiesWithMetaData,
  BlotterOrderAlgoValues,
  BlotterOrderCommonInputs,
  BlotterOrderCommonValues,
  BlotterOrderLimitInputs,
  BlotterOrderLimitValues,
} from '../blotterEntryModel';
import type { AppState } from 'state/model';
import { getBlotterEditedOrderById, getBlotterOrderById } from './blotterSelectors';
import { createSelector } from 'reselect';
import { fieldData } from '../../../utils/fieldSelectors';

export function getBlotterOrderInput<T extends SharedKey<BlotterOrderCommonValues, BlotterOrderCommonInputs>>(prop: T) {
  const get = getSharedProperty<BlotterOrderCommonValues, BlotterOrderCommonInputs, T>(prop);
  return (state: AppState, orderId: string) => get(getBlotterOrderById(state, orderId));
}

export function getBlotterOrderValue<T extends ValueKey<BlotterOrderCommonValues, BlotterOrderCommonInputs>>(prop: T) {
  const get = getValueProperty<BlotterOrderCommonValues, BlotterOrderCommonInputs, T>(prop);
  return (state: AppState, orderId: string) => get(getBlotterOrderById(state, orderId));
}

export function getBlotterOrderLimitInput<T extends SharedKey<BlotterOrderLimitValues, BlotterOrderLimitInputs>>(
  prop: T,
) {
  const get = getSharedProperty<BlotterOrderLimitValues, BlotterOrderLimitInputs, T>(prop);
  return (state: AppState, orderId: string) =>
    get(getBlotterOrderById(state, orderId) as IFormData<BlotterOrderLimitValues, BlotterOrderLimitInputs>);
}

export function getBlotterOrderLimitValue<T extends ValueKey<BlotterOrderLimitValues, BlotterOrderLimitInputs>>(
  prop: T,
) {
  const get = getValueProperty<BlotterOrderLimitValues, BlotterOrderLimitInputs, T>(prop);
  return (state: AppState, orderId: string) =>
    get(getBlotterOrderById(state, orderId) as IFormData<BlotterOrderLimitValues, BlotterOrderLimitInputs>);
}

export function getBlotterEditedOrderLimitValue<T extends ValueKey<BlotterOrderLimitValues, BlotterOrderLimitInputs>>(
  prop: T,
) {
  const get = getValueProperty<BlotterOrderLimitValues, BlotterOrderLimitInputs, T>(prop);
  return (state: AppState, orderId: string) =>
    get(getBlotterEditedOrderById(state, orderId) as IFormData<BlotterOrderLimitValues, BlotterOrderLimitInputs>);
}

export function getBlotterOrderAlgoInput<T extends SharedKey<BlotterOrderAlgoValues, BlotterOrderAlgoInputs>>(prop: T) {
  const get = getSharedProperty<BlotterOrderAlgoValues, BlotterOrderAlgoInputs, T>(prop);
  return (state: AppState, orderId: string) =>
    get(getBlotterOrderById(state, orderId) as IFormData<BlotterOrderAlgoValues, BlotterOrderAlgoInputs>);
}

export function getBlotterEditedOrderAlgoInput<T extends SharedKey<BlotterOrderAlgoValues, BlotterOrderAlgoInputs>>(
  prop: T,
) {
  const get = getSharedProperty<BlotterOrderAlgoValues, BlotterOrderAlgoInputs, T>(prop);
  return (state: AppState, orderId: string) =>
    get(getBlotterEditedOrderById(state, orderId) as IFormData<BlotterOrderAlgoValues, BlotterOrderAlgoInputs>);
}

export function getBlotterOrderAlgoValue<T extends ValueKey<BlotterOrderAlgoValues, BlotterOrderAlgoInputs>>(prop: T) {
  const get = getValueProperty<BlotterOrderAlgoValues, BlotterOrderAlgoInputs, T>(prop);
  return (state: AppState, orderId: string) =>
    get(getBlotterOrderById(state, orderId) as IFormData<BlotterOrderAlgoValues, BlotterOrderAlgoInputs>);
}

export function getBlotterOrderAlgoMetadata<T extends keyof BlotterOrderAlgoMetadata>(prop: T) {
  return (state: AppState, orderId: string) =>
    (getBlotterOrderById(state, orderId) as BlotterOrderAlgoPropertiesWithMetaData)[prop];
}
export function getBlotterOrderIsModified(state: AppState, orderId: string) {
  const inputs = getBlotterOrderById(state, orderId).inputs;
  return Object.keys(inputs).length !== 0;
}
export function getBlotterOrderIsValid(state: AppState, orderId: string) {
  const errors = getBlotterOrderById(state, orderId).errors;
  return Object.keys(errors).length === 0;
}

export const getBlotterOrderProduct = getBlotterOrderInput('product');
export const getBlotterOrderStatus = getBlotterOrderValue('status');
export const getBlotterOrderCurrencyPair = getBlotterOrderValue('currencyPair');
export const getBlotterOrderNotionalCurrency = getBlotterOrderValue('notionalCurrency');
export const getBlotterOrderIsGtc = getBlotterOrderInput('isGtc');
export const getBlotterOrderExpiryDay = getBlotterOrderValue('expiryDay');
export const getBlotterOrderExpiryTime = getBlotterOrderValue('expiryTime');
export const getBlotterOrderWay = getBlotterOrderValue('way');
export const getBlotterOrderAccount = getBlotterOrderValue('account');
export const getBlotterOrderNotionalInput = getBlotterOrderInput('notional');
export const getBlotterOrderExecutedNotional = getBlotterOrderValue('executedNotional');
export const getBlotterOrderLimitPriceInput = getBlotterOrderLimitInput('limitPrice');
export const getBlotterOrderCustomerPriceInput = getBlotterOrderLimitInput('customerPrice');
export const getBlotterOrderTakeProfitMarginInput = getBlotterOrderLimitInput('margin');
export const getBlotterOrderNoWorseThan = getBlotterOrderAlgoInput('noWorseThan');
export const getBlotterOrderStartDate = getBlotterOrderAlgoInput('startDate');
export const getBlotterOrderStartTime = getBlotterOrderAlgoInput('startTime');
export const getBlotterOrderEndDate = getBlotterOrderAlgoInput('endDate');
export const getBlotterOrderEndTime = getBlotterOrderAlgoInput('endTime');
export const getBlotterOrderClippingMode = getBlotterOrderAlgoInput('clippingMode');
export const getBlotterOrderClipSize = getBlotterOrderAlgoInput('clipSize');
export const getBlotterOrderRandomize = getBlotterOrderAlgoInput('randomize');
export const getBlotterOrderSpreadCapture = getBlotterOrderAlgoInput('spreadCapture');
export const getBlotterOrderAlphaSeeker = getBlotterOrderAlgoInput('alphaSeeker');
export const getBlotterOrderSpeed = getBlotterOrderAlgoInput('speed');
export const getBlotterOrderLiquidityPool = getBlotterOrderAlgoInput('liquidityPool');
export const getBlotterOrderMarginInBpsInput = getBlotterOrderLimitInput('marginInBps');
export const getBlotterOrderTakeProfitMarginEditableInput = getBlotterOrderLimitInput('margin');
export const getBlotterOrderFixingMarginTypeInput = getBlotterOrderLimitInput('fixingMarginType');
export const getBlotterOrderFixingBenchmark = getBlotterOrderLimitInput('fixingBenchmark');
export const getBlotterOrderFixingDateUtc = getBlotterOrderLimitValue('fixingDateUtc');
export const getBlotterOrderFixingPriceTypeInput = getBlotterOrderLimitInput('fixingPriceType');
export const getBlotterOrderFixingPlace = getBlotterOrderLimitInput('fixingPlace');
export const getBlotterOrderFixingTime = getBlotterOrderLimitInput('fixingTime');
export const getBlotterOrderTogglePausePending = getBlotterOrderAlgoMetadata('togglePausePending');
export const getBlotterOrderFillNowPending = getBlotterOrderAlgoMetadata('fillNowPending');
export const getBlotterOrderStreamId = getBlotterOrderAlgoMetadata('currentEspStreamId');
export const getBlotterOrderIsReadyToSubmit = getBlotterOrderValue('isReadyToSubmit');
export const getBlotterOrderMode = (state: AppState, orderId: string) => getBlotterOrderById(state, orderId).mode;

// EDITED ORDERS SELECTORS //
export function getBlotterEditedOrderInput<T extends SharedKey<BlotterOrderCommonValues, BlotterOrderCommonInputs>>(
  prop: T,
) {
  const get = getSharedProperty<BlotterOrderCommonValues, BlotterOrderCommonInputs, T>(prop);
  return (state: AppState, orderId: string) => get(getBlotterEditedOrderById(state, orderId));
}
export function getBlotterEditedOrderValue<T extends ValueKey<BlotterOrderCommonValues, BlotterOrderCommonInputs>>(
  prop: T,
) {
  const get = getValueProperty<BlotterOrderCommonValues, BlotterOrderCommonInputs, T>(prop);
  return (state: AppState, orderId: string) => get(getBlotterEditedOrderById(state, orderId));
}
export function getBlotterEditedOrderLimitInput<T extends SharedKey<BlotterOrderLimitValues, BlotterOrderLimitInputs>>(
  prop: T,
) {
  const get = getSharedProperty<BlotterOrderLimitValues, BlotterOrderLimitInputs, T>(prop);
  return (state: AppState, orderId: string) =>
    get(getBlotterEditedOrderById(state, orderId) as IFormData<BlotterOrderLimitValues, BlotterOrderLimitInputs>);
}

export const getBlotterEditedOrderProduct = getBlotterEditedOrderInput('product');
export const getBlotterEditedOrderNotionalInput = getBlotterEditedOrderInput('notional');
export const getBlotterEditedOrderNotionalCurrency = getBlotterEditedOrderValue('notionalCurrency');
export const getBlotterEditedOrderCurrencyPair = getBlotterEditedOrderValue('currencyPair');
export const getBlotterEditedOrderWay = getBlotterEditedOrderValue('way');
export const getBlotterEditedOrderExpiryDay = getBlotterEditedOrderValue('expiryDay');
export const getBlotterEditedOrderExpiryTime = getBlotterEditedOrderValue('expiryTime');
export const getBlotterEditedOrderFixingDateUtc = getBlotterEditedOrderLimitValue('fixingDateUtc');
export const getBlotterEditedOrderFixingBenchmark = getBlotterEditedOrderLimitInput('fixingBenchmark');
export const getBlotterEditedOrderFixingTime = getBlotterEditedOrderLimitInput('fixingTime');
export const getBlotterEditedOrderFixingPlace = getBlotterEditedOrderLimitInput('fixingPlace');
export const getBlotterEditedOrderLimitPriceInput = getBlotterEditedOrderLimitInput('limitPrice');
export const getBlotterEditedOrderCustomerPriceInput = getBlotterEditedOrderLimitInput('customerPrice');
export const getBlotterEditedOrderTakeProfitMarginInput = getBlotterEditedOrderLimitInput('margin');
export const getBlotterEditedOrderFixingMarginTypeInput = getBlotterEditedOrderLimitInput('fixingMarginType');
export const getBlotterEditedOrderFixingPriceTypeInput = getBlotterEditedOrderLimitInput('fixingPriceType');
export const getBlotterEditedOrderMarginInBpsInput = getBlotterEditedOrderLimitInput('marginInBps');
export const getBlotterEditedOrderStartDate = getBlotterEditedOrderAlgoInput('startDate');
export const getBlotterEditedOrderStartTime = getBlotterEditedOrderAlgoInput('startTime');
export const getBlotterEditedOrderEndDate = getBlotterEditedOrderAlgoInput('endDate');
export const getBlotterEditedOrderEndTime = getBlotterEditedOrderAlgoInput('endTime');
export const getBlotterEditedOrderIsGtc = getBlotterEditedOrderInput('isGtc');
export const getBlotterEditedOrderNoWorseThan = getBlotterEditedOrderAlgoInput('noWorseThan');
export const getBlotterEditedOrderSpeed = getBlotterEditedOrderAlgoInput('speed');
export const getBlotterEditedOrderAlphaSeeker = getBlotterEditedOrderAlgoInput('alphaSeeker');
export const getBlotterEditedOrderClippingModeInput = getBlotterEditedOrderAlgoInput('clippingMode');
export const getBlotterEditedOrderClipSizeInput = getBlotterEditedOrderAlgoInput('clipSize');
export const getBlotterEditedOrderRandomize = getBlotterEditedOrderAlgoInput('randomize');
export const getBlotterEditedOrderSpreadCapture = getBlotterEditedOrderAlgoInput('spreadCapture');

export function getBlotterEditedOrderIsModified(state: AppState, orderId: string) {
  const inputs = getBlotterEditedOrderById(state, orderId).inputs;
  return Object.keys(inputs).length !== 0;
}
export function getBlotterEditedOrderIsValid(state: AppState, orderId: string) {
  const errors = getBlotterEditedOrderById(state, orderId).errors;
  return Object.keys(errors).length === 0;
}

export const getBlotteEditedrOrderIsReadyToSubmit = (state: AppState, orderId: string) =>
  getBlotterEditedOrderById(state, orderId).isReadyToSubmit;

export const getBlotterEditedOrderNotional = (quoteId: string) =>
  createSelector([(state: AppState) => getBlotterEditedOrderNotionalInput(state, quoteId)], notionalInput =>
    fieldData(notionalInput).data?.toString(),
  );

export const getBlotterEditedOrderClippingMode = (quoteId: string) =>
  createSelector(
    [(state: AppState) => getBlotterEditedOrderClippingModeInput(state, quoteId)],
    clippingMode => fieldData(clippingMode).data,
  );

export const getBlotterEditedOrderClipSize = (quoteId: string) =>
  createSelector(
    [(state: AppState) => getBlotterEditedOrderClipSizeInput(state, quoteId)],
    clipSize => fieldData(clipSize).data,
  );

function getUserInfo(state: AppState) {
  return state.referenceData.userInfo;
}
function isUserInternalSales(state: AppState) {
  return getUserInfo(state).userType === 'Internal Sales';
}
export const getBlotterEditedOrderLimitPrice = (quoteId: string) =>
  createSelector(
    [
      (state: AppState) => isUserInternalSales(state),
      (state: AppState) => getBlotterEditedOrderProduct(state, quoteId),
      (state: AppState) => getBlotterEditedOrderLimitPriceInput(state, quoteId),
      (state: AppState) => getBlotterEditedOrderCustomerPriceInput(state, quoteId),
    ],
    (isInternalSales, orderProduct, limitPriceInput, customerPriceInput) => {
      const isTakeProfit = fieldData(orderProduct).data === 'TakeProfit';
      const limitPriceInputValue = fieldData(limitPriceInput).data;
      const customerPriceInputValue = fieldData(customerPriceInput).data;

      return isTakeProfit && !isInternalSales
        ? customerPriceInputValue?.toString() ?? ''
        : limitPriceInputValue?.toString() ?? '';
    },
  );

export const getBlotterEditedOrderCustomerPrice = (quoteId: string) =>
  createSelector([(state: AppState) => getBlotterEditedOrderCustomerPriceInput(state, quoteId)], customerPriceInput =>
    fieldData(customerPriceInput).data?.toString(),
  );

export const getBlotterEditedOrderTakeProfitMargin = (quoteId: string) =>
  createSelector(
    [(state: AppState) => getBlotterEditedOrderTakeProfitMarginInput(state, quoteId)],
    takeProfitMarginInput => fieldData(takeProfitMarginInput).data?.toString(),
  );
