import { type FocusEvent, type MutableRefObject, type RefCallback, useCallback, useContext, useEffect } from 'react';
import { FieldTooltip } from 'components/Tooltip';
import { CurrencyPickerContext } from './CurrencyPickerContext';
import { ccyPairExtendedMessageValues } from './extendedCurrencyWarning';
import * as e2e from 'components/Form/Inputs/e2e';
import { QuoteIdContext, withQuoteId } from 'components/contexts';
import type { AlertLevel, Size } from 'components/Form/Inputs/typings';
import { inputClassName } from 'components/Form/Inputs/className';
import type { CurrencyPair } from 'state/referenceData';
import { connectCurrencyPicker } from './connectCurrencyPicker';
import { useDispatch } from 'react-redux';
import { localFieldValidationClear } from 'state/globalActions';
import { useInstrumentAllowingCurrency } from '../share/hooks/useInstrumentAllowingCurrency';
import styled from 'styled-components';

export interface CurrencyPickerProps {
  currencyPair?: CurrencyPair;
  size?: Size;
  additionalClass?: string;
}

function CurrencyPickerRaw({ currencyPair, size, additionalClass }: CurrencyPickerProps): JSX.Element | null {
  const instrument = useInstrumentAllowingCurrency();
  const { inputRef } = useContext(CurrencyPickerContext);

  // the currency <input> field is uncontrolled => its internal state is managed by the DOM
  // when the currencyPair changes, React will not always re-render the <input>
  // the only way to change the internal value is to use the inputRef 🥺
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = currencyPair?.pair ?? '';
    }
  }, [inputRef, currencyPair]);

  return instrument === undefined ? null : (
    <FieldTooltip
      instrument={instrument}
      field="currencyPair"
      messageValues={ccyPairExtendedMessageValues(currencyPair)}
    >
      {({ ref, alertLevel }) => (
        <CcyInput
          initialValue={currencyPair?.pair ?? ''}
          alertLevel={alertLevel}
          size={size}
          setTooltipTargetRef={ref}
          inputRef={inputRef}
          additionalClass={additionalClass}
        />
      )}
    </FieldTooltip>
  );
}

export const CurrencyPicker = withQuoteId(connectCurrencyPicker(CurrencyPickerRaw));

CurrencyPicker.displayName = 'CurrencyPicker';

interface CcyInputProps {
  initialValue: string;
  alertLevel?: AlertLevel;
  size?: Size;
  setTooltipTargetRef: RefCallback<HTMLInputElement>;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  additionalClass?: string;
}

function CcyInput({
  initialValue,
  alertLevel,
  size,
  inputRef,
  setTooltipTargetRef,
  additionalClass,
}: CcyInputProps): JSX.Element {
  const combinedRef = useCallback(
    (input: HTMLInputElement) => {
      setTooltipTargetRef(input);
      inputRef.current = input;
    },
    [setTooltipTargetRef, inputRef],
  );

  const instrument = useInstrumentAllowingCurrency();
  const quoteId = useContext(QuoteIdContext);
  const dispatch = useDispatch();

  const onInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.currentTarget.select();
  };

  const onInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (initialValue !== event.currentTarget.value) {
      dispatch(localFieldValidationClear(instrument!, quoteId, 'currencyPair'));
    }
  };

  const inputClasses = ['fw-medium text-center text-uppercase', inputClassName(alertLevel, size), additionalClass]
    .filter(Boolean)
    .join(' ');

  return (
    <StyledInput
      type="text"
      className={inputClasses}
      defaultValue={initialValue}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      autoComplete="off"
      data-e2e={e2e.selector('currency')}
      data-e2e-level={e2e.inputErrorLevel(alertLevel)}
      ref={combinedRef}
    />
  );
}

// TODO: is it a good solution ? try to remove the custom style
const StyledInput = styled.input`
  width: 78px;
`;
