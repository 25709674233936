import { getBulkLegInput } from './factories';

export type { BulkLegSharedPropertyStatus } from './factories';

export const getBulkLegProduct = getBulkLegInput('product');
export const getBulkLegIsUneven = getBulkLegInput('isUneven');
export const getBulkLegWay = getBulkLegInput('way');
export const getBulkLegAmount = getBulkLegInput('amount');
export const getBulkLegFarAmount = getBulkLegInput('farAmount');
export const getBulkLegPaymentDate = getBulkLegInput('paymentDate');
export const getBulkLegPaymentDateTenor = getBulkLegInput('paymentDateTenor');
export const getBulkLegFarPaymentDate = getBulkLegInput('farPaymentDate');
export const getBulkLegFarPaymentDateTenor = getBulkLegInput('farPaymentDateTenor');
