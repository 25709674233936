import { columnsDefinition, type ColumnId } from './model/blotterModel';
import { assertUnreachable } from '@sgme/fp';
import { composeLeftFunction } from 'utils/composer';
import type { Updater } from '../typings';
import type { SavedWorkspace as SavedWorkspace1 } from '../updater1/model';
import type { SavedWorkspace as SavedWorkspace2 } from './model';

const currentVersion = 2;

export const updater2: Updater<SavedWorkspace1, SavedWorkspace2> = {
  version: currentVersion,
  canUpdate(data: any) {
    return data.version === currentVersion - 1;
  },
  update: (previousWorkspace: SavedWorkspace1) => {
    const operations = composeLeftFunction(
      removeBlotterColumn('cash', ['notional', 'nearNotional', 'farNotional', 'notionalCurrency']),
      removeBlotterColumn('option', [
        'notional',
        'nearNotional',
        'farNotional',
        'notionalCurrency',
      ]),
      addBlotterColumn('cash', [
        'electronicAccount',
        'dealtAmount',
        'farDealtAmount',
        'dealtCurrency',
        'contraAmount',
        'farContraAmount',
        'contraCurrency',
      ]),
      addBlotterColumn('option', [
        'electronicAccount',
        'dealtAmount',
        'dealtCurrency',
        'contraAmount',
        'contraCurrency',
        'optionType',
      ]),
    );

    const savedWorkspace: SavedWorkspace2 = operations(previousWorkspace);
    savedWorkspace.version = 2;

    return savedWorkspace;
  },
};

const removeBlotterColumn =
  (blotterTab: BlotterTab, columnIds: readonly string[]): ((savedWorkspace: any) => any) =>
  savedWorkspace => {
    const tabToModify = getSavedTab(blotterTab);
    const blotterStateToModify = savedWorkspace.blotter[tabToModify];

    const newWorkspace = {
      ...savedWorkspace,
      blotter: {
        ...savedWorkspace.blotter,
        [tabToModify]: {
          ...blotterStateToModify,
          columnsState: blotterStateToModify.columnsState.filter(
            (column: { colId: string }) => !columnIds.includes(column.colId),
          ),
        },
      },
    };

    return newWorkspace;
  };

const addBlotterColumn =
  (blotterTab: BlotterTab, columnIds: readonly ColumnId[]): ((savedWorkspace: any) => any | null) =>
  savedWorkspace => {
    const tabToModify = getSavedTab(blotterTab);

    return {
      ...savedWorkspace,
      blotter: {
        ...savedWorkspace.blotter,
        [tabToModify]: {
          ...savedWorkspace.blotter[tabToModify],
          columnsState: columnIds.reduce(
            (previousColumnState, columnId) =>
              previousColumnState.some((col: { colId: string }) => col.colId === columnId)
                ? previousColumnState
                : [...previousColumnState, columnsDefinition[columnId]],
            savedWorkspace.blotter[tabToModify].columnsState,
          ),
        },
      },
    };
  };

type BlotterTab = 'cash' | 'option' | 'order' | 'accumulator';

function getSavedTab(
  blotterTab: BlotterTab,
): 'cashTab' | 'optionTab' | 'orderTab' | 'accumulatorTab' {
  switch (blotterTab) {
    case 'cash':
      return 'cashTab';
    case 'option':
      return 'optionTab';
    case 'order':
      return 'orderTab';
    case 'accumulator':
      return 'accumulatorTab';
    default:
      assertUnreachable(blotterTab, 'One tab definition is missing');
  }
}
