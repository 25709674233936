import { Suspense } from 'react';
import { CurrencyPickerProvider } from 'components/CurrencyPicker';
import { AutoInstrumentProvider } from 'components/contexts/AutoInstrumentProvider';
import { ExecutionStatus } from '../share/ExecutionStatus';
import { TradeCaptureSpinner } from '../share/TradeCaptureSpinner';
import { TileCatchError } from "./TileCatchError";
import { TileStatus } from "./TileStatus";
import { TileEmpty } from "./TileEmpty";
import { TileSizeMonitor } from "./TileSizeMonitor";
import { TileInstrument } from "./TileInstrument";

export function TileRoot() {
  return (
    <TileStatus error>
      <TileCatchError>
        <AutoInstrumentProvider>
          <CurrencyPickerProvider>
            <Suspense
              fallback={
                <TileEmpty>
                  <div className="h-100 w-100 d-flex justify-content-around align-items-center">
                    <div className="spinner spinner-xl"></div>
                  </div>
                </TileEmpty>
              }
            >
              <TileStatus empty>
                <TileSizeMonitor>
                  <TradeCaptureSpinner />
                  <ExecutionStatus />
                  <TileInstrument />
                </TileSizeMonitor>
              </TileStatus>
            </Suspense>
          </CurrencyPickerProvider>
        </AutoInstrumentProvider>
      </TileCatchError>
    </TileStatus>
  );
}

TileRoot.displayName = 'TileRoot';
