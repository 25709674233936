import type { AlertLevel, Size, VisualEmphasis } from './typings';



const formControlClassBySize: Record<Size, string> = {
  default: 'form-control',
  sm: 'form-control form-control-sm',
  lg: 'form-control form-control-lg',
  md: 'form-control form-control-md'
};
export const formControlClassName = (size: Size = 'default') => formControlClassBySize[size];
/**
 * bs4 lookalike semantic
 */
const levelClass: Record<AlertLevel, string> = {
  warning: ' is-warning',
  invalid: ' is-invalid',
  valid: ' is-valid',
};

export const inputClassName = (alertLevel?: AlertLevel, size?: Size) => {
  const alertClasses = alertLevel === undefined ? '' : levelClass[alertLevel]
  // Filter with boolean allow no extra escape if empty string
  return ['px-4px', formControlClassName(size), alertClasses].filter(Boolean).join(' ');
};

/**
 * update if several button styles are required
 * // @todo very complicated for nothin.... 😣
 */
const buttonEmphasisClass: Record<VisualEmphasis, string> = {
  none: 'btn btn-discreet-primary btn-sm',
  table: 'btn btn-flat-primary border-start',
  readonly: 'btn btn-default form-control form-control-md border-0 h-100 py-0 text-secondary',
  default: 'btn btn-default form-control form-control-md border-0 h-100 py-0',
  warning: 'btn btn-warning btn-sm',
  error: 'btn btn-discreet-danger',
  success: 'btn btn-discreet-success',
  info: 'btn btn-discreet-info',
};
// px-1 btn btn-flat-primary btn-md border-start
export const buttonClassName = (emphasis: VisualEmphasis = 'none') =>
  `px-1 ${buttonEmphasisClass[emphasis]}`;

export const buttonIconClassName = `icon-btn d-flex align-items-center justify-content-center`;
