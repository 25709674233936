import type { Action } from 'state/actions';
import type { Reducer } from 'redux';

import type {
  FxOptionHedgePatchedValues,
  FxOptionHedgesPatchedValuesRecords,
  FxOptionHedgeState,
  FxOptionHedgeStateMap,
} from '../model/optionHedges';
import { FIRST_CURRENCY } from 'state/share/productModel/litterals';
import { addKey, removeKey, removeKeys, updateKey } from '../../../utils/stateMap';
import { strictEntries } from '../../../utils/object';
import type { Collection } from '../../../typings/utils';
import { isDefined } from '@sgme/fp';

export const optionsHedgesReducer: Reducer<FxOptionHedgeStateMap> = (
  state: FxOptionHedgeStateMap = {},
  action: Action,
): FxOptionHedgeStateMap => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      if (action.savedTile.instrument === 'Option') {
        const tileId = action.tileId;

        return Object.entries(action.savedTile.hedges).reduce(
          (acc, [hedgeId, hedge]) => {
            acc[`${tileId}/${hedgeId}`] = {
              values: {
                amount: hedge?.amount ?? null,
                currency: hedge?.currency ?? FIRST_CURRENCY,
                rate: hedge?.rate ?? null,
                paymentDate: null,
              },
              inputs: {},
              errors: {},
              warnings: {},
            };

            return acc;
          },
          { ...state },
        );
      } else {
        return state;
      }

    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
    case 'CLIENTWORKSPACE_TILE_REOPENED':
      return state;

    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
      if (action.instrument === 'Option') {
        return getHedgeKeysForOption(state, action.originalTileId).reduce(
          (stateAcc, currentHedgeKey) => {
            const originalHedge = state[currentHedgeKey];

            if (!isDefined(originalHedge)) {
              return state;
            }

            const currentHedgeId = currentHedgeKey.replace(
              `${action.originalTileId}/`,
              `${action.tileId}/`,
            );

            return addKey(stateAcc, currentHedgeId, {
              values: originalHedge.values,
              inputs: {},
              errors: {},
              warnings: {},
            });
          },
          state,
        );
      } else {
        return state;
      }
    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
      return action.instrument === 'Option'
        ? state
        : removeKeys(state, getHedgeKeysForOption(state, action.tileId));

    case 'CLIENTWORKSPACE_TILE_DELETED':
      return removeKeys(state, getHedgeKeysForOption(state, action.tileId));

    case 'OPTION_TILE_RESET':
      return removeKeys(state, getHedgeKeysForOption(state, action.quoteId));

    case 'HEDGE_RESET':
      return removeKeys(state, getHedgeKeysForOption(state, action.quoteId));

    case 'OPTION_PROPERTIES_RECEIVED':
      return patchHedgeFromOption(state, action.quoteId, action.hedgesPatch);

    case 'OPTION_HEDGE_PROPERTY_CHANGED':
      return updateKey(state, action.hedgeId, hedge => ({
        inputs: {
          ...hedge.inputs,
          ...action.patch,
        },
      }));

    case 'OPTION_HEDGE_PROPERTY_REMOVE_ERROR':
      return updateKey(state, action.hedgeId, hedge => {
        const errors = strictEntries(hedge.errors)
          .filter(([errorKey]) => !(action.keys as string[]).includes(errorKey))
          .reduce(
            (newErrors, [key, error]) => ({
              ...newErrors,
              [key]: error,
            }),
            {},
          );

        return {
          ...hedge,
          errors,
        };
      });

    case 'HEDGE_FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Option'
        ? state
        : updateKey(state, `${action.quoteId}/${action.hedgeId}`, ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));

    case 'LOCAL_HEDGE_FIELD_VALIDATION_SET':
      return action.instrument !== 'Option'
        ? state
        : updateKey(state, `${action.quoteId}/${action.hedgeId}`, ({ errors, warnings }) => {
            const validation = {
              code: action.messageId,
              userNotified: false,
            };
            return action.validationStatus === 'warning'
              ? { warnings: { ...warnings, [action.field]: validation } }
              : { errors: { ...errors, [action.field]: validation } };
          });

    case 'LOCAL_HEDGE_FIELD_VALIDATION_CLEAR':
      return action.instrument !== 'Option'
        ? state
        : updateKey(state, `${action.quoteId}/${action.hedgeId}`, ({ errors, warnings }) => ({
            errors: removeKey(errors, action.field),
            warnings: removeKey(warnings, action.field),
          }));

    default:
      return state;
  }
};

const patchHedgeFromOption = (
  state: FxOptionHedgeStateMap,
  optionId: string,
  hedgePatch: FxOptionHedgesPatchedValuesRecords,
): FxOptionHedgeStateMap => {
  if (!hasOptionHedgeUpdate(hedgePatch)) {
    return state;
  }

  return Object.entries(hedgePatch).reduce(
    patchHedge(state),
    removeKeys(state, getHedgeKeysForOption(state, optionId)),
  ) satisfies FxOptionHedgeStateMap;
};

const patchHedge =
  (previousState: FxOptionHedgeStateMap) =>
  (
    newState: Collection<FxOptionHedgeState>,
    [hedgeId, hedgePatch]: [string, FxOptionHedgePatchedValues],
  ): Collection<FxOptionHedgeState> => {
    const oldValues = previousState[hedgeId]?.values;
    const newValues = hedgePatch.values;

    newState[hedgeId] = {
      values: {
        amount: mergeValuesOrDefault(newValues.amount, oldValues?.amount) ?? null,
        currency: mergeValuesOrDefault(newValues.currency, oldValues?.currency) ?? FIRST_CURRENCY,
        rate: mergeValuesOrDefault(newValues.rate, oldValues?.rate) ?? null,
        paymentDate: mergeValuesOrDefault(newValues.paymentDate, oldValues?.paymentDate) ?? null,
      },
      inputs: {},
      warnings: hedgePatch.warnings,
      errors: hedgePatch.errors,
    } as const;

    return newState;
  };

const mergeValuesOrDefault = <Value>(
  newValue: Value | null | undefined,
  oldValue: Value | null | undefined,
): Value | null | undefined => (newValue !== undefined ? newValue : oldValue);

const hasOptionHedgeUpdate = (hedgePatch: FxOptionHedgesPatchedValuesRecords) =>
  Object.keys(hedgePatch).length > 0;

const getHedgeKeysForOption = (state: FxOptionHedgeStateMap, optionId: string) =>
  Object.keys(state).filter(optionLegId => optionLegId.startsWith(optionId));
