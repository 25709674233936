import { connect } from 'react-redux';
import { mapStateToPropsLiveBlotter, mapDispatchToPropsLiveBlotter } from './connectLiveBlotter';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import {
  mapStateToPropsLiveBlotterTab,
  mapDispatchToPropsLiveBlotterTab,
} from './connectLiveBlotterTab';
import {
  mapDispatchToPropsLiveBlotterPanel,
  mapStateToPropsLiveBlotterPanel,
} from './connectLiveBlotterPanel';
import { mapStateToPropsReadonly } from 'components/share/connect/connectReadonly';
import { mapStateToPropsEditReadonly } from 'components/share/connect/connectEditReadonly';
import { mapDispatchToPropsMinimizeButton } from './connectMinimizeButton';
import {
  mapStateToPropsHistoricalToggle,
  mapDispatchToPropsHistoricalToggle,
} from './connectHistoricalToggle';
import {
  mapStateToPropsHistoricalDateRange,
  mapDispatchToPropsHistoricalDateRange,
} from './connectHistoricalDateRange';
import { mapDispatchToPropsHistoricalDateRangeOption } from './connectHistoricalDateRangeOption';
import { mapStateToPropsBlotterError } from './connectBlotterError';
import { mapStateToPropsBlotterLoading } from './connectBlotterLoading';
import { mapStateToPropsBlotterErrors } from './connectBlotterErrors';
import {
  mapStateToPropsQuickBlotterButton,
  mapDispatchToPropsQuickBlotterButton,
} from './connectQuickBlotterButton';

export const connectReadonly = connect(mapStateToPropsReadonly(selectors));

export const connectEditReadonly = connect(mapStateToPropsEditReadonly(selectors));

export const connectMinimizeButton = connect(
  null,
  mapDispatchToPropsMinimizeButton(actionCreators),
);

export const connectBlotterMode = connect(mapStateToPropsHistoricalToggle(selectors));

export const connectToggleMode = connect(null, mapDispatchToPropsHistoricalToggle(actionCreators));

export const connectHistoricalDateRange = connect(
  mapStateToPropsHistoricalDateRange(selectors),
  mapDispatchToPropsHistoricalDateRange(actionCreators),
);

export const connectHistoricalDateRangeOption = connect(
  null,
  mapDispatchToPropsHistoricalDateRangeOption(actionCreators),
);

export const connectLiveBlotter = connect(
  mapStateToPropsLiveBlotter(selectors),
  mapDispatchToPropsLiveBlotter(actionCreators),
);

export const connectLiveBlotterTab = connect(
  mapStateToPropsLiveBlotterTab(selectors),
  mapDispatchToPropsLiveBlotterTab(actionCreators),
);

export const connectLiveBlotterPanel = connect(
  mapStateToPropsLiveBlotterPanel(selectors),
  mapDispatchToPropsLiveBlotterPanel(actionCreators),
);

export const connectBlotterError = connect(mapStateToPropsBlotterError(selectors));

export const connectBlotterLoading = connect(mapStateToPropsBlotterLoading(selectors));

export const connectBlotterErrors = connect(mapStateToPropsBlotterErrors(selectors));

export const connectQuickBlotterButton = connect(
  mapStateToPropsQuickBlotterButton(selectors),
  mapDispatchToPropsQuickBlotterButton(actionCreators),
);
