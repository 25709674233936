import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { addKey, removeKey } from 'utils/stateMap';
import type { InstrumentChoice } from 'state/referenceData/referenceDataModel';
import type { FxSmartRfsStateMap } from './fxSmartRfsModel';
import { emptyFxSmartRfsState } from './fxSmartRfsModel';

export const fxSmartRfsReducer: Reducer<FxSmartRfsStateMap> = (
  state: FxSmartRfsStateMap = {},
  action: Action,
): FxSmartRfsStateMap => {
  switch (action.type) {
    case 'CREATE_SMART_RFS_REQUESTED_EPIC':
      return { ...state, [action.tileId]: { smartRfsRequested: true } };
    case 'CREATE_SMART_RFS_DONE':
      return { ...state, [action.tileId]: { smartRfsRequested: false } };
    case 'CLIENTWORKSPACE_TILE_RESTORED':
      if (action.savedTile.instrument !== 'SmartRfs') {
        return state;
      }
      return addSmartRfsForTileIfNeeded(state, action.tileId, action.savedTile.instrument);
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
      return addSmartRfsForTileIfNeeded(state, action.tileId, action.instrument);
    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED':
      if (action.instrument !== 'SmartRfs' && state[action.tileId] !== undefined) {
        return removeKey(state, action.tileId);
      }
      return addSmartRfsForTileIfNeeded(state, action.tileId, action.instrument);
    case 'CLIENTWORKSPACE_TILE_DELETED':
      return removeKey(state, action.tileId);
    default:
      return state;
  }
};

function addSmartRfsForTileIfNeeded(
  state: FxSmartRfsStateMap,
  tileId: string,
  instrument: InstrumentChoice | 'Order' | 'BlotterOrder',
): FxSmartRfsStateMap {
  if (instrument !== 'SmartRfs') {
    return state;
  }

  return addKey(state, tileId, {
    ...emptyFxSmartRfsState,
  });
}
