function getType(obj: unknown) {
  return Object.prototype.toString.call(obj).slice(8, -1);
}

export function isArray<T extends readonly U[], U>(arg: T | any): arg is T {
  return getType(arg) === 'Array';
}

function isObject(arg: unknown): arg is object {
  return getType(arg) === 'Object';
}

function compareArray<U>(oldState: readonly U[], newState: readonly U[]): readonly U[] {
  const ret = newState.map((data, index) => immutableReferenceUpdate<U>(oldState[index], data));
  return newState.length === oldState.length && ret.every((data, index) => data === oldState[index]) ? oldState : ret;
}

function compareObject<T extends object>(oldState: T, newState: T) {
  const ret = {} as T;
  let changed = false;
  for (const key in newState) {
    if (newState.hasOwnProperty(key)) {
      ret[key] = immutableReferenceUpdate(oldState[key], newState[key]);
      if (ret[key] !== oldState[key]) {
        changed = true;
      }
    }
  }
  return Object.keys(newState).length === Object.keys(oldState).length && changed === false ? oldState : ret;
}
/**
 * @summary
 * recursively compares two objects and creates a new ref with no false negative equality at data level
 */
export function immutableReferenceUpdate<T>(oldState: unknown, newState: T): T {
  if (isArray(newState) && isArray(oldState)) {
    return compareArray(oldState, newState) as typeof newState;
  }
  if (isObject(newState) && isObject(oldState)) {
    return compareObject(oldState, newState) as typeof newState;
  }
  return newState;
}
