import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { fieldData } from 'utils/fieldSelectors';
import { isDefined } from '@sgme/fp';

export interface TileStatusConnectOwnProps {
  quoteId: string;
}

export interface TileStatusConnectStateProps {
  hasError: boolean;
  isEmpty: boolean;
}

// you can whitelist selectors after implementation
export type TileStatusConnectSelectors = Selectors;

function hasTileError(state: AppState, sl: TileStatusConnectSelectors, quoteId: string) {
  const requestError = sl.getTilePropertiesRequestError(state, quoteId);
  const clientError = sl.getTileState(state, quoteId).clientError;

  return isDefined(requestError) || Boolean(clientError);
}

export const mapStateToPropsTileStatus: MapStateToPropsHOF<
  TileStatusConnectStateProps,
  TileStatusConnectOwnProps,
  AppState,
  TileStatusConnectSelectors
> =
  sl =>
  (state, { quoteId }) => {
    const instrument = sl.getTileState(state, quoteId).instrument;

    // smartRfs is always considered non-empty and without Error
    // as TileStatus/ TileEmpty has underlying TileLayout with nested component that expect formDormain state
    // therefore we skip it and rely directly on smartRfsTile Layout. Will need to change specifically to handle TC error
    if (instrument === 'SmartRfs') {
      return {
        hasError: false,
        isEmpty: false,
      };
    }

    const hasError = sl.isTilePresent(state, quoteId) && hasTileError(state, sl, quoteId);
    const isEmpty = fieldData(sl.getTileCurrencyPair(state, quoteId)).data === null;

    return {
      hasError,
      isEmpty,
    };
  };
