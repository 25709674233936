import type {
  BulkImported,
  BulkLegAdded,
  BulkLegsExcluded,
  BulkLegsRemoved,
  BulkPropertyChanged,
  BulkReset,
} from 'state/fxBulks/fxBulksActions';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { buildLegsMap } from 'state/share/patchHelper';
import type { BulkStoreModelChanges } from './tradeCaptureToBackend';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import { fieldData } from 'utils/fieldSelectors';
import type { BulkLineProduct, FxBulkLegInputs, FxBulkLegState } from 'state/fxBulks/fxBulksModel';
import type { CurrencyChoice } from 'state/share/productModel/litterals';

type TradeCapturePatchBuilderFromActionSelectorsKeys =
  | 'getBulkLegs'
  | 'getBulkLegProduct'
  | 'getBulkAmountCurrency'
  | 'getBulkCurrencyPair'
  | 'getBulkExcludedLegs'
  | 'getBulkLastLegIdRequested'
  | 'getBulkLegState';
export type TradeCapturePatchBuilderFromActionSelectors = Pick<
  Selectors,
  TradeCapturePatchBuilderFromActionSelectorsKeys
>;

type MapStateToActionChanges<TAction> = MapStateToMetadataHOF<
  [string, BulkStoreModelChanges],
  TAction,
  AppState,
  TradeCapturePatchBuilderFromActionSelectors
>;

export const bulkPropertyChangedToPatchWith: MapStateToActionChanges<BulkPropertyChanged> =
  sl =>
  (state, { bulkId, patch, patchLegs }) => {
    const legIds = sl.getBulkLegs(state, bulkId);
    const legs = buildLegs(
      legIds,
      id => fieldData(sl.getBulkLegProduct(state, bulkId, id)).data,
      patchLegs,
    );
    return [
      bulkId,
      {
        ...patch,
        legs: {
          ...legs,
        },
      },
    ];
  };

export const bulkImportedToPatchWith: MapStateToActionChanges<BulkImported> =
  _sl =>
  (_state, { bulkId, patch, patchLegs }) =>
    [
      bulkId,
      {
        ...patch,
        legs: patchLegs,
      },
    ];

export const legChangedToPatchWith: MapStateToActionChanges<{
  bulkId: string;
  legId: string;
  patch: Partial<FxBulkLegInputs>;
}> =
  sl =>
  (state, { bulkId, legId, patch }) => {
    const legIds = sl.getBulkLegs(state, bulkId);
    const legs = {
      ...buildLegs(legIds, id => fieldData(sl.getBulkLegProduct(state, bulkId, id)).data),
      [legId]: {
        product: sl.getBulkLegProduct(state, bulkId, legId).value ?? undefined,
        amountCurrency: sl.getBulkAmountCurrency(state, bulkId).value ?? 1,
        ...patch,
      },
    };

    const currencyPair = sl.getBulkCurrencyPair(state, bulkId).input ?? undefined;

    return [
      bulkId,
      {
        currencyPair,
        amountCurrency: sl.getBulkAmountCurrency(state, bulkId).value ?? 1,
        legs,
      },
    ];
  };

export const legAddToPatchWith: MapStateToActionChanges<BulkLegAdded> =
  sl =>
  (state, { bulkId, product }) => {
    const legIds = sl.getBulkLegs(state, bulkId);
    const legIdToAdd = sl.getBulkLastLegIdRequested(state, bulkId);
    const { data: currencyPair } = fieldData(sl.getBulkCurrencyPair(state, bulkId));
    return [
      bulkId,
      {
        currencyPair,
        legs: {
          ...buildLegs(legIds, id => fieldData(sl.getBulkLegProduct(state, bulkId, id)).data),
          [`${legIdToAdd}`]: { product },
        },
      },
    ];
  };

export const legRemovedToPatchWith: MapStateToActionChanges<BulkLegsRemoved> =
  sl =>
  (state, { bulkId, legIds }) => {
    const excludedLegs = sl.getBulkExcludedLegs(state, bulkId);
    const currentlegIds = sl
      .getBulkLegs(state, bulkId)
      .filter(id => !(legIds.includes(id) || excludedLegs.includes(id)));
    return [
      bulkId,
      {
        legs: buildLegs(
          currentlegIds,
          id => fieldData(sl.getBulkLegProduct(state, bulkId, id)).data,
        ),
      },
    ];
  };

export const legExcludedToPatchWith: MapStateToActionChanges<BulkLegsExcluded> =
  sl =>
  (state, { bulkId, legIds, activatedLegsIds }) => {
    const excludedLegs = sl.getBulkExcludedLegs(state, bulkId);
    const currentlegIds = sl
      .getBulkLegs(state, bulkId)
      .filter(id => !(legIds.includes(id) || excludedLegs.includes(id)));

    const activatedLegsPatchs = buildLegsPatch(
      activatedLegsIds,
      sl.getBulkAmountCurrency(state, bulkId).value || 1,
      sl.getBulkLegState.bind(undefined, state, bulkId),
      id => fieldData(sl.getBulkLegProduct(state, bulkId, id)).data,
    );

    return [
      bulkId,
      {
        currencyPair: sl.getBulkCurrencyPair(state, bulkId).value || null,
        amountCurrency: sl.getBulkAmountCurrency(state, bulkId).value || 1,
        legs: {
          ...buildLegs(
            currentlegIds,
            id => fieldData(sl.getBulkLegProduct(state, bulkId, id)).data,
          ),
          ...activatedLegsPatchs,
        },
      },
    ];
  };

export const bulkResetToPatchWith: MapStateToActionChanges<BulkReset> =
  sl =>
  (state, { bulkId }) => {
    const { data: currencyPair } = fieldData(sl.getBulkCurrencyPair(state, bulkId));
    return [
      bulkId,
      {
        currencyPair,
        legs: {},
      },
    ];
  };

const buildLegsPatch = (
  legIds: readonly string[],
  amountCurrency: CurrencyChoice,
  getBulkLegState: (legId: string) => FxBulkLegState,
  getBulkLegProduct: (legId: string) => BulkLineProduct | null,
) =>
  legIds.reduce((acc, legId) => {
    const legState = getBulkLegState(legId);
    const legData = {
      ...legState.values,
      ...legState.inputs,
      product: getBulkLegProduct(legId) ?? undefined,
      amountCurrency,
    };
    acc[legId] = Object.entries(legData).reduce((innerAcc, [legKey, legValue]) => {
      const key = legKey as keyof FxBulkLegInputs;
      innerAcc[key] = (legValue as any) ?? undefined;
      return innerAcc;
    }, {} as Partial<FxBulkLegInputs>);
    return acc;
  }, {} as Record<string, Partial<FxBulkLegInputs>>);

const buildLegs = (
  legIds: readonly string[],
  getBulkLegProduct: (legId: string) => BulkLineProduct | null,
  legsPatch: { [legId: string]: Partial<FxBulkLegInputs> } = {},
) =>
  buildLegsMap(
    legIds.map(id => ({
      legId: id,
      patch: {
        product: getBulkLegProduct(id) ?? undefined,
        ...legsPatch[id],
      },
    })),
  );
