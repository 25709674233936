import type { AppState } from 'state/model';
import type { CurrencyChoice, BidAskPair, BidAsk } from 'state/share/productModel/litterals';
import { assertUnhandled } from 'utils/error';
import type { DisplayPriceType, IGreeks, IPremiumPairDataString } from '../fxOptionsModel';
import type { HedgeWay } from 'state/share/productModel/litterals';
import { getMarkupCurrencyChoice } from './fxOptionsSelectors';
import { throwIfUndefined } from 'utils/maps';
import { getOptionState, getOptionActiveRfsId } from './fxOptionsProductSelectors';
import type {
  FxOptionStreamState,
  IFxOptionStreamState,
  IFxOptionStreamStreamingState,
  IHedge,
  IMarginData,
  IPremiumData,
  IQuote,
  IQuoteCurrency,
  WayType,
} from '../model/optionsStreams';
import { assertUnreachable, isNotDefined } from '@sgme/fp';
import { getOptionHedgePaymentDate } from './optionHedgeValue';
import { findHedge } from 'components/FxOptionTile/Hedge/findHedge';

const allowedStreamStatus: ReadonlyArray<FxOptionStreamState['status']> = ['PRICING', 'EXPIRED'];

export function isPrice(state: FxOptionStreamState): state is IFxOptionStreamStreamingState {
  return allowedStreamStatus.includes(state.status);
}

export function getOptionStreamState(state: AppState, streamId: string) {
  return throwIfUndefined(
    state.fxOptions.streams[streamId],
    `Option stream state for ${streamId} does not exist`,
  );
}

export function isOptionStreamPresent(state: AppState, streamId: string): boolean {
  return !!state.fxOptions.streams[streamId];
}

export function getQuoteFromStream(
  state: AppState,
  streamId: string,
): Readonly<IQuote<readonly IHedge[]>> | null {
  const stream = getOptionStreamState(state, streamId);
  return isPrice(stream) ? stream.quote : null;
}

export function getOptionQuoteCcyData(state: AppState, tileId: string): IQuoteCurrency | null {
  const {
    displayPriceType: [priceCurrency],
    currentStreamId,
    lastStreamId,
  } = getOptionState(state, tileId);
  const activeStreamId = lastStreamId ?? currentStreamId;

  if (activeStreamId === null) {
    return null;
  }

  const streamState = getOptionStreamState(state, activeStreamId);
  if (!isPrice(streamState)) {
    return null;
  }

  return (priceCurrency === 1 ? streamState?.quote?.ccy1 : streamState?.quote?.ccy2) ?? null;
}

export function getOptionQuote(state: AppState, quoteId: string): IQuote | null {
  const activeStreamId = getOptionActiveRfsId(state, quoteId);

  if (activeStreamId === null) {
    return null;
  }

  const streamState = getOptionStreamState(state, activeStreamId);

  if (!isPrice(streamState)) {
    return null;
  }

  return streamState?.quote ?? null;
}

export function getStrikeFromStream(state: AppState, streamId: string, legId?: string) {
  const legData = getLegData(state, streamId, legId || '0');
  return legData !== null ? legData.strikeData : null;
}

export function getQuoteHedgeFromStream(state: AppState, streamId: string | null) {
  if (streamId !== null) {
    const quote = getQuoteFromStream(state, streamId);
    if (quote !== null) {
      return quote.hedge;
    }
  }
  return null;
}

export function getGreeksFromStream(
  state: AppState,
  streamId: string,
  notionalCurrency: CurrencyChoice,
  legId?: string,
): Readonly<IGreeks> | null {
  const quote = getQuoteFromStream(state, streamId);

  if (quote === null) {
    return null;
  }

  if (legId !== undefined) {
    const legData = getLegData(state, streamId, legId);
    if (legData === null) {
      return null;
    }
    const {
      greeks: {
        deltaCcy1,
        deltaCcy2,
        vegaCcy1,
        vegaCcy2,
        gammaCcy1,
        gammaCcy2,
        thetaCcy1,
        thetaCcy2,
      },
    } = legData;
    return {
      deltaCcy: Number.parseFloat(notionalCurrency === 1 ? deltaCcy1 : deltaCcy2),
      vegaCcy: Number.parseFloat(notionalCurrency === 1 ? vegaCcy1 : vegaCcy2),
      gammaCcy: Number.parseFloat(notionalCurrency === 1 ? gammaCcy1 : gammaCcy2),
      thetaCcy: Number.parseFloat(notionalCurrency === 1 ? thetaCcy1 : thetaCcy2),
    };
  }

  return {
    deltaCcy: Number.parseFloat(notionalCurrency === 1 ? quote.ccy1.deltaCcy : quote.ccy2.deltaCcy),
    vegaCcy: Number.parseFloat(notionalCurrency === 1 ? quote.ccy1.vegaCcy : quote.ccy2.vegaCcy),
    gammaCcy: Number.parseFloat(notionalCurrency === 1 ? quote.ccy1.gammaCcy : quote.ccy2.gammaCcy),
    thetaCcy: Number.parseFloat(notionalCurrency === 1 ? quote.ccy1.thetaCcy : quote.ccy2.thetaCcy),
  };
}

export function getMarketDataFromStream(state: AppState, streamId: string, legId?: string) {
  const legData = getLegData(state, streamId, legId || '0');
  return legData !== null ? legData.marketData : null;
}

export function getMarginDataFromStream(state: AppState, streamId: string, legId: string) {
  const legData = getLegData(state, streamId, legId);
  return legData !== null ? legData.marginData : null;
}

export function getStrategyMarginFromQuoteCcy(quote: IQuoteCurrency, way: WayType): number {
  switch (way) {
    case 'Both':
    case 'Buy':
      return quote.askMarkup;
    case 'Sell':
      return quote.bidMarkup;
    default:
      assertUnreachable(way, 'Unhandled way');
  }
}

export function getStrategyMarginFromQuote(quote: IQuote, markupCurrency: CurrencyChoice): number {
  switch (markupCurrency) {
    case 1:
      return getStrategyMarginFromQuoteCcy(quote.ccy1, quote.strategyWay);
    case 2:
      return getStrategyMarginFromQuoteCcy(quote.ccy2, quote.strategyWay);
    default:
      assertUnreachable(markupCurrency, 'Unhandled markup currency');
  }
}

export function getLegMarginByCcy(
  marginData: IMarginData | null,
  markupCurrency: CurrencyChoice,
): BidAskPair | null {
  if (marginData === null) {
    return null;
  }

  switch (markupCurrency) {
    case 1: {
      return {
        bid: marginData.bidMarkupCcy1 || 0,
        ask: marginData.askMarkupCcy1 || 0,
      };
    }
    case 2:
      return {
        bid: marginData.bidMarkupCcy2 || 0,
        ask: marginData.askMarkupCcy2 || 0,
      };
    default:
      assertUnreachable(markupCurrency, 'Unhandled markup currency');
  }
}

export function getOptionLegPremiumFromStream(
  state: AppState,
  legId: string,
  streamId: string,
  displayPriceType: DisplayPriceType,
): IPremiumPairDataString | null {
  const legData = getLegData(state, streamId, legId);
  const premiumData = legData?.premiumData ?? null;
  return getBidAskPairFromPremiumData(premiumData, displayPriceType);
}

function getBidAskPairFromPremiumData(
  premiumData: IPremiumData | null,
  displayPriceType: DisplayPriceType,
): IPremiumPairDataString | null {
  if (premiumData === null) {
    return null;
  }

  const [currency, displayType] = displayPriceType;

  switch (displayType) {
    case 'AMOUNT':
    case 'VOLATILITY':
      return currency === 1
        ? {
          bid: premiumData.bidAmountCcy1.toString(),
          ask: premiumData.askAmountCcy1.toString(),
        }
        : {
          bid: premiumData.bidAmountCcy2.toString(),
          ask: premiumData.askAmountCcy2.toString(),
        };

    case 'PERCENT':
      return currency === 1
        ? {
          bid: premiumData.bidPercentCcy1.toString(),
          ask: premiumData.askPercentCcy1.toString(),
        }
        : {
          bid: premiumData.bidPercentCcy2.toString(),
          ask: premiumData.askPercentCcy2.toString(),
        };

    case 'PPS':
      return currency === 1
        ? {
          bid: premiumData.bidPpsCcy1.toString(),
          ask: premiumData.askPpsCcy1.toString(),
        }
        : {
          bid: premiumData.bidPpsCcy2.toString(),
          ask: premiumData.askPpsCcy2.toString(),
        };

    default:
      assertUnhandled('display type not handled', displayType);
  }
}

export function getOptionLegMarkupFromStream(
  state: AppState,
  optionId: string,
  legId: string,
  streamId: string,
): BidAskPair | null {
  const markupCurrency = getMarkupCurrencyChoice(state, optionId);

  return getLegMarginByCcy(getMarginDataFromStream(state, streamId, legId), markupCurrency!);
}

function getLegData(state: AppState, streamId: string, legId: string) {
  const quote = getQuoteFromStream(state, streamId);

  return quote?.legsData?.[legId] ?? null;
}

export function getOptionQuoteId(state: AppState, streamId: string | null): string | null {
  if (streamId === null) {
    return null;
  }

  const streamState = getOptionStreamState(state, streamId);

  if (streamState.status !== 'PRICING') {
    return null;
  }

  return streamState.quote.quoteId;
}
export function getOptionStreamStatus(
  state: AppState,
  streamId: string | null,
): IFxOptionStreamState['status'] | null {
  if (streamId === null) {
    return null;
  }

  const { status } = getOptionStreamState(state, streamId);

  return status;
}

export function getOptionVolatilityStreamForRiskReversal(state: AppState, streamId: string | null) {
  if (streamId === null) {
    return null;
  }

  const legsData = getQuoteFromStream(state, streamId)?.legsData;
  if (isNotDefined(legsData)) {
    return null
  }

  const legsDataValues = Object.values(legsData)
  return {
    callVolatility: legsDataValues[0].marketData.volatility,
    putVolatility: legsDataValues[1].marketData.volatility
  }

}

export function getOptionStreamVersion(state: AppState, streamId: string | null): number | null {
  if (streamId === null) {
    return null;
  }

  const { version } = getOptionStreamState(state, streamId);

  return version;
}

export function getOptionHedgeWay(
  state: AppState,
  streamId: string | null,
  hedgeId: string,
): HedgeWay {
  if (streamId === null || !isOptionStreamPresent(state, streamId)) {
    return 'WayUnknown';
  }
  const quote = getQuoteFromStream(state, streamId);
  const hedgePaymentDate = getOptionHedgePaymentDate(state, hedgeId);
  if (quote === null || isNotDefined(quote.hedge)) {
    return 'WayUnknown';
  }

  return findHedge(quote.hedge, hedgePaymentDate)?.way || 'WayUnknown';
}

export function getOptionMidVolatility(state: AppState, streamId: string) {
  const quote = getQuoteFromStream(state, streamId);
  return quote?.midVolatility ?? null;
}

export function getOptionLegMidVolatility(state: AppState, streamId: string, mpLegId: string) {
  const leg = getLegData(state, streamId, mpLegId);
  return leg != null ? leg.midVolatility : null;
}

export function hasOptionBypassedLimitCheck(state: AppState, quoteId: string) {
  const quoteState = getOptionState(state, quoteId);

  if (quoteState === null) {
    return false;
  }

  if (!isOptionStreamPresent(state, quoteState.currentStreamId!)) {
    return false;
  }

  const streamState = getOptionStreamState(state, quoteState.currentStreamId!);

  return streamState.status === 'PRICING' && streamState.skippedLimitCheck;
}

export function getVolatilityFromStream(
  state: AppState,
  streamId: string,
  mpLegId: string,
): BidAsk<string> | undefined {
  const quote = getQuoteFromStream(state, streamId);
  return quote?.legsData?.[mpLegId]?.marketData?.volatility;
}
