import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

export interface CloseTabButtonConnectOwnProps {
  tabId: string;
}

export interface CloseTabButtonConnectDispatchProps {
  close(): void;
}

export type CloseTabButtonConnectActionCreatorsKeys = 'clientWorkspaceTabClosedThunk';
export type CloseTabButtonConnectActionCreators = Pick<
  ActionCreators,
  CloseTabButtonConnectActionCreatorsKeys
>;

export const mapDispatchToPropsCloseTabButton: MapDispatchToPropsHOF<
  CloseTabButtonConnectDispatchProps,
  CloseTabButtonConnectOwnProps,
  CloseTabButtonConnectActionCreators
> =
  ac =>
  (dispatch, { tabId }) => ({
    close: () => dispatch(ac.clientWorkspaceTabClosedThunk(tabId)),
  });
