import type { IHedge } from 'state/fxOptions/model/optionsStreams';

// TODO delete file after migration SGEFX-4288
export function findHedge(
  hedges: readonly IHedge[] | undefined | null,
  paymentDate: string | null | undefined,
) {
  return hedges?.length === 1
    ? hedges?.[0]
    : hedges?.find(({ maturityDate }) => maturityDate === paymentDate) ?? null;
}
