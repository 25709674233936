import type { SavedWorkspace } from 'api/workspaceService/model';
import { updaters } from './updaters';

/**
 * Updates the data to the most recent format. If ever you need to update to a new format,
 * please refers to the HowToUpdate.md file next to this file.
 * @param previousData the workspace data received from the server.
 */
export const updater = (previousData: any): SavedWorkspace | null =>
  updaters.reduce(
    (data, anUpdater) =>
      data === null ? null : anUpdater.canUpdate(data) ? anUpdater.update(data) : data,
    previousData,
  );
