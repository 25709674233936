import type { BlotterData } from '../blotterModel';
import type { TradeBlotterEntry, BlotterEntry } from '../blotterEntryModel';
import { isEmpty } from '@sgme/fp';

export const integrateTrades = (
  currentTrades: BlotterData,
  newTrades: readonly TradeBlotterEntry[],
): BlotterData => {
  if (isEmpty(newTrades)) {
    return currentTrades;
  }

  const tradesToAdd = newTrades.filter(trade => {
    const existingTrade = currentTrades[trade.id];
    return existingTrade === undefined || existingTrade.values.updateTime < trade.values.updateTime;
  });

  const strategyNewChildrenIds: Record<string, readonly string[]> = {};

  const updatedTrades = tradesToAdd.reduce(
    (acc, val) => {
      acc[val.id] = val;
      if (val.strategyReference) {
        const childrenIds = strategyNewChildrenIds[val.strategyReference];
        strategyNewChildrenIds[val.strategyReference] = childrenIds
          ? [...childrenIds, val.id]
          : [val.id];
      }
      return acc;
    },
    { ...currentTrades } as Record<string, BlotterEntry>,
  );

  Object.entries(strategyNewChildrenIds).forEach(updateChildrenIds(updatedTrades));

  return updatedTrades;
};

const updateChildrenIds =
  (trades: Record<string, BlotterEntry>) =>
  ([stratId, newChildIds]: [string, readonly string[]]) => {
    const strategy = trades[stratId] as TradeBlotterEntry;
    if (strategy === undefined) {
      return;
    }
    const { childrenIds: existingChildrenIds = [] } = strategy;
    const childIdsToAdd = newChildIds.filter(childId => !existingChildrenIds.includes(childId)); // remove duplicates
    if (!isEmpty(childIdsToAdd)) {
      trades[stratId] = {
        ...strategy,
        childrenIds: [...existingChildrenIds, ...childIdsToAdd],
      };
    }
  };
