import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';

export interface ICashRfsCancelApi {
  cancelQuoteRequest(rfsId: string): Observable<never>;
}

export function cashRfsCancelApi(http: ISgmeHttp): ICashRfsCancelApi {
  return {
    cancelQuoteRequest(rfsId) {
      return http
        .post<void>('api/fxSpotFwd/rfs/cancel', { rfsId }, { 'X-Correlation-ID': rfsId })
        .pipe(ignoreElements());
    },
  };
}
