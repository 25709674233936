import type { AppState } from 'state/model';
import type { Store } from 'redux';
import type { SGWTConnectCore } from '@sgwt/connect-core';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import { catchError, timeout } from 'rxjs/operators';
import { logger } from 'logging/logger';
import { throwError } from 'rxjs';
import { isDefined } from '@sgme/fp';

export type HelpRequestConfig =
  | 'general'
  | 'access'
  | 'appcrashed'
  | 'dealpending'
  | 'timeouteddeal'
  | 'orderpending';

export function cleanUpSensitiveInformations(key: string, value: { Authorization: string }) {
  if (key === 'headers' && isDefined(value) && value.Authorization !== undefined) {
    value.Authorization = '*** HIDDEN ***';
  }
  return value;
}

export interface IHelpRequestAdditionalParams extends HelpRequestIdParam {
  config?: HelpRequestConfig;
  screenshot?: string;
  details?: string;
  includeLastActions?: boolean;
  includeState?: boolean;
  sendConfirmation?: boolean;
  data?: Record<string, unknown>;
}

export interface HelpRequestIdParam {
  rfsIdsErrors?: TileError[];
  executionIdsErrors?: TileError[];
  rfsIdsNoPrice?: string[];
  espIdsNoPrice?: string[];
}

export interface TileError {
  id: string;
  message: string;
}
const removePartOfState = (state: Readonly<AppState>) => {
  const { referenceData: _, blotter: __, espStreams: ___, ...rest } = state;
  return rest;
}
export const sendHelpRequestHOF =
  (
    sgmeHttpParam: ISgmeHttp,
    sgwtConnectParam: SGWTConnectCore,
    store: Store<AppState>,
    lastActionsGetter: () => readonly unknown[],
    isProbablyUsingRemoteDesktop: () => boolean,
  ) =>
    ({
      config = 'general',
      screenshot,
      details,
      includeLastActions = false,
      includeState = false,
      sendConfirmation = true,
      executionIdsErrors,
      espIdsNoPrice,
      rfsIdsErrors,
      rfsIdsNoPrice,
      data,
    }: IHelpRequestAdditionalParams) => {
      const { userAgent } = navigator;
      const probableConnection = isProbablyUsingRemoteDesktop() ? 'Remote desktop' : 'Direct';
      const smallState = removePartOfState(store.getState())
      const state = includeState === true ? JSON.stringify(smallState) : undefined;
      const lastActions =
        includeLastActions === true
          ? JSON.stringify(lastActionsGetter(), cleanUpSensitiveInformations)
          : undefined;
      // user info is extracted from sgwtConnect
      const login = sgwtConnectParam.getIdTokenClaims()!.sub as string;
      const payload = {
        application: 'SGME-FX',
        config,
        login,
        userAgent,
        probableConnection,
        details,
        state,
        screenshot,
        lastActions,
        sendConfirmation,
        data,
        executionIdsErrors,
        espIdsNoPrice,
        rfsIdsErrors,
        rfsIdsNoPrice,
      };

      const httpPost = (_payload: typeof payload) =>
        sgmeHttpParam
          .post<void>('api/help/request', JSON.stringify(_payload), {
            'Content-Type': 'application/json',
          })
          .pipe(timeout(30000));

      try {
        logger.logInformation('Sending help request {@helpRequestBody}', {
          application: 'SGME-FX',
          config,
          login,
          userAgent,
          probableConnection,
          details,
          stateLength: state?.length,
          screenshotLength: screenshot?.length,
          lastActions,
          sendConfirmation,
          data,
          executionIdsErrors,
          espIdsNoPrice,
          rfsIdsErrors,
          rfsIdsNoPrice,
        });
      } catch (err) {
        logger.logError('Log info failed, need to reduce helpRequestBody')
      }

      return httpPost(payload).pipe(
        catchError((err) => {
          logger.logError('Error while sending help request {message_s}', err.message);
          if (err.status === 413) {
            delete payload.state;
            payload.details =
              `[state missing because of error 413, state size was ${state?.length}]\n\n` +
              (details || '');
            return httpPost(payload);
          } else {
            return throwError(err);
          }
        }),
        catchError((err) => {
          logger.logError('Error while sending help request {message_s}', err.message);
          if (err.status === 413) {
            delete payload.screenshot;
            payload.details =
              `[state and screenshot missing because of error 413, state size was ${state?.length} and screenshot size was ${screenshot?.length}]\n\n` +
              (details || '');
            return httpPost(payload);
          } else {
            return throwError(err);
          }
        }),
      );
    };
