import { SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';
import { getConfig } from 'config/config';

export function Footer(): JSX.Element {
  const { env } = getConfig();

  return (
    <SgwtMiniFooter
      mode="sg-markets"
      type="micro"
      cookiesConsent={env !== 'DEVCI'}
      legalNotices={{
        en: [
          {
            label: 'SG Markets Electronic Services Terms',
            value: 'https://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_ELEC_SERV_TERMS',
          },
          {
            label: 'Notice to Investors',
            value: 'https://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_TO_INVESTORS',
          },
          {
            label: 'Foreign exchange disclosure',
            value: 'https://swapdisclosure.sgcib.com/fx-disclosure',
          },
        ],
        fr: [
          {
            label: 'Termes du Service SG Markets Electronic',
            value: 'https://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_ELEC_SERV_TERMS',
          },
          {
            label: 'Notice Investisseurs',
            value: 'https://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_TO_INVESTORS',
          },
          {
            label: 'Communication pour le Marché des Changes',
            value: 'https://swapdisclosure.sgcib.com/fx-disclosure',
          },
        ],
      }}
      contactUs={{
        mail: 'sgmarketsfx@sgcib.com',
        phones: [
          { label: 'emea', value: '+33 (0)1 42 13 86 00' },
          { label: 'americas', value: '+1 212 278 6022' },
          { label: 'asia pacific', value: '+852 2166 5707' },
        ],
      }}
      accessibilityCompliance="none"
      accessibilityLink="https://shared.sgmarkets.com/accessibility/statement/en.html?redirect_uri=https://fx.sgmarkets.com"
    />
  );
}
