import type {
  Legs,
  TradeCaptureResponseWrapper,
  ErrorLegs,
  TcErrors,
  TradeCaptureRequestWrapper,
  ITradeCaptureMetaData,
} from '../tradeCaptureModel';
import type { FxSwapPatch, SwapProductName } from 'state/fxSwaps/fxSwapsModel';
import type { CurrencyChoice, Possible } from 'state/share/productModel/litterals';

export type SwapTradeCapturePatch = ITradeCaptureMetaData & FxSwapPatch;
export type TradeCaptureSwapRequestChanges = Partial<SwapTradeCaptureRequestProperties> &
  Legs<SwapTradeCaptureRequestLegProperties>;
export type TradeCaptureSwapRequestWrapper =
  TradeCaptureRequestWrapper<TradeCaptureSwapRequestChanges>;
export type TradeCaptureSwapResponseChanges = Partial<SwapTradeCaptureResponseProperties> &
  Legs<SwapTradeCaptureResponseLegProperties>;
export type TradeCaptureSwapResponseWrapper = TradeCaptureResponseWrapper<
  TradeCaptureSwapResponseChanges,
  TradeCaptureSwapErrors,
  TradeCaptureSwapWarnings
>;
export type TradeCaptureSwapErrors = TcErrors<SwapTradeCaptureResponseProperties> &
  ErrorLegs<SwapTradeCaptureResponseLegProperties>;

export type TradeCaptureSwapWarnings = ErrorLegs<SwapTradeCaptureResponseLegProperties>;

export const SwapLegId = '0';

export interface SwapTradeCaptureResponseProperties {
  isNonDeliverable: boolean;
}

export interface SwapTradeCaptureRequestProperties {
  isNonDeliverable: boolean | null;
}

export interface SwapTradeCaptureResponseLegProperties {
  currencyPair: string;
  productName: SwapProductName;
  isReadyToPrice: boolean;
  isPriceObsolete: boolean;
  currency1: string;
  currency2: string;
  negotiatedCurrency: CurrencyChoice;
  nearAmount1: number | null;
  nearAmount2: number | null;
  nearPaymentDate: string;
  nearPaymentDateTenor: string;
  farAmount1: number | null;
  farAmount2: number | null;
  farPaymentDate: string;
  farPaymentDateTenor: string;
  isUneven: boolean;
  swapOffMarket: boolean;
  fixingSource: string;
  nearFixingDate: string;
  farFixingDate: string;
  fixingCurrency: string;
  possibleFixingSources: Possible<string>;
  isCrossed: boolean;
  possibleXCurrencies: Possible<string>;
  possibleSndFixingSources: Possible<string>;
  xCurrency: string;
  sndFixingSource: string;
  nearPriceReferenceBid: number | null;
  nearPriceReferenceAsk: number | null;
  farPriceReferenceBid: number | null;
  farPriceReferenceAsk: number | null;
}

export interface SwapTradeCaptureRequestLegProperties {
  currencyPair: string | null;
  productName: SwapProductName;
  nearAmount1String: string | null;
  nearAmount2String: string | null;
  nearPaymentDateString: string | null;
  farAmount1String: string | null;
  farAmount2String: string | null;
  farPaymentDateString: string | null;
  isUneven: boolean | null;
  swapOffMarket: boolean | null;
  fixingSource: string | null;
  xCurrency: string | null;
  sndFixingSource: string | null;
  nearPriceReferenceBidString: string | null;
  nearPriceReferenceAskString: string | null;
}
