import type {
  AccumulatorPropertiesChanged,
  AccumulatorScheduleImported,
  AccumulatorScheduleLegAdded,
  AccumulatorScheduleLegPropertiesChanged,
  AccumulatorScheduleLegRemoved,
  AccumulatorScheduleReset,
  AccumulatorTileRestoreEpic,
} from 'state/fxAccumulators/actions';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { AccumulatorStoreModelChanges } from './tradeCaptureToBackend';

type TradeCapturePatchBuilderFromActionSelectorsKeys =
  | 'getAccumulatorCurrencyPairInput'
  | 'getAccumulatorScheduleItemIds';
export type TradeCapturePatchBuilderFromActionSelectors = Pick<
  Selectors,
  TradeCapturePatchBuilderFromActionSelectorsKeys
>;

type MapStateToActionChanges<TAction> = MapStateToMetadataHOF<
  [string, AccumulatorStoreModelChanges],
  TAction,
  AppState,
  TradeCapturePatchBuilderFromActionSelectors
>;

export const accumulatorChangedToPatchWith: MapStateToActionChanges<AccumulatorPropertiesChanged> =
  _sl => (_state, action) =>
    [action.quoteId, action.patch];

export const accumulatorScheduleChangedToPatchWith: MapStateToActionChanges<AccumulatorScheduleLegPropertiesChanged> =
  _sl => (_state, action) =>
    [action.quoteId, { schedule: { [action.legId]: action.patch } }];

export const accumulatorScheduleResetToPatchWith: MapStateToActionChanges<AccumulatorScheduleReset> =
  _sl => (_state, action) =>
    [action.quoteId, { schedule: null }];

export const accumulatorScheduleAddedToPatchWith: MapStateToActionChanges<AccumulatorScheduleLegAdded> =
  _sl => (_state, action) =>
    [action.quoteId, { schedule: { [action.legId]: {} } }];

export const accumulatorScheduleRemovedToPatchWith: MapStateToActionChanges<AccumulatorScheduleLegRemoved> =
  _sl => (_state, action) =>
    [action.quoteId, { schedule: { [action.legId]: null } }];

export const accumulatorScheduleImportedToPatchWith: MapStateToActionChanges<AccumulatorScheduleImported> =
  sl => (state, action) =>
    [
      action.quoteId,
      {
        schedule: {
          ...Object.fromEntries(sl.getAccumulatorScheduleItemIds(state, action.quoteId).map(id => [id, null])),
          ...action.patch,
        },
      },
    ];

export const accumulatorRestoreToPatchWith: MapStateToMetadataHOF<
  Array<[string, AccumulatorStoreModelChanges]>,
  AccumulatorTileRestoreEpic,
  AppState,
  TradeCapturePatchBuilderFromActionSelectors
> = _sl => (_state, action) =>
  Object.entries(action.tiles).map(([tileId, tile]) => [
    tileId,
    {
      currencyPair: tile.currencyPair,
      priceType: tile.priceType,
      priceCurrency: tile.priceCurrency,
      premiumDate: tile.premiumDate,
      premiumDateTenor: tile.premiumDateTenor,
      hedgeType: tile.hedgeType,
      way: tile.way,
      amount: tile.amount,
      amountCurrency: tile.amountCurrency,
      amountSplitType: tile.amountSplitType, // amount mode
      leverage: tile.leverage,
      strike: tile.strike,
      step: tile.step,
      accuType: tile.accuType,
      eki: tile.eki,
      ekiDown: tile.ekiDown,
      ekiUp: tile.ekiUp,
      strikeDown: tile.strikeDown,
      strikeUp: tile.strikeUp,
      pivot: tile.pivot,
      akoTrigger: tile.akoTrigger,
      ekiTrigger: tile.ekiTrigger,
      targetProfitType: tile.targetProfitType,
      target: tile.target,
      expiryDate: tile.expiryDate,
      expiryTenor: tile.expiryTenor,
      fixingFrequency: tile.fixingFrequency,
      settlementMode: tile.settlementMode,
      cashSettlementCurrency: tile.cashSettlementCurrency,
      firstFixingDate: tile.firstFixingDate,
      firstFixingDateTenor: tile.firstFixingDateTenor,
      isCrossed: tile.isCrossed,
      crossCurrency: tile.crossCurrency,
      fixingReference1: tile.fixingReference1,
      fixingReference2: tile.fixingReference2,
      settlementFrequency: tile.settlementFrequency,
    } as AccumulatorStoreModelChanges,
  ]);
