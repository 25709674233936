import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import { type IFxCashExecutionData, assertIsPricing } from 'state/fxCashs/fxCashsModel';
import type { IFxExecutionPendingState } from 'state/executions/executionsStateModel';
import { getRelevantPriceFromBidAsk } from 'state/share/productModel/utils';
import type { Way, BidAskPair } from 'state/share/productModel/litterals';
import { assertIsDefined } from '@sgme/fp';
import { fieldData } from 'utils/fieldSelectors';

export type CashRfsExecutionData = IFxCashExecutionData &
  IFxExecutionPendingState & { quoteId: string };

export const cashRfsExecutionWith: MapStateToMetadataHOF<
  CashRfsExecutionData,
  { cashId: string; way: Way; tradeDate: Date; streamId: string },
  AppState,
  Selectors
> =
  sl =>
  (state, { cashId, way, tradeDate, streamId }) => {
    const ensureFieldIsDefined = <T>(
      selector: (s: AppState, id: string) => { value: T | null | undefined },
      fieldName: string,
    ): T => {
      const { value } = selector(state, cashId);
      assertIsDefined(value, `${fieldName} needs to be defined for RFS execution`);
      return value;
    };

    const streamState = sl.getCashRfsStreamState(state, streamId);
    assertIsPricing(streamState);

    const fromBidAsk = (pair: BidAskPair) => getRelevantPriceFromBidAsk(pair, way);

    const productName = sl.getCashProductName(state, cashId);
    const { quoteId } = streamState.quote;

    const allIn = fromBidAsk(sl.getCashRfsAllInPrice(state, cashId));
    const spot =
      productName === 'FxFwd'
        ? fromBidAsk(sl.getCashRfsSpotPriceWithMargin(state, cashId))
        : undefined;
    const swapPoints =
      productName === 'FxFwd'
        ? fromBidAsk(sl.getCashRfsForwardPointsWithMargin(state, cashId))
        : undefined;

    const currencyPair = ensureFieldIsDefined(sl.getCashCurrencyPair, 'currencyPair');
    const precision = sl.getCurrencyPrecision(state, currencyPair);

    const notionalAmount = ensureFieldIsDefined(sl.getCashAmount, 'notionalAmount');
    const expiryDate = new Date(ensureFieldIsDefined(sl.getCashMaturityDate, 'maturityDate'));
    const expiryDateTenor = ensureFieldIsDefined(sl.getCashMaturityDateTenor, 'maturityDateTenor');

    const notionalCurrency = sl.getAmountCurrency(state, cashId);
    assertIsDefined(notionalCurrency, 'amountCurrency needs to be defined for RFS execution');

    const client = sl.getClientForTile(state, cashId);

    const ndReference = fieldData(sl.getCashFixingSource(state, cashId)).data ?? undefined;
    const ndFixingDate = sl.getCashFixingDate(state, cashId) ?? undefined;

    return {
      quoteId,
      instrument: 'Cash',
      status: 'Pending',
      currencyPair,
      way,
      notionalAmount,
      notionalCurrency,
      dealtRate: allIn,
      client,
      expiryDate,
      expiryDateTenor,
      dealtRatePrecision: precision,
      tradeDate,
      productName,
      swapPoints,
      spot,
      ndReference,
      ndFixingDate,
    };
  };
