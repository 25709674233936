import type { ReactNode } from 'react';
import { lazyWithRetry } from '../share/lazy';
import type { TileInstrument as StateTileInstrument } from 'state/referenceData/referenceDataModel';
import { createSwitchChildren } from 'components/share/SwitchChildren';
import { withQuoteId } from 'components/contexts';
import { connectTileInstrument } from './connect';

const FxCashTile = lazyWithRetry(() => import('components/FxCashTile'));
const FxSwapTile = lazyWithRetry(() => import('components/FxSwapTile'));
const FxOptionTile = lazyWithRetry(() => import('components/FxOptionTile/FxOptionTile'));
const FxAccumulatorTile = lazyWithRetry(() => import('components/FxAccumulatorTile'));
const FxOrderTile = lazyWithRetry(() => import('components/FxOrderTile'));
const OrderResultTileContent = lazyWithRetry(() => import('components/FxOrderTile/OrderResult/OrderResultTileContent'));
const FxSmartRfsTile = lazyWithRetry(() => import('components/FxSmartRfsTile/FxSmartRfsTile'));
const FxAmericanForwardTile = lazyWithRetry(() => import('components/FxAmericanForward/FxAmericanForwardTile'));

const instrumentChildren: Record<StateTileInstrument, ReactNode> = {
  Cash: <FxCashTile />,
  Swap: <FxSwapTile />,
  Option: <FxOptionTile />,
  AmericanForward: <FxAmericanForwardTile />,
  Accumulator: <FxAccumulatorTile />,
  Order: <FxOrderTile />,
  BlotterOrder: <OrderResultTileContent />,
  SmartRfs: <FxSmartRfsTile />,
};

export const TileInstrumentRaw = createSwitchChildren('instrument', 'TileInstrument', instrumentChildren);

export const TileInstrument = withQuoteId(connectTileInstrument(TileInstrumentRaw));

const FxOptionTileZoomed = lazyWithRetry(() => import('components/FxOptionTile/FxOptionTileZoomed'));
const FxAccumulatorTileZoomed = lazyWithRetry(() => import('components/FxAccumulatorTile/FxAccumulatorTileZoomed'));

const zoomedInstrumentChildren: Record<'Option' | 'Accumulator', ReactNode> = {
  Option: <FxOptionTileZoomed />,
  Accumulator: <FxAccumulatorTileZoomed />,
};

export const ZoomedTileInstrumentRaw = createSwitchChildren(
  'instrument',
  'ZoomedTileInstrument',
  zoomedInstrumentChildren,
);

export const ZoomedTileInstrument = withQuoteId(connectTileInstrument(ZoomedTileInstrumentRaw));
