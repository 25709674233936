export type Mifid2PanelActions =
  | ToggleMifid2Panel
  | Mifid2StartNewNegotiationEpic
  | Mifid2StartNewNegotiation
  | Mifid2StartNewNegotiationFailed
  | Mifid2RecordTimestampEpic
  | Mifid2RecordTimestampSent
  | Mifid2RecordTimestampReceived
  | Mifid2FinishNegotiation
  | Mifid2FinishNegotiationFailed
  | Mifid2RemoveNegotiation;

export interface ToggleMifid2Panel {
  type: 'TOGGLE_MIFID2_PANEL';
  open: boolean | undefined;
}
export function toggleMifid2Panel(open?: boolean | undefined): ToggleMifid2Panel {
  return {
    type: 'TOGGLE_MIFID2_PANEL',
    open,
  };
}

export interface Mifid2StartNewNegotiationEpic {
  type: 'MIFID2_START_NEW_NEGOTIATION_EPIC';
}
export function mifid2StartNewNegotiationEpic(): Mifid2StartNewNegotiationEpic {
  return {
    type: 'MIFID2_START_NEW_NEGOTIATION_EPIC',
  };
}

export interface Mifid2StartNewNegotiation {
  type: 'MIFID2_START_NEW_NEGOTIATION';
  negotiationId: string;
  timestamp: string;
}
export function mifid2StartNewNegotiation(
  negotiationId: string,
  timestamp: string,
): Mifid2StartNewNegotiation {
  return {
    type: 'MIFID2_START_NEW_NEGOTIATION',
    negotiationId,
    timestamp,
  };
}

export interface Mifid2StartNewNegotiationFailed {
  type: 'MIFID2_START_NEW_NEGOTIATION_FAILED';
}
export function mifid2StartNewNegotiationFailed(): Mifid2StartNewNegotiationFailed {
  return {
    type: 'MIFID2_START_NEW_NEGOTIATION_FAILED',
  };
}

export interface Mifid2RecordTimestampEpic {
  type: 'MIFID2_RECORD_TIMESTAMP_EPIC';
  negotiationId: string;
}
export function mifid2RecordTimestampEpic(negotiationId: string): Mifid2RecordTimestampEpic {
  return {
    type: 'MIFID2_RECORD_TIMESTAMP_EPIC',
    negotiationId,
  };
}

export interface Mifid2RecordTimestampSent {
  type: 'MIFID2_RECORD_TIMESTAMP_SENT';
  negotiationId: string;
  quoteId: string;
  timestamp: string;
}
export function mifid2RecordTimestampSent(
  negotiationId: string,
  quoteId: string,
  timestamp: string,
): Mifid2RecordTimestampSent {
  return {
    type: 'MIFID2_RECORD_TIMESTAMP_SENT',
    negotiationId,
    quoteId,
    timestamp,
  };
}

export interface Mifid2RecordTimestampReceived {
  type: 'MIFID2_RECORD_TIMESTAMP_RECEIVED';
  negotiationId: string;
  quoteId: string;
  success: boolean;
}
export function mifid2RecordTimestampReceived(
  negotiationId: string,
  quoteId: string,
  success: boolean,
): Mifid2RecordTimestampReceived {
  return {
    type: 'MIFID2_RECORD_TIMESTAMP_RECEIVED',
    negotiationId,
    quoteId,
    success,
  };
}

export interface Mifid2FinishNegotiation {
  type: 'MIFID2_FINISH_NEGOTIATION';
  negotiationId: string;
  dealDone: boolean;
}
export function mifid2FinishNegotiation(
  negotiationId: string,
  dealDone: boolean,
): Mifid2FinishNegotiation {
  return {
    type: 'MIFID2_FINISH_NEGOTIATION',
    negotiationId,
    dealDone,
  };
}

export interface Mifid2FinishNegotiationFailed {
  type: 'MIFID2_FINISH_NEGOTIATION_FAILED';
  negotiationId: string;
}
export function mifid2FinishNegotiationFailed(
  negotiationId: string,
): Mifid2FinishNegotiationFailed {
  return {
    type: 'MIFID2_FINISH_NEGOTIATION_FAILED',
    negotiationId,
  };
}

export interface Mifid2RemoveNegotiation {
  type: 'MIFID2_REMOVE_NEGOTIATION';
  negotiationId: string;
}
export function mifid2RemoveNegotiation(negotiationId: string): Mifid2RemoveNegotiation {
  return {
    type: 'MIFID2_REMOVE_NEGOTIATION',
    negotiationId,
  };
}
