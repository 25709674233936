const fontNameDisp = 'Neue Haas Grotesk W04 Disp';
const fonts: ReadonlyArray<[string, number]> = [
  ['Roboto', 300],
  ['Roboto', 400],
  ['Roboto', 500],
  ['Roboto', 700],
  ['Roboto', 900],
  [fontNameDisp, 400],
  [fontNameDisp, 500],
  [fontNameDisp, 700],
  ['Neue Haas Grotesk W04', 400],
  ['Neue Haas Grotesk W04', 500],
  ['Neue Haas Grotesk W04', 700],
  ['Neue Haas Grotesk Text', 400],
];

export const forceLoadFonts = () => {
  fonts.forEach((args) => loadFont(...args));
};

function loadFont(font: string, weight: number) {
  const span = document.createElement('span');
  span.textContent = font;
  span.style.fontFamily = font;
  span.style.fontWeight = weight.toString();
  span.className = 'hidden-font';
  document.body.appendChild(span);
  setTimeout(() => document.body.removeChild(span), 100);
}
