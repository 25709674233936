import type { Observable } from 'rxjs';
import type { Way } from 'state/share/productModel/litterals';
import type { HttpCall, ISgmeHttp } from 'utils/sgmeHttp';
import { transformEmailsToBase64 } from 'utils/base64';

export interface IAmericanForwardQuoteExecutePayload {
  replyToStream: string;
  rfsId: string;
  quoteId: string;
  way: Way;
  emailNotificationList: string;
  userLocalDateTime: string;
  lastQuoteFromBackTimestamp: number;
  lastQuoteReceivedByFrontTimestamp: number;
  executionClickByFrontTimestamp: number;
}

export interface IAmericanForwardQuoteExecuteRequestApi {
  msApiTimeout: number;
  executeQuoteRequest(payload: IAmericanForwardQuoteExecutePayload): Observable<HttpCall<void>>;
}

export function americanForwardQuoteExecuteRequestApi(http: ISgmeHttp): IAmericanForwardQuoteExecuteRequestApi {
  return {
    executeQuoteRequest(payload: IAmericanForwardQuoteExecutePayload) {
      return http.post<void>(
        'api/fxOption/rfs/execute',
        transformEmailsToBase64(payload, 'emailNotificationList', 'email64NotificationList'),
        { 'X-Correlation-ID': payload.rfsId },
      );
    },
    msApiTimeout: 60_000,
  };
}
