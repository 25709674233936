import type { DetailsPanelMode } from '../blotterModel';

export type BlotterPanelAction =
  | BlotterShowDetailsPanel
  | BlotterHideDetailsPanel
  | BlotterSetDetailsPanelMode
  | BlotterChangeDetailsPanelUnexpectedError;

export interface BlotterShowDetailsPanel {
  type: 'BLOTTER_SHOW_DETAILS_PANEL';
  selectedId: string;
  mode: DetailsPanelMode;
  childrenIds: readonly string[] | null;
}

export function blotterShowDetailsPanel(
  selectedId: string,
  mode: DetailsPanelMode,
  childrenIds: readonly string[] | null = null,
): BlotterShowDetailsPanel {
  return {
    type: 'BLOTTER_SHOW_DETAILS_PANEL',
    selectedId,
    mode,
    childrenIds,
  };
}

export interface BlotterHideDetailsPanel {
  type: 'BLOTTER_HIDE_DETAILS_PANEL';
}

export function blotterHideDetailsPanel(): BlotterHideDetailsPanel {
  return {
    type: 'BLOTTER_HIDE_DETAILS_PANEL',
  };
}

export interface BlotterSetDetailsPanelMode {
  type: 'BLOTTER_SET_DETAILS_PANEL_MODE';
  mode: DetailsPanelMode;
  saveError?: string;
}

export function blotterSetDetailsPanelMode(
  mode: DetailsPanelMode,
  saveError?: string,
): BlotterSetDetailsPanelMode {
  return {
    type: 'BLOTTER_SET_DETAILS_PANEL_MODE',
    mode,
    saveError,
  };
}

export interface BlotterChangeDetailsPanelUnexpectedError {
  type: 'BLOTTER_CHANGE_DETAILS_PANEL_UNEXPECTED_ERROR';
  hasError: boolean;
}

export function blotterChangeDetailsPanelUnexpectedError(
  hasError: boolean,
): BlotterChangeDetailsPanelUnexpectedError {
  return {
    type: 'BLOTTER_CHANGE_DETAILS_PANEL_UNEXPECTED_ERROR',
    hasError,
  };
}
