import type { CurrencyPairData } from 'state/referenceData/referenceDataModel';

const normalizeInput = (input: string): string[] => {
  if (input === '') {
    return [];
  }
  if (/^[A-Z]{4,}$/i.test(input)) {
    return [input.substr(0, 3), input.substr(3)];
  }
  return input.replace('/', ' ').split(' ');
};

const createFindStartRegexp = (s: string) => new RegExp(`^${s}`, 'i');

export const currencyPairFilter = (input: string) => {
  const [re1, re2] = normalizeInput(input).map(createFindStartRegexp);

  return ({ ccy1, ccy2 }: CurrencyPairData): boolean => {
    if (re1 === undefined) {
      return false;
    } else if (re2 === undefined) {
      return re1.test(ccy1) || re1.test(ccy2);
    } else {
      return (re1.test(ccy1) && re2.test(ccy2)) || (re2.test(ccy1) && re1.test(ccy2));
    }
  };
};

export const currencyPairEmphasize = (input: string) => {
  const regexps = normalizeInput(input)
    .sort((a, b) => b.length - a.length)
    .map(createFindStartRegexp);

  return ({ ccy1, ccy2 }: CurrencyPairData): string =>
    [ccy1, ccy2]
      .map(ccy => regexps.reduce((acc, regexp) => acc.replace(regexp, '<strong>$&</strong>'), ccy))
      .join('/');
};
