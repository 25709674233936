import {
  type ValueKey,
  getValueProperty,
  type SharedKey,
  getSharedProperty,
  type SharedPropertyStatus,
} from 'state/share/form';
import type {
  IFxOrderValues as OrderValue,
  IFxOrderInputs as OrderInput,
  IFxOrderMetadata,
} from '../fxOrdersModel';
import type { AppState } from 'state/model';
import { getOrderState } from './fxOrdersProductSelectors';

export type OrderMetadataStatus<T extends keyof IFxOrderMetadata> = IFxOrderMetadata[T];

export function getOrderMetadata<T extends keyof IFxOrderMetadata>(property: T) {
  return (state: AppState, orderId: string): OrderMetadataStatus<T> =>
    getOrderState(state, orderId)[property];
}

type OrderValuePropertyKey = ValueKey<OrderValue, OrderInput>;

export function getOrderValue<T extends OrderValuePropertyKey>(prop: T) {
  const get = getValueProperty<OrderValue, OrderInput, T>(prop);
  return (state: AppState, orderId: string) => get(getOrderState(state, orderId));
}

type OrderSharedPropertyKey = SharedKey<OrderValue, OrderInput>;

export function getOrderInput<T extends OrderSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<OrderValue, OrderInput, T>(prop);
  return (state: AppState, orderId: string) => get(getOrderState(state, orderId));
}

export type OrderSharedPropertyStatus<T extends OrderSharedPropertyKey> = SharedPropertyStatus<
  OrderValue[T],
  OrderInput[T]
>;
