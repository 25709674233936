import type {
  InstrumentChoice,
  Client,
  UserInfo,
  EspLimits,
  FeatureToggles,
  ProductsAccess,
  ClosedDates,
} from './referenceDataModel';

export type ReferenceDataAction =
  | ReferenceDataReceived
  | RetrieveClosedDates
  | ClosedDatesRetrieved
  | EspLimitsAddCurrencies
  | RetrieveEspLimitsEpic
  | EspLimitsRetrieved
  | RetrieveEspLimitsFailedEpic
  | PinClientAction
  | UnpinClientAction
  | MoveUpPinnedClientAction
  | MoveDownPinnedClientAction
  | RetrievePinnedClientsAction;

export interface ReferenceDataReceived {
  type: 'REFERENCE_DATA_RECEIVED';
  productTypes: readonly InstrumentChoice[];
  userInfo: UserInfo;
  matomoLogin: string;
  userClients: readonly Client[];
  featureToggles: FeatureToggles;
  productsAccess: ProductsAccess;
  localMarketsCcy: readonly string[];
}

export function referenceDataReceived(
  productTypes: readonly InstrumentChoice[],
  userInfo: UserInfo,
  matomoLogin: string,
  userClients: readonly Client[],
  featureToggles: FeatureToggles,
  productsAccess: ProductsAccess,
  localMarketsCcy: readonly string[],
): ReferenceDataReceived {
  return {
    type: 'REFERENCE_DATA_RECEIVED',
    productTypes,
    userInfo,
    matomoLogin,
    userClients,
    featureToggles,
    productsAccess,
    localMarketsCcy,
  };
}

export interface RetrieveClosedDates {
  type: 'RETRIEVE_CLOSED_DATES';
  currencyPairs: readonly string[];
}
export function retrieveClosedDates(currencyPairs: readonly string[]): RetrieveClosedDates {
  return {
    type: 'RETRIEVE_CLOSED_DATES',
    currencyPairs,
  };
}

export interface ClosedDatesRetrieved {
  type: 'CLOSED_DATES_RETRIEVED';
  closedDates: ClosedDates;
}
export function closedDatesRetrieved(closedDates: ClosedDates): ClosedDatesRetrieved {
  return {
    type: 'CLOSED_DATES_RETRIEVED',
    closedDates,
  };
}

export interface EspLimitsAddCurrencies {
  type: 'ESP_LIMITS_ADD_CURRENCIES';
  currencies: readonly string[];
}

export function espLimitsAddCurrencies(currencies: readonly string[]): EspLimitsAddCurrencies {
  return {
    type: 'ESP_LIMITS_ADD_CURRENCIES',
    currencies,
  };
}

export interface RetrieveEspLimitsEpic {
  type: 'RETRIEVE_ESP_LIMITS_EPIC';
  currencies: readonly string[];
}

export function retrieveEspLimitsEpic(currencies: readonly string[]): RetrieveEspLimitsEpic {
  return {
    type: 'RETRIEVE_ESP_LIMITS_EPIC',
    currencies,
  };
}

export interface EspLimitsRetrieved {
  type: 'ESP_LIMITS_RETRIEVED';
  limits: EspLimits;
}

export function espLimitsRetrieved(limits: EspLimits): EspLimitsRetrieved {
  return {
    type: 'ESP_LIMITS_RETRIEVED',
    limits,
  };
}

export interface RetrieveEspLimitsFailedEpic {
  type: 'RETRIEVE_ESP_LIMITS_FAILED_EPIC';
  currencies: readonly string[];
  retryAfterMs: number | null;
}

export function retrieveEspLimitsFailedEpic(
  currencies: readonly string[],
  retryAfterMs: number | null,
): RetrieveEspLimitsFailedEpic {
  return {
    type: 'RETRIEVE_ESP_LIMITS_FAILED_EPIC',
    currencies,
    retryAfterMs,
  };
}

export interface PinClientAction {
  type: 'PIN_CLIENT';
  companyId: number;
}

export function pinClientAction(companyId: number): PinClientAction {
  return {
    type: 'PIN_CLIENT',
    companyId,
  };
}

export interface UnpinClientAction {
  type: 'UNPIN_CLIENT';
  companyId: number;
}

export function unpinClientAction(companyId: number): UnpinClientAction {
  return {
    type: 'UNPIN_CLIENT',
    companyId,
  };
}

export interface MoveUpPinnedClientAction {
  type: 'MOVE_UP_PINNED_CLIENT';
  companyId: number;
}

export function moveUpPinnedClientAction(companyId: number): MoveUpPinnedClientAction {
  return {
    type: 'MOVE_UP_PINNED_CLIENT',
    companyId,
  };
}

export interface MoveDownPinnedClientAction {
  type: 'MOVE_DOWN_PINNED_CLIENT';
  companyId: number;
}

export function moveDownPinnedClientAction(companyId: number): MoveDownPinnedClientAction {
  return {
    type: 'MOVE_DOWN_PINNED_CLIENT',
    companyId,
  };
}

export interface RetrievePinnedClientsAction {
  type: 'RETRIEVE_PINNED_CLIENT';
  clients: readonly number[];
}

export function retrievePinnedClientsAction(
  clients: readonly number[],
): RetrievePinnedClientsAction {
  return {
    type: 'RETRIEVE_PINNED_CLIENT',
    clients,
  };
}
