import { FormattedMessage } from 'react-intl';
import { ClientPicker, type ClientPickerProps } from 'components/ClientPicker';
import { button } from 'components/Form/Inputs/e2e';
import styled from 'styled-components';

export interface TileClientPickerProps extends Omit<ClientPickerProps, 'banner'> {
  isClientOverridden: boolean;
  onReset(): void;
}

const Banner = styled.div.attrs(() => ({
  className: 'alert alert-outline-warning p-2 mb-2 fw-bold d-flex align-items-center justify-content-between',
}))`
  width: calc(0.375rem * 6 + 600px);
`;

export const TileClientPicker: React.FunctionComponent<TileClientPickerProps> = (props: TileClientPickerProps) => {
  const handleReset = () => {
    props.onReset();
    props.close();
  };
  return (
    <ClientPicker
      banner={
        <Banner>
          <span>
            <FormattedMessage id="tile.changeAccountMsg" />
          </span>
          {props.isClientOverridden && (
            <button
              className="btn btn-default ms-2 text-nowrap"
              onClick={handleReset}
              data-e2e={button('resetAccount')}
            >
              <FormattedMessage id="tile.resetAccount" />
            </button>
          )}
        </Banner>
      }
      {...props}
    />
  );
};
