import type { Thunk } from 'state';
import { SPOT_TENOR } from 'components/share/tenors';
import { fieldData } from 'utils/fieldSelectors';
import type { TieringInfo } from 'state/share/tieringModel';
import type { ProductName } from 'state/share/productModel';
import { isEmpty } from '@sgme/fp';

/* When we are ready to switch to a new ESP stream because some critical stream related data
 * changed (client, currency pair, product name/instrument).
 *
 * Check whether the tile support ESP and either request a new streamKey (mostly tiering)
 * or immediately cancel the existing ESP stream
 */
export function espStreamRestartThunk(
  tileId: string,
  newCurrencyPair?: string | null,
  newProductName?: ProductName,
  newCompanyId?: number,
  newTenor?: string | null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isTilePresent(state, tileId)) {
      return;
    }
    const currencyPair = newCurrencyPair ?? sl.getTileCurrencyPair(state, tileId).value;

    if (currencyPair === null) {
      return;
    }

    const productName = newProductName ?? sl.getProductName(state, tileId);

    const companyId = newCompanyId ?? sl.getCompanyIdFromQuoteId(state, tileId);

    const tenor =
      newTenor ??
      (productName === 'FxSpot' || productName === 'FxFwd'
        ? sl.getCashMaturityDateTenor(state, tileId).value
        : SPOT_TENOR);

    const isTileEspCompatible = sl.isProductEspCompatible(state, tileId);

    if (isTileEspCompatible) {
      dispatch(
        ac.espTieringRequestEpic(tileId, currencyPair!, companyId!, tenor!),
      );
    } else {
      dispatch(ac.espTileStreamUnsubscribeThunk(tileId));
    }
  };
}

export function buildStreamKey(currencyPair: string, tiering: string, tenor: string) {
  const streamKey = `${currencyPair}&&${tiering}`;
  if (tenor === SPOT_TENOR) {
    return streamKey;
  }
  return `${streamKey}&&${tenor}`;
}

/* When we have retrieved tiering (streamKey) from backend
 *
 * May cancel the current ESP (if streamKey changed), and subscribe to a new one using an epic
 */
export function espTieringRequestSuccessThunk(
  tileId: string,
  tieringInfo: TieringInfo,
  currencyPair: string,
  companyId: number,
  previousStreamKey: string | null,
  tenor: string,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isTilePresent(state, tileId) || !sl.isProductEspCompatible(state, tileId)) {
      return;
    }

    const streamKey = buildStreamKey(currencyPair, tieringInfo.tiering, tenor);
    if (previousStreamKey !== null) {
      if (previousStreamKey === streamKey) {
        dispatch(ac.espStreamReconnected(tileId, streamKey, tieringInfo.defaultTiering));
        return;
      } else {
        dispatch(ac.espTileStreamUnsubscribeThunk(tileId, previousStreamKey));
      }
    }

    const refCount = sl.getEspStreamRefCount(state, streamKey);

    if (refCount !== null && refCount > 0) {
      dispatch(ac.espTileUpdatedThunk(tileId, streamKey, tieringInfo.defaultTiering));
    } else {
      const connectionId = sl.getConnectionId(state);
      dispatch(
        ac.espSubscriptionRequestedEpic(
          tileId,
          tieringInfo,
          streamKey,
          currencyPair,
          companyId,
          connectionId,
        ),
      );
    }
  };
}

export function espTileUpdatedThunk(
  tileId: string,
  streamKey: string,
  isDefaultTiering: boolean,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    if (!sl.isTilePresent(state, tileId) || !sl.isProductEspCompatible(state, tileId)) {
      return;
    }

    dispatch(ac.espTileStreamIdAndRefcountUpdated(tileId, streamKey, isDefaultTiering));
  };
}

/* When backend subscribed us successfully to an ESP stream
 *
 * We try to detect here race-conditions where the tile state has changed while the
 * subscription was in progress and the ESP is no longer valid.
 */
export function espTileSubscriptionRequestSuccessThunk(
  tileId: string,
  espStreamId: string,
  espTieringInfo: TieringInfo,
  currencyPair: string,
  companyId: number,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();

    const productName = sl.getProductName(state, tileId);
    const actualCurrencyPair = sl.getTileCurrencyPair(state, tileId).value;
    const { companyId: actualCompanyId } = sl.getClientForTile(state, tileId);

    const isEspRequestContextChanged = (
      !sl.isTilePresent(state, tileId)
      || !sl.isProductEspCompatible(state, tileId)
      || actualCompanyId !== companyId
      || actualCurrencyPair !== currencyPair
    )

    if (isEspRequestContextChanged) {
      dispatch(espCancelInvalidSubscriptionThunk(tileId, espStreamId, espTieringInfo));
      return;
    }

    // get espStream
    const espStream = sl.getEspStreamState(state, espStreamId);

    // if esp stream does not exist
    if (espStream === undefined) {
      // then create esp stream in stream collection and add its id to the tile
      dispatch(ac.espRefcountReset(espStreamId, espTieringInfo.tiering));
      dispatch(ac.espStartPricesReceptionEpic(espStreamId));
    } else if (productName === 'FxSpot') {
      const amount = sl.getCashAmount(state, tileId).value;
      const { data: amountCurrency } = fieldData(sl.getCashAmountCurrency(state, tileId));

      // get margin from esp stream
      const marginPatch = sl.getCashEspStreamMargin(state, espStreamId, amount, amountCurrency);

      dispatch(ac.cashLocalPropertyChanged(tileId, marginPatch));
    }

    dispatch(ac.espTileUpdatedThunk(tileId, espStreamId, espTieringInfo.defaultTiering));
  };
}

/* When we just subcribed to an ESP stream, and we want to cancel immediately
 * that subscription because the tile has changed in the meantime.
 */
function espCancelInvalidSubscriptionThunk(
  tileId: string,
  espStreamId: string,
  espTieringInfo: TieringInfo,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();

    const connectionId = sl.getConnectionId(state);
    const espStream = sl.getEspStreamState(state, espStreamId);

    if (espStream === undefined) {
      // if esp stream does not exist yet, create it so that we can
      // unsubscribe from it in dedicated epic
      dispatch(ac.espRefcountReset(espStreamId, espTieringInfo.tiering));
    }

    dispatch(ac.espStreamUnsubscribe(tileId, connectionId, espStreamId));
  };
}

/*  Send un-subscribe to backend */
export function espTileStreamUnsubscribeThunk(
  tileId: string,
  previousEspStreamId: string | null = null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    const streamKey = previousEspStreamId || sl.getTileEspStreamId(state, tileId);

    if (streamKey !== null) {
      const refCount = sl.getEspStreamRefCount(state, streamKey);
      const connectionId = sl.getConnectionId(state);

      dispatch(ac.espStreamTileUnsubscribe(tileId, streamKey));
      if (refCount && refCount === 1) {
        dispatch(ac.espStreamUnsubscribe(tileId, connectionId, streamKey));
      }
    }
  };
}

export function cashHeartbeatMissedESPStreamsRefreshThunk(): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (sl.isStreamingDisconnected(state)) {
      return;
    }
    const connectionId = sl.getConnectionId(state);
    const streamKeys = sl.getHeartbeatMissedEspStreams(state);
    if (!isEmpty(streamKeys)) {
      dispatch(ac.espStreamsRefreshEpic(connectionId, streamKeys));
    }
  };
}
