import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { assertUnreachable } from '@sgme/fp';

export interface SpinnerConnectOwnProps {
  quoteId: string;
}

export interface SpinnerConnectStateProps {
  waiting: boolean;
}

export type SpinnerConnectSelectorsKeys =
  | 'getTileState'
  | 'getCashPropertiesRequested'
  | 'getOptionPropertiesRequested'
  | 'getSwapPropertiesRequested'
  | 'getBulkPropertiesRequested'
  | 'getAccumulatorPropertiesRequested'
  | 'getOrderPropertiesRequested'
  | 'getAmericanForwardPropertiesRequested'
  | 'getSmartRfsRequested';
export type SpinnerConnectSelectors = Pick<Selectors, SpinnerConnectSelectorsKeys>;

export const mapStateToPropsSpinner: MapStateToPropsHOF<
  SpinnerConnectStateProps,
  SpinnerConnectOwnProps,
  AppState,
  SpinnerConnectSelectors
> =
  (sl) =>
    (state, { quoteId }) => {
      const { instrument } = sl.getTileState(state, quoteId);
      /**
       * @todo tile selector
       */
      switch (instrument) {
        case 'Cash':
          return { waiting: sl.getCashPropertiesRequested(state, quoteId) };
        case 'Option':
          return { waiting: sl.getOptionPropertiesRequested(state, quoteId) };
        case 'Swap':
          return { waiting: sl.getSwapPropertiesRequested(state, quoteId) };
        case 'Bulk':
          return { waiting: sl.getBulkPropertiesRequested(state, quoteId) };
        case 'Accumulator':
          return {
            waiting: sl.getAccumulatorPropertiesRequested(state, quoteId),
          };
        case 'Order':
          return { waiting: sl.getOrderPropertiesRequested(state, quoteId) };
        case 'AmericanForward':
          return { waiting: sl.getAmericanForwardPropertiesRequested(state, quoteId) };
        case 'BlotterOrder':
          /** @todo probably change this */
          return { waiting: false };
        case 'SmartRfs':
          return { waiting: sl.getSmartRfsRequested(state, quoteId) };
        default:
          assertUnreachable(instrument, `Unknown instrument reached in spinner map state to props`);
      }
    };
