import { FormattedMessage } from 'react-intl';
import type { UserPreferencesData } from 'state/userPreferences';

interface UserPrefLabelProps {
  label: keyof UserPreferencesData | 'theme';
  children: React.ReactNode;
}

export const UserPrefLabel: React.FunctionComponent<UserPrefLabelProps> = ({ label, children }) => (
  <div className="card-body d-flex align-items-center justify-content-between p-3 px-md-4 border-bottom">
    <label className="m-0">
      <FormattedMessage id={`userPreferencesForm.${label}.label`} />
    </label>
    {children}
  </div>
);
