export type StreamingConnectionAction =
  | StreamingConnected
  | StreamingReconnected
  | StreamingDisconnected
  | StreamingDegraded
  | StreamingHealthy;

export interface StreamingConnected {
  type: 'STREAMING_CONNECTED';
  connectionId: string;
  driver: string;
}

export function streamingConnected(connectionId: string, driver: string): StreamingConnected {
  return {
    type: 'STREAMING_CONNECTED',
    connectionId,
    driver,
  };
}

export interface StreamingReconnected {
  type: 'STREAMING_RECONNECTED';
  connectionId: string;
  driver: string;
}

export function streamingReconnected(connectionId: string, driver: string): StreamingReconnected {
  return {
    type: 'STREAMING_RECONNECTED',
    connectionId,
    driver,
  };
}

export interface StreamingDisconnected {
  type: 'STREAMING_DISCONNECTED';
  reason: string;
}

export function streamingDisconnected(reason: string): StreamingDisconnected {
  return {
    type: 'STREAMING_DISCONNECTED',
    reason,
  };
}

export interface StreamingDegraded {
  type: 'STREAMING_DEGRADED';
}

export function streamingDegraded(): StreamingDegraded {
  return {
    type: 'STREAMING_DEGRADED',
  };
}

export interface StreamingHealthy {
  type: 'STREAMING_HEALTHY';
}

export function streamingHealthy(): StreamingHealthy {
  return {
    type: 'STREAMING_HEALTHY',
  };
}
