export function filterArray<T>(
  array: readonly T[],
  func: (value: T, index: number, target: readonly T[]) => boolean,
) {
  let pristine = true;
  const filter = (value: T, index: number, target: readonly T[]) => {
    const bool = func(value, index, target);
    if (bool === false) {
      pristine = false;
    }
  };
  const newArray = array.filter(filter);
  return pristine === true ? array : newArray;
}

export function insertInArray<T>(array: readonly T[], value: T) {
  if (array.includes(value) === true) {
    return array;
  }
  return [...array, value];
}
