import type { FxPriceRecordState, PriceRecord } from './priceRecordModel';
import type { Patcher } from 'utils/stateMap';

export function addPriceRecorded<T extends FxPriceRecordState>(action: {
  priceRecord: PriceRecord;
}): Patcher<T> {
  return state =>
    ({
      priceRecords: [...state.priceRecords, action.priceRecord] as readonly PriceRecord[],
    } as Partial<T>);
}
