import type { IFormData } from 'state/share/form';
import type { IPatch } from 'state/share/patchModels';
import type { CurrencyChoice, Possible, Side } from 'state/share/productModel/litterals';
import type { Collection } from 'typings/utils';
import type { StrictExclude } from 'utils/object';
import type { PriceType } from './common';

export type FxOptionLegStateMap = Readonly<Collection<FxOptionLegState>>;

export type FxOptionLegState = VanillaLegState | TypedLegState | FxOptionMultilegState;

// ██╗   ██╗ █████╗ ██╗     ██╗   ██╗███████╗███████╗
// ██║   ██║██╔══██╗██║     ██║   ██║██╔════╝██╔════╝
// ██║   ██║███████║██║     ██║   ██║█████╗  ███████╗
// ╚██╗ ██╔╝██╔══██║██║     ██║   ██║██╔══╝  ╚════██║
//  ╚████╔╝ ██║  ██║███████╗╚██████╔╝███████╗███████║
//   ╚═══╝  ╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚══════╝╚══════╝
//

export type VanillaLegState = IFormData<IFxVanillaLegValues, IFxVanillaLegInputs> & {
  productName: 'Vanilla';
  solvingFor: OptionSolvableValue;
  solved: SolvedType;
};

export interface FxOptionVanillaLegMetadata {
  solvingFor: OptionSolvableValue;
  solved: SolvedType;
}

export type TypedLegState = IFormData<
  IFxOptionTypedStrategyLegValues,
  IFxOptionTypedStrategyLegInputs
> & {
  productName: TypedStrategyLegType;
  legIds: string[];
};

export type FxOptionMultilegState = IFormData<Record<string, never>, Record<string, never>> & {
  productName: 'FxOptionMultileg';
  legIds: string[];
};

export interface IFxVanillaLegValues {
  currency1: string | null;
  currency2: string | null;
  hedgePrice: number | null;
  optionType: OptionType | null;
  possibleOptionTypes: ReadonlyArray<[OptionType, string]> | null;
  expiryDate: string | null;
  expiryDateTenor: string | null;
  strike: string | null;
  isStrikeInDelta: boolean;
  notionalAmount: number | null;
  notionalCurrency: CurrencyChoice;
  deliveryDate: string | null;
  deliveryDateTenor: string | null;
  settlementType: SettlementType | null;
  possibleSettlementTypes: Possible<SettlementType> | null;
  cashSettlementCurrency: string | null;
  possibleCashSettlementCurrencies: Possible<string> | null;
  fixingReference1: string | null;
  possibleFixingReferences1: Possible<string> | null;
  marketPlace: string | null;
  possibleMarketPlaces: Possible<string> | null;
  premiumDate: string | null;
  premiumDateTenor: string | null;
  side: Side | null;
  isReadyToPrice: boolean;
  premiumBid: number | null;
  premiumAsk: number | null;
  isInFine: boolean | null;
  solvable: OptionSolvableValue | null;
  strikeShortcut: string | null;
  /**
   * @todo audit and delete
   */
  premiumTypeString: PriceType | null;
  premiumCurrency: CurrencyChoice | null;
  markup: number | null;
  volatilityBid: number | null;
  volatilityAsk: number | null;
}

export interface IFxOptionTypedStrategyLegValues {}

export type OptionSolvableValue = (typeof optionSolvableValues)[number];
export const defaultSolvingFor = 'PremiumPaymentAmount' as const;
export const optionSolvableValues = ['Strike', 'Amount1', defaultSolvingFor] as const;

// ██╗███╗   ██╗██████╗ ██╗   ██╗████████╗███████╗
// ██║████╗  ██║██╔══██╗██║   ██║╚══██╔══╝██╔════╝
// ██║██╔██╗ ██║██████╔╝██║   ██║   ██║   ███████╗
// ██║██║╚██╗██║██╔═══╝ ██║   ██║   ██║   ╚════██║
// ██║██║ ╚████║██║     ╚██████╔╝   ██║   ███████║
// ╚═╝╚═╝  ╚═══╝╚═╝      ╚═════╝    ╚═╝   ╚══════╝
//

export type FxOptionLegInputs = IFxVanillaLegInputs | IFxOptionTypedStrategyLegInputs;

export interface IFxVanillaLegInputs {
  productName: Exclude<LegType, 'FxOptionMultileg'>;
  optionType: OptionType;
  expiryDate: string | null;
  expiryDateTenor: string | null;
  strike: string | null;
  notionalAmount: string | null;
  notionalCurrency: CurrencyChoice;
  deliveryDate: string | null;
  settlementType: SettlementType | null;
  cashSettlementCurrency: string | null;
  fixingReference1: string | null;
  marketPlace: string | null;
  premiumDate: string | null;
  premiumDateTenor: string | null;
  side: Side;
  premiumBid: string | null;
  premiumAsk: string | null;
  isInFine: boolean;
  /**
   * @todo audit and delete
   */
  premiumTypeString: PriceType | null;
  premiumCurrency: CurrencyChoice | null;
  markup: string | null;
  volatilityBid: string | null;
  volatilityAsk: string | null;
}

// We have Vanilla as a possible productName for inputs and only for input.
// This result in a transient state where a Vanilla leg can have legsIds until TC sends u back the new structure with the Typed leg and its vanilla legs
export interface IFxOptionTypedStrategyLegInputs {
  productName: LegType;
  legIds: string[];
}

//  ██████╗ ████████╗██╗  ██╗███████╗██████╗
// ██╔═══██╗╚══██╔══╝██║  ██║██╔════╝██╔══██╗
// ██║   ██║   ██║   ███████║█████╗  ██████╔╝
// ██║   ██║   ██║   ██╔══██║██╔══╝  ██╔══██╗
// ╚██████╔╝   ██║   ██║  ██║███████╗██║  ██║
//  ╚═════╝    ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝
//

export const optionTypes = ['Call', 'Put'] as const;
export type OptionType = (typeof optionTypes)[number];

export type SettlementType = 'Cash' | 'Physical' | 'Forward' | 'NDF';

export type SolvedType = StrictExclude<OptionSolvableValue, typeof defaultSolvingFor> | null;

export const typedStrategyLegTypes = ['Straddle', 'Strangle', 'RiskReversal'] as const;
export type TypedStrategyLegType = (typeof typedStrategyLegTypes)[number];

export const strategyLegTypes = ['Vanilla', ...typedStrategyLegTypes] as const;

export const legTypes = ['FxOptionMultileg', ...strategyLegTypes] as const;
export type LegType = (typeof legTypes)[number];

export type FxOptionLegsPatchedValuesRecords = Record<string, FxOptionLegPatchedValues>;

export type FxOptionLegPatchedValues = IPatch<IFxVanillaLegValues> & {
  productName: LegType;
  legIds?: string[];
};
