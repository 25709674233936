import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { TabType } from 'state/clientWorkspace';

export interface TabContentsConnectOwnProps {
  tabId: string;
}

export interface TabContentsConnectStateProps {
  tabType: TabType;
  zoomedTile: string | null;
}

// export interface TabContentsConnectDispatchProps {
// }

// you can whitelist selectors after implementation
export type TabContentsConnectSelectors = Selectors;
/*
export type TabContentsConnectSelectorsKeys = 'aSelector'; // example
export type TabContentsConnectSelectors = Pick<Selectors, TabContentsConnectSelectorsKeys>;
*/

export const mapStateToPropsTabContents: MapStateToPropsHOF<
  TabContentsConnectStateProps,
  TabContentsConnectOwnProps,
  AppState,
  TabContentsConnectSelectors
> =
  sl =>
  (state, { tabId }) => ({
    tabType: sl.getTabType(state, tabId),
    zoomedTile: sl.getTabZoomedTile(state, tabId),
  });

// you can whitelist action creators after implementation
// export type TabContentsConnectActionCreators = ActionCreators;
// /*
// export type TabContentsConnectActionCreatorsKeys = 'optionLegPropertyChanged';
// export type TabContentsConnectActionCreators = Pick<ActionCreators, TabContentsConnectActionCreatorsKeys>;
// */

// export const mapDispatchToPropsTabContents: MapDispatchToPropsHOF<
//   TabContentsConnectDispatchProps,
//   TabContentsConnectOwnProps,
//   TabContentsConnectActionCreators
// > = _ac => (_dispatch, _ownProps) => ({
// });
