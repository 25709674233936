import type { Locus, GridState, GridItemPosition } from '../gridLayoutModels';
import { defaultHeights, colWidth, defaultOptionHeight } from 'styles/constants';
import { addKey } from 'utils/stateMap';
import { logger } from 'logging/logger';
import type { InstrumentChoice } from 'state/referenceData/referenceDataModel';

interface TileInfos {
  tileId: string;
  instrument: InstrumentChoice | 'Order' | 'BlotterOrder';
  position?: GridItemPosition;
}

export const addGridItem =
  ({ tileId, instrument, position = { top: 0, left: 0 } }: TileInfos) =>
  ({ gridLayout }: GridState): Pick<GridState, 'gridLayout'> => {
    const height = defaultHeights[instrument] || defaultOptionHeight;
    const { columns, gridItemPositions, gridItemSizes } = gridLayout;

    const newGridItemPositions = addKey(gridItemPositions, tileId, position);

    const newGridItemSizes = addKey(gridItemSizes, tileId, {
      height,
      width: colWidth,
    });

    const colNumber = Math.floor(position.left / colWidth);
    const { top } = position;
    const newTileLocus: Locus = {
      gridItemId: tileId,
      top,
      bottom: top + height,
    };
    const missingColNumber = colNumber + 1 - columns.length;
    const missingColumns =
      missingColNumber > 0 ? Array.from(Array(missingColNumber), () => []) : [];

    const newColumns = columns
      .concat(missingColumns)
      .map((column, index) =>
        index !== colNumber ? column : column.concat([newTileLocus]).sort(sortLocus),
      );
    return {
      gridLayout: {
        gridItemPositions: newGridItemPositions,
        gridItemSizes: newGridItemSizes,
        columns: newColumns,
      },
    };
  };

function sortLocus(a: Locus, b: Locus) {
  if (a.bottom === b.top) {
    return -1;
  }
  if (a.top === b.bottom) {
    return 1;
  }
  if (a.top < b.top && a.bottom < b.top) {
    return -1;
  }
  if (b.top < a.top && b.bottom < a.top) {
    return 1;
  }
  logger.logError(
    'invalid grid state: overlapping Loci: {locus_a_s} {locus_b_s}',
    JSON.stringify(a),
    JSON.stringify(b),
  );
  return -1;
}
