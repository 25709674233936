import type { Observable } from 'rxjs';
import type { HttpCall } from 'utils/sgmeHttp';

export interface ForexOptionExecuteRequestFrontDto {
  way: ForexOptionExecuteRequestFrontDtoWay | null;
  /** Id of RFS */
  rfsId: string | null;
  /** Quote ID within RFS */
  quoteId: string;
  /** Streaming connectionId to reply to */
  replyToStream: string;
  emailNotificationList: string;
  userLocalDateTime: string | null;
  lastQuoteFromBackTimestamp: number;
  lastQuoteReceivedByFrontTimestamp: number;
  executionClickByFrontTimestamp: number;
}

/* eslint-disable no-shadow */
export enum ForexOptionExecuteRequestFrontDtoWay {
  Bid = 'bid',
  Ask = 'ask',
}

export interface IAccumulatorRfsExecuteApi {
  msApiTimeout: number;
  executeQuote(payload: ForexOptionExecuteRequestFrontDto): Observable<HttpCall<void>>;
}
