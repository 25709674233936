import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';

export interface ViewSgLogExecutionErrorConnectOwnProps {
  executionId: string | null;
}

export interface ViewSgLogExecutionErrorConnectStateProps {
  hasError: boolean;
}

export interface ViewSgLogExecutionErrorConnectDispatchProps {
  onShowErrorDetail: () => void;
}

export type ViewSgLogExecutionErrorConnectSelectors = Selectors;

export const mapStateToPropsViewSgLogExecutionError: MapStateToPropsHOF<
  ViewSgLogExecutionErrorConnectStateProps,
  ViewSgLogExecutionErrorConnectOwnProps,
  AppState,
  ViewSgLogExecutionErrorConnectSelectors
> =
  sl =>
  (state, { executionId }) => {
    if (executionId === null || !sl.hasExecution(state, executionId)) {
      return { hasError: false };
    }
    const executionState = sl.getExecutionById(state, executionId);
    return { hasError: executionState.status === 'Error' };
  };

export type ViewSgLogExecutionErrorConnectActionCreators = ActionCreators;

export const mapDispatchToPropsViewSgLogExecutionError: MapDispatchToPropsHOF<
  ViewSgLogExecutionErrorConnectDispatchProps,
  ViewSgLogExecutionErrorConnectOwnProps,
  ViewSgLogExecutionErrorConnectActionCreators
> =
  ac =>
  (dispatch, { executionId }) => ({
    onShowErrorDetail() {
      dispatch(ac.displayExecutionErrorDetail(executionId!));
    },
  });
