import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type {
  TradeCaptureSwapRequestWrapper,
  TradeCaptureSwapResponseWrapper,
  SwapTradeCapturePatch,
} from './tradeCaptureModel';
import { extract } from 'utils/object';

export interface TradeCaptureApiRequest {
  sessionId: string;
  tileId: string;
  request: TradeCaptureSwapRequestWrapper;
}

export interface TradeCaptureBatchedResponses {
  [swapId: string]: TradeCaptureSwapResponseWrapper;
}

export interface TradeCaptureBatchedPatch {
  swapId: string;
  patch: SwapTradeCapturePatch | null;
}

export interface ITradeCaptureSwapApi {
  timeout: number;
  createNewVersions(
    queries: readonly TradeCaptureApiRequest[],
  ): Observable<TradeCaptureBatchedResponses>;
}

export function tradeCaptureApi(http: ISgmeHttp): ITradeCaptureSwapApi {
  return {
    createNewVersions(queries: TradeCaptureApiRequest[]) {
      return http
        .post<TradeCaptureBatchedResponses>(`api/tradeCaptureBatch/FxSwap`, queries)
        .pipe(map(extract('payload')));
    },
    timeout: 10_000,
  };
}
