import { ListUserPref } from './ListUserPref';
import { accountLengthOptions, dateInputCultureInfoOptions } from 'state/userPreferences';
import { hedgeTypes } from 'state/share/productModel/litterals';
import { allPriceTypes } from 'state/fxOptions/fxOptionsModel';

export const AccountNameLengthPref = ListUserPref('accountNameLength', accountLengthOptions);
export const DateInputFormatPref = ListUserPref(
  'dateInputCultureInfo',
  dateInputCultureInfoOptions,
);
export const DefaultHedgeTypePref = ListUserPref(
  'defaultHedgeType',
  hedgeTypes,
  'optiontile.form.hedge',
);
export const DefaultPriceTypePref = ListUserPref(
  'defaultPriceType',
  allPriceTypes,
  'optiontile.priceType',
);
export const OneClickExecPref = ListUserPref('oneClickExecution', [true, false]);
