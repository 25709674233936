import type { ISgmeHttp, HttpCall } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';
import type { UserPreferencesData } from 'state/userPreferences/userPreferencesModel';
import { ignoreElements } from 'rxjs/operators';
import { transformEmailsToBase64 } from 'utils/base64';

export interface IUserPreferencesServiceApi {
  timeout: number;
  save(userPreferences: UserPreferencesData): Observable<HttpCall<void>>;
}

export function userPreferencesServiceApi(http: ISgmeHttp): IUserPreferencesServiceApi {
  const EmailsTransformed = (userPreferences: UserPreferencesData) => {
    const transformEmails = transformEmailsToBase64(userPreferences, 'emails', 'emails');
    const transformEmailsCash = transformEmailsToBase64(
      transformEmails,
      'splitNotificationsEmailsCash',
      'splitNotificationsEmailsCash',
    );
    const transformEmailsOrders = transformEmailsToBase64(
      transformEmailsCash,
      'splitNotificationsEmailsOrders',
      'splitNotificationsEmailsOrders',
    );
    const transformEmailsOptions = transformEmailsToBase64(
      transformEmailsOrders,
      'splitNotificationsEmailsOptions',
      'splitNotificationsEmailsOptions',
    );
    return transformEmailsOptions;
  };
  return {
    save(userPreferences: UserPreferencesData) {
      return http
        .post<void>('api/userdata/userpref', EmailsTransformed(userPreferences))
        .pipe(ignoreElements());
    },
    timeout: 10_000,
  };
}
