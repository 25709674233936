import type { IReferenceDataApi } from 'epics/retrieveReferenceData';
import { correlationIdHeaderName, type ISgmeHttp } from 'utils/sgmeHttp';
import type { ClosedDates, EspLimits, User } from 'state/referenceData/referenceDataModel';
import { map } from 'rxjs/operators';
import { extract } from 'utils/object';
import { v4 as getNewGuid } from 'uuid';
import { logger } from 'logging/logger';

export const referenceDataApi = (
  http: ISgmeHttp,
  getGuid = getNewGuid,
  _logger = logger,
): IReferenceDataApi => ({
  getUserData() {
    const correlationId = getGuid();
    const headers = { [correlationIdHeaderName]: correlationId };
    _logger.logInformation(
      'Requesting user/current with {correlationId_s}',
      headers['X-Correlation-ID'],
    );
    return http.getJSON<User>('api/user/current', headers).pipe(map(extract('payload')));
  },
  getClosedDates(currencyPairs: readonly string[]) {
    return http.post<ClosedDates>('api/dates/closed', currencyPairs).pipe(map(extract('payload')));
  },
  getEspLimits(currencies: readonly string[]) {
    return http.post<EspLimits>('api/user/espLimits', currencies).pipe(map(extract('payload')));
  },
  timeout: 10_000,
});
