import { selectors } from 'state/selectors';
import { buildAccuExecutionReportWith } from './buildAccuExecutionReport';
import { buildAccuQuoteExecuteWith } from './buildAccuQuoteExecute';
import { metaSelectorTradeCaptureFromBackendWith } from './tradeCaptureFromBackend';
import {
  accumulatorChangedToPatchWith,
  accumulatorRestoreToPatchWith,
  accumulatorScheduleAddedToPatchWith,
  accumulatorScheduleChangedToPatchWith,
  accumulatorScheduleImportedToPatchWith,
  accumulatorScheduleRemovedToPatchWith,
  accumulatorScheduleResetToPatchWith,
} from './tradeCapturePatchBuilderFromAction';
import { metaSelectorTradeCaptureToBackendWith } from './tradeCaptureToBackend';

export const accumulatorChangedToPatch = accumulatorChangedToPatchWith(selectors);

export const accumulatorScheduleChangedToPatch = accumulatorScheduleChangedToPatchWith(selectors);

export const accumulatorScheduleResetToPatch = accumulatorScheduleResetToPatchWith(selectors);

export const accumulatorScheduleAddedToPatch = accumulatorScheduleAddedToPatchWith(selectors);

export const accumulatorScheduleRemovedToPatch = accumulatorScheduleRemovedToPatchWith(selectors);

export const accumulatorScheduleImportedToPatch = accumulatorScheduleImportedToPatchWith(selectors);

export const accumulatorRestoreToPatch = accumulatorRestoreToPatchWith(selectors);

export const createAccumulatorTradeCaptureRequest =
  metaSelectorTradeCaptureToBackendWith(selectors);

export const accumulatorResponseToPatchMetaSelector =
  metaSelectorTradeCaptureFromBackendWith(selectors);

export const buildAccuQuoteExecute = buildAccuQuoteExecuteWith(selectors);

export const buildAccuExecutionReport = buildAccuExecutionReportWith(selectors);
