import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';

export interface ExecutionPendingMailPayload {
  productType: string;
  tradeDate: Date;
  currencyPair: string;
  clientId: number;
}

export const metaSelectorBuildExecutionPendingMailPayloadWith: MapStateToMetadataHOF<
  ExecutionPendingMailPayload,
  string,
  AppState,
  Selectors
> = sl => (state, executionId) => {
  const execution = sl.getExecutionById(state, executionId);
  return {
    clientId: execution.client.companyId,
    tradeDate: execution.tradeDate,
    productType: execution.instrument,
    currencyPair: execution.currencyPair,
  };
};
