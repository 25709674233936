export const shallowEqual = function shallowEqual(objA: any, objB: any) {
  // early equality detection
  if (Object.is(objA, objB)) {
    return true;
  }
  // defensive assertion that args are iterable objects
  if (!isObject(objA) || !isObject(objB)) {
    return false;
  }
  // key by key equality assertion
  const A = Object.keys(objA);
  const B = Object.keys(objB);
  // early difference inferable from length difference
  if (A.length !== B.length) {
    return false;
  }
  // compare values for every keys
  return A.every(p => hasOwnProperty.call(objB, p) && Object.is(objA[p], objB[p]));
};

const hasOwnProperty = Object.prototype.hasOwnProperty;
const isObject = (obj: any) => obj !== null && typeof obj === 'object';
