import type { FxAccumulatorRfsStreams, FxAccumulatorRfsStream } from '../fxAccumulatorsModel';
import { updateKey, addKey, removeKey } from 'utils/stateMap';
import type { Action } from 'state/actions';
import type { Reducer } from 'redux';
import { immutableReferenceUpdate } from 'utils/immutableReferenceUpdate';

const defaultFxAccumulatorRfsStreamState = {};

export const fxAccumulatorsRfsStreams: Reducer<FxAccumulatorRfsStreams, Action> = (
  state = defaultFxAccumulatorRfsStreamState,
  action,
) => {
  switch (action.type) {
    case 'ACCUMULATOR_RFS_STARTED':
      return addKey<FxAccumulatorRfsStream>(state, action.rfsId, {
        status: 'AWAITING',
        forceTradeable: action.forceTradeable,
        traderId: null,
      });

    case 'ACCUMULATOR_RFS_QUOTE_RECEIVED':
      return updateKey(state, action.rfsId, currentState => {
        const initialRfsWindow =
          currentState.status === 'AWAITING'
            ? action.quote.rfsWindow
            : currentState.initialRfsWindow;

        const newState: FxAccumulatorRfsStream = {
          status: 'PRICING',
          quote:
            currentState.status === 'PRICING'
              ? immutableReferenceUpdate(currentState.quote, action.quote)
              : action.quote,
          initialRfsWindow,
          lastQuoteReceivedDate: action.quoteDate,
          tiering: action.tiering,
          skippedLimitCheck: action.skippedLimitCheck,
          traderId: currentState.traderId,
          quoteContribution: action.quoteContribution,
        };

        return newState;
      });

    case 'ACCUMULATOR_TRADER_NOTIFICATION_RECEIVED':
      return updateKey<FxAccumulatorRfsStream>(state, action.rfsId, currentState => ({
        status: 'AWAITING',
        traderId: action.traderId,
        forceTradeable: currentState.status === 'AWAITING' ? currentState.forceTradeable : false,
      }));

    case 'ACCUMULATOR_EXECUTION_SENT':
      return updateKey<FxAccumulatorRfsStream>(state, action.executionId, () => ({
        status: 'EXPIRED',
      }));

    case 'ACCUMULATOR_RFS_CANCELED':
    case 'ACCUMULATOR_RFS_TERMINATED':
      if (!action.shouldKeepAsExpired) {
        return removeKey(state, action.rfsId);
      }
      return updateKey(state, action.rfsId, () => ({ status: 'EXPIRED' }));

    case 'ACCUMULATOR_RFS_FAILED':
    case 'ACCUMULATOR_RFS_LAST_REMOVED':
      return removeKey(state, action.rfsId);

    default:
      return state;
  }
};
