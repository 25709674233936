import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { IFxSwapInputs, PatchLegs } from 'state/fxSwaps/fxSwapsModel';
import type {
  SwapTradeCaptureRequestLegProperties,
  TradeCaptureSwapRequestWrapper,
} from 'api/tradeCapture/swap/tradeCaptureModel';

import { parseWithCultureInfo } from 'utils/parseDateWithCultureInfo';
import type { BidAsk } from 'state/share/productModel/litterals';

interface WithSwapId {
  swapId: string;
}

export type SwapStoreModelChanges = Partial<Pick<IFxSwapInputs, 'isNonDeliverable'>> & PatchLegs;

export type TradeCaptureToBackendMetaSelectorsKeys =
  | 'getSwapTradeCaptureIdVersion'
  | 'getUserPreferenceData'
  | 'getProductsAccess';

export type TradeCaptureToBackendMetaSelectorSelectors = Pick<
  Selectors,
  TradeCaptureToBackendMetaSelectorsKeys
>;

export type TradeCaptureToBackendMetadata = {
  patch: SwapStoreModelChanges;
} & WithSwapId;

// ---------------------------------------------------------------------------------
// Public meta selector
// ---------------------------------------------------------------------------------

export const swapTradeCaptureToBackendWith: MapStateToMetadataHOF<
  TradeCaptureSwapRequestWrapper,
  TradeCaptureToBackendMetadata,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> =
  sl =>
  (state, { swapId, patch }) => {
    const idVersion = getNextIdVersion(sl.getSwapTradeCaptureIdVersion(state, swapId));
    const { dateInputCultureInfo: cultureInfo } = sl.getUserPreferenceData(state);

    const {
      legs: { 0: legPatch },
      ...productPatch
    } = patch;

    let tradeCapturelegPatch: Partial<SwapTradeCaptureRequestLegProperties> = {
      productName: 'FxSwap',
    };
    if (legPatch !== undefined) {
      const nearPaymentDate =
        legPatch.nearPaymentDate === undefined
          ? undefined
          : legPatch.nearPaymentDate === null
          ? null
          : parseWithCultureInfo(cultureInfo, legPatch.nearPaymentDate);
      const farPaymentDate =
        legPatch.farPaymentDate === undefined
          ? undefined
          : legPatch.farPaymentDate === null
          ? null
          : parseWithCultureInfo(cultureInfo, legPatch.farPaymentDate);
      tradeCapturelegPatch = {
        ...tradeCapturelegPatch,
        currencyPair: legPatch.currencyPair,
        nearPaymentDateString: nearPaymentDate ?? legPatch.nearPaymentDateTenor,
        farPaymentDateString: farPaymentDate ?? legPatch.farPaymentDateTenor,
        isUneven: legPatch.isUneven,
        swapOffMarket: legPatch.isOffMarket,
        xCurrency: legPatch.xCurrency,
        fixingSource: legPatch.fixingSource,
        sndFixingSource: legPatch.sndFixingSource,
        ...mapPriceReferenceToTradeCapture(legPatch.nearPriceReference, 'near'),
        ...mapPriceReferenceToTradeCapture(legPatch.farPriceReference, 'far'),
      };

      if (legPatch.currencyPair !== undefined) {
        if (sl.getProductsAccess(state).nonDeliverable === false) {
          productPatch.isNonDeliverable = false;
        }
      }

      if (legPatch.nearAmount !== undefined) {
        if (legPatch.amountCurrency === 1) {
          tradeCapturelegPatch.nearAmount1String = legPatch.nearAmount;
        } else if (legPatch.amountCurrency === 2) {
          tradeCapturelegPatch.nearAmount2String = legPatch.nearAmount;
        }
      }
      if (legPatch.farAmount !== undefined) {
        if (legPatch.amountCurrency === 1) {
          tradeCapturelegPatch.farAmount1String = legPatch.farAmount;
        } else if (legPatch.amountCurrency === 2) {
          tradeCapturelegPatch.farAmount2String = legPatch.farAmount;
        }
      }
    }

    return {
      idVersion,
      changedFields: {
        ...productPatch,
        legs: {
          0: tradeCapturelegPatch,
        },
      },
    };
  };

// ---------------------------------------------------------------------------------
// Private metaSelector
// ---------------------------------------------------------------------------------
function getNextIdVersion(lastIdVersion: number | null): number {
  return lastIdVersion == null ? 0 : lastIdVersion + 1;
}

function mapPriceReferenceToTradeCapture(
  data: BidAsk<string> | null | undefined,
  leg: 'near' | 'far',
): Partial<SwapTradeCaptureRequestLegProperties> {
  if (data === undefined) {
    return {};
  }
  if (data === null) {
    return {
      [`${leg}PriceReferenceAskString`]: null,
      [`${leg}PriceReferenceBidString`]: null,
    };
  }
  return {
    [`${leg}PriceReferenceAskString`]: data.ask,
    [`${leg}PriceReferenceBidString`]: data.bid,
  };
}
