import type { ISgmeHttp } from 'utils/sgmeHttp';
import { ignoreElements } from 'rxjs/operators';
import type { IAccumulatorRfsRequestApi } from 'epics/accumulator';

export function accumulatorRfsRequestApi(http: ISgmeHttp): IAccumulatorRfsRequestApi {
  return {
    msToFirstHearbeat: 120_000,
    createQuoteRequest(request) {
      return http
        .post<void>('api/fxAccumulator/rfs/request', request, {
          'X-Correlation-ID': request.rfsId,
        })
        .pipe(ignoreElements());
    },
  };
}
