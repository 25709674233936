import { emphasizeWithAccentsIgnored } from './emphasize';

export const HighlightMatches: React.FunctionComponent<{
  value: string;
  match: string;
}> = ({ value, match }) => (
  <>
    {emphasizeWithAccentsIgnored(value, match).map(({ highlight, text }, index) =>
      highlight ? <b key={index}>{text}</b> : text,
    )}
  </>
);
