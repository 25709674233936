import type { AppState } from 'state/model';
import { getElementById, throwIfUndefined } from 'utils/maps';
import type { Instrument } from 'state/referenceData/referenceDataModel';
import type { CollapsableKey } from '../clientWorkspaceModel';

function getQuotes({ clientWorkspace: { tiles } }: AppState) {
  return tiles;
}

// function getQuoteByIdUnsafe(state: AppState, quoteId: string) {
//   return getElementById(getQuotes(state), quoteId);
// }

function getQuoteById(state: AppState, quoteId: string) {
  return throwIfUndefined(
    getElementById(getQuotes(state), quoteId),
    `Quote with id ${quoteId} not found`,
  );
}

export function getWorkspaceIdByQuoteId(state: AppState, quoteId: string) {
  return getQuoteById(state, quoteId).clientWorkspaceId;
}

export function isQuotePresent(state: AppState, quoteId: string) {
  return getQuotes(state).hasOwnProperty(quoteId);
}

export function isKeyCollapsedInQuote(state: AppState, quoteId: string, keyName: CollapsableKey) {
  return !getQuoteById(state, quoteId).expanded.includes(keyName);
}

export function getQuoteInstrument(state: AppState, quoteId: string) {
  return getQuoteById(state, quoteId).instrument;
}

export function isQuotePresentWithInstrument(
  state: AppState,
  tileId: string,
  instrument: Instrument,
): boolean {
  return isQuotePresent(state, tileId) && getQuoteInstrument(state, tileId) === instrument;
}
