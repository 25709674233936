import { type RefObject, useEffect, useRef } from 'react';

export function useElementSize<Element extends HTMLElement>(
  elementRef: RefObject<Element>,
  onSize: (size: DOMRectReadOnly) => void,
) {
  const observer = useRef(
    new ResizeObserver(([{ contentRect }]) => {
      onSize(contentRect);
    }),
  );

  useEffect(() => {
    const currentObserver = observer.current;
    const currentElement = elementRef.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement !== null) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [elementRef, observer]);
}
