import type {
  ErrorLegs,
  ITradeCaptureMetaData,
  Legs,
  TradeCaptureRequestWrapper,
  TradeCaptureResponseWrapper,
} from '../tradeCaptureModel';
import type { CashProductName, FxCashPatch, IFxCashInputs } from 'state/fxCashs/fxCashsModel';
import type { CurrencyChoice, Possible } from 'state/share/productModel/litterals';

export type CashTradeCapturePatch = ITradeCaptureMetaData & FxCashPatch;
export type TradeCaptureCashRequestChanges = Legs<CashTradeCaptureRequestLegProperties>;
export type TradeCaptureCashRequestWrapper =
  TradeCaptureRequestWrapper<TradeCaptureCashRequestChanges>;
export type TradeCaptureCashResponseChanges =
  Partial<CashTradeCaptureResponsechangedFieldsProperties> &
    Legs<CashTradeCaptureResponseLegProperties>;
export type TradeCaptureCashResponseWrapper = TradeCaptureResponseWrapper<
  TradeCaptureCashResponseChanges,
  TradeCaptureCashErrors
>;
export type TradeCaptureCashErrors = ErrorLegs<CashTradeCaptureResponseLegProperties>;

export const SpotProductName = 'FxSpot';
export const CashLegId = '0';

export interface CashTradeCaptureResponsechangedFieldsProperties {
  currencyPair: string;
  currency1: string;
  currency2: string;
}

export interface CashTradeCaptureResponseLegProperties {
  productName: CashProductName;
  currencyPair: string;
  currency1: string;
  currency2: string;
  isReadyToPrice: boolean;
  isPriceObsolete: boolean;
  maturityDate: string;
  maturityDateTenor: string;
  negotiatedCurrency: CurrencyChoice;
  amount1: number | null;
  amount2: number | null;
  isNonDeliverable: boolean;
  fixingSource: string;
  fixingDate: string;
  fixingCurrency: string;
  possibleFixingCurrencies: Possible<string>;
  possibleFixingSources: Possible<string>;
  isCrossed: boolean;
  possibleXCurrencies: Possible<string>;
  possibleSndFixingSources: Possible<string>;
  xCurrency: string;
  sndFixingSource: string;
}

export interface CashTradeCaptureRequestLegProperties {
  currencyPair: string | null;
  productName: CashProductName;
  maturityDateString: string | null;
  amount1String: string | null;
  amount2String: string | null;
  isNonDeliverable: boolean | null;
  fixingCurrency: string | null;
  fixingSource: string | null;
  xCurrency: string | null;
  sndFixingSource: string | null;
}

interface PatchLeg {
  // cash legId
  0: Partial<IFxCashInputs>;
}

interface PatchLegs {
  currencyPair?: string | null;
  legs: Partial<PatchLeg>;
}

export type CashPropertiesChangedPatch = PatchLegs;

export interface ICashPropertiesChangedPatch {
  cashId: string;
  patch: CashPropertiesChangedPatch;
}
