import type { Observable } from 'rxjs';
import type { ISgmeHttp } from '../../../utils/sgmeHttp';
import { map } from 'rxjs/operators';
import { extract } from '../../../utils/object';
import {
  TradeCaptureAmericanForwardRequestWrapper,
  TradeCaptureAmericanForwardResponseWrapper,
} from './tradeCaptureModel';
import { AmericanForwardProductName } from '../../../state/fxAmericanForward/model/fxAmericanForwardProductModel';
import { AmericanForwardTradeCapturePatch } from '../../../epics/metaSelectors/americanForward/tradeCaptureFromBackend';

export interface TradeCaptureApiRequest {
  productType: AmericanForwardProductName;
  sessionId: string;
  tileId: string;
  request: TradeCaptureAmericanForwardRequestWrapper;
}

export interface TradeCaptureBatchedResponses {
  [americanForwardId: string]: TradeCaptureAmericanForwardResponseWrapper;
}

export interface TradeCaptureBatchedPatch {
  americanForwardId: string;
  patch: AmericanForwardTradeCapturePatch | null;
}

export interface TradeCaptureAmericanForwardApi {
  timeout: number;
  createNewBatchVersion(
    queries: readonly TradeCaptureApiRequest[],
  ): Observable<TradeCaptureBatchedResponses>;
}

export function tradeCaptureApi(http: ISgmeHttp): TradeCaptureAmericanForwardApi {
  return {
    createNewBatchVersion(queries) {
      return http
        .post<TradeCaptureBatchedResponses>('api/tradeCaptureBatch/FxAmericanForward', queries)
        .pipe(map(extract('payload')));
    },
    timeout: 10_000,
  };
}
