import type { Thunk } from 'state';

export function orderDefaultEmailsReceived(
  tabId: string,
  bdrId: string,
  emails: readonly string[],
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    dispatch(ac.orderDefaultEmails(bdrId, emails));

    const state = getState();
    if (!sl.isTabPresent(state, tabId)) {
      return;
    }
    const orderIds = sl
      .getClientWorkspaceTab(state, tabId)
      .tiles.filter(tileId => sl.getQuoteInstrument(state, tileId) === 'Order')
      .filter(tileId => sl.getClientIdByQuoteId(state, tileId) === bdrId);
    const userEmails = sl.getUserPreferenceData(state).emails;
    const finalEmails = [...emails, ...userEmails.filter(mail => !emails.includes(mail))].join(';');
    orderIds.forEach(orderId =>
      dispatch(ac.orderPropertyChanged(orderId, { emails: finalEmails })),
    );
  };
}
