import { isDefined } from '@sgme/fp';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { AppState } from 'state/model';
import { fieldData } from 'utils/fieldSelectors';
import type { OmsOrderSubmissionPayload, OrderRequestPayload } from 'api/order/oms';
import { clearUndefined } from 'utils/clearUndefined';
import { isLimitOrder } from 'state/fxOrders/fxOrdersModel';
import type { Side } from 'state/share/productModel/litterals';
import {
  getAmountsHOF,
  getClipDataHOF,
  getDatesDataHOF,
  getEditedAmountsHOF,
  getEditedClipDataHOF,
  getEditedDatesDataHOF,
  getEditedLimitPriceHOF,
  getLimitPriceHOF,
} from './blotterOrderGetters';
import * as orderMetaSelectors from 'state/blotter/selectors';
import { getUtcExpiry } from '../order/utils';

export type OrderMetaSelectors = typeof orderMetaSelectors;

// ---------------------------------------------------------------------------------
// Public meta selector
// ---------------------------------------------------------------------------------

interface Parameters {
  getNewGuid(): string;
}

export const metaSelectorBlotterOrderToBackend: MapStateToMetadataHOF<
  OrderRequestPayload,
  Parameters & { orderId: string },
  AppState,
  OrderMetaSelectors
> = sl => {
  const makeOrderTypeToBackendPayload = makeOrderTypeToBackendPayloadWith(sl);
  return (state, { getNewGuid, orderId }) => {
    const product = sl.getBlotterOrderProduct(state, orderId);
    const orderTypeSpecificPayload = makeOrderTypeToBackendPayload(state, orderId);

    const notional = fieldData(sl.getBlotterOrderNotionalInput(state, orderId)).data;
    const currencyPair = sl.getBlotterOrderCurrencyPair(state, orderId);
    const way = sl.getBlotterOrderWay(state, orderId);
    const notionalCurrency = sl.getBlotterOrderNotionalCurrency(state, orderId);
    const currencyNumber = currencyPair?.split('/').indexOf(notionalCurrency ?? '');
    const payload: Partial<OmsOrderSubmissionPayload> = {
      isGtc: fieldData(sl.getBlotterOrderIsGtc(state, orderId)).data ?? undefined,
      bdrId: sl.getBlotterOrderAccount(state, orderId) ?? undefined,
      amountInCcy1: currencyNumber === 0 && notional !== null ? Number(notional) : undefined,
      amountInCcy2: currencyNumber === 1 && notional !== null ? Number(notional) : undefined,
      ccyPair: currencyPair ?? undefined,
      way: way === null ? undefined : toOmsSide(way),
      limitPrice: isLimitOrder(product.value)
        ? fieldData(sl.getBlotterOrderLimitPriceInput(state, orderId)).data?.toString() ?? undefined
        : undefined,
      ...orderTypeSpecificPayload,
    };

    return {
      orderType: product.value,
      payload: clearUndefined(payload),
      correlationId: `${orderId}/${getNewGuid()}`,
    };
  };
};

export const metaSelectorBlotterEditedOrderToBackend: MapStateToMetadataHOF<
  OrderRequestPayload,
  Parameters & { orderId: string },
  AppState,
  OrderMetaSelectors
> = sl => {
  const makeOrderTypeToBackendPayload = makeEditedOrderTypeToBackendPayloadWith(sl);

  return (state, { getNewGuid, orderId }) => {
    const product = fieldData(sl.getBlotterEditedOrderProduct(state, orderId)).data;
    const orderTypeSpecificPayload = makeOrderTypeToBackendPayload(state, orderId);
    const notional = fieldData(sl.getBlotterEditedOrderNotionalInput(state, orderId)).data;
    const currencyPair = sl.getBlotterOrderCurrencyPair(state, orderId);
    const way = sl.getBlotterOrderWay(state, orderId);
    const notionalCurrency = sl.getBlotterEditedOrderNotionalCurrency(state, orderId);
    const currencyNumber = currencyPair?.split('/').indexOf(notionalCurrency ?? '');
    const payload: Partial<OmsOrderSubmissionPayload> = {
      isGtc: fieldData(sl.getBlotterEditedOrderIsGtc(state, orderId)).data ?? undefined,
      bdrId: sl.getBlotterOrderAccount(state, orderId) ?? undefined,
      amountInCcy1: currencyNumber === 0 && notional !== null ? Number(notional) : undefined,
      amountInCcy2: currencyNumber === 1 && notional !== null ? Number(notional) : undefined,
      ccyPair: currencyPair ?? undefined,
      way: way === null ? undefined : toOmsSide(way),
      limitPrice: isLimitOrder(product)
        ? fieldData(sl.getBlotterEditedOrderLimitPriceInput(state, orderId)).data?.toString() ?? undefined
        : undefined,
      ...orderTypeSpecificPayload,
    };

    return {
      orderType: product,
      payload: clearUndefined(payload),
      correlationId: `${orderId}/${getNewGuid()}`,
    };
  };
};

function toOmsSide(side: Side) {
  return side === 'Buy' ? 'buy' : 'sell';
}

const makeOrderTypeToBackendPayloadWith = (sl: OrderMetaSelectors) => {
  const getAmounts = getAmountsHOF(sl);
  const getClipData = getClipDataHOF(sl);
  const getLimitPrice = getLimitPriceHOF(sl);
  const getDatesData = getDatesDataHOF(sl);

  return (state: AppState, orderId: string): Partial<OmsOrderSubmissionPayload> => {
    const orderType = sl.getBlotterOrderProduct(state, orderId).value;
    const fixingPriceType = fieldData(sl.getBlotterOrderFixingPriceTypeInput(state, orderId)).data ?? undefined;

    const isInternalSales = state.referenceData.userInfo.userType === 'Internal Sales';
    const isTakeProfitOrder = orderType === 'TakeProfit';
    const takeProfitMargin = fieldData(sl.getBlotterOrderTakeProfitMarginInput(state, orderId)).data;

    const isGtc = fieldData(sl.getBlotterOrderIsGtc(state, orderId)).data;

    const expiryDay = isGtc === true ? undefined : sl.getBlotterOrderExpiryDay(state, orderId) ?? undefined;
    const expiryTime = isGtc === true ? undefined : sl.getBlotterOrderExpiryTime(state, orderId);
    const utcExpiry = getUtcExpiry(isGtc ?? false, expiryDay, expiryTime ?? undefined);
    const orderTypeWithUtcExpiry = ['TakeProfit', 'StopLoss', 'Call'];
    const applicableUtcExpiry = orderTypeWithUtcExpiry.includes(orderType) ? utcExpiry : {};

    switch (orderType) {
      case 'Fixing':
        const marginInBps = fieldData(sl.getBlotterOrderMarginInBpsInput(state, orderId)).data;
        return {
          fixingBenchmark: fieldData(sl.getBlotterOrderFixingBenchmark(state, orderId)).data ?? undefined,
          fixingPriceType,
          fixingDateUtc: sl.getBlotterOrderFixingDateUtc(state, orderId) ?? undefined,
          fixingTime: fieldData(sl.getBlotterOrderFixingTime(state, orderId)).data ?? undefined,
          fixingPlace: fieldData(sl.getBlotterOrderFixingPlace(state, orderId)).data ?? undefined,
          marginInBps: isDefined(marginInBps) ? Number(marginInBps) : undefined,
          fixingMarginType:
            fixingPriceType === 'BIDASK'
              ? 'MarginInBps'
              : fieldData(sl.getBlotterOrderFixingMarginTypeInput(state, orderId)).data ?? undefined,
          limitPrice: undefined,
        };
      default:
        return {
          ccyPair: sl.getBlotterOrderCurrencyPair(state, orderId),
          limitPrice: getLimitPrice(state, orderId),
          customerPrice: fieldData(sl.getBlotterOrderCustomerPriceInput(state, orderId))?.data?.toString() ?? undefined,
          margin: isInternalSales && isTakeProfitOrder ? Number(takeProfitMargin) : undefined,
          ...(orderType !== 'Call' ? getAmounts(state, orderId) : undefined),
          ...getDatesData(state, orderId),
          liquidityPool: fieldData(sl.getBlotterOrderLiquidityPool(state, orderId)).data?.join(',') ?? undefined,
          ...getClipData(state, orderId),
          dodging: fieldData(sl.getBlotterOrderRandomize(state, orderId)).data ?? undefined,
          spreadCapture: fieldData(sl.getBlotterOrderSpreadCapture(state, orderId)).data ?? undefined,
          speed: fieldData(sl.getBlotterOrderSpeed(state, orderId)).data ?? undefined,
          alphaSeeker: fieldData(sl.getBlotterOrderAlphaSeeker(state, orderId)).data ?? undefined,
          ...applicableUtcExpiry,
        };
    }
  };
};

const makeEditedOrderTypeToBackendPayloadWith = (sl: OrderMetaSelectors) => {
  const getAmounts = getEditedAmountsHOF(sl);
  const getClipData = getEditedClipDataHOF(sl);
  const getLimitPrice = getEditedLimitPriceHOF(sl);
  const getDatesData = getEditedDatesDataHOF(sl);

  return (state: AppState, orderId: string): Partial<OmsOrderSubmissionPayload> => {
    const orderType = sl.getBlotterOrderProduct(state, orderId).value;
    const fixingPriceType = fieldData(sl.getBlotterEditedOrderFixingPriceTypeInput(state, orderId)).data ?? undefined;

    const isInternalSales = state.referenceData.userInfo.userType === 'Internal Sales';
    const isTakeProfitOrder = orderType === 'TakeProfit';
    const takeProfitMargin = fieldData(sl.getBlotterEditedOrderTakeProfitMarginInput(state, orderId)).data;

    const isGtc = fieldData(sl.getBlotterEditedOrderIsGtc(state, orderId)).data;

    const expiryDay = isGtc === true ? undefined : sl.getBlotterOrderExpiryDay(state, orderId) ?? undefined;
    const expiryTime = isGtc === true ? undefined : sl.getBlotterOrderExpiryTime(state, orderId);
    const utcExpiry = getUtcExpiry(isGtc ?? false, expiryDay, expiryTime ?? undefined);
    const orderTypeWithUtcExpiry = ['TakeProfit', 'StopLoss', 'Call'];
    const applicableUtcExpiry = orderTypeWithUtcExpiry.includes(orderType) ? utcExpiry : {};

    switch (orderType) {
      case 'Fixing':
        const marginInBps = fieldData(sl.getBlotterEditedOrderMarginInBpsInput(state, orderId)).data;
        return {
          fixingBenchmark: fieldData(sl.getBlotterOrderFixingBenchmark(state, orderId)).data ?? undefined,
          fixingPriceType,
          fixingDateUtc: sl.getBlotterOrderFixingDateUtc(state, orderId) ?? undefined,
          fixingTime: fieldData(sl.getBlotterOrderFixingTime(state, orderId)).data ?? undefined,
          fixingPlace: fieldData(sl.getBlotterOrderFixingPlace(state, orderId)).data ?? undefined,
          marginInBps: isDefined(marginInBps) ? Number(marginInBps) : undefined,
          fixingMarginType:
            fixingPriceType === 'BIDASK'
              ? 'MarginInBps'
              : fieldData(sl.getBlotterEditedOrderFixingMarginTypeInput(state, orderId)).data ?? undefined,
          limitPrice: undefined,
        };
      default:
        return {
          ccyPair: sl.getBlotterOrderCurrencyPair(state, orderId),
          limitPrice: getLimitPrice(state, orderId),
          customerPrice:
            fieldData(sl.getBlotterEditedOrderCustomerPriceInput(state, orderId))?.data?.toString() ?? undefined,
          margin: isInternalSales && isTakeProfitOrder ? Number(takeProfitMargin) : undefined,
          ...(orderType !== 'Call' ? getAmounts(state, orderId) : undefined),
          ...getDatesData(state, orderId),
          liquidityPool: fieldData(sl.getBlotterOrderLiquidityPool(state, orderId)).data?.join(',') ?? undefined,
          ...getClipData(state, orderId),
          dodging: fieldData(sl.getBlotterEditedOrderRandomize(state, orderId)).data ?? undefined,
          spreadCapture: fieldData(sl.getBlotterEditedOrderSpreadCapture(state, orderId)).data ?? undefined,
          speed: fieldData(sl.getBlotterEditedOrderSpeed(state, orderId)).data ?? undefined,
          alphaSeeker: fieldData(sl.getBlotterEditedOrderAlphaSeeker(state, orderId)).data ?? undefined,
          ...applicableUtcExpiry,
        };
    }
  };
};

export const getOrderRequestFromBlotter = metaSelectorBlotterOrderToBackend(orderMetaSelectors);
export const getEditedOrderRequestFromBlotter = metaSelectorBlotterEditedOrderToBackend(orderMetaSelectors);
