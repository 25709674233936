import {
  getDisplayFarPoints,
  getDisplayFarPrice,
  getDisplayMidRateAndMidPts,
  getDisplayNearPoints,
  getDisplayNearPrice,
  getDisplaySwapPointsAllIn,
  getSwapCurrentStreamId,
  getSwapRfsStreamQuote,
  getSwapStreamStatus,
} from '../../../../state/fxSwaps/selectors';
import type { AppState } from '../../../../state/model';
import type { SwapQuoteData, SwapStateData } from './types';
import type { FxSwapState } from 'state/fxSwaps/fxSwapsModel';

export function getSwapStateData(swapState: FxSwapState | null): SwapStateData {
  const swapData = swapState?.values;

  return {
    currencyPair: swapData?.currencyPair ?? '',
    currency1: swapData?.currency1 ?? '',
    currency2: swapData?.currency2 ?? '',
    amountCurrency: swapData?.amountCurrency ?? '',
    nearNotional: swapData?.nearAmount ?? '',
    farNotional: swapData?.farAmount ?? '',
    nearDate: swapData?.nearPaymentDate ?? '',
    nearTenor: swapData?.nearPaymentDateTenor ?? '',
    farDate: swapData?.farPaymentDate ?? '',
    farTenor: swapData?.farPaymentDateTenor ?? '',
  };
}

export function getSwapQuoteData(state: AppState, quoteId: string): SwapQuoteData {
  const streamStatus = getSwapStreamStatus(state, quoteId);

  const emptyBidAskObject = { bid: '', ask: '' };

  if (streamStatus === 'PRICING') {
    const streamId = getSwapCurrentStreamId(state, quoteId);
    const isStreamId = streamId !== null;

    const spot = !isStreamId ? emptyBidAskObject : getSwapRfsStreamQuote(state, streamId).spot;
    const midPoints = getDisplayMidRateAndMidPts(state, quoteId)?.midPts ?? '';
    const nearPrice = getDisplayNearPrice(state, quoteId).value ?? emptyBidAskObject;
    const nearPoints = !isStreamId
      ? emptyBidAskObject
      : getDisplayNearPoints(state, quoteId).points;
    const farPrice = getDisplayFarPrice(state, quoteId).value ?? emptyBidAskObject;
    const farPoints = !isStreamId ? emptyBidAskObject : getDisplayFarPoints(state, quoteId).points;
    const swapPoints = !isStreamId
      ? emptyBidAskObject
      : getDisplaySwapPointsAllIn(state, quoteId).points;

    return {
      spot,
      midPoints,
      nearPrice,
      nearPoints,
      farPrice,
      farPoints,
      swapPoints,
    };
  }

  return {
    spot: emptyBidAskObject,
    midPoints: '',
    nearPrice: emptyBidAskObject,
    nearPoints: emptyBidAskObject,
    farPrice: emptyBidAskObject,
    farPoints: emptyBidAskObject,
    swapPoints: emptyBidAskObject,
  };
}
