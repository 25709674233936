import type { AppState } from 'state/model';
import type { IESPTraderPriceUnderThreshold, IEspStreamState } from './espStreamsModel';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import type { Collection } from 'typings/utils';
import {
  getEspPriceByNotional,
  clientMidRate,
  traderMidRate,
} from './utils';
import { getProductName } from "../sharedSelectors";
import type { ProductName } from "../share/productModel";
import type { CashProductName } from "../fxCashs/fxCashsModel";
import { isDefined } from "@sgme/fp";
import {
  getCashAmount,
  getCashFixingCurrency,
  getCashFixingSource,
  getCashMaturityDateTenor,
  getCashSndFixingSource
} from "../fxCashs/selectors/cashInput";
import {
  getCashPossibleFixingCurrencies,
  getCashPossibleFixingSources,
  getCashPossibleSndFixingSources
} from "../fxCashs/selectors/cashValue";
import { getLadderIndex, isCashAmountInsideEspLimits } from "../fxCashs/selectors/fxCashsSelectors";
import { getCashState } from "../fxCashs/selectors/cashState";


// ███████╗████████╗██████╗ ███████╗ █████╗ ███╗   ███╗
// ██╔════╝╚══██╔══╝██╔══██╗██╔════╝██╔══██╗████╗ ████║
// ███████╗   ██║   ██████╔╝█████╗  ███████║██╔████╔██║
// ╚════██║   ██║   ██╔══██╗██╔══╝  ██╔══██║██║╚██╔╝██║
// ███████║   ██║   ██║  ██║███████╗██║  ██║██║ ╚═╝ ██║
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝

function getEspStreams(state: AppState) {
  return state.espStreams;
}

function getStreamKeys(espStreamCollection: Collection<IEspStreamState>) {
  return Object.keys(espStreamCollection);
}

export function getEspStreamKeys(state: AppState) {
  return getStreamKeys(getEspStreams(state));
}

export function getEspStreamState(
  state: AppState,
  espStreamId: string,
): IEspStreamState | undefined {
  return state.espStreams[espStreamId];
}

export function getEspStreamRefCount(state: AppState, espStreamId: string | null): number | null {
  if (espStreamId !== null) {
    const streamState = getEspStreamState(state, espStreamId);
    if (streamState) {
      return streamState.refCount;
    }
  }
  return null;
}

export function getEspQuoteId(state: AppState, currentEspStreamId: string | null): string | null {
  if (currentEspStreamId === null) {
    return null;
  }
  const streamState = getEspStreamState(state, currentEspStreamId);
  if (streamState === undefined || streamState.status !== 'PRICING') {
    return null;
  }
  return streamState.quoteId;
}

export function getEspSpotMidRate(state: AppState, espStreamId: string): number | null {
  const espStreamState = getEspStreamState(state, espStreamId);
  if (espStreamState && espStreamState.status === 'PRICING') {
    if (espStreamState.priceType === 'ESP.CLIENT.PRICE') {
      return clientMidRate(espStreamState.ladders[0]);
    } else if (espStreamState.priceType === 'ESP.TRADER.PRICE') {
      return traderMidRate(espStreamState.ladders[0]);
    }
  }
  return null;
}

export function getCashEspStreamMargin(
  state: AppState,
  espStreamId: string,
  amount: number | null,
  amountCurrency: CurrencyChoice,
) {
  const espStream = getEspStreamState(state, espStreamId)!;
  if (espStream.status === 'PRICING' && espStream.priceType === 'ESP.TRADER.PRICE') {
    const price = getEspPriceByNotional(espStream, amount, amountCurrency)
      .price as IESPTraderPriceUnderThreshold;
    if (price !== null) {
      return {
        bidMargin: price.marginBid,
        askMargin: price.marginAsk,
      };
    }
  }
  return {
    bidMargin: null,
    askMargin: null,
  };
}

export function getHeartbeatMissedEspStreams(state: AppState) {
  return Object.entries(getEspStreams(state))
    .filter(
      ([_, stream]) =>
        stream !== undefined && stream.status === 'PRICING' && stream.missedHeartbeat === true,
    )
    .map(([streamKey]) => streamKey);
}


//  █████╗ ██╗     ██╗         ██████╗ ██████╗  ██████╗ ██████╗ ██╗   ██╗ ██████╗████████╗
// ██╔══██╗██║     ██║         ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██║   ██║██╔════╝╚══██╔══╝
// ███████║██║     ██║         ██████╔╝██████╔╝██║   ██║██║  ██║██║   ██║██║        ██║
// ██╔══██║██║     ██║         ██╔═══╝ ██╔══██╗██║   ██║██║  ██║██║   ██║██║        ██║
// ██║  ██║███████╗███████╗    ██║     ██║  ██║╚██████╔╝██████╔╝╚██████╔╝╚██████╗   ██║
// ╚═╝  ╚═╝╚══════╝╚══════╝    ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝  ╚═════╝   ╚═╝

export function isProductEspCompatible(state: AppState, tileId: string) {
  const productName = getProductName(state, tileId);

  return isCashProduct(productName)
    ? isCashProductEspCompatible({
      productName,
      tenor: getCashMaturityDateTenor(state, tileId).value as string,

      fixingSource: getCashFixingSource(state, tileId).value as string,
      defaultFixingSource: getCashPossibleFixingSources(state, tileId)?.default,

      secondFixingSource: getCashSndFixingSource(state, tileId).value as string,
      defaultSecondFixingSource: getCashPossibleSndFixingSources(state, tileId)?.default,

      fixingCurrency: getCashFixingCurrency(state, tileId).value as string,
      defaultFixingCurrency: getCashPossibleFixingCurrencies(state, tileId)?.default
    })
    : isNotCashProductEspCompatible(productName)
}

const NOT_CACH_PRODUCT_ESP_COMPATIBLE: Array<Exclude<ProductName, CashProductName>> = [
  "FxOption",
  "FxTargetAccumulator",
  "FxForwardAccumulator",
  "FxOrder",
  "FxBulk"
]

//  ██████╗ █████╗ ███████╗██╗  ██╗
// ██╔════╝██╔══██╗██╔════╝██║  ██║
// ██║     ███████║███████╗███████║
// ██║     ██╔══██║╚════██║██╔══██║
// ╚██████╗██║  ██║███████║██║  ██║
//  ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝

const CASH_PRODUCT_NAME: CashProductName[] = [
  "FxSpot",
  "FxFwd",
  "FxNdf"
];

const isCashProduct = (productName: ProductName): productName is CashProductName => (CASH_PRODUCT_NAME as ProductName[]).includes(productName);


const isNotCashProductEspCompatible = (productName: Exclude<ProductName, CashProductName>) => NOT_CACH_PRODUCT_ESP_COMPATIBLE.includes(productName);

interface IsCashProductEspCompatibleParams {
  productName: CashProductName,

  tenor: string,

  fixingSource?: string | null,
  defaultFixingSource?: string,

  secondFixingSource?: string | null,
  defaultSecondFixingSource?: string,

  fixingCurrency?: string | null,
  defaultFixingCurrency?: string
};

const isCashProductEspCompatible = (cashData: IsCashProductEspCompatibleParams) => {
  if (cashData.productName === 'FxFwd') {
    return false;
  }

  if (cashData.productName === 'FxSpot') {
    return cashData.tenor === 'SP'
  }

  return (
    cashData.tenor === '1M'
    && (isDefined(cashData.defaultFixingSource)
      ? cashData.fixingSource === cashData.defaultFixingSource
      : true)
    && (isDefined(cashData.defaultSecondFixingSource)
      ? cashData.secondFixingSource === cashData.defaultSecondFixingSource
      : true)
    && (isDefined(cashData.defaultFixingCurrency)
      ? cashData.fixingCurrency === cashData.defaultFixingCurrency
      : true)
  )
};

export function getCashPriceSourceToDisplay(state: AppState, quoteId: string) {
  const { currentStreamId, currentEspStreamId } = getCashState(state, quoteId);
  if (isDefined(currentStreamId)) {
    return 'RFS';
  }
  if (isDefined(currentEspStreamId)) {
    const notional = getCashAmount(state, quoteId).value;
    const { status } = getEspStreamState(state, currentEspStreamId)!;
    if (
      status === 'PRICING' &&
      (notional === null ||
        (getLadderIndex(state, quoteId, notional) !== null &&
          isCashAmountInsideEspLimits(state, quoteId, notional)))
    ) {
      return 'ESP';
    }
  }
  return 'None';
}
