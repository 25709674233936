import { InstrumentChooser } from './InstrumentChooser';
import { PredealChecks } from './PredealChecks';
import { NDCheckbox } from './NDCheckbox';
import { CurrencyPicker } from '../CurrencyPicker';
import { TileMenu } from './TileMenu';
import { AccountChanged } from './AccountChanged';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import en from './locales/en.json';
import fr from './locales/fr.json';
import type { FC } from 'react';
import { HStack } from '@sgme/ui';

const messagesMap = { en, fr };

interface TileHeaderProps {
  displayAllComponents?: boolean; // todo-5153 see to rename or pass a specific boolean for each nested component to display
}

export const TileHeader: FC<TileHeaderProps> = ({ displayAllComponents = true }) => (
  <IntlComponentBoundary messagesMap={messagesMap}>
    {displayAllComponents && <AccountChanged />}

    <HStack padding="8px">
      <HStack>
        <InstrumentChooser />
        {displayAllComponents && <PredealChecks />}
      </HStack>

      {displayAllComponents && <CurrencyPicker />}

      {displayAllComponents && <NDCheckbox />}

      <TileMenu displayAllActions={displayAllComponents} />
    </HStack>
  </IntlComponentBoundary>
);
