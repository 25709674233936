import type { GridLayout } from '../../gridLayoutModels';
import { throwIfUndefined } from 'utils/maps';

export function getPositionById(
  gridState: Pick<GridLayout, 'gridItemPositions'>,
  gridItemId: string,
) {
  return throwIfUndefined(
    gridState.gridItemPositions[gridItemId],
    `Grid position for ${gridItemId} does not exist`,
  );
}
