import type { ReactNode } from 'react';
import { IntlProvider, useIntl } from 'react-intl';

type Messages = Record<string, string>;

export type MessagesMap = Record<string, Messages>;

interface IntlComponentBoundaryProps {
  messagesMap: MessagesMap;
  children: ReactNode;
}

function useMessages(messagesMap: MessagesMap, locale: string): Messages {
  const { defaultLocale } = useIntl();
  const messages = messagesMap[locale];
  return locale === defaultLocale ? messages : { ...messagesMap[defaultLocale], ...messages };
}

export function IntlComponentBoundary({
  messagesMap,
  children,
}: IntlComponentBoundaryProps): JSX.Element {
  const { locale, messages: parentMessages } = useIntl();
  const messages = useMessages(messagesMap, locale);
  const allMessages: Messages = { ...(parentMessages as Messages), ...messages };
  return (
    <IntlProvider locale={locale} messages={allMessages}>
      {children}
    </IntlProvider>
  );
}
