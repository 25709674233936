export type FixingReferencesActions = GetFixingReferencesRequestedEpic | GetFixingReferencesDone;

export interface GetFixingReferencesRequestedEpic {
  type: 'GET_FIXING_REFERENCES_REQUESTED';
  asset: string;
  currencyPair: string;
  counterpartyBdrId: string;
  quoteId: string;
}

export function getFixingReferencesRequested(
  asset: string,
  currencyPair: string,
  counterpartyBdrId: string,
  quoteId: string,
): GetFixingReferencesRequestedEpic {
  return {
    type: 'GET_FIXING_REFERENCES_REQUESTED',
    asset,
    currencyPair,
    counterpartyBdrId,
    quoteId,
  };
}

//todo - see if we want to change this with specific error message
export interface GetFixingReferencesDone {
  type: 'GET_FIXING_REFERENCES_DONE';
  success: boolean;
  quoteId: string;
  failureReason?: string;
}

export function getFixingReferencesDone(
  success: boolean,
  quoteId: string,
  failureReason?: string,
): GetFixingReferencesDone {
  return { type: 'GET_FIXING_REFERENCES_DONE', success, quoteId, failureReason };
}
