import { createContext, useContext, type ComponentType } from 'react';
import type { StrictOmit } from 'utils/object';

export function genericContext<K extends string, T>(key: K, defaultValue: T) {
  const pascalCasedKey = key.charAt(0).toUpperCase() + key.slice(1);
  const withDisplayName = `With${pascalCasedKey}`;

  const GenericContext = createContext(defaultValue);
  GenericContext.displayName = `${pascalCasedKey}Context`;

  type ComponentWithoutContextValue<P extends Record<K, T>> = ComponentType<StrictOmit<P, K>>;

  const withValue = <P extends Record<K, T>>(Component: ComponentType<P>) => {
    const WithValue: ComponentWithoutContextValue<P> = props => {
      const value = useContext(GenericContext);
      const genericProp = { [key]: value };
      return <Component {...(props as P)} {...genericProp} />;
    };
    WithValue.displayName = withDisplayName;
    return WithValue;
  };

  return [GenericContext, GenericContext.Provider, withValue] as const;
}
