import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import type { ToastsState } from './toastsModel';

const defaultState: ToastsState = {
  toasts: [],
};

export const toastsReducer: Reducer<ToastsState, Action> = (
  state: ToastsState = defaultState,
  action: Action,
): ToastsState => {
  switch (action.type) {
    case 'ADD_TOAST':
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            id: action.id,

            ...action.toast,
          },
        ],
      };
    case 'REMOVE_TOAST':
      return {
        ...state,
        toasts: state.toasts.filter(c => c.id !== action.id),
      };
    default:
      return state;
  }
};
