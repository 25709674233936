import type { AppState } from 'state/model';
import { getAccumulatorState } from './accumulator';
import type { AccumulatorScheduleInputs, AccumulatorScheduleValues } from '../fxAccumulatorsModel';
import { getSharedProperty, type SharedKey } from 'state/share/form';
import { throwIfUndefined } from 'utils/maps';
import { getLegKey } from 'state/share/patchHelper';
import { getAccumulatorProductName, getTargetAccumulatorAccuType } from './accumulatorValue';
import {
  getForwardAccumulatorCalendarProperties,
  getTargetAccumulatorCalendarProperties,
} from 'components/FxAccumulatorTile/Scheduler/utils';

export function getAccumulatorSchedule(state: AppState, quoteId: string, legId: string) {
  return throwIfUndefined(
    state.fxAccumulators.schedules[getLegKey(quoteId, legId)],
    `Accumulator schedule for tile ${legId} does not exist`,
  );
}

export function getAccumulatorScheduleItemIds(state: AppState, quoteId: string) {
  const { legs } = getAccumulatorState(state, quoteId);
  if (legs === undefined) {
    return [];
  }
  return legs;
}

export const getLegIdsOrderedForSchedule = (state: AppState, quoteId: string) => {
  const scheduleIds = getAccumulatorScheduleItemIds(state, quoteId);
  const schedule = state.fxAccumulators.schedules;
  const scheduleIdsWithDates = Object.fromEntries(
    scheduleIds.map(id => [id, schedule[getLegKey(quoteId, id)]?.values.fixingDate]),
  );
  return Object.keys(scheduleIdsWithDates).sort(
    (id1, id2) => scheduleIdsWithDates[id1]?.localeCompare(scheduleIdsWithDates[id2]!) ?? 1,
  );
};

export const getDisplayedDataInSchedule = (
  state: AppState,
  quoteId: string,
  isMaturitySettlementFrequency: boolean,
) => {
  const productName = getAccumulatorProductName(state, quoteId);
  const isForwardAccumulator = productName === 'FxForwardAccumulator';
  const accumulatorType = isForwardAccumulator ? 'ForwardAccu' : getTargetAccumulatorAccuType(state, quoteId);

  return isForwardAccumulator
    ? getForwardAccumulatorCalendarProperties(isMaturitySettlementFrequency)
    : getTargetAccumulatorCalendarProperties(accumulatorType);
};

export const getscheduleValues = (state: AppState, quoteId: string) => {
  const scheduleIds = getLegIdsOrderedForSchedule(state, quoteId);
  const schedules = state.fxAccumulators.schedules;

  return scheduleIds.map(id => schedules[getLegKey(quoteId, id)]?.values);
};

type AccumulatorScheduleSharedPropertyKey = SharedKey<AccumulatorScheduleValues, AccumulatorScheduleInputs>;

export function getAccumulatorScheduleData<T extends AccumulatorScheduleSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<AccumulatorScheduleValues, AccumulatorScheduleInputs, T>(prop);
  return (state: AppState, quoteId: string, fixingId: string) => get(getAccumulatorSchedule(state, quoteId, fixingId));
}

export function isScheduleFieldCustom<T extends AccumulatorScheduleSharedPropertyKey>(prop: T) {
  return (state: AppState, quoteId: string, fixingId: string) =>
    getAccumulatorScheduleCustomFields(state, quoteId, fixingId).includes(prop);
}

export const getAccumulatorScheduleFixingDate = getAccumulatorScheduleData('fixingDate');

export const getAccumulatorScheduleSettlementDate = getAccumulatorScheduleData('paymentDate');

export const getAccumulatorScheduleStrike = getAccumulatorScheduleData('strike');

export const getAccumulatorScheduleEki = getAccumulatorScheduleData('ekiTrigger');

export const getTargetAccumulatorScheduleEkiDown = getAccumulatorScheduleData('stepDown');
export const getTargetAccumulatorScheduleEkiUp = getAccumulatorScheduleData('stepUp');

export const getAccumulatorScheduleStep = getAccumulatorScheduleData('step');

export const getAccumulatorScheduleNotional = getAccumulatorScheduleData('amount');

export const getAccumulatorScheduleLeverageAmount = getAccumulatorScheduleData('leverageAmount');

export const getAccumulatorScheduleCustomFields = (state: AppState, quoteId: string, fixingId: string) => {
  if (fixingId !== '@@_MISSING_LEGID_PROVIDER') {
    return getAccumulatorSchedule(state, quoteId, fixingId).custom;
  }
  return [];
};

export const getTargetAccumulatorScheduleStrikeDown = getAccumulatorScheduleData('strikeDown');
export const getTargetAccumulatorScheduleStrikeUp = getAccumulatorScheduleData('strikeUp');
