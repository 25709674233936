import type { Thunk } from 'state';
import type { PriceType } from 'state/fxOptions/fxOptionsModel';
import type { CurrencyChoice } from 'state/share/productModel/litterals';

function shouldResetPremium(currentPriceType: PriceType, newPriceType: PriceType) {
  return (
    newPriceType === 'PERCENT' ||
    newPriceType === 'PPS' ||
    currentPriceType === 'PERCENT' ||
    currentPriceType === 'PPS'
  );
}

export function optionPriceTypeChangedThunk(
  optionId: string,
  newPriceType: PriceType,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const [ccy, currentPriceType] = sl.getOptionDisplayPriceType(state, optionId);

    dispatch(
      ac.optionLegsGlobalPropertyChangeThunk(optionId, {
        premiumTypeString: newPriceType,
        premiumCurrency: ccy,
        ...(shouldResetPremium(currentPriceType, newPriceType)
          ? { premiumBid: null, premiumAsk: null }
          : {}),
      }),
    );
    dispatch(ac.optionDisplayPriceTypeChanged(optionId, [ccy, newPriceType]));
  };
}

export function optionPriceTypeCurrencyChangedThunk(optionId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const [ccy, priceType] = sl.getOptionDisplayPriceType(state, optionId);
    const newCcy: CurrencyChoice = ccy === 1 ? 2 : 1;

    dispatch(
      ac.optionLegsGlobalPropertyChangeThunk(optionId, {
        premiumTypeString: priceType,
        premiumCurrency: newCcy,
        // Reset override
        premiumBid: null,
        premiumAsk: null,
      }),
    );
    dispatch(ac.optionDisplayPriceTypeChanged(optionId, [newCcy, priceType]));
  };
}
