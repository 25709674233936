import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import {
  mapStateToPropsFreeLayoutContainer,
  mapDispatchToPropsFreeLayoutContainer,
} from './connectFreeLayoutContainer';
import {
  mapStateToPropsGridContainer,
  mapDispatchToPropsGridContainer,
} from './connectGridContainer';
import { mapStateToPropsTabContents } from './connectTabContents';

export const connectFreeLayoutContainer = connect(
  mapStateToPropsFreeLayoutContainer(selectors),
  mapDispatchToPropsFreeLayoutContainer(actionCreators),
);

export const connectGridContainer = connect(
  mapStateToPropsGridContainer(selectors),
  mapDispatchToPropsGridContainer(actionCreators),
);

export const connectTabContents = connect(mapStateToPropsTabContents(selectors));
