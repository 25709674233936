import type { ISgmeHttp, HttpCall } from 'utils/sgmeHttp';
import type { Observable } from 'rxjs';

export function xoneAutomationApi(http: ISgmeHttp): IXoneAutomation {
  return {
    openReference(reference: string) {
      return http.post<void>('api/desktopAutomation/openReference', {
        reference,
      });
    },
  };
}

export interface IXoneAutomation {
  openReference(reference: string): Observable<HttpCall<void>>;
}
