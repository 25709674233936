/**
 * @private
 * these exports are shared helper for exported selectors
 *
 * don't export this file in barrel ('./index.ts')
 */

import { logger } from 'logging/logger';
import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';
import type {
  FxAccumulatorState,
  FxForwardAccumulatorState,
  FxTargetAccumulatorState,
} from '../fxAccumulatorsModel';

export function getAccumulatorState(state: AppState, tileId: string) {
  return throwIfUndefined(
    state.fxAccumulators.accumulators[tileId],
    `Accumulator state for tile ${tileId} does not exist`,
  );
}

export function getTargetAccumulatorState(state: AppState, tileId: string) {
  const accumulatorState = getAccumulatorState(state, tileId);

  assertTargetAccu(accumulatorState);

  return accumulatorState;
}

export function getForwardAccumulatorState(state: AppState, tileId: string) {
  const accumulatorState = getAccumulatorState(state, tileId);

  assertForwardAccu(accumulatorState);

  return accumulatorState;
}

function assertTargetAccu(
  targetAccuState: FxAccumulatorState,
): asserts targetAccuState is FxTargetAccumulatorState {
  if (targetAccuState.values.productName !== 'FxTargetAccumulator') {
    const err = new Error('This should have been a targetAccu');
    logger.logError(
      'This should have been a targetAccu : {targetAccuState} from : {stack}, source: state',
      JSON.stringify(targetAccuState),
      err.stack,
    );
    throw err;
  }
}

// TODO review can be refactored ?
function assertForwardAccu(
  forwardAccuState: FxAccumulatorState,
): asserts forwardAccuState is FxForwardAccumulatorState {
  if (forwardAccuState.values.productName !== 'FxForwardAccumulator') {
    const err = new Error('This should have been a forwardAccu');
    logger.logError(
      'This should have been a forwardAccu : {forwardAccuState} from : {stack}, source: state',
      JSON.stringify(forwardAccuState),
      err.stack,
    );
    throw err;
  }
}
