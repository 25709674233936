import type { UserPrefConnectProps } from './connect/connectUserPref';
import { FormattedMessage } from 'react-intl';
import type { UserPreferencesData } from 'state/userPreferences';
import { connectUserPref } from './connect';
import { Select } from 'components/share/Select';
import { UserPrefLabel } from './UserPrefLabel';

type ListUserPreferencesKeys = keyof Pick<
  UserPreferencesData,
  | 'accountNameLength'
  | 'dateInputCultureInfo'
  | 'defaultHedgeType'
  | 'defaultPriceType'
  | 'oneClickExecution'
>;

const ListUserPrefInput =
  <K extends ListUserPreferencesKeys>(
    userPrefKey: K,
    options: ReadonlyArray<UserPreferencesData[K]>,
    intlLabelsPrefix?: string,
  ) =>
  ({ userPrefValue, onChangeUserPref }: UserPrefConnectProps<K>): JSX.Element =>
    (
      <UserPrefLabel label={userPrefKey}>
        <Select<UserPreferencesData[K]>
          value={userPrefValue}
          onChange={onChangeUserPref as (value: UserPreferencesData[K]) => void}
          e2eHandle={userPrefKey}
          options={options}
          className="form-select w-auto"
        >
          {({ option, value }) => (
            <FormattedMessage
              id={`${intlLabelsPrefix ?? `userPreferencesForm.${userPrefKey}`}.${option}`}
              key={value}
            >
              {label => <option value={value}>{label}</option>}
            </FormattedMessage>
          )}
        </Select>
      </UserPrefLabel>
    );

export const ListUserPref = <K extends ListUserPreferencesKeys>(
  userPrefKey: K,
  options: ReadonlyArray<UserPreferencesData[K]>,
  intlLabelsPrefix?: string,
) => connectUserPref(userPrefKey)(ListUserPrefInput(userPrefKey, options, intlLabelsPrefix));
