import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import type { NotificationZoneProps } from './typings';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

type HelpCenterLinkProps = {
  helpCenterLinkId?: string;
};

const HelpCenterLink = ({ helpCenterLinkId }: HelpCenterLinkProps) => {
  const { sgwtHelpCenter } = useSgwtWidgets();
  const displayLink = useCallback(() => sgwtHelpCenter?.topic(helpCenterLinkId!), [sgwtHelpCenter, helpCenterLinkId]);
  return helpCenterLinkId === undefined ? null : (
    <a className="alert-link" href="#" onClick={displayLink}>
      <FormattedMessage id="header.notification.linkToHelpCenter" />
    </a>
  );
};

export const NotificationZone = ({ messages }: NotificationZoneProps) => (
  <div className="d-flex justify-content-center fixed-top" style={{ top: 0, zIndex: 2000, left: '50%', transform: 'translateX(-50%)' }}>
      {messages.map((notif, index) => (
        <div key={index} className={`bg-${notif.level} sgwt-account-center-tag p-1 alert-${notif.level}`}>
          <FormattedMessage id={notif.messageId} /> <HelpCenterLink helpCenterLinkId={notif?.helpCenterLinkId} />
        </div>
      ))}
  </div>
);