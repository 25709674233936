import type { TieringRequest, ICashTieringRequestApi } from './model';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import { map } from 'rxjs/operators';
import { format } from 'date-fns';

const NO_TIERING = 'Provided tiering is null';

export function cashTieringRequestApi(http: ISgmeHttp): ICashTieringRequestApi {
  return (req: TieringRequest) => {
    const maturityDate = format(req.maturityDate, 'yyyy-MM-dd');
    const url = `api/fxSpotFwd/esp/tieringV2/${req.clientBdrId}/${req.product}/${req.ccy1}/${req.ccy2}/${maturityDate}`;

    return http.getJSON<string>(url).pipe(
      map(({ payload }) => {
        if (payload === null) {
          throw { status: NO_TIERING };
        }
        return payload;
      }),
    );
  };
}
