import { useUserType } from '../hooks/useUserType';

export function IsInternal({ children }: { children: React.ReactNode }): JSX.Element | null {
  const userType = useUserType();

  if (['Internal Sales', 'Internal Trader'].includes(userType || '')) {
    return <>{children}</>;
  }
  return null;
}
