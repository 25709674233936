import type { Updater } from '../typings';
import type { SavedWorkspace as SavedWorkspace2 } from '../updater2/model';
import type { SavedWorkspace as SavedWorkspace3 } from './model';
import { defaultAccumulatorTab } from './model/blotterModel';

const currentVersion = 3;

export const updater3: Updater<SavedWorkspace2, SavedWorkspace3> = {
  version: currentVersion,
  canUpdate(data: any) {
    return data.version === currentVersion - 1;
  },
  update: (previousWorkspace: SavedWorkspace2) => {
    const savedWorkspace: SavedWorkspace3 = {
      ...previousWorkspace,
      blotter: {
        ...previousWorkspace.blotter,
        accumulatorTab: defaultAccumulatorTab,
      },
      version: 3,
    };

    return savedWorkspace;
  },
};
