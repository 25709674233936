import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import { mapDispatchToPropsCatchTile } from './connectCatchTile';
import { mapStateToPropsTileSize } from './connectTileSize';
import { mapStateToPropsQuoteHasError } from './connectQuoteHasError';
import { mapDispatchToPropsQuoteError } from './connectQuoteError';
import { mapStateToPropsTileInstrument } from './connectTileInstrument';
import { mapStateToPropsTileStatus } from './connectTileStatus';

export const connectCatchTile = connect(null, mapDispatchToPropsCatchTile(actionCreators));

export const connectTileSize = connect(mapStateToPropsTileSize(selectors));

export const connectQuoteHasError = connect(mapStateToPropsQuoteHasError(selectors));

export const connectQuoteError = connect(null, mapDispatchToPropsQuoteError(actionCreators));

export const connectTileInstrument = connect(mapStateToPropsTileInstrument(selectors));

export const connectTileStatus = connect(mapStateToPropsTileStatus(selectors));
