import { isDefined } from '@sgme/fp';
import { format, isValid, type Locale, parseISO, addMinutes } from 'date-fns';
import { enUS as en, fr, de } from 'date-fns/locale';

const locales: { [key: string]: Locale } = {
  en,
  fr,
  de,
};

const isTenor = (dateOrTenor: string) => /^([0-9]+[mdyw]{1}|[a-z]+)$/gi.test(dateOrTenor.trim());

export const DATE_FORMAT_ISO = 'yyyy-MM-dd';
export const DATE_FORMAT_TEXT = 'dd MMM yyyy';
export const FULL_DATE_FORMAT_TEXT = 'dd MMM yyyy h:mm a';
export const FULL_DATE_FORMAT_EXPIRY = 'dd MMM yyyy h:mm:ss a';
export const FULL_DATE_MULTIPASS = "yyyy-MM-dd'T'HH:mm:ss";
export const FULL_DATE_OMS = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export function formatUserInputtedDate(date: Date | string | null, locale: string): string {
  if (date === null) {
    return '';
  }
  if (typeof date === 'string' && isTenor(date)) {
    return date;
  }
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  if (isValid(parsedDate)) {
    return format(parsedDate, DATE_FORMAT_TEXT, { locale: locales[locale] });
  }
  return date as string;
}

function formatWithLocaleWith(formatString: string) {
  return (date: Date | null | undefined, locale: string): string =>
    !isDefined(date) || !isValid(date)
      ? ''
      : format(date, formatString, { locale: locales[locale] });
}

export const formatDateTimeWithLocale = formatWithLocaleWith(FULL_DATE_FORMAT_TEXT);
export const formatDateTimeWithLocaleForExpiry = formatWithLocaleWith(FULL_DATE_FORMAT_EXPIRY);

export function toUtc(date: Date) {
  return addMinutes(date, date.getTimezoneOffset());
}

export const formatDateWithLocale = formatWithLocaleWith(DATE_FORMAT_TEXT);

export function formatDateWithLocaleAndFormat(locale: string) {
  const chosenLocale = locales[locale];
  return (formatString: string) => (date: Date) =>
    format(date, formatString, { locale: chosenLocale });
}

export function getDateForMultipass(date: Date): string {
  return date === null || !isValid(date) ? '' : format(date, FULL_DATE_MULTIPASS);
}

export function getFullDateForOMS(date: Date): string {
  return date === null || !isValid(date) ? '' : format(date, FULL_DATE_OMS);
}

export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function extractDayAndTime(date: Date): DayAndTime {
  const day = format(date, 'yyyy-MM-dd');
  const time = format(date, 'HH:mm:ss');
  return { day, time };
}

export interface DayAndTime {
  day: string;
  time: string;
}
