import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { Instrument } from 'state/referenceData/referenceDataModel';

export interface TestUtilConnectOwnProps {
  quoteId: string;
}

export interface TestUtilConnectStateProps {
  instrument: Instrument;
  rfsId: string | null;
  streamKey: string | null;
  lastExecutedQuoteId: string | null;
}

export type TestUtilConnectSelectorsKeys =
  | 'getQuoteInstrument'
  | 'getTileCurrentStreamId'
  | 'getTileEspStreamId'
  | 'getTileLastExecutedQuoteId';
export type TestUtilConnectSelectors = Pick<Selectors, TestUtilConnectSelectorsKeys>;

export const mapStateToPropsTestUtil: MapStateToPropsHOF<
  TestUtilConnectStateProps,
  TestUtilConnectOwnProps,
  AppState,
  TestUtilConnectSelectors
> =
  sl =>
  (state, { quoteId }) => {
    const instrument = sl.getQuoteInstrument(state, quoteId);
    const isSmartRfs = instrument === 'SmartRfs';

    // smartRfs has no state/logic related to product execution
    return {
      instrument,
      rfsId: isSmartRfs ? null : sl.getTileCurrentStreamId(state, quoteId),
      streamKey: isSmartRfs ? null : sl.getTileEspStreamId(state, quoteId),
      lastExecutedQuoteId: isSmartRfs ? null : sl.getTileLastExecutedQuoteId(state, quoteId),
    };
  };
