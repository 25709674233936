export const calendarProperties = [
  'fixingDate',
  'paymentDate',
  'EKI',
  'strike',
  'ekiUp',
  'strikeUp',
  'PIVOT',
  'strikeDown',
  'ekiDown',
  'step',
  'amount',
  'leverageAmount',
  'deleteIcon',
] as const;

export type SchedulerCalendarProperty = (typeof calendarProperties)[number];
