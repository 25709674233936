import type { Selectors } from 'state/selectors';
import type { Mappers } from './typings';
import { liftToString } from './utils';

export const swapMappers = (
  sl: Selectors,
): Mappers<'Swap', 'xCurrency' | 'nearPaymentDateTenor' | 'farPaymentDateTenor'> => ({
  currencyPair: sl.getSwapCurrencyPair,
  isNonDeliverable: sl.getSwapIsNonDeliverable,
  isUneven: sl.getSwapIsUneven,
  isOffMarket: sl.getSwapIsOffMarket,
  amountCurrency: sl.getSwapAmountCurrency,
  nearAmount: liftToString(sl.getSwapNearAmount),
  nearPaymentDate: sl.getSwapNearPaymentDate,
  nearPriceReference: sl.getSwapNearPrice,
  farAmount: liftToString(sl.getSwapFarAmount),
  farPaymentDate: sl.getSwapFarPaymentDate,
  farPriceReference: sl.getSwapFarPrice,
  fixingSource: sl.getSwapFixingSource,
  sndFixingSource: sl.getSwapSndFixingSource,
});
