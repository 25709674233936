import type { AppState } from 'state/model';
import { getGridById } from './getGrid';
import { throwIfUndefined } from 'utils/maps';

export function getGridItemPositionsByGridId(state: AppState, gridId: string) {
  const gridLayout = getGridLayout(state, gridId);
  return gridLayout.gridItemPositions;
}

export function getGridItemSize(state: AppState, gridId: string, gridItemId: string) {
  const gridLayout = getGridLayout(state, gridId);

  return throwIfUndefined(
    gridLayout.gridItemSizes[gridItemId],
    `Grid size for ${gridId}/${gridItemId} does not exist in state`,
  );
}

export function getGridItemPosition(state: AppState, gridId: string, gridItemId: string) {
  const gridLayout = getGridLayout(state, gridId);

  return throwIfUndefined(
    gridLayout.gridItemPositions[gridItemId],
    `Grid position for ${gridId}/${gridItemId} does not exist in state`,
  );
}

export function getAddTilePositionByGridID(state: AppState, gridId: string) {
  const grid = getGridById(state, gridId);
  if (grid.draggingStatus === 'DRAGGING') {
    return null;
  }
  return grid.addTilePositions;
}

const getGridLayout = (state: AppState, gridId: string) => {
  const grid = getGridById(state, gridId);
  if (grid.draggingStatus === 'DRAGGING') {
    return grid.draggingGridLayout;
  } else {
    return grid.gridLayout;
  }
};
