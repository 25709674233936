import { type Collection } from '../../typings/utils';

export const emptyFxSmartRfsState: FxSmartRfsState = {
  smartRfsRequested: false,
};

export type FxSmartRfsStateMap = Readonly<Collection<FxSmartRfsState>>;

// this will likely need to evolve, maybe follow the input/value shape of other product, for now this is only need for the spinner
export interface FxSmartRfsState {
  smartRfsRequested: boolean;
}
