import type { FormDomain } from 'state/referenceData';
import { genericContext } from './GenericContext';

export {
  withQuoteAndLegIds,
  QuoteIdContext,
  QuoteIdProvider,
  withQuoteId,
  LegIdContext,
  LegIdProvider,
  withLegId,
} from './QuoteAndLegIdsContext';

export const [IndexContext, IndexProvider, withIndex] = genericContext('index', -1);

export const [WorkspaceIdContext, WorkspaceIdProvider, withWorkspaceId] = genericContext(
  'workspaceId',
  '@@_MISSING_WORKSPACEID_PROVIDER',
);

const defaultLegIds: readonly string[] = [];
export const [LegIdsContext, LegIdsProvider, withLegIds] = genericContext('legIds', defaultLegIds);

export const [InstrumentContext, InstrumentProvider, withInstrument] = genericContext<
  'instrument',
  // smartRfs is added here as we want it to be included in instrument provider
  // but excluded from other use case of FormDomain, since FormDomain is typically used for tile with
  // product state related to it. SmartRfs is a special tile that is only used to generate other tile
  // and therefore has no product state related to it
  FormDomain | 'SmartRfs' | undefined
>('instrument', undefined);

export const [GrowlIdContext, GrowlIdProvider, withGrowlId] = genericContext('growlId', '@@_MISSING_GROWLID_PROVIDER');

export const [IsZoomedContext, IsZoomedProvider, withIsZoomed] = genericContext<'isZoomed', boolean>('isZoomed', false);

export const [IsCollapsedContext, IsCollapsedProvider, withIsCollapsed] = genericContext<'isCollapsed', boolean>(
  'isCollapsed',
  false,
);
