import { InstrumentContext, QuoteIdContext } from 'components/contexts';
import { createContext, type MutableRefObject, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import type { AppState } from 'state/model';
import { getTileCurrencyPair } from 'state/tile/selectors';
import { fieldData } from 'utils/fieldSelectors';

interface CurrencyPickerContext {
  currencyPair: string | null;
  inputRef: MutableRefObject<HTMLInputElement | null>;
}

export const CurrencyPickerContext = createContext<CurrencyPickerContext>({
  currencyPair: null,
  inputRef: { current: null },
});

interface CurrencyPickerProviderProps {}

function CurrencyPickerProviderRaw({ children }: React.PropsWithChildren<CurrencyPickerProviderProps>): JSX.Element {
  const quoteId = useContext(QuoteIdContext);
  const instrument = useContext(InstrumentContext);
  const currencyPair = useSelector((state: AppState) => {
    if (instrument === 'SmartRfs') {
      return null;
    }
    return fieldData(getTileCurrencyPair(state, quoteId)).data;
  });

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const currentInputRef = inputRef.current;
    if (currentInputRef !== null) {
      const escHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          currentInputRef.value = currencyPair ?? '';
          currentInputRef.blur();
        }
      };
      currentInputRef.addEventListener('keydown', escHandler);
      return () => currentInputRef.removeEventListener('keydown', escHandler);
    }
  }, [inputRef, currencyPair]);

  const context: CurrencyPickerContext = {
    currencyPair,
    inputRef,
  };

  return <CurrencyPickerContext.Provider value={context}>{children}</CurrencyPickerContext.Provider>;
}

export const CurrencyPickerProvider = CurrencyPickerProviderRaw;
