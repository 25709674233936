import { selectors } from 'state/selectors';
import { swapRfsExecuteRequestWith } from './swapRfsExecuteRequest';
import { swapRfsExecutionWith } from './swapRfsExecution';
import { swapTradeCaptureToBackendWith } from './tradeCaptureToBackend';
import { swapTradeCaptureFromBackendWith } from './tradeCaptureFromBackend';

export const swapRfsExecuteRequest = swapRfsExecuteRequestWith(selectors);
export const swapRfsExecution = swapRfsExecutionWith(selectors);
export const createSwapTradeCaptureToBackendRequest = swapTradeCaptureToBackendWith(selectors);
export const swapTCResponseToPatch = swapTradeCaptureFromBackendWith(selectors);
