import type { BlotterOrderCommonValues, BlotterOrderValues } from 'state/blotter/blotterEntryModel';
import { isLimitOrder } from 'state/fxOrders/fxOrdersModel';
import type { AppState } from 'state/model';
import type { Selectors } from 'state/selectors';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';

type OrderMetaSelectors = Selectors;

export const metaSelectorOrderToBlotterOrderData: MapStateToMetadataHOF<
  Omit<BlotterOrderValues, 'updateTime'>,
  { quoteId: string; getDateNow: () => Date },
  AppState,
  OrderMetaSelectors
> =
  sl =>
  (state, { quoteId, getDateNow }) => {
    const product = sl.getOrderType(state, quoteId).value;
    const commonValues: Omit<BlotterOrderCommonValues, 'product' | 'updateTime'> = {
      account: sl.getClientForTile(state, quoteId).companyId.toString(),
      currencyPair: sl.getOrderCurrencyPair(state, quoteId).value!,
      status: 'Submitted', // Why "Submitted" ? if change for other value it's broke placing order
      way: sl.getOrderWay(state, quoteId).value,
      notional: sl.getOrderAmount(state, quoteId).value,
      notionalCurrency:
        sl.getOrderAmountCurrency(state, quoteId).value === 1
          ? sl.getOrderCurrency1(state, quoteId)
          : sl.getOrderCurrency2(state, quoteId),
      executionPrice: null,
      executedNotional: 0,
      remainingAmount: sl.getOrderAmount(state, quoteId).value,
      isGtc: sl.getOrderIsGtc(state, quoteId).value,
      rejectReason: null,
      expiryDay: sl.getOrderExpiryDay(state, quoteId).value,
      expiryTime: sl.getOrderExpiryTime(state, quoteId).value,
    };

    const limitPrice = sl.getOrderLimitPrice(state, quoteId).value;
    if (isLimitOrder(product)) {
      const customerPrice = sl.getOrderCustomerPrice(state, quoteId).value;
      return {
        product,
        ...commonValues,
        limitPrice: limitPrice === null ? null : parseFloat(limitPrice),
        customerPrice: customerPrice === null ? null : parseFloat(customerPrice),
      };
    } else {
      const clipSize = sl.getOrderClipSize(state, quoteId).value;
      const startDateTime = getDateNow().toISOString();
      const [startDate, startTime] = sl.getOrderIsStartDateNow(state, quoteId)
        ? startDateTime.split(/T|Z/)
        : [
            sl.getOrderStartDate(state, quoteId).value!,
            sl.getOrderStartTime(state, quoteId).value!,
          ];
      return {
        product,
        ...commonValues,
        startDate,
        startTime,
        endDate: sl.getOrderExpiryDay(state, quoteId).value,
        endTime: sl.getOrderExpiryTime(state, quoteId).value,
        maturityDate: sl.getOrderMaturityDate(state, quoteId).value,
        liquidityPool: sl.getOrderLiquidityPool(state, quoteId).value,
        clippingMode: sl.getOrderClippingMode(state, quoteId).value,
        clipSize: clipSize === null ? null : parseFloat(clipSize),
        randomize: sl.getOrderRandomize(state, quoteId).value,
        noWorseThan: limitPrice === null ? null : parseFloat(limitPrice),
        spreadCapture: sl.getOrderSpreadCapture(state, quoteId).value,
        alphaSeeker: sl.getOrderAlphaSeeker(state, quoteId).value,
        speed: sl.getOrderSpeed(state, quoteId).value,
      };
    }
  };
