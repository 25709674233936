import { logger } from 'logging/logger';
import type { StreamSource } from 'state/fxCashs/selectors/fxCashsSelectors';
import type { Instrument } from 'state/referenceData/referenceDataModel';

type LogLevelCode = 0 | 1 | 2;

type LogLevel = 'off' | 'info' | 'verbose';

/* eslint-disable no-console */

const logLevelMap: Record<LogLevel, LogLevelCode> = {
  off: 0,
  info: 1,
  verbose: 2,
};

function getLogLevelCode(level: string | null) {
  const log = level !== null ? (level as LogLevel) : 'off';
  return logLevelMap[log] ?? 0;
}

const idLogger = {
  log: id,
  table: id,
};

// @TODO: remove this
export function loggerBuilder(query: URLSearchParams) {
  const queryLogLevel = getLogLevelCode(query.get('log'));
  return function withprefix(prefix: string) {
    return function atLevel(level: Exclude<keyof typeof logLevelMap, 'off'>) {
      const logLevel = getLogLevelCode(level);
      return query.get('log-' + prefix) !== null || queryLogLevel >= logLevel
        ? {
            log: function log(...args: readonly any[]) {
              console.log(prefix, ...args);
            },
            table: function table(...args: any[]) {
              const title = typeof args[0] === 'string' ? `${prefix} ${args.shift()}` : prefix;
              console.groupCollapsed(title);
              console.table(...args);
              console.groupEnd();
            },
          }
        : idLogger;
    };
  };
}

function id(..._args: readonly any[]) {
  //
}

export function timeoutedExecutionLogging(
  requestType: StreamSource,
  instrument: Instrument,
  message: string,
) {
  logger.logWarning(
    '{instrument} {requestType} quote execution has being manually rejected by MultipassBridge because timeout',
    instrument,
    requestType,
    message,
  );
}
