import type { LogoffReason } from './sessionModel';

export type SessionActions = NotifyDisconnection | SessionRegisterSuccess | SessionRegisterFailure;

export interface NotifyDisconnection {
  type: 'NOTIFY_DISCONNECTION';
  reason: LogoffReason;
}

export function notifyDisconnection(reason: LogoffReason): NotifyDisconnection {
  return {
    type: 'NOTIFY_DISCONNECTION',
    reason,
  };
}

export interface SessionRegisterSuccess {
  type: 'SESSION_REGISTER_SUCCESS';
}

export function sessionRegisterSuccess(): SessionRegisterSuccess {
  return {
    type: 'SESSION_REGISTER_SUCCESS',
  };
}

export interface SessionRegisterFailure {
  type: 'SESSION_REGISTER_FAILURE';
}

export function sessionRegisterFailure(): SessionRegisterFailure {
  return {
    type: 'SESSION_REGISTER_FAILURE',
  };
}
