import { shallowEqual } from './shallowEqual';

/**
 * This unique key should not be exported.
 * It enforces that only code in the scope of this
 * module can access previous props from state
 */
const props = Symbol('previous props');

export interface PreviousProps<T> {
  [props]?: T;
}

function getPreviousProps<T>(state: PreviousProps<T>): T | undefined {
  return state[props];
}

function addNextPropsToState<T>(state: PreviousProps<T> | null, nextProps: T) {
  const nextState = { [props]: nextProps };
  return state === null ? nextState : { ...(state as any), ...nextState };
}

export function deriveStateOnChangedProps<P, S extends PreviousProps<P>>(
  getDerivedStateFromProps: React.GetDerivedStateFromProps<P, S>,
): React.GetDerivedStateFromProps<P, S> {
  return (nextProps, previousState) =>
    previousState !== null && shallowEqual(nextProps, getPreviousProps(previousState))
      ? null
      : addNextPropsToState(getDerivedStateFromProps(nextProps, previousState), nextProps);
}
