import { combineReducers } from 'redux';
import type { Mifid2PanelState } from '../mifidPanelModel';
import { mifid2ToggleReducer } from './mifidToggleReducer';
import { mifid2NegotiationsReducer } from './mifidNegotiationsReducer';
import { mifid2StartingNegotiationReducer } from './mifidStartingNegotiationReducer';
import { mifid2QuotesReducer } from './mifidQuotesReducer';

export const mifid2PanelReducer = combineReducers<Mifid2PanelState>({
  showMifid2Panel: mifid2ToggleReducer,
  isStartingNegotiation: mifid2StartingNegotiationReducer,
  negotiations: mifid2NegotiationsReducer,
  quotes: mifid2QuotesReducer,
});
