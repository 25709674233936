import { type ReactNode, useId } from 'react';
import { label, checkBox } from 'components/Form/Inputs/e2e';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { HStack, type Size } from '@sgme/ui';

interface SwitchInputProps {
  value: boolean;
  disabled?: boolean;
  e2eHandle: string;
  children: ReactNode;
  onChange: (value: boolean) => void;
  size?: Size;
}

export function SwitchInput({
  value,
  disabled = false,
  e2eHandle,
  children,
  onChange,
  size = 'md',
}: SwitchInputProps): JSX.Element {
  const id = useId();
  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked);
  return (
    <>
      <label htmlFor={id} className="cursor-pointer m-0" data-e2e={label(e2eHandle)}>
        {children}
      </label>

      <HStack>
        <div className={`form-check form-check-${size} form-check-valid form-switch`} data-e2e={e2eHandle}>
          <input
            className="form-check-input me-8px"
            checked={value}
            type="checkbox"
            role="switch"
            onChange={onSwitchChange}
            id={id}
            disabled={disabled}
            data-e2e={checkBox(e2eHandle)}
          />

          <StatusLabel htmlFor={id}>
            <FormattedMessage id={`switchInput.${value ? 'on' : 'off'}`} />
          </StatusLabel>
        </div>
      </HStack>
    </>
  );
}

const StatusLabel = styled.label.attrs({ className: 'form-check-label mb-0 cursor-pointer' })`
  width: 2em;
`;
