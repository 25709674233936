import type { Observable } from 'rxjs';
import type { Way } from 'state/share/productModel/litterals';
import type { HttpCall, ISgmeHttp } from 'utils/sgmeHttp';
import { transformEmailsToBase64 } from 'utils/base64';

export interface SwapRfsExecutePayload {
  replyToStream: string;
  rfsId: string;
  quoteId: string;
  way: Way;
  spot: number;
  nearPrice: number;
  farPrice: number;
  nearPoints: number;
  farPointsWithoutMargin: number;
  farPointsWithMargin: number;
  nearSalesAllInRate: number;
  farSalesAllInRate: number;
  emailNotificationList: string;
  userLocalDateTime: string;
  lastQuoteFromBackTimestamp: number;
  lastQuoteReceivedByFrontTimestamp: number;
  executionClickByFrontTimestamp: number;
}

export interface ISwapRfsQuoteExecuteRequestApi {
  msApiTimeout: number;
  executeQuoteRequest(payload: SwapRfsExecutePayload): Observable<HttpCall<void>>;
}

export function swapRfsQuoteExecuteRequestApi(http: ISgmeHttp): ISwapRfsQuoteExecuteRequestApi {
  return {
    executeQuoteRequest({
      replyToStream,
      rfsId,
      quoteId,
      way,
      spot,
      nearPrice,
      farPrice,
      nearPoints,
      farPointsWithoutMargin,
      farPointsWithMargin,
      nearSalesAllInRate,
      farSalesAllInRate,
      emailNotificationList,
      userLocalDateTime,
      lastQuoteFromBackTimestamp,
      lastQuoteReceivedByFrontTimestamp,
      executionClickByFrontTimestamp,
    }) {
      const payload = {
        replyToStream,
        rfsId,
        quoteId,
        way,
        spot,
        nearPrice,
        farPrice,
        nearPoints,
        farPointsWithoutMargin,
        farPointsWithMargin,
        nearSalesAllInRate,
        farSalesAllInRate,
        emailNotificationList,
        userLocalDateTime,
        lastQuoteFromBackTimestamp,
        lastQuoteReceivedByFrontTimestamp,
        executionClickByFrontTimestamp,
      };

      return http.post<void>(
        'api/fxSwap/rfs/execute',
        transformEmailsToBase64(payload, 'emailNotificationList', 'email64NotificationList'),
        { 'X-Correlation-ID': payload.rfsId },
      );
    },
    msApiTimeout: 60_000,
  };
}
