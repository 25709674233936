import type { PriceType } from '../fxOptionsModel';
import { tenPower, roundToPrecision } from 'utils/number';
import { identity } from '@sgme/fp';

// main computations
export const decimalToPercent = (decimal: number) =>
  Number((decimal * 100).toFixed(priceTypePrecision.PERCENT));
export const decimalToPps = (precision: number) => (decimal: number) =>
  Number((decimal * tenPower(precision)).toFixed(priceTypePrecision.PPS));

// reverse (from user input)
export const percentToDecimal = (percent: number) => Number(divider(percent, 100).toFixed(10));
export const ppsToDecimal = (precision: number) => (pps: number) =>
  Number(divider(pps, tenPower(precision)).toFixed(10));

const premiumDisplayMappers: Record<PriceType, (precision: number) => (decimal: number) => number> =
  {
    AMOUNT: () => identity,
    VOLATILITY: () => identity,
    PERCENT: () => decimalToPercent,
    PPS: decimalToPps,
  };

export const makeTransformPremiumForDisplay = (premiumType: PriceType, precision: number) =>
  premiumDisplayMappers[premiumType](precision);

const premiumInputMappers: Record<PriceType, (precision: number) => (decimal: number) => number> = {
  AMOUNT: () => identity,
  VOLATILITY: () => identity,
  PERCENT: () => percentToDecimal,
  PPS: ppsToDecimal,
};

export const makeTransformPremiumInput = (premiumType: PriceType, precision: number) =>
  premiumInputMappers[premiumType](precision);

const dividerWithPrecision = (precision: number) => {
  const factor = tenPower(precision);
  return (dividend: number, divisor: number) =>
    (roundToPrecision(dividend, precision) * factor) / divisor / factor;
};

const divider = dividerWithPrecision(4);

export const priceTypePrecision: Record<PriceType, number> = {
  AMOUNT: 2,
  VOLATILITY: 2,
  PERCENT: 4,
  PPS: 4,
};
