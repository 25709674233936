import type { Thunk } from 'state';
import { assertUnreachable } from '@sgme/fp';
import { isAlgoOrder } from '../../fxOrders/fxOrdersModel';
import type { BlotterClickAction, OrderResultMode } from '../blotterEntryModel';

export function blotterClickActionThunk(
  blotterId: string,
  clickAction: BlotterClickAction,
  childrenIds: readonly string[] | null = null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (sl.getBlotterActiveTab(state) !== 'order') {
      dispatch(ac.blotterDisplayDetailPanelThunk(blotterId, 'Display', childrenIds));
      return;
    }

    const order = sl.getBlotterOrderById(state, blotterId).values;
    const isAlgo = isAlgoOrder(order.product);

    dispatch(
      ac.openOrderTileFromBlotterEntryThunk(blotterId, clickActionToOrderResultMode[clickAction]),
    );

    switch (clickAction) {
      case 'Cancel':
        if (isAlgo) {
          dispatch(ac.blotterOrderCancellationEpic(blotterId, blotterId));
        }
        return;
      case 'Pause':
        dispatch(ac.blotterOrderPauseEpic(blotterId));
        return;
      case 'Resume':
        dispatch(ac.blotterOrderResumeEpic(blotterId));
        return;
      case 'Fill':
        dispatch(ac.blotterOrderFillEpic(blotterId));
        return;
      case 'Edit':
      case 'Display':
        return;
      default:
        assertUnreachable(clickAction, 'Unhandled mode');
    }
  };
}

const clickActionToOrderResultMode: Record<
  BlotterClickAction,
  Exclude<OrderResultMode, 'Error' | 'StillPending'>
> = {
  Cancel: 'Cancel',
  Pause: 'Display',
  Display: 'Display',
  Edit: 'Edit',
  Fill: 'Display',
  Resume: 'Display',
};
