import type { AppState } from 'state/model';
import {
  getValueProperty,
  type ValueKey,
  type SharedKey,
  getSharedProperty,
  type SharedPropertyStatus,
} from 'state/share/form';

import { getOptionState } from './fxOptionsProductSelectors';
import type { IFxOptionInputs, IFxOptionMetadata, IFxOptionValues } from '../model/optionProduct';
import type {
  FxOptionVanillaLegMetadata,
  IFxVanillaLegInputs,
  IFxVanillaLegValues,
} from '../model/optionsLegs';
import { getOptionLeg, getVanillaLeg } from './fxOptionsLegsSelectors';
import { isVanillaLeg } from '../utilities';
import type { IFxHedgeInputs, IFxHedgeValues } from '../model/optionHedges';
import { getOptionHedge } from './fxOptionsHedgesSelectors';
import { isDefined } from '@sgme/fp';

export type OptionMetadataStatus<T extends keyof IFxOptionMetadata> = IFxOptionMetadata[T];

export function getOptionMetadata<T extends keyof IFxOptionMetadata>(property: T) {
  return (state: AppState, optionId: string): OptionMetadataStatus<T> =>
    getOptionState(state, optionId)[property];
}
type OptionValuePropertyKey = ValueKey<IFxOptionValues, IFxOptionInputs>;

export function getOptionValue<T extends OptionValuePropertyKey>(prop: T) {
  const get = getValueProperty<IFxOptionValues, IFxOptionInputs, T>(prop);
  return (state: AppState, optionId: string) => get(getOptionState(state, optionId));
}
export type OptionValuePropertyStatus<T extends OptionValuePropertyKey> = IFxOptionValues[T];

type OptionSharedPropertyKey = SharedKey<IFxOptionValues, IFxOptionInputs>;

export function getOptionInput<T extends OptionSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<IFxOptionValues, IFxOptionInputs, T>(prop);
  return (state: AppState, optionId: string) => get(getOptionState(state, optionId));
  // return (state: AppState, optionId: string) => get(getOptionState(state, optionId));
}

export type OptionSharedPropertyStatus<T extends OptionSharedPropertyKey> = SharedPropertyStatus<
  IFxOptionValues[T],
  IFxOptionInputs[T]
>;

// TODO: validate that all references have the same behavior of the previous one with subLegId
// if leg with legId is a vanilla, don't use subLegId, and return the leg
// if subLegId i defined, get the leg.sublegs[subLegId], and check it is defined
// else return leg.sublegs[0]
export function getOptionVanillaLegValue<
  T extends ValueKey<IFxVanillaLegValues, IFxVanillaLegInputs>,
>(prop: T) {
  const get = getValueProperty<IFxVanillaLegValues, IFxVanillaLegInputs, T>(prop);
  return (state: AppState, legId: string) => {
    const leg = getOptionLeg(state, legId);

    if (isVanillaLeg(leg)) {
      return get(leg);
    }

    const vanillaLeg = leg.legIds[0];
    return get(getVanillaLeg(state, vanillaLeg));
  };
}

type OptionLegSharedPropertyKey = SharedKey<IFxVanillaLegValues, IFxVanillaLegInputs>;

// TODO: validate that all references have the same behavior of the previous one with subLegId
// if leg with legId is a vanilla, don't use subLegId, and return the leg
// if subLegId i defined, get the leg.sublegs[subLegId], and check it is defined
// else return leg.sublegs[0]
export function getOptionVanillaLegInput<T extends OptionLegSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<IFxVanillaLegValues, IFxVanillaLegInputs, T>(prop);
  return (state: AppState, legId: string) => {
    const leg = getOptionLeg(state, legId);

    if (isVanillaLeg(leg)) {
      return get(leg);
    }

    const vanillaLeg = leg.legIds[0];
    return get(getVanillaLeg(state, vanillaLeg));
  };
}

export type OptionLegSharedPropertyStatus<T extends OptionLegSharedPropertyKey> =
  SharedPropertyStatus<IFxVanillaLegValues[T], IFxVanillaLegInputs[T]>;

export type OptionLegMetadataStatus<T extends keyof FxOptionVanillaLegMetadata> =
  FxOptionVanillaLegMetadata[T];

export function getOptionVanillaLegMetadata<T extends keyof FxOptionVanillaLegMetadata>(
  property: T,
) {
  return (state: AppState, legId: string) => getVanillaLeg(state, legId)[property];
}

type OptionHedgeSharedPropertyKey = SharedKey<IFxHedgeValues, IFxHedgeInputs>;

export function getOptionHedgeInput<T extends OptionHedgeSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<IFxHedgeValues, IFxHedgeInputs, T>(prop);

  return (state: AppState, hedgeId: string) => {
    const hedge = getOptionHedge(state, hedgeId);

    return isDefined(hedge) ? get(hedge) : undefined;
  };
}

type OptionHedgeValuePropertyKey = ValueKey<IFxHedgeValues, IFxHedgeInputs>;

export function getOptionHedgeValue<T extends OptionHedgeValuePropertyKey>(prop: T) {
  const get = getValueProperty<IFxHedgeValues, IFxHedgeInputs, T>(prop);
  return (state: AppState, hedgeId: string) => {
    const hedge = getOptionHedge(state, hedgeId);

    return isDefined(hedge) ? get(hedge) : undefined;
  };
}
