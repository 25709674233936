import type { Observable } from 'rxjs';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import { map } from 'rxjs/operators';
import type {
  OrderType,
  IFxOrderValues,
  ClippingMode,
  Speed,
  FixingMarginType,
  FixingPriceType,
} from 'state/fxOrders/fxOrdersModel';
import { v4 } from 'uuid';
import { isDefined, isEmpty } from '@sgme/fp';

export interface IOrderApi {
  timeout: number;
  stillPendingTimeout: number;
  validateOrder(
    orderType: OrderType,
    payload: Partial<OmsOrderSubmissionPayload>,
    correlationId: string,
  ): Observable<OmsOrderValidationResponse>;
  submitOrder(
    orderType: OrderType,
    payload: Partial<OmsOrderSubmissionPayload>,
    correlationId: string,
  ): Observable<string>;
  cancelOrder(request: OmsOrderSimpleRequest): Observable<any>;
  modifyOrder(request: OmsOrderModifyRequest): Observable<any>;
  pauseOrder(request: OmsOrderSimpleRequest): Observable<any>;
  resumeOrder(request: OmsOrderSimpleRequest): Observable<any>;
  fillOrder(request: OmsOrderSimpleRequest): Observable<any>;
}

export function omsApi(http: ISgmeHttp): IOrderApi {
  const sendRequest = sendRequestHOF(http);
  return {
    validateOrder(
      orderType: OrderType,
      payload: Partial<OmsOrderSubmissionPayload>,
      correlationId: string,
    ) {
      return sendRequest<Partial<OmsOrderSubmissionPayload>, OmsOrderValidationResponse>(
        'validate',
        orderType,
        payload,
        correlationId,
      );
    },
    submitOrder(
      orderType: OrderType,
      payload: Partial<OmsOrderSubmissionPayload>,
      correlationId: string,
    ) {
      return sendRequest<Partial<OmsOrderSubmissionPayload>, string>(
        'submit',
        orderType,
        payload,
        correlationId,
      );
    },
    cancelOrder({ orderType, payload }: OmsOrderSimpleRequest) {
      return sendRequest<OrderRef, any>('cancel', orderType, payload, v4());
    },
    modifyOrder({ orderType, payload }: OmsOrderModifyRequest) {
      return sendRequest<OrderModifyPayload | FixingOrderModifyPayload, any>(
        'update',
        orderType,
        payload,
        v4(),
      );
    },
    pauseOrder({ orderType, payload }: OmsOrderSimpleRequest) {
      return sendRequest<OrderRef, any>('pause', orderType, payload, v4());
    },
    resumeOrder({ orderType, payload }: OmsOrderSimpleRequest) {
      return sendRequest<OrderRef, any>('resume', orderType, payload, v4());
    },
    fillOrder({ orderType, payload }: OmsOrderSimpleRequest) {
      return sendRequest<OrderRef, any>('fill-now', orderType, payload, v4());
    },
    timeout: 10_000,
    stillPendingTimeout: 5_000,
  };
}

type ActionType = 'validate' | 'submit' | 'cancel' | 'update' | 'pause' | 'resume' | 'fill-now';

function sendRequestHOF(http: ISgmeHttp) {
  return <REQ, RESP>(
    action: ActionType,
    orderType: OrderType,
    payload: REQ,
    correlationId: string,
  ) => {
    const url = `api/oms/${action}/${orderType}`;
    return http
      .post<RESP>(url, payload, { 'X-Correlation-ID': correlationId })
      .pipe(map(httpCall => httpCall.payload));
  };
}

export interface OrderRequestPayload {
  orderType: OrderType;
  payload: Partial<OmsOrderSubmissionPayload>;
  correlationId: string;
}

export type OmsOrderSubmissionResponse = OmsOrderValidationResponse | string;

export interface OmsOrderValidationResponse {
  message: string;
  validationResponseDetailContent: readonly OmsOrderValidationDetail[];
  isReadyToSubmit: boolean;
  expiryDay: string | null;
  maturityDay: string | null;
  defaultPrice: number | null;
  fixingTypes: string[] | null;
  isCcyForcedBidAsk: boolean | null;
  isStandardGroup: boolean | null;
  fixingTimesOfSelectedType: string[] | null;
  sourceNameOfSelectedType: string[] | null;
  marginInBps: number | null;
  margin: number | null;
  limitPrice: number | null;
  customerPrice: number | null;
  canModifyMarginInBps: boolean;
  fixingMarginType: FixingMarginType;
  ndfFixingDate: string | null;
  ndfFixingSource: string | null;
  backendTime: number | null;
}

export interface OrderValidationDetail {
  code: string;
  field: Partial<keyof IFxOrderValues>;
  description: string;
}

export interface OrderValidationResponse {
  isReadyToSubmit: boolean;
  validationDetails: readonly OrderValidationDetail[];
  expiryDay: string | null | undefined;
  expiryTime: string | null | undefined;
  maturityDay: string | null | undefined;
  limitPrice: number | null | undefined;
  customerPrice?: number | null;
  fixingTypes: string[] | null;
  isCcyForcedBidAsk: boolean | null;
  isStandardGroup: boolean | null;
  fixingTimesOfSelectedType: string[] | null;
  sourceNameOfSelectedType: string[] | null;
  marginInBps: number | null;
  canModifyMarginInBps: boolean;
  fixingMarginType: FixingMarginType;
  ndfFixingDate: string | null;
  ndfFixingSource: string | null;
  margin: number | null | undefined;
}

export function isOmsOrderValidationResponse(
  response: any | null,
): response is OmsOrderValidationResponse {
  return (
    isDefined(response) &&
    response.validationResponseDetailContent !== undefined &&
    Array.isArray(response.validationResponseDetailContent) &&
    response.isReadyToSubmit !== undefined
  );
}

export function isOmsOrderValidationResponseWithFieldError(
  response: any | null,
): response is OmsOrderValidationResponse {
  return (
    isOmsOrderValidationResponse(response) && !isEmpty(response.validationResponseDetailContent)
  );
}

export interface OmsOrderValidationDetail {
  field: string;
  errorDescription: string;
  errorCode: number;
}

export interface OmsOrderSubmissionPayload {
  bdrId: string;
  bdrIni: string | undefined;
  ulysseLogin: string;
  owner: string;
  ccyPair: string;
  way: 'sell' | 'buy';
  limitPrice: string;
  customerPrice: string | undefined;
  expiryDay: string | undefined;
  expiryTime: string | undefined;
  triggerMode: 'Spot' | 'BidOffer' | 'RateOneTouch';
  base64Emails: string;
  amountInCcy1: number | undefined;
  amountInCcy2: number | undefined;
  isGtc: boolean;
  maturityDateString: string | undefined;
  startDateUtc: string | undefined;
  endDateUtc: string | undefined;
  liquidityPool: string | undefined;
  clippingMode: ClippingMode | undefined;
  clipSize: number | undefined;
  dodging: boolean | undefined;
  spreadCapture: boolean | undefined;
  speed: Speed | undefined;
  alphaSeeker: boolean | undefined;
  fixingBenchmark: string | undefined;
  fixingPriceType: FixingPriceType | undefined;
  fixingDateUtc: string | undefined;
  fixingTime: string | undefined;
  fixingPlace: string | undefined;
  marginInBps: number | undefined;
  margin: number | undefined;
  fixingMarginType: FixingMarginType | undefined;
  isNdf: boolean | undefined;
  localTimeWithOffSet : number;
}

interface OrderRef {
  orderRef: string;
}
export interface OmsOrderSimpleRequest {
  orderType: OrderType;
  payload: OrderRef;
}

export interface OmsOrderModifyRequest {
  orderType: OrderType;
  payload: OrderModifyPayload | FixingOrderModifyPayload;
}

interface OrderModifyPayload extends OrderRef {
  orderRef: string;
  ccyPair: string | null;
  limitPrice: string | undefined;
  customerPrice: string | undefined;
  amountInCcy1?: number | undefined;
  amountInCcy2?: number | undefined;
  startDateUtc: string | undefined;
  endDateUtc: string | undefined;
  liquidityPool: string | undefined;
  clippingMode: ClippingMode | undefined;
  clipSize: number | undefined;
  dodging: boolean | undefined;
  spreadCapture: boolean | undefined;
  speed: Speed | undefined;
  alphaSeeker: boolean | undefined;
  margin?: string | number | null | undefined;
}

interface FixingOrderModifyPayload extends OrderRef {
  orderRef: string;
  ccyPair: string | null;
  amountInCcy1?: number | undefined;
  amountInCcy2?: number | undefined;
  fixingPriceType: FixingPriceType | undefined;
  fixingTime: string | undefined;
  fixingPlace: string | undefined;
  marginInBps: number | undefined;
  fixingMarginType: FixingMarginType | undefined;
  fixingDateUtc: string | undefined;
  fixingBenchmark: string | undefined;
}
