import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { SaveState } from 'state/clientWorkspace';

export interface SaveWorkspaceButtonConnectOwnProps {}

export interface SaveWorkspaceButtonConnectStateProps {
  saveWorkspaceState: SaveState;
}

export interface SaveWorkspaceButtonConnectDispatchProps {
  saveWorkspace(): void;
}

// you can whitelist selectors after implementation
export type SaveWorkspaceButtonConnectSelectors = Selectors;
/*
export type SaveWorkspaceButtonConnectSelectorsKeys = 'aSelector'; // example
export type SaveWorkspaceButtonConnectSelectors = Pick<Selectors, SaveWorkspaceButtonConnectSelectorsKeys>;
*/

export const mapStateToPropsSaveWorkspaceButton: MapStateToPropsHOF<
  SaveWorkspaceButtonConnectStateProps,
  SaveWorkspaceButtonConnectOwnProps,
  AppState,
  SaveWorkspaceButtonConnectSelectors
> = sl => state => ({
  saveWorkspaceState: sl.getSaveState(state),
});

// you can whitelist action creators after implementation
export type SaveWorkspaceButtonConnectActionCreators = ActionCreators;
/*
export type SaveWorkspaceButtonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type SaveWorkspaceButtonConnectActionCreators = Pick<ActionCreators, SaveWorkspaceButtonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsSaveWorkspaceButton: MapDispatchToPropsHOF<
  SaveWorkspaceButtonConnectDispatchProps,
  SaveWorkspaceButtonConnectOwnProps,
  SaveWorkspaceButtonConnectActionCreators
> = ac => dispatch => ({
  saveWorkspace: () => dispatch(ac.saveWorkspaceThunk()),
});
