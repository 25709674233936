import type { Reducer } from 'redux';
import type { Action } from 'state/actions';

export const tradingDisabledReducer: Reducer<boolean> = (
  state: boolean = true,
  action: Action,
): boolean => {
  switch (action.type) {
    case 'REFERENCE_DATA_RECEIVED':
      return !action.userInfo.canTrade;
    case 'TOGGLE_TRADING_DISABLED':
      return !state;
    case 'TOGGLE_TRADING_RECEIVED':
      return !action.canTrade;
    default:
      return state;
  }
};
