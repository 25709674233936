import {
  CashLegId,
  type CashTradeCapturePatch,
  type CashTradeCaptureResponseLegProperties,
  type TradeCaptureCashResponseWrapper,
} from './tradeCaptureModel';
import { fromTcError, getPreviousMap, mapAmount, previousValue } from 'api/tradeCapture/tradeCaptureMappingHelper';
import type { IFxCashValues } from 'state/fxCashs/fxCashsModel';
import { clearUndefined } from 'utils/clearUndefined';

// Mapping XONE-TC => WEB APP

function mapCashTCLegPropertiesToCashStoreLegProperties(
  responseCashLeg: Partial<CashTradeCaptureResponseLegProperties>,
): Partial<IFxCashValues> {
  const amount = mapAmount(responseCashLeg.negotiatedCurrency, responseCashLeg.amount1, responseCashLeg.amount2);
  const mappedCashLegProperties: Partial<IFxCashValues> = {
    productName: responseCashLeg.productName,
    amount,
    amountCurrency: responseCashLeg.negotiatedCurrency,
    maturityDate: responseCashLeg.maturityDate,
    maturityDateTenor: responseCashLeg.maturityDateTenor,
    isNonDeliverable: responseCashLeg.isNonDeliverable,
    fixingDate: responseCashLeg.fixingDate,
    fixingSource: responseCashLeg.fixingSource,
    fixingCurrency: responseCashLeg.fixingCurrency,
    possibleFixingCurrencies: responseCashLeg.possibleFixingCurrencies,
    possibleFixingSources: responseCashLeg.possibleFixingSources,
    isCrossed: responseCashLeg.isCrossed,
    possibleXCurrencies: responseCashLeg.possibleXCurrencies,
    possibleSndFixingSources: responseCashLeg.possibleSndFixingSources,
    xCurrency: responseCashLeg.xCurrency,
    sndFixingSource: responseCashLeg.sndFixingSource,
  };
  return clearUndefined(mappedCashLegProperties);
}

export function mapTCCashResponseToCashPropertiesPatch({
  changedFields,
  errors,
  idVersion,
}: TradeCaptureCashResponseWrapper): CashTradeCapturePatch {
  const { legs } = changedFields;
  const { legs: legsErrors } = errors || { legs: {} };
  const legProperties = legs[CashLegId] || {};
  const legErrors = (legsErrors && legsErrors[CashLegId]) || {};

  const isReadyToPrice = changedFields.legs[CashLegId].isReadyToPrice;

  const currencyProperties = clearUndefined({
    currencyPair: changedFields?.currencyPair ?? undefined,
    currency1: changedFields?.currency1 ?? undefined,
    currency2: changedFields?.currency2 ?? undefined,
  });

  return {
    idVersion,
    values: {
      ...clearUndefined({
        ...getPreviousMap(legErrors),
        amount1: undefined, // Prevent TC model leak cause by getPreviousMap
        amount2: undefined, // Prevent TC model leak cause by getPreviousMap
        amount: previousValue(legErrors.amount1 || legErrors.amount2),
      }),
      ...currencyProperties,
      ...mapCashTCLegPropertiesToCashStoreLegProperties(legProperties),
    },
    errors: {
      ...clearUndefined({
        productName: fromTcError(legErrors.productName),
        currencyPair: fromTcError(legErrors.currencyPair),
        currency1: fromTcError(legErrors.currency1),
        currency2: fromTcError(legErrors.currency2),
        amount: fromTcError(legErrors.amount1 || legErrors.amount2),
        amountCurrency: fromTcError(legErrors.negotiatedCurrency),
        maturityDate: fromTcError(legErrors.maturityDate),
        maturityDateTenor: fromTcError(legErrors.maturityDateTenor),
      }),
    },
    isReadyToPrice: isReadyToPrice ?? null,
    isPriceObsolete: changedFields.legs[CashLegId].isPriceObsolete ?? false,
    productName: changedFields.legs[CashLegId].productName!,
  } as CashTradeCapturePatch;
}
