import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import type { AppState } from 'state/model';
import type { SendHelpStatus } from 'state/globalError/globalErrorModel';
import type { Action } from 'state/actions';
import { closeErrorDetail } from 'state/clientWorkspace/clientWorkspaceActions';
import { HelpButton } from './HelpButton';
import type { Dispatch } from 'redux';
import { getTilePropertiesRequestError } from 'state/tile/selectors';

interface IErrorDetailStateProps {
  quoteId: string | null;
  propertiesRequestError: string | null;
}

interface IErrorDetailDispatchProps {
  close(): void;
}

type ErrorDetailProps = IErrorDetailStateProps & IErrorDetailDispatchProps;

const mapStateToProps = (state: AppState): IErrorDetailStateProps => ({
  quoteId: state.globalError.tileId,
  propertiesRequestError:
    state.globalError.tileId !== null
      ? getTilePropertiesRequestError(state, state.globalError.tileId)
      : null,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): IErrorDetailDispatchProps => ({
  close: () => dispatch(closeErrorDetail()),
});

interface IErrorLineProps {
  title: React.ReactNode;
  message: React.ReactNode;
}

const ErrorLine: React.FunctionComponent<IErrorLineProps> = ({ title, message }) => (
  <div className="row flex-nowrap mb-3">
    <div className="col-4 text-end">{title}</div>
    <div className="col">
      <strong>{message}</strong>
    </div>
  </div>
);

const sentCallback = (close: () => void) => (status: SendHelpStatus | null) => {
  // eslint-disable-next-line no-unused-expressions
  status === 'success' && setTimeout(close, 1000);
};

const formatDetails = (quoteId: string | null, error: string | null) => () => ({
  quoteId,
  error,
});

export const ErrorDetail: React.FunctionComponent<ErrorDetailProps> = ({
  quoteId,
  propertiesRequestError,
  close,
}) => (
  <Modal
    isOpen={propertiesRequestError !== null}
    toggle={close}
    backdrop
    contentClassName="border border-danger"
    data-e2e="properties-error-details-modal"
  >
    <ModalHeader tag="h4" className="text-danger">
      <FormattedMessage id="modals.errorDetails.title" />
    </ModalHeader>
    {propertiesRequestError !== null && (
      <ModalBody>
        <div className="d-flex flex-column">
          <ErrorLine
            title={<FormattedMessage id="modals.errorDetails.codeType" />}
            message={propertiesRequestError}
          />
          <ErrorLine
            title={<FormattedMessage id="modals.errorDetails.quoteId" />}
            message={quoteId}
          />
        </div>
      </ModalBody>
    )}
    <ModalFooter>
      <HelpButton
        includeState
        details={formatDetails(quoteId, propertiesRequestError)}
        statusUpdateCallBack={sentCallback(close)}
      />
      <button type="button" className="btn btn-primary" onClick={close}>
        <FormattedMessage id="modals.errorDetails.footer.close" />
      </button>
    </ModalFooter>
  </Modal>
);

export const PropertiesErrorDetailsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorDetail);

export default PropertiesErrorDetailsModal;
