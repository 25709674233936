import type { AppState } from 'state/model';

export function isMifid2PanelVisible(state: AppState) {
  return state.mifid2Panel.showMifid2Panel;
}

export function isStartingMifid2Negotiation(state: AppState) {
  return state.mifid2Panel.isStartingNegotiation;
}

export function getMifid2PanelNegotiations(state: AppState) {
  return state.mifid2Panel.negotiations;
}

export function getMifid2PanelNegotiation(state: AppState, negotiationId: string) {
  return getMifid2PanelNegotiations(state)[negotiationId];
}

export function getMifid2NegotiationQuotes(state: AppState, negotiationId: string) {
  return getMifid2PanelNegotiation(state, negotiationId)?.quotes;
}

export function getMifid2Quote(state: AppState, negotiationId: string, quoteId: string) {
  return state.mifid2Panel.quotes[`${negotiationId}/${quoteId}`];
}
