import { PureComponent } from 'react';
import type { RenderPropChildren } from 'typings/utils';

interface RefToggleState<E = unknown> {
  isOpen: boolean;
  target: E | null;
  propIsOpen?: boolean;
}
interface RefToggleProps {
  isOpen?: boolean;
  toggle?(): void;
}
export interface RefToggleChildrenProps<E> {
  isOpen: boolean;
  target: E | null;
  ref(target: E | null): void;
  toggle(): void;
  close(): void;
  open(): void;
}

export class RefToggle<E = HTMLElement> extends PureComponent<
  RenderPropChildren<RefToggleChildrenProps<E>> & RefToggleProps,
  RefToggleState<E>
> {
  public state = {
    propIsOpen: this.props.isOpen,
    isOpen: this.props.isOpen === true,
    target: null,
  };
  public static getDerivedStateFromProps(
    { isOpen }: RefToggleProps,
    { propIsOpen }: RefToggleState,
  ) {
    if (isOpen !== propIsOpen) {
      return { isOpen, propIsOpen: isOpen };
    }
    return null;
  }
  private toggle = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
    if (this.props.toggle !== undefined) {
      this.props.toggle();
    }
  };
  private close = () => this.setState(() => ({ isOpen: false }));
  private open = () => this.setState(() => ({ isOpen: true }));
  private ref = (target: E | null): void => {
    this.setState(() => ({ target }));
  };
  private get childrenProps(): RefToggleChildrenProps<E> {
    return {
      target: this.state.target,
      isOpen: this.state.isOpen,
      toggle: this.toggle.bind(this),
      close: this.close.bind(this),
      open: this.open.bind(this),
      ref: this.ref.bind(this),
    };
  }
  public render() {
    return this.props.children(this.childrenProps);
  }
}
