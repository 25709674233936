import type {
  GridState,
  GridDraggingState,
  GridItemPosition,
  GridLayout,
  GridIdleState,
  Locus,
  Column,
} from '../gridLayoutModels';
import {
  getDownwardNeighbourGridItem,
  getPositionById,
  getSizeById,
  getOccupiedColumnsIndicesByCoordinates,
  findForceInsertionIndexInColumn,
} from './utils';
import { filterArrayByMatchingKey, updateKey, insertAtIndex } from 'utils/stateMap';
import { reduceGridItemVerticalOffsetPropagation } from './moveGridItems';
import { colWidth } from 'styles/constants';

export const setIdleStatus = (gridState: GridState): Pick<GridIdleState, 'draggingStatus'> => {
  if (gridState.draggingStatus === 'IDLE') {
    return gridState;
  }
  return { draggingStatus: 'IDLE' };
};

export const setDraggingStatus =
  (draggingGridItemId: string) =>
  (gridState: GridState): Pick<GridDraggingState, 'draggingStatus' | 'draggingGridItemId'> => {
    if (gridState.draggingStatus === 'DRAGGING') {
      return gridState;
    }
    return { draggingStatus: 'DRAGGING', draggingGridItemId };
  };

function isDragging(gridState: GridState): gridState is GridDraggingState {
  return gridState.draggingStatus === 'DRAGGING';
}

export const dragGridItem =
  (position: GridItemPosition) =>
  (gridState: GridState): Pick<GridDraggingState, 'draggingGridLayout'> | null => {
    if (!isDragging(gridState)) {
      return null;
    }
    const draggingGridLayout = {
      gridItemSizes: gridState.gridLayout.gridItemSizes,
      ...move(gridState.draggingGridItemId, gridState.gridLayout, position),
    };

    return { draggingGridLayout };
  };

function move(gridItemId: string, layout: GridLayout, dragPosition: GridItemPosition) {
  // correct position
  const position: GridItemPosition = {
    top: dragPosition.top,
    left: Math.floor(dragPosition.left / colWidth) * colWidth,
  };
  // get size
  const size = getSizeById(layout, gridItemId);
  // get original coordinates
  const originalPosition = getPositionById(layout, gridItemId);
  // get origin downward neighbours
  const downwardNeighbours = getDownwardNeighbourGridItem(layout, gridItemId);

  // remove dragged item from columns
  const originColumns = getOccupiedColumnsIndicesByCoordinates(originalPosition.left, size.width);
  const filterOrigin = filterArrayByMatchingKey<Locus, 'gridItemId'>('gridItemId', gridItemId);
  let columns = layout.columns.map((column, index) =>
    originColumns.includes(index) ? filterOrigin(column) : column,
  );

  // update dragged item position
  const gridItemPositions = updateKey(layout.gridItemPositions, gridItemId, () => ({
    ...position,
  }));

  // force insertion to new position
  const destinationColumns = getOccupiedColumnsIndicesByCoordinates(position.left, size.width);
  // if item is dragged to the right of the workspace, add columns to receive loci
  destinationColumns.forEach(colIndex => {
    while (columns.length <= colIndex) {
      columns.push([]);
    }
  });
  const newLocus: Locus = {
    gridItemId,
    top: position.top,
    bottom: position.top + size.height,
  };
  const indexFinder = findForceInsertionIndexInColumn(position.top);
  const insertDestination = (column: Column): Column => {
    const insertionIndex = indexFinder(column);
    return insertAtIndex([newLocus], insertionIndex)(column);
  };
  columns = columns.map((column, index) =>
    destinationColumns.includes(index) ? insertDestination(column) : column,
  );

  const newLayout: GridLayout = {
    ...layout,
    columns,
    gridItemPositions,
  };

  // get new downward neighbours
  const destinationDownwardNeighbours = getDownwardNeighbourGridItem(newLayout, gridItemId);

  // push every destination loci downward

  return reduceGridItemVerticalOffsetPropagation(newLayout, [
    newLocus,
    ...downwardNeighbours,
    ...destinationDownwardNeighbours,
  ]);

  // return layout;
}

export const submitDraggingLayout = (gridState: GridState): GridState | null => {
  if (isDragging(gridState)) {
    return {
      draggingStatus: 'IDLE',
      gridLayout: gridState.draggingGridLayout,
      addTilePositions: [],
    };
  }
  return null;
};
