import { combineReducers } from 'redux';
import type { BlotterState } from '../blotterModel';
import { intradayReducer } from './intradayReducer';
import { historicalTradesReducer } from './historicalTradesReducer';
import { metadataReducer } from './metadataReducer';
import { editedOrdersReducer } from './editedOrdersReducer';

export const blotterReducer = combineReducers<BlotterState>({
  intraday: intradayReducer,
  historical: historicalTradesReducer,
  metadata: metadataReducer,
  editedOrders: editedOrdersReducer,
});
