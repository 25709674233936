import { Component, type ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import type { AppState } from 'state/model';
import { isNoClients, isNoProducts } from 'state/globalError/globalErrorSelectors';
import { sgwtConnect } from 'api/connect';
import { HelpButton } from './HelpButton';

const email = 'sgmarketsfx@sgcib.com';

export type ErrorType = 'NO_CLIENTS' | 'NO_PRODUCTS';

function accessRequestError(errorType: ErrorType) {
  switch (errorType) {
    case 'NO_CLIENTS':
      return 'client set-up error';
    case 'NO_PRODUCTS':
      return 'product set-up error';
  }
}

interface IRequestAccessProps {
  accessError: ErrorType | undefined;
}

interface IRequestAccessState {
  userMail: string;
}

interface IMessages {
  details: string;
  title: string;
  reason: string;
  assistance: string;
  submitButton: string;
}

class RequestAccessModalRaw extends Component<IRequestAccessProps, IRequestAccessState> {
  constructor(props: IRequestAccessProps) {
    super(props);
    this.state = {
      userMail: sgwtConnect.getIdTokenClaims()!.sub,
    };
  }

  private onMailChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.setState({ userMail: event.currentTarget.value });

  private details = () =>
    this.msg.details + '\n' + 'Email provided by user: ' + this.state.userMail;

  private get msg() {
    return messages[this.props.accessError!];
  }

  public render() {
    return this.props.accessError ? (
      <Modal isOpen={this.props.accessError !== undefined} backdrop="static">
        <ModalHeader tag="h4">
          <FormattedMessage id={this.msg.title} />
        </ModalHeader>
        <ModalBody
          data-e2e="modalDeniedReason"
          data-e2e-reason={accessRequestError(this.props.accessError)}
        >
          <FormattedMessage tagName="p" id={this.msg.reason} />
          <FormattedMessage
            tagName="p"
            id={this.msg.assistance}
            values={{ mailLink: <a href={`mailto:${email}`}>{email}</a> }}
          />
          <input
            type="text"
            className="form-control input-lg w-100"
            value={this.state.userMail}
            onChange={this.onMailChange}
          />
        </ModalBody>
        <ModalFooter>
          <HelpButton config="access" labelId={this.msg.submitButton} details={this.details} />
        </ModalFooter>
      </Modal>
    ) : (
      <></>
    );
  }
}

const noClientsMessages: IMessages = {
  details: 'No client displayed for the user',
  title: 'modals.noclients.title',
  reason: 'modals.noclients.body.reason',
  assistance: 'modals.noclients.body.assistance',
  submitButton: 'modals.noclients.footer.submitButton',
};

const noProductsMessages: IMessages = {
  details: 'No products displayed for the user',
  title: 'modals.noproducts.title',
  reason: 'modals.noproducts.body.reason',
  assistance: 'modals.noproducts.body.assistance',
  submitButton: 'modals.noproducts.footer.submitButton',
};

const messages: { [no in ErrorType]: IMessages } = {
  NO_CLIENTS: noClientsMessages,
  NO_PRODUCTS: noProductsMessages,
};

const mapStateToProps = (state: AppState): IRequestAccessProps => {
  if (isNoClients(state)) {
    return { accessError: 'NO_CLIENTS' };
  }

  if (isNoProducts(state)) {
    return { accessError: 'NO_PRODUCTS' };
  }

  return { accessError: undefined };
};

export const RequestAccessModal = connect(mapStateToProps)(RequestAccessModalRaw);

export default RequestAccessModal;
