import type { Locus, LocusCoordinates, GridLayout } from '../gridLayoutModels';
import {
  updateKey,
  updateArrayByMatchingKey,
  type Patcher,
  insertAtIndex,
  filterArrayByMatchingKey,
} from 'utils/stateMap';
import {
  getPositionById,
  getDownwardNeighbourGridItem,
  getUpwardNeighbourGridItem,
  getRightNeighbourGridItemCoordinates,
  getLociById,
  findLocusInsertionIndexInColumn,
} from './utils';
import { colWidth } from 'styles/constants';

const updateLocusByPixelOffset =
  (pixel: number): Patcher<Locus> =>
  ({ top, bottom }) => ({
    top: top + pixel,
    bottom: bottom + pixel,
  });

export const shiftRight =
  (lociCoordinates: readonly LocusCoordinates[] | undefined) =>
  (gridState: GridLayout): GridLayout => {
    if (lociCoordinates === undefined) {
      return {
        ...gridState,
        columns: gridState.columns.concat([[]]),
      };
    }
    return lociCoordinates.reduce(
      (previousGridState, locusCoordinate) =>
        shiftRightOnceGridItemHorizontalPosition(
          previousGridState,
          locusCoordinate.locus.gridItemId,
        ),
      gridState,
    );
  };

function shiftRightOnceGridItemHorizontalPosition(
  gridState: GridLayout,
  gridItemId: string,
): GridLayout {
  // in case we move a multi locus grid item (several col wide),
  // we only shift the leftmost locus to the right
  // . a b c . . => . . b c a .
  const locusToMove = getLociById(gridState, gridItemId)[0];

  // get all neighbours to push  one col to the right
  const { neighbours, colIndex } = getRightNeighbourGridItemCoordinates(gridState, gridItemId);
  // RECURSION
  // if there are neighbours to push, let them handle recursive propagation
  // before the current state is modified
  const recursivelyObtainedNewState = shiftRight(neighbours)(gridState);
  // !RECURSION

  // DELETION UPDATER
  // update columns by deleting the leftmost locus
  const columnWithDeletedLocusUpdater = filterArrayByMatchingKey<Locus, 'gridItemId'>(
    'gridItemId',
    gridItemId,
  );
  // !DELETION UPDATER

  // INSERTION UPDATER
  // if we have neighbour, we should take their place
  // else we need to search for a spot on the next column
  const insertionLocusIndex =
    neighbours === undefined || neighbours[0] === undefined
      ? findLocusInsertionIndexInColumn(
          locusToMove.locus.top,
          locusToMove.locus.bottom,
        )(recursivelyObtainedNewState.columns[colIndex])
      : neighbours[0].locusIndex;

  // update columns by inserting the leftmost locus
  // at the correct position in the next colum
  const columnWithInsertedLocusUpdater = insertAtIndex([locusToMove.locus], insertionLocusIndex);
  // !INSERTION UPDATER

  // APPLY COLUMNS UPDATER
  const updatedColumns = recursivelyObtainedNewState.columns.map((column, colNumber) => {
    switch (colNumber) {
      case locusToMove.colIndex:
        return columnWithDeletedLocusUpdater(column);
      case colIndex:
        return columnWithInsertedLocusUpdater(column);
      default:
        return column;
    }
  });
  // !APPLY COLUMNS UPDATER

  // UPDATE POSITION
  const newPositions = updateKey(
    recursivelyObtainedNewState.gridItemPositions,
    gridItemId,
    ({ left }) => ({ left: left + colWidth }),
  );
  // !UPDATE POSITION

  return {
    ...gridState,
    columns: updatedColumns,
    gridItemPositions: newPositions,
  };
}

export function reduceGridItemVerticalOffsetPropagation(
  gridLayout: GridLayout,
  loci: ReadonlyArray<Pick<Locus, 'gridItemId'>>,
): Pick<GridLayout, 'gridItemPositions' | 'columns'> {
  return loci.reduce<GridLayout>(
    (previousGridLayout, { gridItemId }) => ({
      ...previousGridLayout,
      ...updateGridItemVerticalPosition(previousGridLayout, gridItemId),
    }),
    gridLayout,
  );
}

export function updateGridItemVerticalPosition(
  gridState: GridLayout,
  gridItemId: string,
): Pick<GridLayout, 'gridItemPositions' | 'columns'> {
  // compare old position and uppest possible new position
  const oldTop = getPositionById(gridState, gridItemId).top;
  const newTop = Math.max(
    ...getUpwardNeighbourGridItem(gridState, gridItemId).map(({ bottom }) => bottom),
  );
  const deltaPixel = newTop - oldTop;
  // if position wouldn't change, stop propagation of action
  if (deltaPixel === 0) {
    return gridState;
  }

  // update item in columns and positions
  const newGridState = {
    ...gridState,
    columns: gridState.columns.map(
      updateArrayByMatchingKey('gridItemId', gridItemId, updateLocusByPixelOffset(deltaPixel)),
    ),
    gridItemPositions: updateKey(gridState.gridItemPositions, gridItemId, () => ({ top: newTop })),
  };

  // propagate reduction to downward neighbours
  return reduceGridItemVerticalOffsetPropagation(
    newGridState,
    getDownwardNeighbourGridItem(gridState, gridItemId),
  );
}
