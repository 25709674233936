import type { SettlementType } from 'state/fxOptions/fxOptionsModel';
import type { TradeCaptureOptionLegSettlementType } from 'api/tradeCapture/option/tradeCaptureOptionModel';

const mapLegSettlementTypeToTradeCaptureSettlementType: Record<
  SettlementType,
  TradeCaptureOptionLegSettlementType | undefined
> = {
  Cash: 'Cash',
  Physical: 'Physical',
  NDF: 'NDF',
  Forward: undefined,
};

export function convertLegSettlementTypeToTradeCaptureSettlementType(
  type: SettlementType,
): TradeCaptureOptionLegSettlementType | undefined {
  return mapLegSettlementTypeToTradeCaptureSettlementType[type];
}

const mapTradeCaptureSettlementTypeToLegSettlementType: Record<
  TradeCaptureOptionLegSettlementType,
  SettlementType
> = {
  Cash: 'Cash',
  Physical: 'Physical',
  NDF: 'NDF',
};

export function convertTradeCaptureSettlementTypeToLegSettlementType(
  type: TradeCaptureOptionLegSettlementType,
): SettlementType {
  return mapTradeCaptureSettlementTypeToLegSettlementType[type];
}
