import type { Reducer } from 'redux';
import type { Mifid2PanelActions } from '../mifidPanelActions';

export const mifid2ToggleReducer: Reducer<boolean, Mifid2PanelActions> = (
  state = false,
  action,
) => {
  switch (action.type) {
    case 'TOGGLE_MIFID2_PANEL':
      return action.open ?? !state;
    default:
      return state;
  }
};
