import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { HedgedInstrument } from 'state/referenceData/referenceDataModel';
import type { HedgeValueKeys } from 'state/share/productModel';
import type { AlertLevel, ValidationResult } from 'components/Form/Inputs/typings';

export interface HedgeFieldTooltipConnectOwnProps<I extends HedgedInstrument> {
  instrument: I;
  field: HedgeValueKeys<I>;
  quoteId: string;
  hedgeId: string;
}

interface HedgeFieldNoTooltipConnectStateProps {
  alertLevel: undefined;
}

export interface HedgeFieldTooltipConnectStateProps {
  alertLevel: AlertLevel;
  messageId: string;
  seen: boolean;
}

export interface HedgeFieldTooltipConnectDispatchProps {
  setValidationState(result: ValidationResult): void;
  onSeen(): void;
}

export type HedgeFieldTooltipConnectSelectorsKeys = 'getHedgeFieldValidationState';
export type HedgeFieldTooltipConnectSelectors = Pick<
  Selectors,
  HedgeFieldTooltipConnectSelectorsKeys
>;

export const mapStateToPropsHedgeFieldTooltip =
  (sl: HedgeFieldTooltipConnectSelectors) =>
  <I extends HedgedInstrument>(
    state: AppState,
    { instrument, field, quoteId, hedgeId }: HedgeFieldTooltipConnectOwnProps<I>,
  ): HedgeFieldNoTooltipConnectStateProps | HedgeFieldTooltipConnectStateProps =>
    sl.getHedgeFieldValidationState(state, instrument, field, quoteId, hedgeId);

export type HedgeFieldTooltipConnectActionCreatorsKeys =
  | 'hedgeFieldTooltipSeen'
  | 'localHedgeFieldValidationSet'
  | 'localHedgeFieldValidationClear';

export type HedgeFieldTooltipConnectActionCreators = Pick<
  ActionCreators,
  HedgeFieldTooltipConnectActionCreatorsKeys
>;

export const mapDispatchToPropsHedgeFieldTooltip: MapDispatchToPropsHOF<
  HedgeFieldTooltipConnectDispatchProps,
  HedgeFieldTooltipConnectOwnProps<HedgedInstrument>,
  HedgeFieldTooltipConnectActionCreators
> =
  ac =>
  (dispatch, { instrument, field, quoteId, hedgeId }): HedgeFieldTooltipConnectDispatchProps => ({
    onSeen: () => dispatch(ac.hedgeFieldTooltipSeen(instrument, field, quoteId, hedgeId)),
    setValidationState(result) {
      dispatch(
        result.status === 'valid'
          ? ac.localHedgeFieldValidationClear(instrument, quoteId, hedgeId, field)
          : ac.localHedgeFieldValidationSet(
              instrument,
              quoteId,
              hedgeId,
              field,
              result.status,
              result.messageId,
            ),
      );
    },
  });
