import { connect } from 'react-redux';
import { selectors } from 'state/selectors';
import { actionCreators } from 'state/actions';
import { mapDispatchToPropsAddTabButton } from './connectAddTabButton';
import { mapStateToPropsLockButton, mapDispatchToPropsLockButton } from './connectLockButton';
import { mapDispatchToPropsUserPrefButton } from './connectUserPrefButton';
import {
  mapStateToPropsSaveWorkspaceButton,
  mapDispatchToPropsSaveWorkspaceButton,
} from './connectSaveWorkspaceButton';
import { mapDispatchToPropsMifidPanelButton } from './connectMifidPanelButton';
import { mapStateToPropsTabsMenu } from './connectTabsMenu';
import { mapDispatchToPropsTabMenuItem } from './connectTabMenuItem';
export const connectAddTabButton = connect(null, mapDispatchToPropsAddTabButton(actionCreators));

export const connectLockButton = connect(
  mapStateToPropsLockButton(selectors),
  mapDispatchToPropsLockButton(actionCreators),
);

export const connectUserPrefButton = connect(
  null,
  mapDispatchToPropsUserPrefButton(actionCreators),
);

export const connectMifidPanelButton = connect(
  null,
  mapDispatchToPropsMifidPanelButton(actionCreators),
);

export const connectSaveWorkspaceButton = connect(
  mapStateToPropsSaveWorkspaceButton(selectors),
  mapDispatchToPropsSaveWorkspaceButton(actionCreators),
);

export const connectTabsMenu = connect(mapStateToPropsTabsMenu(selectors));
export const connectTabMenuItem = connect(null, mapDispatchToPropsTabMenuItem(actionCreators));
