export type SmartRfsAction = CreateSmartRfsRequestedEpic | CreateSmartRfsDone;

export interface CreateSmartRfsRequestedEpic {
  type: 'CREATE_SMART_RFS_REQUESTED_EPIC';
  productStringRepresentation: string;
  sessionId: string;
  tileId: string;
}

export function createSmartRfsRequestedEpic(
  productStringRepresentation: string,
  sessionId: string,
  tileId: string,
): CreateSmartRfsRequestedEpic {
  return { type: 'CREATE_SMART_RFS_REQUESTED_EPIC', productStringRepresentation, sessionId, tileId };
}

export interface CreateSmartRfsDone {
  type: 'CREATE_SMART_RFS_DONE';
  tileId: string;
  success: boolean;
  failureReason?: string;
}

export function createSmartRfsDone(success: boolean, tileId: string, failureReason?: string): CreateSmartRfsDone {
  return { type: 'CREATE_SMART_RFS_DONE', success, failureReason, tileId };
}
