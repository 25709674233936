import { useCallback } from 'react';
import { BareNumberInput } from 'components/Form/Inputs/NumberInput';
import type { UserPrefConnectProps } from './connect/connectUserPref';
import { connectUserPref } from './connect';
import { coerceDefaultNotional } from './coerceDefaultNotional';
import { noop } from '@sgme/fp';
import { UserPrefLabel } from './UserPrefLabel';

const DefaultNotionalInput: React.FunctionComponent<UserPrefConnectProps<'defaultNotional'>> = ({
  userPrefValue: defaultNotional,
  onChangeUserPref,
}) => {
  const onDefaultNotional = useCallback(
    (value: string) => {
      onChangeUserPref(coerceDefaultNotional(value));
    },
    [onChangeUserPref],
  );
  return (
    <UserPrefLabel label="defaultNotional">
      <BareNumberInput
        e2eHandle="defaultNotional"
        value={defaultNotional!}
        onSubmit={onDefaultNotional}
        onBlurPristine={noop}
        inputClassName="w-auto"
      />
    </UserPrefLabel>
  );
};

export const DefaultNotionalPref = connectUserPref('defaultNotional')(DefaultNotionalInput);
