import { combineReducers } from 'redux';
import type { FxOrdersState } from '../fxOrdersModel';
import { ordersReducer } from './fxOrdersProductReducer';
import { orderEmailsReducer } from './fxOrdersEmailsReducer';
import { orderTemplatesReducer } from './fxOrderTemplatesReducer';

export const fxOrdersReducer = combineReducers<FxOrdersState>({
  orders: ordersReducer,
  defaultEmailsByBdrId: orderEmailsReducer,
  templates: orderTemplatesReducer,
});
