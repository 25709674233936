import { combineReducers } from 'redux';
import type { FxOptionsState } from '../model';
import { optionsProductReducer } from './optionsProductReducer';
import { optionsStreamsReducer } from './optionsStreamsReducer';
import { optionsStrippingsReducer } from './optionStrippingsReducer';
import { optionsLegsReducer } from './optionsLegsReducer';
import { optionsHedgesReducer } from './optionsHedgesReducer';
import { optionsTemplatesReducer } from './optionsTemplatesReducer';

export const fxOptionsReducer = combineReducers<FxOptionsState>({
  options: optionsProductReducer,
  legs: optionsLegsReducer,
  hedges: optionsHedgesReducer,
  streams: optionsStreamsReducer,
  strippings: optionsStrippingsReducer,
  templates: optionsTemplatesReducer,
});
