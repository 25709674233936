import { identity } from '@sgme/fp';
import type { BidAsk, Way } from './litterals';

export function getRelevantPriceFromBidAsk<T>(pair: BidAsk<T>, way: Way): T {
  if (way === 'Ask') {
    return pair.ask;
  }
  if (way === 'Bid') {
    return pair.bid;
  }
  throw `way ${way} is not supported`;
}

type BidAskTransformer<T> = (bidAsk: BidAsk<T>) => BidAsk<T>;

export const mapBidAsk = <T>(transform: (v: T) => T): BidAskTransformer<T> => {
  if (transform === identity) {
    return identity;
  }
  return ({ bid, ask }) => ({
    bid: transform(bid),
    ask: transform(ask),
  });
};
