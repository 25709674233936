import type { AccumulatorTradeCapturePatch } from 'epics/metaSelectors/accumulator/tradeCaptureFromBackend';
import type { Thunk } from 'state';

export function accumulatorPropertiesReceivedThunk(quoteId: string, patch: AccumulatorTradeCapturePatch): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (
      !sl.isTilePresent(state, quoteId) ||
      sl.getQuoteInstrument(state, quoteId) !== 'Accumulator' // todo refacto
    ) {
      return;
    }

    const {
      idVersion,
      isReadyToPrice,
      isPriceObsolete,
      isProductObsolete,
      summary,
      schedule,
      numberOfSettlements,
      ...receivedPatch
    } = patch;

    // If we have a stream and product is obsolete, we clear it so that the user doesn't see the expired price while waiting for new price
    const currentStreamId = sl.getAccumulatorRfsStreamId(state, quoteId);
    if (currentStreamId !== null && isProductObsolete) {
      dispatch(ac.accumulatorRfsCanceled(quoteId, currentStreamId, false));
    }

    // If we have an expired stream and the price is obsolete, we clear it because the price needs to be updated after the properties change
    const lastStreamId = sl.getAccumulatorLastStreamId(state, quoteId);
    if (lastStreamId !== null && isPriceObsolete) {
      dispatch(ac.accumulatorRfsLastRemoved(quoteId, lastStreamId));
    }

    dispatch(
      ac.accumulatorPropertiesReceived(
        quoteId,
        receivedPatch,
        idVersion,
        isReadyToPrice,
        isPriceObsolete,
        isProductObsolete,
        summary,
        numberOfSettlements,
        schedule,
      ),
    );

    const currencyPair = receivedPatch.values.currencyPair;
    if (currencyPair !== undefined && currencyPair !== sl.getAccumulatorCurrencyPairInput(state, quoteId).value) {
      dispatch(ac.espStreamRestartThunk(quoteId, currencyPair, undefined, undefined));
    }
  };
}

export function accumulatorScheduleAddFixingThunk(quoteId: string): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac, getNewGuid }) => {
    dispatch(ac.accumulatorScheduleLegAdded(quoteId, getNewGuid()));
  };
}
