import type { Thunk } from 'state';
import { fieldData } from 'utils/fieldSelectors';
import { logger } from 'logging/logger';

export function swapRfsRequestedThunk(swapId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (sl.isTilePresent(state, swapId) && sl.getQuoteInstrument(state, swapId) !== 'Swap') {
      // https://itbox-jira.fr.world.socgen/jira/browse/SGEFX-2119: APP_CRASHED due to a getSwapCurrentStreamId
      logger.logWarning(
        `swapRfsRequestedThunk: tile does not exist or is no more a swap: {swapId_s}`,
        swapId,
      );
      return;
    }
    const currentStreamId = sl.getSwapCurrentStreamId(state, swapId);
    dispatch(ac.swapRfsRequestedEpic(swapId, currentStreamId));
  };
}

export function swapRfsCancelThunk(swapId: string, switchingInstrument = false): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (sl.isTilePresent(state, swapId) && sl.getQuoteInstrument(state, swapId) !== 'Swap') {
      // https://itbox-jira.fr.world.socgen/jira/browse/SGEFX-2119: APP_CRASHED due to a getSwapCurrentStreamId
      logger.logWarning(
        `swapRfsCancelThunk: tile does not exist or is no more a swap: {swapId_s}`,
        swapId,
      );
      return;
    }
    const currentStreamId = sl.getSwapCurrentStreamId(state, swapId);
    if (currentStreamId !== null) {
      const { data: isOffMarket } = fieldData(sl.getSwapIsOffMarket(state, swapId));
      dispatch(ac.swapRfsCancel(swapId, currentStreamId));
      dispatch(ac.swapRfsCancelEpic(swapId, currentStreamId));
      if (!switchingInstrument && isOffMarket === false) {
        dispatch(ac.swapPropertyChanged(swapId, { nearPriceReference: null }));
      }
    }
    dispatch(ac.swapRfsClearError(swapId));
  };
}
