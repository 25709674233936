import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

export interface MifidPanelButtonConnectOwnProps {}

export interface MifidPanelButtonConnectDispatchProps {
  toggleMifidPanel(): void;
}

// you can whitelist action creators after implementation
export type MifidPanelButtonConnectActionCreators = ActionCreators;
/*
export type MifidPanelButtonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type MifidPanelButtonConnectActionCreators = Pick<ActionCreators, MifidPanelButtonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsMifidPanelButton: MapDispatchToPropsHOF<
  MifidPanelButtonConnectDispatchProps,
  MifidPanelButtonConnectOwnProps,
  MifidPanelButtonConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  toggleMifidPanel() {
    dispatch(ac.toggleMifid2Panel());
  },
});
