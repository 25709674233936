import type { AppState } from 'state/model';
import type { FxExecutionState } from './executionsModel';
import type { IFxExecutionFillReportState, IFxExecutionState } from './executionsStateModel';
import { throwIfUndefined } from 'utils/maps';
import { isDefined } from '@sgme/fp';

export function hasExecution(state: AppState, executionId: string | undefined | null): boolean {
  return isDefined(executionId) && state.executions[executionId] !== undefined;
}

export function getExecutionById(state: AppState, executionId: string): FxExecutionState {
  return throwIfUndefined(
    state.executions[executionId],
    `Execution state ${executionId} does not exist`,
  );
}

export function getExecutionIdOfTradeId(state: AppState, tradeId: string): string | undefined {
  const trades = 'tradeIds';
  const executionIds = Object.keys(state.executions);
  return executionIds.find(executionId => {
    const execution = state.executions[executionId] as any;
    return execution[trades]?.includes(tradeId);
  });
}

export function hasTradeId(execution: IFxExecutionState): execution is IFxExecutionFillReportState {
  return execution.status === 'BackOfficeValidated';
}
