import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import { getDateForMultipass } from 'utils/dateFormats';
import { assertIsDefined, isEmpty } from '@sgme/fp';
import {
  type ForexOptionExecuteRequestFrontDto,
  ForexOptionExecuteRequestFrontDtoWay,
} from 'api/multipass/accumulator/model';

export const buildAccuQuoteExecuteWith: MapStateToMetadataHOF<
  ForexOptionExecuteRequestFrontDto,
  { rfsId: string; tradeDate: Date },
  AppState,
  Selectors
> =
  sl =>
  (state, { rfsId, tradeDate }) => {
    const replyToStream = sl.getConnectionId(state);

    const { emails, splitNotificationsEmailsOptions, splitNotifications } =
      sl.getUserPreferenceData(state);
    let emailNotificationList;
    if (splitNotifications && !isEmpty(splitNotificationsEmailsOptions)) {
      emailNotificationList = splitNotificationsEmailsOptions.join(';');
    } else {
      emailNotificationList = emails.join(';');
    }
    const rfsQuoteId = sl.getAccumulatorRfsQuoteId(state, rfsId);
    assertIsDefined(rfsQuoteId, 'rfsQuoteId needs to be defined for execution');
    const userLocalDateTime = getDateForMultipass(tradeDate);

    const streamState = sl.getAccumulatorRfsState(state, rfsId);

    if (streamState.status !== 'PRICING') {
      throw 'Assertion error : executing a accumulator stream that is not pricing';
    }
    const lastQuoteFromBackTimestamp = streamState.quote.backTimestamp;
    const lastQuoteReceivedByFrontTimestamp = streamState.quote.frontTimestamp.getTime();
    const executionClickByFrontTimestamp = tradeDate.getTime();

    const result: ForexOptionExecuteRequestFrontDto = {
      emailNotificationList,
      quoteId: rfsQuoteId,
      replyToStream,
      rfsId,
      userLocalDateTime,
      way: ForexOptionExecuteRequestFrontDtoWay.Ask,
      lastQuoteFromBackTimestamp,
      lastQuoteReceivedByFrontTimestamp,
      executionClickByFrontTimestamp,
    };
    return result;
  };
