import type { CollapsableProps } from './typings';
import { noop } from '@sgme/fp';
import { collapsable } from 'components/Form/Inputs/e2e';
import { useToggle } from 'components/share/hooks/useToggle';
import styled from "styled-components";

export const CollapsableComponent: React.ComponentType<CollapsableProps> = ({
  isCollapsed,
  toggleCollapsed,
  disabled = false,
  title,
  children,
  e2eHandle,
}) => (
  <>
    <div className="sgbs-collapse-separator sgbs-collapse-separator-centered">
      <div className="card bg-transparent">
        <div className="card-header p-0">
          <button
            type="button"
            data-nodrag
            data-toggle="collapse"
            data-e2e={collapsable(e2eHandle)}
            onClick={disabled ? noop : toggleCollapsed}
            className={`btn btn-flat-primary btn-sm btn-block justify-content-between text-nowrap p-0${
              isCollapsed ? " collapsed" : ""
            }${disabled ? " cursor-default" : ""}`}
            disabled={disabled}
          >
            <Separator/>
            <span className="mx-8px">{title}</span>
            {disabled === false && <i className="icon icon-sm">keyboard_arrow_down</i>}
            <Separator/>
          </button>
        </div>
      </div>
    </div>

    {isCollapsed === true || disabled === true ? null : children}
  </>
);

const Separator = styled.span`
  flex: 1 1 auto;
  content: "";
  height: 2px;
  border-top: 1px solid var(--bs-light);
`;

export const UncontrolledCollapsable: React.FunctionComponent<
  Omit<CollapsableProps, 'isCollapsed' | 'toggleCollapsed'> & {
    collapsed?: boolean;
  }
> = ({ collapsed, ...props }) => {
  const [isCollapsed, toggleCollapsed] = useToggle(collapsed);
  return (
    <CollapsableComponent isCollapsed={isCollapsed} toggleCollapsed={toggleCollapsed} {...props} />
  );
};
