import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import {
  isVanilla,
  type PremiumType,
  type TcHedgeType,
  type TCOptionHedgeValues,
  type TCOptionLegValues,
  type TradeCaptureOptionChanges,
  type TradeCaptureOptionLegPreviousValues,
  type TradeCaptureOptionLegSettlementType,
  type TradeCaptureOptionPreviousValues,
  type TradeCaptureOptionResponseWrapper,
} from 'api/tradeCapture/option/tradeCaptureOptionModel';
import type { ErrorLegs, TcErrors, TcWarnings, WarningLegs } from 'api/tradeCapture/tradeCaptureModel';
import type { DisplayPriceType, PriceType } from 'state/fxOptions/fxOptionsModel';
import type {
  CurrencyChoice,
  HedgeType,
  Possible,
  PropertyError,
  PropertyErrors,
  PropertyWarning,
  PropertyWarnings,
} from 'state/share/productModel/litterals';
import { FIRST_CURRENCY, SECOND_CURRENCY } from 'state/share/productModel/litterals';
import { clearUndefined } from 'utils/clearUndefined';
import {
  fromTcError,
  fromTcErrorAsString,
  fromTcWarning,
  fromTcWarningAsString,
  previousValue,
  previousValueAsString,
} from 'api/tradeCapture/tradeCaptureMappingHelper';
import { convertTradeCaptureSettlementTypeToLegSettlementType } from './utils/settlementTypesMap';
import { convertTradeCaptureTypeToLegOptionType } from './utils/optionTypesMap';
import { mapOptional } from 'utils/optional';
import type { IPatch } from 'state/share/patchModels';
import type { FxOptionLegsPatchedValuesRecords, IFxVanillaLegValues, LegType } from 'state/fxOptions/model/optionsLegs';
import type { IFxOptionValues } from 'state/fxOptions/model/optionProduct';
import type { FxOptionPatchWithTradeCaptureMetaData } from 'state/fxOptions/actions/optionProduct';
import { isDefined } from '@sgme/fp';
import {
  type FxOptionHedgesPatchedValuesRecords,
  type IFxHedgeValues,
} from '../../../state/fxOptions/model/optionHedges';

type TradeCaptureFromBackendMetaSelectorsKeys = 'getOptionVanillaLegNotionalCurrency' | 'getFeatureToggles';

export type TradeCaptureFromBackendMetaSelectorSelectors = Pick<Selectors, TradeCaptureFromBackendMetaSelectorsKeys>;

interface WithOptionId {
  optionId: string;
}

// @todo SGEFX-4285 Rework legIds
interface TCOptionLegValuesWithoutLegsRecord {
  [key: string]: Omit<TCOptionLegValues & { legIds?: string[] }, 'legs'>;
}

interface PartialTCOptionLegValuesWithoutLegsRecord {
  [key: string]: Partial<Omit<TCOptionLegValues, 'legs'> & { legIds?: string[] }>;
}

// ---------------------------------------------------------------------------------
// Public meta selector
// ---------------------------------------------------------------------------------

const orderChildrenLegs = (
  childrenLegs: { [p: string]: Partial<TCOptionLegValues> },
  newKey: string,
  optionId: string,
) => {
  const childrenLegsArePutOrCall = Object.keys(childrenLegs).some(key => key.includes('call') || key.includes('put'));

  if (childrenLegsArePutOrCall) {
    // we need to always send back call before put
    return [`${optionId}/${newKey}/call`, `${optionId}/${newKey}/put`];
  }

  return Object.keys(childrenLegs).map(childrenKey => `${optionId}/${newKey}/${childrenKey}`);
};

/**
 * Flattens the nested legs from TC in an object with keys for each legs corresponding to their nested path
 * @param allLegs {{[p: string]: Partial<TCOptionLegValues>}} object with nested legs from TC
 * @param optionId {string} recursive path to keep track of the parent ID
 * @param legsValuesWithLegIds {{[p: string]: Partial<TCOptionLegValues>}} recursive object use through the reducer to add all the legs
 * @param parentPath {string} recursive path to keep track of the parent ID
 * @returns {{[key: string]: Partial<Omit<TCOptionLegValues, "legs">>}}The original or cloned/updated object
 */
const flattenNestedLegsValuesWithLegIds = (
  allLegs: { [p: string]: Partial<TCOptionLegValues> },
  optionId: string,
  legsValuesWithLegIds: { [key: string]: Partial<TCOptionLegValues & { legIds?: string[] }> } = {},
  parentPath = '',
): PartialTCOptionLegValuesWithoutLegsRecord => {
  for (const [key, value] of Object.entries(allLegs)) {
    const { legs, ...otherProperties } = value;
    const newKey = parentPath ? `${parentPath}/${key}` : key;

    legsValuesWithLegIds[newKey] = otherProperties;

    if (legs) {
      legsValuesWithLegIds[newKey].legIds = orderChildrenLegs(legs, newKey, optionId);
      flattenNestedLegsValuesWithLegIds(legs, optionId, legsValuesWithLegIds, newKey);
    }
  }

  return legsValuesWithLegIds;
};

type TypeOfWarnings = TcWarnings<TradeCaptureOptionLegPreviousValues> &
  WarningLegs<TradeCaptureOptionLegPreviousValues>;

type TypeOfErrors = TcErrors<TradeCaptureOptionLegPreviousValues> & ErrorLegs<TradeCaptureOptionLegPreviousValues>;

const flattenNestedLegsErrorsOrWarning = (
  allErrorOrWarningLegs: TypeOfWarnings | TypeOfErrors,
  optionId: string,
  returnObject: TypeOfWarnings | TypeOfErrors = {},
  parentPath = '',
): TypeOfWarnings | TypeOfErrors => {
  for (const [key, value] of Object.entries(allErrorOrWarningLegs)) {
    const { legs, ...otherProperties } = value;
    const newKey = parentPath ? `${parentPath}/${key}` : key;

    // todo SGEFX 4285 tighten types
    // @ts-ignore
    returnObject[newKey] = otherProperties;

    if (legs) {
      flattenNestedLegsErrorsOrWarning(legs, optionId, returnObject, newKey);
    }
  }

  return returnObject;
};

/**
 *
 */
export const flattenLegsValuesWithOptionId = (
  optionId: string,
  tcLegs: PartialTCOptionLegValuesWithoutLegsRecord,
): PartialTCOptionLegValuesWithoutLegsRecord => {
  const values = flattenNestedLegsValuesWithLegIds(tcLegs, optionId) as TypeOfWarnings | TypeOfErrors;

  return appendLegKeysWithOptionId(optionId, values) as PartialTCOptionLegValuesWithoutLegsRecord;
};

export const flattenLegsErrorsOrWarningsWithOptionId = (
  optionId: string,
  tcLegsErrorsOrWarning: TypeOfWarnings | TypeOfErrors,
): TypeOfWarnings | TypeOfErrors =>
  appendLegKeysWithOptionId(optionId, flattenNestedLegsErrorsOrWarning(tcLegsErrorsOrWarning, optionId));

/**
 *
 * @param optionId
 * @param legs
 * @returns
 */
const appendLegKeysWithOptionId = (
  optionId: string,
  legs: TypeOfWarnings | TypeOfErrors,
): TypeOfWarnings | TypeOfErrors =>
  Object.entries(legs).reduce((acc, [key, leg]) => {
    acc[`${optionId}/${key}` as keyof (TypeOfWarnings | TypeOfErrors)] = leg;

    return acc;
  }, {} as TypeOfWarnings | TypeOfErrors);

const getChildrenLegIds = (legs: FxOptionLegsPatchedValuesRecords): string[] =>
  Object.entries(legs)
    .filter(([_, leg]) => leg.productName === 'FxOptionMultileg')
    .map(([key, _]) => key);

const mapPremiumData = (vanillaLeg: IPatch<IFxVanillaLegValues>) => {
  const {
    values: { premiumCurrency, premiumTypeString },
  } = vanillaLeg;

  if (!premiumCurrency || !premiumTypeString) {
    return undefined;
  }

  const displayPriceType: DisplayPriceType = [premiumCurrency, premiumTypeString];

  return { displayPriceType };
};

const computeNotionalAmount = (
  leg: Partial<TCOptionLegValues>,
  legId: string,
  state: AppState,
  sl: TradeCaptureFromBackendMetaSelectorSelectors,
): CurrencyChoice => {
  let notionalCurrency: CurrencyChoice;

  if (leg.negotiatedCurrency === undefined && isVanilla(leg)) {
    const { value: notionalCurrencyState } = sl.getOptionVanillaLegNotionalCurrency(state, legId);
    notionalCurrency = notionalCurrencyState;
  } else {
    notionalCurrency = leg.negotiatedCurrency!;
  }

  return notionalCurrency;
};

const computeIsStrikeInDelta = (leg: Partial<TCOptionLegValues>): boolean | undefined =>
  leg.strikeString === undefined ? undefined : isDefined(leg.strikeInDelta);

interface AllFlattenedLegs {
  legsValues: TCOptionLegValuesWithoutLegsRecord;
  legsErrors: {
    [p: string]: TypeOfErrors;
  };
  legsWarnings: {
    [p: string]: TypeOfWarnings;
  };
}

const addValuesErrorsAndWarningsToLegs = (
  allFlattenedLegs: AllFlattenedLegs,
  state: AppState,
  sl: TradeCaptureFromBackendMetaSelectorSelectors,
): FxOptionLegsPatchedValuesRecords => {
  const flattenedLegsWithValuesErrorsAndWarnings = {} as FxOptionLegsPatchedValuesRecords;
  const { legsValues: allLegsValues, legsErrors, legsWarnings } = allFlattenedLegs;

  Object.entries(allLegsValues).forEach(([legId, legValues]) => {
    const { productName } = legValues;
    const notionalCurrency = computeNotionalAmount(legValues, legId, state, sl);
    // todo SGEFX 4285 should we type each subleg lvl specifically (i.e should deliveryDate go on FxOptionmultileg leg ?

    const errorsForLeg = legsErrors?.[legId] ?? {};
    const warningsForLeg = legsWarnings?.[legId] ?? {};

    flattenedLegsWithValuesErrorsAndWarnings[legId] = {
      productName: productName as LegType,
      ...clearUndefined({ legIds: legValues.legIds }),
      errors: mergeLegErrors(errorsForLeg),
      warnings: mergeLegWarnings(warningsForLeg),
      values: mergeLegValues(legValues, notionalCurrency, errorsForLeg),
    };
  });

  return flattenedLegsWithValuesErrorsAndWarnings;
};

const mergeLegErrors = (errorsForLeg: TypeOfErrors) => ({
  ...clearUndefined({
    expiryDate: fromTcError(errorsForLeg.expiryDate),
    strike: fromTcErrorAsString(errorsForLeg.strike),
    notionalAmount: fromTcError(errorsForLeg.amount1 || errorsForLeg.amount2),
    deliveryDate: fromTcError(errorsForLeg.deliveryDate),
    premiumDate: fromTcError(errorsForLeg.premiumPaymentDate),
    premiumBid: fromTcError(errorsForLeg.premiumPaymentAmountBid),
    premiumAsk: fromTcError(errorsForLeg.premiumPaymentAmountAsk),
    markup: fromTcError(errorsForLeg.markupAmount),
    clientVolAsk: fromTcError(errorsForLeg.clientVolAsk),
    clientVolBid: fromTcError(errorsForLeg.clientVolBid),
  }),
});

const mergeLegWarnings = (warningsForLeg: TypeOfWarnings) => ({
  ...clearUndefined({
    expiryDate: fromTcWarning(warningsForLeg.expiryDate),
    strike: fromTcWarningAsString(warningsForLeg.strike),
    notionalAmount: fromTcWarning(warningsForLeg.amount1 || warningsForLeg.amount2),
    deliveryDate: fromTcWarning(warningsForLeg.deliveryDate),
    premiumDate: fromTcWarning(warningsForLeg.premiumPaymentDate),
    premiumBid: fromTcWarning(warningsForLeg.premiumPaymentAmountBid),
    premiumAsk: fromTcWarning(warningsForLeg.premiumPaymentAmountAsk),
    markup: fromTcWarning(warningsForLeg.markupAmount),
    clientVolAsk: fromTcWarning(warningsForLeg.clientVolAsk),
    clientVolBid: fromTcWarning(warningsForLeg.clientVolBid),
  }),
});

const mergeLegValues = (
  legValues: Omit<
    TCOptionLegValues & {
      legIds?: string[] | undefined;
    },
    'legs'
  >,
  notionalCurrency: CurrencyChoice,
  errorsForLeg: TypeOfErrors,
) => {
  if (legValues.productName !== 'Vanilla') {
    return {};
  }

  return clearUndefined({
    currency1: legValues.currency1,
    currency2: legValues.currency2,
    strikeShortcut: legValues.strikeShortcut,
    cashSettlementCurrency: legValues.cashSettlementCurrency,
    possibleCashSettlementCurrencies: legValues.possibleCashSettlementCurrencies,
    fixingReference1: legValues.fixingReference1,
    possibleFixingReferences1: legValues.possibleFixingReferences1,
    side: legValues.side,
    isReadyToPrice: legValues.isReadyToPrice,
    isInFine: legValues.isInFine,
    solvable: legValues.solvable,
    notionalAmount: computeExpectedValue(
      notionalCurrency === 1 ? legValues.amount1 : legValues.amount2,
      previousValue(errorsForLeg.amount1) ?? previousValue(errorsForLeg.amount2),
    ),
    ...mapPremiumType(legValues.premiumType),
    optionType: optionTypeToModel(legValues.type),
    isStrikeInDelta: computeIsStrikeInDelta(legValues),
    expiryDate:
      legValues.expiryDate === null
        ? null
        : dateToModel(legValues.expiryDate) ?? previousValue(errorsForLeg.expiryDate),
    expiryDateTenor: legValues.expiryDateTenor,
    deliveryDate: dateToModel(legValues.deliveryDate),
    deliveryDateTenor: legValues.deliveryDateTenor,
    volatilityAsk: computeExpectedValue(
      legValues.clientVolAsk,
      previousValue(errorsForLeg.premiumPaymentAmountAsk) ?? previousValue(errorsForLeg.clientVolAsk),
    ),
    volatilityBid: computeExpectedValue(
      legValues.clientVolBid,
      previousValue(errorsForLeg.premiumPaymentAmountBid) ?? previousValue(errorsForLeg.clientVolBid),
    ),
    marketPlace: legValues.cutOffMarketPlace,
    possibleMarketPlaces: legValues.possibleCutOffMarketPlaces,
    premiumBid: legValues.premiumPaymentAmountBid,
    premiumAsk: legValues.premiumPaymentAmountAsk,
    notionalCurrency: legValues.negotiatedCurrency,
    markup: computeExpectedValue(legValues.markupAmount, previousValue(errorsForLeg.markupAmount)),
    premiumDate: computeExpectedValue(
      dateToModel(legValues.premiumPaymentDate),
      previousValue(errorsForLeg.premiumPaymentDate),
    ),
    premiumDateTenor: dateToModel(legValues.premiumPaymentDateTenor),
    strike: computeExpectedValue(
      legValues.strikeString,
      previousValueAsString(errorsForLeg.strike) ?? previousValue(errorsForLeg.strikeInDelta),
    ),
    possibleSettlementTypes: possibleSettlementTypesToModel(legValues.possibleSettlementTypes),
    settlementType: settlementTypeToModel(legValues.settlementType),
  });
};

function computeExpectedValue(value: any, defaultValue: any) {
  return value !== undefined ? value : defaultValue;
}

/**
 *
 */
export const generatePathFromResponseCreator: MapStateToMetadataHOF<
  FxOptionPatchWithTradeCaptureMetaData,
  TradeCaptureOptionResponseWrapper & WithOptionId,
  AppState,
  TradeCaptureFromBackendMetaSelectorSelectors
> =
  sl =>
    (state, { changedFields: tcChangedFields, errors, warnings, idVersion, optionId }) => {
      const { legs: tcLegsErrors, ...tcOptionErrors } = errors ?? { legs: {} };
      const { legs: tcLegsWarnings, ...tcOptionWarnings } = warnings ?? {
        legs: {},
      };

      const [changedFieldWithoutHedges, hedges] = extractHedgeFromChangedFields(tcChangedFields);

      tcChangedFields = changedFieldWithoutHedges;

      const legsErrors = tcLegsErrors;

      const legsWarnings = tcLegsWarnings;

      const flattenedLegsErrors = flattenLegsErrorsOrWarningsWithOptionId(optionId, legsErrors!);
      const flattenedLegsWarnings = flattenLegsErrorsOrWarningsWithOptionId(optionId, legsWarnings!);
      const flattenedLegsValues = flattenLegsValuesWithOptionId(optionId, tcChangedFields.legs);

      // @TODO add metada on flattenLegs
      // legs = addMetadataOnLegs(legs);

      const legsWithValues = addValuesErrorsAndWarningsToLegs(
        {
          legsValues: flattenedLegsValues as TCOptionLegValuesWithoutLegsRecord,
          // @ts-ignore
          legsErrors: flattenedLegsErrors,
          // @ts-ignore
          legsWarnings: flattenedLegsWarnings,
        },
        state,
        sl,
      );

      const {
        values: optionValues,
        errors: optionErrors,
        warnings: optionWarnings,
      } = mergeOptionValuesWithChanges(state, {
        changes: tcChangedFields,
        tcErrors: tcOptionErrors,
        tcWarnings: tcOptionWarnings,
      });

      // @TODO à renomer les 4 functions pour comprendre la logique autour du PremiumDate et ExpiryDate
      const optionGlobalPremiumDateValuesFromLeg = getOptionGlobalPremiumDateChangedFieldsFromFirstLeg(legsWithValues);

      const optionErrorsFromLeg = getOptionErrorsFromLegErrors(legsWithValues);

      const cleanedLegs = removePremiumDateFromLegsError(optionErrorsFromLeg, legsWithValues);

      const optionWarningsFromLeg = getOptionWarningsFromLegWarnings(legsWithValues);

      const isReadyToPrice = tcChangedFields.isReadyToPrice === undefined ? null : tcChangedFields.isReadyToPrice;

      const vanillaLegId = getMostNestedKey(Object.keys(cleanedLegs));
      const vanillaLeg = cleanedLegs[vanillaLegId];

      const newLocal: FxOptionPatchWithTradeCaptureMetaData = {
        option: {
          [optionId]: {
            idVersion,
            errors: clearUndefined({ ...optionErrors, ...optionErrorsFromLeg }),
            warnings: clearUndefined({ ...optionWarnings, ...optionWarningsFromLeg }),
            values: {
              ...optionValues,
              ...clearUndefined(optionGlobalPremiumDateValuesFromLeg),
            },
            isReadyToPrice,
            isPriceObsolete: tcChangedFields.isPriceObsolete || false,
            isProductObsolete: tcChangedFields.isProductObsolete || false,
            ...mapPremiumData(vanillaLeg),
            legIds: getChildrenLegIds(cleanedLegs),
          },
        },
        hedges: getHedgesFromTradeCaptureHedges(optionId, hedges, tcLegsErrors, tcLegsWarnings),
        legs: cleanedLegs,
      };
      return newLocal;
    };

type LegResult = [string, Partial<TCOptionLegValues>];
type HedgeResult = [string, Partial<TCOptionHedgeValues>];

function extractHedgeFromChangedFields(tcChangedFields: TradeCaptureOptionChanges) {
  const multiLeg = tcChangedFields.legs['0'];

  const [legsWithoutHedges, hedges] = Object.entries(multiLeg.legs ?? []).reduce(
    ([legResult, hedgeResult], [legId, leg]) => {
      if (leg.productName === 'FxOptionHedge') {
        hedgeResult.push([legId, leg as Partial<TCOptionHedgeValues>]);
      } else {
        legResult.push([legId, leg]);
      }

      return [legResult, hedgeResult];
    },
    [[], []] as [LegResult[], HedgeResult[]],
  );

  const updatedTcChangedFields: TradeCaptureOptionChanges = {
    ...tcChangedFields,
    legs: { '0': { ...tcChangedFields.legs['0'], legs: Object.fromEntries(legsWithoutHedges) } },
  };

  return [updatedTcChangedFields, hedges] as const;
}

/**
 * Load hedge patch from the multi-leg
 */
function getHedgesFromTradeCaptureHedges(
  optionId: string,
  hedges: HedgeResult[],
  tcErrors: TypeOfErrors | undefined,
  tcWarnings: TypeOfWarnings | undefined,
): FxOptionHedgesPatchedValuesRecords {
  const toReduxHedge = (
    patch: FxOptionHedgesPatchedValuesRecords,
    [hedgeId, hedge]: HedgeResult,
  ): FxOptionHedgesPatchedValuesRecords => {
    patch[`${optionId}/${hedgeId}`] = {
      values: clearUndefined({
        rate: hedge.price,
        paymentDate: hedge.deliveryDate,
        amount: mapHedgeAmount(hedge.amountInCcy1, hedge.amountInCcy2),
        currency: mapHedgeCurrency(hedge.amountInCcy1, hedge.amountInCcy2), // TODO 4287: if all CcyX are not defined, how to choice it ? Xone team suggests a currency field ?
      }),
      warnings: isDefined(tcWarnings) ? getHedgeWarnings(hedgeId, tcWarnings) : {},
      errors: isDefined(tcErrors) ? getHedgeErrors(hedgeId, tcErrors) : {},
    };

    return patch;
  };

  return hedges.reduce(toReduxHedge, {} as FxOptionHedgesPatchedValuesRecords);
}

// TODO 4287: fix the warnings with the multi-leg warnings
function getHedgeWarnings(hedgeId: string, tcWarnings: TypeOfWarnings): PropertyWarnings<IFxHedgeValues> {
  return getHedgeWarningsOrErrors<PropertyWarnings<IFxHedgeValues>>(hedgeId, tcWarnings);
}

// TODO 4287: fix the warnings with the multi-leg warnings
function getHedgeErrors(hedgeId: string, tcErrors: TypeOfErrors): PropertyErrors<IFxHedgeValues> {
  return getHedgeWarningsOrErrors<PropertyErrors<IFxHedgeValues>>(hedgeId, tcErrors);
}

function getHedgeWarningsOrErrors<PropertyData extends PropertyWarnings<any> | PropertyErrors<any>>(
  hedgeId: string,
  /** @todo SGEFX-4288 change this to a real type once xone tradecapture implementation can be checked */
  tcWarningsOrErrors: any, // TypeOfWarnings | TypeOfErrors,
): PropertyData {
  const hedgeWarningsOrErrors = tcWarningsOrErrors['0']?.legs?.[hedgeId] ?? {};

  return Object.entries(hedgeWarningsOrErrors).reduce(
    (warningsOrErrors, [propertyTradeCaptureName, propertyWarnings]: [string, any]) => {
      const propertyName = TRADE_CAPTURE_NAME_TO_LOCAL[propertyTradeCaptureName];

      if (isDefined(propertyName)) {
        const [{ id, description, faultyValue }] = propertyWarnings;

        // @ts-ignore
        warningsOrErrors[propertyName] = {
          code: `warning-tc-${id}`,
          description,
          faultyValue,
          userNotified: false,
        };
      }

      return warningsOrErrors;
    },
    {} as Writeable<PropertyData>,
  );
}

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

const TRADE_CAPTURE_NAME_TO_LOCAL: Record<string, keyof IFxHedgeValues> = {
  price: 'rate',
  amoutCCy1: 'amount',
  amoutCCy2: 'amount',
  deliveryDate: 'paymentDate',
};

// ---------------------------------------------------------------------------------
// Private metaSelector
// ---------------------------------------------------------------------------------
interface PrivateMetaSelectorMetaData<TChanges, TPreviousValues> {
  changes: Partial<TChanges>;
  tcErrors: TcErrors<TPreviousValues>;
  tcWarnings: TcWarnings<TPreviousValues>;
}

/**
 * hedgeAmount exists only for a typed strategy
 */
const mergeOptionValuesWithChanges = (
  _state: Readonly<AppState>,
  {
    changes,
    tcErrors,
    tcWarnings,
  }: PrivateMetaSelectorMetaData<TradeCaptureOptionChanges, TradeCaptureOptionPreviousValues>,
): IPatch<IFxOptionValues> => ({
  values: clearUndefined({
    currencyPair: changes.currencyPair === null ? null : changes.currencyPair ?? previousValue(tcErrors.currencyPair),
    hedgeType: hedgeTypeToModel(changes.hedgeType) ?? hedgeTypeToModel(previousValue(tcErrors.hedgeType)),
    markupCurrency: changes.markupCurrency,
    premiumDate:
      changes.premiumDate === null ? null : changes.premiumDate ?? previousValue(tcErrors.premiumPaymentDate),
    premiumDateTenor: changes.premiumDateTenor,
    amountReference:
      changes.amountReference === null ? null : changes.amountReference ?? previousValue(tcErrors.amountReference),
    amountReferenceCurrency: changes.amountReferenceCurrency,
    amountReferenceIsOverridden: changes.isOverriddenAmountReference,
  }),

  errors: clearUndefined({
    currencyPair: fromTcError(tcErrors.currencyPair),
    amountReference: fromTcError(tcErrors.amountReference),
  }),

  warnings: clearUndefined({
    currencyPair: fromTcWarning(tcWarnings.currencyPair),
    amountReference: fromTcWarning(tcWarnings.amountReference),
  }),
});

function hedgeTypeToModel(value: TcHedgeType | null | undefined): HedgeType | undefined {
  if (value === 'None' || value === null) {
    return 'Live';
  }
  return value;
}

function mapHedgeAmount(
  hedgeAmountInCcy1: number | null | undefined,
  hedgeAmountInCcy2: number | null | undefined,
  alternativeValue?: number | null | undefined,
) {
  // if one amount is null and the other is null or undefined
  return (hedgeAmountInCcy1 === null && !isDefined(hedgeAmountInCcy2)) ||
    (hedgeAmountInCcy2 === null && !isDefined(hedgeAmountInCcy1))
    ? null
    : hedgeAmountInCcy1 ?? hedgeAmountInCcy2 ?? alternativeValue;
}

function mapHedgeCurrency(
  hedgeAmountInCcy1?: number | null,
  hedgeAmountInCcy2?: number | null,
): CurrencyChoice | undefined {
  if (isDefined(hedgeAmountInCcy1)) {
    return FIRST_CURRENCY;
  } else if (isDefined(hedgeAmountInCcy2)) {
    return SECOND_CURRENCY;
  }

  return undefined;
}

function mapPremiumType(
  premiumType: PremiumType | null | undefined,
): { premiumTypeString: PriceType; premiumCurrency: CurrencyChoice } | undefined {
  switch (premiumType) {
    case 'SmiledVolatility1':
      return { premiumTypeString: 'VOLATILITY', premiumCurrency: 1 };
    case 'SmiledVolatility2':
      return { premiumTypeString: 'VOLATILITY', premiumCurrency: 2 };
    case 'AmountCurrency1':
      return { premiumTypeString: 'AMOUNT', premiumCurrency: 1 };
    case 'AmountCurrency2':
      return { premiumTypeString: 'AMOUNT', premiumCurrency: 2 };
    case 'Pip12':
      return { premiumTypeString: 'PPS', premiumCurrency: 1 };
    case 'Pip21':
      return { premiumTypeString: 'PPS', premiumCurrency: 2 };
    case 'PercentageCurrency1':
      return { premiumTypeString: 'PERCENT', premiumCurrency: 1 };
    case 'PercentageCurrency2':
      return { premiumTypeString: 'PERCENT', premiumCurrency: 2 };
    default:
      return undefined;
  }
}

const dateToModel = mapOptional((date: string) => date.substring(0, 10));

const settlementTypeToModel = mapOptional(convertTradeCaptureSettlementTypeToLegSettlementType);

const possibleSettlementTypesToModel = mapOptional(
  (possibleSettlementTypes: Possible<TradeCaptureOptionLegSettlementType>) => ({
    default: convertTradeCaptureSettlementTypeToLegSettlementType(possibleSettlementTypes.default),
    possible: possibleSettlementTypes.possible.map(convertTradeCaptureSettlementTypeToLegSettlementType),
  }),
);

interface OptionChangedFieldsFromLeg {
  premiumDate: string | undefined | null;
  premiumDateTenor: string | undefined | null;
}

export function getMostNestedKey(keys: string[]): string {
  let keyWithMaxLength = '';
  keys.forEach(key => {
    if (key.length > keyWithMaxLength.length) {
      keyWithMaxLength = key;
    }
  });

  return keyWithMaxLength;
}

const getOptionGlobalPremiumDateChangedFieldsFromFirstLeg = (
  legs: FxOptionLegsPatchedValuesRecords,
): OptionChangedFieldsFromLeg => {
  const keys = Object.keys(legs);
  const anyVanillaLeg = legs[getMostNestedKey(keys)];

  const {
    values: { premiumDate, premiumDateTenor },
  } = anyVanillaLeg;

  return {
    premiumDate,
    premiumDateTenor,
  };
};

interface OptionErrorsFromLegsErrors {
  premiumDate?: PropertyError<string | null>;
}

const getOptionErrorsFromLegErrors = (legs: FxOptionLegsPatchedValuesRecords): OptionErrorsFromLegsErrors => {
  const getValue = getOptionErrorOrWarningFromErrorOrWarningLegs('errors', legs);

  return { premiumDate: getValue('premiumDate') };
};

interface OptionWarningsFromLegsErrors {
  expiryDate?: PropertyWarning<string | null>;
}

const getOptionWarningsFromLegWarnings = (legs: FxOptionLegsPatchedValuesRecords): OptionWarningsFromLegsErrors => {
  const getValue = getOptionErrorOrWarningFromErrorOrWarningLegs('warnings', legs);

  return {
    expiryDate: getValue('expiryDate'),
  };
};

const removePremiumDateFromLegsError = (
  optionErrorFromLeg: OptionErrorsFromLegsErrors,
  legs: FxOptionLegsPatchedValuesRecords,
): FxOptionLegsPatchedValuesRecords => {
  if (optionErrorFromLeg.premiumDate === undefined) {
    return legs;
  }

  return Object.entries(legs).reduce((acc, [legId, leg]) => {
    const { errors, ...data } = leg;
    const { premiumDate, ...cleanedErrors } = errors;

    acc[legId] = { ...data, errors: cleanedErrors };

    return acc;
  }, {} as FxOptionLegsPatchedValuesRecords);
};

type FieldIndex = keyof PropertyErrors<IFxVanillaLegValues> | keyof PropertyWarnings<IFxVanillaLegValues>;

const getOptionErrorOrWarningFromErrorOrWarningLegs =
  (level: 'errors' | 'warnings', legs: FxOptionLegsPatchedValuesRecords) =>
    <T>(fieldName: FieldIndex): PropertyError<T> | undefined => {
      const errorsOrWarnings = Object.values(legs).map(leg => leg[level]);
      const firstValue = errorsOrWarnings[0] && errorsOrWarnings[0][fieldName];
      // @TODO why this 😫
      const sameValue = errorsOrWarnings.every(
        value => (value[fieldName] && value[fieldName]!.code) === (firstValue && firstValue.code),
      );

      return sameValue ? (firstValue as PropertyError<T>) : undefined;
    };

export const optionTypeToModel = mapOptional(convertTradeCaptureTypeToLegOptionType);
