import type { AppState } from 'state/model';
import { getSwapRfsStream, getSwapRfsStreamQuote } from './fxSwapsStreamsSelectors';
import { convertToPointsWithPrecision, convertToRawWithPrecision } from 'utils/margin';
import { getCurrencyPrecision } from '../../referenceData/referenceDataSelectors';
import { getSwapState } from './fxSwapsProductSelectors';
import { roundToPrecision } from 'utils/number';
import type { BidAskPair, BidAsk } from 'state/share/productModel/litterals';
import { fieldData } from 'utils/fieldSelectors';
import type { SwapOffMarketType } from '../fxSwapsModel';
import {
  getSwapIsOffMarket,
  getSwapCurrencyPair,
  getSwapNearPrice,
  getSwapNearAmount,
  getSwapFarAmount,
  getSwapFarPrice,
} from './swapInput';
import {
  getSwapCurrentStreamId,
  getSwapBidMargin,
  getSwapAskMargin,
  getSwapBidSpotMargin,
  getSwapAskSpotMargin,
} from './swapMetadata';
import { isDefined } from '@sgme/fp';

export type PointsAndRawRecord = Record<'raw' | 'points', BidAsk<number>> & { digits: number };

const bidAskPairTransform =
  (transformer: (value: number) => number) =>
  (pair: BidAskPair): BidAskPair => ({
    bid: transformer(pair.bid),
    ask: transformer(pair.ask),
  });

const roundToSwapPrecision = (precision: number) => (value: number | null) =>
  roundToPrecision(value || 0, precision + 2);

const roundToSpotPrecision = (precision: number) => (value: number | null) =>
  roundToPrecision(value || 0, precision);

const roundToSwapPointsPrecision = () => {
  return (value: number | null) => roundToPrecision(value || 0, 3);
};

const roundToSpotPointsPrecision = (value: number | null) => roundToPrecision(value || 0, 1);

export interface PinnableValue<T> {
  value: T;
  isPinned: boolean;
}

export function getDisplayNearPoints(state: AppState, swapId: string): PointsAndRawRecord {
  const streamId = getSwapCurrentStreamId(state, swapId);
  if (streamId !== null) {
    const streamState = getSwapRfsStream(state, streamId);
    if (streamState.status === 'PRICING') {
      const precision = getSwapStreamCurrencyPrecision(state, swapId);
      if (precision === null) {
        throw new Error('inconsistant state, pricing without swap precision');
      }
      const toPoints = convertToPointsWithPrecision(precision);
      const bidAskPairToPoints = bidAskPairTransform(toPoints);
      const { nearPoints: nearPointsRawValue } = streamState.quote;
      const bidAskWithSwapPrecision = bidAskPairTransform(roundToSwapPrecision(precision));
      const bidAskToSwapPointsPrecision = bidAskPairTransform(
        roundToSwapPointsPrecision(),
      );
      
      return {
        raw: bidAskWithSwapPrecision(nearPointsRawValue),
        points: bidAskToSwapPointsPrecision(bidAskPairToPoints(nearPointsRawValue)),
        digits: 3,
      };
    }
  }
  throw new Error('getDisplayNearPoints selector should not be call in this context');
}

export function getDisplayFarPoints(state: AppState, swapId: string): PointsAndRawRecord {
  const streamId = getSwapCurrentStreamId(state, swapId);
  if (streamId !== null) {
    const streamState = getSwapRfsStream(state, streamId);
    if (streamState.status === 'PRICING') {
      const precision = getSwapStreamCurrencyPrecision(state, swapId);
      if (precision === null) {
        throw new Error('inconsistant state, pricing without swap precision');
      }
      const toPoints = convertToPointsWithPrecision(precision);
      const bidAskPairToPoints = bidAskPairTransform(toPoints);
      const toRaw = convertToRawWithPrecision(precision);
      const bidAskPairToRaw = bidAskPairTransform(toRaw);
      const farPointswithPrecision = bidAskPairToPoints(streamState.quote.farPoints);
      const bidAskWithSwapPrecision = bidAskPairTransform(roundToSwapPrecision(precision));
      const bidAskToSwapPointsPrecision = bidAskPairTransform(
        roundToSwapPointsPrecision(),
      );
      const bidMargin = getSwapBidMargin(state, swapId);
      const askMargin = getSwapAskMargin(state, swapId);

      if (bidMargin === null || askMargin === null) {
        throw new Error('inconsistant state, pricing stream without swap margin');
      }
      const farPoints = {
        bid: farPointswithPrecision.bid - toPoints(bidMargin),
        ask: farPointswithPrecision.ask + toPoints(askMargin),
      };

      return {
        points: bidAskToSwapPointsPrecision(farPoints),
        raw: bidAskWithSwapPrecision(bidAskPairToRaw(farPoints)),
        digits: 3,
      };
    }
  }
  throw new Error('getDisplayFarPoints selector should not be call in this context');
}

export function getDisplaySpot(state: AppState, swapId: string): BidAsk<number> {
  const streamId = getSwapCurrentStreamId(state, swapId);
  const currencyPair = getSwapCurrencyPair(state, swapId).value;
  if (currencyPair !== null && streamId !== null) {
    const streamState = getSwapRfsStream(state, streamId);
    if (streamState.status === 'PRICING') {
      const precision = getCurrencyPrecision(state, currencyPair);
      const { spot: traderSpot, nearPoints } = getSwapRfsStreamQuote(state, streamId);

      const isOffMarket = fieldData(getSwapIsOffMarket(state, swapId)).data;
      if (isOffMarket) {
        return traderSpot;
      }

      const { value: manualNearPrice } = getSwapNearPrice(state, swapId);
      const bidAskToSpotPrecision = bidAskPairTransform(roundToSwapPrecision(precision));
      if (manualNearPrice !== null) {
        return bidAskToSpotPrecision({
          bid: manualNearPrice.bid - nearPoints.bid,
          ask: manualNearPrice.ask - nearPoints.ask,
        });
      }
      const bidSpotMargin = getSwapBidSpotMargin(state, swapId);
      const askSpotMargin = getSwapAskSpotMargin(state, swapId);

      const { value: nearAmount } = getSwapNearAmount(state, swapId);
      const { value: farAmount } = getSwapFarAmount(state, swapId);

      if (nearAmount === null || farAmount === null) {
        throw new Error('inconsistent state, pricing stream without amount');
      }

      if (bidSpotMargin === null || askSpotMargin === null) {
        throw new Error('inconsistent state, pricing stream without spotMargin');
      }
      const unevenFactor = Math.sign(farAmount - nearAmount);
      return bidAskToSpotPrecision({
        bid: traderSpot.bid - unevenFactor * bidSpotMargin,
        ask: traderSpot.ask + unevenFactor * askSpotMargin,
      });
    }
  }
  throw new Error('getDisplaySpot selector should not be call in this context');
}

export function getDisplayNearPrice(
  state: AppState,
  swapId: string,
): PinnableValue<BidAsk<number> | null> {
  const { value: manualNearPrice } = getSwapNearPrice(state, swapId);
  if (manualNearPrice !== null) {
    return { value: manualNearPrice, isPinned: true };
  }
  const streamId = getSwapCurrentStreamId(state, swapId);
  if (streamId !== null) {
    const streamState = getSwapRfsStream(state, streamId);
    if (streamState.status === 'PRICING') {
      const clientSpot = getDisplaySpot(state, swapId);
      if (clientSpot === null) {
        throw new Error('inconsistent state : stream pricing but client spot price is missing');
      }
      const precision = getSwapStreamCurrencyPrecision(state, swapId);
      if (precision === null) {
        throw new Error('inconsistent state : stream pricing but precision is missing');
      }
      const { raw: nearPoints } = getDisplayNearPoints(state, swapId);
      const bidAskWithSwapPrecision = bidAskPairTransform(roundToSwapPrecision(precision + 1));

      return {
        value: bidAskWithSwapPrecision({
          bid: clientSpot.bid + nearPoints.bid,
          ask: clientSpot.ask + nearPoints.ask,
        }),
        isPinned: false,
      };
    }
  }
  return { value: null, isPinned: false };
}

export function getDisplayFarPrice(
  state: AppState,
  swapId: string,
): PinnableValue<BidAsk<number> | null> {
  const { value: manualFarPrice } = getSwapFarPrice(state, swapId);
  if (manualFarPrice !== null) {
    return { value: manualFarPrice, isPinned: true };
  }
  const streamId = getSwapCurrentStreamId(state, swapId);
  if (streamId !== null) {
    const streamState = getSwapRfsStream(state, streamId);
    const precision = getSwapStreamCurrencyPrecision(state, swapId);
    if (streamState.status === 'PRICING' && precision !== null) {
      const clientSpot = getDisplaySpot(state, swapId)!;
      const { raw: farPoints } = getDisplayFarPoints(state, swapId);
      const bidAskWithSwapPrecision = bidAskPairTransform(roundToSwapPrecision(precision + 1));
      const farPrice = {
        bid: clientSpot.bid + farPoints.bid,
        ask: clientSpot.ask + farPoints.ask,
      };

      return {
        value: bidAskWithSwapPrecision(farPrice),
        isPinned: false,
      };
    }
  }
  return { value: null, isPinned: false };
}

export function getSwapStreamCurrencyPrecision(state: AppState, swapId: string): number | null {
  const currencyPair = getSwapCurrencyPair(state, swapId).value;
  if (currencyPair === null) {
    return null;
  }
  const ccyPrecision = getCurrencyPrecision(state, currencyPair);

  return ccyPrecision;
}

export function getDisplaySwapPointsAllIn(state: AppState, swapId: string): PointsAndRawRecord {
  const nearPrice = getDisplayNearPrice(state, swapId);
  const farPrice = getDisplayFarPrice(state, swapId);
  const precision = getSwapStreamCurrencyPrecision(state, swapId);
  if (
    nearPrice === null ||
    farPrice === null ||
    precision === null
  ) {
    throw new Error('getDisplaySwapPointsAllIn selector should not be call in this context');
  }
  const toPoints = convertToPointsWithPrecision(precision);
  const bidAskToPoints = bidAskPairTransform(toPoints);
  const bidAskWithSwapPrecision = bidAskPairTransform(roundToSwapPrecision(precision));
  const bidAskWithSwapPointsPrecision = bidAskPairTransform(
    roundToSwapPointsPrecision(),
  );
  const rawSwapPoints = {
    bid: farPrice.value!.bid - nearPrice.value!.bid,
    ask: farPrice.value!.ask - nearPrice.value!.ask,
  };

  return {
    points: bidAskWithSwapPointsPrecision(bidAskToPoints(rawSwapPoints)),
    raw: bidAskWithSwapPrecision(rawSwapPoints),
    digits: 3,
  };
}

export function getDisplaySpotMargin(
  state: AppState,
  swapId: string,
): Record<'raw' | 'points', BidAsk<number>> {
  const { data: currencyPair } = fieldData(getSwapCurrencyPair(state, swapId));
  if (currencyPair === null) {
    throw new Error(
      'getDisplaySpotMargin selector should not be called without a currency pair defined',
    );
  }
  const precision = getCurrencyPrecision(state, currencyPair);
  const bidAskToSpotPrecision = bidAskPairTransform(roundToSpotPrecision(precision));
  const bidAskToSpotPointsPrecision = bidAskPairTransform(roundToSpotPointsPrecision);

  const bidAskToPoints = bidAskPairTransform(convertToPointsWithPrecision(precision));

  const { value } = getSwapNearPrice(state, swapId);
  if (value === null) {
    const bidSpotMargin = getSwapBidSpotMargin(state, swapId);
    const askSpotMargin = getSwapAskSpotMargin(state, swapId);
    if (bidSpotMargin !== null && askSpotMargin !== null) {
      const rawSpotMargin = {
        bid: bidSpotMargin,
        ask: askSpotMargin,
      };
      return {
        points: bidAskToSpotPointsPrecision(bidAskToPoints(rawSpotMargin)),
        raw: bidAskToSpotPrecision(rawSpotMargin),
      };
    }
  } else {
    const streamId = getSwapCurrentStreamId(state, swapId);
    if (streamId !== null) {
      const streamState = getSwapRfsStream(state, streamId);
      if (streamState.status === 'PRICING') {
        const farNotional = getSwapFarAmount(state, swapId).value || 0;
        const nearNotional = getSwapNearAmount(state, swapId).value || 0;
        const unevenFactor = Math.sign(farNotional - nearNotional);
        const traderSpot = streamState.quote.spot;
        const clientSpot = getDisplaySpot(state, swapId);
        if (clientSpot !== null) {
          const rawSpotMargin = {
            bid: unevenFactor * (traderSpot.bid - clientSpot.bid),
            ask: unevenFactor * (clientSpot.ask - traderSpot.ask),
          };
          return {
            points: bidAskToSpotPointsPrecision(bidAskToPoints(rawSpotMargin)),
            raw: bidAskToSpotPrecision(rawSpotMargin),
          };
        }
      }
    }
  }

  const defaultValue = {
    points: { bid: 0, ask: 0 },
    raw: { bid: 0, ask: 0 },
  };
  return defaultValue;
}

export function getDisplayMidRateAndMidPts(state: AppState, swapId: string) {
  const { currentStreamId } = getSwapState(state, swapId);
  const currencyPair = getSwapCurrencyPair(state, swapId).value;
  if (currentStreamId !== null && currencyPair !== null) {
    const precision = getCurrencyPrecision(state, currencyPair);
    const stream = getSwapRfsStream(state, currentStreamId);
    if (stream.status === 'PRICING') {
      const toPoints = convertToPointsWithPrecision(precision);
      const { midAllInRate = null, delta = null } = stream.quote;

      return {
        midAllInRate,
        midPts: delta === null ? null : roundToPrecision(toPoints(delta), 2),
      };
    }
  }
  return null;
}

export function getSwapStreamStatus(state: AppState, tileId: string) {
  const { currentStreamId } = getSwapState(state, tileId);
  if (isDefined(currentStreamId)) {
    return getSwapRfsStream(state, currentStreamId).status;
  } else {
    return 'NONE';
  }
}

export function getSwapMarkupCurrency(state: AppState, tileId: string) {
  const { markupCurrency } = getSwapState(state, tileId);
  const {
    values: { currency1, currency2 },
  } = getSwapState(state, tileId);
  return (markupCurrency === 1 ? currency1 : currency2) as string;
}

export function getSwapOffMarketType(state: AppState, tileId: string): SwapOffMarketType | null {
  return fieldData(getSwapIsOffMarket(state, tileId)).data === false
    ? null
    : fieldData(getSwapNearPrice(state, tileId)).data !== null
    ? 'rollover'
    : fieldData(getSwapFarPrice(state, tileId)).data !== null
    ? 'predeliver'
    : null;
}

export function getDisplayFarMktPoints(state: AppState, swapId: string): PointsAndRawRecord {
  const streamId = getSwapCurrentStreamId(state, swapId);
  const currencyPair = getSwapCurrencyPair(state, swapId).value;
  if (currencyPair !== null && streamId !== null) {
    const streamState = getSwapRfsStream(state, streamId);
    if (streamState.status === 'PRICING') {
      const precision = getCurrencyPrecision(state, currencyPair);
      const toPoints = convertToPointsWithPrecision(precision);
      const bidAskPairToPoints = bidAskPairTransform(toPoints);
      const toRaw = convertToRawWithPrecision(precision);
      const bidAskPairToRaw = bidAskPairTransform(toRaw);
      const farPoints = bidAskPairToPoints(streamState.quote.farMktPoints);
      const bidAskWithSwapPrecision = bidAskPairTransform(roundToSwapPrecision(precision));
      const bidAskToSwapPointsPrecision = bidAskPairTransform(roundToSwapPointsPrecision());
      return {
        points: bidAskToSwapPointsPrecision(farPoints),
        raw: bidAskWithSwapPrecision(bidAskPairToRaw(farPoints)),
        digits: 3,
      };
    }
  }
  throw new Error('getDisplayFarMktPoints selector should not be call in this context');
}
