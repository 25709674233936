import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Client } from 'state/referenceData/referenceDataModel';
import { createFilterList, type FilterListChildrenProps } from 'components/share/FilterList/FilterList';
import { filterClientsBy } from './utils';
import { createEventHandler } from 'components/share/createEventHandler';
import { ClientList } from './ClientList';
import { input } from 'components/Form/Inputs/e2e';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import en from './locales/en.json';
import fr from './locales/fr.json';

const messagesMap = { en, fr };

export interface ClientPickerProps {
  clients: readonly Client[];
  query?: string;
  banner?: React.ReactNode;
  button?: React.ReactNode;
  onClientChange(companyId: string): void;
  close(): void;
  pinned: readonly number[];
}

export function ClientPicker(props: ClientPickerProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.select();
    }
  }, [inputRef]);

  return (
    <div className="card shadow text-primary">
      <ClientFilterList list={props.clients} query={props.query ?? ''}>
        {(clientFilter) => (
          <ClientSelectEventHandler value={{ ...props, ...clientFilter }}>
            {(handlers) => (
              <>
                <div className="card-header">
                  {props.banner}
                  <div className="input-group input-group-lg">
                    <FormattedMessage id="workspace.client.search.placeholder">
                      {(placeholder) => (
                        <input
                          type="text"
                          data-e2e={input('clientName')}
                          autoFocus
                          className="form-control"
                          placeholder={placeholder!.toString()}
                          value={clientFilter.query}
                          ref={inputRef}
                          {...handlers}
                        />
                      )}
                    </FormattedMessage>
                    {props.button}
                  </div>
                </div>
                <div className="card-body">
                  <IntlComponentBoundary messagesMap={messagesMap}>
                    <ClientList
                      foundClients={clientFilter.filteredList}
                      selected={clientFilter.selectionIndex}
                      highlight={clientFilter.query}
                      gotoClient={clientFilter.goto}
                      onClientChange={props.onClientChange}
                      close={props.close}
                      pinned={props.pinned}
                    />
                  </IntlComponentBoundary>
                </div>
              </>
            )}
          </ClientSelectEventHandler>
        )}
      </ClientFilterList>
    </div>
  );
}

const ClientFilterList = createFilterList(filterClientsBy);
const ClientSelectEventHandler = createEventHandler<
  FilterListChildrenProps<Client> & ClientPickerProps,
  HTMLInputElement,
  'onChange' | 'onKeyDown'
>({
  onChange:
    ({ onQueryChange }) =>
    (event) => {
      onQueryChange(event.currentTarget.value);
    },
  onKeyDown: (props) => (event) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        props.previous();
        return;
      case 'ArrowDown':
        event.preventDefault();
        props.next();
        return;
      case 'Escape':
        props.close();
        return;
      case 'Enter': {
        const client = props.filteredList[props.selectionIndex];
        if (client !== undefined) {
          props.onClientChange(client.companyId.toString());
          props.close();
        }
        return;
      }
    }
  },
});
