import type { Column, GridLayout, LocusCoordinates } from '../../gridLayoutModels';
import { getOccupiedColumnsById } from './gridLayoutColumns';
import { logger } from '../../../../logging/logger';

export function getLociById(
  gridLayout: GridLayout,
  gridItemIdQuery: string,
): readonly LocusCoordinates[] {
  return getOccupiedColumnsById(gridLayout, gridItemIdQuery).map(colIndex => {
    const locusIndex = gridLayout.columns[colIndex].findIndex(
      ({ gridItemId }) => gridItemId === gridItemIdQuery,
    );
    const locus = gridLayout.columns[colIndex][locusIndex];
    if (locusIndex === undefined || locus === undefined) {
      throw new Error('getLociById applied to invalid state');
    }
    return { locusIndex, locus, colIndex };
  });
}

export function getNextLocusInColumn(column: Column, gridItemIdQuery: string) {
  if (column === undefined) {
    logger.logWarning(
      `getNextLocusInColumn was called on undefined column for  grid item id : {gridItemIdQuery_s}`,
      gridItemIdQuery,
    );
    return undefined;
  }
  const index = column.findIndex(({ gridItemId }) => gridItemIdQuery === gridItemId);
  return column[index + 1];
}

export function getPreviousLocusInColumn(column: Column, gridItemIdQuery: string) {
  const index = column.findIndex(({ gridItemId }) => gridItemIdQuery === gridItemId);
  return column[index - 1];
}
