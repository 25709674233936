import { FormattedMessage } from 'react-intl';
import { MenuButton } from './MenuButton';
import { connectUserPrefButton } from './connect';

export interface UserPrefButtonProps {
  toggleUserPref(): void;
}

const tooltip = <FormattedMessage id="navMenuButtons.userPreferences" />;

export const UserPrefButtonRaw: React.FunctionComponent<UserPrefButtonProps> = props => (
  <MenuButton e2eHandle="user-preference" onClick={props.toggleUserPref} tooltip={tooltip}>
    <i className="icon icon-md">settings</i>
  </MenuButton>
);

export const UserPrefButton = connectUserPrefButton(UserPrefButtonRaw);
