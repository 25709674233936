export const getNextFocus = (
  groupElement: HTMLElement,
  currentElement: HTMLElement,
  backwards?: boolean,
) => {
  const elements = Array.from(
    groupElement.querySelectorAll<HTMLElement>('input, select, [data-input]'),
  )
    .filter(
      el =>
        !el.hasAttribute('readonly') &&
        !el.hasAttribute('disabled') &&
        !el.hasAttribute('data-skip-tab') &&
        el.tabIndex !== -1,
    )
    .map((el, i) => [el, i] as const)
    .sort(([el1, i1], [el2, i2]) => el1.tabIndex - el2.tabIndex || i1 - i2)
    .map(([el]) => el);
  const index = elements.indexOf(currentElement);
  const next =
    elements[
      backwards
        ? index - 1 === -1
          ? elements.length - 1
          : index - 1
        : (index + 1) % elements.length
    ];

  return next !== currentElement ? next : undefined;
};
