import { getEnvironment } from '../../../configuration';
import { useIntl } from 'react-intl';
import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';

export function HelpCenter(): JSX.Element {
  const { locale } = useIntl();
  return (
    <aside>
      <SgwtHelpCenter
        language={locale}
        mailSubject={`[SGME-FX:${getEnvironment()?.toUpperCase() ?? 'PROD'}] Help center message`}
        applicationId="sgme-fx"
        allowScreenshot={true}
      />
    </aside>
  );
}
