import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { ProductAccessKeys } from 'state/referenceData/referenceDataModel';
import type { RenderWhenProps } from '../RenderWhen';

export interface UserHasProductsConnectOwnProps {
  products: readonly ProductAccessKeys[];
}

type UserHasProductsConnectStateProps = RenderWhenProps;

export type UserHasProductsConnectSelectorsKeys = 'getProductsAccess';
export type UserHasProductsConnectSelectors = Pick<Selectors, UserHasProductsConnectSelectorsKeys>;

export const mapStateToPropsUserHasProducts: MapStateToPropsHOF<
  UserHasProductsConnectStateProps,
  UserHasProductsConnectOwnProps,
  AppState,
  UserHasProductsConnectSelectors
> =
  sl =>
  (state, { products }) => {
    const condition = Object.entries(sl.getProductsAccess(state)).every(
      ([product, access]) => hasAccessOrUnneeded(products, product, access),
      true,
    );
    return {
      condition,
    };
  };

const hasAccessOrUnneeded = (
  products: readonly string[],
  product: string,
  access: boolean,
): boolean => (products.includes(product) ? access : true);
