import type { TradeCaptureError } from '../../tile/fxTileModel';
import type { FxAccumulatorPatch, AccumulatorSchedule } from '../fxAccumulatorsModel';

export interface AccumulatorPropertiesRequested {
  type: 'ACCUMULATOR_PROPERTIES_REQUESTED';
  quoteId: string;
  sessionId: string;
}

export function accumulatorPropertiesRequested(
  quoteId: string,
  sessionId: string,
): AccumulatorPropertiesRequested {
  return {
    type: 'ACCUMULATOR_PROPERTIES_REQUESTED',
    quoteId,
    sessionId,
  };
}

export interface AccumulatorPropertiesRequestFailed {
  type: 'ACCUMULATOR_PROPERTIES_REQUEST_FAILED';
  quoteId: string;
  error: TradeCaptureError;
}

export function accumulatorPropertiesRequestFailed(
  quoteId: string,
  error: TradeCaptureError,
): AccumulatorPropertiesRequestFailed {
  return {
    type: 'ACCUMULATOR_PROPERTIES_REQUEST_FAILED',
    quoteId,
    error,
  };
}

export interface AccumulatorPropertiesReceived {
  type: 'ACCUMULATOR_PROPERTIES_RECEIVED';
  quoteId: string;
  patch: FxAccumulatorPatch;
  isReadyToPrice: boolean | null;
  isPriceObsolete: boolean;
  isProductObsolete: boolean;
  idVersion: number;
  summary: string;
  numberOfSettlements?: number | null;
  schedule?: AccumulatorSchedule;
}

export function accumulatorPropertiesReceived(
  quoteId: string,
  patch: FxAccumulatorPatch,
  idVersion: number,
  isReadyToPrice: boolean | null,
  isPriceObsolete = false,
  isProductObsolete = false,
  summary = '',
  numberOfSettlements?: number | null,
  schedule?: AccumulatorSchedule,
): AccumulatorPropertiesReceived {
  return {
    type: 'ACCUMULATOR_PROPERTIES_RECEIVED',
    quoteId,
    patch,
    isReadyToPrice,
    isPriceObsolete,
    isProductObsolete,
    idVersion,
    summary,
    numberOfSettlements,
    schedule,
  };
}
