import type { CurrencyPairData } from '../state/referenceData/referenceDataModel';

export function sortCurrencies(a: CurrencyPairData, b: CurrencyPairData) {
  if (a.isInverted && !b.isInverted) {
    return 1;
  } else if (!a.isInverted && b.isInverted) {
    return -1;
  } else {
    if (a.pair < b.pair) {
      return -1;
    }

    if (a.pair > b.pair) {
      return 1;
    }
  }

  return 0;
}
