import type { CurrencyChoice, Side, BidAskPair, Way } from 'state/share/productModel/litterals';
import { precisionAdderWithPrecision } from 'utils/number';
import { convertToRawWithPrecision } from 'utils/margin';

export const getImpactedDigitsFromPoints = (points: number) => {
  const abs = Math.abs(points);
  return abs > 0 ? Math.floor(Math.log10(1000 * abs)) : 0;
};

export const toggleCurrency = (ccy: CurrencyChoice): CurrencyChoice => (ccy === 1 ? 2 : 1);

export const getBidAskValueWith =
  (customerWay: Side) =>
  ({ bid, ask }: BidAskPair): number =>
    customerWay === 'Buy' ? ask : bid;

export const makeBidAskValueWith = (customerWay: Side) => (value: number) =>
  customerWay === 'Buy'
    ? {
        ask: value,
        bid: 0,
      }
    : {
        ask: 0,
        bid: value,
      };

export const makeBulkLegId = (bulkId: string, legId: string) => `${bulkId}/${legId}`;

// TODO: factorize me with CASH product
export const makeSpotWithMargin = (
  customerWay: Way,
  spotWithoutMargin: number,
  marginPoints: number,
  precision: number,
): number => {
  const adder = precisionAdderWithPrecision(precision);
  const fromPoints = convertToRawWithPrecision(precision);
  switch (customerWay) {
    case 'Bid':
      return adder(spotWithoutMargin, -fromPoints(marginPoints));
    case 'Ask':
      return adder(spotWithoutMargin, fromPoints(marginPoints));
  }
};

export const makeForwardPointWithMargin = (
  customerWay: Way,
  forwardPts: number,
  forwardPtsMargin: number,
): number => {
  switch (customerWay) {
    case 'Bid':
      return forwardPts - forwardPtsMargin;
    case 'Ask':
      return forwardPts + forwardPtsMargin;
  }
};

export const makeAllIn = (spotWithMargin: number, fwdPoints: number, precision: number) => {
  const adder = precisionAdderWithPrecision(precision + 1);
  const fromPoints = convertToRawWithPrecision(precision);
  return adder(spotWithMargin, fromPoints(fwdPoints));
};

export const toggleSide = (side: Side): Side => (side === 'Sell' ? 'Buy' : 'Sell');

export const wayToSide = (way: Way) => (way === 'Ask' ? 'Buy' : 'Sell');

export const sideToWay = (side: Side) => (side === 'Buy' ? 'Ask' : 'Bid');

export const getDisplayedSide = (side: Side, amountCurrency: CurrencyChoice) =>
  amountCurrency === 2 ? toggleSide(side) : side;

export const makeTotalMarginRaw = (
  spotMarginPoints: number,
  forwardMarginPoints: number,
  precision: number,
): number => {
  const fromPoints = convertToRawWithPrecision(precision);
  const result = fromPoints(spotMarginPoints + forwardMarginPoints);
  return result;
};
