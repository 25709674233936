import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { QuoteContributionType } from 'state/share/productModel/litterals';

interface TraderInfosConnectOwnProps {
  quoteId: string;
}

interface TraderInfosConnectStateProps {
  traderId: string | null;
  contribution: QuoteContributionType | null;
  summary: string | null;
}

// export type TraderIdConnectSelectors = Pick<
//   Selectors,
//   'getTileTraderId' | 'getOptionState' | 'getClientForTile'
// >;

export const mapStateToPropsTraderInfos: MapStateToPropsHOF<
  TraderInfosConnectStateProps,
  TraderInfosConnectOwnProps,
  AppState,
  Selectors
> =
  sl =>
  (state, { quoteId }) => {
    const traderId = sl.getTileTraderId(state, quoteId);
    if (traderId) {
      const ccyPair = sl.getTileCurrencyPair(state, quoteId);
      const client = sl.getClientForTile(state, quoteId);
      const instrument = sl.getQuoteInstrument(state, quoteId);
      const contribution = sl.getTileContribution(state, quoteId);

      return {
        traderId,
        contribution,
        summary: `${instrument} ${ccyPair.value} ${client.companyName}`, // + legs ?
      };
    } else {
      return noTrader;
    }
  };

const noTrader: TraderInfosConnectStateProps = {
  traderId: null,
  contribution: null,
  summary: null,
};
