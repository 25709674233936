import { getAccumulatorValue, getForwardAccumulatorValue, getTargetAccumulatorValue } from './factories';

export const getAccumulatorProductName = getAccumulatorValue('productName');

export const getAccumulatorPossibleHedgeTypes = getAccumulatorValue('possibleHedgeTypes');

export const getAccumulatorHedgePaymentDate = getAccumulatorValue('hedgePaymentDate');

// export const getAccumulatorHedgeNotional = getAccumulatorValue('hedgeNotional');
export const getAccumulatorPossiblePriceTypes = getAccumulatorValue('possiblePriceTypes');

export const getAccumulatorTargetProfitTypeList = getAccumulatorValue('targetProfitTypeList');

export const getAccumulatorFixingFrequenciesList = getAccumulatorValue('fixingFrequenciesList');

export const getAccumulatorSettlementModeList = getAccumulatorValue('settlementModeList');

export const getAccumulatorCashSettlementCurrencyList = getAccumulatorValue('cashSettlementCurrencyList');

export const getAccumulatorSettlementFrequenciesList = getAccumulatorValue('settlementFrequenciesList');

export const getAccumulatorFixingReferencesList1 = getAccumulatorValue('fixingReferencesList1');

export const getAccumulatorFixingReferencesList2 = getAccumulatorValue('fixingReferencesList2');

export const getAccumulatorCalendar = getAccumulatorValue('calendar');

export const getAccumulatorDeliveryDate = getAccumulatorValue('deliveryDate');

export const getAccumulatorCutOffMarketPlace = getAccumulatorValue('cutOffMarketPlace');

export const getAccumulatorLastFixingDate = getAccumulatorValue('lastFixingDate');

export const getAccumulatorTotalLeverageAmount = getAccumulatorValue('totalLeverage');

export const getAccumulatorTotalNotionalAmount = getAccumulatorValue('totalNotional');

export const getAccumulatorCustomFields = getAccumulatorValue('customFields');

export const getAccumulatorPossibleCrossCurrencies = getAccumulatorValue('possibleCrossCurrencies');

export const getAccumulatorDefaultFixingReference1 = getAccumulatorValue('defaultFixingReference1');

export const getAccumulatorDefaultFixingReference2 = getAccumulatorValue('defaultFixingReference2');

export const getAccumulatorStrikeComparator = getAccumulatorValue('strikeComparator');

export const getForwardAccumulatorAccuType = getForwardAccumulatorValue('accuType');

export const getTargetAccumulatorAccuType = getTargetAccumulatorValue('accuType');

export const getTargetAccuTypeList = getTargetAccumulatorValue('possibleAccuTypeList');

export const getAccumulatorBarrier = getAccumulatorValue('barrier');

export const getAccumulatorStepDown = getAccumulatorValue('stepDown');

export const getAccumulatorStepUp = getAccumulatorValue('stepUp');
