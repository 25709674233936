import type { AppState } from 'state/model';
import type { GridItemPosition, Column } from '../gridLayoutModels';
import { colWidth } from 'styles/constants';
import { findLocusInsertionIndexInColumn, getOccupiedColumnsByCoordinates } from '../reducer/utils';
import { getGridById } from './getGrid';
import { getGridItemSize, getGridItemPosition } from './getGridItems';

const filterGridItemInColumn = (gridItemId: string) => (column: Column) =>
  column.filter(locus => locus.gridItemId !== gridItemId);

const positiveOrZero = (n: number) => (n > 0 ? n : 0);

/**
 * @todo
 * this selector is currently leaking some noop drag position
 */

export function newDragPositionWouldChangePosition(
  state: AppState,
  gridId: string,
  gridItemId: string,
  dragPosition: GridItemPosition,
) {
  // is current status idle
  const grid = getGridById(state, gridId);
  if (grid.draggingStatus === 'IDLE') {
    return true;
  }

  // negative values are artefacts of dnd api
  const left = positiveOrZero(dragPosition.left);
  const top = positiveOrZero(dragPosition.top);
  const { width, height } = getGridItemSize(state, gridId, gridItemId);

  // is drag position in different colum ? reduce
  const currentPosition = getGridItemPosition(state, gridId, gridItemId);
  if (left < currentPosition.left || left > currentPosition.left + colWidth) {
    return true;
  }

  // is drag position directly hovering placeholder position ? noop
  if (top >= currentPosition.top && top < currentPosition.top + height) {
    return false;
  }

  const columns = grid.draggingGridLayout.columns.map(filterGridItemInColumn(gridItemId));
  const occupiedColumns = getOccupiedColumnsByCoordinates(left, width, columns);
  const slots = occupiedColumns.map(findLocusInsertionIndexInColumn(top, top + height));
  // is drag position directly above any other tile ? reduce
  if (slots.includes(-1)) {
    /**
     * @todo
     * if currently dragged tile plaholder is immediately above the hovered tile
     * and the tile would be hovered in the stored state too, noop
     */
    // return false
    return true;
  }
  // is drag position, with top gravity applied, resulting in an equal position ? noop
  if (
    Math.max(
      ...occupiedColumns.map((column, index) => {
        const slot = slots[index];
        if (slot === 0) {
          return 0;
        }
        const upperLocus = column[slot - 1];
        return upperLocus.bottom;
      }),
    ) === currentPosition.top
  ) {
    return false;
  }
  return true;
}
