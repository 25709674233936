import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { addKey, updateKey, removeKey } from 'utils/stateMap';
import type { FxOptionStrippingMap } from '../model/optionStrippings';

// TODO: add tests

export const optionsStrippingsReducer: Reducer<FxOptionStrippingMap, Action> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case 'OPTION_STRIPPING_OPENED':
      if (state[action.quoteId] !== undefined) {
        return state;
      }
      return addKey(state, action.quoteId, {
        requestPending: false,
        error: undefined,
      });

    case 'OPTION_STRIPPING_REQUESTED':
      return updateKey(state, action.quoteId, () => ({
        requestPending: true,
      }));

    case 'OPTION_STRIPPING_FAILED':
      return updateKey(state, action.quoteId, () => ({
        requestPending: false,
        error: action.error,
      }));

    case 'OPTION_STRIPPING_CLOSED':
    case 'OPTION_STRIPPING_RECEIVED':
      return removeKey(state, action.quoteId);

    default:
      return state;
  }
};
