import type { FxSwapStreamState } from '../fxSwapsModel';

import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';
export function getSwapRfsStream(state: AppState, streamId: string): FxSwapStreamState {
  return throwIfUndefined(
    state.fxSwaps.streams[streamId],
    `Swap stream state ${streamId} does not exist`,
  );
}

export function getSwapRfsStreamQuote(state: AppState, streamId: string) {
  const stream = getSwapRfsStream(state, streamId);
  const quote = stream.status === 'PRICING' ? stream.quote : undefined;
  return throwIfUndefined(quote, `Swap stream state ${streamId} not in PRICING state`);
}

export function isSwapStreamPresent(state: AppState, streamId: string): boolean {
  return !!state.fxSwaps.streams[streamId];
}

export function getSwapRfsStreamQuoteType(state: AppState, streamId: string) {
  return getSwapRfsStreamQuote(state, streamId).quoteType;
}
