import type { Reducer } from 'redux';
import type { FxSwapStreamStateMap, FxSwapStreamState } from '../fxSwapsModel';
import type { Action } from 'state/actions';
import { addKey, removeKey, updateKey } from 'utils/stateMap';

export const swapStreamsReducer: Reducer<FxSwapStreamStateMap> = (
  state: FxSwapStreamStateMap = {},
  action: Action,
): FxSwapStreamStateMap => {
  switch (action.type) {
    case 'SWAP_RFS_STARTED':
      return addKey(state, action.streamId, {
        status: 'AWAITING',
      } as FxSwapStreamState);
    case 'SWAP_RFS_FAILED':
    case 'SWAP_RFS_TERMINATED':
    case 'SWAP_RFS_CANCEL':
      return removeKey(state, action.streamId);
    case 'SWAP_EXECUTION_SENT':
      return removeKey(state, action.executionId);
    case 'SWAP_QUOTE_RECEIVED':
      return updateKey(state, action.streamId, currentState => {
        const initialRfsWindow =
          currentState.status === 'PRICING'
            ? currentState.initialRfsWindow
            : action.quote.rfsWindow;
        return {
          status: 'PRICING',
          quote: action.quote,
          initialRfsWindow,
          tiering: action.tiering,
        } as FxSwapStreamState;
      });
  }

  return state;
};
