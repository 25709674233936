import { logger } from 'logging/logger';

export function redirectToRequestAccessPage() {
  logger.logInformation('Redirecting to request access page');
  window.location.replace(window.sgmeConfiguration.accessRequestUrl + location.href);
}

function getQueryParam(queryParam: string): boolean {
  const params = new URLSearchParams(self.location.search);
  if (!params.has(queryParam)) {
    return false;
  }

  const param = params.get(queryParam)?.toLocaleLowerCase();

  return param === 'true';
}

export const isTraceLogEnabledQueryParameter = () => getQueryParam('isTraceLogEnabled');
