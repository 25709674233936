/**
 * @private
 * these exports are shared helper for exported selectors
 *
 * don't export this file in barrel ('./index.ts')
 */

import type { AppState } from 'state/model';
import { throwIfUndefined } from 'utils/maps';

/**
 * @private
 */
export function getBulkState(state: AppState, bulkId: string) {
  return throwIfUndefined(
    state.fxBulks.bulks[bulkId],
    `Bulk state for tab ${bulkId} does not exist`,
  );
}
