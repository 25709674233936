import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { IntlComponentBoundary, type MessagesMap } from 'utils/i18n/IntlComponentBoundary';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { strictEntries } from 'utils/object/entries';
import { NotificationEmails } from './NotificationEmails';
import { AutoCloseGrowlAfterPref } from './AutoCloseGrowlAfter';
import {
  ShowSwapPointsPref,
  ResetTileToSpotOnQuoteExecutionPref,
  OrderQuickLimitPref,
  ShowDeltaStrikePref,
  OptionOneWayQuotationPref,
  OptionDisplayMidVolatilityPref,
  OptionStrategyGroupLegsPref,
  OptionAlignCurrenciesPref,
  OptionExpandGreekAndMktPref,
  SplitNotificationsPref,
} from './simpleCheckboxUserPrefs';
import { DefaultNotionalPref } from './DefaultNotional';
import { ShowMyMainDealsPref } from './ShowMyMainDeals';
import { createSwitchChildren } from 'components/share/SwitchChildren';
import { IsInternal } from 'components/share/UserType';
import { ChangeThemeComponent } from './ChangeTheme';
import * as e2e from 'components/Form/Inputs/e2e';
import {
  AccountNameLengthPref,
  DateInputFormatPref,
  DefaultHedgeTypePref,
  DefaultPriceTypePref,
  OneClickExecPref,
} from './selectPreferences';
import styled from 'styled-components';
import en from './locales/en.json';
import fr from './locales/fr.json';
import { usePreferences } from 'components/share/hooks/usePreferences';

const messagesMap: MessagesMap = { en, fr };

const userPrefTabs = ['general', 'notifications', 'cash', 'option', 'blotter'] as const;

type UserPrefTab = (typeof userPrefTabs)[number];

const menuItems: Record<UserPrefTab, JSX.Element> = {
  general: <FormattedMessage id="userPreferencesForm.general.label" />,
  cash: <FormattedMessage id="userPreferencesForm.cash.label" />,
  option: <FormattedMessage id="userPreferencesForm.option.label" />,
  blotter: <FormattedMessage id="userPreferencesForm.blotter.label" />,
  notifications: <FormattedMessage id="userPreferencesForm.notifications.label" />,
};

function UserPrefsByTab(): Record<UserPrefTab, React.ReactNode> {
  const { splitNotifications } = usePreferences();

  const userPrefsByTabConst = {
    general: (
      <>
        <p className="text-medium mt-4 mb-2">
          <FormattedMessage id="userPreferencesForm.section.display" />
        </p>

        <div className="card card-bordered">
          <ChangeThemeComponent />
          <DateInputFormatPref />
          <AccountNameLengthPref />
        </div>

        <p className="text-medium mt-4 mb-2">
          <FormattedMessage id="userPreferencesForm.section.interface" />
        </p>

        <div className="card card-bordered">
          <AutoCloseGrowlAfterPref />
          <OneClickExecPref />
        </div>
      </>
    ),

    cash: (
      <>
        <p className="text-medium mb-2">
          <FormattedMessage id="userPreferencesForm.section.display" />
        </p>

        <div className="card card-bordered">
          <ShowSwapPointsPref />
          <OrderQuickLimitPref />
        </div>

        <p className="text-medium mt-4 mb-2">
          <FormattedMessage id="userPreferencesForm.section.default" />
        </p>

        <div className="card card-bordered">
          <ResetTileToSpotOnQuoteExecutionPref tooltipId="userPreferencesForm.resetTileOnSuccessfulExecution.tooltip" />
          <DefaultNotionalPref />
        </div>
      </>
    ),

    option: (
      <>
        <p className="text-medium mb-2">
          <FormattedMessage id="userPreferencesForm.section.display" />
        </p>

        <div className="card card-bordered">
          <ShowDeltaStrikePref />
          <IsInternal>
            <OptionDisplayMidVolatilityPref />
          </IsInternal>
          <OptionOneWayQuotationPref />
          <OptionExpandGreekAndMktPref />
        </div>

        <p className="text-medium mt-4 mb-2">
          <FormattedMessage id="userPreferencesForm.section.default" />
        </p>

        <div className="card card-bordered">
          <DefaultPriceTypePref />
          <DefaultHedgeTypePref />
          <OptionStrategyGroupLegsPref />
        </div>

        <p className="text-medium mt-4 mb-2">
          <FormattedMessage id="userPreferencesForm.section.optionsBehaviour" />
        </p>

        <div className="card card-bordered">
          <OptionAlignCurrenciesPref />
        </div>
      </>
    ),

    blotter: (
      <div className="card card-bordered">
        <ShowMyMainDealsPref />
      </div>
    ),

    notifications: (
      <>
        <div className="card card-bordered">
          <SplitNotificationsPref />
        </div>

        {
          !splitNotifications
            ? (
              <div className="mt-2">
                <p className="text-medium mb-2">
                  <FormattedMessage id="userPreferencesForm.section.notifications"/>
                </p>

                <NotificationEmails notificationsType="default"/>
              </div>
            )
            : (
              <div className="mt-2">
                <p className="text-medium mb-2">
                  <FormattedMessage id="userPreferencesForm.spotForwardNdgSection.notifications"/>
                </p>

                <NotificationEmails notificationsType="cash"/>

                <p className="text-medium mb-2">
                  <FormattedMessage id="userPreferencesForm.orderSection.notifications"/>
                </p>

                <NotificationEmails notificationsType="orders"/>

                <p className="text-medium mb-2">
                  <FormattedMessage id="userPreferencesForm.optionsAccumulatorSection.notifications"/>
                </p>

                <NotificationEmails notificationsType="options"/>
              </div>
            )
        }
      </>
    ),
  };

  return userPrefsByTabConst as Record<UserPrefTab, React.ReactNode>;
}

const LeftColumn = styled.div.attrs({ className: 'border-end' })`
  min-width: 26%;
`;

const Body = styled.div.attrs({ className: 'd-flex border shadow-max p-0' })`
  max-height: 80vh;
`;

export function UserPreferencesContents(): JSX.Element {
  const SelectedTabContent = createSwitchChildren(
    'activeTab',
    'UserPrefsSelectedTabContent',
    UserPrefsByTab(),
  );

  const [activeTab, setActiveTab] = useState<UserPrefTab>('general');

  const changeTab = useCallback(
    (tab: UserPrefTab) => (e: React.MouseEvent) => {
      e.preventDefault();
      setActiveTab(tab);
    },
    [setActiveTab],
  );

  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <Body>
        <LeftColumn>
          <h3 className="p-3 px-md-4 text-truncate">
            <FormattedMessage id="modals.userPreferences.title" />
          </h3>

          <ListGroup tag="div">
            {strictEntries(menuItems).map(([key, label]) => (
              <ListGroupItem
                tag="a"
                href=""
                key={key}
                action
                active={key === activeTab}
                onClick={changeTab(key)}
                data-e2e={e2e.item(key)}
              >
                {label}
              </ListGroupItem>
            ))}
          </ListGroup>
        </LeftColumn>

        <div className="d-flex flex-column flex-grow-1">
          <h5 className="p-2 px-lg-4 py-lg-3 mt-1">{menuItems[activeTab]}</h5>

          <div className="overflow-y-auto p-2 px-lg-4 py-lg-3">
            <SelectedTabContent activeTab={activeTab} />
          </div>
        </div>
      </Body>
    </IntlComponentBoundary>
  );
}
