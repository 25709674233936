import { FormattedMessage, useIntl } from 'react-intl';
import type { Mifid2QuoteStatus } from 'state/mifidPanel';
import { connectMifidQuote } from './connect';
import { formatDateTimeWithLocale } from 'utils/dateFormats';

export interface MifidQuoteProps {
  quoteId: string;
  timestamp: string;
  status: Mifid2QuoteStatus;
}

const MifidQuoteRaw: React.FunctionComponent<MifidQuoteProps> = ({
  quoteId,
  timestamp,
  status,
}) => {
  const { locale } = useIntl();
  return (
    <span className={status === 'Failed' ? 'text-danger' : ''}>
      <FormattedMessage
        id="mifidPanel.quote"
        values={{
          id: quoteId,
          timestamp: formatDateTimeWithLocale(new Date(timestamp), locale),
          status,
        }}
      />
    </span>
  );
};

export const MifidQuote = connectMifidQuote(MifidQuoteRaw);
