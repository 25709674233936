import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import {
  type GlobalErrorState,
  GLOBAL_ERROR_AUTH_EXPIRED,
} from 'state/globalError/globalErrorModel';

export const globalErrorReducer: Reducer<GlobalErrorState> = (
  state: GlobalErrorState = {
    error: null,
    tileId: null,
    executionId: null,
    isOffline: false,
  },
  action: Action,
): GlobalErrorState => {
  switch (action.type) {
    case 'APP_CRASHED':
      const { error } = action;
      return {
        ...state,
        error: !state.error
          ? `${error?.stack ?? error ?? `internal error in ${action.source}`}`
          : state.error,
        tileId: state.tileId,
      };
    case 'AUTH_EXPIRED':
      return {
        ...state,
        error: GLOBAL_ERROR_AUTH_EXPIRED,
        tileId: state.tileId,
      };
    case 'NO_CLIENTS':
      return {
        ...state,
        error: 'NO_CLIENTS',
      };
    case 'NO_PRODUCTS':
      return {
        ...state,
        error: 'NO_PRODUCTS',
      };
    case 'USER_OFFLINE':
      return {
        ...state,
        isOffline: true,
      };
    case 'DISPLAY_STREAM_ERROR_DETAIL':
      return {
        ...state,
        error: state.error,
        tileId: action.tileId,
      };
    case 'DISPLAY_EXECUTION_ERROR_DETAIL':
      return {
        ...state,
        error: state.error,
        executionId: action.executionId,
      };
    case 'DISPLAY_PROPERTIES_ERROR_DETAIL':
      return {
        ...state,
        error: state.error,
        tileId: action.quoteId,
      };
    case 'CLOSE_ERROR_DETAIL':
      return {
        ...state,
        error: state.error,
        tileId: null,
        executionId: null,
      };
    default:
      return state;
  }
};
