import type {
  ESPPriceLadder,
  ESPPriceType,
  ESPClientPriceLadder,
  ESPTraderPriceLadder,
} from './espStreamsModel';
import type { TieringInfo } from 'state/share/tieringModel';

export type FxEspStreamAction =
  | EspSubscriptionRequestedEpic
  | EspRefcountReset
  | EspStartPricesReceptionEpic
  | EspTileStreamIdAndRefcountUpdated
  | EspPriceReceived
  | EspInvalidPriceReceived
  | EspHearbeatMissed
  | EspStreamUnsubscribe
  | EspStreamUnsubscriptionRequestSent
  | EspStreamTileUnsubscribe
  | EspSubscriptionRequestFailed
  | EspTieringRequestEpic
  | EspStreamKeyRequestPending
  | EspStreamReconnected
  | EspStreamsRefreshEpic;

export interface EspSubscriptionRequestedEpic {
  type: 'ESP_SUBSCRIPTION_REQUESTED_EPIC';
  tileId: string;
  tieringInfo: TieringInfo;
  streamKey: string;
  currencyPair: string;
  companyId: number;
  connectionId: string;
}

export function espSubscriptionRequestedEpic(
  tileId: string,
  tieringInfo: TieringInfo,
  streamKey: string,
  currencyPair: string,
  companyId: number,
  connectionId: string,
): EspSubscriptionRequestedEpic {
  return {
    type: 'ESP_SUBSCRIPTION_REQUESTED_EPIC',
    tileId,
    tieringInfo,
    streamKey,
    currencyPair,
    companyId,
    connectionId,
  };
}

export interface EspTieringRequestEpic {
  type: 'ESP_TIERING_REQUEST_EPIC';
  quoteId: string;
  currencyPair: string;
  companyId: number;
  tenor: string;
}

export function espTieringRequestEpic(
  quoteId: string,
  currencyPair: string,
  companyId: number,
  tenor: string,
): EspTieringRequestEpic {
  return {
    type: 'ESP_TIERING_REQUEST_EPIC',
    quoteId,
    currencyPair,
    companyId,
    tenor,
  };
}

export interface EspStreamKeyRequestPending {
  type: 'ESP_STREAM_KEY_REQUEST_PENDING';
  tileId: string;
}

export function espStreamKeyRequestPending(tileId: string): EspStreamKeyRequestPending {
  return {
    tileId,
    type: 'ESP_STREAM_KEY_REQUEST_PENDING',
  };
}

export interface EspStreamReconnected {
  type: 'ESP_STREAM_RECONNECTED';
  tileId: string;
  streamKey: string;
  isDefaultTIering: boolean;
}

export function espStreamReconnected(
  tileId: string,
  streamKey: string,
  isDefaultTIering: boolean,
): EspStreamReconnected {
  return {
    tileId,
    streamKey,
    type: 'ESP_STREAM_RECONNECTED',
    isDefaultTIering,
  };
}

export interface EspStreamsRefreshEpic {
  type: 'ESP_STREAMS_REFRESH_EPIC';
  connectionId: string;
  streamKeys: readonly string[];
}

export function espStreamsRefreshEpic(
  connectionId: string,
  streamKeys: readonly string[],
): EspStreamsRefreshEpic {
  return {
    type: 'ESP_STREAMS_REFRESH_EPIC',
    connectionId,
    streamKeys,
  };
}

export interface EspSubscriptionRequestFailed {
  type: 'ESP_SUBSCRIPTION_REQUEST_FAILED';
  tileId: string;
  error: string;
}

export function espSubscriptionRequestFailed(
  tileId: string,
  error: string,
): EspSubscriptionRequestFailed {
  return {
    type: 'ESP_SUBSCRIPTION_REQUEST_FAILED',
    tileId,
    error,
  };
}

export interface EspRefcountReset {
  type: 'ESP_REFCOUNT_RESET';
  streamId: string;
  tiering: string | null;
}

export function espRefcountReset(streamId: string, tiering: string | null): EspRefcountReset {
  return {
    type: 'ESP_REFCOUNT_RESET',
    streamId,
    tiering,
  };
}

export interface EspStartPricesReceptionEpic {
  type: 'ESP_START_PRICES_RECEPTION_EPIC';
  streamId: string;
}

export function espStartPricesReceptionEpic(streamId: string): EspStartPricesReceptionEpic {
  return {
    type: 'ESP_START_PRICES_RECEPTION_EPIC',
    streamId,
  };
}

export interface EspTileStreamIdAndRefcountUpdated {
  type: 'ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED';
  tileId: string;
  streamId: string;
  isDefaultTiering: boolean;
}

export function espTileStreamIdAndRefcountUpdated(
  tileId: string,
  streamId: string,
  isDefaultTiering: boolean,
): EspTileStreamIdAndRefcountUpdated {
  return {
    type: 'ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED',
    tileId,
    streamId,
    isDefaultTiering,
  };
}

interface ICashESPPriceReceived {
  type: 'ESP_PRICE_RECEIVED';
  streamId: string;
  quoteId: string;
  multipassInstanceId: string;
  priceDecodedTime: number;
  frontTimestamp: number;
  backtimestamp: number;
}

interface ICashESPTraderPriceReceived extends ICashESPPriceReceived {
  priceType: 'ESP.TRADER.PRICE';
  ladders: ESPTraderPriceLadder;
}

interface ICashESPClientPriceReceived extends ICashESPPriceReceived {
  priceType: 'ESP.CLIENT.PRICE';
  ladders: ESPClientPriceLadder;
}

export type EspPriceReceived = ICashESPClientPriceReceived | ICashESPTraderPriceReceived;

export function espPriceReceived(
  streamId: string,
  quoteId: string,
  multipassInstanceId: string,
  ladders: ESPPriceLadder,
  priceDecodedTime: number,
  frontTimestamp: number,
  backtimestamp: number,
  priceType: ESPPriceType = 'ESP.TRADER.PRICE',
) {
  return {
    type: 'ESP_PRICE_RECEIVED',
    quoteId,
    multipassInstanceId,
    streamId,
    ladders,
    priceType,
    priceDecodedTime,
    frontTimestamp,
    backtimestamp,
  } as EspPriceReceived;
}

interface EspInvalidPriceReceived {
  type: 'ESP_INVALID_PRICE_RECEIVED';
  streamId: string;
}

export function espInvalidPriceReceived(streamId: string) {
  return {
    type: 'ESP_INVALID_PRICE_RECEIVED',
    streamId,
  };
}

interface EspHearbeatMissed {
  type: 'ESP_HEARTBEAT_MISSED';
  streamId: string;
}

export function espHeartbeatMissed(streamId: string): EspHearbeatMissed {
  return {
    type: 'ESP_HEARTBEAT_MISSED',
    streamId,
  };
}

export interface EspStreamUnsubscribe {
  type: 'ESP_STREAM_UNSUBSCRIBE_EPIC';
  cashId: string;
  streamKey: string;
  connectionId: string;
}

export function espStreamUnsubscribe(
  cashId: string,
  connectionId: string,
  streamKey: string,
): EspStreamUnsubscribe {
  return {
    type: 'ESP_STREAM_UNSUBSCRIBE_EPIC',
    cashId,
    streamKey,
    connectionId,
  };
}

export interface EspStreamUnsubscriptionRequestSent {
  type: 'ESP_STREAM_UNSUBSCRIPTION_REQUEST_SENT';
  tileId: string;
  espStreamId: string;
}

export function espStreamUnsubscriptionRequestSent(
  tileId: string,
  espStreamId: string,
): EspStreamUnsubscriptionRequestSent {
  return {
    type: 'ESP_STREAM_UNSUBSCRIPTION_REQUEST_SENT',
    tileId,
    espStreamId,
  };
}

export interface EspStreamTileUnsubscribe {
  type: 'ESP_STREAM_TILE_UNSUBSCRIBE';
  tileId: string;
  streamKey: string;
}

export function espStreamTileUnsubscribe(
  tileId: string,
  streamKey: string,
): EspStreamTileUnsubscribe {
  return {
    type: 'ESP_STREAM_TILE_UNSUBSCRIBE',
    tileId,
    streamKey,
  };
}
