import { makeValuesPredicate } from '@sgme/fp';
import { logger } from 'logging/logger';
import { type ReactNode, useEffect, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { BUS_TOPIC_GLOBALLANGUAGE, getWidgetBus } from './helpers';

const supportedLocales = ['en', 'fr'] as const;
type SupportedLocale = (typeof supportedLocales)[number];

export const langKey = 'sgmeFxLang';

const isSupportedLocale = makeValuesPredicate(supportedLocales);

interface IntlProviderProps {
  locale: string;
  globalMessagesMap: Record<string, Record<string, string>>;
  children: ReactNode;
}

export function IntlProvider({ locale, globalMessagesMap, children }: IntlProviderProps) {
  const [currentLocale, setCurrentLocale] = useState<SupportedLocale>(
    isSupportedLocale(locale) ? locale : 'en',
  );

  // Use the SGWT Widgets bus to be aware of the modification of the language,
  // which is triggered by the <sgwt-account-center> widget.
  useEffect(() => {
    const bus = getWidgetBus();
    if (bus) {
      const languageSubscription = bus.subscribe<string>(BUS_TOPIC_GLOBALLANGUAGE, lang => {
        if (lang && supportedLocales.includes(lang.toLowerCase() as SupportedLocale)) {
          setCurrentLocale(lang.toLowerCase() as SupportedLocale);
          document.querySelector('html')!.lang = lang;
          if (localStorage) {
            try {
              localStorage.setItem(langKey, lang);
            } catch {
              logger.logError('Unable to store language, localStorage might be full');
            }
          }
        }
      });
      return () => {
        bus.unsubscribe(languageSubscription);
      };
    }
  }, []);

  return (
    <ReactIntlProvider
      locale={currentLocale}
      defaultLocale="en"
      messages={globalMessagesMap[currentLocale]}
    >
      {children}
    </ReactIntlProvider>
  );
}
