import type { ImmutableCollection } from 'typings/utils';

export function getElementById<T>(
  collection: ImmutableCollection<T>,
  itemId: string,
): T | undefined {
  return collection[itemId];
}

/**
 * @deprecated use assertIsDefined instead
 * @param value Value to check if it is undefined
 * @param throwMsg Message to include in the error thrown when value is undefined
 */
export function throwIfUndefined<T>(value: T | undefined, throwMsg: string): T {
  if (value === undefined) {
    throw new Error(throwMsg);
  }
  return value;
}
