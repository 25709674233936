import type * as React from 'react';
import { connect, type MapStateToPropsParam, type MapDispatchToProps } from 'react-redux';
import type { Dispatch } from 'redux';
import type { RenderPropChildren } from 'typings/utils';

type RenderPropFunctionComponent<P, T = P> = React.FunctionComponent<RenderPropChildren<T> & P>;

interface DispatchProps {
  dispatch: Dispatch<any>;
}

function _renderPropsConnect<TStateProps, TDispatchProps, TOwnProps, State>(
  mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
  mapDispatchToProps: MapDispatchToProps<TDispatchProps, TOwnProps>,
): RenderPropFunctionComponent<TOwnProps, TStateProps & TDispatchProps & TOwnProps> {
  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(({ children, ...rest }: any) => children(rest)) as any;
}

export interface RenderPropsConnect {
  (): RenderPropFunctionComponent<{}, DispatchProps>;

  <TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = {}>(
    mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
  ): RenderPropFunctionComponent<TOwnProps, TStateProps & TOwnProps & DispatchProps>;

  <TDispatchProps = {}, TOwnProps = {}>(
    mapStateToProps: null | undefined,
    mapDispatchToProps: MapDispatchToProps<TDispatchProps, TOwnProps>,
  ): RenderPropFunctionComponent<TOwnProps, TDispatchProps & TOwnProps & DispatchProps>;

  <TStateProps, TDispatchProps, TOwnProps, State>(
    mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
    mapDispatchToProps: MapDispatchToProps<TDispatchProps, TOwnProps>,
  ): RenderPropFunctionComponent<
    TOwnProps,
    TStateProps & TDispatchProps & TOwnProps & DispatchProps
  >;
}

export const renderPropsConnect: RenderPropsConnect = _renderPropsConnect as any;
