import { getRelevantPriceFromBidAsk } from 'state/share/productModel/utils';
import type { Way } from 'state/share/productModel/litterals';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type { IFxSwapExecutionData } from 'state/fxSwaps/fxSwapsModel';
import { assertIsDefined } from '@sgme/fp';

type MySelectorKeys =
  | 'getSwapState'
  | 'getSwapStreamCurrencyPrecision'
  | 'getCurrencyPrecision'
  | 'getClientForTile'
  | 'getDisplayNearPrice'
  | 'getDisplaySwapPointsAllIn'
  | 'getDisplayFarPrice'
  | 'getSwapIsNonDeliverable'
  | 'getSwapProductName';

type MySelectors = Pick<Selectors, MySelectorKeys>;

export const swapRfsExecutionWith: MapStateToMetadataHOF<
  IFxSwapExecutionData,
  { swapId: string; way: Way; tradeDate: Date },
  AppState,
  MySelectors
> =
  sl =>
  (state, { swapId, way, tradeDate }) => {
    const {
      values: {
        nearAmount: nearNotionalAmount,
        farAmount: farNotionalAmount,
        nearPaymentDate,
        farPaymentDate,
        amountCurrency,
        currencyPair,
        currency1,
        currency2,
      },
    } = sl.getSwapState(state, swapId);
    const precision = sl.getSwapStreamCurrencyPrecision(state, swapId);

    assertIsDefined(nearNotionalAmount, 'nearAmount needs to be defined for execution');
    assertIsDefined(farNotionalAmount, 'farAmount needs to be defined for execution');
    assertIsDefined(nearPaymentDate, 'nearPaymentDate needs to be defined for execution');
    assertIsDefined(farPaymentDate, 'farPaymentDate needs to be defined for execution');
    assertIsDefined(amountCurrency, 'amountCurrency needs to be defined for execution');
    assertIsDefined(currency1, 'currency1 needs to be defined for execution');
    assertIsDefined(currency2, 'currency2 needs to be defined for execution');
    assertIsDefined(currencyPair, 'currencyPair needs to be defined for execution');
    assertIsDefined(precision, 'precision needs to be defined for execution');

    const notionalCurrency = amountCurrency === 1 ? currency1 : currency2;

    const client = sl.getClientForTile(state, swapId);

    const nearPrice = sl.getDisplayNearPrice(state, swapId).value;
    const farPrice = sl.getDisplayFarPrice(state, swapId).value;

    assertIsDefined(nearPrice, 'nearPrice should be defined for execution');
    assertIsDefined(farPrice, 'farPrice should be defined for execution');

    const allInPrice = sl.getDisplaySwapPointsAllIn(state, swapId);
    const productName = sl.getSwapProductName(state, swapId);

    const executionSummary: IFxSwapExecutionData = {
      instrument: 'Swap',
      productName,
      currencyPair,
      way,
      swapPoints: getRelevantPriceFromBidAsk(allInPrice.points, way),
      nearNotionalAmount,
      farNotionalAmount,
      notionalCurrency,
      nearDate: new Date(nearPaymentDate),
      farDate: new Date(farPaymentDate),
      nearPrice: getRelevantPriceFromBidAsk(nearPrice, way),
      farPrice: getRelevantPriceFromBidAsk(farPrice, way),
      client,
      tradeDate,
      precision: precision! + 2,
    };
    return executionSummary;
  };
