import { LogBuilder } from '@sgme/logger';

function getLogUrl() {
  const baseUrl = window.sgmeConfiguration.apiUrl;
  if (baseUrl.endsWith('/')) {
    return baseUrl + 'api/log';
  }
  return baseUrl + '/api/log';
}

const isNode =
  typeof process === 'object' && Boolean(process.versions) && Boolean(process.versions.node);

let logBuilder = LogBuilder();
let crashLogBuilder = LogBuilder();
if (!isNode) {
  if (process.env.NODE_ENV === 'production') {
    logBuilder = logBuilder
      .enableServerLogs({
        url: getLogUrl(),
        prefix: 'FX ',
        storeName: 'FX',
        scheduler: {
          defaultInterval: 10_000,
          batchSize: 500,
          range: {
            min: 5_000,
            max: 20_000,
          },
          retryPolicy: 'dropOnFail',
        },
      })
      .setAuthTokenFactory(() => (window as any)?.sgwtConnect?.getAuthorizationHeader());

    crashLogBuilder = crashLogBuilder
      .enableServerLogs({
        url: getLogUrl(),
        prefix: 'FX ',
        storeName: 'Crash-app',
        scheduler: {
          defaultInterval: 1_000,
          batchSize: 500,
          range: {
            min: 5_000,
            max: 20_000,
          },
          retryPolicy: 'retryOnFail',
        },
      })
      .setAuthTokenFactory(() => (window as any)?.sgwtConnect?.getAuthorizationHeader());
  } else {
    logBuilder = logBuilder.enableConsole({ minLevel: 'WARN' });
    crashLogBuilder = crashLogBuilder.enableConsole({minLevel: "ERROR"});
  }
}

export const logger = logBuilder.build();
export const crashLogger = crashLogBuilder.build();
