import { getBulkMetadata } from './factories';

export const getBulkLegs = getBulkMetadata('legs');
export const getBulkStep = getBulkMetadata('step');
export const getBulkSelectedLegs = getBulkMetadata('selectedLegs');
export const getBulkExcludedLegs = getBulkMetadata('excludedLegs');
export const getBulkLastLegIdRequested = getBulkMetadata('lastLegIdRequested');
export const getBulkTradeCaptureCurrentSessionId = getBulkMetadata('currentSessionId');
export const getBulkTradeCaptureIdVersion = getBulkMetadata('tradeCaptureIdVersion');
export const getBulkPropertiesRequested = getBulkMetadata('propertiesRequested');
export const getBulkPropertiesRequestedError = getBulkMetadata('propertiesRequestError');
export const getBulkIsPriceable = getBulkMetadata('isPriceable');
export const getBulkCurrentStreamId = getBulkMetadata('currentStreamId');
export const getBulkLastStreamError = getBulkMetadata('lastStreamError');
