import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';

export interface AccountChangedConnectOwnProps {
  quoteId: string;
}

export interface AccountChangedConnectStateProps {
  accountName: string | null;
}

export type AccountChangedConnectSelectorsKeys =
  | 'getTileOverriddenClientId'
  | 'getUserClientById'
  | 'getClientName';
export type AccountChangedConnectSelectors = Pick<Selectors, AccountChangedConnectSelectorsKeys>;

export const mapStateToPropsAccountChanged: MapStateToPropsHOF<
  AccountChangedConnectStateProps,
  AccountChangedConnectOwnProps,
  AppState,
  AccountChangedConnectSelectors
> =
  sl =>
  (state, { quoteId }) => {
    const accountId = sl.getTileOverriddenClientId(state, quoteId);
    if (accountId === null) {
      return {
        accountName: null,
      };
    }

    const client = sl.getUserClientById(state, accountId);
    const accountName = client ? sl.getClientName(state, client) : accountId;
    return {
      accountName,
    };
  };
