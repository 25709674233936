import { default as html2canvas, type Options } from 'html2canvas';
import { isDev } from '../../configuration';
import { logger } from '../logging/logger';

export const takeScreenshotHOF =
  (html2canvasParam: typeof html2canvas, element: HTMLElement) => () => {
    const opts: Partial<Options> = {
      allowTaint: false,
      logging: isDev,
      useCORS: true,
      ignoreElements: (htmlElement: Element) =>
        (htmlElement instanceof HTMLImageElement &&
          htmlElement.src.toLowerCase().includes('piwik')) ||
        htmlElement.id === 'HelpRequestModal',
    };
    // eslint-disable-next-line no-console
    console.info('Will generate screenshot for help request');
    return html2canvasParam(element, opts)
      .then((canvas) => {
        // eslint-disable-next-line no-console
        console.info('Did generate screenshot');
        return canvas.toDataURL('image/png');
      })
      .catch((reason) => {
        logger.logError(
          'Could not generate screenshot for help request {message_s}',
          reason.message,
        );
        return undefined;
      });
  };

let _takeScreenshot: () => Promise<string | undefined>;

export const takeScreenshot = () => {
  if (_takeScreenshot === undefined) {
    _takeScreenshot = takeScreenshotHOF(html2canvas, document.body);
  }

  return _takeScreenshot();
};

export interface ITakeOptions {
  element: HTMLElement;
  shouldIgnoreElement: (element: Element) => boolean;
}

export function take({ element, shouldIgnoreElement }: ITakeOptions): Promise<HTMLCanvasElement> {
  return html2canvas(element, {
    logging: true,
    ignoreElements: (htmlElement): boolean => {
      if (htmlElement instanceof HTMLImageElement && htmlElement.src.toLowerCase().includes('piwik')) {
        return true;
      }

      return shouldIgnoreElement(htmlElement);
    },
  });
}