import type { Thunk } from 'state';
import type { QuoteContributionType } from 'state/share/productModel/litterals';
import type { IHedge, IQuote } from '../model/optionsStreams';

export function optionStreamRequestedThunk(
  optionId: string,
  forceTradable: boolean,
  bypassCheck: boolean,
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(ac.optionRfsCancelThunk(optionId));
    dispatch(ac.optionStreamRequested(optionId, forceTradable, bypassCheck));
  };
}

export function optionStreamRefreshThunk(
  optionId: string,
  isReadyToPrice: boolean | null,
  isPriceObsolete: boolean,
  isProductObsolete: boolean,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    const currentStreamId = sl.getTileCurrentStreamId(state, optionId);

    if (currentStreamId !== null) {
      if (isReadyToPrice && !isPriceObsolete && !isProductObsolete) {
        // When TC replies ready to price but price/product aren't obsolete, we do nothing
        return;
      }
      if (isProductObsolete) {
        dispatch(ac.optionRfsCancelThunk(optionId, false));
      } else if (isPriceObsolete && isReadyToPrice) {
        dispatch(ac.optionStreamUpdated(optionId, currentStreamId));
      }
    }

    const isSolvable = sl.isOneLegSolvableInOption(state, optionId);
    const isSolvingPremium = sl.isAllLegsSolvingInOption(state, optionId, 'PremiumPaymentAmount');
    const isSolvingStrike = sl.isAllLegsSolvingInOption(state, optionId, 'Strike');

    const isObsolete = !isPriceObsolete || isProductObsolete;
    const isValidDate = sl.isValidDateOptionInAllLegs(state, optionId);

    if (isReadyToPrice && isSolvable && isSolvingPremium && isObsolete && isValidDate) {
      dispatch(ac.optionStreamRequested(optionId, false, false));
    }
    if (isReadyToPrice && isSolvable && isSolvingStrike) {
      dispatch(ac.optionSolvingRequested(optionId));
    }
  };
}

export function optionRfsCancelThunk(optionId: string, forceKeepExpired?: false): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const currentStreamId = sl.getTileCurrentStreamId(state, optionId);
    if (currentStreamId !== null) {
      const shouldKeepPrice =
        forceKeepExpired !== undefined
          ? forceKeepExpired
          : sl.getOptionStreamStatus(state, currentStreamId) === 'PRICING';

      dispatch(ac.optionStreamCanceled(optionId, currentStreamId, shouldKeepPrice));
    }
    dispatch(ac.optionRfsClearError(optionId));
  };
}

export function optionQuoteReceivedThunk(
  optionId: string,
  streamId: string,
  quote: IQuote<readonly IHedge[]>,
  tiering: string | null,
  bypassCheck: boolean,
  quoteContribution: QuoteContributionType | null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (!sl.isQuotePresentWithInstrument(state, optionId, 'Option')) {
      return;
    }
    const lastStreamId = sl.getOptionLastStreamId(state, optionId);
    if (lastStreamId !== null) {
      dispatch(ac.optionStreamLastRemoved(optionId, lastStreamId));
    }
    dispatch(
      ac.optionQuoteReceived(optionId, streamId, quote, tiering, bypassCheck, quoteContribution),
    );
  };
}

export function optionTraderNotificationReceivedThunk(
  optionId: string,
  streamId: string,
  traderId: string | null,
  quoteContribution: QuoteContributionType | null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (!sl.isQuotePresentWithInstrument(state, optionId, 'Option')) {
      return;
    }
    const lastStreamId = sl.getOptionLastStreamId(state, optionId);
    if (lastStreamId !== null) {
      dispatch(ac.optionStreamLastRemoved(optionId, lastStreamId));
    }
    dispatch(ac.optionTraderNotificationReceived(optionId, streamId, traderId, quoteContribution));
  };
}
