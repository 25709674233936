import type { MapStateToPropsFactoryHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import { createSelector } from 'reselect';
import type { Client } from 'state/referenceData/referenceDataModel';
import { strictValues } from 'utils/object';

export interface TabPopoverConnectOwnProps {
  tabId: string;
}

export interface TabPopoverConnectStateProps {
  pinned: readonly number[];
  clients: readonly Client[];
}

export interface TabPopoverConnectDispatchProps {
  onClientChange(companyId: string): void;
}

// you can whitelist selectors after implementation
export type TabPopoverConnectSelectors = Selectors;
/*
export type TabPopoverConnectSelectorsKeys = 'aSelector'; // example
export type TabPopoverConnectSelectors = Pick<Selectors, TabPopoverConnectSelectorsKeys>;
*/

export const mapStateToPropsTabPopover: MapStateToPropsFactoryHOF<
  TabPopoverConnectStateProps,
  TabPopoverConnectOwnProps,
  AppState,
  TabPopoverConnectSelectors
> = sl => () => {
  const getClients = createSelector([sl.getAllUserClients], strictValues);

  return state => {
    const pinned = state.referenceData.pinnedCompanies;

    return {
      pinned,
      clients: getClients(state),
    };
  };
};

// you can whitelist action creators after implementation
export type TabPopoverConnectActionCreators = ActionCreators;
/*
export type TabPopoverConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type TabPopoverConnectActionCreators = Pick<ActionCreators, TabPopoverConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsTabPopover: MapDispatchToPropsHOF<
  TabPopoverConnectDispatchProps,
  TabPopoverConnectOwnProps,
  TabPopoverConnectActionCreators
> =
  ac =>
  (dispatch, { tabId }) => ({
    onClientChange: (companyId: string) =>
      dispatch(ac.clientWorkspaceClientChangedThunk(tabId, companyId)),
  });
