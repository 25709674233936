import { selectors } from 'state/selectors';
import { metaSelectorTradeCaptureToBackendWith } from './tradeCaptureToBackend';
import {
  legAddToPatchWith,
  legChangedToPatchWith,
  legRemovedToPatchWith,
  bulkPropertyChangedToPatchWith,
  bulkResetToPatchWith,
  bulkImportedToPatchWith,
  legExcludedToPatchWith,
} from './tradeCapturePatchBuilderFromAction';
import { metaSelectorTradeCaptureFromBackendWith } from './tradeCaptureFromBackend';
import { metaSelectorQuoteRequestBuilderWith } from './buildQuoteRequest';
import { metaSelectorQuoteExecutionBuilderWith } from './buildQuoteExecution';

export const bulkResponseToPatchMetaSelector = metaSelectorTradeCaptureFromBackendWith(selectors);

export const createBulkTradeCaptureRequest = metaSelectorTradeCaptureToBackendWith(selectors);

export const bulkLegAddedToPatch = legAddToPatchWith(selectors);

export const bulkLegChangedToPatch = legChangedToPatchWith(selectors);

export const bulkLegRemovedToPatch = legRemovedToPatchWith(selectors);

export const bulkLegsExcludedToPatch = legExcludedToPatchWith(selectors);

export const bulkResetToPatch = bulkResetToPatchWith(selectors);

export const bulkPropertyChangedToPatch = bulkPropertyChangedToPatchWith(selectors);

export const bulkImportedToPatch = bulkImportedToPatchWith(selectors);

export const bulkBuildQuoteRequest = metaSelectorQuoteRequestBuilderWith(selectors);

export const bulkQuoteExecution = metaSelectorQuoteExecutionBuilderWith(selectors);
