export interface OptionStrippingOpened {
  type: 'OPTION_STRIPPING_OPENED';
  quoteId: string;
}

export function optionStrippingOpened(quoteId: string): OptionStrippingOpened {
  return {
    type: 'OPTION_STRIPPING_OPENED',
    quoteId,
  };
}

export interface OptionStrippingClosed {
  type: 'OPTION_STRIPPING_CLOSED';
  quoteId: string;
}

export function optionStrippingClosed(quoteId: string): OptionStrippingClosed {
  return {
    type: 'OPTION_STRIPPING_CLOSED',
    quoteId,
  };
}

export interface OptionStrippingRequested {
  type: 'OPTION_STRIPPING_REQUESTED';
  quoteId: string;
  frequency: 'Daily' | 'Weekly' | 'Monthly';
  startDate: string;
  iterations: number | undefined;
  endDate: string | undefined;
}

export function optionStrippingRequested(
  quoteId: string,
  frequency: 'Daily' | 'Weekly' | 'Monthly',
  startDate: string,
  iterations: number | undefined,
  endDate: string | undefined,
): OptionStrippingRequested {
  return {
    type: 'OPTION_STRIPPING_REQUESTED',
    quoteId,
    frequency,
    startDate,
    iterations,
    endDate,
  };
}

export interface OptionStrippingReceived {
  type: 'OPTION_STRIPPING_RECEIVED';
  quoteId: string;
}

export function optionStrippingReceived(quoteId: string): OptionStrippingReceived {
  return {
    type: 'OPTION_STRIPPING_RECEIVED',
    quoteId,
  };
}

export interface OptionStrippingFailed {
  type: 'OPTION_STRIPPING_FAILED';
  quoteId: string;
  error: string;
}

export function optionStrippingFailed(quoteId: string, error: string): OptionStrippingFailed {
  return {
    type: 'OPTION_STRIPPING_FAILED',
    quoteId,
    error,
  };
}
