import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { assertUnreachable } from '@sgme/fp';
import { tileContainerPadding } from 'styles/constants';

import { withQuoteId } from '../contexts';
import { EmphasizedIcon } from './EmphasizedIcon';
import * as e2e from 'components/Form/Inputs/e2e';
import { IsInternal } from './UserType/IsInternal';
import type { ModeStatus, OrderResultMode } from 'state/blotter/blotterEntryModel';
import { connectOrderOverlay } from './connect';

const Overlay = styled.div`
  top: ${tileContainerPadding}px;
  bottom: ${tileContainerPadding}px;
  left: ${tileContainerPadding}px;
  right: ${tileContainerPadding}px;
  z-index: 8;
`;

interface ExecutionOverlayProps {
  modeStatus: ModeStatus;
  mode: OrderResultMode;
  quoteId: string;
  onDismiss(): void;
  onViewLog(): void;
  onClose(): void;
}

const OrderResultOverlayRaw: React.FunctionComponent<ExecutionOverlayProps> = ({
  mode,
  modeStatus,
  onDismiss,
  onViewLog,
  onClose,
}) => {
  if (modeStatus === 'Idle') {
    return null;
  }

  const color = modeStatus === 'Error' ? 'danger' : modeStatus === 'Success' ? 'info' : 'warning';
  return (
    <Overlay
      className={`d-flex flex-column align-items-center justify-content-center py-3 position-absolute bg-lvl1 text-${color}`}
      data-e2e={e2e.overlay('orderStatus')}
      data-e2e-status={modeStatus}
    >
      <div className="mb-3">
        <EmphasizedIcon icon={getSymbolForStatus(modeStatus)} color={color} />
      </div>
      <h4 data-e2e="order-status-text">
        <FormattedMessage id={`OrderResult.${mode}.status.${modeStatus}.title`} />
      </h4>
      <p className="text-secondary">
        <FormattedMessage id={`OrderResult.${mode}.status.${modeStatus}.message`} />
      </p>
      {modeStatus === 'Success' && (
        <>
          <p className="text-secondary mx-5 text-center">
            {<FormattedMessage id="OrderResult.Display.status.Success.unableToLoad" />}
          </p>
          <button onClick={onClose} className="btn btn-flat d-block my-2">
            <FormattedMessage id="OrderResult.Display.status.close" />
          </button>
        </>
      )}
      {modeStatus === 'Error' && (
        <div className="p-1">
          <button className="btn btn-flat d-block my-2" onClick={onDismiss} data-e2e="dismiss-btn">
            <FormattedMessage id="OrderResult.Display.status.dismiss" />
          </button>
          <IsInternal>
            <button className="btn btn-danger btn-sm" onClick={onViewLog} data-e2e="view-log-btn">
              <FormattedMessage id="OrderResult.Display.status.viewLog" />
            </button>
          </IsInternal>
        </div>
      )}
    </Overlay>
  );
};

const getSymbolForStatus = (status: Exclude<ModeStatus, 'Idle'>) => {
  switch (status) {
    case 'Pending':
    case 'StillPending':
      return 'hourglass_empty';
    case 'Error':
    case 'Timeouted':
      return 'warning';
    case 'Success':
      return 'check';
    default:
      assertUnreachable(status, 'Execution status not handled');
  }
};

export const OrderResultOverlay = withQuoteId(connectOrderOverlay(OrderResultOverlayRaw));
