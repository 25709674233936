import { UserType } from '.';

interface IsInternalSalesProps {
  children: React.ReactNode;
}

export function IsInternalSales({ children }: IsInternalSalesProps) {
  return (
    <UserType>
      {({ userType }) =>
        userType === 'Internal Sales' ? (children as React.ReactElement<any>) : null
      }
    </UserType>
  );
}
