import type { Reducer } from 'redux';
import type { BlotterData } from '../blotterModel';
import type { BlotterEntry } from '../blotterEntryModel';
import type { Collection } from 'typings/utils';
import type { Action } from 'state/actions';

const initialState: BlotterData = {};

export const historicalTradesReducer: Reducer<BlotterData> = (
  blotterData: BlotterData = initialState,
  action: Action,
): BlotterData => {
  switch (action.type) {
    case 'BLOTTER_RETRIEVE_HISTORICAL_TRADES':
    case 'BLOTTER_HISTORICAL_TRADES_REQUEST_FAILED':
      return initialState;
    case 'BLOTTER_HISTORICAL_TRADES_RECEIVED':
      return action.trades.reduce((acc, val) => {
        acc[val.id] = val;
        return acc;
      }, {} as Collection<BlotterEntry>);
  }
  return blotterData;
};
