import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

export interface TabMenuItemConnectOwnProps {
  tabId: string;
}

export interface TabMenuItemConnectDispatchProps {
  onSwitchToTab(): void;
}

// you can whitelist action creators after implementation
export type TabMenuItemConnectActionCreators = ActionCreators;
/*
export type TabMenuItemConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type TabMenuItemConnectActionCreators = Pick<ActionCreators, TabMenuItemConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsTabMenuItem: MapDispatchToPropsHOF<
  TabMenuItemConnectDispatchProps,
  TabMenuItemConnectOwnProps,
  TabMenuItemConnectActionCreators
> =
  ac =>
  (dispatch, { tabId }) => ({
    onSwitchToTab() {
      dispatch(ac.clientWorkspaceTabSwitched(tabId));
    },
  });
