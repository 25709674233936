import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import { extract } from '../utils/object';

interface FixingReference {
  FixingId: string;
  CurrencyPair: null;
  FixingPage: string;
  FixingHour: string;
  FixingPlace: string;
  MediaName: string;
}

export interface FixingReferenceApi {
  getFixingReferences: (
    asset: string,
    currencyPair: string,
    counterpartyBdrId: string,
  ) => Observable<Array<FixingReference>>;
}

export const fixingReferenceApi = (http: ISgmeHttp): FixingReferenceApi => ({
  getFixingReferences(asset: string, currencyPair: string, counterpartyBdrId: string) {
    const params = new URLSearchParams({
      currencyPair,
      counterpartyBdrId,
    });

    return http
      .getJSON<Array<FixingReference>>(`api/ReferentialData/v2/${asset}/FixingReferences?${params}`)
      .pipe(map(extract('payload')));
  },
});
