import { updater1 } from './updater1/updater1';
import { updater2 } from './updater2/updater2';
import { updater3 } from './updater3/updater3';
import { updater4 } from './updater4/updater4';
import { updater5 } from './updater5/updater5';
import { updater6 } from './updater6/updater6';
import { updater7 } from './updater7/updater7';
import { updater8 } from './updater8/updater8';
import { updater9 } from './updater9/updater9';

/**
 * updaters holds an array of updaters sorted in increasing ordered based on the SavedWorkspace
 * version they can update. Each updater holds its own model, this ensure we can easily track the
 * changes in the model and that the typing of the saved workspace received is not couple to
 * the typing of our redux state. Updater models not being shared allows also to delete a whole
 * updater folder if every users updated
 */

export const updaters = [
  updater1,
  updater2,
  updater3,
  updater4,
  updater5,
  updater6,
  updater7,
  updater8,
  updater9,
] as const;
