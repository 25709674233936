import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';
import type { Selectors } from 'state/selectors';

export interface TileMenuConnectOwnProps {
  quoteId: string;
}

export type TileMenuConnectSelectorsKeys =
  | 'getQuoteInstrument'
  | 'getOptionLeg'
  | 'getOptionQuote'
  | 'getLegIdsOfMultilegOfOption'
  | 'getOptionDisplayPriceType'
  | 'getSwapStreamStatus'
  | 'getSwapState'
  | 'getSwapRfsStreamQuote'
  | 'getDisplayNearPrice'
  | 'getDisplayFarPrice'
  | 'getDisplayNearPoints'
  | 'getDisplayFarPoints'
  | 'getDisplaySwapPointsAllIn'
  | 'getDisplayMidRateAndMidPts';

export type TileMenuConnectSelectors = Pick<Selectors, TileMenuConnectSelectorsKeys>;

export interface TileMenuConnectDispatchProps {
  closeTile(): void;

  duplicateTile(): void;

  resetTile(): void;
}

export type TileMenuConnectActionCreatorsKeys = 'tileClosedThunk' | 'tileDuplicateThunk' | 'partialResetTileThunk';

export type TileMenuConnectActionCreators = Pick<ActionCreators, TileMenuConnectActionCreatorsKeys>;

export const mapDispatchToPropsTileMenu: MapDispatchToPropsHOF<
  TileMenuConnectDispatchProps,
  TileMenuConnectOwnProps,
  TileMenuConnectActionCreators
> =
  ac =>
  (dispatch, { quoteId }) => ({
    closeTile: () => {
      dispatch(ac.tileClosedThunk(quoteId));
    },
    duplicateTile() {
      dispatch(ac.tileDuplicateThunk(quoteId));
    },
    resetTile() {
      dispatch(ac.partialResetTileThunk(quoteId));
    },
  });
