import { v4 } from 'uuid';
import type { Toast } from './toastsModel';

export const autoHideToastDelay = 5_000;

export type ToastsActions = AddToastAction | RemoveToastAction;

export interface AddToastAction {
  type: 'ADD_TOAST';
  toast: Toast;
  id: string;
}

export const addToastAction = (toast: Toast, id: string = v4()): AddToastAction => ({
  type: 'ADD_TOAST',
  toast,
  id,
});

export interface RemoveToastAction {
  type: 'REMOVE_TOAST';
  id: string;
}

export const removeToastAction = (id: string): RemoveToastAction => ({
  type: 'REMOVE_TOAST',
  id,
});
