import type { Thunk } from 'state';
import { fieldData } from 'utils/fieldSelectors';
import type { BulkLineProduct } from '../fxBulksModel';

export function bulkLegWayChanged(quoteId: string, legId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { data: prevWay } = fieldData(sl.getBulkLegWay(state, quoteId, legId));
    if (prevWay === 'Buy') {
      dispatch(ac.bulkLegPropertyChanged(quoteId, legId, { way: 'Sell' }));
    } else if (prevWay === 'Sell') {
      dispatch(ac.bulkLegPropertyChanged(quoteId, legId, { way: 'Buy' }));
    }
  };
}

export function bulkLegIsUnevenChanged(quoteId: string, legId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { data: prevIsUneven } = fieldData(sl.getBulkLegIsUneven(state, quoteId, legId));
    dispatch(ac.bulkLegPropertyChanged(quoteId, legId, { isUneven: !prevIsUneven }));
  };
}

export function bulkLegProductChanged(
  quoteId: string,
  legId: string,
  product: BulkLineProduct,
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(ac.bulkLegReset(quoteId, legId));
    dispatch(ac.bulkLegPropertyChanged(quoteId, legId, { product }));
  };
}
