import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { BlotterMode } from 'state/blotter/blotterModel';

export interface HistoricalToggleConnectOwnProps {}

export interface HistoricalToggleConnectStateProps {
  mode: BlotterMode;
}

export interface HistoricalToggleConnectDispatchProps {
  switchMode(mode: BlotterMode): void;
}

// you can whitelist selectors after implementation
export type HistoricalToggleConnectSelectors = Selectors;
/*
export type HistoricalToggleConnectSelectorsKeys = 'aSelector'; // example
export type HistoricalToggleConnectSelectors = Pick<Selectors, HistoricalToggleConnectSelectorsKeys>;
*/

export const mapStateToPropsHistoricalToggle: MapStateToPropsHOF<
  HistoricalToggleConnectStateProps,
  HistoricalToggleConnectOwnProps,
  AppState,
  HistoricalToggleConnectSelectors
> = sl => (state, _ownProps) => ({
  mode: sl.getBlotterMode(state),
});

// you can whitelist action creators after implementation
export type HistoricalToggleConnectActionCreators = ActionCreators;
/*
export type HistoricalToggleConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type HistoricalToggleConnectActionCreators = Pick<ActionCreators, HistoricalToggleConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsHistoricalToggle: MapDispatchToPropsHOF<
  HistoricalToggleConnectDispatchProps,
  HistoricalToggleConnectOwnProps,
  HistoricalToggleConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  switchMode(mode) {
    dispatch(ac.blotterChangeMode(mode));
  },
});
