export type TileAction = TileExecutionOverlayHidden;

export interface TileExecutionOverlayHidden {
  type: 'TILE_EXECUTION_OVERLAY_HIDDEN';
  quoteId: string;
}

export function tileExecutionOverlayHidden(quoteId: string): TileExecutionOverlayHidden {
  return {
    type: 'TILE_EXECUTION_OVERLAY_HIDDEN',
    quoteId,
  };
}
