import type { Thunk } from 'state';
import type { ImportedStrategyRow } from 'utils/csvParsing/strategy';
import { fieldData } from 'utils/fieldSelectors';
import type { IFxOptionInputs } from '../model/optionProduct';
import type { FxOptionLegInputs } from '../model/optionsLegs';

export function optionStrategyImportThunk(
  strategyId: string,
  importedStrategyRows: readonly ImportedStrategyRow[],
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const ccyPair = fieldData(sl.getOptionCurrencyPair(state, strategyId)).data!;
    const patch = mapStrategyImportToTradeCapturePatch(importedStrategyRows, ccyPair);
    const [n, priceType] = sl.getOptionDisplayPriceType(state, strategyId);

    if (priceType === 'VOLATILITY' && Object.keys(patch.legs ?? {}).length > 1) {
      dispatch(ac.optionDisplayPriceTypeChanged(strategyId, [n, 'AMOUNT']));
    }

    dispatch(ac.optionLegsImported(strategyId, patch));
  };
}

const wayMap = {
  buy: 'Buy',
  sell: 'Sell',
} as const;

const typeMap = {
  call: 'Call',
  put: 'Put',
} as const;

function mapStrategyImportToTradeCapturePatch(
  importedStrategyRow: readonly ImportedStrategyRow[],
  ccyPair: string,
): Partial<IFxOptionInputs> & {
  legs?: Record<string, Partial<FxOptionLegInputs>>;
} {
  const legs = importedStrategyRow.reduce((acc, curr, index) => {
    acc[index] = {
      side: wayMap[curr.way],
      optionType: typeMap[curr.type],
      expiryDate: curr.expiryDate,
      strike: curr.strike,
      notionalAmount: curr.notional,
      notionalCurrency: ccyPair.indexOf(curr.notionalCcy) === 0 ? 1 : 2,
      deliveryDate:
        curr.deliveryDate === '' || curr.deliveryDate === null ? undefined : curr.deliveryDate,
    };
    return acc;
  }, {} as Record<string, Partial<FxOptionLegInputs>>);

  return { currencyPair: ccyPair, legs };
}
