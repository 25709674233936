import type { CurrencyChoice } from 'state/share/productModel/litterals';
import type {
  ESPPriceType,
  EspStreamStreamingState,
  IESPClientPriceUnderThreshold,
  IESPPriceThreshold,
  IESPTraderPriceUnderThreshold,
} from './espStreamsModel';
import { logger } from 'logging/logger';
import { isEmptyOrNotDefined } from "@sgme/fp";

// ███████╗███████╗██████╗     ██████╗  █████╗ ████████╗ █████╗
// ██╔════╝██╔════╝██╔══██╗    ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
// █████╗  ███████╗██████╔╝    ██║  ██║███████║   ██║   ███████║
// ██╔══╝  ╚════██║██╔═══╝     ██║  ██║██╔══██║   ██║   ██╔══██║
// ███████╗███████║██║         ██████╔╝██║  ██║   ██║   ██║  ██║
// ╚══════╝╚══════╝╚═╝         ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
//
const bidWith = (priceType: ESPPriceType) => (price: IESPPriceThreshold) =>
  priceType === 'ESP.TRADER.PRICE'
    ? (price as IESPTraderPriceUnderThreshold).traderBid
    : (price as IESPClientPriceUnderThreshold).clientBid;
const askWith = (priceType: ESPPriceType) => (price: IESPPriceThreshold) =>
  priceType === 'ESP.TRADER.PRICE'
    ? (price as IESPTraderPriceUnderThreshold).traderAsk
    : (price as IESPClientPriceUnderThreshold).clientAsk;

export function getEspPriceByNotional(
  { priceType, ladders }: Pick<EspStreamStreamingState, 'priceType' | 'ladders'>,
  notional: number | null,
  notionalCurrency: CurrencyChoice,
): {
  price: IESPTraderPriceUnderThreshold | IESPClientPriceUnderThreshold | null;
  index: number | null;
} {
  if (notional === null) {
    return { price: ladders[0] ?? null, index: null };
  }

  const bid = bidWith(priceType);
  const ask = askWith(priceType);

  let index = 0;
  for (const price of ladders) {
    const coeffBid = notionalCurrency === 1 ? 1 : bid(price);
    const coeffAsk = notionalCurrency === 1 ? 1 : ask(price);

    if (notional <= coeffBid * price.thresholdBid && notional <= coeffAsk * price.thresholdAsk) {
      return { price, index };
    }
    index++;
  }
  return { price: null, index: null };
}

export function clientMidRate(threshold: IESPClientPriceUnderThreshold) {
  return (threshold.clientAsk + threshold.clientBid) / 2;
}

export function traderMidRate(threshold: IESPTraderPriceUnderThreshold) {
  return (threshold.traderAsk + threshold.traderBid) / 2;
}

export function getLadderIndexFrom(
  espStreamingState: EspStreamStreamingState,
  amount: number | null,
  amountCurrency: CurrencyChoice,
): number | null {
  if (
    espStreamingState.ladders[0].thresholdAsk === 0 ||
    espStreamingState.ladders[0].thresholdBid === 0
  ) {
    return null;
  }
  const ladder = getEspPriceByNotional(espStreamingState, amount, amountCurrency);
  if (ladder.price != null) {
    if (ladder.price.valid) {
      return ladder.index;
    }
    logger.logInformation('PRICE IS INVALID : {price_s}', JSON.stringify(ladder.price));
  }

  return null;
}

export function isAmountInsideValidLadders(
  amount: number | null,
  amountCurrency: CurrencyChoice,
  espStreamingState: EspStreamStreamingState,
): boolean {
  if (
    amount === null ||
    isEmptyOrNotDefined(espStreamingState.ladders) ||
    espStreamingState.priceType === undefined
  ) {
    return true;
  } else {
    return getLadderIndexFrom(espStreamingState, amount, amountCurrency) !== null;
  }
}
