import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import { connectMifidPanel } from './connect';
import { Negotiation } from './Negotiation';
import en from './locales/en.json';
import fr from './locales/fr.json';

const messagesMap = { en, fr };

export interface MifidPanelProps {
  isVisible: boolean;
  negociations: readonly string[];
  isCreatingNewNegotiation: boolean;
  onClose(): void;
  onNewNegotiation(): void;
}

const Container = styled.div`
  width: 400px;
  z-index: 175;
`;

function MifidPanelRaw({
  isVisible,
  negociations,
  isCreatingNewNegotiation,
  onClose,
  onNewNegotiation,
}: MifidPanelProps): JSX.Element | null {
  return !isVisible ? null : (
    <Container className="p-3 bg-lvl1">
      <IntlComponentBoundary messagesMap={messagesMap}>
        <div className="d-flex">
          <h5 className="flex-grow-1">
            <FormattedMessage id="mifidPanel.title" />
          </h5>
          <button
            className="btn btn-sm btn-icon btn-flat-secondary align-self-start"
            onClick={onClose}
          >
            <i className="icon icon-sm">close</i>
          </button>
        </div>
        <p className="text-secondary">
          <FormattedMessage id="mifidPanel.description" />
        </p>
        <div className="d-flex flex-column">
          {negociations.map(id => (
            <Negotiation key={id} negotiationId={id} />
          ))}
          <button
            className="btn btn-icon-text btn-primary"
            onClick={onNewNegotiation}
            disabled={isCreatingNewNegotiation}
          >
            <i className="icon">add</i>
            <FormattedMessage id="mifidPanel.newNegotiation" />
          </button>
        </div>
      </IntlComponentBoundary>
    </Container>
  );
}

export const MifidPanel = connectMifidPanel(MifidPanelRaw);
